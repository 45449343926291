/**
 * Created by dbichenko on 23.12.2016.
 */
angular.module('sinvoice-common')
    .constant('DocumentConstants', {
        STATUS_OPEN     : 0,
        STATUS_CLOSED   : 1,
        STATUS_CANCELLED: 2
    })
    .constant('DocumentTypeConstants', {
        TYPE_TAX_INVOICE               : 'tax_invoice',
        TYPE_PRICE_QUOTE               : 'price_quote',
        TYPE_PAYMENT_REQUEST           : 'payment_request',
        TYPE_INVOICE_RETAINER          : 'invoice_retainer',
        TYPE_DELIVERY_NOTE             : 'delivery_note',
        TYPE_RETURN_FORM               : 'return_form',
        TYPE_RECEIPT                   : 'receipt',
        TYPE_TAX_INVOICE_RECEIPT       : 'tax_invoice_receipt',
        TYPE_TAX_INVOICE_CREDIT        : 'tax_invoice_credit',
        TYPE_ORDER                     : 'order',
        TYPE_ORDER_PURCHASE            : 'purchase_order',
        TYPE_PERFORM                   : 'perform',
        TYPE_RECEIPT_CREDIT            : 'receipt_credit',
        TYPE_TAX_INVOICE_RECEIPT_CREDIT: 'tax_invoice_receipt_credit',
        TYPE_BALANCE_ADJUSTMENT        : 'balance_adjustment'
    })
    .constant('PaymentTypeConstants', {
        TYPE_CASH         : 'cash',
        TYPE_CHECK        : 'check',
        TYPE_BANK_TRANSFER: 'bank_transfer',
        TYPE_CREDIT_CARD  : 'credit_card',
        TYPE_PAYPAL       : 'paypal',
        TYPE_DIRECT_DEBIT : 'direct_debit',
        TYPE_MONEY_WORTH  : 'money_worth'
    })
    .run(function (ConstantStorage, PaymentTypeConstants, DocumentTypeConstants) {

        ConstantStorage.registerViewConstant('PAYMENT_TYPES', PaymentTypeConstants);
        ConstantStorage.registerViewConstant('DOC_TYPES', DocumentTypeConstants);

    });