angular.module('sinvoice-common')

    .filter('amountValueFormatter', function ($filter) {

        return function (value, currency) {
            return '<span class="money-amount">' + $filter('translateCurrencyLabel')(currency) + ' ' + $filter('number')(value, 2) + '</span>'
        }


    })

    .factory('dpickerSettings', function () {
        return {
            dateFormat: 'DD/MM/YYYY',
            timeFormat: 'DD/MM/YYYY HH:mm'
        }
    })

    .filter('dpickerDateFormatter', function (dpickerSettings) {

        return function (value, emptyValueAllowed, customFormat) {

            if (emptyValueAllowed && !value)
                return '';

            return momentWrapped(value).format(customFormat || dpickerSettings.dateFormat);
        }
    })
    .filter('translateExportOptionLabel', function ($translate) {
        return function (value) {

            if (!value)
                return '';

            return $translate.instant('common.export_options.' + value)
        }
    })

    .filter('translateCreditCard', ['$translate', function ($translate) {
        return function (value) {
            if (!value)
                return '';

            return $translate.instant('common.credit_cards.' + value);
        }
    }])

    .filter('translateLanguageLabel', function ($translate) {
        return function (value) {

            if (!value)
                return '';

            return $translate.instant('common.language.' + value);
        }
    })

    .filter('translateCurrencyLabel', function ($translate) {
        return function (value) {
            if (!value)
                return '';

            return $translate.instant('common.currency.' + value);
        }
    })

    .filter('translateBusinessTypeLabel', function ($translate) {
        return function (value) {
            if (!value)
                return '';

            return $translate.instant('common.business_type.' + value);
        }
    })

    .filter('prependWithZeroes', function () {
        return function (value, count) {


            var string = value.toString();

            var number = count - string.length;
            if (number > 0)
                return '0'.repeat(number) + string;
            else
                return string;


        }
    })
    .filter('toInt', function () {
        return function (value) {
            return value ? parseInt(value) : 0
        }
    })

    // Used for ui-select to search item by written value
    .filter('toString', function () {
        return function (value) {
            return value ? value.toString() : ''
        }
    })
    .filter('trusted', function ($sce) {
        return function (value) {
            return $sce.trustAsHtml(value)
        }
    })
    .filter('trustedResource', function ($sce) {
        return function (value) {
            return $sce.trustAsResourceUrl(value)
        }
    })
    .filter('propsFilter', function () {
        return function (items, props) {
            var out = [];

            if (angular.isArray(items)) {
                var keys = Object.keys(props);

                items.forEach(function (item) {
                    var itemMatches = false;

                    for (var i = 0; i < keys.length; i++) {
                        var prop = keys[i];
                        var text = props[prop].toLowerCase();
                        if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                            itemMatches = true;
                            break;
                        }
                    }

                    if (itemMatches) {
                        out.push(item);
                    }
                });
            } else {
                // Let the output be the input untouched
                out = items;
            }

            return out;
        };
    })

    .filter('denumberize', function () {
        return function (text) {
            var stripped = String(text)
                .replace(/<[^>]+>/gm, '')
                .replace(/,/g, '');


            return parseFloat(stripped);
        }
    })
    .filter('nl2br', function () {
        return function (text) {
            return text ? text.replace(/\n/g, '<br>') : '';
        };
    })
    .filter('enum', function () {

        var indexedArray = [];
        return function (value, array, group, labelAttribute, idAttribute) {

            if (!labelAttribute)
                labelAttribute = 'name';

            if (!idAttribute)
                idAttribute = 'id';

            if (!group)
                group = 'default';

            if (!indexedArray[group]) {
                indexedArray[group] = {};
                array.forEach(function (val) {
                    indexedArray[group][val[idAttribute]] = val[labelAttribute];
                });
            }
            return indexedArray[group][value];
        };
    })
    .filter('translateCustomerPaymentTerm', function ($translate) {
        return function (value) {

            if (!value)
                return '';

            return $translate.instant('customers.payment_terms.' + value);
        }
    })
    .filter('contentLength', [function () {

        return function (value) {


            if (undefined == value)
                return 0;


            return value;
        };
    }]);



