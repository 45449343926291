/**
 * Created by dbichenko on 15.08.2016.
 */
/**
 * Created by dbichenko on 15.08.2016.
 */
angular.module('sinvoice-desktop').controller('overdueInvoicesController',
    function ($scope,
              $filter,
              DTOptionsBuilder,
              DirectiveCompiler,
              $translate,
              DTColumnBuilder,
              DataTableLanguageSettings,
              dpickerSettings,
              Document,
              Events,
              DocumentTypeConstants) {

        function initializeDataTableColumns() {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('issueDate')
                    .withTitle($translate.instant('control.documents.date'))
                    .renderWith(function (data) {
                        return momentWrapped(data).format(dpickerSettings.dateFormat)
                    }),

                DTColumnBuilder
                    .newColumn('paymentDate')
                    .withTitle($translate.instant('documents.date_of_payment'))
                    .renderWith(function (data) {
                        return momentWrapped(data).format(dpickerSettings.dateFormat)
                    }),

                DTColumnBuilder
                    .newColumn('type.docType')
                    .withTitle($translate.instant('control.documents.type'))
                    .renderWith(function (data) {
                        return $filter('translateDocType')(data)
                    }),

                DTColumnBuilder
                    .newColumn('docNumber')
                    .withTitle($translate.instant('control.documents.number')),


                DTColumnBuilder
                    .newColumn('title')
                    .withTitle($translate.instant('control.documents.title')),

                DTColumnBuilder
                    .newColumn('total')
                    .withTitle($translate.instant('control.documents.amount'))
                    .renderWith(function (data, type, fullData) {
                        return DirectiveCompiler.compileIntoHtml('money-amount', {
                            currency: 'NIS',
                            amount  : fullData.displayedTotalNIS
                        });
                    })

            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;
        }

        function initializeDataTable(promise) {
            return DTOptionsBuilder
                .fromFnPromise(promise)
                .withOption('sDom', "Rt")
                .withOption("bInfo", false)
                .withOption('order', [1, "asc"])
                .withOption("oLanguage", DataTableLanguageSettings)
                .withButtons([])
                .withOption("bootstrap", false)

        }

        (function () {


            Events.subscribeToEvent('documents.update', $scope, function () {
                $scope.dtInstance.rerender();
            });

            $scope.dtInstance = {};
            $scope.dtOptions  = initializeDataTable(function () {

                return Document.getExtendedList().then(function (data) {
                    return data.filter(function (value) {
                        var yesterday   = momentWrapped().subtract(1, 'days');
                        var paymentTime = momentWrapped(value.paymentDate);
                        return value.type.docType === DocumentTypeConstants.TYPE_TAX_INVOICE
                            && (paymentTime <= yesterday);
                    })
                });
            });
            $scope.dtColumns  = initializeDataTableColumns();

        })()

    });