/**
 * Created by abichenko on 09.11.17.
 */
angular.module('sinvoice-common')

    .directive('lightboxLink', function (Lightbox, $templateCache) {

        $templateCache.put('custom-lightbox-template', '' +
            '<div class=modal-body ng-swipe-left=Lightbox.nextImage() ng-swipe-right=Lightbox.prevImage()>' +
            '   <div class=lightbox-nav>' +
            '       <a class=close aria-hidden=true ng-click=$dismiss()>' +
            '           <i class="fa fa-times-circle"></i>' +
            '       </a>' +
            '   </div>' +
            '   <div class=lightbox-image-container>' +
            '       <div class=lightbox-image-caption><span>{{Lightbox.imageCaption}}</span></div>' +
            '       <img ng-if=!Lightbox.isVideo(Lightbox.image) lightbox-src={{Lightbox.imageUrl}}>' +
            '       <div ng-if=Lightbox.isVideo(Lightbox.image) class=\"embed-responsive embed-responsive-16by9\">' +
            '           <video ng-if=!Lightbox.isSharedVideo(Lightbox.image) lightbox-src={{Lightbox.imageUrl}} controls autoplay></video>' +
            '           <embed-video ng-if=Lightbox.isSharedVideo(Lightbox.image) lightbox-src={{Lightbox.imageUrl}} ng-href={{Lightbox.imageUrl}} iframe-id=lightbox-video class=embed-responsive-item>' +
            '               <a ng-href={{Lightbox.imageUrl}}>Watch video</a>' +
            '           </embed-video>' +
            '       </div>' +
            '</div>');


        return {
            link: function (scope, element, attrs) {

                if (!attrs['lightboxLink']) {
                    return;
                }

                element.on('click', function (e) {

                    e.preventDefault();

                    var lightbox = Lightbox;

                    Lightbox.templateUrl = 'custom-lightbox-template';

                    lightbox.openModal([{
                        type: 'video',
                        url : attrs['lightboxLink']
                    }], 0, {});

                })

            }
        }

    })