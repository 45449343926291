/**
 * Created by dbichenko on 23.09.2016.
 */
angular.module('sinvoice-desktop').service('DocTypeCommentModal', function (Modal, $rootScope, ModalContainer, $translate) {


    this.newComment  = newComment;
    this.editComment = editComment;
    this.closeAll    = closeAll;

    return this;

    function newComment() {
        displayNewCommentModal();
    }

    function editComment(type) {
        displayEditCommentModal(type);
    }


    function closeAll() {
        ModalContainer.closeGroup('doctypeCommentModals')
    }

    function displayEditCommentModal(type) {

        return Modal.customDialog({
            title      : $translate.instant('profile.templates.edit_comment_modal_title'),
            templateUrl: './desktop/components/profile/docTypeCommentModal.html',
            scopeVariables  : {
                type: type
            },
            size       : "medium"
        }).then(function (dialog) {
            ModalContainer.setElement('edit', dialog, 'doctypeCommentModals');
            return dialog;
        });
    }


    function displayNewCommentModal() {

        return Modal.customDialog({
            title      : $translate.instant('profile.templates.new_comment_modal_title'),
            templateUrl: './desktop/components/profile/docTypeCommentModal.html',
            size       : "medium"
        }).then(function (dialog) {
            ModalContainer.setElement('new', dialog, 'doctypeCommentModals');
            return dialog;
        });
    };
})