angular.module('sinvoice-common')
    .config(function ($translateProvider, DEFAULT_LANGUAGE) {
        $translateProvider.useStaticFilesLoader({
            prefix: './assets/langs/',
            suffix: '.json'
        });
        $translateProvider.preferredLanguage(DEFAULT_LANGUAGE);
        /*$translateProvider.useSanitizeValueStrategy('escape');*/
        $translateProvider.fallbackLanguage('en');
        $translateProvider.useMissingTranslationHandler('customTranslationHandler');
        $translateProvider.useSanitizeValueStrategy('escape');

    })
    .run(function ($rootScope, $translate, onInitContainer) {

        var promise = new Promise(function (resolve, reject) {
            var destroyOnTranslationLoad = $rootScope.$on('$translateLoadingEnd', function (e, object) {
                if (object.language === $translate.preferredLanguage()) {
                    resolve();
                    destroyOnTranslationLoad();
                }
            })
        });

        onInitContainer.addPromise(promise);
    })
    .factory('CustomLanguageTranslatorFactory', function ($translate) {

        return {
            // Thats because .use is asynchronous callback,
            // so it wont work properly without wrapping whole function into promise

            useLanguageForCallback: function (lang, callback) {

                var currentLanguage = $translate.use();

                $translate.use(lang).then(function () {
                    var result = callback();

                    if (result && result.then) {
                        result.then(function () {
                            $translate.use(currentLanguage);
                        })
                    } else {
                        $translate.use(currentLanguage);
                    }
                });
            }
        }
    })
    .factory('customTranslationHandler', function ($rootScope, $translate) {
        return function (key, lang) {
            var keys    = key.split('.');
            var context = keys[0];
            var newKey  = 'global.' + keys[1];

            if (context != 'global') {
                if (!$translate.$translationTable) {
                    throw new Error('Cannot read this expression "' + key + '" at lang \' ' + lang + ' \' ');
                }
                if ($translate.$translationTable[lang][newKey]) {
                    return $translate.$translationTable[lang][newKey];
                } else {
                    return newKey;
                }
            } else {
                return key;
            }
        };
    });
