/**
 * Created by abichenko on 20.11.17.
 */
angular.module('sinvoice-mobile')
    .directive('extrasBlock', function () {

        return {
            scope           : {
                templateVariable          : '=',
                selectedVatParametersModel: '=',
                discountsModel            : '=',
                currencyModel             : '=',
                commentModel              : '=',
                isRoundingEnabled         : '='
            },
            controller      : 'extrasBlockController',
            controllerAs    : 'vm',
            bindToController: true,
            templateUrl     : './mobile/components/new-document/partials/extras-block.html',
            link            : function (scope, element, attrs) {

            }
        }

    });