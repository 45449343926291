/**
 * Created by abichenko on 15.03.18.
 */
angular.module('sinvoice-desktop').controller('topnavSearchController', function ($scope, Search, $state, TopnavSearch, Events) {


    $scope.spinner = {enabled: false};

    $scope.query = '';

    $scope.onChange = function () {

        TopnavSearch.setCurrentQueryString($scope.query);
        Events.triggerEvent('topnav.query.changed');
    };


    $scope.performSearch = function () {
        $state.go('appsearch', {query: $scope.query});
    };

    $scope.updateQueryString = function () {
        TopnavSearch.setCurrentQueryString($scope.query);
    };


});