angular.module('sinvoice-desktop').controller('communicationsController',
    function ($scope,
              DTOptionsBuilder,
              DTColumnBuilder,
              Url,
              Customer,
              $filter,
              $compile,
              TokenStorage,
              DataTableBootstrapSettings,
              DataTableLanguageSettings,
              CommunicationModal,
              Communication,
              DataTableDatepickerLabels,
              CustomerModal,
              NotificationModal,
              Events,
              Modal,
              DataTableFilterValuesConverter,
              DataTablePromiseWrapper,
              UserSubscription,
              $q) {

        $scope.spinner               = {enabled: true};
        $scope.communication         = {};
        $scope.editCommunicationForm = {};
        $scope.dtTotalCount          = {};
        $scope.dtColumns             = null;
        $scope.submissions           = null;


        Events.subscribeToEvent('communications.update', $scope, function () {
            reloadDataTables();
        });


        $scope.getCommunicationFile = function (id) {
            window.open(Url.customers.getCommunicationFile(id, TokenStorage.getCurrentSessionToken()), '_blank');
            return false;
        };


        function reloadDataTables() {
            $scope.dtInstances.all.reloadData(null, true);
            $scope.dtInstances.closed.reloadData(null, true);
            $scope.dtInstances.open.reloadData(null, true);
        }

        function loadStatuses() {
            $scope.statuses = [
                {id: 1, name: 'customers.edit.communications.table.statuses.open'},
                {id: 2, name: 'customers.edit.communications.table.statuses.closed'}
            ]
        }

        function loadSubmissionsTypes() {

            return Communication.getSubmissionTypes().then(function (data) {
                $scope.submissions = data;
            })
        }

        (function () {

            loadStatuses();

            $q.all([
                loadSubmissionsTypes()
            ]).then(function () {
                loadTable();
            })

        })();

        function initializeDataTable(promise, buttonContainer, removeFilterStatus) {

            var aoColumns = [
                null,
                {type: 'text', bRegex: true, bSmart: true},

                {type: 'date-range', bRegex: false, bSmart: false, sRangeFormat: DataTableDatepickerLabels.fromToLabels()},

                {
                    type  : 'select',
                    bSmart: false,
                    bRegex: false,
                    values: DataTableFilterValuesConverter.convert($scope.statuses, 'name')
                },
                {
                    type  : 'select',
                    bSmart: false,
                    bRegex: false,
                    values: DataTableFilterValuesConverter.convert($scope.submissions, 'trans_key')
                },
                {type: 'text', bRegex: true, bSmart: true},
                {type: 'text', bRegex: true, bSmart: true},
                null
            ];

            if (removeFilterStatus) {
                aoColumns.splice(3, 1);
            }

            var dtOptions = DTOptionsBuilder
                .fromFnPromise(promise)
                .withBootstrap()
                .withOption('sDom', "brt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('autoWidth', false)
                .withOption('order', [1, "asc"])
                .withColumnFilter({
                    aoColumns: aoColumns
                })
                .withOption('oLanguage', DataTableLanguageSettings)
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withButtons([])
                .withOption('fnCreatedRow', rowCallback);


            return dtOptions;
        }

        function initializeDataTableColumns(removeStatus) {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('status')
                    .withClass('blue_font')
                    .withClass('search_col')
                    .withTitle($filter('translate')('customers.edit.documents.table.status'))
                    .renderWith(function (data) {
                        return '<i class="status-state"></i>'
                    }),

                DTColumnBuilder
                    .newColumn('id')
                    .withTitle($filter('translate')('customers.edit.communications.table.id'))
                    .withClass('text-nowrap text-center')
                    .renderWith(function (data, type, full) {

                        var expandButton = full.content
                            ? '<a class="expand-button fa fa-caret-left" href="#"></a>'
                            : '';
                        return '<span class="id-link">' + data + '</span>' + expandButton;
                    }),

                DTColumnBuilder
                    .newColumn('date')
                    .withClass('date_col_small')
                    .withTitle($filter('translate')('customers.edit.communications.table.date'))
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data, type, full) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),

                DTColumnBuilder
                    .newColumn('status')
                    .withTitle($filter('translate')('customers.edit.communications.table.status'))
                    .renderWith(function (data, type, full) {
                        var statusLabel = $filter('enum')(data, $scope.statuses, 'statuses');
                        return $filter('translate')(statusLabel);
                    }),


                DTColumnBuilder
                    .newColumn('submission_type')
                    .withTitle($filter('translate')('customers.edit.communications.table.method'))
                    .renderWith(function (data, type, full) {
                        var methodLabel = $filter('enum')(data.id, $scope.submissions, 'submissions', 'trans_key');
                        return $filter('translate')(methodLabel);
                    }),


                DTColumnBuilder
                    .newColumn('contact.full_name')
                    .withTitle($filter('translate')('customers.edit.communications.table.contact')),


                DTColumnBuilder
                    .newColumn('subject')
                    .withClass('subject_col')
                    .withTitle($filter('translate')('customers.edit.communications.table.subject')),

                DTColumnBuilder
                    .newColumn('file')
                    .withClass('file_col')
                    .withTitle($filter('translate')('customers.edit.communications.table.file'))
                    .renderWith(function (data, type, full) {
                        return full.has_file
                            ? '<a href="#" class="fa fa-file file-link"></a>'
                            : null
                    })
                    .notSortable()

            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            if (removeStatus) {
                delete dtColumns[3];
                return dtColumns;
            } else {
                return dtColumns;
            }
        }


        function loadTable() {

            $scope.dtInstances = {
                all   : {},
                closed: {},
                open  : {}
            };

            $scope.dtColumns              = {};
            $scope.dtColumns.all          = initializeDataTableColumns(false);
            $scope.dtColumns.openAndClose = initializeDataTableColumns(true);
            $scope.dtOptions              = {};

            var promiseProvider = function () {
                return Customer.getCommunications($scope.edited.customer.id).then(function (data) {
                    $scope.spinner.enabled = false;
                    return data;
                });
            };
            promiseProvider     = DataTablePromiseWrapper.wrap(promiseProvider);

            $scope.dtOptions['all']    = initializeDataTable(promiseProvider.filteredBy(null), '#all_communications_container', false);
            $scope.dtOptions['closed'] = initializeDataTable(promiseProvider.filteredBy({status: 2}), '#closed_communications_container', true);
            $scope.dtOptions['open']   = initializeDataTable(promiseProvider.filteredBy({status: 1}), '#open_communications_container', true);
        }

        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

            $compile(nRow)($scope);

            var isCommunicationDisabled = (aData.status !== 1);
            var row                     = $(nRow);

            if (isCommunicationDisabled) {
                row.addClass('disabled');
            }

            row.on('click', '.status-state', function (e) {

                e.preventDefault();
                e.stopPropagation();

                if (isCommunicationDisabled)
                    return;

                var that = $(this);

                if (that.hasClass('disabled'))
                    return;


                Modal
                    .confirm(
                        $filter('translate')('customers.edit.communications.solved_confirm'),
                        null,
                        null,
                        {title: $filter('translate')('customers.edit.communications.solved_confirm_title')}
                    )
                    .then(UserSubscription.checkSubscriptionForEditAction)
                    .then(function () {

                        Communication.close(aData.id).then(function () {
                            reloadDataTables();
                        });
                    });

            });


            row.on('click', '.file-link', function (e) {

                e.stopPropagation();
                $scope.getCommunicationFile(aData.id);
                return false;
            });

            row.on('click', '.id-link', function (e) {

                e.preventDefault();
                e.stopPropagation();

                CommunicationModal.editCommunication(aData.id)
            });


            row.on('click', '.expand-button', function (e) {


                e.preventDefault();
                e.stopPropagation();

                var that  = $(this);
                var table = $(nRow).parents('table').DataTable();
                var row   = table.row(nRow);


                var child = row.child;
                if (child() == null) {
                    child(aData.content);
                    $(child()).addClass('communication-content-row');

                }

                if (!child.isShown()) {
                    that
                        .removeClass('fa-caret-left')
                        .addClass('fa-caret-down');
                    child.show();
                } else {
                    that
                        .removeClass('fa-caret-down')
                        .addClass('fa-caret-left');
                    child.hide();
                }


            });

            return nRow;
        };


    });