/**
 * Created by dbichenko on 06.03.2017.
 */
angular.module('sinvoice-desktop').run(function ($rootScope, growl, Auth, RegisterState, ModalContainer, DataStorage) {

    $rootScope.$on('auth:logout', function () {
        ModalContainer.closeAllModals();
        DataStorage.reset();
    });

    $rootScope.$on('auth:reset-stored-user', function () {
        RegisterState.data.value = "firstStep";
    });

    var expiredCallback = function () {
        if (Auth.isAuthenticated()) {
            growl.warning('common.session_expired');
            Auth.logout();
        }
    };

    $rootScope.$on('tokenExpired', expiredCallback);
    $rootScope.$on('notAuthenticated', expiredCallback);
});