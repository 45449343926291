/**
 * Created by dbichenko on 28.12.2016.
 */
angular
    .module('sinvoice-desktop')

    .factory('reportPaymentFormatterProvider', function (BankChecker, $filter) {

        var formatterCallbacks = {
            expiryDate  : function (value, payment) {

                var pieces = [
                    payment.expiryMM ? payment.expiryMM.toString() : '',
                    payment.expiryYY ? payment.expiryYY.toString() : ''
                ];

                return pieces.filter(function (val) {
                    return !!val;
                })
                    .join('/');
            },
            currency    : function (value) {
                return $filter('translateCurrencyLabel')(value.name);
            },
            cardType    : function (value) {
                return $filter('translateCreditCard')(value);

            },
            paymentType : function (value) {
                var key = "documents.payment_methods." + value + ".title";
                return $filter('translate')(key);
            },
            paypalAmount: function (value, payment) {
                return $filter('translateCurrencyLabel')(payment.currency.symbol )+ ' '+  $filter('number')(value, 2);

            },
            date        : function (value) {
                if (!value)
                    return null;

                return $filter('dpickerDateFormatter')(value.date);
            },
            amount      : function (value, payment, isCreditDocument) {

                var raw = isCreditDocument ? value * -1 : value;

                return [
                    $filter('translateCurrencyLabel')('NIS') + ' ' + $filter('number')(raw, 2),
                    raw
                ];
            },
            bank        : function (value, payment) {

                if (!value) {
                    return '';
                }
                if (BankChecker.isCustomBank(value)) {
                    var label = $filter('translate')('common.bank.custom');

                    if (payment.customBankName)
                        return label + ' (' + payment.customBankName + ')';
                    else
                        return label;

                } else {
                    return value.bank_name;
                }

            }
        };

        return {
            getCallback: function (name) {
                return angular.isDefined(formatterCallbacks[name]) ? formatterCallbacks[name] : null;
            }
        };


    })
    .filter('reportShortPaymentDataProvider', function (reportPaymentFormatterProvider) {


        return function (payment, isCreditDocument) {

            return {
                amount: reportPaymentFormatterProvider.getCallback('amount')(payment.totalNIS, payment)[1],
                type  : reportPaymentFormatterProvider.getCallback('paymentType')(payment.type, payment),
                date  : reportPaymentFormatterProvider.getCallback('date')(payment.issueDate, payment)
            }

        }

    })
    .filter('reportPaymentDataProvider', function ($filter, reportPaymentFormatterProvider) {


        var map = {
            cash         : {
                type     : {label: 'reports.incomeForAccounting.table.payments.type', formatter: 'paymentType'},
                issueDate: {label: 'documents.payment_methods.date', formatter: 'date'},
                amountNIS: {label: 'documents.payment_methods.amount', formatter: 'amount'}
            },
            paypal       : {
                type         : {label: 'reports.incomeForAccounting.table.payments.type', formatter: 'paymentType'},
                issueDate    : {label: 'documents.payment_methods.date', formatter: 'date'},
                account      : {label: 'documents.payment_methods.account'},
                transactionId: {label: 'documents.payment_methods.transactionId'},
                amount       : {label: 'documents.payment_methods.amount', formatter: 'paypalAmount'},
                currency     : {label: 'documents.currency', formatter: 'currency'},
                totalNIS     : {label: 'documents.payment_methods.total', formatter: 'amount'},
            },
            check        : {
                type       : {label: 'reports.incomeForAccounting.table.payments.type', formatter: 'paymentType'},
                issueDate  : {label: 'documents.payment_methods.date', formatter: 'date'},
                bank       : {label: 'documents.payment_methods.bank', formatter: 'bank'},
                branch     : {label: 'documents.payment_methods.branch'},
                account    : {label: 'documents.payment_methods.account'},
                checkNumber: {label: 'documents.payment_methods.check.number'},
                amountNIS  : {label: 'documents.payment_methods.amount', formatter: 'amount'},
            },
            credit_card  : {
                type         : {label: 'reports.incomeForAccounting.table.payments.type', formatter: 'paymentType'},
                issueDate    : {label: 'documents.payment_methods.date', formatter: 'date'},
                cardType     : {label: 'documents.payment_methods.credit_card.type', formatter: 'cardType'},
                lastDigits   : {label: 'documents.payment_methods.credit_card.last_digits'},
                expiryYY     : {label: 'documents.payment_methods.credit_card.expiry_date', formatter: 'expiryDate'},
                paymentsCount: {label: 'documents.payment_methods.credit_card.number_of_payments'},
                voucherNumber: {label: 'documents.payment_methods.credit_card.voucher'},
                amountNIS    : {label: 'documents.payment_methods.amount', formatter: 'amount'},

            },
            bank_transfer: {
                type     : {label: 'reports.incomeForAccounting.table.payments.type', formatter: 'paymentType'},
                issueDate: {label: 'documents.payment_methods.date', formatter: 'date'},
                bank     : {label: 'documents.payment_methods.bank', formatter: 'bank'},
                branch   : {label: 'documents.payment_methods.branch'},
                account  : {label: 'documents.payment_methods.account'},
                reference: {label: 'documents.payment_methods.reference'},
                amountNIS: {label: 'documents.payment_methods.amount', formatter: 'amount'},

            },
            direct_debit : {
                type     : {label: 'reports.incomeForAccounting.table.payments.type', formatter: 'paymentType'},
                issueDate: {label: 'documents.payment_methods.date', formatter: 'date'},
                bank     : {label: 'documents.payment_methods.bank', formatter: 'bank'},
                branch   : {label: 'documents.payment_methods.branch'},
                account  : {label: 'documents.payment_methods.account'},
                reference: {label: 'documents.payment_methods.reference'},
                amountNIS: {label: 'documents.payment_methods.amount', formatter: 'amount'},
            },
            money_worth  : {
                type     : {label: 'reports.incomeForAccounting.table.payments.type', formatter: 'paymentType'},
                issueDate: {label: 'documents.payment_methods.date', formatter: 'date'},
                text     : {label: 'documents.payment_methods.money_worth.text'},
                amountNIS: {label: 'documents.payment_methods.amount', formatter: 'amount'},
            }
        };

        return function (payment, document) {

            var isCreditDocument = $filter('isCreditDocument')(document.docType);


            var propertyMap = map[payment.type];
            if (!propertyMap) {
                throw new Error(payment.type + ' is not supported');
            }

            var tableData = [];

            $.each(propertyMap, function (key, val) {


                var propertyValue;
                var formattedValue;


                if (val.displayProperty)
                    propertyValue = payment[key] && payment[key][val.displayProperty]
                        ? payment[key][val.displayProperty]
                        : '';
                else {
                    propertyValue = payment[key];
                }

                if (!val.formatter)
                    formattedValue = propertyValue;
                else {
                    var callback   = reportPaymentFormatterProvider.getCallback(val.formatter);
                    formattedValue = callback(propertyValue, payment, isCreditDocument);
                }

                var raw;
                var value;

                if (Array.isArray(formattedValue)) {
                    value = formattedValue[0];
                    raw   = formattedValue[1];
                } else {

                    value = formattedValue;
                    raw   = propertyValue;
                }


                tableData.push({
                    value: value,
                    label: val.label,
                    raw  : raw,
                    type : val.formatter
                });
            });

            return tableData;
        }

    });
