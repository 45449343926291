/**
 * Created by dbichenko on 18.11.2016.
 */
angular.module('sinvoice-common').factory('DocumentTaxCalculator', function () {


    return {


        getTaxPercentage: function () {
            return this.getTaxModifier() * 100;
        },

        getTaxModifier: function () {
            return 0.17
        },

        calculateAmountWithTax: function (amount) {
            return amount * (1 + this.getTaxModifier());
        },

        calculateAmountWithoutTax: function (amountWithVat) {
            return amountWithVat / (1 + this.getTaxModifier());
        }
    }
});