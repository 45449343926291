/**
 * Created by abichenko on 23.11.17.
 */
angular.module('sinvoice-mobile').directive('bankInputBlock', function () {

    return {
        scope           : {
            banks         : '=',
            bank          : '=',
            customer      : '=',
            parentForm    : '=',
            customBankName: '='
        },
        controller      : 'bankInputBlockController',
        controllerAs    : 'vm',
        bindToController: true,
        templateUrl     : './mobile/components/new-document/partials/bank-input-block.html',

    }
});