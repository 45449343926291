/**
 * Created by abichenko on 20.11.17.
 */
angular.module('sinvoice-mobile').directive('issueDateBlock', function () {


    return {
        scope      : {
            issueDateModel: '=',
            docType       : '='
        },
        controller : 'issueDateBlockController',
        templateUrl: './mobile/components/new-document/partials/issue-date-block.html',
        link       : function (scope, element, attrs) {

        }
    }
})