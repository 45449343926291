/**
 * Created by dbichenko on 30.06.2016.
 */
angular.module('sinvoice-desktop')

    .factory('RegisterState', function () {
        var rs        = {};
        rs.data       = {};
        rs.data.value = "firstStep";
        return rs;
    })

    .factory('Register', function ($q, API, Url) {
        return {
            performFirstStep: function (userData) {

                var defer = $q.defer();

                API.post(Url.register.firstStep(), userData)
                    .success(function (data) {
                        defer.resolve(data);
                    })
                    .error(function (error) {
                        defer.reject(error);
                    });

                return defer.promise;
            }
        }
    })

    .factory('CheckIfExistEmail', function ($q, API, Url) {
        return {
            checkEmail: function (attribut, value) {

                var defer = $q.defer();

                API.get(Url.auth.checkIfExistEmail(attribut, value), {})
                    .success(function (data) {
                        defer.resolve(data);
                    })
                    .error(function (error) {
                        defer.reject(error);
                    });

                return defer.promise;
            }
        }
    });
