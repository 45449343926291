angular.module('sinvoice-desktop').controller('invitemanagerController', function ($scope) {

    $scope.spinner = {enabled: false};

    $scope.rejectInvitation = function () {
        $scope.spinner.enabled = true;
        $scope
            .cancelFunc()
            .then(function () {
                $scope.$currentModal.close();
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })

    };
    $scope.approvedInvitation     = function () {
        $scope.spinner.enabled = true;
        $scope
            .okFunc()
            .then(function () {
                $scope.$currentModal.close();
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })

    }

});
