/**
 * Created by abichenko on 19.03.18.
 */
angular.module('sinvoice-common')
    .directive('customerUniqueNumber', function (asyncValidatorFactory, Customer) {

        return {

            require: 'ngModel',
            link   : function (scope, element, attr, ngModel) {

                ngModel.$asyncValidators['uniqueNumber'] = asyncValidatorFactory
                    .createAsyncValidator(function (object) {
                        return Customer
                            .checkIfExist('businessNumber', object.value)
                            .then(function (data) {
                                if (attr['initialValue'] && attr['initialValue'] === object.value) {
                                    return true;
                                } else {
                                    return !data.result
                                }
                            });
                    });
            }
        }

    });