/**
 * Created by dbichenko on 16.11.2016.
 */
angular.module('sinvoice-desktop')
    .directive('fileUpload', ['FileHelper', '$q', function (FileHelper, $q) {

        return {
            require: 'ngModel',
            scope  : {
                'fileContent': '=fileUpload',
                'file'       : '=ngModel',
                'onChange'   : '&?',
            },
            link   : function (scope, element, attrs, ngModelCtrl) {

                function validExtentionFile(bool) {
                    ngModelCtrl.$setValidity('extension', bool);
                }

                function validSizeFile(bool) {
                    ngModelCtrl.$setValidity('size', bool);
                }

                function setFiles(files) {

                    var promises = [];

                    if (files.length <= 1) {
                        ngModelCtrl.$setViewValue(files[0]);
                    } else {
                        ngModelCtrl.$setViewValue(files);
                    }

                    $.each(files, function (i, file) {

                        var promise = new Promise(function (resolve, reject) {

                            var reader    = new FileReader();
                            reader.onload = function (e) {
                                var content = e.target.result;
                                resolve({
                                    file   : file,
                                    content: content
                                });
                            };
                            reader.readAsDataURL(file);

                        });

                        promises.push(promise);
                    });

                    $q.all(promises).then(function (data) {


                        var contents = data.map(function (value) {
                            return value.content;
                        });

                        if (contents.length <= 1)
                            scope.fileContent = contents[0];
                        else
                            scope.fileContent = contents;

                        if (scope.onChange)
                            scope.onChange({addedFiles: data});

                    })
                }

                element.on('change', function (e) {


                    if (e.target.files.length) {

                        ngModelCtrl.$setDirty();

                        var allowedFormats = attrs['allowedFormats']
                            ? JSON.parse(attrs['allowedFormats'])
                            : [];

                        allowedFormats = allowedFormats.map(function (value) {
                            return value.toLowerCase();
                        });

                        var isValidSize      = true;
                        var isValidExtension = true;
                        var validFiles       = [];


                        $.each(e.target.files, function (i, file) {

                            var isValidFile = true;
                            var extension   = FileHelper.getExtension(file.name);
                            var fileSize    = file.size;

                            if (allowedFormats.length && allowedFormats.indexOf(extension.toLowerCase()) === -1) {
                                isValidExtension = false;
                                isValidFile      = false;
                            }

                            var maxSize;
                            if (!angular.isDefined(attrs['allowedSize']))
                                maxSize = 1024 * 1024 * 5; // 5 MB
                            else {
                                maxSize = attrs['allowedSize'] * 1024 * 1024;
                            }

                            if (fileSize >= maxSize) {
                                isValidSize = false;
                                isValidFile = false;
                            }

                            if (isValidFile)
                                validFiles.push(file);

                        });

                        scope.$apply(function () {
                            validSizeFile(isValidSize);
                            validExtentionFile(isValidExtension);
                        });

                        var addOnlyValid = attrs['addOnlyValid'];
                        var filesToAdd;
                        if (attrs['addOnlyValid']) {
                            filesToAdd = validFiles;
                        } else {
                            filesToAdd = e.target.files;
                        }
                        if (filesToAdd.length)
                            setFiles(filesToAdd);

                        // This allows to trigger on change event, in case of uploading of same file
                        element.val("");

                    }

                });

            }
        }
    }])
    .directive('clickDelegate', function () {
        return {
            link: function (scope, element, attrs) {
                var container = element.parent();
                var target    = container.find(attrs['clickDelegate']);

                element.on('click', function () {
                    target.trigger('click');
                })
            }
        }
    })
    .factory('FileHelper', function () {


        function getBase64Mime(base64String) {
            var parts = base64String.split(',');
            return parts[0];
        }

        function getBase64Content(base64String) {

            var parts = base64String.split(',');
            return parts[1];
        }

        function getExtension(filename) {
            return filename.split('.').pop()
        }

        function base64ToArrayBuffer(base64) {
            var binary_string = window.atob(base64);
            var len           = binary_string.length;
            var bytes         = new Uint8Array(len);
            for (var i = 0; i < len; i++) {
                bytes[i] = binary_string.charCodeAt(i);
            }
            return bytes.buffer;
        }


        var openWindowWithHTML = function (promiseCallback) {
            if (!promiseCallback instanceof Promise)
                throw Error('Callback should provide a promise');

            /*var newWindow           = window.open();
             newWindow.location.href = 'http://fe.si.local';*/

            promiseCallback().then(function (content) {
                window.document.write(content);
            });
        }

        var openWindowAndPrint = function (promiseCallback) {

            // Its a solution for chrome popup blocker issue
            // Google chrome restricts to open popup asynchronously, its allowed only via direct user actions
            // So we can not just load data and open popup after that - it will be blocked.
            if (!promiseCallback instanceof Promise)
                throw Error('Callback should provide a promise');

            var newWindow = window.open();

            promiseCallback().then(function (url) {
                openUrlByPDFViewer(url, newWindow, true);
            });


            return newWindow;
        };


        var openPDF = function (promiseCallback, windowToUse) {
            if (!promiseCallback instanceof Promise)
                throw Error('Callback should provide a promise');

            var isWindowNew = false;
            if (!windowToUse) {
                windowToUse = window.open();
                isWindowNew = true;
            }

            return promiseCallback()
                .then(function (url) {
                    openUrlByPDFViewer(url, windowToUse, false);
                })
                .catch(function (data) {
                    if (isWindowNew) {
                        windowToUse.close();
                    }
                    return Promise.reject(data);
                });

        };

        var saveArchive = function (content, filename) {
            var blob = new Blob([base64ToArrayBuffer(content)], {type: "application/zip;base64"});
            window.saveAs(blob, filename)
        };

        var saveBase64 = function (content, fileName) {

            var base64Content = getBase64Content(content);
            var blob          = new Blob([base64ToArrayBuffer(base64Content)]);

            window.saveAs(blob, fileName)
        };

        var openUrlByPDFViewer = function (url, windowToUse, print) {

            // IE
            /*            if(window.navigator.msSaveOrOpenBlob){
             window.navigator.msSaveOrOpenBlob(blob);
             }else{*/


            var viewerUrl             = '/assets/viewerpdf/web/viewer.html?print=' + print + '&file=' + url;
            windowToUse.location.href = viewerUrl;
        };

        var openBlobAsPDF = function (blob, windowToUse, print) {

            // IE
            /*            if(window.navigator.msSaveOrOpenBlob){
             window.navigator.msSaveOrOpenBlob(blob);
             }else{*/


            var url       = URL.createObjectURL(blob);
            var viewerUrl = '/assets/viewerpdf/web/viewer.html?print=' + print + '&file=' + encodeURIComponent(url);

            if (!print)
                windowToUse.document.write(
                    '<iframe src="' + viewerUrl + '" style="width: 100%;height: 100%; position: absolute; left: 0; top: 0; border: none">'
                );
            else {
                windowToUse.location.href = viewerUrl;
            }
            // }
        };

        var showFileUploadDialogue = function () {

            return new Promise(function (resolve, reject) {

                var input = angular.element(document.createElement("input"));
                input.attr("type", "file");
                input.trigger("click");
                input.on('change', function (e) {

                    var file      = e.target.files[0];
                    var reader    = new FileReader();
                    reader.onload = function (e) {
                        var content = e.target.result;
                        resolve({
                            file   : file,
                            content: content
                        });
                    };
                    reader.readAsDataURL(file);

                });

            });

        };

        return {
            getExtension          : getExtension,
            base64ToArrayBuffer   : base64ToArrayBuffer,
            openWindowAndPrint    : openWindowAndPrint,
            openPDF               : openPDF,
            saveArchive           : saveArchive,
            openWindowWithHTML    : openWindowWithHTML,
            saveBase64            : saveBase64,
            showFileUploadDialogue: showFileUploadDialogue
        }
    });