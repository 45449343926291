angular.module('sinvoice-desktop').controller('requestController', function ($scope, $location, $window, $rootScope, ResetPass) {

    $scope.spinner = {enabled: false};


    $scope.sendRequest = function () {

        if ($scope.loginForm.$invalid)
            return;

        $scope.spinner.enabled = true;

        ResetPass
            .sendResetRequest($scope.email)
            .then(function (data) {
                $scope.showSuccessMessage = true;
            })
            .finally(function () {
                $scope.spinner.enabled = false;

            })


    }
});