/**
 * Created by abichenko on 14.12.17.
 */

angular
    .module('sinvoice-common')
    .run(function (onInitContainer, Events, Profile, BusinessBankAccountDataProvider) {

        onInitContainer.addAfterLoginPromise(loadBusinessInfo, false);

        Events.subscribeToEvent('profile.business-info.update', null, loadBusinessInfo);

        function loadBusinessInfo() {

            return Profile.getBusinessInfo().then(function (data) {

                BusinessBankAccountDataProvider.setProfile(data);
            })
        }


    })
    .factory('BusinessBankAccountDataProvider', function () {

        var profileData = null;

        return {

            setProfile: function (profile) {
                profileData = profile;
            },

            getBank          : function (customer) {
                var details = getFirstBankDetailsFromCustomer(customer);

                if (details) {
                    return details.bank;
                }
            },
            getBranch        : function (customer) {
                var details = getFirstBankDetailsFromCustomer(customer);

                if (details) {
                    return details.branch;
                }
            },
            getCustomBankName: function (customer) {
                var details = getFirstBankDetailsFromCustomer(customer);

                if (details) {
                    return details.customBankName;
                }
            },
            getCustomBankNumber: function (customer) {
                var details = getFirstBankDetailsFromCustomer(customer);

                if (details) {
                    return details.customBankNumber;
                }
            },
            getAccount       : function (customer) {
                var details = getFirstBankDetailsFromCustomer(customer);

                if (details) {
                    return details.account;
                }
            }
        }


        function getFirstBankDetails() {
            if (!profileData.accounts[0]) {
                return null;
            }

            return profileData.accounts[0];
        }


        function getFirstBankDetailsFromCustomer(customer) {
            if (!customer.accounts[0]) {
                return null;
            }

            return customer.accounts[0];
        }


    });