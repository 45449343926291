angular.module('sinvoice-common').factory('Subscription', function ($q, API, Url) {

    return {
        getCurrent           : function () {

            var defer = $q.defer();

            API.get(Url.subscriptions.current(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;

        },
        change               : function (subscriptionId, transactionId, amount, raw) {
            var defer = $q.defer();

            API.post(Url.subscriptions.change(subscriptionId), {
                params     : {
                    transactionId: transactionId,
                    amount       : amount,
                    raw          : raw,
                },
            })
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;

        },
        cancel               : function () {
            var defer = $q.defer();

            API.post(Url.subscriptions.cancel(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;

        },
        getAll               : function () {


            var defer = $q.defer();

            API.get(Url.subscriptions.all(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;

        },
        getYaadpayCredentials: function () {
            var defer = $q.defer();

            API.get(Url.payments.yaadpayCredentials(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }

    }
});