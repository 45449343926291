/**
 * Created by abichenko on 28.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('customerMissingInfoPopupController', function ($scope, Events, Customer, $filter) {

        $scope.customerMissingInfoForm = {};

        $scope.getMessage = function () {

            var translateKey;
            if ($scope.triggeredBy === 'taxValue') {
                translateKey = 'documents.missing_info_popup.body_tax_value';
            } else {
                translateKey = 'documents.missing_info_popup.body';
            }

            return $filter('documentPopupTranslate')(translateKey, $scope.docType.docType);
        };

        $scope.save = function () {

            if ($scope.customerMissingInfoForm.$invalid)
                return false;

            Customer
                .update($scope.customer.id, $scope.customer)
                .then(function () {
                    $scope.$currentModal.close();
                    $scope.onSave($scope.customer);
                    Events.triggerEvent('customer.update');
                });
        };

        $scope.cancel = function () {
            $scope.onCancel();
            $scope.$currentModal.close();
        };

    });