/**
 * Created by abichenko on 29.03.18.
 */
angular.module('sinvoice-mobile')
    .directive('customBankInputBlock', function () {

        return {
            scope           : {
                customBankName: '=',
                customer      : '=',
                parentForm    : '='
            },
            controller      : 'customBankInputBlockController',
            controllerAs    : 'vm',
            bindToController: true,
            templateUrl     : './mobile/components/new-document/partials/custom-bank-input-block.html',
            link            : function (scope, element, attrs) {

            }
        }
    });