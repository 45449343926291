/**
 * Created by abichenko on 17.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('mobileNewDocumentStepOneController', function ($scope, DocumentType, ConstantStorage, AuthDataStorage) {

        var registeredConstants = ConstantStorage.getRegisteredConstants();

        $scope.businessType = AuthDataStorage.retrieveStoredUser().profile.businessType.type;

        $scope.DOC_TYPES = registeredConstants['DOC_TYPES'];

        $scope.documentTypes = {};

        $scope.spinner = {enabled: true};

        $scope.availableDocTypes = [];

        $scope.selectDocType = function (docType) {
            $scope.onTypeSelect($scope.documentTypes[docType]);
        };


        function loadData() {
            return DocumentType.getAvailableDocTypes()
                .then(function (data) {
                    data.forEach(function (value) {
                        $scope.documentTypes[value.docType] = value;
                        $scope.availableDocTypes.push(value.docType);
                    })
                })
        }

        (function () {
            loadData().finally(function () {
                $scope.spinner.enabled = false;
            });
        })();
    });