/**
 * Created by dbichenko on 16.09.2016.
 */
angular.module('sinvoice-desktop').service('ManagedCustomersModal', function ($translate, Modal, ScopeProvider) {

    this.sendInvitations       = sendInvitations;
    this.resendInvitations     = resendInvitations;
    this.getNormalizedData     = getNormalizedData;
    this.exportManagedCustomer = exportManagedCustomer;

    return this;

    function exportManagedCustomer(id) {

        Modal.customDialog({
            title         : $translate.instant('customers.export_managed_customer_popup.title'),
            templateUrl   : './desktop/components/customers/representatives/export-managed-customer-popup.html',
            size          : "large",
            scopeVariables: {
                managedCustomerId: id
            }
        });

    }

    function getNormalizedData(source) {

        if (source.type == 'customer') {
            return {
                firstName: source.name,
                lastName : '',
                phone    : source.phone,
                email    : source.email,
                source   : source
            }
        } else if (source.type == 'distributor') {
            return {
                firstName: source.firstName,
                lastName : source.lastName,
                email    : source.email,
                phone    : source.phone,
                source   : source
            }
        } else if (source.type == 'invite') {
            // :DDDD
            source.source = source;
            return source;
        } else
            return {}

    }


    function initializeScope() {
        var scope = ScopeProvider.getNewIsolatedScope();

        scope.spinner = {};

        return scope;
    }


    function displayInviteModal(scope) {


        Modal.customDialog({
            title      : $translate.instant('customers.managed_customer.add_managed_customer_poppup.new_customer'),
            templateUrl: './desktop/components/customers/representatives/invite-managed-customer-popup.html',
            scope      : scope,
            size       : "large"
        });
    }

    function sendInvitations() {

        var scope = initializeScope();

        displayInviteModal(scope);
    }

    function resendInvitations(customers) {

        var scope      = initializeScope();
        scope.isResend = true;
        scope.invites  = customers;

        if (customers.length == 1) {
            scope.message = customers[0].message;
        }

        scope.invites.map(function (customer) {
            customer.type = 'invite';
            return getNormalizedData(customer);
        });


        displayInviteModal(scope);

    }

});