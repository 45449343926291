angular.module('sinvoice-desktop').controller('searchController', function ($scope, $state, $stateParams, HelpService, $filter) {

    $scope.pageParams.hideHeader = false;
    $scope.searchPerformed       = false;
    $scope.discoveredQuestions   = [];
    $scope.spinner               = {enabled: true};

    (function () {
        $scope.query               = $stateParams.query;
        $scope.pageParams.subTitle = $filter('translate')('help.searchResults');
        HelpService
            .searchQuestions($scope.query)
            .then(function (data) {
                $scope.searchPerformed     = true;
                $scope.discoveredQuestions = data;
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })

    })();

});