/**
 * Created by dbichenko on 01.11.2016.
 */
angular.module('sinvoice-desktop').controller('discountTableElementController', function ($scope, Currency, Events, DocumentInitializer) {


    DocumentInitializer.addInitCallback(function () {

        if (!$scope.discount) {
            throw new Error('$scope.discount is not specified. Please check your variable name.')
        }

        Events.subscribeToEvent('currencies.change', $scope, function (event, newCurrency, oldCurrency) {
            if (newCurrency == oldCurrency || !newCurrency)
                return false;

            $scope.discount.value = Currency.convert($scope.discount.value, oldValue, newValue).toFixed(2);
        });
    });

});