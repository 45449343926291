angular.module('sinvoice-desktop').controller('reportsController',
    function ($scope,
              $compile,
              $q,
              $window,
              $rootScope,
              $translate,
              dpickerSettings,
              $filter,
              Report,
              $state,
              ReportsExport,
              Events,
              AuthDataStorage) {
        $scope.template  = {};
        $scope.customers = [];

        $scope.$watch('reportsGlobalData.currentCustomer', function (newVal, oldVal) {
            if (newVal) {
                Events.triggerEvent('reports.changeCustomer', newVal, oldVal);
            }
        });
        $scope.currentState = '';
        $scope.$on('onLoadReportPage', function (event, data) {
            $scope.currentState = data.state;
            $scope.page         = {
                title      : data.page.alias,
                description: data.page.description ? data.page.description : '',
                alias      : data.page.alias.replace(/\./g,"-")
            };
        });


        function initializeDataTable(promise) {


            var dtOptions = DTOptionsBuilder
                .fromFnPromise(promise)
                .withBootstrap()
                .withOption('sDom', "<'row'<'col-xs-12 text-left mb20'B>>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")

                .withOption('autoWidth', false)
                .withOption('columnDefs', [
                    {width: '26%', targets: 0},
                    {width: '33%', targets: 1},
                    {width: '23%', targets: 0},
                    {width: '18%', targets: -1}
                ])
                .withColumnFilter({
                    aoColumns: [
                        null,
                        null,
                        null,
                        null]
                })
                .withOption('oLanguage', DataTableLanguageSettings)
                .withBootstrapOptions(DataTableBootstrapSettings).withButtons([])
                .withOption('fnCreatedRow', rowCallback);

            return dtOptions;
        }

        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
            $compile(nRow)($scope);

            $(nRow).on('click', '.view', function (e) {

                Report.get(aData.id);
                e.stopPropagation();

            });
            $(nRow).on('click', '.print', function (e) {

                ReportsExport.print(aData);
                e.stopPropagation();
            });
            $(nRow).on('click', '.export', function (e) {

                ReportsExport.showAsPDF(aData);
                e.preventDefault();
            });
            $(nRow).on('click', '.email', function (e) {

                ReportsExport.email(aData);
                e.stopPropagation();
            });
            return nRow;
        }

        function initializeDataColumns() {
            var dtColumns = [

                DTColumnBuilder
                    .newColumn('actions')
                    .withTitle($translate.instant('reports.table.actions'))
                    .renderWith(function (data, type, fullData) {
                        var buttons = "<div class='tbl_type_5_controls'>" +
                            "<span class='view'></span>" +
                            "<span class='print'></span>" +
                            "<span class='export'></span>" +
                            "<span class='email'></span>" +


                            "</div>";
                        return buttons;
                    }),
                DTColumnBuilder
                    .newColumn('details')
                    .withTitle($translate.instant('reports.table.details'))
                    .renderWith(function (data, type, fullData) {

                        if (!fullData.from || !fullData.to)
                            return '';

                        var from = momentWrapped(fullData.from).format(dpickerSettings.dateFormat);
                        var to   = momentWrapped(fullData.to).format(dpickerSettings.dateFormat);
                        return from + ' - ' + to + "<strong>: " + $translate.instant('reports.table.date') + "</strong>";

                    }),
                DTColumnBuilder
                    .newColumn('type')
                    .withTitle($translate.instant('reports.table.report'))
                    .renderWith(function (data, type, fullData) {
                        return $filter('translateReportType')(data)
                    }),

                DTColumnBuilder
                    .newColumn('creationDate')
                    .withTitle($translate.instant('reports.table.date'))
                    .renderWith(function (data) {
                        var date = new momentWrapped(data).format(dpickerSettings.dateFormat);
                        if (date != 'Invalid date') {
                            return date;
                        } else {
                            return '';
                        }
                    })
            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;
        }

        function loadTable() {
            $scope.dtColumns  = initializeDataColumns();
            $scope.dtOptions  = initializeDataTable(function () {
                return Report.getAll();
            });
            $scope.dtInstance = {};

        }

        (function () {
            var user = AuthDataStorage.retrieveStoredUser();

            var helpPopupStatus = user.isReportHelpPopupShown;

            $scope.showHelp = angular.isDefined(helpPopupStatus)
                ? helpPopupStatus
                : true;

        })();

    });