/**
 * Created by abichenko on 17.07.17.
 */
angular.module('sinvoice-desktop').factory('DistributorExport', function (Referral, $q, FileHelper) {


    return {
        exportCommissionsToPDF  : exportCommissionsToPDF,
        printCommissions        : printCommissions,
        downloadCommissionsAsXLS: downloadCommissionsAsXLS,
    };


    function downloadCommissionsAsXLS(commissions) {

        return Referral.getXLSContent(commissions.join(',')).then(function (data) {
            FileHelper.saveArchive(data.content, 'commissions.zip')
        })
    }


    function exportCommissionsToPDF(commissions) {

        var defer = $q.defer();

        FileHelper.openPDF(function () {
            return Referral.getPDFContent(commissions.join(','))
                .then(function (data) {
                    defer.resolve();
                    return data.link;
                })
        });

        return defer.promise;
    }


    function printCommissions(commissions) {

        var defer = $q.defer();

        FileHelper.openWindowAndPrint(function () {
            return Referral.getPDFContent(commissions.join(','))
                .then(function (data) {
                    defer.resolve();
                    return data.link;
                })
        });

        return defer.promise;
    }


})
