/**
 * Created by dbichenko on 10.11.2016.
 */
angular.module('sinvoice-desktop').service('NotificationModal', function (Modal, $translate, ModalContainer) {

    this.sendEmailDirectly                 = sendEmailDirectly;
    this.sendEmailDirectlyToSingleCustomer = sendEmailDirectlyToSingleCustomer;

    this.sendSMSToCustomers              = sendSMSToCustomers;
    this.sendSMSToSingleCustomer         = sendSMSToSingleCustomer;
    this.sendSMSToManagedCustomers       = sendSMSToManagedCustomers;
    this.sendSMSToManagedCustomerInvites = sendSMSToManagedCustomerInvites;

    this.sendEmailToCustomers              = sendEmailToCustomers;
    this.sendEmailToSingleCustomer         = sendEmailToSingleCustomer;
    this.sendEmailToManagedCustomers       = sendEmailToManagedCustomers;
    this.sendEmailToManagedCustomerInvites = sendEmailToManagedCustomerInvites;

    this.sendEmailToDistributors = sendEmailToDistributors;

    this.displaySendResultPopup      = displaySendResultPopup;
    this.displaySendEmailResultPopup = displaySendEmailResultPopup;
    this.displaySendSMSResultPopup   = displaySendSMSResultPopup;

    return this;

    function close() {
        ModalContainer.closeGroup('notification-popup');
    }

    function sendEmailDirectly(customers) {
        displaySendEmailDirectlyPopup(customers, true);
    }

    function sendEmailDirectlyToSingleCustomer(customer) {
        displaySendEmailDirectlyPopup([customer], false);
    }

    function displaySendEmailDirectlyPopup(customers, isCustomerDropdownEnabled) {
        Modal
            .customDialog({
                scopeVariables: {
                    customers                 : customers,
                    $isCustomerDropdownEnabled: isCustomerDropdownEnabled
                },
                title         : $translate.instant('common.notifications.send_email_popup.title'),
                templateUrl   : './desktop/components/common/notifications/send-email-directly-popup.html',
                onEscape      : close
            })
            .then(function (dialog) {
                ModalContainer.setElement('email', dialog, 'notification-popup');
                return dialog;
            });
    }


    function displaySendEmailPopup(recipients, isRecipientsEditable, type) {
        Modal
            .customDialog({
                scopeVariables: {
                    recipients           : recipients,
                    $isRecipientsEditable: !!isRecipientsEditable,
                    $type                : type
                },
                title         : $translate.instant('common.notifications.send_email_popup.title'),
                templateUrl   : './desktop/components/common/notifications/send-email-popup.html',
                onEscape      : close
            })
            .then(function (dialog) {
                ModalContainer.setElement('email', dialog, 'notification-popup');
                return dialog;
            });
    }

    function sendEmailToDistributors(distributors) {
        displaySendEmailPopup(distributors, true, 'distributors');
    }

    function sendEmailToManagedCustomerInvites(invites) {
        displaySendEmailPopup(invites, true, 'managed-customer-invites');
    }

    function sendEmailToCustomers(customers) {
        displaySendEmailPopup(customers, true, 'customers');
    }

    function sendEmailToSingleCustomer(customer) {
        displaySendEmailPopup([customer], false, 'customers');
    }

    function sendEmailToManagedCustomers(managedCustomers) {
        displaySendEmailPopup(managedCustomers, true, 'managed-customers');
    }

    function displaySendSMSPopup(recipients, isRecipientsEditable, type) {


        Modal
            .customDialog({
                scopeVariables: {
                    recipients           : recipients,
                    $isRecipientsEditable: !!isRecipientsEditable,
                    $type                : type
                },
                title         : $translate.instant('common.notifications.send_sms_popup.title'),
                templateUrl   : './desktop/components/common/notifications/send-sms-popup.html',
                onEscape      : close
            })
            .then(function (dialog) {
                ModalContainer.setElement('sms', dialog, 'notification-popup');
                return dialog;
            });
    }

    function sendSMSToManagedCustomerInvites(invites) {
        displaySendSMSPopup(invites, true, 'managed-customer-invites');
    }

    function sendSMSToCustomers(customers) {
        displaySendSMSPopup(customers, true, 'customers');
    }

    function sendSMSToSingleCustomer(customer) {
        displaySendSMSPopup([customer], false, 'customers');
    }

    function sendSMSToManagedCustomers(managedCustomers) {
        displaySendSMSPopup(managedCustomers, true, 'managed-customers');
    }

    function displaySendResultPopup(failedEmails, failedPhones, allEmails, allPhones) {
        return Modal
            .alert(null, {
                title         : $translate.instant('common.notifications.send_result_popup.title'),
                closeButton   : false,
                templateUrl   : './desktop/components/common/notifications/send-result-popup.html',
                scopeVariables: {
                    failedEmails: failedEmails,
                    failedPhones: failedPhones,
                    allEmails   : allEmails,
                    allPhones   : allPhones,
                }
            })
            .then(function (dialog) {
                ModalContainer.setElement('send-result', dialog, 'notification-popup');
                return dialog;
            })
    }

    function displaySendEmailResultPopup(failedNames, allNames) {
        var msg = failedNames.length
            ? $translate.instant('common.notifications.send_email_popup.failed_emails', {
                'recipient_list': failedNames.join(', ')
            })
            : $translate.instant('common.notifications.send_email_popup.success_emails', {
                'recipient_list': allNames.join(', ')
            });

        return Modal
            .alert(msg, {
                title      : $translate.instant('common.notifications.send_email_popup.title'),
                closeButton: false
            })
            .then(function (dialog) {
                ModalContainer.setElement('email', dialog, 'notification-popup');
                return dialog;
            })
    }

    function displaySendSMSResultPopup(failedNames, allNames) {
        var msg = failedNames.length
            ? $translate.instant('common.notifications.send_sms_popup.failed_phones', {
                'recipient_list': failedNames.join(', ')
            })
            : $translate.instant('common.notifications.send_sms_popup.success_phones', {
                'recipient_list': allNames.join(', ')
            });

        return Modal
            .alert(msg, {
                title      : $translate.instant('common.notifications.send_sms_popup.title'),
                closeButton: false
            })
            .then(function (dialog) {
                ModalContainer.setElement('sms', dialog, 'notification-popup');
                return dialog;
            })
    }

});