/**
 * Created by abichenko on 11.12.17.
 */
angular.module('sinvoice-desktop')
    .controller('sendEmailDirectlyPopupController',
        function ($scope, Customer, $filter, NotificationModal, NotificationSendCopyLabelHelper) {

            $scope.spinner = {enabled: true};

            $scope.availableCustomers = [];

            $scope.sendEmailForm = {};

            $scope.getAvailableCustomers = function () {
                return $scope.availableCustomers.filter(function (customer) {

                    if (!$scope.emails)
                        return true;

                    return !_.includes($scope.emails, customer.email)
                });
            };

            $scope.onSendCopyChange = function () {
                NotificationSendCopyLabelHelper.onChangeCallback($scope.sendCopy);

            };

            $scope.send = function () {

                if ($filter('isSubmitNotAllowed')($scope.sendEmailForm))
                    return false;


                $scope.spinner.enabled = true;

                Customer
                    .sendEmailDirectly($scope.emails, $scope.message, $scope.subject, $scope.sendCopy)
                    .then(function (data) {

                        var failedList = data.result.email.failure;
                        NotificationModal
                            .displaySendEmailResultPopup(failedList, $scope.emails)
                            .then($scope.cancel);

                    })
                    .finally(function () {
                        $scope.spinner.enabled = false;
                    })
            };

            $scope.cancel = function () {
                $scope.$currentModal.close();
            };

            $scope.addSelectedCustomer = function (selectedCustomer) {

                if (!$scope.emails) {
                    $scope.emails = [];
                }

                $scope.emails.push(selectedCustomer.email);
            };

            function updateEmails() {
                $scope.emails = $scope
                    .customers
                    .filter(function (customer) {
                        return customer.email
                    })
                    .map(function (customer) {
                        return customer.email
                    });
            }


            (function () {

                $scope.sendCopy = NotificationSendCopyLabelHelper.getDefaultValue();

                if (!angular.isDefined($scope.emails) && angular.isDefined($scope.customers)) {
                    updateEmails();
                }

                if ($scope.emails) {
                    $scope.emails = _.uniq($scope.emails);
                }

                if (!angular.isDefined($scope.$isCustomerDropdownEnabled)) {
                    $scope.$isCustomerDropdownEnabled = true;
                }

                Customer
                    .getData()
                    .then(function (data) {
                        $scope.availableCustomers = data.filter(function (customer) {
                            return !!customer.email;
                        });
                    })
                    .finally(function () {
                        $scope.spinner.enabled = false;
                    })

            })()
        });