angular.module('sinvoice-desktop').controller('receiptsWithoutInvoicesController',
    function ($scope,
              $filter,
              DTOptionsBuilder,
              DTColumnBuilder,
              $translate,
              Document,
              DirectiveCompiler,
              dpickerSettings,
              DataTableLanguageSettings,
              Events,
              DocumentTypeConstants) {


        function initializeDataTableColumns() {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('issueDate')
                    .withTitle($translate.instant('control.documents.date'))
                    .renderWith(function (data) {
                        return momentWrapped(data).format(dpickerSettings.dateFormat)
                    }),

                DTColumnBuilder
                    .newColumn('type.docType')
                    .withTitle($translate.instant('control.documents.type'))
                    .renderWith(function (data) {
                        return $filter('translateDocType')(data)
                    }),

                DTColumnBuilder
                    .newColumn('docNumber')
                    .withTitle($translate.instant('control.documents.number')),

                DTColumnBuilder
                    .newColumn('title')
                    .withTitle($translate.instant('control.documents.title')),


                DTColumnBuilder
                    .newColumn('total')
                    .withTitle($translate.instant('control.documents.amount'))
                    .renderWith(function (data, type, fullData) {

                        return DirectiveCompiler.compileIntoHtml('money-amount', {
                            currency: 'NIS',
                            amount  : fullData.displayedTotalNIS
                        });
                    })

            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;
        }

        function initializeDataTable(promise) {
            return DTOptionsBuilder
                .fromFnPromise(promise)
                .withOption('sDom', "Rt")
                .withOption("bInfo", false)
                .withOption("oLanguage", DataTableLanguageSettings)
                .withButtons([])
                .withOption('order', [0, "desc"])
                .withOption("bootstrap", false)

        }


        function extractChecks(data) {

            return data.filter(function (value) {
                if (value.type.docType !== DocumentTypeConstants.TYPE_RECEIPT)
                    return false;

                var hasTaxInvoice = false;

                if (value.relatedDocs) {
                    value.relatedDocs.forEach(function (doc) {

                        if (!hasTaxInvoice)
                            hasTaxInvoice = (doc.type.docType === DocumentTypeConstants.TYPE_TAX_INVOICE);

                    });
                }

                return !hasTaxInvoice;
            });
        }

        (function () {

            $scope.dtInstance = {};
            $scope.dtOptions  = initializeDataTable(function () {
                return Document.getData(0, 0).then(function (data) {
                    return extractChecks(data);
                });
            });
            $scope.dtColumns  = initializeDataTableColumns();

            Events.subscribeToEvent('documents.update', $scope, function () {
                $scope.dtInstance.rerender();
            })

        })()
    });