/**
 * Created by dbichenko on 12.08.2016.
 */
angular.module('sinvoice-desktop').controller('systemMessagesController', function ($scope, $timeout) {

    $scope.spinner  = true;
    $scope.messages = null;
    $scope.hidden   = null;

    $scope.removeMessage = function (message) {
        $scope.hidden.push(message.id);
        filterMessages();
    };

    function filterMessages() {
        $scope.messages = $scope.messages.filter(function (value) {
            return $scope.hidden.indexOf(value.id) === -1
        });
    }


    (function () {

        $timeout(function () {
            $scope.spinner  = false;
            $scope.messages = [
                {id: 1, text: 'Message 1'},
                {id: 2, text: 'Message 2'},
                {id: 3, text: 'Message 3'},
                {id: 5, text: 'Message 6'}
            ];

            $scope.hidden = [];

            filterMessages();


        }, 5000);


    })()

});