angular.module('sinvoice-desktop').controller('myController',
    function ($scope, CustomerModal) {



        $scope.openNewCustomerForm = function () {
            CustomerModal.createCustomer();
        };


    });
