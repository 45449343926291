/**
 * Created by dbichenko on 24.04.2017.
 */
angular.module('sinvoice-desktop').service('CustomerExport', function (Customer, $q, FileHelper) {

    this.exportCustomersToPDF   = exportCustomersToPDF;
    this.exportCustomersToCSV   = exportCustomersToCSV;
    this.printCustomers         = printCustomers;
    this.downloadCustomersAsXLS = downloadCustomersAsXLS;

    function exportCustomersToCSV(customers) {

        var customerIds = customers.map(function (value) {
            return value.id;
        });

        return Customer.getCSVArchiveContent(customerIds.toString()).then(function (data) {
            FileHelper.saveArchive(data.content, 'customers.zip')
        })
    }


    function exportCustomersToPDF(customers, columns) {

        var defer = $q.defer();

        var customerIds = customers.map(function (value) {
            return value.id;
        });

        FileHelper.openPDF(function () {
            return Customer.getPDFContent(customerIds.toString(), columns.toString())
                .then(function (data) {
                    defer.resolve();
                    return data.link;
                })
        });

        return defer.promise;
    }

    function printCustomers(customers, columns) {

        var defer = $q.defer();

        var customerIds = customers.map(function (value) {
            return value.id;
        });

        FileHelper.openWindowAndPrint(function () {
            return Customer.getPDFContent(customerIds.toString(), columns.toString())
                .then(function (data) {
                    defer.resolve();
                    return data.link;
                })
        });

        return defer.promise;

    }

    function downloadCustomersAsXLS(customers, columns) {

        var customerIds = customers.map(function (value) {
            return value.id;
        });


        return Customer.getXLSArchiveContent(customerIds.toString(), columns.toString()).then(function (data) {
            FileHelper.saveArchive(data.content, 'customers.zip')
        })
    }


    return this;

});