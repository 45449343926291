/**
 * Created by dbichenko on 05.07.2016.
 */
angular.module('sinvoice-common')
    .factory('ModalContainer', function () {

        var openedWindows = {};


        function closeGroup(group) {

            if (!openedWindows[group])
                return;

            $.each(openedWindows[group], function (key, dialog) {

                if (dialog && dialog.close)
                    dialog.close();

                delete openedWindows[key];
            })
        }

        function closeSelected(key, group) {
            if (!group) {
                group = 'default';
            }
            if (openedWindows[group][key] && openedWindows[group][key].close)
                openedWindows[group][key].close();

            delete openedWindows[key];
        }


        function setElement(key, dialog, group) {


            if (!group) {
                group = 'default';
            } else {
                for (var defaultkey in openedWindows['default']) {
                    if (openedWindows['default'][defaultkey] === dialog) {
                        delete openedWindows['default'][defaultkey];
                    }
                }
            }

            if (!openedWindows[group])
                openedWindows[group] = {};

            openedWindows[group][key] = dialog;
        }

        function closeAllModals() {
            for (var group in openedWindows) {
                closeGroup(group);
            }
        }

        return {
            closeAllModals: closeAllModals,
            closeGroup    : closeGroup,
            closeSelected : closeSelected,
            setElement    : setElement
        }


    })
    .service('Modal', [

        '$ngBootbox', '$q', '$templateRequest', '$window', '$compile', '$translate', 'ModalContainer', 'ScopeProvider',
        function ($ngBootbox, $q, $templateRequest, $window, $compile, $translate, ModalContainer, ScopeProvider) {

            var bootbox = $ngBootbox;

            bootbox.customDialog = customDialog;

            bootbox.confirm = confirm;

            bootbox.alert = alert;

            function alert(msg, additionalOptions) {

                var defer = $q.defer();

                if (!angular.isDefined(additionalOptions))
                    additionalOptions = {};

                var buttons = {
                    ok: {
                        label    : $translate.instant('common.ok'),
                        className: "btn-info btn-sm",
                        callback : function () {
                            return defer.resolve();
                        }
                    }
                };

                var options = {
                    className: 'centered-popup',
                    buttons  : buttons,
                    size     : "medium",
                    title    : $translate.instant('common.alert_title'),
                    onEscape : function () {
                        return defer.reject();
                    },
                    closeButton: false
                };


                if (!angular.isDefined(additionalOptions.templateUrl)) {
                    options.templateUrl = './desktop/components/common/common-popup.html';
                } else {
                    options.templateUrl = additionalOptions.templateUrl;
                }

                if (angular.isDefined(additionalOptions.scopeVariables)) {
                    options.scopeVariables = additionalOptions.scopeVariables;
                } else {
                    options.scopeVariables = {};
                }

                if (!options.scopeVariables.message)
                    options.scopeVariables.message = msg;

                if (angular.isDefined(additionalOptions.title)) {
                    options.title = additionalOptions.title;
                }

                if (angular.isDefined(additionalOptions.closeButton)) {
                    options.closeButton = additionalOptions.closeButton;
                }

                customDialog(options);

                return defer.promise;
            }


            function confirm(msg, acceptLabel, declineLabel, additionalOptions) {


                var defer = $q.defer();

                if (!acceptLabel)
                    acceptLabel = $translate.instant("global.bootbox_confirm.buttons.accept");

                if (!declineLabel)
                    declineLabel = $translate.instant("global.bootbox_confirm.buttons.decline");

                var buttons = {
                    accept : {
                        label    : acceptLabel,
                        className: "btn-info btn-sm",
                        callback : function () {
                            return defer.resolve();
                        }
                    },
                    decline: {
                        label    : declineLabel,
                        className: "btn-link btn-xs",
                        callback : function () {
                            return defer.reject();
                        }
                    }
                };


                var options = {
                    className     : 'centered-popup',
                    buttons       : buttons,
                    size          : "medium",
                    title         : $translate.instant('common.confirm_title'),
                    scopeVariables: {
                        message: msg
                    },
                    templateUrl   : './desktop/components/common/common-popup.html',
                    onEscape      : function () {
                        return defer.reject();
                    },
                    closeButton   : false,
                };

                if (!additionalOptions)
                    additionalOptions = {};

                if (angular.isDefined(additionalOptions.closeButton)) {
                    options.closeButton = additionalOptions.closeButton;
                }

                if (angular.isDefined(additionalOptions.title)) {
                    options.title = additionalOptions.title;
                }


                customDialog(options);

                return defer.promise;
            }

            function customDialog(options) {

                var requests = [];
                var headerHtml;
                var footerHtml;


                if (!options.scope) {
                    options.scope        = ScopeProvider.getNewIsolatedScope();
                    options.destroyScope = true;
                }

                if (!options.scopeVariables) {
                    options.scopeVariables = {};
                }

                if (options.headerUrl) {
                    options.title = null;
                } else if (options.headerUrl !== null) {
                    options.headerUrl            = './desktop/components/common/common-popup-header.html';
                    options.scopeVariables.title = options.title;
                    options.title                = null;
                }

                $.each(options.scopeVariables, function (key, value) {
                    options.scope[key] = value;
                });

                options.scope.$currentModal = {};

                if (options.templateUrl) {
                    var bodyRequest = $templateRequest(options.templateUrl).then(function (template) {
                        options.message = $compile(template)(options.scope);
                    });
                    requests.push(bodyRequest);
                }

                if (options.headerUrl) {
                    var headerRequest = $templateRequest(options.headerUrl).then(function (template) {
                        headerHtml = compileHeader(template, options);
                    });
                    requests.push(headerRequest);
                }


                if (options.footerUrl) {
                    options.buttons = null;

                    var footerRequest = $templateRequest(options.footerUrl).then(function (template) {
                        footerHtml = compileFooter(template, options);
                    });
                    requests.push(footerRequest);
                }

                function uniqueId() {
                    function s4() {
                        return Math.floor((1 + Math.random()) * 0x10000)
                            .toString(16)
                            .substring(1);
                    }

                    return s4() + s4() + '_' + s4() + '_' + s4() + '_' +
                        s4() + '_' + s4() + s4() + s4();
                }

                return $q
                    .all(requests)
                    .then(function () {

                        var dialog        = loadDialog(options);
                        var wrappedDialog = createDialogWrapper(dialog);
                        var defaultDialog = angular.copy(options.scope.$currentModal);

                        options.scope.$currentModal = angular.extend(wrappedDialog, defaultDialog);

                        if (footerHtml) {
                            dialog.find('.modal-content').append(footerHtml);
                        }

                        if (headerHtml) {
                            dialog.find('.modal-content').prepend(headerHtml);
                        }

                        ModalContainer.setElement(uniqueId(), options.scope.$currentModal);

                        return options.scope.$currentModal;
                    });
            }


            function compileHeader(template, options) {

                var header     = $compile(template)(options.scope);
                var headerHtml = $('<div class="modal-header">');
                headerHtml.append(header);

                if (options.closeButton !== false) {
                    options.closeButton = false;
                    var closeButton;
                    var closeButtonHtml = '' +
                        '<a class="close" tabindex="0" space-bar-clickable>' +
                        '   <i class="fa fa-times-circle"></i>' +
                        '</a>';

                    closeButton = $compile(closeButtonHtml)(options.scope);
                    closeButton.on('click', function () {
                        if (options.onEscape) {
                            options.onEscape();
                        }
                    });
                    headerHtml.append(closeButton);
                }

                if (!angular.isDefined(options.onEscape)) {
                    options.onEscape = function () {

                        var currModal = options.scope.$currentModal;
                        if (!currModal.onCancelCallback) {
                            return currModal.close();
                        }

                        var promise = new Promise(function (resolve, reject) {
                            return resolve(currModal.onCancelCallback());
                        });

                        promise.then(function (isCloseRequired) {
                            if (isCloseRequired !== false) {
                                currModal.close();
                            }
                        });
                    };

                    if (!angular.isDefined(options.scope.cancel))
                        options.scope.cancel = options.onEscape;
                }

                return headerHtml;
            }


            function compileFooter(template, options) {
                var footer     = $compile(template)(options.scope);
                var footerHtml = $('<div class="modal-footer">');
                footerHtml.append(footer);

                return footerHtml;
            }

            function loadDialog(options) {
                var dialog = $window.bootbox.dialog(options);

                if (options.destroyScope) {
                    dialog.on('hidden.bs.modal', function (e) {
                        options.scope.$destroy();
                    });
                }

                return dialog;
            }


            function createDialogWrapper(dialog) {
                return {
                    element         : dialog,
                    onCancelCallback: null,
                    close           : function () {
                        return dialog.modal('hide');
                    }
                };
            }


            return bootbox;

        }])
    .factory('EditedValueComparator', function () {


        function difference(object, base) {
            function changes(object, base) {

                return _.transform(object, function (result, value, key) {

                    if (key === '$$hashKey') {
                        return;
                    }

                    if (!_.isEqual(value, base[key])) {
                        result[key] = (_.isObject(value) && _.isObject(base[key]))
                            ? changes(value, base[key])
                            : value;
                    }
                });
            }


            return changes(object, base);
        }

        return {
            isObjectPropsChanged        : function (oldValue, newValue, properties) {

                if (!properties) {
                    throw new Error('Properties should be provided');
                }

                var isEqual = true;

                $.each(oldValue, function (key, value) {
                    if (!_.includes(properties, key))
                        return;

                    if (!_.isEqual(oldValue[key], newValue[key])) {
                        isEqual = false;
                    }
                });

                return !isEqual;

            },
            isObjectChangedExceptOfProps: function (oldValue, newValue, ignoredProperties) {

                var diff = difference(angular.copy(newValue), angular.copy(oldValue));

                var trackedProperties = _.keys(diff).filter(function (value) {
                    return !_.includes(ignoredProperties, value)
                });

                return trackedProperties.length;
            }
        }

    })
    .directive('ngBootboxModalWatcher', function () {

        return {
            link: function (scope, element) {

                $(document).on('hidden.bs.modal', '.modal', function (e) {

                    if ($('.modal:visible').length)
                        $(document.body).addClass('modal-open');

                })
            }
        }
    });