/**
 * Created by abichenko on 31.07.17.
 */
angular.module('sinvoice-desktop')
    .service('CustomerImport', function (Customer, FileHelper) {
        this.importFromXLSContent = importFromXLSContent;
        this.checkUploadStatus    = checkUploadStatus;
        this.confirmImporting     = confirmImporting;

        return this;

        function confirmImporting(token) {
            return Customer.confirmImporting(token);

        }

        function checkUploadStatus(token) {
            return Customer.getUploadStatus(token);
        }

        function importFromXLSContent(content) {

            return Customer.importFromXLS(content);

        }
    });