/**
 * Created by abichenko on 14.11.17.
 */
angular.module('sinvoice-desktop')

    .directive('shareMenu', function () {

        return {
            transclude: true,
            template  : '' +
            '<span>' +
            '   <a class="yl_share">{{"control.share"|translate}}</a>' +
            '   <span class="share-menu-container" style="display: none" ng-transclude=""></span>' +
            '</span>',
            link      : function (scope, element, attrs) {


                element.find('.yl_share').popover({
                    content  : function () {
                        return element.find('.share-menu-container').html();
                    },
                    trigger  : 'click',
                    html     : true,
                    placement: 'bottom',
                });


            }
        }


    })

    .directive('googlePlusShareLink', function () {


        return {
            transclude: true,
            template  : '<span><a class="google-plus-share-link" target="_blank"  ng-transclude=""></a></span>',
            restrict  : 'E',
            scope     : {
                'link': '@'
            },
            link      : function (scope, element, attrs) {

                if (!attrs['link'])
                    return;

                var params = {
                    url: attrs['link']
                };

                element.find('.google-plus-share-link').attr('href', 'https://plus.google.com/share?' + $.param(params));
            }
        }

    })


    .directive('linkedinShareLink', function () {

        return {
            transclude: true,
            template  : '<span><a class="linkedin-share-link" target="_blank"  ng-transclude=""></a></span>',
            restrict  : 'E',
            scope     : {
                'link': '@'
            },
            link      : function (scope, element, attrs) {


                if (!attrs['link'])
                    return;

                var docTitle    = $("meta[property='og:title']").attr("content");
                var description = $("meta[property='og:description']").attr("content");
                var url         = $("meta[property='og:url']").attr("content");


                var params = {
                    mini   : 'true',
                    url    : attrs['link'],
                    title  : docTitle,
                    summary: description,
                    source : url
                };

                element.find('.linkedin-share-link').attr('href', 'https://www.linkedin.com/shareArticle?' + $.param(params));
            }
        }


    })
    .directive('facebookShareLink', function () {


        return {
            transclude: true,
            template  : '<span><a class="facebook-share-link"  target="_blank" ng-transclude=""></a></span>',
            restrict  : 'E',
            scope     : {
                'link': '@'
            },
            link      : function (scope, element, attrs) {

                if (!attrs['link'])
                    return;

                element.find('.facebook-share-link').attr('href', 'http://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(attrs['link']))
            }
        }
    })
    .directive('twitterShareUrl', function () {


        return {
            transclude: true,
            template  : '<span><a class="twitter-share-link"  target="_blank" ng-transclude=""></a></span>',
            restrict  : 'E',
            scope     : {
                'link': '@'
            },
            link      : function (scope, element, attrs) {

                if (!attrs['link'])
                    return;

                var description = $("meta[property='og:description']").attr("content");

                var params = {
                    url : attrs['link'],
                    text: description
                };

                element
                    .find('.twitter-share-link')
                    .attr('href', 'http://twitter.com/share?' + $.param(params))
            }
        }
    });