/**
 * Created by dbichenko on 02.06.2017.
 */
angular.module('sinvoice-desktop').controller('occasionalCustomerMissingInfoPopup', function ($scope) {


    $scope.occasionalCustomerMissingInfoForm = {};

    $scope.save = function () {

        if ($scope.occasionalCustomerMissingInfoForm.$invalid)
            return false;

        $scope.onSave($scope.occasionalCustomerName, $scope.occasionalCustomerVatNumber);
        $scope.$currentModal.close();
    };

    $scope.cancel = function () {
        $scope.onCancel();
        $scope.$currentModal.close();
    };


});