/**
 * Created by abichenko on 12.04.18.
 */
angular.module('sinvoice-desktop').service('TopnavTextContainer', function () {

    var text = null;

    return {
        setText  : function (newText) {
            text = newText;
        },
        clearText: function () {
            text = null;
        },
        getText  : function () {
            return text;
        }
    }
});