/**
 * Created by ikorol on 18.10.2017.
 */
angular.module('sinvoice-mobile').controller('MobileDocumentsCustomersController', function ($scope, Subscription, AuthDataStorage, $state) {
    $scope.spinner                 = {enabled: true};
    $scope.currentSubscriptionData = null;


    $scope.getDocumentsLeft = function () {

        if (!$scope.currentSubscriptionData.currentPeriod)
            return 0;

        return $scope.getMaxDocuments() - $scope.getUsed();
    };

    $scope.getMaxDocuments = function () {
        return $scope.currentSubscriptionData.currentPeriod.selectedPlan.maxDocuments;
    };

    $scope.getUsed = function () {
        return $scope.currentSubscriptionData.currentPeriod.documentsUsed;
    };

    $scope.newCustomer = function () {
        $state.go('new-customer');
    };

    $scope.newDocument = function () {
        $state.go('new-document');
    };

    $scope.getUserName = function () {
        var profile = AuthDataStorage.getProfile();

        return profile.firstName + ' ' + profile.lastName;
    };

    (function () {
        Subscription.getCurrent()
            .then(function (data) {
                $scope.currentSubscriptionData = data.content;
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })
    })();


})