/**
 * Created by abichenko on 27.10.17.
 */
angular.module('sinvoice-mobile')
    .directive('onLinkClick', function () {

        return {
            scope: {onLinkClick: '&'},
            link : function (scope, element, attrs) {
                element.on('click', 'a', function () {
                    scope.onLinkClick();
                })
            }
        }
    })
    .directive('activeOnStateMatch', function ($state, $rootScope) {

            return {
                scope: {},
                link : function (scope, element, attrs) {
                    if (!attrs['uiSref'])
                        return;

                    scope.$watch(function () {
                        return $state.$current.name
                    }, function (newName) {

                        var liElement = element.parent();
                        if (attrs['uiSref'] === newName && !liElement.hasClass('active')) {
                            liElement.addClass('active')
                        } else if (liElement.hasClass('active')) {
                            liElement.removeClass('active');
                        }
                    });
                }
            }
        }
    );
