/**
 * Created by dbichenko on 15.03.2017.
 */
angular.module('sinvoice-desktop').controller('editItemController',
    function ($scope, Item, Events, Modal, Currency, $filter, EditedValueComparator, DesktopModal, $q) {

        var backup;

        $scope.spinner = {
            enabled: true
        };

        $scope.currencies = [];

        $scope.$currentModal.onCancelCallback = function () {
            if (EditedValueComparator.isObjectChangedExceptOfProps(backup, $scope.item)) {
                return DesktopModal.unsavedChanges();
            } else {
                return true;
            }
        };

        $scope.delete = function () {
            Modal
                .confirm(
                    $filter('translate')('items.delete_message'),
                    null,
                    null,
                    {title: $filter('translate')('items.delete_message_title')}
                )
                .then(function () {

                    $scope.spinner.enabled = true;


                    Item
                        .deleteItem($scope.item.id)
                        .then(function () {
                            Modal.hideAll();
                            Events.triggerEvent('items.update');
                        })
                        .finally(function () {
                            $scope.spinner.enabled = false;
                        })

                })
        };

        $scope.save = function () {


            function onSuccess(data) {
                if (data.status == false) {
                    $scope.itemForm.sku.$setValidity("unique", false);
                    return false;
                } else {
                    Modal.hideAll();
                    Events.triggerEvent('items.update');
                    return true;
                }
            }

            $scope.itemForm.sku.$setValidity("unique", true);

            if ($scope.itemForm.$invalid) {
                return false;
            }

            var dataToSave = angular.copy($scope.item);

            $scope.spinner.enabled = true;

            if (dataToSave.id) {
                Item
                    .updateItem(dataToSave.id, dataToSave)
                    .then(onSuccess)
                    .finally(function () {
                        $scope.spinner.enabled = false;
                    })

            } else {
                Item
                    .createItem(dataToSave)
                    .then(onSuccess)
                    .then(function (result) {
                        if (result) {
                            Modal.alert(
                                $filter('translate')('items.save_success_popup.message'),
                                {title: $filter('translate')('items.save_success_popup.title')}
                            );
                        }
                    })
                    .finally(function () {
                        $scope.spinner.enabled = false;
                    })

            }
        };


        function loadCurrencies() {
            return Currency.getData().then(function (data) {
                $scope.currencies = data;

            });
        }

        (function () {

            var itemPromise = new Promise(function (resolve, reject) {
                if ($scope.itemId) {
                    return resolve(Item.get($scope.itemId));
                } else {
                    return resolve({});
                }
            });

            itemPromise.then(function (data) {
                $scope.item = data;
            });

            var currencyPromise = loadCurrencies();

            $q
                .all([
                    itemPromise,
                    currencyPromise
                ])
                .then(function () {

                    if (!angular.isDefined($scope.item.price))
                        $scope.item.price = 0;

                    if (!$scope.item.currency) {
                        $scope.item.currency = $scope.currencies[0];
                    }

                    backup                 = angular.copy($scope.item);
                    $scope.spinner.enabled = false;
                });


        })();
    });