/**
 * Created by abichenko on 01.12.17.
 */
angular.module('sinvoice-mobile')
    .controller('mobileResetPasswordController', function ($scope, $state, $stateParams, ResetPass, $filter) {

        $scope.spinner = {enabled: false};

        $scope.selected = {};

        $scope.token = '';

        $scope.resetPassForm = {};

        (function () {

            if (!$stateParams.token) {
                $state.go("login");
                return
            }

            ResetPass.validateToken($stateParams.token).then(function (data) {
                $scope.token = $stateParams.token;
            }, function () {
                $state.go('login');
            })

        })();


        $scope.resetPass = function () {

            if ($filter('isSubmitNotAllowed')($scope.resetPassForm)) {
                return;
            }

            $scope.spinner.enabled = true;

            ResetPass
                .changePass($scope.token, $scope.selected.password)
                .then(function () {
                    $state.go("login")
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        };

    });