/**
 * Created by dbichenko on 27.09.2016.
 */
angular.module('sinvoice-common').factory('Profile', function ($q, API, Url) {

    return {
        getData      : function () {


            var defer = $q.defer();

            API.get(Url.profile.profile(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;

        },
        updateProfile: function (data) {


            var defer = $q.defer();

            API.post(Url.profile.update(), data)
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },

        updateBusinessInfo: function (data) {

            var defer = $q.defer();

            API.patch(Url.profile.businessInfo(), data)
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getBusinessInfo   : function () {

            var defer = $q.defer();

            API.get(Url.profile.businessInfo(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getBusinessTypes  : function () {
            var defer = $q.defer();

            API.get(Url.profile.businessTypes(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },

        changePass               : function (password) {
            var defer = $q.defer();

            API.post(Url.profile.changepass(), password)
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;

        },
        getBusinessDefinitions   : function () {

        },
        updateBusinessDefinitions: function (data) {

            var defer = $q.defer();

            API.patch(Url.profile.businessDefinitions(), data)
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },

        getBookManagementSettings     : function () {
            var defer = $q.defer();

            API.get(Url.profile.bookManagementSettings(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        updateBookManagementSettings  : function (data) {
            var defer = $q.defer();

            API.post(Url.profile.bookManagementSettings(), {settings: data})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getMailSettings               : function () {
            var defer = $q.defer();

            API.get(Url.profile.mailSettings(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        updateMailSettings            : function (data) {
            var defer = $q.defer();

            API.post(Url.profile.mailSettings(), {settings: data})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getPrintSettings              : function () {
            var defer = $q.defer();

            API.get(Url.profile.printSettings(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        updatePrintSettings           : function (data) {
            var defer = $q.defer();

            API.post(Url.profile.printSettings(), {settings: data})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getSecretQuestions            : function () {

            var defer = $q.defer();

            API.get(Url.secretQuestions.all(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getWizcountSettings           : function () {
            var defer = $q.defer();

            API.get(Url.profile.wizcountSettings(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        updateWizcountSettings        : function (data) {
            var defer = $q.defer();

            API.post(Url.profile.wizcountSettings(), {settings: data})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getSubscriptionSettings       : function () {
            var defer = $q.defer();

            API.get(Url.profile.subscriptionSettings(), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        updateSubscriptionSettings    : function (data) {
            var defer = $q.defer();

            API.post(Url.profile.subscriptionSettings(), {settings: data})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        updateSendCopyEnabledByDefault: function (value) {
            var defer = $q.defer();

            API.post(Url.profile.setSendCopyEnabledByDefault(), {value: value})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        checkIfNumberExist: function (value) {

            var defer = $q.defer();

            API.get(Url.business.checkIfNumberExist(value), {})
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;

        }
    }
});