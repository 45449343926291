/**
 * Created by abichenko on 14.02.18.
 */
angular.module('sinvoice-mobile').controller('contactUsController', function ($scope, Question, $filter, AuthDataStorage) {

    $scope.contactUsForm = {};
    $scope.spinner       = {sendInquiry: false};
    $scope.isFormHidden  = false;

    $scope.sendContactForm = function () {
        if ($filter('isSubmitNotAllowed')($scope.contactUsForm)) {
            return;
        }
        $scope.spinner.sendInquiry = true;
        Question
            .sendInquiry($scope.sender)
            .then(function (data) {
                if (data.status) {
                    $scope.spinner.sendInquiry = false;
                    $scope.isFormHidden        = true;
                }
            });
    };

    (function () {

        var profile   = AuthDataStorage.getProfile();
        $scope.sender = {
            email   : profile.email,
            phone   : profile.phone,
            fullName: profile.firstName + " " + profile.lastName
        };

    })();
});