/**
 * Created by dbichenko on 18.01.2017.
 */
angular.module('sinvoice-desktop').controller('ordersBookTemplateChangerController', function ($scope, Events) {

    
    $scope.currentTemplate = '';
    
    $scope.changeOrdersBookTemplate = function (value) {
        
        $scope.currentTemplate = value;
        
        Events.triggerEvent('reports.ordersBookTemplateChanged', value);
    };

    (function () {
        $scope.changeOrdersBookTemplate('slider');
    })()

});