/**
 * Created by ikorol on 12.10.2017.
 */
angular.module('sinvoice-mobile').controller('MobileLayoutController', function ($rootScope, $scope, $state, Auth) {

    $scope.isActive = function () {
        return $rootScope.isVisibleMenu;
    };

    $scope.isHeaderHidden = function () {
        return !Auth.isAuthenticated();
    };

    $rootScope.$on('auth:login', function () {
        $state.go('my-documents-customers');
    });


    var expiredCallback = function () {
        if (Auth.isAuthenticated()) {
            Auth.logout();
        }
    };

    $rootScope.$on('tokenExpired', expiredCallback);
    $rootScope.$on('notAuthenticated', expiredCallback);

    $rootScope.$on('auth:logout', function () {
        $state.go('login')
    });

});