/**
 * Created by abichenko on 24.11.17.
 */
angular.module('sinvoice-mobile')
    .directive('paypalBlock', function () {
        return {
            scope      : {
                payment      : '=',
                currencies   : '=',
                dates        : '=',
                isNisPayments: '=',
                currency     : '=',
                nisCourse    : '='

            },
            controller : 'paypalBlockController',
            templateUrl: './mobile/components/new-document/partials/paypal-block.html',
            link       : function (scope, element, attrs) {

            }
        }

    });