/**
 * Created by Front-end on 29.12.2016.
 */
angular.module('sinvoice-desktop').controller('detailsBankController',
    function ($scope, Modal, $filter, BankProvider, Events, $timeout, Customer, DetailsBackup, DesktopModal, BankChecker) {

        $scope.bankForm          = {};
        $scope.editedBankAccount = null;

        $scope.isCustomBankSelected = function (bank) {
            return BankChecker.isCustomBank(bank);
        };

        $scope.deleteBankAccount = function (bankAccount) {
            Modal
                .confirm(
                    $filter('translate')('customers.edit.details.bank_account.delete_confirm'),
                    null,
                    null,
                    {title: $filter('translate')('customers.edit.details.bank_account.delete_confirm_title')}
                )
                .then(function () {
                    return Customer.deleteBankAccount(bankAccount.id);
                })
                .then(function () {
                    $scope.edited.customer.accounts = $scope.edited.customer.accounts.filter(function (currentAccount) {
                        return currentAccount != bankAccount;
                    });
                    reload();
                });

        };

        $scope.saveBankAccount = function () {
            if ($scope.editedBankAccount.id) {
                Customer.updateBankAccount($scope.editedBankAccount.id, $scope.editedBankAccount).then(function (data) {
                    for (var i = 0; i < $scope.edited.customer.accounts.length; i++) {
                        if ($scope.edited.customer.accounts[i].id == $scope.editedBankAccount.id) {
                            $scope.edited.customer.accounts[i] = data;
                        }
                    }
                    doCancelBankAccount();
                    reload();
                });
            } else {
                $scope.editedBankAccount.customer_id = $scope.edited.customer.id;
                Customer.createBankAccount($scope.editedBankAccount).then(function (data) {
                    $scope.edited.customer.accounts.push(data);
                    doCancelBankAccount();
                    reload();
                })
            }
        };


        $scope.editBankAccount = function (bankAccount) {
            $scope.editedBankAccount = bankAccount;
            DetailsBackup.registerTrackedSection('bankAccount', angular.copy($scope.editedBankAccount), $scope.editedBankAccount);
        };

        $scope.cancelBankAccount = function () {
            if (DetailsBackup.isChanged('bankAccount')) {
                DesktopModal
                    .unsavedChanges()
                    .then(function () {
                        $timeout(doCancelBankAccount);
                    });
            } else {
                return doCancelBankAccount();
            }
        };

        $scope.addBankAccount = function () {
            $scope.editedBankAccount = {};
            DetailsBackup.registerTrackedSection('bankAccount', angular.copy($scope.editedBankAccount), $scope.editedBankAccount);
        };

        function doCancelBankAccount() {
            $scope.editedBankAccount = null;
            $scope.bankForm.$setPristine();
            DetailsBackup.unregisterTrackedSection('bankAccount');
        }

        function loadBanks() {
            BankProvider.getAll(true).then(function (data) {
                $scope.banks = data;
            })
        }

        function reload() {
            Events.triggerEvent('customer.update');
        }

        (function () {
            loadBanks();
        })();
    });