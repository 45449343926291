/**
 * Created by dbichenko on 21.07.2016.
 */
angular.module('sinvoice-desktop').controller('receiptController',
    function ($scope,
              Modal,
              $translate,
              FormHelper,
              Currency,
              BusinessChecker,
              CustomerTypeChecker,
              Events,
              TemplateChecker,
              DocumentModalCallbackContainer,
              DocumentModalCallbackChecker,
              RelatedDocumentTableCallbackContainer,
              DocumentModalCallbackProvider,
              DocumentTypeConstants,
              AuthDataStorage) {

        $scope.template.$oneSideRelationDocTypes = [
            DocumentTypeConstants.TYPE_ORDER,
            DocumentTypeConstants.TYPE_DELIVERY_NOTE,
            DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
            DocumentTypeConstants.TYPE_PRICE_QUOTE,
            DocumentTypeConstants.TYPE_PERFORM
        ];

        $scope.template.$reliableTypesOfOpenDocuments = [
            DocumentTypeConstants.TYPE_TAX_INVOICE,
            DocumentTypeConstants.TYPE_ORDER,
            DocumentTypeConstants.TYPE_DELIVERY_NOTE,
            DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
            DocumentTypeConstants.TYPE_PRICE_QUOTE,
            DocumentTypeConstants.TYPE_PERFORM,
            DocumentTypeConstants.TYPE_RECEIPT_CREDIT,
            'receipt_balance_adjustment'
        ];

        Events.subscribeToEvent('relatedDocs.checkedHasRelatedDocuments', $scope, function (e, hasRelatedDocs) {
            if (!hasRelatedDocs && TemplateChecker.isRetrotractiveDocsRelatingAllowed($scope)) {
                $scope.template.saveButtonDisabled = true;
            }
        });


        function initializeCustomValidators() {

            if (BusinessChecker.isExemptDealer(AuthDataStorage.getProfile().businessType)) {
                $scope.template.$reliableTypesOfAnyStatusDocuments = [
                    DocumentTypeConstants.TYPE_RECEIPT_CREDIT
                ];
            }

            DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                var editedDocument = $scope.editedDocument;
                var paymentErrors  = $scope.template.payments.errors;

                paymentErrors.required = false;

                if (!editedDocument.payments.length) {
                    flow.deny();
                    paymentErrors.required = true;
                    return false;
                }

                flow.allow();
            });

            Events.subscribeToEvent('paymentForms.update', $scope, function (e, value) {

                $.each(value, (function (key, forms) {
                    $.each(forms, (function (key, form) {
                        if (!form)
                            return;
                        FormHelper.addValidator(form.amount, 'required', function (value) {
                            return !!value && value > 0;
                        })

                    }))
                }))
            });
        }


        (function () {
            initializeCustomValidators();


            if (!angular.isDefined($scope.editedDocument.isRoundingEnabled))
                $scope.template.showRoundedTotal = true;
            else {
                $scope.template.showRoundedTotal = !!($scope.editedDocument.isRoundingEnabled);
            }

            DocumentModalCallbackContainer.addPreSaveCallback(
                DocumentModalCallbackProvider.occasionalCustomerInfoMissing($scope.editedDocument, $scope.template, $scope.documentForm)
            );

            DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                if (!DocumentModalCallbackChecker.isAmountDoesNotMatchPopupNeedToBeShown(
                        $scope.editedDocument,
                        $scope.template.$paymentsTotalWithVatNIS,
                        $scope.template.$finalItemsTotalWithVatNIS
                    ))
                    return flow.allow();

                Modal
                    .confirm(
                        $translate.instant('documents.receipt.not_match_message'),
                        null,
                        null,
                        {title: $translate.instant('documents.receipt.not_match_message_title')}
                    )
                    .then(
                        flow.allow,
                        flow.deny
                    );
            })


        })()

    });