/**
 * Created by abichenko on 21.11.17.
 */
angular.module('sinvoice-mobile').controller('issueDateBlockController', function ($scope) {


    $scope.minDate = momentWrapped().startOf('day').subtract(15, 'days');

    (function () {

        if (!angular.isDefined($scope.issueDateModel))
            $scope.issueDateModel = momentWrapped().startOf('day')._d;

    })();
});