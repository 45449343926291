/**
 * Created by dbichenko on 26.12.2016.
 */
angular.module('sinvoice-desktop').controller('issueDateInputBlockController', function ($scope, Events, DocumentInitializer) {


    DocumentInitializer.addInitCallback(function () {

        if (!angular.isDefined($scope.editedDocument.issueDate)) {
            $scope.editedDocument.issueDate = momentWrapped();
        }

        $scope.minIssueDate = momentWrapped().subtract(14, 'days');
        $scope.maxIssueDate = momentWrapped();


        $scope.$watch('editedDocument.issueDate', function (newVal, oldVal) {

            if (newVal === oldVal || !newVal)
                return;

            Events.triggerEvent('issueDate.change', newVal);
        });
    });
});