/**
 * Created by abichenko on 24.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('paymentSummaryBlockController', function ($scope, DocumentSummaryDataProvider) {

        $scope.summaryData = [];

        $scope.summaryPerType = null;

        $scope.$watch(function () {
            return JSON.stringify(DocumentSummaryDataProvider.getPaymentSummaries(
                $scope.templateVariable,
                $scope.taxValue,
                $scope.currency,
                $scope.nisCourse,
                getPaymentSymbol()
            ));
        }, function (value) {
            $scope.summaryData = JSON.parse(value);
        });

        function getPaymentSymbol() {
            return $scope.isNisPayments ? 'NIS' : $scope.currency.name;
        }
    });