/**
 * Created by abichenko on 18.09.17.
 */
angular.module('sinvoice-desktop').controller('exportPopupHeaderController', function ($scope, $state) {

    $scope.goToExportSettings = function ($event) {
        $event.preventDefault();
        $scope.$currentModal.close();
        $state.go('profile.export');

    }
});