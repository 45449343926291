/**
 * Created by dbichenko on 31.10.2016.
 */
angular.module('sinvoice-desktop').controller('discountFormController', function ($scope, DiscountCalculator, Currency, Events, DocumentInitializer) {


    $scope.getDiscountAmount = function () {
        return DiscountCalculator.calculateValue($scope.editedDocument.discounts[0], $scope.template.$itemsTotalBeforeDiscount)
    };


    $scope.toggleUseDiscount = function () {
        if ($scope.template.useDiscount) {
            $scope.editDiscount();

        } else {
            $scope.editedDocument.discounts = [];
            $scope.template.useDiscount     = false;
            $scope.template.showDiscount    = false;
            $scope.editedDiscount.value     = 0;
        }
    };

    $scope.hideShowDiscount = function () {
        $scope.template.showDiscount = $scope.template.showDiscount ? false : true;
    };
    $scope.changeDiscount   = function () {
        $scope.template.useDiscount = true;
        $scope.editedDiscount.value = 0;
    };

    $scope.editDiscount = function () {

        var discount = $scope.editedDocument.discounts[0] || {};

        $scope.editedDiscount        = angular.copy(discount);
        $scope.template.showDiscount = true;
    };

    $scope.saveDiscount = function () {

        var discount = angular.copy($scope.editedDiscount);

        if (!discount.value) {
            $scope.editedDocument.discounts = []
        } else {
            $scope.editedDocument.discounts = [discount];
        }
        $scope.template.showDiscount = false;

    };

    $scope.cancelDiscount = function () {
        $scope.template.showDiscount    = false;
        $scope.template.useDiscount     = false;
        $scope.editedDiscount           = {};
        $scope.editedDocument.discounts = [];
    };


    function convertFixedDiscountAmount(oldVal, newVal) {
        var editedDocument = $scope.editedDocument;
        var discounts      = editedDocument.discounts;
        if (discounts && discounts[0] && discounts[0].type == 'fixed') {
            discounts[0].value = Currency.convert(discounts[0].value, oldVal, newVal, editedDocument.nisCourse);
        }
    }

    function updateDiscountCheckbox() {
        var discount                = $scope.editedDocument.discounts && $scope.editedDocument.discounts[0];
        $scope.template.useDiscount = !!discount;
    }

    DocumentInitializer.addInitCallback(function () {

        $scope.$watchCollection('editedDocument.discounts', function (newval, oldval) {
            if (newval != oldval && newval.length == 0) {
                $scope.cancelDiscount();
            }
        });

        $scope.$watchCollection('editedDocument.discounts', function () {
            $scope.editedDiscount = $scope.editedDocument.discounts[0];
            updateDiscountCheckbox();
        });

        Events.subscribeToEvent('currencies.change', $scope, function (event, newCurrency, oldCurrency) {
            if (newCurrency == oldCurrency || !newCurrency)
                return false;

            convertFixedDiscountAmount(oldCurrency, newCurrency);
        });

        if (!$scope.editedDocument.discounts) {
            $scope.editedDocument.discounts = [];
        }
    });
});