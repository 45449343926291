angular.module('sinvoice-desktop').controller('dashboardController',
    function ($scope,
              $rootScope,
              $timeout,
              DocumentModal,
              CustomerModal,
              API,
              Url,
              Modal,
              $translate,
              dragularService,
              DashboardRestService,
              Events,
              DashboardService) {


        var dragularServices       = [];
        var sliderDragularServices = [];

        $scope.widgetsConfig = {};

        $scope.viewModelWide    = false;
        $scope.allWidgets       = [];
        $scope.itemLeftColumn   = [];
        $scope.itemRightColumn  = [];
        $scope.itemMainColumn   = [];
        $scope.mainBlockVisible = false;
        $scope.dataLoaded       = true;

        // Config slider
        $scope.slickConfig = {
            rtl         : true,
            enabled     : true,
            slidesToShow: 4,
            infinite    : false,
            dots        : true,
            arrows      : true,
            event       : {
                init: initializeDragularSlider
            }
        };

        var sendRequestOnServer = _.debounce(function (widgets) {
            API.put(Url.widgets.updateSettings(), widgets)
        }, 3000);


        (function () {
            loadWidgetsFromServer();


            $scope.$watch('widgetsConfig', function (newVal, oldVal) {

                if (!newVal)
                    return false;

                saveUserOrderWidgets();
                if (newVal.widgets && newVal.widgets.length) {
                    loadPage();
                }

            }, true);

            Events.subscribeToEvent('$translateChangeEnd', $scope, function () {
                loadPage();
            });


            $scope.$on('dragulardrop', function (event, el, target, source, sourceContainer, elementIndex, targetContainer, targetDropIndex) {

                var domTarget = angular.element(target);
                var domSource = angular.element(source);

                var position = null;
                if (domTarget.hasClass('left_column'))
                    position = 'left';
                else if (domTarget.hasClass('right_column'))
                    position = 'right';
                else return;

                if (target == source)
                    targetContainer = sourceContainer;

                targetContainer.forEach(function (value, key) {
                    setWidgetPosition(value.alias, position, key);
                });

            });

        })();


        $scope.createDocument = function () {
            DocumentModal.createDocument();
        };

        $scope.createCustomer = function () {

            CustomerModal.createCustomer();
        };

        function initializeDragularSlider() {

            function canAcceptHandler(el, target, source, sibling) {
                return false;
            }

            sliderDragularServices.forEach(function (value, k) {
                value.destroy();
            });

            sliderDragularServices = [

                dragularService('.slick-track', {
                    containersModel: [$scope.itemMainColumn],
                    revertOnSpill  : true,
                    scope          : $scope,
                    accepts        : canAcceptHandler
                }),

                dragularService('.docs_widgets_container', {
                    containersModel: [$scope.docsWidgets],
                    revertOnSpill  : true,
                    scope          : $scope,
                    accepts        : canAcceptHandler
                }),

                dragularService('.account_widgets_container', {
                    containersModel: [$scope.accountWidgets],
                    revertOnSpill  : true,
                    scope          : $scope,
                    accepts        : canAcceptHandler
                }),

                dragularService('.comminic_widgets_container', {
                    containersModel: [$scope.comminicWidgets],
                    revertOnSpill  : true,
                    scope          : $scope,
                    accepts        : canAcceptHandler
                }),

                dragularService('.income_widgets_container', {
                    containersModel: [$scope.incomeWidgets],
                    revertOnSpill  : true,
                    scope          : $scope,
                    accepts        : canAcceptHandler
                })
            ];


        }

        function initializeDragularColumns() {


            dragularServices.forEach(function (value, k) {
                value.destroy();
            });

            dragularServices = [];

            var mainService = dragularService('.right_column, .left_column', {
                containersModel: [$scope.itemRightColumn, $scope.itemLeftColumn],
                revertOnSpill  : true,
                scope          : $scope,
                accepts        : function (el, target, source) {

                    if (target == source)
                        return true;

                    var element   = angular.element(el);
                    var domTarget = angular.element(target);

                    if (domTarget.hasClass('left_column') && element.attr('data-dragable-to-left') !== 'true')
                        return false;

                    return true;

                }
            });


            dragularServices.push(mainService);

        }


        $scope.toogleShowMainBlock = function (e) {

            e.preventDefault();
            $scope.mainBlockVisible = !$scope.mainBlockVisible;
        };


        // Close widget by click on cross
        $scope.closeWidget = function (e) {

            e.preventDefault();
            var widget = this.item;
            setWidgetPosition(widget.alias, '', 0);

        };

        function setWidgetPosition(alias, position, order) {

            $scope.widgetsConfig.widgets.forEach(function (value) {
                if (alias == value.alias) {
                    value.position = position;
                    value.order    = order;
                }
            })

        }

        $scope.changeViewModelToColumn = function (e) {

            e.preventDefault();

            $scope.widgetsConfig.layout = 'column';

            loadPage();
        };

        $scope.changeViewModelToWide = function (e) {

            e.preventDefault();

            $scope.widgetsConfig.layout = 'wide';
            loadPage();
        };

        // get Widgets from servise

        $scope.setDefaultWidgetsPosition = function (e) {

            e.preventDefault();

            Modal
                .confirm(
                    $translate.instant('control.reset_confirm'),
                    null,
                    null,
                    {title: $translate.instant('control.reset_confirm_title')}
                )
                .then(function () {
                    $scope.widgetsConfig = DashboardService.getDefaultWidgetsPlacement();
                })
        };

        $scope.goToNextSlide = function () {

            $scope.slickConfig.method.slickGoTo(0);

            reloadSlider();
        };


        function reloadSlider() {

            $scope.dataLoaded = false;
            $timeout(function () {
                $scope.dataLoaded = true;
            });

        };

        function createViewVariables() {

            var data    = $scope.widgetsConfig.widgets;
            var widgets = DashboardService.filterWidgetsByColumns(data);

            $scope.viewModelWide   = ($scope.widgetsConfig.layout == 'wide');
            $scope.docsWidgets     = widgets.mainColumnCats.documents;
            $scope.accountWidgets  = widgets.mainColumnCats.account;
            $scope.comminicWidgets = widgets.mainColumnCats.communications;
            $scope.incomeWidgets   = widgets.mainColumnCats.income;

            if ($scope.viewModelWide) {
                $scope.itemRightColumn = widgets.leftColumn.concat(widgets.rightColumn);
                $scope.itemLeftColumn  = [];
            } else {
                $scope.itemRightColumn = widgets.rightColumn;
                $scope.itemLeftColumn  = widgets.leftColumn;
            }

            $scope.itemMainColumn = widgets.mainColumn;

        }


        function loadWidgetsFromServer() {

            DashboardRestService.getWidgets()
                .then(function (data) {

                    $scope.widgetsConfig = DashboardService.getDefaultWidgetsPlacement();
                    if (data && data.widgets && data.widgets.length) {
                        $scope.widgetsConfig.layout = data.layout;
                        mergeByProperty($scope.widgetsConfig.widgets, data.widgets, 'alias');
                    }

                });

            function mergeByProperty(arr1, arr2, prop) {
                _.each(arr2, function (arr2obj) {
                    var arr1obj = _.find(arr1, function (arr1obj) {
                        return arr1obj[prop] === arr2obj[prop];
                    });

                    arr1obj ? _.extend(arr1obj, arr2obj) : arr1.push(arr2obj);
                });
            }

        }

        function loadPage() {

            createViewVariables();
            initializeDragularColumns();
            reloadSlider();
        }

        // Save user order widgets on server
        function saveUserOrderWidgets() {
            sendRequestOnServer($scope.widgetsConfig);
        }

    });
