/**
 * Created by abichenko on 30.10.17.
 */
angular.module('sinvoice-mobile')
    .controller('newDocumentController', function ($scope, $state, UserSubscription, Events) {


        $scope.stepsEnabled = {
            first : true,
            second: false,
            third : false
        };

        $scope.spinner = {enabled: true};

        $scope.editedDocument = {};

        $scope.innerState = 1;


        $scope.switchToStepOne = function () {

            if (!$scope.stepsEnabled.first)
                return;


            $scope.innerState = 1;

        };
        $scope.switchToStepTwo = function (docType) {

            if (docType) {
                $scope.editedDocument      = {};
                $scope.editedDocument.type = docType;
                $scope.stepsEnabled.second = true;
                $scope.stepsEnabled.third  = false;
            }

            if (!$scope.stepsEnabled.second)
                return;

            $scope.innerState = 2;

        };

        $scope.afterSecondStepSave = function () {
            $scope.stepsEnabled.third = true;
            $scope.switchToStepThree();
        };

        $scope.switchToStepThree = function () {

            if (!$scope.stepsEnabled.third)
                return;

            $scope.innerState = 3;
        };

        $scope.afterSave = function () {
            Events.triggerEvent('documents.update');
            $state.go('my-documents');
        };

        (function () {
            UserSubscription
                .checkSubscriptionForDocumentCreation()
                .then(null, function (data) {
                    $state.go('my-documents-customers');
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        })();

    });