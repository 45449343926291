/**
 * Created by abichenko on 23.11.17.
 */
angular.module('sinvoice-mobile')
    .directive('paymentTypeSection', function () {


        return {

            scope     : {
                title   : '=',
                isOpened: '=?'
            },
            template  : '' +
            '<div class="payment-type-section">' +
            '   <div class="document-row-title title" ng-click="isOpened = !isOpened"><b>{{title}}</b>' +
            '       <div class="pull-left">' +
            '           <i ng-class="{\'fa-caret-down\':!isOpened,\'fa-caret-up\':isOpened}" class="fa"></i>' +
            '       </div>' +
            '   </div>' +
            '   <div class="section-content" ng-transclude="" ng-show="isOpened"></div>' +
            '</div>',
            transclude: true,
            link      : function (scope, element, attrs) {
            }
        }
    });