/**
 * Created by abichenko on 17.11.17.
 */
angular.module('sinvoice-mobile').directive('newDocumentStepOneBlock', function () {


    return {
        scope      : {
            onTypeSelect  : '='
        },
        controller : 'mobileNewDocumentStepOneController',
        templateUrl: './mobile/components/new-document/new-document-step-one.html',
        link       : function (scope, element, attrs) {

        }
    }
})