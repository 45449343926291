/**
 * Created by abichenko on 22.11.17.
 */
angular.module('sinvoice-mobile')
    .directive('summaryBlock', function () {

        return {
            scope      : {
                editedDocument  : '=',
                templateVariable: '=',
            },
            controller : 'summaryBlockController',
            templateUrl: './mobile/components/new-document/partials/summary-block.html',
            link       : function () {

            }
        }
    });
