angular.module('sinvoice-desktop').controller('resetPassController', function ($scope, $state, ResetPass, $stateParams, $filter, $timeout) {

    $scope.resetPassForm = {};

    $scope.token    = '';
    $scope.password = '';
    $scope.spinner  = {enabled: true};

    $scope.isChanged = false;

    (function () {

        if (!$stateParams.token) {
            $state.go("login");
            return;
        }

        ResetPass.validateToken($stateParams.token)
            .then(function (data) {
                $scope.token = $stateParams.token;
            }, function () {
                $state.go('login');
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })

    })();


    $scope.resetPass = function () {

        if ($filter('isSubmitNotAllowed')($scope.resetPassForm)) {
            return;
        }

        $scope.spinner.enabled = true;

        ResetPass
            .changePass($scope.token, $scope.password)
            .then(function () {
                $scope.isChanged = true;
                $timeout(function () {
                    $state.go('login');
                }, 5000)
            })
            .finally(function () {
                $scope.spinner.enabled = false;

            })
    };

});

