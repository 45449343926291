/**
 * Created by abichenko on 21.11.17.
 */
angular.module('sinvoice-common')
    .factory('ParagraphTotalCalculator', function (DocumentTaxCalculator) {

        return {
            calculateTotals            : function (paragraph) {


                var withVat    = 0.0;
                var withoutVat = 0.0;

                var unitPrice = parseFloat(paragraph.unitPrice || 0);
                var quantity  = parseFloat(paragraph.quantity || 0);
                if (paragraph.displayWithVat) {
                    withVat    = DocumentTaxCalculator.calculateAmountWithTax(unitPrice);
                    withoutVat = unitPrice;
                } else {
                    withVat    = unitPrice;
                    withoutVat = unitPrice;
                }

                paragraph.unitPriceVAT = parseFloat(withVat);
                paragraph.total        = parseFloat(withoutVat * quantity);
                paragraph.totalVat     = parseFloat(withVat * quantity);

            },
            calculateUnitPriceFromTotal       : function (paragraph) {

                var total    = parseFloat(paragraph.total || 0);
                var quantity = parseFloat(paragraph.quantity || 0);
                var totalVat = 0.0;

                if (paragraph.displayWithVat) {
                    totalVat = DocumentTaxCalculator.calculateAmountWithTax(total);
                } else {
                    totalVat = total;
                }

                paragraph.totalVat     = parseFloat(totalVat);
                paragraph.unitPrice    = parseFloat(total / quantity);
                paragraph.unitPriceVAT = parseFloat(totalVat / quantity);

            },
            calculateUnitPriceFromTotalWithVat: function (paragraph) {

                var total    = 0.0;
                var totalVat = parseFloat(paragraph.totalVat || 0);
                var quantity = parseFloat(paragraph.quantity || 0);

                if (paragraph.displayWithVat) {
                    total = DocumentTaxCalculator.calculateAmountWithoutTax(totalVat)
                } else {
                    total = totalVat;
                }

                paragraph.total        = parseFloat(total);
                paragraph.unitPrice    = parseFloat(total / quantity);
                paragraph.unitPriceVAT = parseFloat(totalVat / quantity);

            }
        }

    });