/**
 * Created by dbichenko on 23.09.2016.
 */
angular.module('sinvoice-desktop').controller('headerBlockController',
    function ($scope,
              DocTypeCommentModal,
              Document,
              $translate,
              Language,
              $rootScope,
              Modal,
              Events,
              AuthDataStorage,
              TemplateChecker,
              EditedValueComparator,
              DocumentModal,
              DesktopModal,
              promiseHelper,
              DocumentInitializer,
              DocumentTypeConstants,
              ModalContainer,
              $timeout,
              ProfileChecker) {

        var originalRelatedDocs = angular.copy($scope.editedDocument.relatedDocs);

        var backup;


        $scope.editInternalComment = function () {
            DocTypeCommentModal.editComment($scope.editedDocument.type);
        };

        $scope.closeModal = function (e) {


            var isPopupNeedToBeShown = false;

            if (EditedValueComparator.isObjectChangedExceptOfProps(backup, $scope.editedDocument, ['relatedDocs'])) {
                isPopupNeedToBeShown = true;
            }

            if (TemplateChecker.isRetrotractiveDocsRelatingAllowed($scope)) {
                if (!_.isEqualWith(originalRelatedDocs, $scope.editedDocument.relatedDocs)) {
                    isPopupNeedToBeShown = true;
                }
            }

            if (isPopupNeedToBeShown) {
                DesktopModal.unsavedChanges(DocumentModal.closeAll);
            } else {
                DocumentModal.closeAll();
            }
        };

        $scope.onCurrencySelect = function (oldCurrencyId, newCurrency) {

            // There is no other way to obtain previous value via ng-change
            var oldCurrency = $scope.currencies.filter(function (currency) {
                return currency.id == oldCurrencyId;
            }).pop();

            if ($scope.editedDocument.paragraphs &&
                $scope.editedDocument.paragraphs.length) {
                return Modal
                    .confirm(
                        $translate.instant('documents.changed_currency_confirm'),
                        null,
                        null,
                        {title: $translate.instant('documents.changed_currency_confirm_title'), }
                    )
                    .then(
                        function () {
                            Events.triggerEvent('currencies.change', newCurrency, oldCurrency);
                            $scope.editedDocument.paragraphs = [];
                        },
                        function () {
                            $scope.editedDocument.currencies = oldCurrency;
                        })
            } else {

                Events.triggerEvent('currencies.change', newCurrency, oldCurrency);
                return promiseHelper.resolvedPromise();
            }

        };

        $scope.getFilteredLanguages = function () {
            return $scope.languages.filter(function (lang) {
                if (lang == 'en') {
                    return $scope.isEnglishLanguageEnabled();
                } else
                    return true;
            })
        };

        $scope.getFilteredCurrencies = function () {
            return $scope.currencies.filter(function (currency) {
                if (currency.name !== 'NIS') {
                    return $scope.isEnglishLanguageEnabled();
                } else {
                    return true;
                }
            });
        };

        $scope.isEnglishLanguageEnabled = function () {
            return ProfileChecker.isEnglishAvailable(AuthDataStorage.getProfileBusinesses())
                || $scope.editedDocument.language === 'en';
        };


        function loadLanguages() {
            return Language.getData().then(function (data) {
                $scope.languages = data;
            })

        }

        DocumentInitializer.addAfterInitCallback(function () {
            $timeout(function () {
                backup = angular.copy($scope.editedDocument);
            })
        });

        DocumentInitializer.addInitCallback(function () {

            $scope.languages = [];

            $scope.isLongTitle = Document.isCreditDoc($scope.editedDocument.type.docType)
                || $scope.editedDocument.type.docType === DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT;

            Events.subscribeToEvent('doctypes.update', $scope, function () {
                Document.getDocTypes().then(function (data) {
                    var selectedDocType        = $scope.editedDocument.type.docType;
                    $scope.editedDocument.type = data[selectedDocType]
                });
            });

            return loadLanguages().then(function () {

                var editedDocument = $scope.editedDocument;
                var customer       = editedDocument.customer;

                if (!editedDocument.language) {
                    var defaultLanguage;

                    defaultLanguage = customer && customer.language
                        ? customer.language
                        : $scope.getFilteredLanguages()[0];

                    if (defaultLanguage === 'en' && !$scope.isEnglishLanguageEnabled()) {
                        defaultLanguage = $scope.getFilteredLanguages();
                    }

                    editedDocument.language = defaultLanguage;
                }

                $scope.$watch('editedDocument.customer', function () {
                    if (editedDocument.language && !_.includes($scope.getFilteredLanguages(), editedDocument.language)) {
                        editedDocument.language = $scope.getFilteredLanguages()[0];
                    }

                    var filteredCurrencies = $scope
                        .getFilteredCurrencies()
                        .map(function (currency) {
                            return currency.id;
                        });

                    if (editedDocument.currencies && !_.includes(filteredCurrencies, editedDocument.currencies.id)) {
                        var defaultCurrency       = $scope.getFilteredCurrencies()[0];
                        editedDocument.currencies = defaultCurrency;
                    }
                });
            });
        });


    });