/**
 * Created by abichenko on 28.11.17.
 */
angular.module('sinvoice-common').controller('balanceAdjustmentController', function ($scope, BusinessChecker, AuthDataStorage, $translate) {


    if (BusinessChecker.isExemptDealer(AuthDataStorage.getProfile().businessType)) {
        $scope.invoicesAdjustmentAllow = true
    } else {
        $scope.invoicesAdjustmentAllow = false
    }


    if (!$scope.editedDocument.title)
        $scope.editedDocument.title = $translate.instant('documents.balance_adjustment.default_title');

});