/**
 * Created by dbichenko on 23.11.2016.
 */
angular.module('sinvoice-desktop').controller('taxInvoiceReceiptCreditController',
    function ($scope,
              Modal,
              $translate,
              FormHelper,
              dateHelper,
              Events,
              Currency,
              TemplateChecker,
              DocumentTypeConstants,
              DocumentModalCallbackContainer,
              DocumentModalCallbackChecker,
              CustomerTypeChecker,
              DocumentModalCallbackProvider) {

        $scope.template.$reliableTypesOfOpenDocuments      = [DocumentTypeConstants.TYPE_RETURN_FORM];
        $scope.template.$reliableTypesOfAnyStatusDocuments = [DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT];
        $scope.template.$oneSideRelationDocTypes           = [DocumentTypeConstants.TYPE_RETURN_FORM];
        $scope.template.itemsRequired                      = true;


        $scope.template.$isOccasionalDocsRelatingAllowed = true;

        Events.subscribeToEvent('relatedDocs.related', $scope, function (e, documents) {

            if (!$scope.template.isEditable)
                return;

            var docNumberString = documents.map(function (document) {
                return $translate.instant('documents.credit_documents_title_document_label', {
                    'docNumber': document.docNumber
                });
            }).join(', ');

            $scope.editedDocument.title = docNumberString
                ? $translate.instant('documents.credit_documents_title_placeholder', {docNumberString: docNumberString})
                : '';
        });


        (function () {

            DocumentModalCallbackContainer.addPreSaveCallback(
                DocumentModalCallbackProvider.creditDocumentOccasionalCustomerWarning($scope.editedDocument)
            );

            Events.subscribeToEvent('paymentForms.update', $scope, function (e, value) {

                $.each(value, (function (key, forms) {
                    $.each(forms, (function (key, form) {
                        if (!form)
                            return;
                        FormHelper.addValidator(form.amount, 'required', function (value) {
                            return !!value && value > 0;
                        })

                    }))
                }))
            })

            DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                var editedDocument  = $scope.editedDocument;
                var paymentErrors   = $scope.template.payments.errors;
                var paragraphErrors = $scope.template.paragraphs.errors;

                paymentErrors.required   = false;
                paragraphErrors.required = false;

                if (!editedDocument.paragraphs.length) {
                    flow.deny();
                    paragraphErrors.required = true;
                    return false;
                }

                if (!editedDocument.payments.length) {
                    flow.deny();
                    paymentErrors.required = true;
                    return false;
                }

                flow.allow();
            });


            DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {


                if (!DocumentModalCallbackChecker.isAmountDoesNotMatchPopupNeedToBeShown(
                        $scope.editedDocument,
                        $scope.template.$paymentsTotalWithVatNIS,
                        $scope.template.$finalItemsTotalWithVatNIS
                    ))
                    return flow.allow();

                Modal.alert(
                    $translate.instant('documents.tax_invoice_receipt_credit.not_match_message'),
                    {title: $translate.instant('documents.tax_invoice_receipt_credit.not_match_message_title')}
                )

            });

            DocumentModalCallbackContainer.addPreSaveCallback(
                DocumentModalCallbackProvider.occasionalCustomerInfoMissing($scope.editedDocument, $scope.template, $scope.documentForm)
            );


        })();
    });