/**
 * Created by Front-end on 29.12.2016.
 */
angular.module('sinvoice-desktop').controller('detailsContactsController',
    function ($scope, Modal, $filter, Events, Customer, DetailsBackup, DesktopModal, $timeout, ProfileChecker, AuthDataStorage) {

        $scope.contactForm   = {};
        $scope.editedContact = null;


        $scope.isEnglishAvailable = function () {
            return ProfileChecker.isEnglishAvailable(AuthDataStorage.retrieveStoredUser().profile.businesses);
        };


        $scope.editContact = function (contact) {
            $scope.editedContact = {
                id    : contact.id,
                fname : contact.fname,
                lname : contact.lname,
                phones: contact.phones ? JSON.parse(JSON.stringify(contact.phones)) : [],
                faxes : contact.faxes ? JSON.parse(JSON.stringify(contact.faxes)) : [],
                emails: contact.emails ? JSON.parse(JSON.stringify(contact.emails)) : []
            };
            DetailsBackup.registerTrackedSection('contact', angular.copy($scope.editedContact), $scope.editedContact);
        };

        $scope.deleteContact = function (contact) {
            Modal
                .confirm(
                    $filter('translate')('customers.edit.details.contacts.delete_confirm'),
                    null,
                    null,
                    {title: $filter('translate')('customers.edit.details.contacts.delete_confirm_title')}
                )
                .then(function () {
                    return Customer.deleteContact(contact.id)
                })
                .then(function (data) {
                    $scope.edited.customer.contacts = $scope.edited.customer.contacts.filter(function (currentContact) {
                        return currentContact != contact;
                    });
                    reload();
                });
        };

        $scope.saveContact = function () {
            if ($scope.editedContact.id) {
                Customer
                    .updateContact($scope.editedContact.id, $scope.editedContact)
                    .then(function (data) {
                        for (var i = 0; i < $scope.edited.customer.contacts.length; i++) {
                            if ($scope.edited.customer.contacts[i].id == $scope.editedContact.id) {
                                $scope.edited.customer.contacts[i] = data;
                            }
                        }
                        doCancelContact();
                        reload();
                    });
            } else {
                $scope.editedContact.customer_id = $scope.edited.customer.id;
                Customer
                    .createContact($scope.editedContact)
                    .then(function (data) {
                        $scope.edited.customer.contacts.push(data);
                        doCancelContact();
                        reload();
                    })
            }
        };

        $scope.addContact = function () {
            $scope.editedContact = {
                faxes : [],
                emails: [],
                phones: []
            };

            DetailsBackup.registerTrackedSection('contact', angular.copy($scope.editedContact), $scope.editedContact);

        };

        $scope.cancelContact = function () {
            if (DetailsBackup.isChanged('contact')) {
                DesktopModal
                    .unsavedChanges()
                    .then(function () {
                        $timeout(doCancelContact);
                    });
            } else {
                return doCancelContact();
            }
        };

        $scope.addNewField = function (array) {
            array.push('');
        };

        $scope.removeField = function (array, index) {
            array.splice(index, 1);
        };

        function doCancelContact() {
            $scope.editedContact = null;
            $scope.contactForm.$setPristine();
            DetailsBackup.unregisterTrackedSection('contact');
        }

        function reload() {
            Events.triggerEvent('customer.update');
        }


    });