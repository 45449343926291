/**
 * Created by dbichenko on 28.02.2017.
 */
angular.module('sinvoice-desktop').controller('profilePageFooterController', function ($scope, $timeout, promiseHelper) {

    $scope.showResponseMessage = 0;
    $scope.state               = 'edit';

    $scope.cancel = function () {
        $scope.state = 'edit';
        $scope.onCancel();
    };

    $scope.save = function () {


        var validatePromise;

        var result = $scope.isSaveAllowed();

        if (angular.isDefined(result.then)) {
            validatePromise = result;
        } else if (!angular.isFunction(result)) {
            validatePromise = promiseHelper.resolvedPromise(result);
        } else {
            validatePromise = promiseHelper.resolvedPromise(result);
        }

        validatePromise.then(function (result) {

            if (!result)
                return;

            $timeout(function () {
                if ($scope.state === 'edit') {
                    $scope.state = 'confirm';
                } else {
                    $scope.state = 'edit';

                    var onSavePromise = $scope.onSave();

                    if (!onSavePromise.then) {
                        throw Error('onSave should provide promise object');
                    }

                    onSavePromise
                        .then(
                            function () {
                                $scope.showResponseMessage = 1;
                                $timeout(function () {
                                    $scope.showResponseMessage = 0;
                                }, 3000);

                            },
                            function () {
                                $scope.showResponseMessage = 2;
                            })
                }
            })
        })

    }

});
