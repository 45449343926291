/**
 * Created by abichenko on 19.10.17.
 */
angular.module('sinvoice-desktop')
    .filter('translateToolLabel', function ($filter) {

        return function (value) {

            if (!value)
                return '';

            return $filter('translate')('customers.managed_customer.add_managed_customer_poppup.tools.' + value)
        }

    })
    .filter('translateRemindOptionLabel', function ($filter) {

        return function (value) {

            if (!value)
                return '';

            return $filter('translate')('customers.managed_customer.add_managed_customer_poppup.reminder_title.' + value)
        }

    });