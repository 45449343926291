/**
 * Created by abichenko on 29.03.18.
 */
angular.module('sinvoice-mobile')
    .factory('PaymentBlockModal', function (MobileModal) {

        function selectNewPaymentType(onSave, isEnglishLanguageEnabled, currency, isNisPayments, nisCourse, taxValue, templateVariable, customer) {
            MobileModal.overlay('./mobile/components/new-document/partials/popups/select-new-payment-type-popup.html', {
                scopeVariables: {
                    onSave           : onSave,
                    documentVariables: {
                        isEnglishLanguageEnabled: isEnglishLanguageEnabled,
                        currency                : currency,
                        isNisPayments           : isNisPayments,
                        nisCourse               : nisCourse,
                        taxValue                : taxValue,
                        templateVariable        : templateVariable,
                        customer                : customer
                    }
                }
            })
        }

        function editPayment(payment, onSave, docVariables) {
            var scopeVariables = _.extend(docVariables, {payment: payment});
            displayPaymentPopup(payment.type, onSave, scopeVariables);
        }


        function addPayment(type, onSave, docVariables) {
            displayPaymentPopup(type, onSave, docVariables);
        }

        function displayPaymentPopup(type, onSave, docVariables) {
            var scopeVariables = _.extend({type: type, onSave: onSave}, docVariables);

            MobileModal.overlay('./mobile/components/new-document/partials/popups/edit-payment-popup.html', {
                scopeVariables: scopeVariables
            });
        }

        return {
            selectNewPaymentType: selectNewPaymentType,
            addPayment          : addPayment,
            editPayment         : editPayment
        }

    });