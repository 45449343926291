/**
 * Created by abichenko on 17.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('mobileCustomerBlockController',
        function ($scope, Customer, DocumentCustomerProvider, $filter, Events, CustomerTypeChecker) {

            var occasionalCustomer = null;
            var vm                 = this;

            vm.customerForm                 = null;
            vm.isOccasionalCustomerSelected = false;
            vm.spinner                      = {
                main        : true,
                customerForm: false
            };

            vm.addCustomer = function () {
                vm.customerForm = {};
            };

            vm.editCustomer = function (customer) {
                vm.customerForm = angular.copy(customer);
            };

            vm.changeCustomer = function (newCustomer) {
                vm.selectedCustomer = newCustomer;
                Events.triggerEvent('customer.change', vm.selectedCustomer);
            };

            vm.saveCustomer = function () {

                if (vm.customerForm.id) {
                    vm.spinner.customerForm = true;
                    Customer
                        .update(vm.customerForm.id, vm.customerForm)
                        .then(function () {
                            vm.cancelEditing();
                            loadData();
                        })
                        .finally(function () {
                            vm.spinner.customerForm = false;
                        });
                } else {
                    vm.spinner.customerForm = true;
                    Customer
                        .create(vm.customerForm)
                        .then(function (data) {
                            vm.selectedCustomer = data;
                            vm.cancelEditing();
                            Events.triggerEvent('customer.change', vm.selectedCustomer);
                            loadData();
                        })
                        .finally(function () {
                            vm.spinner.customerForm = false;
                        });
                }
            };

            vm.toggleUseExistingCustomer = function () {
                if (vm.isOccasionalCustomerSelected) {
                    vm.selectedCustomer = occasionalCustomer;
                    vm.cancelEditing();
                } else if (vm.customers.length) {
                    vm.selectedCustomer = vm.customers[0];
                }
                Events.triggerEvent('customer.change', vm.selectedCustomer);
            };


            vm.cancelEditing = function () {
                vm.customerForm = null;
            };

            function loadData() {
                return Customer.getData().then(function (data) {
                    vm.customers       = DocumentCustomerProvider.filterWithDocumentSupport(data);
                    occasionalCustomer = DocumentCustomerProvider.filterOccasionalCustomer(data);
                });

            }

            (function () {

                loadData()
                    .then(function () {


                        if (vm.selectedCustomer) {
                            var occasional = CustomerTypeChecker.isOccasional(vm.selectedCustomer.customerType);

                            if (vm.isOccasionalCustomerEnabled && occasional) {
                                vm.isOccasionalCustomerSelected = true;
                            } else if (!vm.isOccasionalCustomerEnabled && occasional) {
                                vm.selectedCustomer = null;
                            }
                        }

                        if (!vm.customerOccasionalName)
                            vm.customerOccasionalName = $filter('translate')('documents.use_occuring');

                    })
                    .finally(function () {
                        vm.spinner.main = false;
                    });


            })()
        });