/**
 * Created by abichenko on 14.03.18.
 */
angular.module('sinvoice-desktop').directive('businessAccount', function (BankChecker) {


        return {
            scope      : {
                account           : '=',
                parentForm        : '=?',
                formIndex         : '=?',
                isEnglishAvailable: '=?',
                onRemove          : '&',
                banks             : '='
            },
            controller : function ($scope) {
                $scope.isCustomBank = function (bank) {
                    return BankChecker.isCustomBank(bank);
                }
            },
            templateUrl: './desktop/components/profile/partials/business-account.html',
            link       : function (scope) {

            }
        }
    }
);