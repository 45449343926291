/**
 * Created by Front-end on 16.12.2016.
 */
/**
 * Created by dbichenko on 29.09.2016.
 */
angular.module('sinvoice-desktop').controller('createNewCutomerPopupController', function ($scope, Customer, $filter) {

    $scope.createCustomerModalForm = {};

    $scope.getMessage = function () {

        var translateKey;
        if ($scope.triggeredBy === 'taxValue') {
            translateKey = 'documents.create_customer_popup.body_tax_value';
        } else {
            translateKey = 'documents.create_customer_popup.body';
        }

        return $filter('documentPopupTranslate')(translateKey, $scope.docType.docType);
    };

    $scope.save = function () {
        if ($scope.createCustomerModalForm.$invalid) {
            return false;
        }

        Customer.create($scope.customer).then(function (data) {
            $scope.customer = data;
            $scope.$currentModal.close();
            $scope.onSave($scope.customer);
            $scope.flow.allow();
        })

    };

    $scope.cancel = function () {
        $scope.flow.deny();
        $scope.$currentModal.close();
    };

    (function () {


        if (!$scope.flow) {
            throw new Error('flow is not defined')
        }

        if (!$scope.customer)
            $scope.customer = {};


    })();

    function initializeValidators() {

        $scope.validators = {}
    }

    initializeValidators();


});