/**
 * Created by abichenko on 01.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('filterBlockController', function ($scope, DocumentFiltersValues, $filter) {

        $scope.availableDocTypes  = [];
        $scope.availableCustomers = [];
        $scope.availableStatuses  = [];


        $scope.selected = {
            docType : [],
            customer: [],
            statuses: {},
            amount  : {}
        };

        $scope.isClearButtonShown = function () {
            return isFilterEnabled();
        };

        $scope.close = function () {
            $scope.isOpened = false;
        };

        $scope.finish = function () {
            $scope.onFinish(getFilteredDocuments(), isFilterEnabled());
            $scope.isOpened = false;
        };

        $scope.clearFilter = function (filterName) {
            $scope.selected[filterName] = angular.copy($scope.backup[filterName]);
        };

        $scope.clear = function () {
            $scope.selected = angular.copy($scope.backup);
        };

        $scope.getSelectedStatusCount = function () {
            return getSelectedStatusCount();
        }

        $scope.$watchCollection('documents', updateDocTypes);

        $scope.$watchCollection('customers', updateCustomers);


        function getFilteredDocuments() {

            var filteredDocuments = $scope.documents

                .filter(function (document) {

                    var criterias = [];


                    if (parseFloat($scope.selected.amount.from)) {

                        var fromCriteria = parseFloat(document.displayedTotalNIS) >= parseFloat($scope.selected.amount.from);
                        criterias.push(fromCriteria);
                    }
                    if (parseFloat($scope.selected.amount.to)) {
                        var toCriteria = parseFloat(document.displayedTotalNIS) <= parseFloat($scope.selected.amount.to);
                        criterias.push(toCriteria);
                    }


                    if (!criterias.length) {
                        return true;
                    } else {
                        var failedChecks = criterias.filter(function (criteria) {
                            return criteria === false;
                        }).length;

                        return !failedChecks;
                    }
                })

                .filter(function (document) {

                    var allowedStatuses = [];

                    if (!$scope.selected.statuses)
                        return true;

                    $.each($scope.selected.statuses, function (key, value) {
                        if (value)
                            allowedStatuses.push(parseInt(key));
                    });

                    if (!allowedStatuses.length)
                        return true;

                    return _.includes(allowedStatuses, document.status);

                })

                .filter(function (document) {

                    if (!$scope.selected.customer.length) {
                        return true;
                    }

                    var selectedCustomers = $scope.selected.customer.map(function (customer) {
                        return parseInt(customer.value);
                    });


                    return _.includes(selectedCustomers, document.customer.id);
                })

                .filter(function (document) {

                    if (!$scope.selected.docType.length) {
                        return true;
                    }

                    var selectedDocTypes = $scope.selected.docType.map(function (docType) {
                        return docType.value;
                    });

                    return _.includes(selectedDocTypes, document.type.docType);
                });


            return filteredDocuments;
        }

        function isFilterEnabled() {

            if ($scope.selected.docType.length)
                return true;

            if ($scope.selected.customer.length)
                return true;

            if (getSelectedStatusCount())
                return true;

            if ($scope.selected.amount.from || $scope.selected.amount.to)
                return true;

            return false;
        }

        function updateDocTypes() {
            $scope.availableDocTypes = DocumentFiltersValues
                .getTypeFilterValues($scope.documents, 'docType')
                .filter(function (type) {
                    return !!type.value
                });
        }


        function updateCustomers() {
            $scope.availableCustomers = DocumentFiltersValues.getCustomerFilterValues($scope.documents, 'id');
        }

        function getSelectedStatusCount() {
            var selectedCount = 0;

            $.each($scope.selected.statuses, function (key, value) {
                if (value)
                    selectedCount++;
            });
            return selectedCount;
        }

        (function () {
            $scope.availableStatuses = DocumentFiltersValues.getStatuses().filter(function (status) {
                return status.value !== '';
            });

            $scope.backup = angular.copy($scope.selected);
        })();
    });