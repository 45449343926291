angular.module('sinvoice-desktop').controller('customerDocumentsController',
    function ($scope,
              DTOptionsBuilder,
              DTColumnBuilder,
              DataTableBootstrapSettings,
              $translate,
              Customer,
              $filter,
              monthsFromToday,
              $compile,
              DataTableLanguageSettings,
              $q,
              Document,
              DocumentExport,
              DocumentModal,
              DocumentConstants,
              DocumentFiltersValues,
              DataTableFunctions,
              $state,
              AuthDataStorage,
              DataTableDatepickerLabels) {


        $scope.dtInstances     = {};
        $scope.spinner         = {};
        $scope.charts          = {};
        $scope.charts.brief    = {};
        $scope.charts.income   = {};
        $scope.charts.received = {};

        $scope.spinner         = {};
        $scope.spinner.enabled = true;
        $scope.activeTab       = {'all': true};

        $scope.businessType = AuthDataStorage.getProfile().businessType.type;

        $scope.produceReport = function () {


            var selectedInstance = $scope.dtInstances[getActiveTab()];

            var params = {
                'customers': $scope.edited.customer.id
            };

            if (selectedInstance) {

                params.docTypes = DataTableFunctions
                    .getSelectedData(selectedInstance.DataTable)
                    .map(function (document) {
                        return document.type.docType;
                    })
                    .toString();
            }

            $state.go('reports.incomeanddebts', params);

            $scope.$currentModal.close();
        };

        $scope.openNewDocumentForm = function () {
            DocumentModal.createDocumentForCustomer($scope.edited.customer);
        };


        (function () {
            loadTable();
            loadCharts();
            DocumentModal.subscribeToUpdate($scope, function () {
                reloadDataTables();
                loadCharts();
            });
        })();

        function getActiveTab() {
            var active = null;

            $.each($scope.activeTab, function (key, val) {

                if (val)
                    active = key;
            });
            return active;
        }


        function getFilteredData(docType, status) {

            var defer = $q.defer();

            var criteria = {};

            var documents = angular.copy($scope.documents);

            if (angular.isArray(docType)) {
                documents = documents.filter(function (document) {
                    return _.includes(docType, document.type.docType);
                })
            } else {
                criteria.type = {docType: docType};
            }

            if (typeof  status != 'undefined')
                criteria.status = status;

            var data = Object.keys(criteria).length
                ? $filter('filter')(documents, criteria, true)
                : documents;

            defer.resolve(data);

            return defer.promise;
        }

        function loadCustomerDocuments() {
            return Customer.getRelatedDocuments($scope.edited.customer.id).then(function (data) {
                $scope.documents = data;
            });
        }


        function loadCharts() {

            var months = $filter('translate')('global.monthNames').split(',').reverse();

            $scope.activeChart                = 'income';
            $scope.charts.income.categories   = months;
            $scope.charts.received.categories = months;

            Customer.getStats($scope.edited.customer.id).then(function (data) {
                $scope.charts.brief.data = data;
            });

            Customer.getIncome($scope.edited.customer.id).then(function (data) {
                $scope.charts.income.data = data
            });

            Customer.getReceived($scope.edited.customer.id).then(function (data) {
                $scope.charts.received.data = data
            });

        }


        function loadTable() {

            loadCustomerDocuments().then(function () {

                $scope.$broadcast('documents.loaded', getFilteredData, initializeDataTableColumns, initializeDataTable);
                $scope.spinner.enabled = false;

            });

        };


        function initializeDataTableColumns(showStatusColumn, showCustomerNameColumn) {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('select')
                    .withTitle('<dt-select-all-checkbox></dt-select-all-checkbox>')
                    .notSortable()
                    .withOption('width', '52px')
                    .withClass('select-checkbox ignored-on-export')
                    .renderWith(function (data) {
                        return '<dt-select-checkbox class="check-area"></dt-select-checkbox>';
                    }),

                DTColumnBuilder
                    .newColumn('status')
                    .withClass('status-box')
                    .withOption('width', '60px')
                    .withTitle($translate.instant('customers.edit.documents.table.status'))
                    .renderWith(function (data) {
                        return '<i class="status-state">' + data + '</i>'
                    }),


                DTColumnBuilder
                    .newColumn('docNumber')
                    .withTitle($translate.instant('customers.edit.documents.table.doc_number'))
                    .renderWith(function (data) {
                        return '<span class="num-link">' + data + '</span>';
                    }),

                DTColumnBuilder
                    .newColumn('doc_type_label')
                    .withTitle($translate.instant('customers.edit.documents.table.doc_type_label')),

                DTColumnBuilder
                    .newColumn('issueDate')
                    .withClass('date_col_small')
                    .withTitle($translate.instant('customers.edit.documents.table.issue_date'))
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),


                DTColumnBuilder
                    .newColumn('customerOccasionalName')
                    .withTitle($translate.instant('customers.edit.documents.table.customer')),

                DTColumnBuilder
                    .newColumn('title')
                    .withClass('title_col')
                    .withTitle($translate.instant('customers.edit.documents.table.title')),

                DTColumnBuilder
                    .newColumn('amount')
                    .withTitle($translate.instant('customers.edit.documents.table.total_with_vat'))
                    .withOption('createdCell', function (td, cellData, rowData, row, col) {
                            var cell = $(td);
                            if (!Document.isCreditDoc(rowData.type.docType) && !Document.isBalanceAdjustment(rowData.type.docType))
                                cell.addClass('green-text');
                            else {
                                cell.addClass('red-text')
                            }
                        }
                    )
                    .withOption('render', {
                        "sort"   : function (data, type, fullData) {
                            return Number(fullData.displayedTotalNIS);
                        },
                        "filter" : function (data, type, fullData) {
                            return Number(fullData.displayedTotalNIS);
                        },
                        "display": function (data, type, fullData) {
                            return '<cut-value>' + $filter('number')(fullData.displayedTotalNIS, 2) + '</cut-value>'
                        }
                    }),

                DTColumnBuilder
                    .newColumn('balance')
                    .withTitle($translate.instant('documents.table.balance'))
                    .withOption('createdCell', function (td, cellData, rowData, row, col) {
                            var cell = $(td);
                            if (!Document.isCreditDoc(rowData.type.docType) && !Document.isBalanceAdjustment(rowData.type.docType))
                                cell.addClass('green-text');
                            else {
                                cell.addClass('red-text')
                            }
                        }
                    )
                    .withOption('render', {
                        "sort"   : function (data, type, fullData) {
                            return Number(fullData.remainingBalanceNIS);
                        },
                        "filter" : function (data, type, fullData) {
                            return Number(fullData.remainingBalanceNIS);
                        },
                        "display": function (data, type, fullData) {
                            var value;
                            if (angular.isNumber(parseFloat(fullData.remainingBalanceNIS) == 0) || fullData.remainingBalanceNIS == null)
                                value = 0;
                            else
                                value = fullData.remainingBalanceNIS;

                            return '<cut-value>' + $filter('number')(value, 2) + '</cut-value>'
                        }
                    }),
                DTColumnBuilder
                    .newColumn('actions')
                    .withClass('text-center ignored-on-export')
                    .withOption('width', '12%')
                    .withTitle($translate.instant('customers.edit.documents.table.actions'))
                    .renderWith(function (data, type, row) {
                        return $scope.edited.customer.customerType.isDocumentEditingAvailable
                            ? '' +
                            '<span class="button-wrapper">' +
                            '   <button subscription-checked-button class="duplicate static"></button>' +
                            '   <button class="print_row static"></button>' +
                            '</span>'
                            : '' +
                            '<button class="print_row static"></button>'
                    })
                    .notSortable()

            ];

            var excludedColumns = [];

            if (!showStatusColumn)
                excludedColumns.push(1);

            if (!showCustomerNameColumn)
                excludedColumns.push(5);

            dtColumns = dtColumns.filter(function (val, index) {
                return !_.includes(excludedColumns, index);
            });

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;
        }


        function initializeDataTable(promise, buttonContainer, showStatusColumn, showCustomerNameColumn) {

            var filterValues = {
                types: []
            };

            function promiseCallback() {
                return promise().then(function (documents) {
                    loadFilters(documents, filterValues);
                    return documents
                })
            }

            function loadFilters(existingDocuments, filterValues) {

                // We cannot just replace filterValues with empty array because reference will be lost
                // So we should cleanup original one

                $.each(filterValues, function (key, value) {
                    value.splice(0, value.length);
                });

                DocumentFiltersValues
                    .getTypeFilterValues(existingDocuments)
                    .forEach(function (val) {
                        filterValues.types.push(val);
                    });

            }

            var aoColumns = [
                null,
                {
                    type  : 'select',
                    bRegex: false,
                    bSmart: false,
                    values: DocumentFiltersValues.getStatuses()
                },

                {type: 'text', bRegex: true, bSmart: true},
                {
                    type  : 'select',
                    bRegex: false,
                    bSmart: false,
                    values: filterValues.types
                },
                {
                    type           : 'date-range',
                    bRegex         : true,
                    bSmart         : true,
                    'sRangeFormat' : DataTableDatepickerLabels.fromToLabels(),
                    sortedByDefault: true
                },

                {type: 'text', bRegex: true, bSmart: true},
                {type: 'text', bRegex: true, bSmart: true},
                {type: 'text', bRegex: true, bSmart: true},
                {type: 'text', bRegex: true, bSmart: true},
                null
            ];

            var excludedColumns = [];

            if (!showStatusColumn)
                excludedColumns.push(1);


            if (!showCustomerNameColumn)
                excludedColumns.push(5);

            aoColumns = aoColumns.filter(function (val, index) {
                return !_.includes(excludedColumns, index);
            });


            var sortedColumn = 1;
            aoColumns.forEach(function (columnInfo, index) {
                if (columnInfo && columnInfo.sortedByDefault)
                    sortedColumn = index;
            });


            var buttonsOptions = [
                {
                    extend: 'subscriptionEditActionButton',

                    text: '<i class="envelope"></i>' + $translate.instant('customers.email'),

                    extendOptions: {
                        enabledCallback: function (e, dt) {
                            return !!DataTableFunctions.getSelectedData(dt).length;
                        },
                        action         : function (e, dt, node, config) {

                            var documents = DataTableFunctions.getSelectedData(dt);

                            DocumentExport.email(documents, $scope.edited.customer.email);
                        }
                    }
                },
                {
                    extend   : 'collection',
                    autoClose: true,
                    text     : '<i class="cloud-download"></i>' + $translate.instant('customers.export'),
                    buttons  : [


                        // Thats because excel button does not have any options to disable it until some rows got selected
                        {
                            extend   : 'selected',
                            className: 'excel-button-delegate',
                            autoClose: true,
                            text     : $translate.instant('customers.excel'),
                            action   : function (e, dt) {
                                dt.buttons('.excel-button').trigger()
                            }
                        },
                        {
                            extend       : 'excel',
                            className    : 'excel-button hidden',
                            exportOptions: {
                                modifier: {
                                    selected: true
                                },
                                columns : ':visible:not(.ignored-on-export)'
                            },
                            customizeData: function (object) {
                                var statusIndex = 0;
                                var body        = object.body;
                                object.header.reverse();
                                body.forEach(function (row, index) {
                                    body[index][statusIndex] = $filter('translateDocStatus')(row[statusIndex]);
                                    row.reverse();
                                });

                            }
                        },

                        {
                            extend   : 'selected',
                            autoClose: true,
                            text     : $translate.instant('documents.table.pdf'),
                            action   : function (e, dt) {

                                var ids = DataTableFunctions.getSelectedData(dt).map(function (document) {
                                    return document.id;
                                });

                                DocumentExport.downloadAsPdf(ids);
                            }
                        }
                    ]
                },
                {
                    extend: 'selectedSingle',
                    text  : '<i class="clone"></i>' + $translate.instant('customers.duplicate'),
                    action: function (e, dt, node, config) {
                        DataTableFunctions.getSelectedData(dt).forEach(function (document) {
                            DocumentModal.duplicateDocument(document.id);
                        });
                    }
                },
                {
                    extend: 'selected',
                    action: function (e, dt, node, config) {
                        var ids = DataTableFunctions.getSelectedData(dt).map(function (document) {
                            return document.id;
                        });

                        DocumentExport.print(ids);

                    },
                    text  : '<i class="print"></i>' + $translate.instant('customers.printing')
                },
                {
                    extend: 'selected',
                    action: function (e, dt, node, config) {

                        var ids = DataTableFunctions.getSelectedData(dt).map(function (document) {
                            return document.id;
                        });

                        DocumentExport.showAsPDF(ids);

                    },
                    text  : '<i class="fa fa-eye"></i>' + $translate.instant('documents.table.view_document')
                }
            ];

            if (!$scope.edited.customer.customerType.isDocumentEditingAvailable) {
                buttonsOptions = buttonsOptions.filter(function (element, index) {
                    return index != 0 && index != 2
                });
            }

            return DTOptionsBuilder
                .fromFnPromise(promiseCallback)
                .withBootstrap()
                .withOption('autoWidth', false)
                .withOption('sDom', "Brt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withColumnFilter({
                    aoColumns: aoColumns
                })
                .withOption('oLanguage', DataTableLanguageSettings)
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withOption('fnCreatedRow', rowCallback)
                .withOption('buttonContainer', buttonContainer)
                .withOption('order', [sortedColumn, 'desc'])
                .withButtons(buttonsOptions);
        }


        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

            $compile(nRow)($scope);

            if (aData.status != DocumentConstants.STATUS_OPEN) {
                $(nRow).addClass('disabled');
            }

            $(nRow).on('click', '.print_row', function (e) {
                DocumentExport.print(aData.id);
                e.preventDefault();
                e.stopPropagation();
            });

            $(nRow).on('click', '.duplicate', function (e) {
                DocumentModal.duplicateDocument(aData.id);
                e.preventDefault();
                e.stopPropagation();
            });

            $(nRow).on('click', '.num-link', function (e) {
                DocumentModal.editDocument(aData.id);
                e.stopPropagation();

            });

        }

        function reloadDataTables() {

            loadCustomerDocuments().then(function () {
                $.each($scope.dtInstances, function (key, instance) {
                    instance.reloadData(null, true);
                })
            })
        }
    });