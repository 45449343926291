/**
 * Created by dbichenko on 26.07.2016.
 */
angular.module('sinvoice-desktop').filter('itemFilter', function ($filter) {

    return function (items, filterValue) {

        if (filterValue == null || filterValue == "") {
            return items;
        }

        var filtered = [];
        filterValue  = filterValue.toLowerCase();

        items.forEach(function (value) {

            var name = value.name.toLowerCase();
            var sku  = value.sku.toLowerCase();

            if ((name.indexOf(filterValue) > -1) || (sku.indexOf(filterValue) > -1))
                filtered.push(value)
        });

        return filtered;

    }


});



