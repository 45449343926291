/**
 * Created by abichenko on 27.11.17.
 */
angular.module('sinvoice-mobile').controller('orderController',function ($scope) {

    (function () {

        if (!angular.isDefined($scope.editedDocument.validUntil))
            $scope.editedDocument.validUntil = momentWrapped().startOf('day')._d;

    })()

});