/**
 * Created by abichenko on 27.10.17.
 */
angular.module('sinvoice-common')

    .directive('coloredNisAmount', function () {

        return {
            replace : true,
            scope   : {
                amount       : '=',
                forceNegative: '=?'
            },
            template: '' +
            '<span>' +
            "   <span ng-if=\"forceNegative\">" +
            "       {{ 'NIS' | translateCurrencyLabel}}&lrm; {{minusSign}}{{amount|number:2}}" +
            "   </span>" +
            "   <span ng-class=\"classes\">" +
            "       {{ 'NIS' | translateCurrencyLabel}}&lrm; {{amount|number:2}}" +
            "   </span>" +
            "</span>",
            link    : function (scope, element, attrs) {


                scope.classes = {
                    'negative-amount': scope.amount < 0
                };

                if (scope.forceNegative) {
                    var minusSign;
                    if (value < 0) {
                        minusSign = '-';
                    } else {
                        minusSign = '';
                    }
                    scope.minusSign = minusSign;

                }
            }

        }
    })

    .directive('moneyAmount', function ($timeout) {

        return {
            scope   : {'amount': '=', 'currency': '='},
            replace : true,
            template: '' +
            '<span class="money-amount">' +
            '{{currency|translateCurrencyLabel}}&lrm; {{amount|number:2}}' +
            '</span>',
            link    : function (element, scope, attrs) {
            }
        }

    })
    .directive("passwordVerify", function () {
        return {
            require: "ngModel",
            scope  : {
                passwordVerify: '='
            },
            link   : function (scope, element, attrs, ctrl) {

                scope.$watch(function () {
                    return scope.passwordVerify;
                }, function () {
                    ctrl.$validate();
                });

                ctrl.$validators.passwordVerify = function (viewValue) {
                    return viewValue === scope.passwordVerify;
                };

            }
        };
    })
    .directive('arrayInput', function () {
        return {
            require: 'ngModel',
            link   : function (scope, elem, attrs, ctrl) {


                var watcher = scope.$watchCollection(attrs.ngModel, function (value, old) {
                    if (!Array.isArray(value) || old === value) {
                        return;
                    }

                    ctrl.$viewValue = ctrl.$formatters.reduceRight(function (prev, fn) {
                        return fn(prev)
                    }, ctrl.$modelValue);

                    ctrl.$render()

                });
                scope.$on('$destroy', watcher);

                ctrl.$parsers.push(function (value) {
                    return value.split(',')
                });

                ctrl.$formatters.push(function (value) {
                    return value.join(',')
                })

            }
        }
    })
    .directive("validationType", function () {

        function isValid(value, mask) {
            if (angular.isArray(value)) {
                var result = true;

                value.forEach(function (element) {
                    if (!mask.test(element)) {
                        result = false;
                    }
                });

                return result;
            } else {
                return !value || mask.test(value)
            }
        }

        // requires an isloated model
        return {
            restrict: 'A',
            require : 'ngModel',
            link    : function (scope, ele, attrs, ctrl) {
                var type              = attrs.validationType,
                    validateViewValue = !!angular.isDefined(attrs.validationTypeUseViewValue),
                    masks             = {};

                masks['positive-amount']             = /^[0-9]{1,8}([.]{1}[0-9]{1,2})?$/;
                masks['cheque-number']               = /^\d+$/;
                masks['negative-or-positive-amount'] = /^[-+]{0,1}[0-9]{1,8}([.]{1}[0-9]{1,2})?$/;
                masks['number-except-zero']          = /^[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*$/;
                masks['number']                      = /^[0-9]+$/;
                masks['phone']                       = /^(?=(\+|\*)\d+)\1\d+|[\d]+?$/;
                masks['fax']                         = /^[0-9+*\-]+$/;
                masks['number-precise']              = /^[0-9]*([.]{1}[0-9]{1,4})?$/;
                masks['email']                       = /^.+\@\S+\.\S+$/;
                masks['web']                         = /^((http|ftp)s?\:\/\/)?([a-z0-9][a-z0-9\-]*\.)+[a-z0-9][a-z0-9\-]*$/i;
                masks['password']                    = /^(?=[a-zA-Z0-9]*[a-zA-Z])(?=[a-zA-Z0-9]*[0-9])[a-zA-Z0-9]{6,}$/i;
                masks['business_number']             = /^[0-9]{9}$/;
                masks['bank-branch']                 = /^[0-9]{1,3}$/;
                masks['bank-account']                = /^[0-9]{4,10}$/;
                masks['cvv']                         = /^[0-9]{3}$/;
                masks['card-number']                 = /^[0-9]*$/;

            // ^(?!0(?:\.0)?$)([0-9]*[.,][0-9]+|[1-9]+?)$

                // add a parser that will process each time the value is
                // parsed into the model when the user updates it. data-validation-type="number"
                ctrl.$validators[type] = (function (value, viewValue) {
                    return isValid(validateViewValue ? viewValue : value, masks[type]);
                });

            }
        }
    })


    .directive('inputWithIcon', function () {
        return {
            require: 'ngModel',
            link   : function (scope, element, attr, ngModelCtrl) {
                var container = angular.element('<div>');
                var icon      = angular.element('<img>');

                element.addClass('input-with-icon');
                container.addClass('input-with-icon-container');
                icon.attr('src', attr['inputWithIcon'] == 'he' ? 'assets/img/he.png' : 'assets/img/en.png');
                icon.addClass('input-icon');

                element.wrap(container);
                element.parent().append(icon);

            }
        }
    })

    .directive('multipleSelect', function ($timeout, $translate) {
        function link(scope, element, attrs, ctrl) {


            function getNormalizedOption(option) {
                return {value: option[attrs.valueProperty], label: option[attrs.labelProperty]};
            }

            ctrl.$parsers.push(function (value) {
                var array = [];
                scope.options.forEach(function (item, index) {

                    var normalizedItem = getNormalizedOption(item);
                    if (value.indexOf('' + normalizedItem.value) != -1) {
                        array.push(item);
                    }
                });
                return array;
            });

            function loadOptions(options) {

                options = angular.copy(options);

                if (attrs.labelProperty && attrs.valueProperty) {
                    options = options.map(function (option) {
                        return getNormalizedOption(option);
                    })
                }

                for (var i = 0; i < options.length; i++) {
                    var item = options[i];
                    if (typeof item.value != 'undefined') {
                        if (typeof item.label != 'undefined') {
                            select.add(item.value, item.label);
                        } else {
                            select.add(item.value);
                            throw new Error('Options must key "label"');
                        }
                    } else {
                        throw new Error('Options must key "value"');
                    }
                }

                $timeout(function () {
                    scope.ngModel.forEach(function (item, index) {
                        var value = getNormalizedOption(item).value.toString();
                        select.selectItem(value);
                    })
                })

            }

            element.SumoSelect({
                triggerChangeCombined   : false,//(typeof scope.change == "function") ? false : true,
                captionFormat           : "נבחר {0}",
                captionFormatAllSelected: "נבחר {0}",
                selectAll               : true,
                placeholder             : attrs.placeholder ? attrs.placeholder : "",
                search                  : (typeof attrs.search != 'undefined') ? true : false,
                searchText              : "",
                locale                  : [
                    $translate.instant('common.multiselect.ok'),
                    $translate.instant('common.multiselect.cancel'),
                    $translate.instant('common.multiselect.select_all')
                ]
            });

            var select    = element[0].sumo;
            var container = element.parent();

            container.on('click.check', 'li', function (event) {
                if (typeof scope.change == "function") {
                    scope.$apply(function () {
                        scope.$eval(scope.change);
                    });
                }
            });


            function getSelectedElementIndex(container) {
                var options        = container.find('li.opt:not(.disabled, .hidden)');
                var selectedOption = container.find('li.opt.sel:not(.hidden)');
                var index          = options.index(selectedOption);
                return index;
            }

            var previousOption = 0;
            container.on('keydown', function (e) {

                if (e.keyCode == 38) {
                    var selectedElementIndex = getSelectedElementIndex(container);
                    var selectAll            = container.find('.select-all');

                    if (selectAll.length && selectedElementIndex == 0 && previousOption == 0) {
                        e.preventDefault();
                        if (selectAll.hasClass('selected'))
                            select.unSelectAll();
                        else {
                            select.selectAll();
                        }
                    }
                }

                previousOption = selectedElementIndex;
            });


            scope.$watchCollection('options', function (oldVal, newVal) {
                loadOptions(scope.options);
            });

        }

        return {
            restrict: 'A',
            require : 'ngModel',
            scope   : {
                change       : "&",
                ngModel      : "=",
                options      : "=",
                labelProperty: '@',
                valueProperty: '@'
            },
            link    : link
        }
    })
    .directive('spinner', function () {
        return {
            restrict: 'E',
            template: '<div class="text-center">' +
            '<img src="./assets/img/spinner.gif">' +
            '</div>',
            link    : function (scope, element, attr) {

            }
        }
    })