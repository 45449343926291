/**
 * Created by abichenko on 29.01.18.
 */
angular.module('sinvoice-common')
    .factory('Question', function (Url, API, $q) {
        return {

            getQuestionTooltips: function () {
                var defer = $q.defer();

                API.get(Url.questions.tooltips(), {})
                    .success(function (data) {
                        defer.resolve(data);
                    })
                    .error(function (error) {
                        defer.reject(error);
                    });

                return defer.promise;
            },

            getQuestionDetails: function (id) {
                var defer = $q.defer();

                API.get(Url.questions.details(id), {})
                    .success(function (data) {
                        defer.resolve(data);
                    })
                    .error(function (error) {
                        defer.reject(error);
                    });

                return defer.promise;
            },

            getQuestionByQuery: function (query) {
                var defer = $q.defer();

                API.get(Url.questions.search(encodeURIComponent(query)), {})
                    .success(function (data) {
                        defer.resolve(data);
                    })
                    .error(function (error) {
                        defer.reject(error);
                    });

                return defer.promise;
            },

            getCategories: function () {
                var defer = $q.defer();

                API.get(Url.questions.categories(), {})
                    .success(function (data) {
                        defer.resolve(data);
                    })
                    .error(function (error) {
                        defer.reject(error);
                    });

                return defer.promise;
            },
            sendInquiry  : function (data) {
                var defer = $q.defer();

                API.post(Url.contacts.sendForm(), data)
                    .success(function (data) {
                        defer.resolve(data);
                    })
                    .error(function (error) {
                        defer.reject(error);
                    });

                return defer.promise;
            }
        }
    });