/**
 * Created by abichenko on 05.02.18.
 */

angular.module('sinvoice-common')
    .factory('TaxPeriodProvider', function ($filter) {


        return {
            getDatepickerValues : getDatepickerValues,
            getPreviousTaxPeriod: getPreviousTaxPeriod
        };

        function getTaxPeriods(previousYear) {

            var startOfYear;
            if (previousYear)
                startOfYear = momentWrapped().subtract(1, 'year').year();
            else {
                startOfYear = momentWrapped().year();
            }


            var periods = [
                {
                    from: momentWrapped([startOfYear, 0]).startOf('month'),
                    to  : momentWrapped([startOfYear, 1]).endOf('month')
                },
                {
                    from: momentWrapped([startOfYear, 2]).startOf('month'),
                    to  : momentWrapped([startOfYear, 3]).endOf('month')
                },
                {
                    from: momentWrapped([startOfYear, 4]).startOf('month'),
                    to  : momentWrapped([startOfYear, 5]).endOf('month')
                },
                {
                    from: momentWrapped([startOfYear, 6]).startOf('month'),
                    to  : momentWrapped([startOfYear, 7]).endOf('month')
                },
                {
                    from: momentWrapped([startOfYear, 8]).startOf('month'),
                    to  : momentWrapped([startOfYear, 9]).endOf('month')
                },
                {
                    from: momentWrapped([startOfYear, 10]).startOf('month'),
                    to  : momentWrapped([startOfYear, 11]).endOf('month')
                }
            ];

            return periods;

        }

        function getPreviousTaxPeriod() {

            var taxPeriods = getTaxPeriods();

            var prevTaxPeriod = null;

            taxPeriods.forEach(function (period, i) {

                if ((momentWrapped() <= period.to) && (momentWrapped() >= period.from)) {
                    prevTaxPeriod = taxPeriods[i - 1] ? taxPeriods[i - 1] : null;
                }

            });

            if (!prevTaxPeriod) {
                var prevYearPeriods = getTaxPeriods(true);

                prevTaxPeriod = prevYearPeriods[prevYearPeriods.length - 1]
            }

            return prevTaxPeriod;


        }

        function getDatepickerValues() {

            var previousTaxPeriod = getPreviousTaxPeriod();

            return [
                {
                    id   : 'two_month',
                    title: $filter('translate')('common.date_range.twoMonth'),
                    from : previousTaxPeriod.from,
                    to   : previousTaxPeriod.to
                },
                {
                    id   : 'last_month',
                    title: $filter('translate')('common.date_range.lastMonth'),
                    from : momentWrapped().subtract(1, 'month').startOf('month'),
                    to   : momentWrapped().subtract(1, 'month').endOf('month')
                },
                {
                    id   : 'previous_years',
                    title: $filter('translate')('common.date_range.previousYears'),
                    from : momentWrapped().subtract(1, 'year').startOf('year').startOf('month'),
                    to   : momentWrapped().subtract(1, 'year').endOf('year').endOf('month')
                },
                {
                    id   : 'current_year',
                    title: $filter('translate')('common.date_range.currentYear'),
                    from : momentWrapped().startOf('year').startOf('month'),
                    to   : momentWrapped()
                },
                {
                    id   : 'custom',
                    title: $filter('translate')('common.date_range.custom'),
                    from : momentWrapped(),
                    to   : momentWrapped()
                }
            ]
        }


    });