/**
 * Created by abichenko on 27.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('taxInvoiceController', function ($scope, DocumentModalCallbackContainer) {

        $scope.errors = {};


        (function () {

            if (!angular.isDefined($scope.editedDocument.paymentDate))
                $scope.editedDocument.paymentDate = momentWrapped().startOf('day')._d;


            initializeCustomValidators();

            function initializeCustomValidators() {
                DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                    var editedDocument  = $scope.editedDocument;
                    var paragraphErrors = $scope.errors;

                    paragraphErrors.required = false;

                    if (!editedDocument.paragraphs.length) {
                        flow.deny();
                        paragraphErrors.required = true;
                        return false;
                    }
                    flow.allow();
                });

            }

        })();


    });