/**
 * Created by dbichenko on 22.12.2016.
 */
angular.module('sinvoice-desktop').controller('newIncomeForAccountingBlockController',
    function ($scope,
              Document,
              Report,
              Customer,
              Events,
              DocumentTypeConstants,
              $stateParams,
              $filter,
              ReportsFilterMapper,
              TaxPeriodProvider) {


        $scope.spinner            = {
            enabled: true
        };
        $scope.newReportForm      = {};
        $scope.availableCustomers = [];
        $scope.availableDocTypes  = [];

        $scope.setOtherDate     = function () {
            $scope.selected.dateExample = $scope.dateExamples[4];
        };
        $scope.updateFromToDate = function (date) {
            $scope.selected.from = date.from ? date.from : new Date();
            $scope.selected.to   = date.to ? date.to : new Date();
        };

        $scope.produceNewReport = function () {

            $scope.spinner.enabled = true;

            var requestData = angular.copy($scope.selected);

            Report.createReport('bookkeeping_income', requestData).then(function (data) {
                $scope.spinner.enabled = false;
                Events.triggerEvent('report.update', data);
            });

        };

        (function () {

            $scope.selected = {
                filters: {
                    customers: [],
                    docTypes : []
                }
            };

            $scope.dateExamples = TaxPeriodProvider.getDatepickerValues();
            var dateFilters     = ReportsFilterMapper.getMappedDate(
                $stateParams.from,
                $stateParams.to,
                $scope.dateExamples
            );

            $scope.selected.to          = dateFilters.to;
            $scope.selected.from        = dateFilters.from;
            $scope.selected.dateExample = dateFilters.dateExample;

            Report.getFilterValues('bookkeeping_income')
                .then(function (data) {

                    $scope.availableCustomers = data.customers;

                    var groups = data.groups.map(function (group) {
                        return {id: group, label: $filter('translate')('reports.incomeForAccounting.select.' + group)}
                    });

                    var docTypes = data.docTypes.map(function (val) {
                        return {id: val, label: $filter('translateDocType')(val)}
                    });

                    $scope.availableDocTypes = groups.concat(docTypes);


                })
                .then(function () {

                    var selectedDocTypes = $stateParams.docTypes
                        ? $stateParams.docTypes
                        : $scope.availableDocTypes[0].id;

                    $scope.selected.filters.docTypes = ReportsFilterMapper.getMappedValues(selectedDocTypes, 'id', $scope.availableDocTypes);

                    $scope.selected.filters.customers = ReportsFilterMapper.getMappedValues($stateParams.customers, 'id', $scope.availableCustomers);
                })

                .then(function () {
                    $scope.spinner.enabled = false;
                })

        })();

    });