angular.module('sinvoice-desktop').controller('helpController', function ($scope, HelpService, $timeout) {

    $scope.pageParams = {
        subTitle  : null,
        hideHeader: false
    };
    $scope.categories = [];

    $scope.spinner = {enabled: true};

    (function () {

        HelpService
            .loadCategories()
            .then(function (data) {
                $timeout(function () {
                    $scope.categories = data;
                });
            })
            .finally(function () {
                $timeout(function () {
                    $scope.spinner.enabled = false;
                });
            });


    })();


});