/**
 * Created by dbichenko on 26.09.2016.
 */
angular.module('sinvoice-desktop').factory('DistributorSettings', function ($q, Url, API) {

    return {

        setPaymentType: function (paymentType) {
            var deferred = $q.defer();
            API.post(Url.profile.setPaymentType(), {paymentType: paymentType})
                .success(function (data) {
                    deferred.resolve(data);
                });

            return deferred.promise;
        },
        getSettings   : function (paymentType) {
            var deferred = $q.defer();
            API.get(Url.profile.settings())
                .success(function (data) {
                    deferred.resolve(data);
                });

            return deferred.promise;
        },
        getDetails    : function () {

            var defer = $q.defer();

            API.get(Url.profile.referralAccounts(), {}).success(function (data) {
                defer.resolve(data)
            });


            return defer.promise;


        },
        updateDetails : function (details) {



            var defer = $q.defer();

            API.post(Url.profile.referralAccounts(), {'details': details})
                .success(function (data) {
                    if (data.status) {
                        defer.resolve(data);
                    } else {
                        defer.reject();
                    }
                });


            return defer.promise;
        }
    }
});