/**
 * Created by dbichenko on 07.09.2016.
 */
angular.module('sinvoice-desktop').controller('printDocumentPopupController', function ($scope, Document, Url, Modal, ModalContainer, DocumentExport) {


    $scope.spinner = {};

    function startSpinner() {
        $scope.spinner.enabled = true;
    }

    function stopSpinner() {
        $scope.spinner.enabled = false;
    }

    $scope.printEnglishOriginal = function () {
        startSpinner();

        DocumentExport
            .printEnglishOriginal($scope.editedDocument.id)
            .finally(stopSpinner);

    };

    $scope.printOriginalAndCopy = function () {
        startSpinner();

        DocumentExport
            .printOriginalAndCopy($scope.editedDocument.id)
            .finally(stopSpinner);

    };

    $scope.printDocument = function () {


        startSpinner();
        var tokenForOriginal = getOriginalToken($scope.editedDocument);


        if (tokenForOriginal) {
            DocumentExport
                .printByToken(tokenForOriginal)
                .finally(stopSpinner)
        } else {
            DocumentExport
                .print($scope.editedDocument.id)
                .finally(stopSpinner)
        }
    };


    $scope.showAsPDF = function () {

        startSpinner();
        var tokenForOriginal = getOriginalToken($scope.editedDocument);

        if (tokenForOriginal) {
            DocumentExport
                .showAsPDFByToken(tokenForOriginal)
                .finally(stopSpinner)
        } else {
            DocumentExport
                .showAsPDF($scope.editedDocument.id)
                .finally(stopSpinner);
        }
    };

    function getOriginalToken(document) {
        return document.export && document.export.tokenForOriginal
            ? document.export.tokenForOriginal : null;
    }

});
