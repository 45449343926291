angular.module('sinvoice-desktop').controller('businessInfoController',
    function ($scope, City, Profile, BankProvider, ProfileChecker, AuthDataStorage, $q, UserSubscription, Events) {

        $scope.addresses  = [];
        $scope.contacts   = [];
        $scope.accounts   = [];
        $scope.backupInfo = {
            adresses: [],
            contacts: [],
            accounts: []
        };
        $scope.spinner    = {
            enabled: true
        };

        $scope.isSubmitButtonDisabled = function () {
            return UserSubscription.isCheckInProgress();
        };


        $scope.isSaveAllowed = function () {
            return new Promise(function (resolve, reject) {

                if ($scope.profileForm.$invalid) {
                    return reject(false);
                }

                return UserSubscription.checkSubscriptionForEditAction().then(function () {
                    return resolve(true)
                });

            })
        };

        $scope.addAddress = function () {
            $scope.addresses.push({});
        };

        $scope.removeAddress = function (index) {
            if ($scope.addresses[index].id) {
                $scope.addresses[index].deleted = true;
            }
            else {
                $scope.addresses.splice(index, 1);
            }
        };

        $scope.addContact = function () {
            $scope.contacts.push({});
        };

        $scope.removeContact = function (index) {

            if ($scope.contacts[index].id) {
                $scope.contacts[index].deleted = true;
            }
            else {
                $scope.contacts.splice(index, 1);
            }
        };

        $scope.addAccount = function () {
            $scope.accounts.push({});
        };

        $scope.removeAccount = function (index) {

            if ($scope.accounts[index].id) {
                $scope.accounts[index].deleted = true;
            }
            else {
                $scope.accounts.splice(index, 1);
            }
        };


        $scope.save = function () {
            $scope.spinner.enabled = true;

            return Profile
                .updateBusinessInfo({
                    "addresses": $scope.addresses,
                    "contacts" : $scope.contacts,
                    "accounts" : $scope.accounts
                })
                .then(function (data) {
                    if (data.success) {
                        $scope.addresses = data.data.addresses;
                        $scope.contacts  = data.data.contacts;
                        $scope.accounts  = data.data.accounts;
                        angular.forEach($scope.addresses, function (value, key) {
                            $scope.addresses[key].city = {id: value.city_id, name: value.city.name};
                        });
                        angular.forEach($scope.accounts, function (value, key) {
                            $scope.accounts[key].name = {id: value.name};
                        });
                        angular.copy($scope.addresses, $scope.backupInfo.adresses);
                        angular.copy($scope.contacts, $scope.backupInfo.contacts);
                        angular.copy($scope.accounts, $scope.backupInfo.accounts);
                        Events.triggerEvent('profile.business-info.update');

                        return Promise.resolve(data);
                    } else {
                        return Promise.reject();
                    }
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        };

        $scope.cancel = function () {
            angular.copy($scope.backupInfo.adresses, $scope.addresses);
            angular.copy($scope.backupInfo.contacts, $scope.contacts);
            angular.copy($scope.backupInfo.accounts, $scope.accounts);
        };

        $scope.isAllDeleted = function (array) {
            if (!array.length) {
                return true;
            }
            var deletedItems = 0;
            array.forEach(function (item, i) {
                if (item.deleted) {
                    deletedItems++;
                }
            })
            if (deletedItems == array.length) {
                return true;
            }
            return false;
        };

        $scope.isEnglishAvailable = function () {
            return ProfileChecker.isEnglishAvailable(AuthDataStorage.getProfileBusinesses());
        };

        (function () {
            loadData()
        })();

        function loadData() {

            $q
                .all([

                    Profile.getData().then(function (data) {
                        $scope.user = data;
                    }),

                    City.getAll().then(function (data) {
                        $scope.cities = data;
                    }),


                    BankProvider.getAll(true).then(function (data) {
                        $scope.banks = data;
                    }),

                    Profile.getBusinessInfo()
                        .then(function (data) {
                            $scope.addresses = data.addresses;
                            $scope.contacts  = data.contacts;
                            $scope.accounts  = data.accounts;

                            angular.forEach($scope.addresses, function (value, key) {
                                $scope.addresses[key].city = {id: value.city_id, name: value.city.name};
                            });
                            angular.forEach($scope.accounts, function (value, key) {
                                $scope.accounts[key].name = {id: value.name};
                            });

                            angular.copy($scope.addresses, $scope.backupInfo.adresses);
                            angular.copy($scope.contacts, $scope.backupInfo.contacts);
                            angular.copy($scope.accounts, $scope.backupInfo.accounts);

                        })
                ])
                .then(function () {
                    $scope.spinner.enabled = false;
                })
        }

        $scope.getNonDeletedAddresses = function () {
            return $scope.addresses.filter(function (value) {
                return !value.deleted;
            });
        }
    });