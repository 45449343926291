angular.module('sinvoice-desktop').controller('editDocumentController',
    function ($scope,
              DocumentModal,
              Document,
              Modal,
              $translate,
              $filter,
              Currency,
              DocumentModalCallbackContainer,
              DocumentConstants,
              DocumentTypeConstants,
              CustomerTypeChecker,
              RelatedDocumentTableCallbackContainer,
              DocumentModalCallbackChecker,
              Events) {


        function setDefaults() {


            $scope.template.isEditable                         = false;
            $scope.template.showEmailContent                   = false;
            $scope.template.showCustomerForm                   = false;
            $scope.template.hasRelatedDocuments                = false;
            $scope.template.isEditable                         = !$scope.template.isModalLockedForEdit && DocumentModal.isEditable($scope.editedDocument);
            $scope.template.showEmailContent                   = !!($scope.editedDocument.emailContent);
            $scope.template.$isOccasionalCustomerBlockDisabled = !$scope.editedDocument.type.isOccasionalCustomerAllowed;
            $scope.template.isRetroactiveRelatingAllowed       = DocumentModal.isRetroactiveRelatingAllowed($scope.editedDocument);
            $scope.template.isActiveDocument                   = DocumentModal.isActive($scope.editedDocument);

        }

        $scope.removeEmail = function (index) {
            $scope.editedDocument.clientEmails.splice(index, 1);
        };

        $scope.addEmail = function () {


            $scope.editedDocument.clientEmails.push($scope.template.additionalEmail);
            $scope.template.additionalEmail = '';
        };

        $scope.updateEmailContent = function () {
            if (!$scope.editedDocument.emailContent) {
                $scope.editedDocument.emailContent = null;
            }
        };


        Events.subscribeToEvent('relatedDocs.related', $scope, function (e, documents, prevDocuments, addedDocument) {

            if (!addedDocument)
                return;

            var documentsToSearch = addedDocument.relatedDocs.concat(addedDocument.relatedToDocs);

            if (!documentsToSearch.length || addedDocument.status != DocumentConstants.STATUS_CLOSED)
                return;

            var creditDocs = documentsToSearch.filter(function (document) {
                var docType = document.type.docType;
                return Document.isCreditDoc(docType);
            });

            if (creditDocs.length) {
                Modal.alert(
                    $translate.instant('documents.relating_document_has_credits_warning'),
                    {title: $translate.instant('documents.relating_document_has_credits_warning_title')}
                );
            }


        });


        (function () {

            initializeCustomValidatorsOccassionalCustomer();
            initializeShowBalanceWarningPopup();

            DocumentModalCallbackContainer.addPreSaveCallback(customerIdRequiredPopup);
            DocumentModalCallbackContainer.addPreSaveCallback(documentEditingWarning);
            DocumentModalCallbackContainer.addPreSaveCallback(issueDateConfirm);
            DocumentModalCallbackContainer.addPreSaveCallback(withholdingTaxPopup);
            RelatedDocumentTableCallbackContainer.addPreSelectCallback(singleTypeRelationPopup);
            RelatedDocumentTableCallbackContainer.addPreSelectCallback(relateOrCopyDocumentPopup);


            function withholdingTaxPopup(flow) {

                if (!DocumentModalCallbackChecker.isWithholdingTaxPopupNeedToBeShown(
                        $scope.editedDocument,
                        $scope.template.$paymentsTotal
                    ))
                    return flow.allow();

                Modal.confirm(
                    $translate.instant('documents.withholding_tax_warning'),
                    null,
                    null,
                    {title: $translate.instant('documents.withholding_tax_warning_title')}
                )
                    .then(flow.allow, flow.deny);
            }

            function singleTypeRelationPopup(flow, attributes) {

                var addedDocumentType     = attributes.addedDocument.type.docType;
                var twoSidedRelationTypes = _.differenceWith(
                    $scope.template.$reliableTypesOfOpenDocuments.concat($scope.template.$reliableTypesOfAnyStatusDocuments || []),
                    $scope.template.$oneSideRelationDocTypes
                );


                var normalizedTwoSidedRelationTypes = twoSidedRelationTypes
                    .map(function (docType) {
                        if (docType === 'receipt_balance_adjustment' || docType === 'invoice_balance_adjustment')
                            return DocumentTypeConstants.TYPE_BALANCE_ADJUSTMENT;
                        else {
                            return docType
                        }
                    });

                if (!_.includes(normalizedTwoSidedRelationTypes, addedDocumentType)) {
                    flow.allow();
                    return true;
                }


                var actions             = attributes.actions;
                var alreadyRelatedTypes = [];

                if ($scope.editedDocument.relatedDocs) {

                    alreadyRelatedTypes = $scope.editedDocument.relatedDocs
                        .filter(function (document) {
                            return _.includes(normalizedTwoSidedRelationTypes, document.type.docType);
                        })
                        .map(function (document) {
                            return document.type.docType;
                        });

                    alreadyRelatedTypes = _.uniq(alreadyRelatedTypes);
                }

                if (alreadyRelatedTypes.length && !_.includes(alreadyRelatedTypes, addedDocumentType)) {
                    Modal
                        .alert(
                            $translate.instant('documents.already_related_type',
                                {
                                    documentType      : $filter('translateDocType')($scope.editedDocument.type.docType),
                                    alreadyRelatedType: alreadyRelatedTypes.map($filter('translateDocType'))[0],
                                    addedDocumentType : $filter('translateDocType')(addedDocumentType)
                                }
                            ),
                            {title: $translate.instant('documents.already_related_type_title')}
                        );
                    flow.deny();
                    actions.preventRelate();

                } else {
                    flow.allow();
                }


            }

            function relateOrCopyDocumentPopup(flow, attributes) {

                var actions = attributes.actions;

                function isRelatePopupNeedToBeShown(addedDocument) {
                    if (!$scope.template.$oneSideRelationDocTypes)
                        return false;

                    return _.includes($scope.template.$oneSideRelationDocTypes, addedDocument.type.docType);
                }

                if ($scope.editedDocument.id || !isRelatePopupNeedToBeShown(attributes.addedDocument)) {
                    flow.allow();
                    return;
                }

                Modal
                    .confirm(
                        $translate.instant('documents.on_select_related_doc_popup.message'),
                        $translate.instant('documents.on_select_related_doc_popup.relate'),
                        $translate.instant('documents.on_select_related_doc_popup.copy'),
                        {
                            closeButton: false,
                            title      : $translate.instant('documents.on_select_related_doc_popup.title')
                        }
                    )
                    .then(
                        function () {
                            flow.deny();
                            actions.relate();
                        },
                        function () {
                            flow.deny();
                            actions.copy();
                        });

            }

            function initializeShowBalanceWarningPopup() {

                DocumentModalCallbackContainer.addPreSaveCallback(showBalanceWarningPopup);

                function getDocumentAmount() {

                    if ($scope.editedDocument.id)
                        return $scope.editedDocument.displayedTotalNIS;

                    var documentAmount = 0;

                    if ($scope.editedDocument.type.isPaymentBased) {
                        documentAmount = $scope.template.$paymentsTotalWithVat;
                    } else if (Document.isBalanceAdjustment($scope.editedDocument.type.docType)) {
                        documentAmount = $scope.editedDocument.amount;
                    } else {
                        documentAmount = $scope.template.$finalItemsTotalWithVat;
                    }
                    return documentAmount;
                }

                function showBalanceWarningPopup(flow, additionalArguments) {

                    var summaries            = {};
                    var isPopupNeedToBeShown = false;

                    if ($scope.editedDocument.relatedDocs)
                        $scope.editedDocument.relatedDocs.forEach(function (document) {
                            if (!summaries[document.type.docType]) {

                                summaries[document.type.docType] = {
                                    total : Number(document.displayedTotalNIS),
                                    amount: 1
                                };
                            } else {
                                summaries[document.type.docType].total += Number(document.displayedTotalNIS);
                                summaries[document.type.docType].amount += 1;
                            }
                        });

                    $.each(summaries, function (key, value) {
                        if (!isPopupNeedToBeShown && getDocumentAmount() < value.total) {
                            isPopupNeedToBeShown = true;
                        }
                    });


                    if (isPopupNeedToBeShown) {
                        Modal
                            .confirm(
                                $translate.instant('documents.balance_warning'),
                                null,
                                null,
                                {title: $translate.instant('documents.balance_warning_title')}
                            )
                            .then(
                                function () {
                                    flow.allow();
                                },
                                function () {
                                    flow.deny();
                                }
                            );
                    } else {
                        flow.allow();
                    }
                }
            }

            function initializeCustomValidatorsOccassionalCustomer() {
                $scope.$watch('template.$finalItemsTotalWithVat + template.$paymentsTotalWithVat', function () {

                    var customerOccasionalVatNumber = $scope.documentForm.customerOccasionalVatNumber;

                    if (customerOccasionalVatNumber) {
                        customerOccasionalVatNumber.$validate();
                    }
                });
            }

            function issueDateConfirm(flow) {


                if (!DocumentModalCallbackChecker.isIssueDatePopupNeedToBeShown($scope.editedDocument)) {
                    flow.allow();
                    return;
                }

                var lastDocument = $scope.editedDocument.type.lastDocument;
                var dateText     = momentWrapped(lastDocument.issueDate).format('DD/MM/YYYY');
                var message      = $translate.instant('documents.change_issue_date', {
                    dateText: dateText
                });

                Modal.confirm(
                    message,
                    $translate.instant('documents.button_confirm_issue_date'),
                    $translate.instant('documents.button_cancel_issue_date'),
                    {title: $translate.instant('documents.change_issue_date_title')}
                )
                    .then(
                        function () {
                            flow.allow()
                        }, function () {
                            flow.deny()
                        })

            }


            function documentEditingWarning(flow) {

                if ($scope.editedDocument.id && DocumentModal.isEditable($scope.editedDocument)) {
                    Modal
                        .confirm(
                            $translate.instant('documents.document_edit_warning'),
                            null,
                            null,
                            {title: $translate.instant('documents.document_edit_warning_title')}
                        )
                        .then(function () {
                            flow.allow();
                        })
                } else {
                    flow.allow();
                    return true;
                }

            }


            function customerIdRequiredPopup(flow) {

                var editedDocument  = $scope.editedDocument;
                var invalidAmount   = DocumentModalCallbackChecker.isCustomerMissingForAmountPopupNeedToBeShown(
                    editedDocument,
                    $scope.template.$paymentsTotalWithVatNIS,
                    $scope.template.$finalItemsTotalWithVatNIS
                );
                var invalidTaxValue = DocumentModalCallbackChecker.isCustomerMissingForTaxValuePopupNeedToBeShown(
                    editedDocument
                );

                var reason = null;
                if (invalidAmount) {
                    reason = 'amount'
                } else if (invalidTaxValue) {
                    reason = 'taxValue'
                }

                if (!reason) {
                    return flow.allow();
                }

                if (CustomerTypeChecker.isOccasional($scope.editedDocument.customer.customerType)) {
                    createCustomerPopup(reason)
                } else {
                    missingBusinessNumberPopup(reason);
                }


                function missingBusinessNumberPopup(triggeredBy) {
                    Modal.customDialog({
                        scopeVariables: {
                            docType    : editedDocument.type,
                            customer   : angular.copy($scope.editedDocument.customer),
                            flow       : flow,
                            onSave     : function (customer) {
                                $scope.editedDocument.customer = customer
                            },
                            triggeredBy: triggeredBy
                        },
                        title         : $translate.instant('documents.missing_info_popup.title'),
                        templateUrl   : './desktop/components/documents/popups/customer-info-missing-popup.html',
                        className     : 'centered-popup',
                        size          : "medium"
                    });
                };

                function createCustomerPopup(triggeredBy) {

                    var defaultCustomerName = $translate.instant('documents.use_occuring');

                    Modal.customDialog({
                        scopeVariables: {
                            customer          : {
                                email         : editedDocument.customerEmail,
                                address       : editedDocument.customerAddress,
                                businessnumber: editedDocument.customerOccasionalVatNumber,
                                name          : editedDocument.customerOccasionalName != defaultCustomerName
                                    ? editedDocument.customerOccasionalName
                                    : ''
                            },
                            docType           : editedDocument.type,
                            flow              : flow,
                            isExistingCustomer: $scope.template.useExistingCustomer,
                            onSave            : function (customer) {
                                $scope.editedDocument.customer = customer
                            },
                            triggeredBy       : triggeredBy

                        },
                        title         : $translate.instant('documents.missing_info_popup.title'),
                        templateUrl   : './desktop/components/documents/popups/create-new-cutomer-popup.html',
                        className     : 'centered-popup',
                        size          : "medium"
                    });
                }
            };


        })();


        /*$scope.$watch('template.$reliableTypesOfOpenDocuments', updateRelatedDocumentList);*/

        setDefaults();


    });
