/**
 * Created by abichenko on 18.09.17.
 */
angular.module('sinvoice-desktop').controller('exportPopupController', function ($scope, Report, DocumentExport, $filter, TaxPeriodProvider) {

    var customRange;

    $scope.spinner         = {enabled: false};
    $scope.exportPopupForm = {};
    $scope.dateExamples    = TaxPeriodProvider.getDatepickerValues();
    $scope.exportOptions   = {};
    $scope.formats         = [
        {label: 'mivne', alias: 'mivne'},
        {label: 'wizcount', alias: 'wizcount'}
    ];

    $scope.cancel = function ($event) {
        $event.preventDefault();
        $scope.$currentModal.close();
    };

    $scope.updateFromToDate = function (date) {
        $scope.exportOptions.from = date.from ? date.from : new Date();
        $scope.exportOptions.to   = date.to ? date.to : new Date();
    };

    $scope.export = function () {

        $scope.exportPopupForm.$setValidity('zero_result', true);

        if ($filter('isSubmitNotAllowed')($scope.exportPopupForm)) {
            return;
        }


        var promise;
        if ($scope.exportOptions.format.alias === 'mivne') {
            promise = DocumentExport.showMivneSummary([], $scope.exportOptions.from, $scope.exportOptions.to)
        }

        if ($scope.exportOptions.format.alias === 'wizcount') {
            promise = DocumentExport.downloadAsWizcount([], $scope.exportOptions.from, $scope.exportOptions.to)
        }

        if (promise) {

            $scope.spinner.enabled = true;
            promise
                .then(function (result) {
                    if (!result) {
                        $scope.exportPopupForm.$setValidity('zero_result', false);
                    } else {
                        $scope.$currentModal.close();
                    }

                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        }

    };

    (function () {

        customRange                      = $scope.dateExamples[0];
        $scope.exportOptions.format      = $scope.formats[0];
        $scope.exportOptions.dateExample = customRange;
        $scope.exportOptions.to          = customRange.to;
        $scope.exportOptions.from        = customRange.from;
    })();
});