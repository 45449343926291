/**
 * Created by dbichenko on 04.11.2016.
 */
angular.module('sinvoice-desktop').factory('sendSMSPopupFunctions', function (ManagedCustomers, Customer) {

    var functions = {
        'customers'               : {
            getData       : Customer.getData,
            sendSMS       : Customer.sendSMS,
            mapToRecipient: function (customer) {
                return {
                    id   : customer.id,
                    name : customer.name,
                    phone: customer.phone
                }
            }
        },
        'managed-customer-invites': {
            getData       : ManagedCustomers.getInvited,
            sendSMS       : ManagedCustomers.sendSMSToInvited,
            mapToRecipient: function (invite) {
                return {
                    id   : invite.id,
                    name : invite.firstName,
                    phone: invite.phone
                }
            }
        },
        'managed-customers'       : {
            getData       : ManagedCustomers.getAll,
            sendSMS       : ManagedCustomers.sendSMS,
            mapToRecipient: function (managedCustomer) {
                return {
                    id   : managedCustomer.id,
                    name : managedCustomer.user.name,
                    phone: managedCustomer.user.phone
                }
            }
        }
    };

    function getFunction(type, funcName) {

        var func = functions[type][funcName];

        if (angular.isDefined(func) && angular.isFunction(func)) {
            return func;
        } else {
            throw new Error(type + ' is not supported type for SMS popup');
        }
    }

    return {
        getDataFunction   : function (type) {
            return getFunction(type, 'getData');
        },
        getSendSMSFunction: function (type) {
            return getFunction(type, 'sendSMS');
        },
        getMapperFunction : function (type) {
            return getFunction(type, 'mapToRecipient');
        }
    }

});

