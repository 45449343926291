/**
 * Created by abichenko on 21.12.17.
 */
angular.module('sinvoice-mobile')
    .directive('closableSection', function () {

        return {
            scope     : {
                'title'   : '@',
                'isOpened': '=?'
            },
            transclude: true,
            controller: function ($scope) {

                if (!angular.isDefined($scope.isOpened))
                    $scope.isOpened = true;

                $scope.toggle = function () {
                    $scope.isOpened = !$scope.isOpened;
                }

            },
            template  : '' +
            '<div>' +
            '   <div class="document-row-title" ng-click="toggle()"> ' +
            '       <span style="float: left" class="circle-btn" ng-class="{\'add-circle\':!isOpened,\'remove-circle\':isOpened}">' +
            '           <span class="fa" ng-class="{\'fa-plus\':!isOpened,\'fa-minus\':isOpened}"></span>' +
            '       </span>' +
            '       <h3 style="float: right">{{title}}</h3>' +
            '       <div style="clear: both"></div>' +
            '   </div>' +
            '   <div class="section-block-content" ng-show="isOpened" ng-transclude=""></div>' +
            '<div>',
            link      : function (scope, element, attrs) {


            }
        }

    });