/**
 * Created by dbichenko on 25.08.2016.
 */
angular.module('sinvoice-desktop').factory('ManagedCustomers', function (API, Url, $q) {

    return {
        stats              : function (id) {
            var deferred = $q.defer();

            API.get(Url.managedCustomers.stats(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        getDocuments       : function (id) {
            var deferred = $q.defer();

            API.get(Url.managedCustomers.documents(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        get                : function (id) {
            var deferred = $q.defer();

            API.get(Url.managedCustomers.get(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        invite             : function (invites, message, resendOptions) {

            var deferred = $q.defer();
            API.post(Url.managedCustomers.invite(), {
                invites: invites,
                message: message,
                resend : resendOptions
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;

        },
        createEmptyCustomer: function () {
            var obj = {};

            obj.lastName  = '';
            obj.firstName = '';
            obj.facebook  = '';
            obj.phone     = '';
            obj.email     = '';
            obj.source    = null;

            return obj;
        },

        getInvitable         : function () {
            var deferred = $q.defer();

            API.get(Url.managedCustomers.invitable())
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        getAll               : function () {

            var deferred = $q.defer();

            API.get(Url.managedCustomers.all())
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        getInvited           : function () {

            var deferred = $q.defer();

            API.get(Url.managedCustomers.invited())
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        changeStatus         : function (status, ids) {

            var deferred = $q.defer();

            API.post(Url.managedCustomers.changeStatus(status), {ids: ids})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;


        },
        resendInvitations    : function (ids, message) {

            var deferred = $q.defer();

            API.post(Url.managedCustomers.resendInvitations(), {ids: ids, message: message})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;


        },
        setComment           : function (id, comment) {

            var deferred = $q.defer();

            API.put(Url.managedCustomers.comment(id), {comment: comment})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        getIncomingInvites   : function () {

            var defer = $q.defer();

            API
                .get(Url.managedCustomers.incoming())
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (data) {
                    defer.reject(data)
                });

            return defer.promise;

        },
        acceptIncomingInvite : function (id) {
            var defer = $q.defer();

            API
                .post(Url.managedCustomers.acceptIncoming(id))
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (data) {
                    defer.reject(data)
                });

            return defer.promise;

        },
        declineIncomingInvite: function (id) {

            var defer = $q.defer();

            API
                .post(Url.managedCustomers.declineIncoming(id))
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (data) {
                    defer.reject(data)
                });

            return defer.promise;
        },
        checkIfExist         : function (attribute, value) {
            var defer = $q.defer();

            API
                .get(Url.managedCustomers.checkIfExist(attribute, value))
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (data) {
                    defer.reject(data)
                });

            return defer.promise;
        },
        sendSMS              : function (ids, message) {
            var deferred = $q.defer();

            API.post(Url.managedCustomers.sendSMS(), {ids: ids, message: message})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        sendSMSToInvited     : function (ids, message) {
            var deferred = $q.defer();

            API.post(Url.managedCustomers.sendSMSToInvited(), {ids: ids, message: message})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        sendEmail            : function (ids, message, subject, sendCopy) {
            var deferred = $q.defer();

            API.post(Url.managedCustomers.sendEmail(), {
                ids     : ids,
                message : message,
                subject : subject,
                sendCopy: sendCopy
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        sendEmailToInvited   : function (ids, message, subject, sendCopy) {
            var deferred = $q.defer();

            API.post(Url.managedCustomers.sendEmailToInvited(), {
                ids     : ids,
                message : message,
                subject : subject,
                sendCopy: sendCopy
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },


        getManagerDetails   : function () {
            var defer = $q.defer();

            API.get(Url.managedCustomers.managerDetails())
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        refuseManagement    : function () {
            var defer = $q.defer();

            API.post(Url.managedCustomers.removeManager())
                .success(function (data) {
                    defer.resolve(data);
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getPDFContent       : function (customers, columns) {
            var deferred = $q.defer();
            API.get(Url.managedCustomers.pdfContent(), {customers: customers, columns: columns})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getXLSArchiveContent: function (customers, columns) {
            var deferred = $q.defer();
            API.get(Url.managedCustomers.xlsContent(), {customers: customers, columns: columns})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getExportFilterValues: function (id) {
            var deferred = $q.defer();
            API.get(Url.managedCustomers.exportFilterValues(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        exportToPDF  : function (id, options) {
            var deferred = $q.defer();
            API.post(Url.managedCustomers.exportToPDF(id), options)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        exportToExcel: function (id, options) {
            var deferred = $q.defer();
            API.post(Url.managedCustomers.exportToExcel(id), options)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getFilteredDocumentsToExport: function (id, options) {
            var deferred = $q.defer();
            API.post(Url.managedCustomers.documentsToExport(id), options)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

    }

});
