/**
 * Created by dbichenko on 28.11.2016.
 */
angular.module('sinvoice-desktop').controller('balanceAdjustmentController',
    function ($scope, $translate, Modal, AuthDataStorage, DocumentModalCallbackContainer, BusinessChecker, DocumentTypeConstants) {
    $scope.template.$onlyOpenedReliableDocuments       = false;
    $scope.template.$isOccasionalDocsRelatingAllowed   = true;

        $scope.template.$reliableTypesOfAnyStatusDocuments = [
        ];

        $scope.$watch('editedDocument.isPositive', function (value) {

            // String to bool conversion - "1" and "0" values.
            $scope.template.$reliableTypesOfOpenDocuments = !Number(value)
                ? [DocumentTypeConstants.TYPE_TAX_INVOICE, DocumentTypeConstants.TYPE_TAX_INVOICE_CREDIT]
                : [DocumentTypeConstants.TYPE_RECEIPT, DocumentTypeConstants.TYPE_RECEIPT_CREDIT]
        });

        (function () {

            if (BusinessChecker.isExemptDealer(AuthDataStorage.getProfile().businessType)) {
                $scope.invoicesAdjustmentAllow = true
            } else {
                $scope.invoicesAdjustmentAllow = false
            }

            if (!$scope.template.relatedDocumentsShowConditions) {
                $scope.template.relatedDocumentsShowConditions = [];
            }

            $scope.template.relatedDocumentsShowConditions.push(function () {
                if (!angular.isDefined($scope.editedDocument.isPositive)) {
                    return false;
                }
                return true;
            });

            if (!$scope.editedDocument.title)
                $scope.editedDocument.title = $translate.instant('documents.balance_adjustment.default_title');

            DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {
                if (!angular.isDefined($scope.editedDocument.isPositive)) {
                    Modal
                        .alert(
                            $translate.instant('documents.balance_adjustment.errors.adjustment_is_not_define',
                                {title: $translate.instant('documents.balance_adjustment.errors.adjustment_is_not_define_title')})
                        )
                        .then(flow.deny);
                } else {
                    flow.allow();
                }

            });
        })();
    });