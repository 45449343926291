/**
 * Created by abichenko on 01.11.17.
 */
angular.module('sinvoice-mobile').directive('singleDocument', function () {


    return {
        scope      : {
            'document'                : '=',
            'sendDocumentDefaultEmail': '@'
        },
        controller : 'singleDocumentController',
        templateUrl: './mobile/components/my-documents/single-document.html',
        link       : function (scope,element,attrs) {
        }
    }

})