(function() {
  var module = angular.module('sinvoice-common.mock', ['ngMockE2E'])

  var mockDelay = 0;
  module.config(['$provide', function($provide) {
    /**
     * simulate real network requests with some delay.
     **/
    $provide.decorator('$httpBackend', function($delegate) {
      var proxy = function(method, url, data, callback, headers) {
        // Ignore customer details (ending with digits) and my_details.html
        if (/([0-9]+|\.html)$/.test(url)) {

          return $delegate.call(this, method, url, data, callback, headers);
        }

        var interceptor = function() {
          var _this = this,
              _arguments = arguments;

          // delay only API calls
          var delay = mockDelay;

          setTimeout(function() {

            callback.apply(_this, _arguments);
          }, delay);

        };

        return $delegate.call(this, method, url, data, interceptor, headers);
      };

      for(var key in $delegate) {
        proxy[key] = $delegate[key];
      }

      return proxy;
    });
  }]);

  module.run(['$httpBackend', 'Url', function($httpBackend, Url) {
    $httpBackend
      .whenGET(/\/assets\/.*/)
      .passThrough()

    $httpBackend
      .whenGET(/\/components\/.*/)
      .passThrough()

    // mocking
    $httpBackend.whenGET(Url.stats.invites()).respond({
      commissions: {
        '2015': [
          {month: 12, count: 10},
          {month: 11, count: 15},
          {month: 10, count: 20}
        ],
        '2016': [
          {month: 1, count: 20},
          {month: 2, count: 100}
        ]
      },

      customers: {
        '2015': [
          {month: 7, count: 2000},
          {month: 9, count: 2000},
          {month: 11, count: 1000},
          {month: 12, count: 20},
          {month: 8, count: 2000},
          {month: 6, count: 2000},
          {month: 10, count: 2000},
          {month: 5, count: 2000}
        ],
        '2016': [
          {month: 1, count: 1500},
          {month: 2, count: 300},
        ]
      },
    });

    // mocking
    $httpBackend.whenGET(Url.stats.users()).respond([
      {
          "first_name": "Georgi",
          "last_name": "Novakov",
          "email": "georgi1@abv.bg",
          "accepted": 1,
          "commission": 0,
          "income": 0,
          "status": "registered"
      },
      {
          "first_name": "gosho",
          "last_name": "georgiev",
          "email": "vmalevski@develop-soft.com",
          "accepted": null,
          "commission": 0,
          "income": 0,
          "status": "Not registered yet"
      },
      {
          "first_name": "gosho",
          "last_name": "georgiev",
          "email": "vmalevski1@develop-soft.com",
          "accepted": null,
          "commission": 0,
          "income": 0,
          "status": "Not registered yet"
      },
      {
          "first_name": "gosho",
          "last_name": "georgiev",
          "email": "vmalevski2@develop-soft.com",
          "accepted": null,
          "commission": 0,
          "income": 0,
          "status": "Not registered yet"
      },
      {
          "first_name": "gosho",
          "last_name": "georgiev",
          "email": "vmalevski3@develop-soft.com",
          "accepted": null,
          "commission": 0,
          "income": 0,
          "status": "Not registered yet"
      },
      {
          "first_name": "gosho",
          "last_name": "georgiev",
          "email": "vmalevsk5i@develop-soft.com",
          "accepted": null,
          "commission": 0,
          "income": 0,
          "status": "Not registered yet"
      },
      {
          "first_name": "gosho",
          "last_name": "georgiev",
          "email": "vmalevski41@develop-soft.com",
          "accepted": null,
          "commission": 0,
          "income": 0,
          "status": "Not registered yet"
      }
    ])

    $httpBackend
      .whenPOST(Url.invitations.send())
      .respond({
        success: true,
        status: 200
      })

    $httpBackend
      .whenGET(Url.profile.profile())
      .respond({
        "firstName": "Iliyan",
        "lastName": "Betovski",
        "email": "ibetovski@gmail.com",
        "phone": "345345",
        "firstQuestion": {
          "id": 1,
          "question": "What is the name of your elementary school?",
          "order_number": 1,
          "active": true
        },
        "firstAnswer": "nikoe",
        "secondQuestion": {
          "id": 1,
          "question": "What is the name of your elementary school?",
          "order_number": 1,
          "active": true
        },
        "secondAnswer": "nikoe",
        "referralUrl": "ffffff123a"
      })
  }])
})()
