/**
 * Created by abichenko on 11.10.17.
 */
angular.module('sinvoice-common').factory('DataStorage', function () {


    var storage = {};


    return {
        getValue: function (key) {
            return storage[key];
        },
        setValue: function (key, value) {
            storage[key] = value;
        },
        reset   : function () {
            storage = {};
        }
    }

});