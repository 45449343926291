/**
 * Created by dbichenko on 06.01.2017.
 */
angular.module('sinvoice-desktop').directive('customerDocumentsTable', function ($timeout, $compile) {


    return {
        template: "" +
        "<table id='datatable-{{ $id }}' " +
        "   dt-instance='instance'" +
        "   dt-options='options'" +
        "   dt-columns='columns'" +
        "   class='table docsTable table-striped table-hover table-bordered mb0 language-based-table'>" +
        "   <thead></thead>" +
        "</table>" +
        "<table>" +
        "   <tfoot id='tfoot-{{ $id }}'>" +
        "       <tr>" +
        "           <th ng-repeat='column in columns track by $index'>" +
        "           </th>" +
        "       </tr>" +
        "   </tfoot>" +
        "</table>",

        scope: {
            'instance'           : '=',
            'options'            : '=',
            'columns'            : '='
        },
        link : function (scope, element, attrs) {

            $timeout(function () {

                var tfoot = $('#tfoot-' + scope.$id);
                var table = $('#datatable-' + scope.$id);

                tfoot.find('th').removeAttr('ng-repeat');
                tfoot.find('th').first().append($('<span class="search-table"></span>'));

                table.append(tfoot);

                table.attr('datatable', '');


                $compile(table)(scope);


            });

        }
    }

});