/**
 * Created by dbichenko on 03.08.2016.
 */
angular.module('sinvoice-desktop').controller('paragraphTotalController',
    function ($scope,
              $timeout,
              Document,
              Modal,
              Events,
              DiscountCalculator,
              DocumentTaxCalculator,
              DocumentSummaryDataProvider,
              ParagraphCheck,
              DocumentInitializer,
              Currency,
              $translate) {


        var initialDiscounts = [];
        var initialComment   = '';

        $scope.getDiscountAmount = function () {
            var discounts = $scope.editedDocument.discounts;
            if (!discounts || !discounts[0])
                return 0;

            return DiscountCalculator.calculateValue(discounts[0], $scope.template.$itemsTotalBeforeDiscount)

        };

        $scope.updateVATOptions = function () {
            $scope.editedDocument.selectedVATParameters = $scope.selectedVatOption;
            Events.triggerEvent('vatOptions.change', $scope.editedDocument.selectedVATParameters);
            $scope.template.vatOptionsShow  = !$scope.template.vatOptionsShow;
            $scope.isVatApplyButtonDisabled = true;
        };

        $scope.confirmComment = function () {
            if (!$scope.template.showAddComment) {
                $scope.template.showAddComment = true;
                $scope.showComment             = true;

            }
            $scope.editableComment = false;
        };

        $scope.updateComment = function () {
            if (!$scope.template.showAddComment) {
                $scope.editedDocument.comment = null;
                $scope.showComment            = false;
                return;
            }
            $scope.editableComment = false;
            $scope.showComment     = true;
        };

        $scope.toggleComment = function () {
            $scope.showComment = $scope.showComment ? false : true;
        };

        // Reset content in comment area
        function resetComment() {
            $scope.template.showAddComment = false;
            $scope.editedDocument.comment  = null;
            $scope.showComment             = false;
        }

        $scope.editComment = function () {
            $scope.editableComment = $scope.editableComment ? false : true
        };


        function importVariablesFromRelatedDocs(documents) {

            var discountSum      = 0;
            var importedComments = [];
            var roundingSum      = 0;

            if (initialDiscounts.length) {
                $scope.editedDocument.discounts.forEach(function (disc) {
                    discountSum += Number(disc.absoluteValue);
                })
            }

            documents.forEach(function (document) {

                if (document.roundingValue)
                    roundingSum += document.roundingValue;

                if (document.comment)
                    importedComments.push(document.comment);

                document.discounts.forEach(function (discount) {
                    discountSum += Currency.convert(
                        Number(discount.absoluteValue),
                        document.currencies,
                        $scope.editedDocument.currencies,
                        $scope.editedDocument.nisCourse
                    );
                });

            });


            var commentString             = importedComments.join("\n\n");
            $scope.editedDocument.comment = initialComment + (initialComment != '' ? '\n\n' : '') + commentString;
            if ($scope.editedDocument.comment != '') {
                $scope.showComment = true;
            }

            if (roundingSum) {
                $scope.editedDocument.paragraphs.push({
                    unitPrice               : roundingSum,
                    isImportedFromRelatedDoc: true,
                    quantity                : 1,
                    details                 : $translate.instant('documents.item_rounding_margin_label'),
                    displayWithVat          : false
                });
            }


            if (!discountSum) {
                removeDiscounts();
            } else {
                $scope.editedDocument.discounts = [
                    {value: discountSum, type: 'fixed', absoluteValue: discountSum}
                ];
            }

        }


        function updateTemplateVariables() {
            $scope.template.showAddComment = !!($scope.editedDocument.comment);
            $scope.showComment             = !!($scope.editedDocument.comment);

            if (typeof($scope.template.showRoundedTotal) != 'undefined')
                return;

            if (typeof($scope.editedDocument.isRoundingEnabled) == 'undefined') {
                $scope.template.showRoundedTotal = true;
            } else {
                $scope.template.showRoundedTotal = !!($scope.editedDocument.isRoundingEnabled);
            }

        }

        function removeDiscounts() {
            $scope.editedDocument.discounts = [];
        }

        $scope.$watch(function () {
            var template       = $scope.template;
            var editedDocument = $scope.editedDocument;

            return editedDocument.displayWithVat +
                template.$itemsExcludedVatTotal +
                template.$itemsIncludedVatTotal +
                template.$itemsIncludedVatTotalWithVat;

        }, function () {
            calculateTemplateValues();
        });

        function calculateTemplateValues() {


            var itemsExcludedVatTotal        = $scope.template.$itemsExcludedVatTotal;
            var itemsIncludedVatTotal        = $scope.template.$itemsIncludedVatTotal;
            var itemsIncludedVatTotalWithVat = $scope.template.$itemsIncludedVatTotalWithVat;

            var totalAmount        = itemsExcludedVatTotal + itemsIncludedVatTotal;
            var totalAmountWithVat = itemsExcludedVatTotal + itemsIncludedVatTotalWithVat;
            var vatAmount          = totalAmountWithVat - totalAmount;
            if (vatAmount < 0)
                vatAmount = 0;


            $scope.template.$vatAmount = parseFloat(vatAmount);

            $scope.template.$finalItemsTotal        = parseFloat(totalAmount);
            $scope.template.$finalItemsTotalWithVat = parseFloat(totalAmountWithVat);

            calculateItemsTotalNIS();

        }

        function calculateItemsTotalNIS() {

            var finalAmountNIS = Currency.convertAmount(
                parseFloat($scope.template.$finalItemsTotalWithVat),
                $scope.editedDocument.currencies.name,
                'NIS',
                $scope.editedDocument.nisCourse
            );

            $scope.template.$finalItemsTotalWithVatNIS = finalAmountNIS || 0;

            $scope.template.$vatAmountNIS = Currency.convertAmount(
                $scope.template.$vatAmount,
                $scope.editedDocument.currencies.name,
                'NIS',
                $scope.editedDocument.nisCourse
            );
        }

        DocumentInitializer.addInitCallback(function () {

            updateTemplateVariables();
            $scope.isVatApplyButtonDisabled = true;
            $scope.resetComment             = resetComment;
            $scope.template.roundingAmount  = 0;
            $scope.template.vatTaxDisplay   = DocumentTaxCalculator.getTaxPercentage();
            $scope.template.vatOptionsShow  = false;
            $scope.editableComment          = false;
            $scope.isVATNotAllowed          = ParagraphCheck.isVATNotAllowed;

            if (ParagraphCheck.isVATNotAllowed()) {
                $scope.editedDocument.selectedVATParameters = 'exclude';
            } else {
                $scope.editedDocument.selectedVATParameters = $scope.editedDocument.selectedVATParameters || 'include';
            }

            initialDiscounts         = angular.copy($scope.editedDocument.discounts ? $scope.editedDocument.discounts : []);
            initialComment           = angular.copy($scope.editedDocument.comment ? $scope.editedDocument.comment : '');
            $scope.selectedVatOption = angular.copy($scope.editedDocument.selectedVATParameters);

            $scope.$watch(function () {
                return JSON.stringify(DocumentSummaryDataProvider.getSummaries(
                    $scope.template,
                    $scope.editedDocument.selectedVATParameters,
                    $scope.editedDocument.type.docType,
                    $scope.editedDocument.discounts,
                    $scope.editedDocument.currencies,
                    $scope.editedDocument.nisCourse
                ));
            }, function (value) {
                $scope.summaryData = JSON.parse(value);
            });

            $scope.$watch('selectedVatOption', function (newVal, oldVal) {
                if (!newVal || newVal == oldVal || newVal == $scope.editedDocument.selectedVATParameters) {
                    $scope.isVatApplyButtonDisabled = true;
                    return;
                }
                $scope.isVatApplyButtonDisabled = false;
            });

            $scope.$watch('editedDocument.selectedVATParameters', function (newVal, oldVal) {
                if (!newVal || newVal == oldVal) {
                    return
                }
                $scope.selectedVatOption = $scope.editedDocument.selectedVATParameters;
            });

            $scope.$watch('template.showRoundedTotal', function (newValue) {
                $scope.editedDocument.isRoundingEnabled = newValue;
            });

            $scope.$watch('template.$finalItemsTotalWithVat', function (newValue) {
                $scope.documentForm.$setValidity('totalAmount', newValue > 0);
            });

            $scope.$watch('editedDocument.nisCourse', function (newVal) {
                calculateItemsTotalNIS();
            });

            Events.subscribeToEvent('relatedDocs.selectedFromTable', $scope, function (e, newDocs, previousDocs) {

                if (!$scope.template.isEditable)
                    return;

                var isRounding = true;
                importVariablesFromRelatedDocs(newDocs);
                updateTemplateVariables();
                for (var i = 0; i < newDocs.length; i++) {
                    if (newDocs[i].isRoundingEnabled) {
                        isRounding = true;
                        break;
                    } else {
                        isRounding = false;
                    }
                }
                $scope.template.showRoundedTotal = isRounding;

            });

            Events.subscribeToEvent('relatedDocs.checkedHasRelatedDocuments', $scope, function (e, newVal) {
                if (!newVal) {
                    updateTemplateVariables();
                }
            });

        });

    });