/**
 * Created by dbichenko on 17.11.2016.
 */
angular.module('sinvoice-desktop').controller('closeDocumentBlockController', function ($scope, Modal, Document, DocumentModal, $translate, DocumentConstants) {

    $scope.documentIsClosable = function () {
        return $scope.editedDocument.id && editedDocumentIsActive() && $scope.editedDocument.type.isClosable
    };


    $scope.closeDocument = function () {
        Modal
            .confirm(
                $translate.instant('documents.close_document_message'),
                null,
                null,
                {title:$translate.instant('documents.close_document_message_title')}
            )
            .then(function () {
                Document.closeDocument($scope.editedDocument.id).then(function () {
                    DocumentModal.closeAll();
                    DocumentModal.notifyAboutUpdate();
                })
            })
    };

    function editedDocumentIsActive() {
        return $scope.editedDocument.status == DocumentConstants.STATUS_OPEN;
    }
});