/**
 * Created by abichenko on 17.11.17.
 */
angular.module('sinvoice-common')
    .factory('Bank', function ($q, Url, API) {
        return {
            getAll: function () {

                var defer = $q.defer();

                API.get(Url.banks.all(), {})
                    .success(function (data) {
                        defer.resolve(data)
                    })
                    .error(function (error) {
                        defer.reject()
                    });

                return defer.promise;

            }
        }
    })
    .factory('Language', function ($q) {
        return {
            getData: function () {
                var deferred = $q.defer();

                deferred.resolve([
                    'he',
                    'en'
                ]);

                return deferred.promise;
            }
        }
    })

    .factory('Currency', function ($http, $q, $log, API, Url) {
        var todayCurrencyCourses   = {};
        var currencyCoursesHistory = {};
        var dataDefer              = $q.defer();


        return {
            populateCurrencies: function () {
                API.get(Url.currencies.all(), {})
                    .success(function (data) {
                        dataDefer.resolve(data);
                        data.forEach(function (currency) {
                            todayCurrencyCourses[currency.name]   = currency.nisCourse;
                            currencyCoursesHistory[currency.name] = currency.rateHistory;
                        })
                    })
                    .error(function (error) {
                        dataDefer.reject(error);
                    });

                return dataDefer.promise;

            },

            getData: function () {
                return dataDefer.promise;
            },

            getCurrencyCourseForDate    : function (key, date) {
                var dateKey = momentWrapped(date).format('YYYY-MM-DD');

                return currencyCoursesHistory[key][dateKey]['nisCourse'];
            },
            getCurrencyCourse           : function (key) {
                return todayCurrencyCourses[key];
            },
            convert                     : function (amount, fromCurrency, toCurrency, customNisCourse) {
                return convertAmount(amount, fromCurrency.name, toCurrency.name, customNisCourse)
            },
            convertAmount               : convertAmount,
            recalculateAmountToNewCourse: function (amount, oldCourse, newCourse) {
                var coeff = (oldCourse / newCourse).toPrecision(8);
                return Math.round(parseFloat(Number(amount) / coeff) * 100) / 100
            }
        };


        function convertAmount(amount, from, to, customNisCourse) {


            var currencies = angular.copy(todayCurrencyCourses);

            var fromCourse = currencies[from];
            var toCourse   = currencies[to];

            if (!fromCourse || !toCourse) {
                throw new Error('Currency not found')
            }

            if (customNisCourse && to == 'NIS') {
                fromCourse = customNisCourse;
            }
            if (customNisCourse && from == 'NIS') {
                toCourse = customNisCourse;
            }

            var coeff = (fromCourse / toCourse).toPrecision(8);
            return parseFloat(Number(amount) * coeff);

        }
    })


    .factory('BusinessChecker', function () {


        return {
            isExemptDealer: function (businessType) {
                return businessType.type === 'exempt_dealer';
            }
        }
    })
    .factory('BankChecker', function () {


        return {
            isCustomBank: function (bank) {
                return bank && bank.type === 'custom';
            }
        }

    });
