/**
 * Created by dbichenko on 26.09.2016.
 */
angular.module('sinvoice-desktop').controller('refuseController', function ($stateParams, $state, $scope, API, Url) {

    $scope.inviteBy = null;

    $scope.getName = function () {
        return [$scope.inviteBy]
            .filter(function (value) {
                return !!value;
            })
            .join(' ');
    };

    function refuse(token) {
        return new Promise(
            function (resolve, reject) {
                API.post(Url.auth.refuseInviteToken(token))
                    .success(function (data) {
                        $scope.inviteBy = data.inviteBy;
                        resolve();
                    })
                    .error(function () {
                        $scope.isInactive = true;
                    })
            });
    }

    (function () {

        if ($stateParams.inviteToken) {
            refuse($stateParams.inviteToken);
        } else {
            $scope.isInactive = true;
        }
    })();

});