/**
 * Created by abichenko on 13.02.18.
 */
angular.module('sinvoice-common').factory('Communication', function (API, Url, $q) {

    return {
        getData           : function () {
            var deferred = $q.defer();
            API.get(Url.customers.all(), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getSubmissionTypes: function () {

            var deferred = $q.defer();
            API.get(Url.customers.getSubmissions())
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        create            : function (communication) {

            var deferred = $q.defer();
            API.post(Url.customers.createCommunication(), communication)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;

        },
        update            : function (id, communication) {
            var deferred = $q.defer();
            API.patch(Url.customers.updateCommunication(id), communication)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        delete            : function (id) {
            var deferred = $q.defer();
            API.delete(Url.customers.deleteCommunication(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        close            : function (id) {
            var deferred = $q.defer();
            API.post(Url.customers.closeCommunication(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        get               : function (id) {
            var deferred = $q.defer();
            API.get(Url.customers.getCommunication(id))
                .success(function (data) {
                    deferred.resolve(data)
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;

        }

    }


})