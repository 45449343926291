angular.module('sinvoice-desktop').controller('representativesInvitedController',
    function ($scope,
              $translate,
              DTOptionsBuilder,
              DTColumnBuilder,
              ManagedCustomers,
              DataTableLanguageSettings,
              DataTableBootstrapSettings,
              DataTableDatepickerLabels,
              $filter,
              $compile,
              TopnavContainer,
              CustomerModal,
              ManagedCustomersModal,
              DataTableFunctions,
              NotificationModal,
              ManagedCustomerConstants,
              Events,
              ManagedCustomersTranslator) {

        // Load blue top menu
        (function () {
            loadNavbarMenu();
        })();

        // Load blue top menu
        function loadNavbarMenu() {

            TopnavContainer.addElement('managedCustomers', './desktop/components/customers/representatives/managed-customers-navbar.html');
            $scope.$on('$destroy', function () {
                TopnavContainer.removeElement('managedCustomers');
            });
        }


        function loadTable() {


            $scope.dtInstance = {};
            $scope.dtColumns  = initializeDataColumns();
            $scope.dtOptions  = initializeDataTable(function () {
                return ManagedCustomers.getInvited();
            });

            Events.subscribeToEvent('invites.update', $scope, function () {
                $scope.dtInstance.reloadData(null, false);
            });
        }

        loadTable();


        $scope.resendInvitation = function (id) {
            ManagedCustomers.resendInvitations([id]).then(function () {
                $scope.dtInstance.reloadData(null, false);
            });
        };

        function resendInvitations() {
            var invitations = getResendableInvitations();
            ManagedCustomersModal.resendInvitations(invitations);
        }

        function getResendableInvitations() {
            return DataTableFunctions
                .getSelectedData($scope.dtInstance.DataTable)
                .filter(function (customer) {
                    return !!customer.isResendPossible
                });
        }


        function initializeDataColumns() {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('select')
                    .withOption('width', '50px')
                    .withTitle('<dt-select-all-checkbox/>')
                    .notSortable()
                    .withClass('select-checkbox')
                    .renderWith(function (data) {
                        return '<dt-select-checkbox/>';
                    }),

                DTColumnBuilder
                    .newColumn('status')
                    .withOption('width', '77px')

                    .withTitle($translate('customers.managed_customer.invited_table.status'))
                    .withOption('render', {
                        'filter' : function (data) {
                            return data;
                        },
                        'display': function (data) {
                            return ManagedCustomersTranslator.getTranslatedStatus(data)
                        }
                    }),

                DTColumnBuilder
                    .newColumn('name')
                    .withTitle($translate('customers.managed_customer.invited_table.user_name')),


                DTColumnBuilder
                    .newColumn('createdAt')
                    .withTitle($translate('customers.managed_customer.invited_table.invitation_date'))
                    .withOption('width', '150px')
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data) {
                        return $filter('dpickerDateFormatter')(data)
                    }),

                DTColumnBuilder
                    .newColumn('email')
                    .withTitle($translate('customers.managed_customer.invited_table.email')),


                DTColumnBuilder
                    .newColumn('phone')
                    .withOption('width', '90px')
                    .withTitle($translate('customers.managed_customer.invited_table.phone')),

                DTColumnBuilder
                    .newColumn('send')
                    .withOption('width', '90px')
                    .withTitle($translate('customers.managed_customer.invited_table.send_invite'))
                    .notSortable()
                    .renderWith(function (data, type, full) {
                        return full.isResendPossible
                            ? '' +
                            '   <button type="button" subscription-edit-action-button="" ng-click="resendInvitation(' + full.id + ')" class="the_button_2 tbl_send_btn">' +
                            '   ' + $translate.instant('customers.managed_customer.invited_table.send_button_label') +
                            '   </button>'
                            : '';
                    })


            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;

        }


        function initializeDataTable(promise) {
            return DTOptionsBuilder

                .fromFnPromise(promise)
                .withBootstrap()
                .withOption('sDom', "<'row'<'col-xs-12 text-left mb20'B>>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('autoWidth', false)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withOption('createdRow', function (row, data, dataIndex) {
                    $compile(row)($scope);
                })
                .withColumnFilter({
                    aoColumns: [
                        null,
                        {
                            type  : 'select',
                            bRegex: false,
                            bSmart: false,
                            values: [
                                {
                                    value: ManagedCustomerConstants.STATUS_INVITED,
                                    label: ManagedCustomersTranslator.getTranslatedStatus(ManagedCustomerConstants.STATUS_INVITED)
                                },
                                {
                                    value: ManagedCustomerConstants.STATUS_DECLINED,
                                    label: ManagedCustomersTranslator.getTranslatedStatus(ManagedCustomerConstants.STATUS_DECLINED)
                                }
                            ]
                        },
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'date-range', bRegex: true, bSmart: true, sRangeFormat: DataTableDatepickerLabels.fromToLabels()},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        null
                    ]
                })
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withButtons([
                    {
                        extend       : 'subscriptionEditActionButton',
                        text         : '<i class="mobile"></i>' + $translate.instant('customers.sms'),
                        extendOptions: {
                            action         : function (e, dt, node, config) {

                                var invites = DataTableFunctions.getSelectedData(dt);
                                NotificationModal.sendSMSToManagedCustomerInvites(invites);
                            },
                            enabledCallback: function (e, dt) {

                                var invitesWithPhones = DataTableFunctions
                                    .getSelectedData(dt)
                                    .filter(function (invite) {
                                        return !!invite.phone;
                                    });

                                return !!invitesWithPhones.length;
                            }
                        }
                    },
                    {
                        extend       : 'subscriptionEditActionButton',
                        text         : '<i class="envelope"></i>' + $translate.instant('customers.email'),
                        extendOptions: {
                            action         : function (e, dt, node, config) {
                                var invites = DataTableFunctions.getSelectedData(dt);
                                NotificationModal.sendEmailToManagedCustomerInvites(invites);
                            },
                            enabledCallback: function (e, dt) {
                                var invitesWithEmails = DataTableFunctions
                                    .getSelectedData(dt)
                                    .filter(function (invite) {
                                        return !!invite.email;
                                    });

                                return !!invitesWithEmails.length;
                            }
                        }
                    },
                    {
                        text  : '<i class="newspaper-o"></i>' + $translate.instant('customers.newslater'),
                        action: function (e, dt, node, config) {
                            alert('Newsletter activated');
                        }
                    },
                    {
                        extend       : 'subscriptionEditActionButton',
                        text         : $translate('customers.managed_customer.invited_table.resend_invitation'),
                        extendOptions: {
                            enabledCallback: function (e, dt) {
                                var resendableInvitations = getResendableInvitations();
                                return !!resendableInvitations.length;
                            },
                            action         : function (e) {
                                resendInvitations();
                            }
                        }
                    }
                ]);

        }


    });



