/**
 * Created by abichenko on 20.03.18.
 */
angular.module('sinvoice-desktop').controller('topnavSearchResultController',
    function ($scope, CustomerModal, DocumentModal, ItemModal, $stateParams, Search, TopnavSearch, Events, $state) {

        var searchDebounced;

        $scope.spinner = {enabled: true};

        $scope.viewDocument = function (id) {
            DocumentModal.viewDocument(id);
        };

        $scope.viewCustomer = function (id) {
            CustomerModal.editCustomer(id);
        };

        $scope.viewItem = function (id) {
            ItemModal.editItem(id);
        };

        $scope.viewReport = function (route, params) {
            $state.go(route, params);
        };


        function updateResults() {
            $scope.spinner.enabled = true;

            return Search
                .getFormattedSearchResults($scope.query)
                .then(function (result) {

                    $scope.documents = result.documents;
                    $scope.customers = result.customers;
                    $scope.items     = result.items;
                    $scope.reports   = result.reports;
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                    $scope.isAwaiting      = false;
                })
        }


        Events.subscribeToEvent('topnav.query.changed', $scope, function () {
            $scope.query      = TopnavSearch.getCurrentQueryString();
            $scope.isAwaiting = true;
            searchDebounced();
        });

        searchDebounced = _.debounce(function () {
            updateResults();
        }, 1000);

        (function () {

            $scope.query = $stateParams.query;

            TopnavSearch.setCurrentQueryString($scope.query);

            updateResults();

        })();
    });