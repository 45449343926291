/**
 * Created by dbichenko on 04.10.2016.
 */
angular.module('sinvoice-desktop')


    .directive('scrollableOnDrag', function () {

        return {
            link: function (scope, element, attrs) {

                var handler = autoScroll([window], {
                    margin           : 100,
                    pixels           : 40,
                    scrollWhenOutside: true,
                    autoScroll       : function () {
                        return this.down;
                    }
                });

                scope.$on('$destroy', function () {
                    handler.destroy();
                })
            }
        }

    });