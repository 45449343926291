angular.module('sinvoice-common').service('$localStorage', function ($window) {

    this.set = function (key, value) {
        $window.localStorage[key] = value;
    };

    this.get = function (key, defaultValue) {
        return $window.localStorage[key] || defaultValue;
    };

    this.setItem = function (key, value) {
        $window.localStorage[key] = JSON.stringify(value);
    };

    this.getItem = function (key) {
        return JSON.parse($window.localStorage[key] || '{}');
    };

    this.remove = function (key) {
        $window.localStorage.removeItem(key);
    };

    this.clearItem = function (key) {
        delete $window.localStorage[key];
    };

    this.clear = function () {
        $window.localStorage.clear();
    };
});