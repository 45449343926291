angular.module('sinvoice-desktop').controller('detailsController',
    function ($scope, $rootScope, Modal, Language, Events, Customer, DocumentModal, ProfileChecker, $timeout, DetailsBackup, DesktopModal, AuthDataStorage) {

        $scope.paymentTerms = [];
        $scope.editUserForm = {};


        $scope.isEnglishAvailable = function () {
            return ProfileChecker.isEnglishAvailable(AuthDataStorage.getProfileBusinesses());
        };

        $scope.editGeneral = function (customer) {
            $scope.editedDetails = {
                id              : customer.id,
                status          : customer.status,
                name            : customer.name,
                nameEn          : customer.nameEn,
                businessnumber  : customer.businessnumber,
                email           : customer.email,
                phone           : customer.phone,
                fax             : customer.fax,
                address         : customer.address,
                addressEn       : customer.addressEn,
                customerType    : customer.customerType,
                accountingNumber: customer.accounting_number,
                comment         : customer.comment,
                language        : customer.language,
                paymentTerm     : customer.paymentTerm,
                paymentTermOther: customer.paymentTermOther
            };
            DetailsBackup.registerTrackedSection('details', angular.copy($scope.editedDetails), $scope.editedDetails);
        };

        $scope.cancelGeneral = function () {
            if (DetailsBackup.isChanged('details')) {
                DesktopModal.unsavedChanges().then(function () {
                    $timeout(doCancelGeneral);
                })
            } else {
                return doCancelGeneral();
            }
        };

        $scope.saveGeneral = function () {
            Customer
                .updateGeneral($scope.editedDetails.id, $scope.editedDetails)
                .then(function (data) {
                    $scope.edited.customer = data;
                    Events.triggerEvent('customer.update');
                    reload();
                    doCancelGeneral();
                });
        };

        function doCancelGeneral() {
            $scope.editedDetails = null;
            DetailsBackup.unregisterTrackedSection('details');
        }

        function reload() {
            Events.triggerEvent('customer.update');
        }

        function loadLanguages() {
            return Language.getData().then(function (data) {
                $scope.languages = data.filter(function (lang) {
                    if (lang === 'en') {
                        return $scope.isEnglishAvailable() || $scope.edited.customer.language === 'en';
                    } else
                        return true;
                });
            });
        }

        function loadPaymentTerms() {
            Customer.getPaymentTerms().then(function (data) {
                $scope.paymentTerms = data;
            })
        }

        function loadStatuses() {

            $scope.statuses = [
                {id: 0, name: 'customers.edit.details.general.statuses.inactive'},
                {id: 1, name: 'customers.edit.details.general.statuses.active'}
            ]
        }

        function initializeValidators() {
            $scope.validators = {}
        }

        (function () {
            DocumentModal.subscribeToUpdate($scope, function () {
                Customer.reloadCustomer($scope.edited.customer.id).then(function (data) {
                    $scope.edited.customer     = data;
                    $rootScope.editingCustomer = data;
                })
            });
            loadLanguages();
            loadPaymentTerms();
            loadStatuses();
            initializeValidators();
        })();


    });