/**
 * Created by abichenko on 20.11.17.
 */
angular.module('sinvoice-mobile').controller('itemsBlockController', function ($scope, Item, DocumentItemAmountCalculator, Events) {


    $scope.spinner = {enabled: true};

    $scope.selected = {};

    $scope.items = [];

    $scope.addParagraph = function () {
        $scope.paragraphs.push({
            quantity: 1
        });
    };


    $scope.removeParagraph = function (index) {
        $scope.paragraphs.splice(index, 1);
    };


    $scope.addExistingItem = function (selectedExistingItem) {

        if (!$scope.selected.existingItem)
            return;

        var selectedExistingItem = $scope.selected.existingItem;

        $scope.paragraphs.push({
            id       : selectedExistingItem.id,
            sku      : selectedExistingItem.sku,
            name     : selectedExistingItem.name,
            details  : selectedExistingItem.description,
            unitPrice: selectedExistingItem.price,
            item     : selectedExistingItem,
            quantity: 1
        });

        $scope.selected.existingItem = null;
    };


    $scope.$watch(function () {
        return JSON.stringify($scope.discountsModel);
    }, calculateItemsTotal);

    $scope.$watch(function () {

        var result = 0.0;

        $scope.paragraphs.forEach(function (paragraph) {
            result += paragraph.totalVat + paragraph.total;
        });

        result += $scope.isRoundingEnabled;

        return result;

    }, calculateItemsTotal);


    function calculateItemsTotal() {

        var paragraphs           = angular.copy($scope.paragraphs);
        var beforeDiscountTotals = DocumentItemAmountCalculator.getItemBeforeDiscountTotals(paragraphs);
        var template             = $scope.templateVariable;

        var itemsExcludedVatTotal        = beforeDiscountTotals.itemsExcludedVatTotal;
        var itemsIncludedVatTotal        = beforeDiscountTotals.itemsIncludedVatTotal;
        var itemsIncludedVatTotalWithVat = beforeDiscountTotals.itemsIncludedVatTotalWithVat;

        template.$itemsExcludedVatBeforeDiscount = itemsExcludedVatTotal;
        template.$itemsIncludedVatBeforeDiscount = itemsIncludedVatTotal;
        template.$itemsTotalBeforeDiscount       = itemsExcludedVatTotal + itemsIncludedVatTotal;


        var discount;
        if ($scope.discountsModel && $scope.discountsModel[0]) {
            // Currently we need only one discount.
            discount = $scope.discountsModel[0];
        }

        var afterDiscountTotals = DocumentItemAmountCalculator.getItemAfterDiscountTotals(
            discount,
            itemsIncludedVatTotal,
            itemsExcludedVatTotal,
            itemsIncludedVatTotalWithVat
        );

        var itemsExcludedVatTotalDiscounted        = afterDiscountTotals.itemsExcludedVatTotalDiscounted;
        var itemsIncludedVatTotalDiscounted        = afterDiscountTotals.itemsIncludedVatTotalDiscounted;
        var itemsIncludedVatTotalWithVatDiscounted = afterDiscountTotals.itemsIncludedVatTotalWithVatDiscounted;

        template.$itemsExcludedVatAfterDiscount        = itemsExcludedVatTotalDiscounted;
        template.$itemsIncludedVatAfterDiscount        = itemsIncludedVatTotalDiscounted;
        template.$itemsIncludedVatWithVatAfterDiscount = itemsIncludedVatTotalWithVatDiscounted;


        var afterRoundingTotals = DocumentItemAmountCalculator.getItemAfterRounding(
            $scope.isRoundingEnabled,
            itemsExcludedVatTotalDiscounted,
            itemsIncludedVatTotalDiscounted,
            itemsIncludedVatTotalWithVatDiscounted
        );

        var itemsExcludedVatTotalRounded        = afterRoundingTotals.itemsExcludedVatTotalRounded;
        var itemsIncludedVatTotalWithVatRounded = afterRoundingTotals.itemsIncludedVatTotalWithVatRounded;
        var itemsIncludedVatTotalRounded        = afterRoundingTotals.itemsIncludedVatTotalRounded;

        template.$itemsExcludedVatTotal         = itemsExcludedVatTotalRounded;
        template.$itemsIncludedVatTotal         = itemsIncludedVatTotalRounded;
        template.$itemsIncludedVatTotalWithVat  = itemsIncludedVatTotalWithVatRounded;
        template.$itemsIncludedVatRoundingValue = itemsIncludedVatTotalRounded - itemsIncludedVatTotalDiscounted;
        template.$itemsExcludedVatRoundingValue = itemsExcludedVatTotalRounded - itemsExcludedVatTotalDiscounted;

    }

    Events.subscribeToEvent('currency.change', $scope, function () {
        $scope.paragraphs = [];
    });

    function loadItems() {
        return Item.getData()
            .then(function (data) {
                $scope.items = data;
            })
    };

    (function () {

        loadItems().finally(function () {
            $scope.spinner.enabled = false;
        });

        if (!angular.isDefined($scope.paragraphs))
            $scope.paragraphs = [];
    })();
});