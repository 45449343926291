angular.module('sinvoice-desktop').controller('reportsIndexController',
    function ($scope,
              $compile,
              $q,
              DTColumnBuilder,
              DTOptionsBuilder,
              IncomeForAccountingHelper,
              $translate,
              DataTableLanguageSettings,
              DataTableBootstrapSettings,
              dpickerSettings,
              ReportsExport,
              DocumentExport,
              $filter,
              Report,
              $state,
              ReportRouteResolver,
              TaxPeriodProvider,
              $stateParams) {

        $scope.spinner      = {enabled: true};
        $scope.currentState = $state.current.name;
        $scope.$emit('onLoadReportPage', {
            state: $scope.currentState,
            page : {
                title      : $filter('translate')('reports.pages.recentReports'),
                description: '',
                alias      : 'reports.pages.recentReports'
            }
        });

        function initializeDataTable(promise) {


            var dtOptions = DTOptionsBuilder
                .fromFnPromise(promise)
                .withBootstrap()
                .withOption('sDom', "<'row'<'col-xs-12 text-left mb20'B>>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('autoWidth', false)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withBootstrapOptions(DataTableBootstrapSettings).withButtons([])
                .withOption('order', [3, "desc"])
                .withOption('fnCreatedRow', rowCallback);

            return dtOptions;
        }

        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
            $compile(nRow)($scope);

            $(nRow).on('click', '.view', function (e) {
                var route  = ReportRouteResolver.resolve(aData.type);
                var params = ReportRouteResolver.resolveFiltersToStateParams(
                    aData.filters,
                    aData.id,
                    aData.from,
                    aData.to
                );
                $state.go(route, params);

                e.stopPropagation();
            });

            setupMivneExportButton();

            setupWizcountExportButton();

            $(nRow).on('click', '.print', function (e) {
                ReportsExport.print(aData);
                e.stopPropagation();
            });

            $(nRow).find('.pdf-export').on('click', function (e) {
                ReportsExport.showAsPDF(aData);
            });

            $(nRow).find('.excel-export').on('click', function (e) {
                ReportsExport.downloadAsXLS(aData);
            });

            $(nRow).find('.csv-export').on('click', function (e) {
                ReportsExport.downloadAsCSV(aData);
            });


            $(nRow).on('click', '.email', function (e) {
                ReportsExport.email(aData);
                e.stopPropagation();
            });
            return nRow;


            function setupMivneExportButton() {
                var mivneExportButton = $(nRow).find('.mivne-export');

                if (IncomeForAccountingHelper.isMivneExportingAllowed(aData)) {
                    mivneExportButton.on('click', function (e) {
                        DocumentExport.showMivneSummary(_.map(aData.content.docs, 'id'));
                    });
                } else {
                    mivneExportButton.attr('disabled', '');
                }
            }

            function setupWizcountExportButton() {
                var wizcountExportButton = $(nRow).find('.wizcount-export');

                if (IncomeForAccountingHelper.isWizcountExportingAllowed(aData)) {
                    wizcountExportButton.on('click', function (e) {
                        DocumentExport.downloadAsWizcount(_.map(aData.content.docs, 'id'));
                    });
                } else {
                    wizcountExportButton.attr('disabled', '');
                }
            }

        }

        function initializeDataColumns() {
            var dtColumns = [

                DTColumnBuilder
                    .newColumn('creationDate')
                    .withOption('width', '18%')
                    .withOption('type', 'date-formatted')
                    .withTitle($translate.instant('reports.table.date'))
                    .renderWith(function (data) {
                        var date = new momentWrapped(data).format(dpickerSettings.dateFormat);
                        if (date != 'Invalid date') {
                            return date;
                        } else {
                            return '';
                        }
                    }),

                DTColumnBuilder
                    .newColumn('type')
                    .withTitle($translate.instant('reports.table.report'))
                    .renderWith(function (data, type, fullData) {
                        return $filter('translateReportType')(data)
                    }),

                DTColumnBuilder
                    .newColumn('details')
                    .withOption('width', '33%')

                    .withClass('details-column')
                    .withTitle($translate.instant('reports.table.details'))
                    .renderWith(function (data, type, fullData) {

                        var from = $filter('dpickerDateFormatter')(fullData.from);
                        var to   = $filter('dpickerDateFormatter')(fullData.to);

                        var html = '';

                        if (fullData.type == 'item_income' && fullData.content.length) {
                            html += '<span class="title-text">' + $translate.instant('reports.table.items') + ': </span>';
                            html += '<span>' + fullData
                                    .content
                                    .map(function (row) {
                                        return row.details
                                    })
                                    .join(', ');
                            html += '</span>';
                            html += '<br>';
                        }


                        html += '<span class="title-text" style="">' + $translate.instant('reports.table.date') + ': </span>';
                        html += '<nobr>';
                        html += from;
                        html += "-";
                        html += to;
                        html += '</nobr>';

                        return html;

                    }),

                DTColumnBuilder
                    .newColumn('actions')
                    .withOption('width', '26%')
                    .notSortable()
                    .withTitle($translate.instant('reports.table.actions'))
                    .renderWith(function (data, type, fullData) {

                        var buttons = [];

                        buttons.push("<button type='button' subscription-edit-action-button='' space-bar-clickable class='email'></button>");
                        buttons.push("<span dt-collection-button class='export'>");

                        if (fullData.type == 'bookkeeping_income') {
                            buttons.push(
                                "<button type='button' class='btn btn-info btn-sm mr10 the_button_2 mivne-export'>" +
                                "   {{'reports.incomeForAccounting.export_to_mivne_button_label'|translate}}" +
                                "</button>"
                            );
                            buttons.push(
                                "<button type='button' class='btn btn-info btn-sm mr10 the_button_2 wizcount-export'>" +
                                "   {{'reports.incomeForAccounting.export_to_wizcount_button_label'|translate}}" +
                                "</button>"
                            );
                            buttons.push(
                                "<button type='button' class='btn btn-info btn-sm mr10 the_button_2 csv-export'>CSV</button>");
                        } else {
                            buttons.push(
                                "<button type='button' class='btn btn-info btn-sm mr10 the_button_2 excel-export'>Excel</button>"
                            );
                        }

                        buttons.push(
                            "<button type='button' class='btn btn-info btn-sm mr10 the_button_2 pdf-export'>PDF</button>"
                        );
                        buttons.push("</span>");
                        buttons.push("<button type='button' space-bar-clickable class='print'></button>");
                        buttons.push("<button type='button' space-bar-clickable class='view'></button>");

                        return "<div class='tbl_type_5_controls language-based-direction'>" + buttons.join('') + "</div>";
                    })


            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;

        }

        function loadTable() {

            var prevPeriod = TaxPeriodProvider.getPreviousTaxPeriod();

            return $q
                .all([
                    IncomeForAccountingHelper.$loadingPromise
                ])
                .then(function () {
                    $scope.dtColumns  = initializeDataColumns();
                    $scope.dtOptions  = initializeDataTable(function () {
                        return Report
                            .getAll($stateParams.report)
                            .then(function (data) {
                                return data.filter(function (value) {

                                    if (!prevPeriod)
                                        return false;

                                    return (moment(value.from) >= prevPeriod.from) && (moment(value.to) <= prevPeriod.to)
                                });
                            });
                    });
                    $scope.dtInstance = {};
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        }

        (function () {
            loadTable();
        })();
    });