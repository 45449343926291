angular
    .module('sinvoice-desktop')
    .service('CustomerModal', [
        '$translate', 'Modal', '$rootScope', 'Customer', 'ScopeProvider',
        function ($translate, Modal, $rootScope, Customer, ScopeProvider) {


            this.createCustomer               = createCustomer;
            this.editCustomer                 = editCustomer;
            this.importCustomersFromCSV       = importCustomersFromCSV;
            this.importCustomersPreviewScreen = importCustomersPreviewScreen;

            return this;


            function importCustomersPreviewScreen(token) {

                return Modal.customDialog({
                    title         : $translate.instant('customers.import_customers_preview_popup.title'),
                    templateUrl   : './desktop/components/customers/import-customers-preview-popup.html',
                    size          : "large",
                    className     : "extra-large-popup",
                    scopeVariables: {
                        operationToken: token
                    },
                    destroyScope  : true
                });
            }

            function importCustomersFromCSV() {

                return Modal.customDialog({
                    title       : $translate.instant('customers.import_customers_from_csv_popup.title'),
                    templateUrl : './desktop/components/customers/import-customers-from-csv-popup.html',
                    size        : "medium",
                    className   : 'centered-popup',
                    destroyScope: true
                });
            }


            function createCustomer() {
                Modal.customDialog({
                    title      : $translate.instant('customers.new.new_customer_title'),
                    templateUrl: './desktop/components/customers/new-customer.html',
                    size       : "large",
                });
            }

            function editCustomer(id) {
                Modal.customDialog({
                    headerUrl     : './desktop/components/customers/edit-customer-header.html',
                    templateUrl   : './desktop/components/customers/edit-customer.html',
                    size          : "large",
                    scopeVariables: {
                        customerId: id,
                        edited    : {sections: {}},
                        activeTab : {
                            customer      : {documents: true},
                            communications: {all: true}
                        }
                    }
                });
            }


        }]);

