/**
 * Created by abichenko on 04.04.18.
 */
angular.module('sinvoice-desktop').controller('customersTableController',
    function ($scope,
              DTOptionsBuilder,
              DTColumnBuilder,
              DataTableBootstrapSettings,
              DataTableLanguageSettings,
              DataTableFunctions,
              DataTableDefaultsWrapper,
              DataTableStorage,
              Customer,
              CustomerModal,
              $compile,
              Events,
              BusinessChecker,
              DataTableDatepickerLabels,
              NotificationModal,
              CustomerImport,
              $filter,
              DirectiveCompiler,
              CustomerExport,
              $state,
              Attribute,
              DocumentModal,
              UserSubscription,
              AuthDataStorage) {

        $scope.tableName   = 'customers';
        $scope.spinner     = {enabled: true};
        $scope.dt          = {};
        $scope.dt.instance = {};

        $scope.changeStatus = function (status) {

            var ids = DataTableFunctions.getSelectedData($scope.dt.instance.DataTable).map(function (customer) {
                return customer.id;
            });

            Customer.changeStatus(status, ids).then(function () {
                $scope.dt.instance.reloadData(null, true);
                $scope.dt.instance.colResize.redraw();
            });

        };

        (function () {

            DataTableStorage
                .retrieve($scope.tableName)
                .then(function (rsp) {
                    $scope.dt.colSetting = rsp.data;
                    return reloadTable();
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                });


            Events.subscribeToEvent('customer.update', $scope, function () {
                $scope.dt.instance.reloadData(null, true);
                $scope.dt.instance.colResize.redraw();
            });

            Events.subscribeToEvent('attributes.update', $scope, function () {
                $scope.spinner.enabled = true;
                reloadTable().finally(function () {
                    $scope.spinner.enabled = false;
                });
            });

            DocumentModal.subscribeToUpdate($scope, function () {
                $scope.dt.instance.reloadData(null, true);
                $scope.dt.instance.colResize.redraw();
            });
        })();


        function reloadTable() {


            return Attribute
                .allAttributes()
                .then(function (attributes) {
                    loadTable(function () {
                        return Customer.getData();
                    }, attributes);
                })
        }

        function loadTable(promiseCallback, attributes) {


            var filterSettings = [
                {
                    type          : 'number-range',
                    bRegex        : true,
                    bSmart        : true,
                    'sRangeFormat': DataTableDatepickerLabels.fromToLabels()
                }, /*balance*/
                {type: 'text', bRegex: true, bSmart: true}, /*businessnumber*/
                {type: 'text', bRegex: true, bSmart: true}, /*language*/
                {type: 'text', bRegex: true, bSmart: true}, /*paymentTerm*/
                {type: 'text', bRegex: true, bSmart: true}, /*comment*/
                {type: 'text', bRegex: true, bSmart: true}, /*address*/
                {type: 'text', bRegex: true, bSmart: true}, /*fax*/
                {type: 'text', bRegex: true, bSmart: true}, /*accounting_number*/
                {type: 'text', bRegex: true, bSmart: true}, /*contactperson*/
                {type: 'text', bRegex: true, bSmart: true}, /*phone*/
                {type: 'text', bRegex: true, bSmart: true}, /*email*/
                {type: 'text', bRegex: true, bSmart: true}, /*id*/
                {type: 'text', bRegex: true, bSmart: true}, /*name*/
                {
                    type            : 'select',
                    bRegex          : false,
                    bCaseInsensitive: true,
                    values          : [
                        {value: '', label: $filter('translate')('customers.table.statuses.all')},
                        {value: 's0', label: $filter('translate')('customers.table.statuses.inactive')},
                        {value: 's1', label: $filter('translate')('customers.table.statuses.active')}
                    ]
                }/*status*/,
                null,
            ];


            var attributeColumns = initializeAttributeColumns(attributes);
            $scope.dt.columns    = initializeColumns();
            $scope.dt.columns    = attributeColumns.concat($scope.dt.columns);

            var attributeColumnFilters = initializeAttributeColumnFilters(attributes);
            filterSettings             = attributeColumnFilters.concat(filterSettings);

            var notToggleableColumns = ['name', 'status', 'select'];
            $scope.dt.options        = initializeOptions(promiseCallback, filterSettings, notToggleableColumns);


        };

        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

            $compile(nRow)($scope);

            if (aData.status == 0)
                $(nRow).addClass('disabled');

            $(nRow).on('click', '.name-box', function (e) {
                CustomerModal.editCustomer(aData.id);
                e.stopPropagation();
                e.preventDefault();
            });


            $(nRow).on('click', '.document-o', function (e) {
                DocumentModal.createDocumentForCustomer(aData);
                e.stopPropagation();
                e.preventDefault();
            });


            $(nRow).on('click', '.document-report', function (e) {
                $state.go('reports.incomeanddebts', {customers: aData.id});
                e.stopPropagation();
                e.preventDefault();
            });

            return nRow;
        }

        function initializeColumns() {

            var columns = [

                DTColumnBuilder
                    .newColumn('balance')
                    .withOption('width', 'auto')
                    .withClass('ltr amount-holder')
                    //.withOption('width', '150px')
                    .withTitle(
                        (BusinessChecker.isExemptDealer(AuthDataStorage.retrieveStoredUser().profile.businessType))
                            ? $filter('translate')('customers.table.received')
                            : $filter('translate')('customers.table.amount')
                            + '<dt-remove-column-button>'
                    )
                    .withOption('render', {
                        "_": "plain",

                        "filter" : function (data) {
                            return data;
                        },
                        "sort"   : function (data) {
                            return data;
                        },
                        "display": function (data, type, fullData) {
                            return DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : data
                            });
                        }
                    }),

                DTColumnBuilder
                    .newColumn('businessnumber')
                    //.withOption('width', '95px')
                    .withOption('width', 'auto')
                    .withClass('business-number-column')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.business_number') + "'>" + $filter('translate')('customers.table.business_number') + "</span>" + '<dt-remove-column-button>')
                    .withOption('render', {
                        'display': function(data,type,fullData) {
                            if (data === null) {
                                return;
                            }
                            return "<span class='my_elipsis' title='" + data + "'>" + data + "</span>";
                        }
                    }),

                DTColumnBuilder
                    .newColumn('language')
                    .withOption('width', 'auto')
                    //.withTitle($filter('translate')('customers.table.language') + '<dt-remove-column-button>')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.language') + "'>" + $filter('translate')('customers.table.language') + "</span>" + '<dt-remove-column-button>')
                    //.withOption('columnDefs.render',$.fn.dataTable.render.ellipsis( 4,true))
                    .renderWith(function (data) {
                        //return $filter('translateLanguageLabel')(data);
                        return "<span class='my_elipsis' title='" + $filter('translateLanguageLabel')(data) + "'>" + $filter('translateLanguageLabel')(data) + "</span>";
                    })
                    .notVisible(),


                DTColumnBuilder
                    .newColumn('paymentTerm')
                    .withOption('width', 'auto')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.payment_term') + "'>" + $filter('translate')('customers.table.payment_term') + "</span>" + '<dt-remove-column-button>')
                    .renderWith(function (data, type, full) {
                        if (data === null) {
                            return;
                        }
                        if (data) {
                            if (data.name == 'other') {
                                return full.paymentTermOther;
                            } else {
                                return "<span class='my_elipsis' title='" + $filter('translateCustomerPaymentTerm')(data.name) + "'>" + $filter('translateCustomerPaymentTerm')(data.name) + "</span>";
                            }
                        } else {
                            return null;
                        }

                    })
                    .notVisible(),


                DTColumnBuilder
                    .newColumn('comment')
                    .withOption('width', 'auto')
                    //.withTitle($filter('translate')('customers.table.comments') + '<dt-remove-column-button>')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.comments') + "'>" + $filter('translate')('customers.table.comments') + "</span>" + '<dt-remove-column-button>')
                    .notVisible()
                    .withOption('render', {
                        'display': function(data,type,fullData) {
                            if (data === null) {
                                return;
                            }
                            return "<span class='my_elipsis' title='" + data + "'>" + data + "</span>";
                        }
                    }),


                DTColumnBuilder
                    .newColumn('address')
                    .withOption('width', 'auto')
                    //.withTitle($filter('translate')('customers.table.address') + '<dt-remove-column-button>')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.address') + "'>" + $filter('translate')('customers.table.address') + "</span>" + '<dt-remove-column-button>')
                    .notVisible()
                    .withOption('render', {
                        'display': function(data,type,fullData) {
                            if (data === null) {
                                return;
                            }
                            return "<span class='my_elipsis' title='" + data + "'>" + data + "</span>";
                        }
                    }),

                DTColumnBuilder
                    .newColumn('fax')
                    .withOption('width', 'auto')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.fax') + "'>" + $filter('translate')('customers.table.fax') + "</span>" + '<dt-remove-column-button>')
                    .notVisible()
                    .withOption('render', {
                        'display': function(data,type,fullData) {
                            if (data === null) {
                                return;
                            }
                            return "<span class='ltr-direction' title='" + data + "'>" + data + "</span>";
                        }
                    }),


                DTColumnBuilder
                    .newColumn('accounting_number')
                    .withOption('width', 'auto')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.accounting_number') + "'>" + $filter('translate')('customers.table.accounting_number') + "</span>" + '<dt-remove-column-button>')
                    .notVisible()
                    .withOption('render', {
                        'display': function(data,type,fullData) {
                            if (data === null) {
                                return;
                            }
                            return "<span class='my_elipsis' title='" + data + "'>" + data + "</span>";
                        }
                    }),

                DTColumnBuilder
                    .newColumn('contactperson')
                    .withOption('width', 'auto')
                    //.withTitle($filter('translate')('customers.table.contact') + '<dt-remove-column-button>')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.contact') + "'>" + $filter('translate')('customers.table.contact') + "</span>" + '<dt-remove-column-button>')
                    .withOption('render', {
                        'display': function(data,type,fullData) {
                            if (data === null) {
                                return;
                            }
                            return "<span class='my_elipsis' title='" + data + "'>" + data + "</span>";
                        }
                    }),

                DTColumnBuilder
                    .newColumn('phone')
                    .withOption('width', 'auto')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.phone') + "'>" + $filter('translate')('customers.table.phone') + "</span>" + '<dt-remove-column-button>')
                    .withOption('render', {
                        'display': function(data,type,fullData) {
                            if (data === null) {
                                return;
                            }
                            return "<span class='my_elipsis' title='" + data + "'>" + data + "</span>";
                        }
                    })
                    .withClass('ltr'),

                DTColumnBuilder
                    .newColumn('email')
                    .withOption('width', 'auto')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.email') + "'>" + $filter('translate')('customers.table.email') + "</span>" + '<dt-remove-column-button>')
                    .withOption('render', {
                        'display': function(data,type,fullData) {
                            if (data === null) {
                                return;
                            }
                            return "<span class='my_elipsis' title='" + data + "'>" + data + "</span>";
                        }
                    }),

                DTColumnBuilder
                    .newColumn('id')
                    .withOption('width', 'auto')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.id') + "'>" + $filter('translate')('customers.table.id') + "</span>" + '<dt-remove-column-button>')
                    .notVisible()
                    .withOption('render', {
                        'display': function(data,type,fullData) {
                            if (data === null) {
                                return;
                            }
                            return "<span class='my_elipsis' title='" + data + "'>" + data + "</span>";
                        }
                    }),


                DTColumnBuilder
                    .newColumn('name')
                    .withOption('width', 'auto')
                    //.withTitle($filter('translate')('customers.table.name'))
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.name') + "'>" + $filter('translate')('customers.table.name') + "</span>")
                    .withOption('render', {
                        "filter" : function (data, type, fullData) {
                            return data;
                        },
                        "display": function (data, type, fullData) {

                            var newDocumentButton = fullData.customerType.isDocumentEditingAvailable
                                ? '<button subscription-checked-button class="row-button btn document-o"></button>'
                                : '';

                            return '' +
                                '<div class="name-box">' +
                                '   <div class="name-box-button-block language-based-direction">' +
                                '       ' + newDocumentButton +
                                '       <button class="row-button btn document-report"></button>' +
                                '   </div>' +
                                '   <div class="name-box-text-block">' +
                                '       <span class="my_elipsis" title="' + data + '">' + data + '</span>' +
                                '   </div>' +
                                '</div>';

                        }
                    }),

                DTColumnBuilder
                    .newColumn('status')
                    .withOption('width', 'auto')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.status') + "'>" + $filter('translate')('customers.table.status') + "</span>")
                    .withClass('status-box')
                    //.withOption('columnDefs.render',$.fn.dataTable.render.ellipsis( 4,true))
                    //.withOption('width', '70px')
                    .withOption('render', {
                        "_"      : "plain",
                        "filter" : function (data, type, fullData) {
                            return 's' + data;
                        },
                        "display": function (data, type, fullData) {
                            return '<span class= "customers-status status-state"></span>';

                        }
                    }),
                DTColumnBuilder
                    .newColumn('select')
                    .withTitle('' +
                        '<span style="display: none;">' + $filter('translate')('customers.table.select') + '</span>' +
                        '<dt-select-all-checkbox></dt-select-all-checkbox>'
                    )
                    .withClass('select-checkbox')
                    //.withOption('width', 'auto')
                    .withOption('width', '60px')
                    .renderWith(function (data) {
                        return '<dt-select-checkbox class="check-area"></dt-select-checkbox>';
                    })
            ];

            columns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return columns;
        }

        function initializeOptions(promiseCallback, filterSettings, notToggleableColumns) {

            var toggleableColumns = [];

            $scope.dt.columns.forEach(function (definition, key) {
                if (!_.includes(notToggleableColumns, definition.mData)) {
                    toggleableColumns.push(key);
                }
            });

            return DTOptionsBuilder
                .fromFnPromise(promiseCallback)
                .withBootstrap()
                .withOption('sDom', "<'row'<'col-xs-12 text-left mb20 language-based-direction'B>>r<'customer-table-wrapper't><'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('stateSave', true)
                .withOption('stateLoadCallback', function (settings) {
                    return $scope.dt.colSetting;
                })
                .withOption('stateSaveCallback', function (settings, data) {

                    DataTableFunctions.recompileHeader(this.api().columns().header());

                    DataTableStorage.store($scope.tableName, data).then(function () {
                        $scope.json = angular.toJson(data);
                    })

                })
                .withOption('deferRender', true)
                .withOption('colReorder', {
                    fixedColumnsRight: 2,
                    fixedColumnsLeft: 1
                })
                .withOption('autoWidth', false)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withColumnFilter({
                    bUseColVis: true,
                    aoColumns : filterSettings
                })
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withButtons([
                    {
                        extend   : 'collection',
                        autoClose: true,
                        text     : $filter('translate')('customers.status'),
                        buttons  : [
                            {
                                extend       : 'subscriptionEditActionButton',
                                text         : $filter('translate')('customers.active'),
                                extendOptions: {
                                    enabledCallback: function (e, dt) {
                                        return !!DataTableFunctions.getSelectedData(dt).length;
                                    },
                                    action         : function (e, dt, node, config) {
                                        $scope.changeStatus(1);
                                    }
                                }
                            },
                            {
                                extend       : 'subscriptionEditActionButton',
                                text         : $filter('translate')('customers.inactive'),
                                extendOptions: {
                                    enabledCallback: function (e, dt) {
                                        return !!DataTableFunctions.getSelectedData(dt).length;
                                    },
                                    action         : function (e, dt, node, config) {
                                        $scope.changeStatus(0);
                                    }

                                }
                            }
                        ]
                    },
                    {
                        extend       : 'subscriptionEditActionButton',
                        text         : '<i class="mobile"></i>' + $filter('translate')('customers.sms'),
                        enabled      : false,
                        extendOptions: {

                            action         : function (e, dt, node, config) {
                                var customers = DataTableFunctions.getSelectedData(dt);
                                NotificationModal.sendSMSToCustomers(customers);
                            },
                            enabledCallback: function (e, dt) {
                                var customersWithPhones = DataTableFunctions
                                    .getSelectedData(dt)
                                    .filter(function (customer) {
                                        return !!customer.phone;
                                    });

                                return !!customersWithPhones.length;
                            }
                        }
                    },
                    {
                        extend       : 'subscriptionEditActionButton',
                        text         : '<i class="envelope"></i>' + $filter('translate')('customers.email'),
                        enabled      : false,
                        extendOptions: {
                            action         : function (e, dt, node, config) {

                                var customers = DataTableFunctions.getSelectedData(dt);
                                NotificationModal.sendEmailToCustomers(customers);

                            },
                            enabledCallback: function (e, dt) {

                                var customerWithEmails = DataTableFunctions
                                    .getSelectedData(dt)
                                    .filter(function (customer) {
                                        return !!customer.email;
                                    });

                                return !!customerWithEmails.length;
                            }
                        }
                    },
                    {
                        text  : '<i class="newspaper-o"></i>' + $filter('translate')('customers.newslater'),
                        action: function (e, dt, node, config) {
                            alert('Newsletter activated');
                        }
                    },
                    {
                        extend   : 'collection',
                        autoClose: true,
                        text     : '<i class="cloud-download"></i>' + $filter('translate')('customers.export'),
                        enabled  : false,
                        enabledCallback: function (e, dt) {
                            return !!DataTableFunctions.getSelectedData(dt).length;
                        },
                        buttons  : [
                            {
                                extend         : 'selected',
                                text           : $filter('translate')('customers.excel'),
                                action         : function (e, dt, node, config) {

                                    var customers = DataTableFunctions.getSelectedData(dt);
                                    var columns   = DataTableFunctions.getVisibleColumnNames(dt);

                                    CustomerExport.downloadCustomersAsXLS(customers, columns);

                                },
                                enabled        : false,
                                enabledCallback: function (e, dt) {
                                    return !!DataTableFunctions.getSelectedData(dt).length;
                                }
                            },
                            {
                                text  : 'PDF',
                                extend: 'selected',

                                action: function (e, dt, node, config) {

                                    var customers = DataTableFunctions.getSelectedData(dt);
                                    var columns   = DataTableFunctions.getVisibleColumnNames(dt);

                                    CustomerExport.exportCustomersToPDF(customers, columns);

                                },

                                enabled        : false,
                                enabledCallback: function (e, dt) {
                                    return !!DataTableFunctions.getSelectedData(dt).length;
                                }
                            },
                            {
                                text  : 'CSV',
                                extend: 'selected',
                                action: function (e, dt, node, config) {
                                    var customers = DataTableFunctions.getSelectedData(dt);

                                    CustomerExport.exportCustomersToCSV(customers);
                                },

                                enabled        : false,
                                enabledCallback: function (e, dt) {
                                    return !!DataTableFunctions.getSelectedData(dt).length;
                                }
                            }
                        ]
                    },
                    {
                        extend   : 'collection',
                        autoClose: true,
                        text     : '<i class="cloud-upload"></i>' + $filter('translate')('customers.import'),
                        buttons  : [{

                            extend       : 'subscriptionEditActionButton',
                            text         : 'CSV',
                            extendOptions: {
                                action: function (e, dt, node, config) {
                                    CustomerModal.importCustomersFromCSV();
                                }
                            },
                        }]
                    },
                    {
                        extend: 'selected',
                        action: function (e, dt, node, config) {

                            var customers = DataTableFunctions.getSelectedData(dt);
                            var columns   = DataTableFunctions.getVisibleColumnNames(dt);

                            CustomerExport.printCustomers(customers, columns);
                        },
                        text  : '<i class="print"></i>' + $filter('translate')('customers.printing')
                    },
                    {
                        extend          : 'colvis',
                        columns         : toggleableColumns,
                        text            : '<i class="cog"></i>' + $filter('translate')('customers.edit_column'),
                        className       : 'btn-warning',
                        collectionLayout: 'top',
                    }
                ])
                .withOption('fnCreatedRow', rowCallback);
        }

        function initializeAttributeColumns(attributes) {
            var attributeColumnDefinitions = [];
            attributes.forEach(function (attribute) {
                var columnDef = DTColumnBuilder
                    .newColumn('attribute.' + attribute.id)
                    .withTitle("<span class='my_elipsis' title='" + attribute.name + "'>" + attribute.name + "</span>" + '<dt-remove-column-button>')
                    .withOption('width', '60px')
                    .renderWith(function (data, type, fullData) {
                        var object = _.find(fullData.attributeValues, function (value) {
                            return value.attribute.id === attribute.id;
                        });
                        return !!object ? object.value : ''
                    })
                    .notVisible();

                attributeColumnDefinitions.push(columnDef);
            });

            attributeColumnDefinitions.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return attributeColumnDefinitions;
        }

        function initializeAttributeColumnFilters(attributes) {
            var attributeColumnFilters = [];
            attributes.forEach(function (attribute) {

                if (attribute.type === 'choice') {
                    attributeColumnFilters.push({
                        type  : 'select',
                        values: attribute.typeOption.choices
                    });
                } else {
                    attributeColumnFilters.push({'type': 'text'});
                }

            });
            return attributeColumnFilters;
        }

    });