/**
 * Created by abichenko on 05.02.18.
 */
angular.module('sinvoice-desktop')
    .controller('exportManagedCustomerPopupController',
        function ($scope, TaxPeriodProvider, ManagedCustomers, $filter, DocumentExport, FileHelper, Modal) {

            $scope.availableDocTypes  = [];
            $scope.availableCustomers = [];

            $scope.spinner  = {enabled: true};
            $scope.selected = {
                filters: {}
            };

            $scope.exportOptions         = ['pdf', 'excel', "mivne", 'wizcount'];
            $scope.selected.exportOption = $scope.exportOptions[0];


            $scope.updateFromToDate = function (date) {
                $scope.selected.filters.from = date.from ? date.from : new Date();
                $scope.selected.filters.to   = date.to ? date.to : new Date();
            };

            $scope.export = function () {

                $scope.spinner.enabled = true;

                var promise;
                var filters      = $scope.selected.filters;
                var exportOption = $scope.selected.exportOption;

                if (exportOption === 'excel') {
                    promise = ManagedCustomers
                        .exportToExcel($scope.managedCustomerId, filters)
                        .then(function (data) {
                            if (!data.content) {
                                return Promise.reject(false);
                            } else {
                                FileHelper.saveArchive(data.content, 'report.zip');
                                return Promise.resolve()
                            }
                        })

                } else if (exportOption === 'pdf') {
                    promise = FileHelper.openPDF(function () {
                        return ManagedCustomers
                            .exportToPDF($scope.managedCustomerId, filters)
                            .then(function (data) {
                                if (!data.link) {
                                    return Promise.reject(false);
                                } else {
                                    return data.link;
                                }
                            })
                    })

                } else if (exportOption === 'mivne') {
                    promise = ManagedCustomers
                        .getFilteredDocumentsToExport($scope.managedCustomerId, filters)
                        .then(function (data) {
                            if (!data.ids.length) {
                                return Promise.reject(false);
                            } else {
                                return DocumentExport.showMivneSummary(data.ids)
                            }
                        })
                } else if (exportOption === 'wizcount') {
                    promise = ManagedCustomers
                        .getFilteredDocumentsToExport($scope.managedCustomerId, filters)
                        .then(function (data) {
                            if (!data.ids.length) {
                                return Promise.reject(false);
                            } else {
                                return DocumentExport.downloadAsWizcount(data.ids)
                            }
                        })
                }

                promise
                    .then(function (data) {
                        $scope.$currentModal.close();
                    })
                    .catch(function () {
                        Modal.alert(
                            $filter('translate')('customers.export_managed_customer_popup.no_documents_available'),
                            {title: $filter('translate')('customers.export_managed_customer_popup.no_documents_available_title')}
                        );
                        $scope.spinner.enabled = false;
                    })
            };

            (function () {

                $scope.dateExamples                 = TaxPeriodProvider.getDatepickerValues();
                $scope.selected.filters.to          = $scope.dateExamples[0].to;
                $scope.selected.filters.from        = $scope.dateExamples[0].from;
                $scope.selected.filters.dateExample = $scope.dateExamples[0];

                ManagedCustomers
                    .getExportFilterValues($scope.managedCustomerId)
                    .then(function (data) {
                        $scope.availableCustomers = data.customers;
                        $scope.availableDocTypes  = data.docTypes.map(function (val) {
                            return {id: val, label: $filter('translateDocType')(val)}
                        });

                        $scope.selected.filters.customers = $scope.availableCustomers;
                        $scope.selected.filters.docTypes  = $scope.availableDocTypes;
                    })
                    .finally(function () {
                        $scope.spinner.enabled = false;
                    })

            })();

        });