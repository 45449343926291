/**
 * Created by abichenko on 22.11.17.
 */
angular.module('sinvoice-common')

    .factory('DocumentSummaryDataProvider', function ($filter, ParagraphCheck) {


        return {
            getPaymentSummaries: getPaymentSummaries,
            getSummaries       : getSummaries
        };

        function getPaymentSummaries(templateVariable, taxValue, currency, nisCourse, paymentSymbol) {

            var result = [];

            result.push({
                label : $filter('translate')('documents.payment_methods.before', {symbol: $filter('translateCurrencyLabel')(paymentSymbol)}),
                value : templateVariable.$paymentsTotal,
                symbol: paymentSymbol
            });

            result.push({
                label : $filter('translate')('documents.payment_methods.tax_amount'),
                value : taxValue,
                symbol: paymentSymbol
            });

            if (currency.name === 'NIS') {
                result.push({
                    label : $filter('translate')('documents.payments_total'),
                    value : templateVariable.$paymentsTotalWithVat,
                    symbol: paymentSymbol
                });
            } else {
                if (paymentSymbol !== 'NIS') {
                    result.push({
                        label   : $filter('translate')('documents.conversion_rate'),
                        value   : nisCourse,
                        decimals: 4
                    });
                }
                result.push({
                    label : $filter('translate')('documents.payments_total_nis'),
                    value : templateVariable.$paymentsTotalWithVatNIS,
                    symbol: 'NIS'
                });
            }

            result.forEach(function (value, index) {
                if (!value.value) {
                    value.value = 0.0;
                }
                if (!value.decimals) {
                    value.decimals = 2;
                }
            });

            return result;
        }

        function getSummaries(templateVariable, selectedVATParameters, docType, discounts, currency, nisCourse) {

            var result = [];

            if (selectedVATParameters === 'exclude' || selectedVATParameters === 'mixed') {

                result.push({
                    label : $filter('documentPopupTranslate')('documents.total_excluded_vat_items', docType),
                    value : templateVariable.$itemsExcludedVatBeforeDiscount,
                    symbol: currency.name
                });

                if (discounts && discounts.length) {
                    result.push({
                        label : $filter('documentPopupTranslate')('documents.total_excluded_vat_items_discount', docType),
                        value : templateVariable.$itemsExcludedVatBeforeDiscount - templateVariable.$itemsExcludedVatAfterDiscount,
                        symbol: currency.name
                    });
                }

                if (templateVariable.$itemsExcludedVatRoundingValue) {
                    result.push({
                        label : $filter('translate')('documents.round_total'),
                        value : templateVariable.$itemsExcludedVatRoundingValue,
                        symbol: currency.name
                    });
                }

                if (discounts && discounts.length) {
                    result.push({
                        label : $filter('documentPopupTranslate')('documents.total_excluded_vat_items_discounted', docType),
                        value : templateVariable.$itemsExcludedVatTotal,
                        symbol: currency.name
                    })
                }
            }

            if (selectedVATParameters === 'include' || selectedVATParameters === 'mixed') {
                result.push({
                    label : $filter('documentPopupTranslate')('documents.total_included_vat_items', docType),
                    value : templateVariable.$itemsIncludedVatBeforeDiscount,
                    symbol: currency.name
                });

                if (discounts && discounts.length) {
                    result.push({
                        label : $filter('documentPopupTranslate')('documents.total_included_vat_items_discount', docType),
                        value : templateVariable.$itemsIncludedVatBeforeDiscount - templateVariable.$itemsIncludedVatAfterDiscount,
                        symbol: currency.name
                    });
                }

                if (templateVariable.$itemsIncludedVatRoundingValue) {
                    result.push({
                        label : $filter('translate')('documents.round_total'),
                        value : templateVariable.$itemsIncludedVatRoundingValue,
                        symbol: currency.name
                    });
                }

                if (discounts && discounts.length) {
                    result.push({
                        label : $filter('documentPopupTranslate')('documents.total_included_vat_items_discounted', docType),
                        value : templateVariable.$itemsIncludedVatTotal,
                        symbol: currency.name
                    })
                }
            }

            if (selectedVATParameters !== 'exclude') {
                result.push({
                    label : $filter('documentPopupTranslate')('documents.vat_in_currency', docType, {
                        symbol: $filter('translateCurrencyLabel')(currency.name)
                    }),
                    value : templateVariable.$vatAmount,
                    symbol: currency.name
                })
            }


            if (currency.name === 'NIS') {
                result.push({
                    label : $filter('documentPopupTranslate')('documents.total_for_payment', docType),
                    value : templateVariable.$finalItemsTotalWithVat,
                    symbol: currency.name
                })
            }

            if (currency.name !== 'NIS') {


                result.push({
                    value : templateVariable.$finalItemsTotalWithVat,
                    label : $filter('documentPopupTranslate')('documents.total_for_payment_in_currency', docType, {
                        symbol: $filter('translateCurrencyLabel')(currency.name)
                    }),
                    symbol: currency.name
                });

                if (templateVariable.$finalItemsTotalWithVatNIS) {
                    result.push({
                        label   : $filter('translate')('documents.conversion_rate'),
                        value   : nisCourse,
                        decimals: 4
                    });
                }


                if (!ParagraphCheck.isVATNotAllowed()) {
                    result.push({
                        label : $filter('translate')('documents.vat_amount_in_nis'),
                        value : templateVariable.$vatAmountNIS,
                        symbol: 'NIS'
                    });
                }

                result.push({
                    label : $filter('documentPopupTranslate')('documents.total_for_payment_in_nis', docType),
                    value : templateVariable.$finalItemsTotalWithVatNIS,
                    symbol: 'NIS'
                });

            }

            result.forEach(function (value, index) {
                if (!value.value) {
                    value.value = 0.0;
                }
                if (!value.decimals) {
                    value.decimals = 2;
                }

            });


            return result;

        }


    });
