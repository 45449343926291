angular.module('sinvoice-desktop').controller('itemsController',
    function ($scope,
              Modal,
              DTOptionsBuilder,
              DataTableDatepickerLabels,
              DTColumnBuilder,
              $compile,
              $translate,
              DataTableLanguageSettings,
              DataTableBootstrapSettings,
              Item,
              $filter,
              DirectiveCompiler,
              Currency,
              ItemModal,
              Events,
              $state) {

        $scope.currencies      = [];
        $scope.currenciesNames = [];


        $scope.openNewItemForm = function () {
            ItemModal.createNewItem();
        };

        function loadCurrencies() {
            Currency.getData().then(function (data) {
                var currency = [];
                angular.forEach(data, function (value, key) {
                    currency.push({id: value.id, name: value.name})
                });
                $scope.currencies = currency;
                $scope.currencies.forEach(function (currency) {
                    $scope.currenciesNames.push({
                        value: currency.name,
                        label: $filter('translateCurrencyLabel')(currency.name)
                    })
                })
            });
        };

        function loadItems() {
            $scope.dtInstance = {};
            $scope.dtOptions  = DTOptionsBuilder
                .fromFnPromise(function () {
                    return Item.getData();
                })
                .withBootstrap()
                .withDisplayLength(20)
                .withOption('autoWidth', false)
                .withOption('sDom', "<'row'<>>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('oLanguage', DataTableLanguageSettings)

                .withColumnFilter({
                    aoColumns: [
                        {
                            type  : 'text',
                            bRegex: true,
                            bSmart: true
                        },

                        {
                            type  : 'text',
                            bRegex: true,
                            bSmart: true
                        },
                        {
                            type  : 'text',
                            bRegex: true,
                            bSmart: true
                        },
                        {
                            type        : 'number-range',
                            bRegex      : true,
                            bSmart      : true,
                            sRangeFormat: DataTableDatepickerLabels.fromToLabels()
                        },
                        {
                            type  : 'select',
                            bRegex: false,
                            bSmart: false,
                            values: $scope.currenciesNames
                        }
                    ]
                })
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withButtons([])
                .withOption('fnCreatedRow', rowCallback);


            $scope.dtColumns = [
                DTColumnBuilder
                    .newColumn('sku')
                    .withTitle($translate.instant('items.table.sku'))
                    .withClass('sku-box'),

                DTColumnBuilder
                    .newColumn('name')
                    .withTitle($translate.instant('items.table.name'))
                    .withClass('name-box')
                    .renderWith(function (data, type, full) {
                        var innerColumn = '' +
                            '<span class="name-block-wrapper">' +
                            '   <span class="name-block-button"> ' +
                            '      <button class="row-button btn document-report"></button>' +
                            '   </span>' +
                            '   <span class="name-block-data">' + data + '</span>' +
                            '</span>';


                        return innerColumn;
                    }),

                DTColumnBuilder
                    .newColumn('description')
                    .withTitle($translate.instant('items.table.description'))
                    .withClass('description-box'),

                DTColumnBuilder
                    .newColumn('price')
                    .withTitle($translate.instant('items.table.price'))
                    .withClass('price-box')
                    .withOption('render', {
                        "filter" : function (data, type, fullData) {
                            return Number(fullData.price);
                        },
                        "display": function (data, type, fullData) {
                            return DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: $filter('translateCurrencyLabel')(fullData.currency.name),
                                amount  : fullData.price
                            });
                        }
                    }),

                DTColumnBuilder
                    .newColumn('currency')
                    .withTitle($translate.instant('items.table.currency'))
                    .withClass('currency-box')
                    .withOption('render', {
                        "sort"   : function (data) {
                            return $filter('translateCurrencyLabel')(data.name);
                        },
                        "filter" : function (data, type, fullData) {
                            return data.name;
                        },
                        "display": function (data) {
                            return $filter('translateCurrencyLabel')(data.name);

                        }
                    })


            ];

            $scope.dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

        }


        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

            $compile(angular.element(nRow).contents())($scope);

            $(nRow).on('click', 'td.name-box, td.sku-box', function (e) {
                e.stopPropagation();
                ItemModal.editItem(aData.id);
            });

            $(nRow).on('click', '.document-report', function (e) {
                e.stopPropagation();
                $state.go('reports.incomeitems', {items: aData.sku});
            });

            return nRow;
        }

        (function () {

            Events.subscribeToEvent('items.update', $scope, function () {
                $scope.dtInstance.rerender();

            });
            loadItems();
            loadCurrencies();
        })();
    });
