/**
 * Created by abichenko on 20.03.18.
 */
angular.module('sinvoice-desktop').factory('IncomeAndDebtsSummaryRowRenderer', function ($filter, $compile, ScopeProvider) {


    return {
        getRenderedDom: function (data, columnCount) {

            var row       = $('<tr>');
            var cell      = $('<td>');
            var labelCell = $('<td>');

            data.forEach(function (summaryElement) {
                var labelBlock = $('<div>').html(summaryElement.label);

                labelBlock.css({
                    'white-space': 'nowrap',
                    'text-align' : 'right'

                });

                labelCell.append(labelBlock);


                var value      = $filter('number')(summaryElement.value, 2);
                var valueBlock = $('<div>').attr('cut-value', '').html(value);

                if (summaryElement.value < 0) {
                    valueBlock.addClass('negative-amount');
                }
                valueBlock.css({
                    'direction': 'ltr'
                });

                $compile(valueBlock)(ScopeProvider.getNewIsolatedScope());

                cell.append(valueBlock);
            });

            row.append(labelCell);
            _.range(columnCount - 2).forEach(function () {
                row.append($('<td>'))
            });
            row.append(cell);
            row.addClass('tbl_summary_group_title');

            return row;
        }
    }

})