angular.module('sinvoice-desktop').directive('ordersBookSlider', function ($compile) {

    var template = '' +
        '<ul class="orders_slider orders-slider" ng-show="slides.length">' +
        '   <li ng-repeat="slide in slides track by slide.index">' +
        '       <div class="order_slider_item" tabindex="0" ng-click="setActiveSlide(slide)">' +
        '           <div class="slider-content"></div>' +
        '           <div class="std_block_shadow"></div>' +
        '       </div>' +
        '   </li>' +
        '</ul>';

    return {
        scope  : {
            slides     : '=',
            activeSlide: '=?'
        },
        compile: function (directiveElement) {

            var contentToInsert = directiveElement.html();
            directiveElement.html('');

            return {
                pre : function (scope, element, attrs) {

                    var tpl = angular.element(template);

                    tpl.find('.slider-content').append(contentToInsert);

                    $compile(tpl)(scope);

                    element.append(tpl);
                }
                ,
                post: function (scope, element, attributes, controller, transcludeFn) {

                    function initSlider() {
                        $(element).find('.orders-slider').slick({
                            dots          : false,
                            infinite      : false,
                            speed         : 300,
                            slidesToShow  : 4,
                            slidesToScroll: 1
                        });
                        if (jQuery.isEmptyObject(scope.activeSlide)) {
                            setActiveSlide(scope.slides[0]);
                        }
                    }

                    function setActiveSlide(slide) {
                        scope.activeSlide = slide;
                    }

                    scope.$watchCollection('slides', function (newVal, oldVal) {
                        initSlider();
                    });

                    scope.setActiveSlide = setActiveSlide;
                }
            };


        }
    }
});
