angular.module('sinvoice-desktop').controller('registerController', function ($scope, RegisterState, TopnavTextContainer, API, Url, $stateParams, Auth, AuthDataStorage, $filter, Events) {

    $scope.spinner         = {};
    $scope.spinner.enabled = false;
    $scope.currentState    = RegisterState.data;
    $scope.registerData    = {
        user: {}
    };

    function checkReferral(token) {
        return new Promise(
            function (resolve, reject) {
                API.get(Url.auth.validateInviteToken(token))
                    .success(function (data) {
                        if (!!data)
                            resolve(data);
                        else
                            reject(data);
                    })
            })
    }


    (function () {

        TopnavTextContainer.setText($filter('translate')('register.top_hint_text'));

        Events.subscribeToEvent('$stateChangeSuccess', $scope, function () {
            TopnavTextContainer.clearText()
        });


        if ($stateParams.invite) {
            checkReferral($stateParams.invite).then(function (data) {

                if(data.success){
                    $scope.registerData.user    = {
                        email: data.email,
                        first_name: data.first_name,
                        last_name: data.last_name,
                        phone: data.phone,
                    };
                }

                $scope.inviteToken = $stateParams.invite;
            });
        } else if ($stateParams.refId) {
            $scope.refId = $stateParams.refId;
        }

        if (Auth.isAuthenticated() && !Auth.registrationIsComplete()) {

            var stepnumber = AuthDataStorage.retrieveStoredUser().registrationStep;

            if (stepnumber == 1) {
                $scope.currentState.value = 'secondStep';
            } else if (stepnumber == 2) {
                $scope.currentState.value = 'thirdStep';
            }
        }

    })();

});
