/**
 * Created by abichenko on 24.11.17.
 */
angular.module('sinvoice-mobile')
    .directive('singlePaymentWrapper', function () {

        return {
            scope     : {payment: '='},
            transclude: true,
            template  : '<div class="payment-row" ng-transclude=""></div>',
            controller: 'singlePaymentWrapperController',
            link      : function (scope, element, attrs, dummy, transclude) {
            }
        }

    });