angular.module('sinvoice-mobile').controller('MobileViewCustomerController',
    function ($scope, Customer, $stateParams, $state, MobileModal, Events, CustomerDetailsModal) {

        $scope.spinner = {enabled: true};

        $scope.onPaginateChange = function (currentPage, perPage) {
            $scope.pagination = {
                currentPage: currentPage,
                perPage    : perPage
            };
        };

        $scope.viewDetails = function () {
            CustomerDetailsModal.viewCustomerDetails($scope.customer);
        };

        $scope.editCustomer = function () {
            CustomerDetailsModal.editCustomerDetails($scope.customer);
        };

        $scope.createDocument = function () {
            $state.go('new-document', {'customerId': $scope.customer.id});
        };

        function loadCustomerDocuments() {
            return Customer
                .getRelatedDocuments($scope.customer.id)
                .then(function (data) {
                    $scope.customerDocuments = data;
                });
        }

        function loadCustomer(id) {
            return Customer.getSingleCustomer(id)
                .then(function (data) {
                    $scope.customer = data;
                });
        }

        function loadData() {
            loadCustomer($stateParams.customerId)
                .then(loadCustomerDocuments)
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        }

        (function () {
            loadData();

            Events.subscribeToEvent('customer.update', $scope, function () {
                loadData();
            });

        })();
    });