angular.module('sinvoice-desktop').controller('recentDocumentsController',
    function ($scope, $filter, DTOptionsBuilder, DTColumnBuilder, $translate, Document, dpickerSettings, DataTableLanguageSettings, Events, DirectiveCompiler) {


        function initializeDataTableColumns() {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('issueDate')
                    .withTitle($translate.instant('control.documents.date'))
                    .renderWith(function (data) {
                        return momentWrapped(data).format(dpickerSettings.dateFormat)
                    }),

                DTColumnBuilder
                    .newColumn('type.docType')
                    .withTitle($translate.instant('control.documents.type'))
                    .renderWith(function (data) {
                        return $filter('translateDocType')(data)
                    }),

                DTColumnBuilder
                    .newColumn('docNumber')
                    .withTitle($translate.instant('control.documents.number')),

                DTColumnBuilder
                    .newColumn('title')
                    .withTitle($translate.instant('control.documents.title')),

                DTColumnBuilder
                    .newColumn('totalWithVat')
                    .withTitle($translate.instant('control.documents.amount'))
                    .renderWith(function (data, type, fullData) {
                        return DirectiveCompiler.compileIntoHtml('money-amount', {
                            currency: 'NIS',
                            amount  : fullData.displayedTotalNIS
                        });

                    })
            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });


            return dtColumns;
        }

        function initializeDataTable(promise) {
            return DTOptionsBuilder
                .fromFnPromise(promise)
                .withOption('sDom', "Rt")
                .withOption("bInfo", false)
                .withOption("oLanguage", DataTableLanguageSettings)
                .withButtons([])
                .withOption('order', [0, "desc"])
                .withOption("bootstrap", false)

        }

        (function () {


            Events.subscribeToEvent('documents.update', $scope, function () {
                $scope.dtInstance.rerender();
            });

            $scope.dtInstance = {};
            $scope.dtOptions  = initializeDataTable(function () {
                return Document.getData(6, 0);
            });
            $scope.dtColumns  = initializeDataTableColumns();

        })()
    });