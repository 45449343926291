/**
 * Created by dbichenko on 03.10.2016.
 */
angular.module('sinvoice-common').factory('ResetPass', function (API, Url, $q) {


    return {
        validateToken   : function (token) {

            var defer = $q.defer();

            API.get(Url.resetPass.validate(token), {})
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (error) {
                    defer.reject()
                });

            return defer.promise;
        },
        changePass      : function (token, password) {

            var defer = $q.defer();

            API.post(Url.resetPass.confirm(), {
                "token"   : token,
                "password": password
            })
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (error) {
                        defer.reject()
                    }
                );

            return defer.promise;

        },
        sendResetRequest: function (email) {

            var defer = $q.defer();

            API.post(Url.resetPass.generate(), {"email": email})
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (error) {
                    defer.reject()
                });

            return defer.promise;

        }
    }

})