angular.module('sinvoice-desktop').controller('customerBlockController',
    function ($scope, Customer, $translate, Events, CustomerTypeChecker, promiseHelper, DocumentCustomerProvider, DocumentInitializer) {

        var occasionalCustomer = null;

        $scope.isLanguageEnglish = function () {
            return $scope.editedDocument.language == 'en'
        };

        $scope.occasionalCustomerEmailChange = function () {
            if ($scope.editedDocument.customerEmail)
                $scope.editedDocument.clientEmails = [$scope.editedDocument.customerEmail];
        };

        $scope.changeCustomer = function () {
            Events.triggerEvent('customer.change', $scope.editedDocument.customer);
            $scope.customer = null;
        };

        $scope.saveCustomer = function () {

            $scope.spinner.newCustomer = true;

            if ($scope.customer.id) {
                var newCustomer = angular.copy($scope.customer);
                Customer.update($scope.customer.id, $scope.customer).then(function () {
                    angular.forEach($scope.customers, function (customer, key) {
                        if (customer.id == newCustomer.id) {
                            $scope.customers[key]          = newCustomer;
                            $scope.editedDocument.customer = newCustomer;
                            extractEmails($scope.editedDocument.customer);
                        }
                    });
                    $scope.spinner.newCustomer = false;
                })
            } else {
                Customer.create($scope.customer).then(function (data) {
                    $scope.customers.push(data);
                    $scope.editedDocument.customer = data;
                    $scope.spinner.newCustomer     = false;
                    extractEmails($scope.editedDocument.customer);
                    Events.triggerEvent('customer.change', $scope.editedDocument.customer)
                })
            }

            $scope.cancelCustomer();
        };


        $scope.addCustomer = function () {
            $scope.customerCopy = {};
            $scope.customer     = {};
        };

        $scope.editCustomer = function () {
            $scope.customer     = angular.copy($scope.editedDocument.customer);
            $scope.customerCopy = angular.copy($scope.customer);
        };

        $scope.cancelCustomer = function () {
            $scope.customer = null;
        };


        $scope.toggleUseExistingCustomer = function () {
            if (!$scope.template.useExistingCustomer) {
                $scope.editedDocument.clientEmails = [];
                $scope.cancelCustomer();
                $scope.editedDocument.customer = occasionalCustomer;
            } else if ($scope.customers.length) {
                $scope.editedDocument.customer = $scope.customers[0];

            }
            Events.triggerEvent('customer.change', $scope.editedDocument.customer);
        };


        function extractEmails(selectedCustomer) {
            var newEmails = [];
            if (selectedCustomer.email) {
                newEmails.push(selectedCustomer.email);
            }
            $scope.editedDocument.clientEmails = newEmails;
        }


        function prepareCustomers() {
            occasionalCustomer = DocumentCustomerProvider.filterOccasionalCustomer($scope.customers);
            $scope.customers   = DocumentCustomerProvider.filterWithDocumentSupport($scope.customers);
        }

        function initializeValidators() {

            $scope.validators = {}
        }

        DocumentInitializer.addInitCallback(function () {

            $scope.isEnglishOccasionalCustomerNameBlockShown = $scope.isLanguageEnglish();
            $scope.isEnglishCustomerNameBlockShown           = $scope.isLanguageEnglish();
            $scope.customer                                  = null;

            $scope.$watch('template.$isOccasionalCustomerBlockDisabled', function (newVal) {

                if (newVal !== true) {
                    return;
                }

                if ($scope.editedDocument.customer && CustomerTypeChecker.isOccasional($scope.editedDocument.customer.customerType)) {
                    $scope.editedDocument.customer      = null;
                    $scope.template.useExistingCustomer = true
                }
            });


            Events.subscribeToEvent('customer.change', $scope, function (e, selectedCustomer) {
                    extractEmails(selectedCustomer);
                    $scope.editedDocument.customerNameEn = selectedCustomer.nameEn;
                }
            );


            prepareCustomers();

            if (!$scope.editedDocument.customerOccasionalName)
                $scope.editedDocument.customerOccasionalName = $translate.instant('documents.use_occuring');

            if ($scope.editedDocument.customer && !$scope.editedDocument.clientEmails.length && !$scope.editedDocument.id) {
                extractEmails($scope.editedDocument.customer);
            }

            if ($scope.editedDocument.customer) {
                $scope.editedDocument.customerNameEn = $scope.editedDocument.customer.nameEn;
            }

            if ($scope.editedDocument.customer && CustomerTypeChecker.isOccasional($scope.editedDocument.customer.customerType)) {
                $scope.template.useExistingCustomer = false;
            } else {
                $scope.template.useExistingCustomer = true;
            }

            initializeValidators();
        });


    });