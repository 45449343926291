/**
 * Created by dbichenko on 23.11.2016.
 */
angular.module('sinvoice-desktop').filter('getCustomerInfoMissingPopupBody', function ($translate) {

    return function (value) {
        if (!value)
            return '';

        return $translate.instant('documents.' + value + ".missing_info_popup.body");
    }

});