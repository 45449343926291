/**
 * Created by dbichenko on 12.01.2017.
 */
angular.module('sinvoice-desktop')

    .filter('translateChequeStatus', function ($filter) {

    return function (value) {
        return $filter('translate')('reports.cheques.table.statuses.' + value);
    }

});