angular.module('sinvoice-desktop')
    .controller('mainController', function ($rootScope, $state, DTDefaultOptions, Auth, IncomingInvites, Profile, DEFAULT_LANGUAGE, $translate, AuthDataStorage) {
        $rootScope.lang           = {
            en: $translate.use() == 'en' ? 1 : 0,
            he: $translate.use() == 'he' ? 1 : 0
        };
        $rootScope.langDate       = $translate.use();
        $rootScope.defaultLoading = DTDefaultOptions.setLoadingTemplate('<img src="assets/img/spinner.gif" >');
        $rootScope.addClass       = function (langOn, langOff) {
            $rootScope.lang[langOn]  = 1;
            $rootScope.lang[langOff] = 0;
        };


        $rootScope.$on('auth:login', function () {
            $state.go('control.dashboard');
        });

        $rootScope.$on('auth:logout', function () {
            $state.go('login')
        });

        $rootScope.$on('auth:login', function () {
            IncomingInvites.processIncomingInvites();

            var user = AuthDataStorage.retrieveStoredUser();
            if (user.registrationStep != 1) {
                Profile.getData().then(function (data) {
                    Auth.changeProfile(data);
                });
            }

        })
    });
