/**
 * Created by abichenko on 08.12.17.
 */
angular.module('sinvoice-mobile')
    .controller('searchPopupController', function ($scope, Search, $filter, $state) {

        var searchDebounce;


        $scope.isAwaiting = false;
        $scope.spinner    = {enabled: true};
        $scope.documents  = [];
        $scope.customers  = [];

        $scope.sectionIsAvailable = function (sectionName) {

            var stateGroup = $state.current.group;

            if (!angular.isDefined(stateGroup)) {
                return true;
            }

            if (stateGroup === 'documents') {
                return sectionName === 'documents';
            }
            if (stateGroup === 'customers') {
                return sectionName === 'customers';
            }
            return true;

        };

        $scope.performSearch = function () {

            $scope.isAwaiting = true;

            searchDebounce();

            $scope.onChange($scope.query);
        };

        $scope.viewDocument = function (id) {
            $state.go('view-document', {documentId: id});

            $scope.$currentModal.close();
        };

        $scope.viewCustomer = function (id) {
            $state.go('view-customer', {customerId: id});

            $scope.$currentModal.close();
        };

        function updateResults() {
            $scope.spinner.enabled = true;

            return Search
                .getFormattedSearchResults($scope.query)
                .then(function (result) {
                    $scope.documents = result.documents;
                    $scope.customers = result.customers;
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        }


        (function () {
            updateResults();

            searchDebounce = _.debounce(function () {
                updateResults().finally(function () {
                    $scope.isAwaiting = false;
                });
            }, 1000);

        })();
    });