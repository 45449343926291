/**
 * Created by abichenko on 07.12.17.
 */
angular.module('sinvoice-common').factory('Search', function (Url, API, $q, $filter, ReportRouteResolver) {


    function createReportRows(reports) {
        return reports.map(function (report) {

            var stringParts = [
                $filter('translateReportType')(report.type)
            ];

            var route  = ReportRouteResolver.resolve(report.type);
            var params = ReportRouteResolver.resolveFiltersToStateParams(
                report.filters,
                report.id,
                report.from,
                report.to
            );

            return {value: stringParts.join(' '), id: report.id, route: route, params: params}
        })
    }

    function createItemRows(items) {
        return items.map(function (item) {

            var stringParts = [
                item.name,
                '-',
                item.sku,
            ];
            return {value: stringParts.join(' '), id: item.id}
        })
    }

    function createCustomerRows(customers) {
        return customers.map(function (customer) {
            var stringParts = [
                '#' + customer.id,
                customer.name
            ];
            if (customer.businessnumber) {
                stringParts.push('- ' + customer.businessnumber)
            }
            return {value: stringParts.join(' '), id: customer.id}
        });
    }

    function createDocumentRows(documents) {
        return documents.map(function (document) {
            var value = [document.title, '-', '#' + document.docNumber].join(' ');
            return {value: value, id: document.id};
        });
    }

    return {

        search: function (query) {

            var deferred = $q.defer();

            API.get(Url.business.search(), {query: query})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;

        },

        getFormattedSearchResults: function (query) {

            return this
                .search(query)
                .then(function (data) {

                    var result = {};

                    result.documents = createDocumentRows(data.documents);
                    result.customers = createCustomerRows(data.customers);
                    result.items     = createItemRows(data.items);
                    result.reports   = createReportRows(data.reports);

                    return result;
                });
        }
    }
})