/**
 * Created by dbichenko on 05.08.2016.
 */
angular.module('sinvoice-common')
    .service('AuthDataStorage', function ($sessionStorage, TokenStorage, $rootScope) {


        var object = {

            getProfile          : function () {
                return object.retrieveStoredUser().profile;
            },
            getProfileBusinesses: function () {
                return object.getProfile().businesses;
            },
            getHebrewBusiness   : function () {
                return object.getProfileBusinesses().he;
            },
            changeProfile                 : function (profile) {
                var user     = this.retrieveStoredUser();
                user.profile = profile;
                $sessionStorage.putObject('userInfo', user);
            },
            changeDistributorPopupStatus  : function (value) {
                var user                            = this.retrieveStoredUser();
                user.isDistributorInitialPopupShown = value;
                $sessionStorage.putObject('userInfo', user);
            },
            changeReportHelpPopupStatus   : function (value) {
                var user                    = this.retrieveStoredUser();
                user.isReportHelpPopupShown = value;
                $sessionStorage.putObject('userInfo', user);
            },
            changeSendCopyEnabledByDefault: function (value) {
                var user                        = this.retrieveStoredUser();
                user.isSendCopyEnabledByDefault = value;
                $sessionStorage.putObject('userInfo', user);
            },
            retrieveStoredUser            : function () {
                return $sessionStorage.getObject('userInfo') || {};
            },
            setRootScopeUser              : function (user) {
                $rootScope.currentUser = user;
            },
            resetStoredUser               : function () {
                $sessionStorage.remove('userInfo');
                $sessionStorage.remove('business_logo');
                $sessionStorage.remove('widgets_order');

                this.setRootScopeUser(null);
                $rootScope.$broadcast('auth:reset-stored-user');
            },

            storeUser: function (data) {
                $sessionStorage.putObject('userInfo', {
                    username                      : data.user_name,
                    isAdmin                       : data.is_admin,
                    isLocked                      : data.is_locked,
                    registrationStep              : data.registration_step,
                    isDistributorInitialPopupShown: data.is_distributor_initial_popup_shown,
                    isReportHelpPopupShown        : data.is_reports_help_popup_enabled,
                    isSendCopyEnabledByDefault    : data.is_send_copy_enabled_by_default,
                    profile                       : data.profile,
                    is_accountant                 : data.is_accountant,
                    user_type                     : data.user_type,
                });
                $sessionStorage.put('business_logo', data.business_logo);
                $sessionStorage.put('widgets_order', data.widgets_order);
            }
        };
        return object

    })
    .service('Auth', function ($rootScope, API, Url, TokenStorage, AuthDataStorage) {


        this.isAuthenticated                = isAuthenticated;
        this.registrationIsComplete         = registrationIsComplete;
        this.logout                         = logout;
        this.login                          = login;
        this.getUser                        = retrieveStoredUser;
        this.changeProfile                  = changeProfile;
        this.changeDistributorPopupStatus   = changeDistributorPopupStatus;
        this.changeReportHelpPopupStatus    = changeReportHelpPopupStatus;
        this.changeSendCopyEnabledByDefault = changeSendCopyEnabledByDefault;
        this.recoverPassword                = recoverPassword;
        this.onRegister                     = onRegister;
        this.getProfileValue                = getProfileValue;
        this.checkSessionToken              = checkSessionToken;
        this.checkRememberedToken           = checkRememberedToken;
        this.refreshData                    = refreshData;

        return this;


        function getProfileValue(value) {
            return retrieveStoredUser()[value];
        }

        function recoverPassword() {

        }

        function retrieveStoredToken() {
            return TokenStorage.getCurrentSessionToken();
        }

        function retrieveStoredUser() {
            return AuthDataStorage.retrieveStoredUser();
        }

        function onRegister() {
            AuthDataStorage.resetStoredUser();
        }

        function refreshData(data) {
            AuthDataStorage.storeUser(data);
            AuthDataStorage.setRootScopeUser(retrieveStoredUser());
            $rootScope.$broadcast('auth:login');
        }

        function login(data, rememberMe) {

            TokenStorage.setCurrentSessionToken(data.api_key);

            if (rememberMe) {
                TokenStorage.setRememberedToken(data.api_key);  
            }

            refreshData(data);
        }

        function changeProfile(profile) {
            AuthDataStorage.changeProfile(profile);
        }

        function changeDistributorPopupStatus(value) {
            AuthDataStorage.changeDistributorPopupStatus(value);
        }

        function changeReportHelpPopupStatus(value) {
            AuthDataStorage.changeReportHelpPopupStatus(value);
        }

        function changeSendCopyEnabledByDefault(value) {
            AuthDataStorage.changeSendCopyEnabledByDefault(value);
        }

        function isAuthenticated() {
            return !!(AuthDataStorage.retrieveStoredUser().username);
        }


        function registrationIsComplete() {
            var userInfo = AuthDataStorage.retrieveStoredUser();
            return userInfo.registrationStep === 3;
        }

        function logout() {
            AuthDataStorage.resetStoredUser();
            $rootScope.$broadcast('auth:logout');
        }

        function checkRememberedToken() {

            return new Promise(function (resolve, reject) {


                var rememberedToken = TokenStorage.getRememberedToken();

                if (rememberedToken) {
                    TokenStorage.setCurrentSessionToken(rememberedToken)
                }

                resolve()
            })
        }


        function checkSessionToken() {

            return new Promise(function (resolve, reject) {

                if (!retrieveStoredToken()) {
                    return resolve();
                }

                return API.get(Url.auth.validateApiKey())
                    .success(function (data) {
                        refreshData(data);
                        resolve();
                    })
                    .error(function () {
                        logout();
                        resolve();
                    })
            })

        }
    });