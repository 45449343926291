/**
 * Created by pc on 02.08.2016.
 */
angular.module('sinvoice-desktop')
    .controller('returnFormController',
        function ($scope,
                  Modal,
                  $translate,
                  DocumentModalCallbackContainer,
                  DocumentTypeConstants,
                  RelatedDocumentTableCallbackContainer) {

            $scope.template.$reliableTypesOfOpenDocuments      = [
                DocumentTypeConstants.TYPE_DELIVERY_NOTE,
                DocumentTypeConstants.TYPE_TAX_INVOICE
            ];

            (function () {


                if (!angular.isDefined($scope.editedDocument.returnDate))
                    $scope.editedDocument.returnDate = momentWrapped();

                RelatedDocumentTableCallbackContainer.addPreSelectCallback(deliveryNoteSelectPopup);

                DocumentModalCallbackContainer.addPreSaveCallback(customerIdRequiredPopup);


                function deliveryNoteSelectPopup(flow, attributes) {

                    var actions       = attributes.actions;
                    var addedDocument = attributes.addedDocument;

                    if (addedDocument.type.docType !== DocumentTypeConstants.TYPE_DELIVERY_NOTE) {
                        flow.allow();
                        return;
                    }

                    Modal
                        .confirm(
                            $translate.instant('documents.on_select_related_doc_popup.message'),
                            $translate.instant('documents.return_form.on_select_related_doc_popup.relate_bidirectional'),
                            $translate.instant('documents.on_select_related_doc_popup.copy'),
                            {
                                closeButton: false,
                                title      : $translate.instant('documents.on_select_related_doc_popup.title')
                            }
                        )
                        .then(
                            function () {
                                flow.deny();
                                actions.relate();
                            },
                            function () {
                                flow.deny();
                                actions.copy();
                            });

                }

                function customerIdRequiredPopup(flow) {

                    var customer = $scope.editedDocument.customer;

                    if (customer && customer.businessnumber) {
                        flow.allow();
                        return true;
                    }

                    var popupFunc = function () {

                        Modal.customDialog({
                            scopeVariables: {
                                docType : $scope.editedDocument.type,
                                customer: angular.copy(customer),
                                flow    : flow,
                                onSave  : function (customer) {
                                    $scope.editedDocument.customer = customer
                                }
                            },
                            title         : $translate.instant('documents.missing_info_popup.title'),
                            templateUrl   : './desktop/components/documents/popups/customer-info-missing-popup.html',
                            className     : 'centered-popup',
                            size          : "medium"
                        });

                    };

                    popupFunc();

                }


            })()


        });