angular.module('sinvoice-desktop')

    .directive('tpicker', ['$compile', function ($compile) {
        return {
            scope      : {
                opened: '=?',
                time  : '=ngModel'
            },
            require    : "ngModel",
            templateUrl: './desktop/components/customers/time-select-popup.html',
            replace    : true,
            link       : function (scope, element, attr, ngModelCtrl) {

                $.each(attr, function (key, value) {
                    element.removeAttr(key);
                });

                element.find('.input-container input').attr('class', attr.class);

                scope.$watch('opened', function (newVal) {

                    var dropdown = $(element).find('.dropdown-menu');
                    if (newVal)
                        dropdown.show();
                    else
                        dropdown.hide()
                });

            },
            controller : function ($scope) {

                $scope.$watch('time', function (newVal) {


                    if (!newVal)
                        return false;

                    var parsed = momentWrapped(newVal, 'HH:mm');

                    $scope.hour   = parsed.format('HH');
                    $scope.minute = parsed.format('mm');

                });

                $scope.open  = function () {
                    $scope.opened = true;
                };
                $scope.close = function () {
                    $scope.opened = false;
                };

                $scope.save = function () {
                    $scope.time = $scope.hour + ':' + $scope.minute;
                    $scope.close();
                }
            }
        }
    }])
;
   