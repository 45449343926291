/**
 * Created by abichenko on 20.11.17.
 */
angular.module('sinvoice-mobile').directive('itemsBlock', function () {


    return {
        scope      : {
            paragraphs                : "=",
            selectedVatParametersModel: '=',
            discountsModel            : '=',
            templateVariable          : '=',
            isRoundingEnabled         : '=',
            currency                  : '=',
            docType                   : '='

        },
        controller : 'itemsBlockController',
        templateUrl: './mobile/components/new-document/partials/items-block.html',
        link       : function (scope, element, attrs) {

        }
    }
})