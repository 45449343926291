/**
 * Created by abichenko on 29.01.18.
 */
angular.module('sinvoice-desktop')
    .controller('smilingManController', function ($scope, $timeout, BusinessLogo, UserSubscription) {

        $scope.allowedFormats = ['png', 'jpg'];

        (function loadLogo() {
            $scope.spinner = {enabled: true};
            BusinessLogo
                .getLogo()
                .then(function (data) {
                    $scope.logo = data.content;
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        })();


        $scope.close = function () {
            $scope.helpPopupIsOpened = false;
        };

        $scope.togglePopup = function () {
            $scope.helpPopupIsOpened = !$scope.helpPopupIsOpened;
        };

        $scope.getLogoContent = function () {

            if ($scope.logo)
                return $scope.logo;
            else {
                return "/assets/img/smb_man_arr.png";
            }
        };

        $scope.uploadLogo = function (addedFiles) {

            $scope.spinner.enabled = true;

            UserSubscription
                .checkSubscriptionForEditAction()
                .then(function () {
                    return BusinessLogo.uploadLogo(addedFiles[0].content)
                })
                .then(function () {
                    $scope.logo = addedFiles[0].content;
                })
                .finally(function () {
                    $timeout(function () {
                        $scope.spinner.enabled = false;
                    });
                });
        };

        (function () {

            if (!angular.isDefined($scope.helpPopupIsOpened)) {
                $scope.helpPopupIsOpened = false;
            }

        })();
    });