/**
 * Created by dbichenko on 30.08.2016.
 */
angular.module('sinvoice-desktop').controller('invitedDistributorsController', function (Referral, $scope, Events) {
    var perPage = 2;

    $scope.spinner = true;
    $scope.invites = null;

    $scope.showAmount = perPage;

    $scope.showMore = function () {
        $scope.showAmount += perPage;
    };

    $scope.resendInvitation = function (id) {
        Referral.resendInvitation(id).then(function(){        
            loadTable();
            Events.triggerEvent('invites.update');
        });
    };

    Events.subscribeToEvent('invites.update', $scope, function (){
        loadTable();
    });

    function loadTable() {
        return Referral.getInvited().then(function (data) {

            data.sort(function (a, b) {
                return new Date(a.creationTime) - new Date(b.creationTime);
            });

            $scope.invites = data;
        });
    }

    (function () {

        loadTable().then(function () {
            $scope.spinner = false;
        });
    })()

});
