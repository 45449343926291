/**
 * Created by dbichenko on 03.11.2016.
 */
angular.module('sinvoice-desktop').controller('sendSMSPopupController', function ($scope, $filter, sendSMSPopupFunctions, Modal, $translate, NotificationModal) {

    var allRecipients = [];

    $scope.spinner = {enabled: true};


    $scope.removeSelectedRecipient = function ($index) {
        $scope.recipients.splice($index, 1);
    };


    $scope.addSelectedRecipient = function (recipient) {
        $scope.recipients.push(recipient);
    };

    $scope.send = function () {

        if ($filter('isSubmitNotAllowed')($scope.sendSMSForm))
            return false;


        $scope.spinner.enabled = true;
        var ids                = _.map($scope.recipients, 'id');
        var sendSMSFunction    = sendSMSPopupFunctions.getSendSMSFunction($scope.$type);

        sendSMSFunction(ids, $scope.message, $scope.recipients)
            .then(function (data) {

                    var emptyPhones = $scope.recipients.filter(function (recipient) {
                        return !recipient.phone;
                    });

                    var serverError = $scope.recipients.filter(function (recipient) {
                        if (!data.result.phone.failure.length) {
                            return false;
                        }
                        return _.includes(data.result.phone.failure, recipient.phone);
                    });


                    var failedList = _.flatten([
                        emptyPhones,
                        serverError
                    ]);

                    var failedNames       = _.map(failedList, 'name');
                    var allRecipientNames = _.map($scope.recipients, 'name');

                    NotificationModal
                        .displaySendSMSResultPopup(failedNames, allRecipientNames)
                        .then($scope.cancel)
                }
            )
            .finally(function () {
                $scope.spinner.enabled = false;
            })
    }
    ;

    $scope.cancel = function () {
        $scope.$currentModal.close();
    };


    (function () {


        $scope.message = '';

        if ($scope.recipients) {
            var mapperFunction = sendSMSPopupFunctions.getMapperFunction($scope.$type);
            $scope.recipients  = $scope.recipients.map(mapperFunction)
        } else {
            $scope.recipients = [];
        }

        loadAllRecipients().then(function () {

            $scope.spinner.enabled = false;

            $scope.$watchCollection('recipients', function (newVal) {

                $scope.sendSMSForm.$setValidity('recipients', newVal.length >= 1);

                $scope.availableRecipients = _.differenceBy(allRecipients, $scope.recipients, 'id');

            });
        });

    })();


    function loadAllRecipients() {

        var dataFunction   = sendSMSPopupFunctions.getDataFunction($scope.$type);
        var mapperFunction = sendSMSPopupFunctions.getMapperFunction($scope.$type);

        return dataFunction().then(function (data) {
            allRecipients = data.map(mapperFunction);
        })
    }


})
;