/**
 * Created by abichenko on 29.01.18.
 */
angular.module('sinvoice-desktop')

    .directive('questionButtonWithPopup', function () {
        return {
            replace : true,
            scope   : {'alias': '='},
            template: '' +
            '<div style="position: relative">' +
            '   <question-button on-click="toggle()"></question-button>' +
            '   <help-popup class="customer-section-popup" ' +
            '               on-close="close()"' +
            '               ng-if="popupIsOpened"' +
            '               alias="alias">' +
            '   </help-popup>' +
            '</div>',
            link    : function (scope, element, attr) {
                scope.popupIsOpened = false;

                scope.close = function () {
                    scope.popupIsOpened = false;
                };

                scope.toggle = function () {
                    scope.popupIsOpened = !scope.popupIsOpened;
                };

            }
        }
    })

    .directive('smilingMan', function (UserSubscription) {

        return {

            scope      : {
                title                  : '=',
                description            : '=',
                helpPopupIsOpened      : '=',
                helpPopupTemplate      : '=?',
                helpPopupHeaderTemplate: '=?',
                helpPopupAlias         : '=?'
            },
            controller : 'smilingManController',
            replace    : true,
            templateUrl: './desktop/components/common/smiling-man/smiling-man-directive.html',

            link: function (scope, element, attrs) {

                element.attr('title', '');

                element.on('click', '.upload-button', function () {
                    if (UserSubscription.isCheckInProgress()) {
                        return;
                    }
                    angular.element(".businessLogoInput").click();
                });
            }
        }
    })

    .directive('questionButton', function () {
        return {

            restrict: 'E',
            replace : true,
            scope   : {'onClick': '&'},
            template: '<a class="smb_links_help" ng-click="click()" tabindex="0"></a>',

            link: function (scope, element, attrs) {

                scope.click = function () {
                    scope.onClick();
                }

            }
        }

    });
