/**
 * Created by dbichenko on 05.09.2016.
 */
angular.module('sinvoice-desktop').service('TopnavContainer', function () {

    var elements = {};

    this.addElement    = addElement;
    this.getElements   = getElements;
    this.removeElement = removeElement;

    return this;

    function addElement(key, templateUrl) {
        elements[key] = templateUrl;
    }

    function getElements() {
        return elements;
    }

    function removeElement(key) {
        delete elements[key];
    }

})