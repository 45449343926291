/**
 * Created by abichenko on 14.02.18.
 */
angular.module('sinvoice-desktop').factory('DetailsBackup', function (EditedValueComparator) {

    var backups = {};

    return {
        isChanged               : function (sectionName) {
            if (sectionName) {
                return EditedValueComparator.isObjectChangedExceptOfProps(backups[sectionName].old, backups[sectionName].new)
            } else {
                var result = false;
                $.each(backups, function (key, section) {
                    if (EditedValueComparator.isObjectChangedExceptOfProps(section.old, section.new)) {
                        result = true;
                    }
                });
                return result;
            }
        },
        registerTrackedSection  : function (sectionName, oldValue, newValue) {
            backups[sectionName] = {old: oldValue, new: newValue};
        },
        unregisterTrackedSection: function (sectionName) {
            delete backups[sectionName];
        }
    }


})