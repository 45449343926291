/**
 * Created by abichenko on 08.12.17.
 */
angular.module('sinvoice-mobile').controller('MobileViewDocumentController',
    function ($scope,
              $state,
              $stateParams,
              Document,
              $filter,
              CustomerTypeChecker,
              DocumentSummaryDataProvider,
              DocumentPaymentAmountCalculator,
              ModalContainer,
              MobileModal) {

        $scope.spinner  = {enabled: true};
        $scope.document = null;

        $scope.back = function () {
            $state.go('my-documents');
        };

        $scope.close = function () {

            MobileModal
                .confirm(
                    $filter('translate')('documents.close_document_message'),
                    null,
                    null, {title: $filter('translate')('documents.close_document_message_title')}
                )
                .then(function () {
                    $scope.spinner.enabled = true;
                    Document.closeDocument($scope.document.id)
                        .then(function () {
                            ModalContainer.closeAllModals();
                            $state.go('my-documents');
                        })
                        .finally(function () {
                            $scope.spinner.enabled = false;
                        })
                })

        };

        $scope.getCustomerName = function (document) {
            if (CustomerTypeChecker.isOccasional(document.customer.customerType)) {
                return document.customerOccasionalName
            } else {
                return document.customer.name;
            }
        };


        function getPaymentSymbol(document) {
            return document.isNisPayments ? 'NIS' : document.currencies.symbol;
        }

        function populatePaymentsSummary(document) {
            var paymentsTotalWithVat = 0;
            document.payments.forEach(function (payment) {
                paymentsTotalWithVat += parseFloat(payment.total);
            });

            var totals = DocumentPaymentAmountCalculator.getPaymentTotals(
                paymentsTotalWithVat,
                document.taxValue,
                !!document.taxValue,
                document.isNisPayments,
                document.currencies,
                document.nisCourse
            );

            $scope.paymentSummary = DocumentSummaryDataProvider.getPaymentSummaries({
                    $paymentsTotalWithVatNIS: totals.paymentsTotalWithVatNIS,
                    $paymentsTotalWithVat   : totals.paymentsTotalWithVat,
                    $paymentsTotal          : totals.paymentsTotal
                },
                document.taxValue,
                document.currencies,
                document.nisCourse,
                getPaymentSymbol(document)
            )
        }

        function populateParagraphSummary(document) {
            $scope.paragraphSummary = DocumentSummaryDataProvider.getSummaries({
                    $itemsExcludedVatBeforeDiscount: document.itemsWithoutVatTotalBeforeDiscount,
                    $itemsExcludedVatAfterDiscount : document.itemsWithoutVatTotalAfterDiscount,
                    $itemsExcludedVatRoundingValue : document.itemsWithoutVatRoundingValue,
                    $itemsExcludedVatTotal         : document.itemsWithoutVatTotalRounded,
                    $itemsIncludedVatBeforeDiscount: document.itemsWithVatTotalBeforeDiscount,
                    $itemsIncludedVatAfterDiscount : document.itemsWithVatTotalAfterDiscount,
                    $itemsIncludedVatRoundingValue : document.itemsWithVatRoundingValue,
                    $itemsIncludedVatTotal         : document.itemsWithVatTotalRounded,
                    $vatAmount                     : document.roundedVATAmount,
                    $finalItemsTotalWithVat        : document.roundedItemsTotalWithVat,
                    $vatAmountNIS                  : document.roundedVATAmountNIS,
                    $finalItemsTotalWithVatNIS     : document.roundedItemsTotalWithVatNIS
                },
                document.selectedVATParameters,
                document.type.docType,
                document.discounts,
                document.currencies,
                document.nisCourse
            );
        }

        function populateSummaries(document) {
            populateParagraphSummary(document);
            populatePaymentsSummary(document);
        }

        function populateDates(document) {

            var result = [];
            var map    = [
                ['issue_date', 'issueDate'],
                ['return_date', 'returnDate'],
                ['delivery_date', 'deliveryDate'],
                ['date_of_payment', 'paymentDate'],
                ['valid_until', 'validUntil']
            ];

            map.forEach(function (row) {
                var label        = row[0];
                var propertyName = row[1];

                if (angular.isDefined(document[propertyName])) {
                    result.push({
                        value: document[propertyName],
                        label: $filter('documentPopupTranslate')('documents.' + label, document.type.docType)
                    })
                }
            });

            $scope.dates = result;
        }

        (function () {

            Document.get($stateParams.documentId)
                .then(function (data) {
                    var document    = data[0];
                    $scope.document = document;
                    populateSummaries(document);
                    populateDates(document);
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        })();
    });