/**
 * Created by abichenko on 07.03.18.
 */
angular.module('sinvoice-desktop').controller('attributesController',
    function ($scope,
              DataTableLanguageSettings,
              DataTableBootstrapSettings,
              DTOptionsBuilder,
              DTColumnDefBuilder,
              Attribute,
              $filter,
              Events,
              AttributeModal,
              Modal,
              $compile,
              $timeout) {

        $scope.spinner = {enabled: true};

        $scope.values = [];

        $scope.editAttributeValue = function (value) {
            AttributeModal.editAttributeValue(value.attribute.id, $scope.edited.customer.id)
        };

        $scope.deleteAttribute = function (attribute) {

            Modal
                .confirm(
                    $filter('translate')('customers.edit.attributes.delete_confirm_message'),
                    null,
                    null,
                    {title: $filter('translate')('customers.edit.attributes.delete_confirm_message_title')}
                )
                .then(function () {
                    return Attribute.deleteAttribute(attribute.id)
                })
                .then(function () {
                    return Modal.alert(
                        $filter('translate')('customers.edit.attributes.delete_success_message'),
                        {title: $filter('translate')('customers.edit.attributes.delete_success_message_title')}
                    )
                })
                .then(function () {
                    Events.triggerEvent('attributes.update');
                })
        };

        $scope.createAttribute = function () {
            AttributeModal.createAttribute();
        };

        function loadTable() {
            $scope.dtColumns  = initializeDataTableColumns();
            $scope.dtInstance = {};
            $scope.dtOptions  = initializeDataTable();
        }

        function initializeDataTableColumns() {

            var columns = [
                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1),
                DTColumnDefBuilder.newColumnDef(2),
                DTColumnDefBuilder.newColumnDef(3).renderWith(function (data) {
                    return data;
                })

            ];


            return columns;

        }

        function initializeDataTable(promise) {

            var dtOptions = DTOptionsBuilder
                .newOptions()
                .withBootstrap()
                .withOption('sDom', "rt<'row'<'col-xs-12 text-center'p>>")
                .withOption('autoWidth', false)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withOption('order', [1, "asc"])
                .withDisplayLength(10)
                .withOption('fnCreatedRow', function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
                    $compile(nRow.cells)(angular.element(nRow).scope());
                })
                .withButtons([]);


            return dtOptions;
        }

        function loadAttributes() {
            return Attribute
                .getValues($scope.edited.customer.id)
                .then(function (data) {
                    $timeout(function () {
                        $scope.values = data;
                    })
                })
                .finally(function () {
                    $scope.spinner.enabled = false
                })
        }

        (function () {

            Events.subscribeToEvent('attributes.update', $scope, function () {
                loadAttributes();
            });

            loadAttributes();

            loadTable();
        })();

    });