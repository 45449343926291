/**
 * Created by dbichenko on 31.08.2016.
 */
angular.module('sinvoice-desktop').controller('representativesMainController', function ($scope, TopnavContainer, ManagedCustomersManagement) {


    $scope.edited = {customer:{}};

    $scope.backToTable = function () {
        ManagedCustomersManagement.disableManagementMode();
    };

    $scope.$on('editCustomer', function (event, id) {
        ManagedCustomersManagement.setCurrentManagedCustomer(id);
        updateCurrentCustomer();
    });

    function loadNavbarMenu() {

        TopnavContainer.addElement('managedCustomers', './desktop/components/customers/representatives/managed-customers-navbar.html');
        $scope.$on('$destroy', function () {
            TopnavContainer.removeElement('managedCustomers');
        });
    }

    function updateCurrentCustomer() {
        $scope.edited.customer = ManagedCustomersManagement.getCurrentManagedCustomer();
    }

    (function () {

        loadNavbarMenu();

        $scope.$watch(function () {
            return ManagedCustomersManagement.getCurrentManagedCustomer();
        }, function () {
            updateCurrentCustomer();
        })

    })();
});