/**
 * Created by abichenko on 29.11.17.
 */
angular.module('sinvoice-mobile').factory('MobileDocumentModalCallbackProvider', function (MobileModal, $filter, $q) {

    return {
        openOccasionalCustomerInfoMissingPopup: function (editedDocument) {

            var occasionalCustomerName = editedDocument.customerOccasionalName;
            if (occasionalCustomerName === $filter('translate')('documents.use_occuring')) {
                occasionalCustomerName = '';
            }

            var defer = $q.defer();

            MobileModal.bluebarModal(
                './mobile/components/new-document/partials/popups/occasional-customer-info-missing-popup.html',
                {
                    scopeVariables: {
                        docType                    : editedDocument.type,
                        occasionalCustomerName     : occasionalCustomerName,
                        occasionalCustomerVatNumber: editedDocument.customerOccasionalVatNumber,
                        onSave                     : function (occasionalCustomerName, occasionalCustomerVatNumber) {
                            editedDocument.customerOccasionalVatNumber = occasionalCustomerVatNumber;
                            editedDocument.customerOccasionalName      = occasionalCustomerName;
                            defer.resolve();
                        },
                        onCancel                   : function () {
                            defer.reject();
                        }
                    },
                    title         : $filter('documentPopupTranslate')('documents.occasional_customer_missing_info_popup.title', editedDocument.type.docType),
                });

            return defer.promise;
        }
    }

});