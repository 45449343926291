/**
 * Created by abichenko on 14.12.17.
 */
angular.module('sinvoice-desktop')
    .controller('paymentsElementBranchController', function ($scope, BusinessBankAccountDataProvider, DocumentInitializer) {

        DocumentInitializer.addInitCallback(function () {
            if (!angular.isDefined($scope.payment.branch) && $scope.selectedCustomerData) {
                $scope.payment.branch = BusinessBankAccountDataProvider.getBranch($scope.selectedCustomerData);
            }
        });

    });