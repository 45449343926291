/**
 * Created by abichenko on 07.03.18.
 */

angular.module('sinvoice-common')
    .factory('Attribute', function ($q, Url, API) {

        return {

            getTypes       : function () {
                var deferred = $q.defer();
                API.get(Url.attributes.types(), {})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise
            },

            allAttributes       : function () {
                var deferred = $q.defer();
                API.get(Url.attributes.all(), {})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise
            },

            createAttribute: function (data) {
                var deferred = $q.defer();

                API.put(Url.attributes.all(), data)
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },


            updateAttribute: function (id, data) {
                var deferred = $q.defer();

                API.patch(Url.attributes.getSingle(id), data)
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

            getValues: function (customerId) {
                var deferred = $q.defer();
                API.get(Url.attributes.allValues(customerId), {})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

            getSingleValue: function (id, customerId) {
                var deferred = $q.defer();

                API.get(Url.attributes.getValue(customerId, id))
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

            updateValue: function (id, customerId, data) {
                var deferred = $q.defer();

                API.patch(Url.attributes.getValue(customerId, id), data)
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

            deleteAttribute: function (id) {
                var deferred = $q.defer();

                API.delete(Url.attributes.getSingle(id))
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;

            },
            createValue    : function (customerId, data) {
                var deferred = $q.defer();

                API.put(Url.attributes.allValues(customerId), data)
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

        }

    });