/**
 * Created by dbichenko on 15.08.2016.
 */
angular.module('sinvoice-desktop').directive('highchart', ['$filter',function ($filter) {

    return {
        restrict: 'A',
        scope   : {
            width     : '@chartWidth',
            height    : '@chartHeight',
            categories: '=',
            data      : '=chartData'
        },

        link: function ($scope, $element, $attrs) {

            function roundData(data) {
                for (var i = 0; i < data.length; i++) {
                    data[i] = Math.round(data[i]);
                }
                return data;
            }


            drawChart();

            $scope.$watch('width + height', function (newVal, oldVal) {
                if (newVal != oldVal) {
                    drawChart();
                }
            });

            $scope.$watch('categories', function (newVal, oldVal) {
                if (newVal != oldVal) {
                    drawChart();
                }
            });
            $scope.$watch('data', function (newVal, oldVal) {
                if (newVal != oldVal) {
                    drawChart();
                }
            });



            function drawChart() {


                if (typeof $scope.width === 'undefined') {
                    throw new Error('custom highcharts: chart-width attribute is expected');
                }

                if (typeof $scope.categories === 'undefined') {
                    throw new Error('custom highcharts: categories attribute is expected');
                }

                if (typeof $scope.data === 'undefined') {
                    throw new Error('custom highcharts: chart-data attribute is expected');
                }

                var maxValue;
                var minValue;
                var stepValue = 5000;


                var chartOptions = {
                    width: $scope.width
                };

                if (typeof $scope.height !== 'undefined')
                    chartOptions.height = $scope.height;

                $scope.data = roundData($scope.data);
                maxValue    = Math.max.apply(null, $scope.data);
                minValue    = Math.min.apply(null, $scope.data);

                $($element).highcharts({
                    chart      : chartOptions,
                    legend     : {
                        enabled: false
                    },
                    plotOptions: {
                        series: {
                            marker: {
                                fillColor: '#0A69B1',
                                lineWidth: 2,
                                lineColor: '#0A69B1' // inherit from series
                            }
                        }
                    },
                    colors     : ['#CCCCCC'],
                    title      : {
                        text: '',
                        x   : -18 //center
                    },
                    xAxis      : {
                        categories: $scope.categories,

                        reversed : true,
                        lineColor: "#CCCCCC",
                        tickWidth: 0
                    },
                    yAxis      : {
                        tickWidth   : 1,
                        tickPosition: 'inside',
                        tickLength  : 35,
                        tickColor   : "#CCCCCC",
                        // floor       : minValue,
                        // ceiling     : maxValue,
                        min: 0,
                        max: maxValue + stepValue,
                        endOnTick:false,
                        tickInterval: stepValue,

                        title       : {
                            text: ''
                        },
                        align       : 'left',
                        opposite    : true,
                        labels      : {
                            align: 'right',
                            x    : 0,
                            y    : -15,
                            formatter: function() {
                                return $filter('number')(this.value, 2);
                            }
                        },
                        offset      : 35
                    },
                    series     : [{
                        data: $scope.data
                    }]
                });
            }
        }
    }
}])

    .directive('highchartTabs', function () {
        return {
            scope     : {
                tabs       : '=',
                activeChart: '='
            },
            transclude: true,
            link      : function (scope, element, attrs) {
            },
            controller: function ($scope) {

                this.activateTab = $scope.activateTab = function (tabId) {
                    $scope.activeChart = tabId;
                };

                this.tabIsActive = $scope.tabIsActive = function (tabId) {
                    return $scope.activeChart == tabId
                };

            },
            template  : '' +
            '<div class="pull-right"> ' +
            '   <div class="graph_widget_graph_box pull-left">' +
            '      <ng-transclude></ng-transclude>' +
            '   </div>' +
            '   <div class="graph_widget_tabs pull-right">' +
            '      <span ng-repeat="tab in tabs">' +
            '         <a href="" ng-class="{active: tabIsActive(tab.id)}" ng-click="activateTab(tab.id)">' +
            '             <div class="graph_widget_tabs_valign">' +
            '                 {{ tab.label }} <span class="ltr">{{ tab.value }}</span> ' +
            '             </div>' +
            '         </a>' +
            '      </span>' +
            '   </div>' +
            '</div>'

        }
    })

    .directive('highchartTab', function () {
        return {
            scope  : {tabId: '@'},
            require: '^highchartTabs',
            link   : function (scope, element, attrs, tabCtrl) {

                scope.$watch(function () {
                        return tabCtrl.tabIsActive(scope.tabId)
                    },
                    function (newVal) {

                        if (newVal)
                            element.show();
                        else
                            element.hide();
                    })

            }
        }
    })

    .directive('highchartBrief', function () {
        return {
            scope   : {
                title: '=?',
                lines: '=?'
            },
            link    : function (scope, element, attrs) {

            },
            template: '' +
            '   <div class="brief_info_box">' +
            '      <h4>{{title}}</h4>' +
            '      <section>' +
            '         <table class="detailed_graph_table" style="table-layout: fixed">' +
            '         <tr ng-repeat="line in lines">' +
            '             <td style="width: 50%"><a href="#">{{ line.label }}</a></td>' +
            '             <td style="width: 50%">' +
            '                   <cut-value class="brief-value">{{ line.value }}</cut-value>' +
            '             </td>' +
            '         </tr>' +
            '         </table>' +
            '      </section>' +
            '   </div>'


        }
    })
;