/**
 * Created by ikorol on 24.10.2017.
 */
angular.module('sinvoice-mobile')
    .controller('MobileCustomerDetailsPopupController',
        function ($stateParams, $scope, Customer, $filter, Events, CustomerDetailsModal) {

            $scope.backup = {};

            $scope.detailsForm = {};

            $scope.spinner = {enabled: false};

            $scope.close = function () {
                CustomerDetailsModal.closeContactEditModals();
                $scope.$currentModal.close();
            };

            $scope.removeEmail = function () {
                $scope.customer.email = '';
            };

            $scope.removePhone = function () {
                $scope.customer.phone = '';
            };

            $scope.editPhone = function (phone) {

                if (!$scope.isEditable)
                    return;

                CustomerDetailsModal.editPhone(
                    phone,
                    function (newPhone) {
                        $scope.customer.phone = newPhone
                    }
                );

            };

            $scope.editEmail = function (email) {

                if (!$scope.isEditable)
                    return;

                CustomerDetailsModal.editEmail(
                    email,
                    function (newEmail) {
                        $scope.customer.email = newEmail
                    }
                );
            };


            $scope.save = function () {

                if ($filter('isSubmitNotAllowed')($scope.detailsForm)) {
                    return;
                }

                $scope.spinner.enabled = true;
                Customer
                    .update($scope.customer.id, $scope.customer)
                    .finally(function () {
                        $scope.spinner.enabled = false;
                        Events.triggerEvent('customer.update');
                        $scope.$currentModal.close();
                    })
            };


            (function () {
                $scope.backup.customer = angular.copy($scope.customer);
                initializeValidators();
            })();

            function initializeValidators() {
                $scope.validators = {}
            }

        });