/**
 * Created by dbichenko on 08.11.2016.
 */
angular.module('sinvoice-desktop').controller('YourUrlBlockController', function ($scope, AuthDataStorage, $state) {

    var referralId = AuthDataStorage.getProfile().referralUrl;
    $scope.url     = $state.href('register', {refId: referralId},{absolute:true});




});