/**
 * Created by dbichenko on 15.09.2016.
 */
angular.module('sinvoice-common').factory('promiseHelper', function ($q) {


    return {
        createPromiseChain         : createPromiseChain,
        createPromiseChainWithDefer: createPromiseChainWithDefer,
        resolvedPromise            : resolvedPromise,
    };

    function createPromiseChain(data) {
        var promiseChain = resolvedPromise();

        data.forEach(function (callback) {

            if (!angular.isFunction(callback)) {
                callback = function () {
                    return callback;
                };
            }

            promiseChain = promiseChain.then(callback);

        });

        return promiseChain;
    }

    function createPromiseChainWithDefer(data, additionalArguments) {
        var promiseCallbacks = [];

        data.forEach(function (callback) {

            if (!angular.isFunction(callback)) {
                throw new Error('Please provide correct callback to create promise chain');
            }

            var defer = $q.defer();
            var flow  = {
                allow: defer.resolve,
                deny : defer.reject
            };

            promiseCallbacks.push(function () {
                callback(flow, additionalArguments);
                return defer.promise
            });

        });

        return createPromiseChain(promiseCallbacks)
    }

    function resolvedPromise(result) {
        var defer = $q.defer();
        defer.resolve(result);
        return defer.promise;
    }


});