/**
 * Created by dbichenko on 17.08.2016.
 */
angular.module('sinvoice-desktop')
    .directive('cutValue', function () {

        $(document).on('mouseenter', '.cut-value-container', function () {


            var that = $(this);

            var wrapper = that.find('.cut-value-wrapper');
            if (Number(wrapper.width()) >= wrapper[0].scrollWidth)
                return;


            var tooltip = that.find('.cut-value-tooltip');

            tooltip.css({display: 'inline-block'});
            tooltip.html(wrapper.html());
            wrapper.hide();


        });


        $(document).on('mouseleave', '.cut-value-container', function () {


            var that = $(this);


            var wrapper = that.find('.cut-value-wrapper');
            var tooltip = that.find('.cut-value-tooltip');

            tooltip.hide();
            wrapper.show();
        });

        function setWrapperCSS(element) {
            element.css({
                'display'      : 'block',
                'white-space'  : 'nowrap',
                'overflow-x'   : 'hidden',
                'text-overflow': 'ellipsis',
                'width'        : '100%',
                'background'   : '',
                'position'     : 'relative',
                'z-index'      : 'initial'
            })

        }

        function setTooltipCSS(element) {
            element.css({
                'white-space': 'nowrap',
                'background' : 'white',
                'position'   : 'relative',
                'overflow-x' : 'visible',
                'width'      : 'auto',
                'z-index'    : '999999'
            })
        }


        return {
            replace   : true,
            transclude: true,
            template  : '' +
            '<div style="position: relative; width: 100%; direction: ltr" class="cut-value-container">' +
            '   <div ng-transclude class="cut-value-wrapper"></div>' +
            '   <div class="cut-value-tooltip"></div>' +
            '</div>',
            link      : function (scope, element, attr) {

                var wrapper = element.find('.cut-value-wrapper');
                setWrapperCSS(wrapper);
                var tooltip = element.find('.cut-value-tooltip');
                setTooltipCSS(tooltip);

            }
        }

    })
    .directive('detailsInput', function () {
        return {
            link: function (scope, element, attr) {
                element.on('click', function () {
                    element.find('textarea').focus();
                })
            }
        }
    })

    .directive('inputWithClearButton', function ($timeout) {


        return {
            restrict  : 'A',
            scope     : {
                onCleanValue: '&'
            },
            transclude: 'element',
            replace   : true,
            priority  : 10,
            template  : '' +
            '<div class="clearfix">' +
            '   <div class="input-with-clean-button-holder">' +
            '      <div ng-transclude></div>' +
            '      <button class="delete-grey" type="button" ng-click="cleanValue()">' +
            '         <i class="fa fa-times" aria-hidden="true"></i>' +
            '      </button>' +
            '      <div class="clearfix"></div>' +
            '   </div>' +
            '</div>',
            link      : function (scope, element, attrs) {

                scope.cleanValue = function () {
                    scope.onCleanValue();
                };

                element.removeClass();
                element.addClass('clearfix');
            }
        }
    })

    .directive('overlay', function () {


        return {
            transclude: true,
            template  : '' +
            '<div style="position: absolute;width: 100%;height: 100%;background: white;z-index: 99999999;left: 0;top: 0;">' +
            '   <table style="width: 100%;height: 100%">' +
            '       <tr>' +
            '           <td style="vertical-align: middle">' +
            '               <div ng-transclude=""></div>' +
            '           </td>' +
            '       </tr>' +
            '   </table>' +
            '</div>',
            link      : function () {

            }
        }
    })
    .directive('autogrow', function ($interval) {

        $interval(function () {

            $('.autogrow.queued-to-resize:visible').each(function (key, element) {

                var elementToResize = $(element);

                resize(elementToResize);

                elementToResize.removeClass('queued-to-resize');
            })

        }, 250);

        function resize(element) {

            var scrollHeight = element[0].scrollHeight;

            element.css('height', element.attr('data-basic-height'));
            element.css('height', scrollHeight);
        }


        function link(scope, element, attr) {

            var basicHeight = element.css('height');

            element.addClass('autogrow');
            element.addClass('queued-to-resize');
            element.attr('data-basic-height', basicHeight);

            element.on('change, cut, paste, drop, keydown', function () {
                element.addClass('queued-to-resize');
            });

        }

        return {
            link    : link,
            restrict: 'A'
        }
    });


function isEmpty(value) {
    return angular.isUndefined(value) || value === '' || value === null || value !== value;
}