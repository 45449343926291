angular.module('sinvoice-desktop').controller('representativesMainTableController',
    function ($scope,
              $translate,
              DTOptionsBuilder,
              DTColumnBuilder,
              Customer,
              ManagedCustomers,
              ManagedCustomersManagement,
              DataTableLanguageSettings,
              DataTableBootstrapSettings,
              DataTableFunctions,
              DataTableStorage,
              DataTableDatepickerLabels,
              $filter,
              Modal,
              $timeout,
              $state,
              CustomerModal,
              ManagedCustomerExport,
              ManagedCustomerConstants,
              UserSubscription,
              DirectiveCompiler,
              NotificationModal,
              $compile) {

        // Select
        $scope.managedCustomers = [];
        $scope.selectIsDisabled = true;
        $scope.selectCustomer   = selectCustomer;


        $scope.states = [{name: 'first', state: ''}, {name: 'second', state: 'state'}];


        (function () {
            // Get all managed customers
            ManagedCustomers.getAll().then(function (data) {
                $scope.managedCustomers = data;
                $scope.selectIsDisabled = !data.length;
                addMainPageOption();
            });
        })();

        // Aded link on main page for select
        function addMainPageOption() {
            var page = {};

            page.id        = 0;
            page.comment   = 'main page';
            page.user      = {};
            page.user.name = $filter('translate')('customers.main');

            $scope.managedCustomers.unshift(page);
            $scope.managedCustomer.selected = $scope.managedCustomers[0];
        }

        // Set current edited customer
        function selectCustomer(item) {
            if (item.comment === 'main page') {
                $state.reload();
            } else {
                ManagedCustomersManagement.enableManagementMode(item.id);
            }

        };


        function loadTableOptions() {
            $scope.colSetting = {};

            return DataTableStorage.retrieve('representatives').then(function (rsp) {
                $scope.colSetting = rsp.data;
            });
        }

        function loadTable() {

            $scope.dtInstance = {
                object: {}
            };

            loadTableOptions().then(function () {

                $scope.dtColumns = initializeDataColumns();
                $scope.dtOptions = initializeDataTable(function () {
                    return ManagedCustomers.getAll();
                });
            })
        }

        loadTable();


        function initializeDataColumns() {


            var dtColumns = [

                DTColumnBuilder
                    .newColumn('select')
                    .withOption('width', '50px')
                    .withTitle('' +
                        '<span style="display: none;">' + $translate.instant('customers.table.select') + '</span>' +
                        '<dt-select-all-checkbox></dt-select-all-checkbox>' +
                        '<dt-remove-column-button></dt-remove-column-button>'
                    )
                    .notSortable()
                    .withClass('select-checkbox')
                    .renderWith(function (data) {
                        return '<dt-select-checkbox/>';
                    }),

                DTColumnBuilder
                    .newColumn('status')
                    .withClass('status-box')
                    .withOption('width', '60px')
                    .withTitle($translate.instant('customers.table.status') + '<dt-remove-column-button/>')
                    .withOption('render', {
                        "_"      : "plain",
                        "filter" : function (data, type, fullData) {
                            return 's' + data;
                        },
                        "display": function (data, type, fullData) {
                            return '<span class= "customers-status status-state"></span>';

                        }
                    }),


                DTColumnBuilder
                    .newColumn('user.phone')
                    .withTitle($translate.instant('customers.table.user_phone') + '<dt-remove-column-button/>')
                    .notVisible(),

                DTColumnBuilder
                    .newColumn('business.businessDescriptionHe')
                    .withTitle($translate.instant('customers.table.business_description_he') + '<dt-remove-column-button/>')
                    .notVisible(),

                DTColumnBuilder
                    .newColumn('business.businessType.type')
                    .withTitle($translate.instant('customers.table.business_type') + '<dt-remove-column-button/>')
                    .withOption('render', {
                        "filter" : function (data, type, fullData) {
                            return $filter('translateBusinessTypeLabel')(fullData.business.businessType.type);
                        },
                        "display": function (data, type, fullData) {
                            return $filter('translateBusinessTypeLabel')(fullData.business.businessType.type);
                        }
                    })
                    .notVisible(),


                DTColumnBuilder
                    .newColumn('business.businessNameHe')
                    .withTitle($translate.instant('customers.table.business_name_he') + '<dt-remove-column-button/>'),


                DTColumnBuilder
                    .newColumn('user.name')
                    .withClass('name')
                    .withTitle($translate.instant('customers.table.customer_name') + '<dt-remove-column-button/>'),


                DTColumnBuilder
                    .newColumn('business.authorizedDealerId')
                    .withTitle($translate.instant('customers.table.business_no') + '<dt-remove-column-button/>'),


                DTColumnBuilder
                    .newColumn('user.email')
                    .withTitle($translate.instant('customers.table.mail') + '<dt-remove-column-button/>'),

                DTColumnBuilder
                    .newColumn('income')
                    .withClass('amount')
                    .withOption('width', '150px')
                    .withTitle($translate.instant('customers.table.income') + '<dt-remove-column-button/>')
                    .withOption('render', {
                        "filter" : function (data, type, fullData) {
                            return fullData.income;
                        },
                        "display": function (data, type, fullData) {
                            return DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : fullData.income
                            });
                        }
                    }),


                DTColumnBuilder
                    .newColumn('comment')
                    .withTitle($translate.instant('customers.table.comments') + '<dt-remove-column-button/>')
                    .withOption('render', {
                        "filter" : function (data, type, fullData) {
                            return data;
                        },
                        "display": function (data) {
                            if (data === null) {
                                data = ''
                            }
                            return '<input class="comment-input" value="' + data + '" style="width:100%;border:none;background: none">'
                        }
                    })
            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;
        }


        function changeStatus(status, dt) {


            var ids = DataTableFunctions
                .getSelectedData(dt)
                .map(function (customer) {
                    return customer.id;
                });

            ManagedCustomers.changeStatus(status, ids).then(function () {
                $scope.dtInstance.object.reloadData(null, true)
            })


        }

        function initializeDataTable(promise) {
            return DTOptionsBuilder

                .fromFnPromise(promise)
                .withBootstrap()
                .withOption('sDom', "<'row'<'col-xs-12 text-left mb20'B>>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('stateSave', true)
                .withOption('stateLoadCallback', function (settings) {
                    return $scope.colSetting
                })
                .withOption('autoWidth', false)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withOption('stateSaveCallback', function (settings, data) {

                    DataTableFunctions.recompileHeader(this.api().columns().header());

                    DataTableStorage.store('representatives', data).then(function () {
                        $scope.json = angular.toJson(data);
                    })


                })

                .withOption('createdRow', rowCallback)
                .withColumnFilter({
                    bUseColVis: true,
                    aoColumns : [
                        null,
                        {
                            type            : 'select',
                            bRegex          : true,
                            bCaseInsensitive: true,
                            values          : [
                                {value: '', label: $translate.instant('customers.managed_customer.all')},
                                {value: 's1', label: $translate.instant('customers.managed_customer.active')},
                                {value: 's2', label: $translate.instant('customers.managed_customer.unact')},
                            ]
                        },
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'number-range', bRegex: true, bSmart: true, 'sRangeFormat': DataTableDatepickerLabels.fromToLabels()},
                        {type: 'text', bRegex: true, bSmart: true}

                    ]
                })
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withButtons([
                    {
                        extend   : 'collection',
                        autoClose: true,
                        text     : $translate.instant('customers.status'),
                        buttons  : [
                            {
                                extend       : 'subscriptionEditActionButton',
                                text         : $translate.instant('customers.managed_customer.active'),
                                extendOptions: {
                                    action: function (e, dt) {
                                        changeStatus(ManagedCustomerConstants.STATUS_ACTIVE, dt);
                                    }
                                }

                            },
                            {
                                extend       : 'subscriptionEditActionButton',
                                text         : $translate.instant('customers.managed_customer.unact'),
                                extendOptions: {
                                    action: function (e, dt) {
                                        changeStatus(ManagedCustomerConstants.STATUS_INACTIVE, dt);
                                    },
                                }
                            },
                            {
                                extend       : 'subscriptionEditActionButton',
                                text         : $translate.instant('customers.managed_customer.delete_button_label'),
                                extendOptions: {
                                    action: function (e, dt) {
                                        Modal
                                            .confirm(
                                                $translate.instant('customers.managed_customer.delete_confirm'),
                                                null,
                                                null,
                                                {title: $translate.instant('customers.managed_customer.delete_confirm_title')})
                                            .then(function () {
                                                changeStatus(ManagedCustomerConstants.STATUS_DELETED, dt);
                                            })
                                    }
                                },
                            }
                        ]

                    },
                    {
                        extend       : 'subscriptionEditActionButton',
                        text         : '<i class="mobile"></i>' + $translate.instant('customers.sms'),
                        extendOptions: {
                            action         : function (e, dt, node, config) {
                                var managedCustomers = DataTableFunctions.getSelectedData(dt);
                                NotificationModal.sendSMSToManagedCustomers(managedCustomers);
                            },
                            enabledCallback: function (e, dt) {
                                var managedCustomersWithPhones = DataTableFunctions
                                    .getSelectedData(dt)
                                    .filter(function (customer) {
                                        return !!customer.user.phone;
                                    });

                                return !!managedCustomersWithPhones.length;
                            }
                        }
                    },
                    {
                        extend       : 'subscriptionEditActionButton',
                        text         : '<i class="envelope"></i>' + $translate.instant('customers.email'),
                        extendOptions: {
                            action         : function (e, dt, node, config) {
                                var managedCustomers = DataTableFunctions.getSelectedData(dt);
                                NotificationModal.sendEmailToManagedCustomers(managedCustomers);
                            },
                            enabledCallback: function (e, dt) {
                                var managedCustomersWithEmails = DataTableFunctions
                                    .getSelectedData(dt)
                                    .filter(function (customer) {
                                        return !!customer.user.email;
                                    });

                                return !!managedCustomersWithEmails.length;
                            }
                        }
                    },
                    {
                        text  : '<i class="newspaper-o"></i>' + $translate.instant('customers.newslater'),
                        action: function (e, dt, node, config) {
                            alert('Newsletter activated');
                        }
                    },
                    {
                        extend   : 'collection',
                        autoClose: true,
                        text     : '<i class="cloud-download"></i>' + $translate.instant('customers.export'),
                        buttons  : [
                            {
                                extend: 'selected',
                                text  : $translate.instant('customers.excel'),
                                action: function (e, dt, node, config) {

                                    var customers = DataTableFunctions.getSelectedData(dt);
                                    var columns   = DataTableFunctions.getVisibleColumnNames(dt);

                                    ManagedCustomerExport.downloadManagedCustomersAsXLS(customers, columns);

                                }
                            },
                            {
                                extend: 'selected',
                                text  : 'PDF',
                                action: function (e, dt, node, config) {

                                    var customers = DataTableFunctions.getSelectedData(dt);
                                    var columns   = DataTableFunctions.getVisibleColumnNames(dt);

                                    ManagedCustomerExport.exportManagedCustomersToPDF(customers, columns);

                                },
                            }
                        ]
                    },
                    {
                        text  : '<i class="cloud-upload"></i>' + $translate.instant('customers.import'),
                        action: function (e, dt, node, config) {
                            alert('Import activated');
                        }
                    },
                    {
                        extend   : 'colvis',
                        text     : '<i class="cog"></i>' + $translate.instant('customers.edit_column'),
                        className: 'btn-warning'
                    }
                ]);

        }

        function rowCallback(nRow, aData, iDisplayIndex) {

            $compile(nRow)($scope);


            if (aData.status !== ManagedCustomerConstants.STATUS_ACTIVE) {
                $(nRow).addClass('disabled');
            }

            var setCommentDebounced = _.debounce(function (text) {
                ManagedCustomers.setComment(aData.id, text);
            }, 1000);

            $(nRow).on('change', '.comment-input', function () {
                setCommentDebounced($(this).val());
            });


            $(nRow).on('click', '.name', function () {

                $timeout(function () {
                    ManagedCustomersManagement.enableManagementMode(aData.id)
                })
            });


        }


    });



