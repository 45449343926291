/**
 * Created by dbichenko on 19.10.2016.
 */
angular.module('sinvoice-desktop').controller('exportPdfController', function ($scope, DocumentExport, $state, $stateParams) {

    (function () {

        DocumentExport.showAsPDFByToken($stateParams.token,true);


    })();
})