/**
 * Created by abichenko on 06.10.17.
 */
angular.module('sinvoice-desktop')
    .controller('subscriptionsYearlyBlockController', function ($scope, $filter, $q, Subscription, Profile, Events, Modal) {

        $scope.currentPlan  = null;
        $scope.spinner      = {enabled: false};
        $scope.activeTab    = {};
        $scope.activatePlan = function (plan) {
            $scope.selectedPlan = _.find($scope.plans, {'alias': plan.alias, 'type': plan.type});
        };

        $scope.getPaymentBlockTitle = function () {

            var key;
            var activeUntil;
            var startsFrom;

            startsFrom  = $scope.selectedPlan.affectedPeriods.from;
            activeUntil = $scope.selectedPlan.affectedPeriods.until;

            if ($scope.selectedPlan.action === 'extend') {
                key = 'profile.subscriptions.upgrade_subscription.yearly_package_extend_title';
            } else {
                key = 'profile.subscriptions.upgrade_subscription.yearly_package_update_title';
            }

            return $filter('translate')(key, {
                from: $filter('dpickerDateFormatter')(startsFrom),
                to  : $filter('dpickerDateFormatter')(momentWrapped(activeUntil).subtract(1, 'day'))
            })

        };

        $scope.isPlanAvailable = function (plan) {
            if (!$scope.currentSubscriptionData.currentSubscription){
                return true;
            }

            if (!$scope.currentSubscriptionData.isSubscriptionCancellingAllowed){
                return true;
            }

/*            if ($scope.currentSubscriptionData.currentSubscription.isCancelled){
                return true;
            }*/

            var currentPlan = $scope.currentSubscriptionData.currentSubscription.selectedPlan;

            if (currentPlan.alias === 'starter'){
                return true;
            }

            return (plan.maxDocuments > currentPlan.maxDocuments)
        };

        $scope.getTodayDate = function () {
            return momentWrapped();
        };

        $scope.isPaymentAvailable = function () {

            if (!$scope.selectedPlan)
                return false;

            if ($scope.currentPlan)
                return $scope.currentPlan.id !== $scope.selectedPlan.id;
            else {
                return true;
            }
        };

        $scope.upgradeSubscription = function () {

            $scope.spinner.enabled = true;

            return $q
                .all([
                    Subscription.change($scope.selectedPlan)
                ])
                .then(function () {
                    Events.triggerEvent('subscription.update');
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        };

        (function () {
            $scope.spinner.enabled = true;

            Profile.getSubscriptionSettings().then(function (data) {
                if (!!(data.selectedPlan)) {
                    $scope.currentPlan                       = angular.copy(data.selectedPlan);
                    $scope.selectedPlan                      = data.selectedPlan;
                    $scope.activeTab[data.selectedPlan.type] = true;
                } else {
                    $scope.activeTab['monthly'] = true;
                }
            }).finally(function () {
                $scope.spinner.enabled = false;

            })
        })();
    });
