/**
 * Created by abichenko on 21.09.17.
 */
angular.module('sinvoice-common')
    .factory('SubscriptionCheckedButtonRenderer', function (UserSubscription, $timeout) {

        return {
            wrapClickListener: function (element, promiseCallback) {

                element.on('click', function (e, options) {

                    if (!options || !options.isCheckCompleted) {
                        e.stopImmediatePropagation();
                        e.preventDefault();
                        promiseCallback().then(function () {

                            $timeout(function () {

                                element.trigger('click', {isCheckCompleted: true});
                            })
                        })
                    }

                });
            },
            applyListener    : function (scope, element) {
                scope.$watch(function () {
                    return UserSubscription.isCheckInProgress();
                }, function (newValue) {

                    if (newValue) {

                        element.attr('disabled', 'true');
                    } else {
                        element.removeAttr('disabled')
                    }

                })
            }
        }
    })

    .directive('subscriptionEditActionButton', function (UserSubscription, SubscriptionCheckedButtonRenderer, Modal) {
        return {
            link: function (scope, element, attrs) {
                SubscriptionCheckedButtonRenderer.applyListener(scope, element);

                SubscriptionCheckedButtonRenderer.wrapClickListener(element, function () {
                    return UserSubscription.checkSubscriptionForEditAction()
                })
            }
        }
    })
    .directive('subscriptionDocumentCreateActionButton', function (UserSubscription, SubscriptionCheckedButtonRenderer, Modal) {
        return {
            link: function (scope, element, attrs) {
                SubscriptionCheckedButtonRenderer.applyListener(scope, element);

                SubscriptionCheckedButtonRenderer.wrapClickListener(element, function () {
                    return UserSubscription.checkSubscriptionForDocumentCreation()
                })
            }
        }
    })
    .directive('subscriptionCheckedButton', function (SubscriptionCheckedButtonRenderer) {

        return {
            link: function (scope, element, attrs) {
                SubscriptionCheckedButtonRenderer.applyListener(scope, element);
            }
        }

    });