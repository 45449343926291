/**
 * Created by dbichenko on 15.08.2016.
 */
angular.module('sinvoice-desktop').controller('checksController',
    function ($scope, $filter, DTOptionsBuilder, $translate, DTColumnBuilder, DataTableLanguageSettings, dpickerSettings, Document, DocumentModal, Events, DirectiveCompiler) {

        function initializeDataTableColumns() {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('document.docNumber')
                    .withTitle($translate.instant('documents.table.number'))
                    .withClass('num-link'),
                DTColumnBuilder
                    .newColumn('amountNIS')
                    .withTitle($translate.instant('documents.payment_methods.amount'))
                    .renderWith(function (data) {
                        return DirectiveCompiler.compileIntoHtml('money-amount', {
                            currency: 'NIS',
                            amount  : data
                        });
                    }),
                DTColumnBuilder
                    .newColumn('bank.bank_name')
                    .withTitle($translate.instant('documents.payment_methods.bank')),
                DTColumnBuilder
                    .newColumn('branch')
                    .withTitle($translate.instant('documents.payment_methods.branch')),

                DTColumnBuilder
                    .newColumn('account')
                    .withTitle($translate.instant('documents.payment_methods.account')),

                DTColumnBuilder
                    .newColumn('checkNumber')
                    .withTitle($translate.instant('documents.payment_methods.check.number'))

            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;
        }

        function initializeDataTable(promise) {
            return DTOptionsBuilder
                .fromFnPromise(promise)
                .withOption('sDom', "Rt")
                .withOption("oLanguage", DataTableLanguageSettings)
                .withButtons([])
                .withOption('order', [2, "asc"])
                .withOption("fnRowCallback", function (nRow, aData) {

                    $(nRow).off('click', '.num-link');
                    $(nRow).on('click', '.num-link', function (e) {
                        DocumentModal.editDocument(aData.document.id);
                        e.stopPropagation();

                    });

                    return nRow
                })
                .withOption("bootstrap", false)

        }

        function filterCheques(cheques) {

            return cheques.filter(function (cheque) {
                return momentWrapped(cheque.dueDate) > momentWrapped()
            })


        }

        (function () {


            Events.subscribeToEvent('documents.update', $scope, function () {
                $scope.dtInstance.rerender();
            });


            $scope.dtInstance = {};
            $scope.dtOptions  = initializeDataTable(function () {

                return Document.getCheques().then(function (data) {
                    return filterCheques(data);
                });
            });
            $scope.dtColumns  = initializeDataTableColumns();

        })()

    });