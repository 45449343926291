/**
 * Created by abichenko on 30.01.18.
 */
angular.module('sinvoice-desktop')
    .controller('reportHelpNotificationPopupHeaderController', function ($scope, AuthDataStorage, Report) {

        $scope.toggleDontShowPopupButton = function () {
            var showPopup = !$scope.dontShowAgain;

            Report.updateHelpPopupStatus(showPopup).then(function () {
                Auth.changeReportHelpPopupStatus(showPopup);
            });
        };


        (function () {
            var user             = AuthDataStorage.retrieveStoredUser();
            $scope.dontShowAgain = !user.isReportHelpPopupShown;

        })();

    });