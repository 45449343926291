/**
 * Created by dbichenko on 03.10.2016.
 */
angular.module('sinvoice-desktop').controller('sendReportViaEmailPopupController',
    function ($scope, Report, AuthDataStorage, Modal, $filter, NotificationSendCopyLabelHelper, Customer) {

        $scope.customers = [];

        $scope.availableCustomers = [];

        $scope.spinner = {enabled: true};

        $scope.send_report = function () {

            if ($scope.sendReportViaEmailForm.$invalid)
                return;

            var ids = $scope.reports.map(function (report) {
                return report.id;
            });

            var customerIds = $scope.customers.map(function (customer) {
                return customer.id
            });

            $scope.spinner.enabled = true;

            // TODO Change popup to single report handling
            Report
                .sendToCustomers(ids[0], customerIds, $scope.subject, $scope.message, $scope.sendCopy)
                .then(function (data) {
                    $scope.$currentModal.close();

                    if (!data.result.email.failure.length) {
                        Modal.alert(
                            $filter('translate')('reports.email_send_successful'),
                            {title: $filter('translate')('reports.email_send_successful_title')}
                        );
                    } else {
                        Modal.alert(
                            $filter('translate')('reports.email_send_failure', {
                                emailList: data.result.email.failure.join(', ')
                            }),
                            {title: $filter('translate')('reports.email_send_failure_title')}
                        );
                    }
                })
        };

        $scope.onSendCopyChange = function () {
            NotificationSendCopyLabelHelper.onChangeCallback($scope.sendCopy);
        };

        $scope.cancel = function () {
            $scope.$currentModal.close();
        };

        (function () {
            $scope.sendCopy = NotificationSendCopyLabelHelper.getDefaultValue();

            if (!angular.isDefined($scope.reports))
                $scope.reports = [];


            if ($scope.reports[0]) {

                var report   = $scope.reports[0];
                var business = AuthDataStorage.getHebrewBusiness();

                $scope.subject = $filter('translate')('reports.send_report_via_email_popup.default_subject', {
                    reportName  : $filter('translateReportType')(report.type),
                    businessName: business.name,
                    from        : $filter('dpickerDateFormatter')(momentWrapped(report.from)),
                    to          : $filter('dpickerDateFormatter')(momentWrapped(report.to)),
                });

                $scope.message = $filter('translate')('reports.send_report_via_email_popup.default_message', {
                    reportName  : $filter('translateReportType')(report.type),
                    businessName: business.name,
                    from        : $filter('dpickerDateFormatter')(momentWrapped(report.from)),
                    to          : $filter('dpickerDateFormatter')(momentWrapped(report.to)),
                });
            }

            Customer.getData()
                .then(function (data) {
                    $scope.availableCustomers = data.filter(function (customer) {
                        return !!customer.email;
                    })
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                });

        })();
    });