/**
 * Created by abichenko on 01.11.17.
 */
angular.module('sinvoice-common')

    .filter('isPaymentBased', function (Document) {
        return function (value) {
            return Document.isPaymentBased(value);
        }
    })
    .filter('isCreditDocument', function (Document) {
        return function (value) {
            return Document.isCreditDoc(value);
        }
    })
    .filter('translatePaymentType', ['$translate', function ($translate) {

        return function (value) {

            if (!value)
                return '';

            return $translate.instant('documents.payment_methods.' + value + '.title');
        }
    }])

    .filter('documentPopupTranslate', ['$translate', function ($translate) {
        function isTranslateExist(value, docType) {
            try {
                var translatedText = $translate.instant('documents.' + docType + '.' + parseValue(value));
                return !!translatedText;
            } catch (error) {
                return false;
            }
        }

        function parseValue(value) {
            var array = value.split('.');
            array.splice(0, 1);
            value = array.join('.');
            return value;
        }

        return function (value, docType, variables) {

            if (!value)
                return '';
            if (isTranslateExist(value, docType)) {
                return $translate.instant('documents.' + docType + '.' + parseValue(value), variables);
            }
            return $translate.instant(value, variables);
        }
    }])

    .filter('translateDocStatus', ['$translate', function ($translate) {

        var statusKeys = {
            '' : 'documents.table.statuses.all',
            '0': 'documents.table.statuses.open',
            '1': 'documents.table.statuses.closed'
        };

        return function (status) {
            return $translate.instant(statusKeys[status]);
        }

    }])
    .filter('translateDocType', ['$translate', function ($translate) {

        return function (value) {

            if (!value)
                return '';

            return $translate.instant('documents.types.' + value);
        }
    }]);