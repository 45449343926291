/**
 * Created by abichenko on 23.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('bankInputBlockController', function ($scope, $filter, BusinessBankAccountDataProvider, $timeout) {

        var vm = this;

        vm.getBankName = function (bank) {

            if (!bank)
                return '';

            return bank.type === 'default' ? bank.bank_name : $filter('translate')('common.bank.custom');
        };

        (function () {

            $timeout(function () {
            });

            if (!angular.isDefined(vm.bank) && vm.customer) {
                vm.bank = BusinessBankAccountDataProvider.getBank(vm.customer);
            }


            if (!angular.isDefined(vm.customBankName) && vm.customer) {
                vm.customBankName = BusinessBankAccountDataProvider.getCustomBankName(vm.customer);
            }

        })();

    });