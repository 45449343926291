/**
 * Created by dbichenko on 21.07.2016.
 */
angular.module('sinvoice-desktop').controller('taxInvoiceCreditController',
    function ($scope,
              $translate,
              Modal,
              FormHelper,
              dateHelper,
              Events,
              TemplateChecker,
              DocumentTypeConstants,
              DocumentModalCallbackContainer,
              DocumentModalCallbackProvider) {

        $scope.template.$reliableTypesOfOpenDocuments = [
            DocumentTypeConstants.TYPE_RECEIPT_CREDIT,
            DocumentTypeConstants.TYPE_RETURN_FORM,
            'invoice_balance_adjustment'
        ];

        $scope.template.$reliableTypesOfAnyStatusDocuments = [
            DocumentTypeConstants.TYPE_TAX_INVOICE
        ];

        $scope.template.$oneSideRelationDocTypes = [
            DocumentTypeConstants.TYPE_RETURN_FORM
        ];

        $scope.template.$isOccasionalDocsRelatingAllowed = true;
        $scope.template.itemsRequired                    = true;


        $scope.$watch('editedDocument.customer', function (newValue, oldValue) {

            if (newValue || oldValue == newValue)
                return false;

        });

        Events.subscribeToEvent('relatedDocs.related', $scope, function (e, documents) {

            if (!$scope.template.isEditable)
                return;

            var docNumberString = documents.map(function (document) {
                return $translate.instant('documents.credit_documents_title_document_label', {
                    'docNumber': document.docNumber
                });
            }).join(', ');

            $scope.editedDocument.title = docNumberString
                ? $translate.instant('documents.credit_documents_title_placeholder', {docNumberString: docNumberString})
                : '';
        });

        (function () {

            DocumentModalCallbackContainer.addPreSaveCallback(
                DocumentModalCallbackProvider.creditDocumentOccasionalCustomerWarning($scope.editedDocument)
            );

            DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                if (!$scope.editedDocument.relatedDocs || !$scope.editedDocument.relatedDocs.length) {
                    flow.allow();
                    return true;
                }

                var relatedDocumentTotalAmount = _.sumBy($scope.editedDocument.relatedDocs, 'displayedTotal');

                if ($scope.template.$finalItemsTotalWithVat > relatedDocumentTotalAmount) {

                    Modal
                        .confirm(
                            $translate.instant('documents.tax_invoice_credit.amount_warning'),
                            null,
                            null,
                            {title: $translate.instant('documents.tax_invoice_credit.amount_warning_title')}
                        )
                        .then(function () {
                            flow.allow();
                        });

                } else {
                    flow.allow();
                }

            });

            DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                var editedDocument  = $scope.editedDocument;
                var paragraphErrors = $scope.template.paragraphs.errors;

                paragraphErrors.required = false;

                if (!editedDocument.paragraphs.length) {
                    flow.deny();
                    paragraphErrors.required = true;
                    return false;
                }
                flow.allow();
            });


        })();


    });