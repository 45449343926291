angular.module('sinvoice-common')
    .factory('TokenStorage', function ($sessionStorage, $cookies) {


        return {
            getCurrentSessionToken  : function () {
                return $sessionStorage.get('token');
            },
            setCurrentSessionToken  : function (newToken) {
                $sessionStorage.put('token', newToken);
            },
            resetCurrentSessionToken: function () {
                $sessionStorage.remove('token');
            },
            getRememberedToken      : function () {
                return $cookies.get('token');
            },
            setRememberedToken      : function (newToken) {

                var expireDate = new Date();
                expireDate.setDate(expireDate.getDate() + 14);
                $cookies.put('token', newToken, {'expires': expireDate});
            },
            resetRememberedToken    : function () {
                $cookies.remove('token');
            }
        }

    })
    .factory('ApiInterceptor', ['$rootScope', '$q', function ($rootScope, $q) {
        return {
            responseError: function (response) {
                $rootScope.$broadcast({
                    401: 'notAuthenticated',
                    403: 'notAuthorized',
                    419: 'sessionTimeout',
                    440: 'sessionTimeout',
                    498: 'tokenExpired'
                }[response.status], response);
                return $q.reject(response);
            }
        };
    }])
    .factory("API", function ($http, $rootScope, $location, $q, $localStorage, TokenStorage) {

        function encodeUriQuery(val) {
            return encodeURIComponent(val)
                .replace(/%40/gi, "@")
                .replace(/%3A/gi, ":")
                .replace(/%24/g, "$")
                .replace(/%2C/gi, ",")
                .replace(/%3B/gi, ";")
                .replace(/%20/g, "+")
                .replace(/%7B/g, "{")
                .replace(/%7D/g, "}")
                .replace(/%5B/g, "[")
                .replace(/%5D/g, "]")
                .replace(/%22/g, '"')
                .replace(/%5C/g, "\\");
        }

        var queryUrl = function (path, requestData) {
            if (!angular.isObject(requestData)) {
                return path;
            }
            var keys       = Object.keys(requestData);
            var queryParts = [];

            for (var k = 0; k < keys.length; k++) {
                var key   = keys[k];
                var value = requestData[key];
                if (angular.isObject(value)) {
                    value = JSON.stringify(value);
                }
                queryParts.push(encodeUriQuery(key) + "=" + encodeUriQuery(value));
            }
            return path + (queryParts.length ? "?" + queryParts.join("&") : "");
        };

        var apiRequest = function (method, path, requestData) {
            var token   = TokenStorage.getCurrentSessionToken();
            var headers = {"Api-Key": token};
            var options = {method: method, url: path, headers: headers, data: requestData || {}, withCredentials: true};

            if (method == "postFile") {
                headers["Content-Type"]  = undefined;  // To ensure multipart boundary is added
                options.method           = "post";
                options.headers          = headers;
                options.transformRequest = angular.identity;
            }

            var callbacks   = {};
            var canceler    = $q.defer();
            options.timeout = canceler.promise;
            $http(options).success(function (data, status, headers, config) {
                if (callbacks.success) {
                    callbacks.success(data, status, headers, config);
                }
            }).error(function (data, status, headers, config) {
                if (callbacks.error) {
                    callbacks.error(data, status, headers, config);
                }
            });

            var methods = {
                cancel : function () {
                    canceler.resolve("Request canceled");
                },
                success: function (callback) {
                    callbacks.success = callback;
                    return methods;
                },
                error  : function (callback) {
                    callbacks.error = callback;
                    return methods;
                }
            };
            return methods;
        };

        return {
            get     : function (path, query) {
                return apiRequest("get", queryUrl(path, query));
            },
            post    : function (path, requestData) {
                return apiRequest("post", path, requestData);
            },
            postFile: function (path, requestData) {
                return apiRequest("postFile", path, requestData);
            },
            put     : function (path, requestData) {
                return apiRequest("put", path, requestData);
            },
            patch   : function (path, requestData) {
                return apiRequest("patch", path, requestData);
            },
            options : function (path, query) {
                return apiRequest("options", queryUrl(path, query));
            },
            delete  : function (path) {
                return apiRequest("delete", path, {});
            }
        };
    });