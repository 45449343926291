/**
 * Created by abichenko on 13.03.18.
 */
angular.module('sinvoice-desktop')
    .controller('editAttributeValueController',

        function ($scope, Attribute, Events, $filter, DesktopModal, EditedValueComparator, Modal) {

            var backup;

            $scope.attributeValueEditForm = {};
            $scope.spinner                = {enabled: true};

            $scope.$currentModal.onCancelCallback = function () {
                if (EditedValueComparator.isObjectChangedExceptOfProps(backup, $scope.value)) {
                    return DesktopModal.unsavedChanges();
                } else {
                    return true;
                }
            };

            $scope.save = function () {

                if ($filter('isSubmitNotAllowed')($scope.attributeValueEditForm))
                    return false;

                $scope.spinner.enabled = true;

                Attribute
                    .updateValue($scope.attributeId, $scope.customerId, $scope.value)
                    .then(function () {
                        return Modal.alert(
                            $filter('translate')('customers.edit.attributes.value_save_success_message'),
                            {title: $filter('translate')('customers.edit.attributes.value_save_success_message_title')}
                        )
                    })
                    .then(function () {
                        Events.triggerEvent('attributes.update');
                        $scope.$currentModal.close();
                    })
                    .finally(function () {
                        $scope.spinner.enabled = false;
                    })
            };

            (function () {


                Attribute
                    .getSingleValue($scope.attributeId, $scope.customerId)
                    .then(function (data) {
                        $scope.value = data;

                    })
                    .finally(function () {
                        $scope.spinner.enabled = false;
                        backup                 = angular.copy($scope.value);
                    })

            })();

        });