/**
 * Created by Администратор on 11.07.2016.
 */
angular
    .module('sinvoice-common')
    .factory('SpinnerIconRenderer', function () {
        return {
            appendIconToButton: function (element) {

                var icon = angular.element('<i>');

                icon.addClass('fa fa-spinner fa-spin');

                element.append(icon);

                return icon;

            }
        }
    })

    .directive('disabledOnInvalidButton', function ($filter, SpinnerIconRenderer) {

        return {
            link: function (scope, element, attrs) {

                if (attrs['disabledOnPendingButton']) {
                    throw Error('`disabledOnInvalidButton` directive cannot be used on same element with `disabledOnPendingButton`');
                }

                var form = scope[attrs['disabledOnInvalidButton']];
                var icon = SpinnerIconRenderer.appendIconToButton(element);

                scope.$watch(
                    function () {
                        return $filter('isSubmitNotAllowed')(form)
                    },
                    function (newVal) {

                        var isFormPending = form && form.$pending;

                        if (newVal) {
                            element.attr('disabled', '');
                            if (isFormPending) {
                                icon.show();
                            }

                        } else {

                            if (!isFormPending) {
                                icon.hide();
                            }

                            element.removeAttr('disabled');
                        }
                    })
            }
        }
    })
    .directive('disabledOnPendingButton', function (SpinnerIconRenderer) {
        return {
            link: function (scope, element, attrs) {

                var form = scope[attrs['disabledOnPendingButton']];

                var icon = SpinnerIconRenderer.appendIconToButton(element);

                scope.$watch(function () {
                    return form.$pending;
                }, function (newVal) {

                    if (newVal) {
                        icon.show();
                        element.attr('disabled', '');
                    } else {
                        element.removeAttr('disabled');
                        icon.hide();
                    }

                })

            }
        }
    })
    .directive('input', function () {

        return function (scope, element, attrs) {


            if (attrs['type'] !== 'date')
                return;

            var placeholder = attrs['placeholder'];

            function updatePlaceholder() {
                if (!element.val()) {
                    element.attr('placeholder', placeholder)
                } else {
                    element.attr('placeholder', '');
                }
            }

            updatePlaceholder();

            element
                .on('focus', function () {
                    element.attr('placeholder', '')
                })
                .on('blur', function () {
                    updatePlaceholder();
                });
        }
    })

    .directive('expiryMonth', function () {
        return {
            require: ['^^expiryDateBlock', 'ngModel'],
            link   : function (scope, element, attrs, ctrl) {
                var expiryDateBlockCtrl = ctrl [0];
                var ngModelCtrl         = ctrl [1];

                expiryDateBlockCtrl.setMonthCtrl(ngModelCtrl)
            }
        }
    })
    .directive('expiryYear', function () {
        return {
            require: ['^^expiryDateBlock', 'ngModel'],
            link   : function (scope, element, attrs, ctrl) {
                var expiryDateBlockCtrl = ctrl [0];
                var ngModelCtrl         = ctrl [1];

                expiryDateBlockCtrl.setYearCtrl(ngModelCtrl)
            }
        }
    })
    .directive('expiryDateBlock', function () {

        var yearCtrl;
        var monthCtrl;

        return {
            link      : function (scope, element, attrs) {


                scope.$watch(function () {

                    if (!(monthCtrl.$viewValue && yearCtrl.$viewValue)) {

                        yearCtrl.$setValidity('expiryDate', true);
                        monthCtrl.$setValidity('expiryDate', true);
                        return;
                    }

                    var currentDate = momentWrapped();
                    var expiryDate  = momentWrapped(monthCtrl.$viewValue + ' ' + yearCtrl.$viewValue, 'MM YY');

                    var result = currentDate.startOf('month') <= expiryDate.startOf('month');
                    yearCtrl.$setValidity('expiryDate', result);
                    monthCtrl.$setValidity('expiryDate', result);

                });


            },
            controller: function () {


                this.setYearCtrl  = function (modelCtrl) {
                    yearCtrl = modelCtrl;


                };
                this.setMonthCtrl = function (modelCtrl) {
                    monthCtrl = modelCtrl;
                }
            }
        }

    })
    .factory('asyncValidatorFactory', function ($timeout, $q) {

        return {
            createAsyncValidator: function (validateSource) {

                var pendingValidation;

                return validate;

                function doValidation(modelValue, viewValue) {
                    var def = $q.defer();

                    var promise = validateSource({value: modelValue});

                    if (promise) {
                        promise.then(function (data) {
                            if (data === true)
                                def.resolve();
                            else
                                def.reject();
                        });
                    } else {
                        def.resolve();
                    }
                    return def.promise;
                }

                function validate(modelValue, viewValue) {

                    if (pendingValidation)
                        $timeout.cancel(pendingValidation);

                    pendingValidation = $timeout(function () {
                        return doValidation(modelValue, viewValue);
                    }, 1000);

                    return pendingValidation;

                };
            }
        }

    })
    .directive('backendValidate', function (asyncValidatorFactory) {
        return {
            require: 'ngModel',
            scope  : {
                backendValidateSource: "&",
                backendValidate      : '@'
            },
            link   : function (scope, element, attrs, ngModelCtrl) {

                var errorLabel = scope['backendValidate'] || 'backend';

                ngModelCtrl.$asyncValidators[errorLabel] = asyncValidatorFactory.createAsyncValidator(scope.backendValidateSource);
            }
        }
    })

    .directive('unique', function ($parse) {
        return {
            restrict: 'A',
            require : 'ngModel',
            link    : function (scope, elem, attr, ngModel) {
                ngModel.$validators.unique = function (value) {

                    var collection = $parse(attr['unique'])(scope);

                    return !_.includes(collection, value)
                }
            }
        }
    })
    .directive('ngMin', function () {
        return {
            restrict: 'A',
            require : 'ngModel',
            link    : function (scope, elem, attr, ctrl) {
                scope.$watch(function () {
                    return attr.ngMin
                }, function () {
                    ctrl.$validate();
                });

                function minValidator(value) {

                    if (isEmpty(value))
                        return true;

                    var min = Number(attr.ngMin);


                    if (!angular.isDefined(min))
                        min = Infinity;

                    return Number(value) >= min;
                }

                ctrl.$validators.min = minValidator
            }
        };
    })

    .directive('ngMax', function () {
        return {
            restrict: 'A',
            require : 'ngModel',
            link    : function (scope, elem, attr, ctrl) {
                scope.$watch(function () {
                    return attr.ngMax
                }, function () {
                    ctrl.$validate();
                });

                function maxValidator(value) {

                    if (isEmpty(value))
                        return true;

                    var max = Number(attr.ngMax);

                    if (!angular.isDefined(max))
                        max = Infinity;

                    return Number(value) <= max;
                }

                ctrl.$validators.max = maxValidator
            }
        };

    })
    .directive('setFocus', function () {
        return {
            scope: {setFocus: '='},
            link : function (scope, element, attr) {
                if (scope.setFocus) {
                    $(element).find('input').focus();
                }
            }
        };
    })

    .factory('FormHelper', function () {
        function addValidator(property, errorName, callback) {
            property.$validators[errorName] = callback;
            property.$validate();
        }

        function getInputControllers(form) {
            var inputControllers = [];

            angular.forEach(form, function (value, key) {

                if (typeof value === 'object' && value.hasOwnProperty('$modelValue'))
                    inputControllers.push(value)
            });

            return inputControllers;
        }


        return {
            getInputControllers: getInputControllers, addValidator: addValidator,
            addValidatorOnInit : function (propertyPath, errorName, callback, scope) {

                scope.$watch(propertyPath, function (newVal, oldVal) {

                    if (newVal && !oldVal) {
                        addValidator(newVal, errorName, callback);
                    }
                })

            }
        }
    })
    .directive('uiSelectHoverTooltip', function ($compile, $timeout) {
        return {
            restrict: 'A',
            require : '^uiSelect',
            link    : function (scope, element, attr, uiSelect) {

                if (!element.hasClass('ui-select-match')) {
                    throw Error('ui-select-hover-tooltip directive should be applied to ui-select-match element');
                }


                var container              = element.parents('.ui-select-container');
                var textLabel              = element.find('.ui-select-match-text');
                var input                  = container.find('.ui-select-toggle');
                var content                = textLabel.html();
                var hoverFrame             = angular.element('<div><table class="outer"><tr><td class="inner" style="background: none!important;"></td></tr></div>');
                var hoverFrameInnerWrapper = hoverFrame.find('.inner');
                var hoverFrameOuterWrapper = hoverFrame.find('.outer');

                hoverFrame.css({
                    'position' : 'absolute',
                    'color'    : input.css('color'),
                    'top'      : '0px',
                    'min-width': '100%',
                    'height'   : '100%',
                    'cursor'   : 'pointer',
                    'z-index'  : 999
                });

                hoverFrame.hide();
                hoverFrameInnerWrapper.html(content);
                hoverFrameInnerWrapper.css({
                    'padding-top'   : input.css('padding-top'),
                    'padding-bottom': input.css('padding-bottom'),
                    'vertical-align': 'middle',
                    'text-align'    : 'right',
                    'border-radius' : input.css('border-radius'),
                    'border'        : input.css('border'),
                    'background'    : input.css('background'),

                });
                hoverFrameOuterWrapper.css({
                    'height': '100%',
                    'width' : '100%'
                });

                hoverFrame = $compile(hoverFrame)(scope);
                hoverFrame.insertAfter(element);

                hoverFrame.on('click', function () {
                    $timeout(function () {
                        uiSelect.activate();
                    });
                    hideFrame();
                });

                container
                    .on('mouseenter', function () {
                        if (isTextFitsInput())
                            return;

                        if (uiSelect.selected && !uiSelect.open)
                            showFrame();
                    })
                    .on('mouseleave', function () {
                        hideFrame();
                    });

                function isTextFitsInput() {
                    var inputWidth       = parseFloat(input.css('width'));
                    // var inputPaddingRight = parseFloat(input.css('padding-right'));
                    var inputPaddingLeft = parseFloat(input.css('padding-left'));
                    var visibleWidth     = inputWidth - inputPaddingLeft;

                    return (textLabel[0].scrollWidth <= visibleWidth)
                }

                function hideFrame() {
                    hoverFrame.hide();
                    input.parent().css({opacity: 1});
                }

                function showFrame() {
                    hoverFrame.show();
                    input.parent().css({opacity: 0});
                }
            }
        }
    })
    .directive('isolateForm', function () {
        return {
            restrict: 'A',
            require : '?form',
            link    : function (scope, elm, attrs, ctrl) {
                if (!ctrl) {
                    return;
                }

                // Do a copy of the controller
                var ctrlCopy = {};
                angular.copy(ctrl, ctrlCopy);

                // Get the parent of the form
                var parent = elm.parent().controller('form');
                // Remove parent link to the controller
                parent.$removeControl(ctrl);

                // Replace form controller with a "isolated form"
                var isolatedFormCtrl = {
                    $setValidity: function (validationToken, isValid, control) {
                        ctrlCopy.$setValidity(validationToken, isValid, control);
                        parent.$setValidity(validationToken, true, ctrl);
                    },
                    $setDirty   : function () {
                        elm.removeClass('ng-pristine').addClass('ng-dirty');
                        ctrl.$dirty    = true;
                        ctrl.$pristine = false;
                    },
                };
                angular.extend(ctrl, isolatedFormCtrl);
            }
        }
    })
    .directive('groupWithValidation', function () {
        return {
            scope     : {},
            link      : function (scope, elem, attrs) {

                scope.isValid = true;
                scope.$watch('isValid', function (value) {

                    if (!value) {
                        elem.addClass('has-error');
                    } else {
                        elem.removeClass('has-error');
                    }

                })

            },
            controller: function ($scope) {

                this.isValid = function () {
                    return !!$scope.isValid;
                };

                this.setValid = function (value) {
                    $scope.isValid = value;
                }

            }
        }
    })
    .directive('messagesForGroupWithValidation', function () {
        return {
            require: '^^groupWithValidation',
            link   : function (scope, elem, attrs, groupController) {

                scope.$watch(function () {
                    return groupController.isValid();

                }, function (value) {

                    if (value)
                        elem.hide();
                    else
                        elem.show();

                })
            }
        }
    })

    .directive('inputWithRounding', function ($filter) {

        return {
            require: 'ngModel',
            link   : function (scope, element, attr, ngModelCtrl) {

                ngModelCtrl.$formatters.push(function (value) {
                    if (angular.isDefined(attr['showEmptyValue']) && !value) {
                        return '';
                    } else if (!value) {
                        value = 0;
                    }

                    var rounded = Number(Math.round(value + 'e' + 2) + 'e-' + 2);

                    return rounded.toFixed(2);
                });


            }
        }
    })
    .directive('inputWithValidation', function ($filter) {
        return {
            require: ['ngModel', '^^form', '?^^groupWithValidation'],
            link   : function (scope, elem, attrs, controllers) {

                var form;
                var model = controllers[0];
                var group = controllers[2];

                if (attrs['parentForm']) {
                    form = scope[attrs['parentForm']];
                } else {
                    form = controllers[1];
                }

                scope.$watch(function () {
                    return $filter('isInvalid')(model, form)
                }, function (newVal) {


                    if (group) {
                        group.setValid(!newVal);
                    } else {
                        if (newVal) {
                            elem.addClass('has-error');
                        } else {
                            elem.removeClass('has-error');
                        }
                    }

                })

            }
        }
    })

    .directive('form', scrollToError)
    .directive('ngForm', scrollToError)


    .filter('isSubmitNotAllowed', function () {
        return function (form) {
            return !!(form.$invalid || form.$pending);
        }
    })
    .filter('isInvalid', function () {
        return function (value, form) {

            if (!value)
                return false;

            if (!form)
                form = value.$$parentForm;

            return value.$invalid && (value.$dirty || form.$submitted);
        }

    });


function scrollToError($location, $anchorScroll) {
    return {
        require: 'form',
        link   : function (scope, element, attr, formCtrl) {


            $(element).on('submit', function () {

                var errorCtrl = formCtrl;

                var firstInvalid = null;

                $('#has-error').each(function (i, el) {
                    $(this).attr('id', '');
                });
                var invalidInputs = [];
                for (var err in formCtrl.$error) {
                    for (var i = 0; i < formCtrl.$error[err].length; i++) {

                        var errorProperty = formCtrl.$error[err][i];

                        if (errorProperty) {

                            var input = $('[name="' + errorProperty.$name + '"]');
                            if (input[0] && input.is(':visible'))
                                invalidInputs.push(input);
                        }
                    }
                }

                firstInvalid = invalidInputs[0];
                if (invalidInputs[0]) {
                    var id = firstInvalid.attr('id');
                    if (!id) {
                        firstInvalid.attr('id', 'has-error')
                    }

                    $location.hash(id);
                    $anchorScroll();
                    $location.hash(null);
                    firstInvalid.focus();
                } else {

                    firstInvalid = $('.has-error:visible');
                    if (firstInvalid[0]) {
                        if (!firstInvalid.attr('id')) {
                            firstInvalid.attr('id', 'has-error')
                        }
                        $location.hash(firstInvalid.attr('id'));
                        $anchorScroll();
                        $location.hash(null);
                    }
                }
            })
        }
    };
}
