/**
 * Created by abichenko on 08.12.17.
 */
angular.module('sinvoice-common')
    .filter('highlightSearchQuery', function () {
        function escapeRegexp(queryToEscape) {
            return ('' + queryToEscape).replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1');
        }

        return function (matchItem, query) {
            return query && matchItem ? ('' + matchItem).replace(new RegExp(escapeRegexp(query), 'gi'), '<b class="overlap">$&</b>') : matchItem;
        };
    });