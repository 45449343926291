/**
 * Created by dbichenko on 03.11.2016.
 */
angular.module('sinvoice-desktop')

    .factory('dpickerRenderer', function ($rootScope, dpickerSettings, dateHelper) {

        function render(element, minDate, maxDate, showToday, inline, withClock, ignoreDateRange) {

            var dateFormat;
            var options = {
                locale        : $rootScope.langDate,
                format        : '',
                ignoreReadonly: true,
            };

            if (angular.isDefined(minDate) && !ignoreDateRange)
                options.minDate = momentWrapped(minDate).startOf('day');

            if (angular.isDefined(maxDate) && !ignoreDateRange)
                options.maxDate = momentWrapped(maxDate).startOf('day');

            if (angular.isDefined(inline))
                options.inline = !!inline;

            if (angular.isDefined(showToday))
                options.showTodayButton = !!showToday;


            if (withClock) {
                dateFormat = dpickerSettings.timeFormat;
            } else {
                dateFormat = dpickerSettings.dateFormat;
            }

            if ($rootScope.langDate == "he") {
                options.icons = {
                    next    : 'glyphicon glyphicon-chevron-left',
                    previous: 'glyphicon glyphicon-chevron-right'
                };
            }

            options.format = dateFormat;
            var dtp        = $(element).datetimepicker(options);

            dtp.on('dp.change', function (e) {

                if (!e.date)
                    return null;

                e.date = momentWrapped(e.date).startOf('day');
            });

            $(element)
                .attr('readonly', '');
            $(element)
                .addClass('datepicker-input');

            return dtp;

        }

        return {render: render}
    })
    .directive('dpicker', function (dpickerRenderer, dateHelper) {
        return {
            restrict: "A",
            require : "ngModel",
            scope   : {
                onChange: '&?',
                ngModel : '=?',
                minDate : '=?',
                maxDate : '=?'
            },
            link    : function (scope, element, attrs, ngModelCtrl) {

                if (!angular.isDefined(attrs.useCurrent))
                    attrs.useCurrent = true;

                var dtp = dpickerRenderer.render(
                    element,
                    scope.minDate,
                    scope.maxDate,
                    !attrs.inline,
                    attrs.inline,
                    attrs.onclock,
                    attrs.useCurrent,
                    attrs.ignoreDateRange
                );

                var options = dtp.data("DateTimePicker").options();
                var format  = options.format;


                ngModelCtrl.$formatters.push(function (value) {

                    if (!value)
                        return null;

                    if (!attrs.onclock) {
                        return momentWrapped(value).startOf('day').format(format);
                    }

                    return momentWrapped(value).format(format);
                });

                ngModelCtrl.$validators.minDate = function (value) {
                    if (!value || !angular.isDefined(scope.minDate))
                        return true;

                    var currDate = momentWrapped(value).startOf('day');
                    var minDate  = momentWrapped(scope.minDate).startOf('day');

                    return currDate >= minDate;
                };

                ngModelCtrl.$validators.maxDate = function (value) {
                    if (!value || !angular.isDefined(scope.maxDate))
                        return true;

                    var currDate = momentWrapped(value).startOf('day');
                    var maxDate  = momentWrapped(scope.maxDate).startOf('day');

                    return currDate <= maxDate;
                };


                if (scope.ngModel) {
                    dtp.data("DateTimePicker").date(scope.ngModel);
                } else if (!angular.isDefined(scope.ngModel) && attrs.useCurrent) {

                    scope.ngModel = attrs.onclock
                        ? momentWrapped()
                        : momentWrapped().startOf('day');
                    ngModelCtrl.$render();

                }

                dtp.on("dp.change", function (e) {
                    scope.$apply(function () {
                        ngModelCtrl.$setDirty();
                        scope.ngModel = e.date;
                    });
                });
            }
        };
    });