angular.module('sinvoice-desktop').controller('inviteManagedCustomerPopupController',
    function ($scope,
              Modal,
              $translate,
              DTOptionsBuilder,
              DTColumnBuilder,
              Customer,
              $compile,
              $q,
              $filter,
              ManagedCustomers,
              Referral,
              DataTableLanguageSettings,
              DataTableFunctions,
              Events,
              ManagedCustomersModal,
              NotificationModalHelper,
              NotificationModal,
              DataTableBootstrapSettings) {

        $scope.removeInvite   = removeInvite;
        $scope.addEmptyInvite = addEmptyInvite;
        $scope.showTable      = showTable;

        $scope.isTableShown               = false;
        $scope.forms                      = [];
        $scope.invitableCustomers         = [];
        $scope.spinner.addManagedCustomer = true;


        $scope.sendInvitation = function () {

            if ($filter('isSubmitNotAllowed')($scope.sendManagedCustomerInvitationForm))
                return false;


            $scope.spinner.addManagedCustomer = true;
            if ($scope.isResend) {

                var ids = _.map($scope.invites, 'id');

                ManagedCustomers
                    .resendInvitations(ids, $scope.message)
                    .then(function (data) {

                        var failureEmails = data.result.email.failure;
                        var allEmails     = failureEmails.concat(data.result.email.success);
                        var failurePhones = data.result.phone.failure;
                        var allPhones     = failurePhones.concat(data.result.phone.success);

                        var getNames                  = NotificationModalHelper.getNamesForRecipients;
                        var failedEmailRecipientNames = getNames($scope.invites, failureEmails, 'email', ['firstName', 'lastName']);
                        var emailRecipientNames       = getNames($scope.invites, allEmails, 'email', ['firstName', 'lastName']);
                        var failedPhoneRecipientNames = getNames($scope.invites, failurePhones, 'phone', ['firstName', 'lastName']);
                        var phoneRecipientNames       = getNames($scope.invites, allPhones, 'phone', ['firstName', 'lastName']);

                        NotificationModal.displaySendResultPopup(
                            failedEmailRecipientNames,
                            failedPhoneRecipientNames,
                            emailRecipientNames,
                            phoneRecipientNames
                        );
                        $scope.$currentModal.close();
                    })
                    .finally(function () {
                        $scope.spinner.addManagedCustomer = false;
                    })

            } else {

                ManagedCustomers
                    .invite($scope.invites, $scope.message, $scope.resend)
                    .then(function (data) {
                        Events.triggerEvent('invites.update');
                        var failureEmails = data.result.email.failure;
                        var allEmails     = failureEmails.concat(data.result.email.success);
                        var failurePhones = data.result.phone.failure;
                        var allPhones     = failurePhones.concat(data.result.phone.success);

                        var getNames                  = NotificationModalHelper.getNamesForRecipients;
                        var failedEmailRecipientNames = getNames($scope.invites, failureEmails, 'email', ['firstName', 'lastName']);
                        var emailRecipientNames       = getNames($scope.invites, allEmails, 'email', ['firstName', 'lastName']);
                        var failedPhoneRecipientNames = getNames($scope.invites, failurePhones, 'phone', ['firstName', 'lastName']);
                        var phoneRecipientNames       = getNames($scope.invites, allPhones, 'phone', ['firstName', 'lastName']);

                        NotificationModal.displaySendResultPopup(
                            failedEmailRecipientNames,
                            failedPhoneRecipientNames,
                            emailRecipientNames,
                            phoneRecipientNames
                        );

                        $scope.$currentModal.close();
                    })
                    .finally(function () {
                        $scope.spinner.addManagedCustomer = false;
                    })

            }
        };

        function addEmptyInvite() {
            var length   = $scope.invites.length;
            var customer = ManagedCustomers.createEmptyCustomer();
            $scope.invites.push(customer);


        }

        function removeInvite(index) {
            $scope.invites.splice(index, 1);
        }


        function showTable(e) {

            e.preventDefault();
            $scope.isTableShown = !$scope.isTableShown;
        }


        $scope.addRegisteredUser = function (data) {
            $scope.invites.push(data)
        };


        function select(source) {

            var normalizedData = ManagedCustomersModal.getNormalizedData(source);
            $scope.addRegisteredUser(normalizedData);
        }


        function reloadTableContent() {

            if (!$scope.dt.instance.reloadData)
                return;

            $scope.dt.instance.reloadData(null, false);
        }

        function getFilteredData() {

            var defer            = $q.defer();
            var customerData     = angular.copy($scope.invitableCustomers);
            var distributorsData = angular.copy($scope.invitableDistributors);

            customerData.forEach(function (value) {
                value.type = 'customer';
            });

            distributorsData.forEach(function (value) {
                value.type = 'distributor';
            });


            var data = customerData.concat(distributorsData);

            if ($scope.invites.length) {

                data = _.differenceWith(data, $scope.invites, function (tableElement, invite) {
                    return invite.source
                        && invite.source.id == tableElement.id
                        && invite.source.type == tableElement.type;
                });

            }

            defer.resolve(data);
            return defer.promise;
        }

        function initializeDataTableColumns() {


            var dtColumns = [

                DTColumnBuilder
                    .newColumn('select')
                    .withTitle('<dt-select-all-checkbox/>')
                    .withClass('select-checkbox')
                    .withOption('width', '50px')
                    .notSortable()
                    .renderWith(function (data) {
                        return '<dt-select-checkbox class="check-area"></dt-select-checkbox>';
                    }),

                DTColumnBuilder
                    .newColumn('email')
                    .withOption('width', '200px')
                    .withTitle($translate.instant('customers.managed_customer.email')),

                DTColumnBuilder
                    .newColumn('name')
                    .withOption('width', '200px')
                    .withTitle($translate.instant('customers.managed_customer.first_name')),

                DTColumnBuilder
                    .newColumn('phone')
                    .withOption('width', '200px')
                    .withTitle($translate.instant('customers.managed_customer.phone')),

                DTColumnBuilder
                    .newColumn('type')
                    .notSortable()
                    .withTitle($translate.instant('customers.managed_customer.distributors'))
                    .renderWith(function (data) {

                        if (data == 'distributor')
                            return 'Active<div class="green_check_mark"></div>';
                        else
                            return '';
                    }),

                DTColumnBuilder
                    .newColumn('add')
                    .withOption('width', '80px')
                    .notSortable()
                    .withTitle($translate.instant('customers.managed_customer.add'))
                    .renderWith(function (data) {
                        return '<button type="button" class="btn btn-success btn-xs btn-plus select-customer"><i class="fa fa-plus"></i></button>';
                    })


            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;

        }

        function initializeDataTable() {
            return DTOptionsBuilder

                .fromFnPromise(function () {
                    return getFilteredData();
                })
                .withBootstrap()
                .withOption('sDom', "<'row'<'col-xs-12 text-left mb20'B>>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('oLanguage', DataTableLanguageSettings)
                .withOption('autoWidth', false)
                .withColumnFilter({
                    aoColumns: [
                        null,
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        null,
                        null
                    ]
                })
                .withBootstrapOptions(DataTableBootstrapSettings)

                .withButtons([
                    {
                        extend: 'selected',
                        text  : $filter('translate')('customers.managed_customer.add_managed_customer_poppup.add_marked_button'),
                        action: function (e, dt, node, config) {

                            $scope.$apply(function () {

                                DataTableFunctions.getSelectedData(dt).forEach(function (data) {
                                    select(data);
                                });

                            })
                        }
                    }
                ])
                .withOption('fnCreatedRow', rowCallback);

        }

        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

            $compile(nRow)($scope);

            /*if (aData.status != 1) {
             $(nRow).addClass('disabled');
             }*/

            $(nRow).on('click', '.select-customer', function () {
                $scope.$apply(function () {
                    select(aData);
                })
            });
        }


        function loadTable() {

            $scope.dt = {};

            var customerPromise = ManagedCustomers.getInvitable().then(function (data) {
                $scope.invitableCustomers = data;
            });

            var distributorsPromise = Referral.getNonManaged().then(function (data) {
                $scope.invitableDistributors = data;
            });


            return $q.all([
                customerPromise,
                distributorsPromise
            ]).then(function () {
                $scope.dt.instance = {};
                $scope.dt.options  = initializeDataTable();
                $scope.dt.columns  = initializeDataTableColumns();
            })


        }


        function setReadOnly() {
            $scope.isEditable = !$scope.isResend;
        }

        (function () {

            setReadOnly();


            if (!$scope.message) {
                $scope.message = ($translate.instant('common.add_managed_customer_poppup.message_block_text'));
            }


            if (!$scope.invites) {
                $scope.invites = [];
                $scope.addEmptyInvite();
            }


            $scope.resendOptions = [
                'no_reminder',
                'one_week',
                'two_week',
                'month'
            ];
            $scope.resend        = $scope.resendOptions[0];

            loadTable().then(function () {
                $scope.spinner.addManagedCustomer = false;

                $scope.$watchCollection('invites', function (newval, oldval) {
                    reloadTableContent();
                });

            })


        })()


    });