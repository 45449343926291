angular.module('sinvoice-desktop').controller('topnavController',

    function ($scope,
              $rootScope,
              Customer,
              API,
              $window,
              $translate,
              $localStorage,
              Url,
              Modal,
              $cookieStore,
              Events,
              DISABLE_DEBUG_MODE,
              TopnavContainer,
              TopnavTextContainer,
              Auth) {


        $scope.additionalNavbars  = TopnavContainer.getElements();
        $scope.haveSubmenu        = false;
        $scope.haveMainMenu       = false;
        $scope.haveThreeLevelMenu = false;
        $scope.mainMenu           = [];
        $scope.subMenu            = [];
        $scope.twoLevelMenu       = [];
        $scope.threeLevelMenu     = [];

        $scope.mainmenus       = [];
        $scope.submenus        = [];
        $scope.threelevelmenus = [];

        $scope.currMenu         = $localStorage.getItem('currNav').currState;
        $scope.subpage          = $localStorage.getItem('currNav').subpage;
        $scope.subsubmenu       = $localStorage.getItem('currNav').subsubmenu;
        $scope.userIsAuthorized = Auth.registrationIsComplete();
        $scope.mainMenu         = [
            {
                name: 'topnav.control',
                menu: 'control',
                url : 'control.dashboard'
            },
            {
                name: 'topnav.customers',
                menu: 'customers',
                url : 'customers.my'
            },
            {
                name: 'topnav.documents',
                url : 'documents'
            },
            {
                name: 'topnav.items',
                url : 'items'
            },
            {
                name: 'topnav.reports',
                url : 'reports.index',
                menu: 'reports'
            },
            {
                name: 'topnav.distributors',
                menu: 'distributors',
                url : 'distributors.dashboard'
            },
            {
                name: 'topnav.newsletters',
                url : 'newsletters'
            }
        ];

        $scope.show_it = true;
        if($scope.currentUser !== undefined && $scope.currentUser !== null){
            $scope.show_it = (($scope.currentUser.user_type === 'accountant'))
        }

        $scope.subMenu = [
            {
                name   : 'topnav.myCustomers',
                menu   : 'customers',
                submenu: null,
                url    : 'customers.my',
                show_it: true
            },
            {
                name   : 'topnav.manegeCustomersMain',
                menu   : 'customers',
                submenu: null,
                url    : 'customers.representatives',
                show_it: $scope.show_it
            },
            {
                name   : 'topnav.manegeCustomersInvited',
                menu   : 'customers',
                submenu: null,
                url    : 'customers.invited',
                show_it: $scope.show_it
            },
            {
                name   : 'topnav.dashboardPanel',
                menu   : 'control',
                submenu: null,
                url    : 'control.dashboard',
                show_it: true
            },
            {
                name   : 'topnav.distributors',
                menu   : 'distributors',
                submenu: null,
                url    : 'distributors.dashboard',
                show_it: true
            },
            {
                name   : 'topnav.commissions',
                menu   : 'distributors',
                submenu: null,
                url    : 'distributors.commissions',
                show_it: true
            },
            {
                name   : 'topnav.distributorSettings',
                menu   : 'distributors',
                submenu: null,
                url    : 'distributors.distributorSettings',
                show_it: true
            },
            //Hide this route/link according of SUP-2185
/*            {
                name   : 'topnav.backup',
                menu   : 'control',
                submenu: null,
                url    : 'control.backup',
                show_it: true
            },*/
            {
                name   : 'topnav.export',
                menu   : 'control',
                submenu: null,
                url    : 'control.export',
                show_it: true
            }
        ];

        $scope.threeLevelMenu = [];

        $scope.mainmenus       = [
            'documents',
            'reports.index',
            'reports.accounting',
            'reports.incomeanddebts',
            'reports.vatincome',
            'reports.incomeitems',
            'reports.cheques',
            'reports.customersbook',
            'reports.commissions',
            'reports.ordersbook',
            'items',
            'newsletters',
            'distributors.dashboard',
            'help.search',
            'help.category',
            'help.details',
            'help.contactus',
            'help',
            'control.panel',
            'control.export',
            'control.dashboard',
            'control.backup',
            'distributors.commissions',
            'distributors.distributorSettings',
            'customers.my',
            'customers.representatives',
            'profile.edit',
            'profile.business-definition',
            'profile.subscription',
            'profile.business-info',
            'profile.change-password',
            'profile.mail',
            'profile.book-management',
            'profile.print-settings',
            'profile.export',
            'profile.my-docs',
            'profile.templates',
            'customers.main',
            'customers.invited',
            'customers.inside',
            'appsearch'
        ];
        $scope.submenus        = [
            'distributors.dashboard',
            'control.panel',
            'control.export',
            'control.backup',
            'distributors.commissions',
            'distributors.distributorSettings',
            'control.dashboard',
            'customers.my',
            'customers.representatives',
            'customers.main',
            'customers.invited',
            'customers.inside'];
        $scope.threelevelmenus = [];

        $scope.onStateChange = function () {
            $scope.currMenu   = $localStorage.getItem('currNav').currState;
            $scope.subpage    = $localStorage.getItem('currNav').subpage;
            $scope.subsubmenu = $localStorage.getItem('currNav').subsubmenu;
            $scope.setMenus();
            $scope.userIsAuthorized = Auth.registrationIsComplete();
        };

        $scope.$on('$destroy', function () {
            angular.forEach($rootScope.$$listeners['state-changed'], function (ls, k) {
                if (ls == $scope.onStateChange) {
                    $rootScope.$$listeners['state-changed'].splice(k, 1);
                }
            });
        });

        Events.subscribeToEvent('$stateChangeSuccess', $scope, $scope.onStateChange);

        $scope.parentcities = $scope.$parent.cities;
        $scope.setMenus     = function () {
            $scope.haveSubmenu        = false;
            $scope.haveMainMenu       = false;
            $scope.haveThreeLevelMenu = false;
            if ($scope.threelevelmenus.indexOf($scope.currMenu) > -1) {
                $scope.haveThreeLevelMenu = true;
            }
            if ($scope.submenus.indexOf($scope.currMenu) > -1) {
                $scope.haveSubmenu = true;
            }
            if ($scope.mainmenus.indexOf($scope.currMenu) > -1) {
                $scope.haveMainMenu = true;
            }
        };


        $scope.changeLang = function (lang) {
            if (lang == 'he') {
                $rootScope.langDate = "he";
            }
            else {
                $rootScope.langDate = "en-gb";
            }
            $translate.use(lang);
        };


        $scope.setZindex = function (e, z) {
            $('.navbar-search').css('z-index', z);
        };

        $scope.exportTable = function ($event) {

            $event.preventDefault();
            Modal.customDialog({
                templateUrl: './desktop/components/control/export-popup.html',
                headerUrl  : './desktop/components/control/export-popup-header.html'
            })
        };


        $scope.logout = function () {
            Auth.logout();
        };

        $scope.setMenus();

        $scope.isLanguageSwitcherShown = function () {
            return !DISABLE_DEBUG_MODE;
        }

        $scope.getTopText = function () {
            return TopnavTextContainer.getText();
        }
    });