/**
 * Created by dbichenko on 31.08.2016.
 */
angular.module('sinvoice-desktop').directive('selectDateRange', function () {


    return {
        scope      : {
            'from'    : '=',
            'to'      : '=',
            'onChange': '&?'
        },
        templateUrl: './desktop/components/customers/representatives/select-date-range-popup.html',
        transclude : 'element',
        replace    : true,

        link      : function (scope, element, attrs) {
           
            var dpTo   = $('.dp-to');
            var dpFrom = $('.dp-from');

            dpFrom.on("dp.change", function (e) {
                dpTo.data("DateTimePicker").minDate(e.date);
                scope.onChange();
            });
            dpTo.on("dp.change", function (e) {
                dpFrom.data("DateTimePicker").maxDate(e.date);
                scope.onChange();
            });

            scope.$watch('opened', function (newVal) {

                var dropdown = $(element).find('.dropdown-menu');
                if (newVal) {
                    dropdown.show();
                } else {
                    dropdown.hide()
                }
            });

        },
        controller: function ($scope, $timeout) {




            $scope.open  = function () {
                $scope.opened = true;
            };
            $scope.close = function () {
                $scope.opened = false;
            };

        },
    }

});