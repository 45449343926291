/**
 * Created by abichenko on 30.08.17.
 */
angular.module('sinvoice-desktop')
    .filter('translateExternalProgramLabel', function ($filter) {

        return function (value) {

            if (!value)
                return '';

            return $filter('translate')('profile.mailSettings.send_report.external_programs.' + value)
        }
    })
    .filter('translateWizcountTransactionName', function ($filter) {

        return function (value) {

            if (!value)
                return '';

            return $filter('translate')('profile.export_settings.wizcount_settings.transaction_types.' + value);

        }

    });