/**
 * Created by abichenko on 20.11.17.
 */
angular.module('sinvoice-mobile').directive('generalBlock', function () {


    return {
        scope           : {
            commentModel     : "=",
            currencyModel    : "=",
            languageModel    : "=",
            nisCourse        : "=",
            internalComment  : '=',
            internalCommentEn: '='
        },
        controllerAs    : 'vm',
        bindToController: true,
        controller      : 'generalBlockController',
        templateUrl     : './mobile/components/new-document/partials/general-block.html',
        link            : function (scope, element, attrs) {

        }
    }
})