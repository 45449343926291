/**
 * Created by abichenko on 10.11.17.
 */
angular.module('sinvoice-mobile')
    .directive('actionButton', function () {
        return {
            scope     : {
                'icon' : '@',
                'label': '@'
            },
            transclude: true,
            template  : '' +
            '<div>' +
            '   <span class="ico-holder">' +
            '      <span style="display: table; width: 100%">' +
            '           <span style="display: table-cell; vertical-align: middle">' +
            '               <img ng-src="{{icon}}">' +
            '           </span>' +
            '       </span>' +
            '   </span>' +
            '   <span>{{ label }}</span>' +
            '</div>',
            link      : function (scope, element, attr) {

            }
        }

    })
    .directive('smsLink', function () {

        return {
            restrict  : 'E',
            transclude: true,
            template  : '<a class="link-element" ng-transclude="" target="_blank"></a>',
            link      : function (scope, element, attrs) {

                if (!attrs['href']) {
                    throw new Error('SMS link directive should have href parameter');
                }

                var href;
                if ((navigator.platform.indexOf("iPhone") !== -1)
                    || (navigator.platform.indexOf("iPod") !== -1)
                    || (navigator.platform.indexOf("iPad") !== -1)) {
                    href = "sms://" + attrs['href'];
                }
                else {
                    href = "sms:" + attrs['href'];
                }
                element.find('.link-element').attr('href', href);


            }
        }
    })
    .directive('navigateLink', function () {

        return {
            restrict  : 'E',
            transclude: true,
            template  : '<a class="link-element" ng-transclude="" target="_blank"></a>',
            link      : function (scope, element, attrs) {

                if (!attrs['href']) {
                    throw new Error('Navigate link directive should have href parameter');
                }

                var href;

                // If it's an iPhone..
                if ((navigator.platform.indexOf("iPhone") !== -1)
                    || (navigator.platform.indexOf("iPod") !== -1)
                    || (navigator.platform.indexOf("iPad") !== -1)) {
                    href = "maps://maps.google.com/maps?daddr=" + attrs['href'];
                }
                else {
                    href = "http://maps.google.com/maps?daddr=" + attrs['href'];
                }


                element.find('.link-element').attr('href', href);


            }
        }
    });
