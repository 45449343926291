/**
 * Created by dbichenko on 06.02.2017.
 */
angular.module('sinvoice-desktop').factory('ReportsFilterMapper', function () {


    return {
        getMappedValues: function (stateParam, propertyToMap, availableValues) {

            if (stateParam) {
                var array = stateParam.split(',');
                return _.filter(availableValues, function (value) {
                    return _.includes(array, value[propertyToMap].toString());
                });

            } else {
                return angular.copy(availableValues);
            }
        },
        getMappedDate  : function (from, to, availableValues) {

            if (from && to) {
                return {
                    dateExample: _.find(availableValues, function (element) {
                        return element.id == 'custom';
                    }),
                    from       : from,
                    to         : to
                }
            } else {
                return {
                    dateExample: availableValues[0],
                    from       : availableValues[0].from,
                    to         : availableValues[0].to
                }
            }

        }
    }

});