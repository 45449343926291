/**
 * Created by abichenko on 17.11.17.
 */
angular.module('sinvoice-common').factory('DocumentCustomerProvider', function (CustomerTypeChecker) {


    return {
        filterOccasionalCustomer : function (existingCustomers) {

            var occasionalCustomer = null;

            existingCustomers.forEach(function (customer) {
                if (CustomerTypeChecker.isOccasional(customer.customerType)) {
                    occasionalCustomer = customer;
                }
            });

            return occasionalCustomer;
        },
        filterWithDocumentSupport: function (existingCustomers) {

            return existingCustomers.filter(function (customer) {
                return customer.customerType.isDocumentEditingAvailable && !CustomerTypeChecker.isOccasional(customer.customerType)
            })
        }
    }


})