/**
 * Created by dbichenko on 03.10.2016.
 */
angular.module('sinvoice-desktop')
    .factory('ManagedCustomersTranslator', function ($translate, ManagedCustomerConstants) {

        return {
            getTranslatedStatus: function (statusId) {
                if (statusId === ManagedCustomerConstants.STATUS_INVITED) {
                    return $translate.instant('customers.managed_customer.statuses.invited');
                } else if (statusId === ManagedCustomerConstants.STATUS_DECLINED) {
                    return $translate.instant('customers.managed_customer.statuses.declined');
                } else {
                    return '';
                }

            }
        }
    });