/**
 * Created by dbichenko on 17.01.2017.
 */
angular.module('sinvoice-desktop').controller('reportsSelectCustomerBlockController', function ($scope, Report,Events) {

    $scope.reportsGlobalData = {};
    $scope.spinner           = {enabled: true};


    $scope.$watch('reportsGlobalData.currentCustomer', function (newVal, oldVal) {
        if (newVal) {
            Events.triggerEvent('reports.changeCustomer', newVal, oldVal);
        }
    });


    function loadCustomers() {
        Report.getCustomerBookFilterValues().then(function (customers) {
            $scope.customers       = customers;
            $scope.spinner.enabled = false;
        })
    }

    (function () {
        loadCustomers();
    })();


});