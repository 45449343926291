/**
 * Created by dbichenko on 12.09.2016.
 */
angular.module('sinvoice-desktop').controller('managedCustomerInviteFormController', function ($scope, ManagedCustomers, promiseHelper, $filter) {

    $scope.managedCustomerInviteForm = {};

    (function () {

        initializeValidators();

        $scope.tools = [
            'email','sms'
        ];
        if (!$scope.invite.tool) {
            $scope.invite.tool = $scope.tools[0];
        }


    })();


    function initializeValidators() {

        $scope.validators = {
            uniqueEmail: function (value) {

                if (!value || $scope.isResend) {
                    return promiseHelper.resolvedPromise(true);
                }

                return ManagedCustomers.checkIfExist('email', value).then(function (data) {
                    return !data.result
                });
            },
            uniquePhone: function (value) {

                if (!value || $scope.isResend) {
                    return promiseHelper.resolvedPromise(true);
                }

                return ManagedCustomers.checkIfExist('phone', value).then(function (data) {
                    return !data.result
                });
            }
        }
    }

});