/**
 * Created by dbichenko on 03.10.2016.
 */
angular.module('sinvoice-desktop').service('CommunicationModal', function (Communication, $filter, Modal) {


    this.newCommunication  = newCommunication;
    this.editCommunication = editCommunication;

    return this;
    function displayCommunicationPopup(communication) {

        Modal.customDialog({
            title         : $filter('translate')('customers.edit.communications.new.title'),
            templateUrl   : './desktop/components/customers/edit-communication.html',
            scopeVariables: {
                communication: communication
            },
            size          : "large",
            className     : "new-communication-modal"
        });
    }

    function editCommunication(id) {

        Communication.get(id).then(function (data) {
            displayCommunicationPopup(data);
        });

    };

    function newCommunication() {
        displayCommunicationPopup();
    }


});