/**
 * Created by ikorol on 20.10.2017.
 */
angular.module('sinvoice-mobile').controller('MobileLoginController', function ($rootScope, $scope, Login, Auth, $filter, $timeout) {


    $scope.spinner   = {enabled: false};
    $scope.loginForm = {};
    $scope.username  = '';
    $scope.password  = '';

    $scope.doLogin = function () {

        $scope.loginForm.password.$setValidity('invalid', true);
        $scope.loginForm.$setValidity('subscription', true);

        if ($filter('isSubmitNotAllowed')($scope.loginForm)) {
            return
        }

        $scope.spinner.enabled = true;

        Login
            .performLogin($scope.username, $scope.password)
            .then(function (data) {
                if (data.success && data.api_key) {
                    $scope.isPreloader = true;
                    $timeout(function () {
                        Auth.login(data, $scope.rememberMe);
                    }, 1500);
                } else {

                    if (data.isLocked) {
                        $scope.loginForm.$setValidity('subscription', false);
                    } else {
                        $scope.loginForm.password.$setValidity('invalid', false);
                    }
                }
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            });
    };

})