/**
 * Created by abichenko on 27.12.17.
 */
angular.module('sinvoice-mobile')
    .controller('branchInputBlockController',
        function ($scope, BusinessBankAccountDataProvider) {

            var vm = this;

            (function () {
                if (!angular.isDefined(vm.branch) && vm.customer) {
                    vm.branch = BusinessBankAccountDataProvider.getBranch(vm.customer);
                }
            })();

        });