/**
 * Created by abichenko on 24.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('paypalBlockController', function ($scope, Currency) {

        $scope.calculateAmount = function () {


            var from  = $scope.isNisPayments ? 'NIS' : $scope.currency.name;
            var to    = $scope.payment.currency.name;
            var total = $scope.payment.total;


            if (!from || !to || !total)
                return;

            $scope.payment.amount = Currency.convertAmount(total, from, to, getCustomCourse(from, to));
        };


        $scope.calculateTotal = calculateTotal;


        $scope.$watch('isNisPayments', refreshValues);
        $scope.$watch('payment.currency', refreshValues);
        $scope.$watch('nisCourse', refreshValues);
        $scope.$watch('payment.amount', calculateSummary);


        function getCustomCourse(from, to) {

            if ((from === 'NIS') && ($scope.currency.name === to))
                return $scope.nisCourse;

            if ((to === 'NIS') && ($scope.currency.name) === from)
                return $scope.nisCourse;

            return $scope.payment.currency.nisCourse;
        }


        function calculateSummary() {
            $scope.payment.$summary = $scope.payment.total;
        }


        function calculateTotal() {

            var from   = $scope.payment.currency.name;
            var to     = $scope.isNisPayments ? 'NIS' : $scope.currency.name;
            var amount = $scope.payment.amount;


            if (!from || !to || !amount)
                return;

            $scope.payment.total = Currency.convertAmount(amount, from, to, getCustomCourse(from, to));
        }

        function refreshValues() {
            calculateTotal();
            calculateSummary();
        }

        (function () {

            if (!angular.isDefined($scope.payment.currency)) {
                $scope.payment.currency = $scope.currencies[0];
            }

            calculateSummary();


        })();
    });