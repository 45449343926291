/**
 * Created by abichenko on 03.08.17.
 */
angular
    .module('sinvoice-desktop')
    .controller('importCustomersPreviewPopupController',
        function ($scope, CustomerImport, DTOptionsBuilder, DTColumnBuilder, DataTableLanguageSettings, Events, $filter, Modal, $timeout) {

            $scope.existingUsers = '';
            $scope.errorProperties = [];

            $scope.confirm = function () {
                CustomerImport
                    .confirmImporting($scope.operationToken)
                    .then(function () {
                        Events.triggerEvent('customer.update');
                        $scope.$currentModal.close();
                    });
            };

            $scope.cancel = function () {
                $scope.$currentModal.close();
            };

            (function () {
                loadDataTable();
            })();


            function loadDataTable() {

                $scope.dt          = {};
                $scope.dt.options  = initializeDataTable();
                $scope.dt.columns  = initializeDataColumns();
                $scope.dt.instance = {};
                $scope.ltr_columns = [
                    'phone',
                    'fax',
                    'contactPhone1',
                    'contactPhone2',
                    'contactFax1',
                    'contactFax2'
                ];

                function initializeDataColumns() {


                    var dtColumns = [


                        initializeColumn('name'),
                        initializeColumn('businessNumber'),
                        initializeColumn('address'),
                        initializeColumn('email'),
                        initializeColumn('phone'),
                        initializeColumn('fax'),
                        initializeColumn('accountingNumber'),
                        initializeColumn('paymentTerms')
                            .withOption('render', function (data, type, full) {
                                if (data && data.id) {
                                    return $filter('translateCustomerPaymentTerm')(data.name);
                                } else {
                                    return data;
                                }
                            }),
                        initializeColumn('balance'),
                        initializeColumn('comments'),
                        initializeColumn('language'),
                        initializeColumn('contactFirstName'),
                        initializeColumn('contactLastName'),
                        initializeColumn('contactEmail1'),
                        initializeColumn('contactEmail2'),
                        initializeColumn('contactPhone1'),
                        initializeColumn('contactPhone2'),
                        initializeColumn('contactFax1'),
                        initializeColumn('contactFax2'),
                        initializeColumn('bank')
                            .withOption('render', function (data) {
                                if (data && data.id) {
                                    return data.name;
                                } else {
                                    return data;
                                }
                            }),
                        initializeColumn('bankNumber'),
                        initializeColumn('bankBranch'),
                        initializeColumn('bankAccount'),
                        initializeColumn('ibanNumber'),
                        initializeColumn('beneficiaryName')

                    ];


                    function initializeColumn(propertyName) {
                        return DTColumnBuilder
                            .newColumn('values.' + propertyName)
                            .withOption('createdCell', function (td, cellData, rowData, row, col) {
                                var cell = angular.element(td);
                                var errorProperties = []
                                    .concat(rowData.errors.invalid)
                                    .concat(rowData.errors.required)
                                    .concat(rowData.errors.object)
                                    .map(function (value) {
                                        return value.toLowerCase();
                                    });
                                if ($scope.ltr_columns.indexOf(propertyName) !== -1) {
                                    cell.addClass('ltr');
                                }
                                if (_.includes(errorProperties, propertyName.toLowerCase())) {
                                    angular.copy(errorProperties , $scope.errorProperties);
                                    cell.addClass('missing-info-error');
                                }
                            })
                    }

                    return dtColumns;
                }

                function initializeDataTable() {

                    function handleAlreadyExistingNumbers(customers) {

                        var usersWithObjectError = [];
                        var invalidError = [];
                        var requiredError = [];
                        customers.forEach(function (customer) {
                            if (customer.errors.object.length) {
                                usersWithObjectError.push(customer);
                            }
                        });

                        $scope.existingUsers = usersWithObjectError.map(function (user) {
                            return user.values.name;
                        }).join(', ');
                    }

                    function handleCSVDuplicates(customers) {

                        var duplicateNumbers    = [];
                        var duplicateNumbersMap = {};

                        customers.forEach(function (customer) {
                            var number = customer.values.businessNumber;

                            if (number) {
                                if (!duplicateNumbersMap[number]) {
                                    duplicateNumbersMap[number] = 1;
                                } else {
                                    duplicateNumbersMap[number]++;
                                }
                            }
                        });

                        $.each(duplicateNumbersMap, function (key, value) {
                            if (value > 1)
                                duplicateNumbers.push(key);
                        });


                        if (duplicateNumbers.length) {
                            Modal.alert(
                                $filter('translate')(
                                    'customers.import_customers_preview_popup.unique_alert',
                                    {numbers: duplicateNumbers.join(",&nbsp;")}
                                ),
                                {title: $filter('translate')('customers.import_customers_preview_popup.unique_alert_title')}
                            );
                        }

                    }

                    return DTOptionsBuilder
                        .fromFnPromise(function () {
                            return CustomerImport
                                .checkUploadStatus($scope.operationToken)
                                .then(function (result) {
                                    handleAlreadyExistingNumbers(result.data);
                                    handleCSVDuplicates(result.data);
                                    return result.data;
                                });
                        })
                        .withBootstrap()
                        .withOption('sDom', "r<'overflow-wrapper'<'width-wrapper't>><'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                        .withOption('oLanguage', DataTableLanguageSettings)
                        .withDisplayLength(10)
                        .withOption('fnCreatedRow', function (nRow, aData) {
                            if (aData.errors.object.length)
                                angular.element(nRow).find('td').addClass('duplicate-error');


                        });
                }
            }

        });