/**
 * Created by abichenko on 27.12.17.
 */
angular.module('sinvoice-mobile')
    .directive('branchInputBlock', function () {

        return {
            scope           : {
                branch    : '=',
                customer  : '=',
                parentForm: '='
            },
            controller      : 'branchInputBlockController',
            controllerAs    : 'vm',
            bindToController: true,
            templateUrl     : './mobile/components/new-document/partials/branch-input-block.html',
            link            : function (scope, element, attrs) {

            }
        }
    });