/**
 * Created by abichenko on 12.10.17.
 */
angular.module('sinvoice-desktop').factory('SubscriptionsPaymentCredentialsProvider', function (Subscription, promiseHelper) {

    var cached = {};

    return {
        getYaadpayCredentials: function () {

            if (cached['yaadpay']) {

                return promiseHelper.resolvedPromise(cached['yaadpay'])
            } else {
                return Subscription
                    .getYaadpayCredentials()
                    .then(function (data) {
                        cached['yaadpay'] = data;
                        return data;
                    })
            }

        }
    }

});