angular.module('sinvoice-desktop').factory('Referral', function ($http, $q, $log, API, Url) {
    return {
        getAll        : function () {
            var deferred = $q.defer();
            API.get(Url.invitations.all(), {})
                .success(function (data) {
                    deferred.resolve(data);
                });
            return deferred.promise;

        },
        getInvited    : function () {
            var deferred = $q.defer();
            API.get(Url.invitations.invites(), {})
                .success(function (data) {
                    deferred.resolve(data);
                });
            return deferred.promise;
        },
        getNonManaged : function () {
            var deferred = $q.defer();
            API.get(Url.invitations.nonManaged(), {})
                .success(function (data) {
                    deferred.resolve(data);
                });
            return deferred.promise;
        },
        getData       : function () {
            var deferred = $q.defer();
            API.get(Url.profile.profile(), {})
                .success(function (data) {
                    deferred.resolve(data.referralUrl);
                });
            return deferred.promise;
        },
        getStats      : function () {
            var deferred = $q.defer();
            API.get(Url.invitations.stats(), {})
                .success(function (data) {
                    deferred.resolve(data);
                });

            return deferred.promise;
        },
        invite        : function (customers, message, resendOptions) {

            var deferred = $q.defer();
            API.post(Url.invitations.send(), {
                users        : customers,
                message      : message,
                resendOptions: resendOptions
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;

        },
        getCommissions: function () {
            var deferred = $q.defer();
            API.get(Url.invitations.commissions(), {})
                .success(function (data) {
                    deferred.resolve(data);
                });

            return deferred.promise;
        },

        createEmptyCustomer: function () {
            var obj = {};

            obj.lastName  = '';
            obj.firstName = '';
            obj.facebook  = '';
            obj.phone     = '';
            obj.email     = '';

            return obj;
        },
        checkIfExist       : function (attribute, value) {
            var defer = $q.defer();

            API
                .get(Url.invitations.checkIfExist(attribute, value))
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (data) {
                    defer.reject(data)
                });

            return defer.promise;
        },
        resendInvitation   : function (id) {

            var deferred = $q.defer();

            API.post(Url.invitations.resend(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;


        },
        sendEmail          : function (ids, message, subject, sendCopy) {
            var deferred = $q.defer();

            API.post(Url.invitations.sendEmail(), {ids: ids, message: message, subject: subject, sendCopy: sendCopy})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        sendEmailToInvited : function (ids, message, subject, sendCopy) {
            var deferred = $q.defer();

            API.post(Url.invitations.sendEmailToInvited(), {ids: ids, message: message, subject: subject, sendCopy: sendCopy})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        getInvited         : function () {
            var deferred = $q.defer();
            API.get(Url.invitations.invites(), {})
                .success(function (data) {
                    deferred.resolve(data);
                });
            return deferred.promise;
        },
        getPDFContent      : function (commissions) {
            var deferred = $q.defer();
            API.get(Url.invitations.exportCommissionsToPDF(), {commissions: commissions})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getXLSContent      : function (commissions) {
            var deferred = $q.defer();
            API.get(Url.invitations.exportCommissionsToXLS(), {commissions: commissions})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
    };
});