/**
 * Created by abichenko on 27.10.17.
 */
angular.module('sinvoice-common').service('Login', function ($q, API, Url) {

    return {
        performLogin: function (username, password) {

            var deferred = $q.defer();

            API
                .post(Url.auth.login(), {
                    username: username,
                    password: password
                })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;

        }
    }


});