/**
 * Created by abichenko on 22.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('summaryBlockController', function ($scope, DocumentSummaryDataProvider, Currency, Events) {


        $scope.summaryData = [];


        $scope.$watch(function () {
            return JSON.stringify($scope.templateVariable)
        }, function () {
            updateSummaryData();
        });

        $scope.$watch('editedDocument.selectedVATParameters', updateSummaryData);
        $scope.$watch('editedDocument.nisCourse', updateSummaryData);
        Events.subscribeToEvent('currency.change', $scope, updateSummaryData);

        function updateSummaryData() {

            calculateTemplateValues();
            calculateItemsTotalNIS();

            $scope.summaryData = DocumentSummaryDataProvider.getSummaries(
                $scope.templateVariable,
                $scope.editedDocument.selectedVATParameters,
                $scope.editedDocument.type.docType,
                $scope.editedDocument.discounts,
                $scope.editedDocument.currencies,
                $scope.editedDocument.nisCourse
            )
        }

        function calculateTemplateValues() {


            var itemsExcludedVatTotal        = $scope.templateVariable.$itemsExcludedVatTotal;
            var itemsIncludedVatTotal        = $scope.templateVariable.$itemsIncludedVatTotal;
            var itemsIncludedVatTotalWithVat = $scope.templateVariable.$itemsIncludedVatTotalWithVat;

            var totalAmount        = itemsExcludedVatTotal + itemsIncludedVatTotal;
            var totalAmountWithVat = itemsExcludedVatTotal + itemsIncludedVatTotalWithVat;
            var vatAmount          = totalAmountWithVat - totalAmount;
            if (vatAmount < 0)
                vatAmount = 0;


            $scope.templateVariable.$vatAmount              = parseFloat(vatAmount);
            $scope.templateVariable.$finalItemsTotal        = parseFloat(totalAmount);
            $scope.templateVariable.$finalItemsTotalWithVat = parseFloat(totalAmountWithVat);

            calculateItemsTotalNIS();

        }

        function calculateItemsTotalNIS() {

            var finalAmountNIS = Currency.convertAmount(
                parseFloat($scope.templateVariable.$finalItemsTotalWithVat),
                $scope.editedDocument.currencies.name,
                'NIS',
                $scope.editedDocument.nisCourse
            );

            $scope.templateVariable.$finalItemsTotalWithVatNIS = finalAmountNIS || 0;

            $scope.templateVariable.$vatAmountNIS = Currency.convertAmount(
                $scope.templateVariable.$vatAmount,
                $scope.editedDocument.currencies.name,
                'NIS',
                $scope.editedDocument.nisCourse
            );
        }

    });
