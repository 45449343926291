/**
 * Created by abichenko on 06.10.17.
 */
angular.module('sinvoice-desktop')
    .directive('subscriptionsMySubscriptionBlock', function () {

        return {
            templateUrl: "./desktop/components/profile/subscriptions-my-subscription-block.html",
            link       : function (scope, element, attrs) {

            },
            scope      : {currentSubscriptionData: '='}
        }
    })
    .controller('subscriptionsMySubscriptionBlockController', function ($scope, $filter, $translate, Modal, ModalContainer, API, Url, Auth, Events) {

        $scope.spinner      = {enabled: false};

        $scope.getActiveUntil = function () {
            var date = $scope.currentSubscriptionData.currentSubscription.activeUntil;
            return $filter('dpickerDateFormatter')(momentWrapped(date).subtract(1, 'days'));
        };

        $scope.getDocumentsLeft = function () {
            return $scope.getMaxDocuments() - $scope.getUsed();
        };

        $scope.getMaxDocuments = function () {
            return $scope.currentSubscriptionData.currentPeriod.selectedPlan.maxDocuments;
        };

        $scope.getUsed = function () {
            return $scope.currentSubscriptionData.currentPeriod.documentsUsed;
        };

        $scope.getUsedPercent = function () {
            var percent = ($scope.getUsed() / $scope.getMaxDocuments() ) * 100;
            return percent > 100 ? 100 : percent;
        };

        $scope.getCurrentSubscriptionLabel = function () {
            return $scope.currentSubscriptionData.currentSubscription.selectedPlan.label;
        };


        $scope.getAccountBalance = function () {

            var type = $scope.currentSubscriptionData.currentSubscription.selectedPlan.type;

            var price = $scope.currentSubscriptionData.currentSubscription.selectedPlan.price;
            var totalPrice = $scope.currentSubscriptionData.currentSubscription.selectedPlan.totalPrice;

            var expires_date = momentWrapped($scope.currentSubscriptionData.currentSubscription.activeUntil);
            var starts_date = momentWrapped($scope.currentSubscriptionData.currentSubscription.startsFrom);
            var current_date = momentWrapped();

            // var all_period_diff = expires_date.diff(starts_date, 'month');
            // var diff_date = expires_date.diff(current_date, 'month');

            var balance = 0;

            switch (type) {
                case 'monthly' :
                    balance = (30 - expires_date.diff(current_date, 'days')) * (expires_date.diff(starts_date, 'days') / totalPrice);
                    break;
                case 'yearly' :
                    balance = expires_date.diff(current_date, 'month') * price;
                    break;
                case 'starter' :
                    balance = 0;

                    break;
                default:
            }

            function round(number, precision) {
                var pair = (number + 'e').split('e');
                var value = Math.round(pair[0] + 'e' + (+pair[1] + precision));
                pair = (value + 'e').split('e');
                return +(pair[0] + 'e' + (+pair[1] - precision))
            }

            return round(balance * 1.17, 2);
        };

        $scope.cancelChangeSubscriptionButton = function () {

            Modal
                .customDialog({
                    scope      : $scope,
                    title      : $translate.instant('profile.subscriptions.upgrade_subscription.popup_header_main'),
                    templateUrl: './desktop/components/profile/partials/subscriptions-popup-processing-cancel.html',
                    size       : "middle",
                    onEscape   : close
                })
                .then(function (dialog) {
                    ModalContainer.setElement('cancelChangeSubscriptionButton', dialog, 'subscriptionModals');
                });
        };

        $scope.currentStep = 'start';

        $scope.changeSubscription = function () {

            Modal
                .customDialog({
                    scope      : $scope,
                    title      : $translate.instant('profile.subscriptions.upgrade_subscription.change'),
                    templateUrl: './desktop/components/profile/partials/subscriptions-popup-processing-change.html',
                    size       : "middle",
                    onEscape   : close
                })
                .then(function (dialog) {
                    ModalContainer.setElement('changeSubscription', dialog, 'subscriptionModals');
                });
        };

        $scope.showStep = function (step) {
            return step == $scope.currentStep;
        };

        $scope.changeActionStatus = function(type){
            $scope.currentStep = type;
        };

        $scope.requestSubscriptionCancellation = {
            cancellation_reason: ''
        };
        
        var profile   = Auth.getUser().profile;

        $scope.request_data = {
            email   :    profile.email,
            phone   :    profile.phone,
            fullName:    profile.firstName + " " + profile.lastName,
        };

        $scope.sendRequest = function () {
            $scope.request_data.requestContent = $scope.requestSubscriptionCancellation.cancellation_reason;

            if ($scope.subscriptionBlockForm.$invalid) {
                return;
            } else {
                API.post(Url.subscriptions.sendRequestAboutCancel(), {'request_data' : $scope.request_data})
                    .success(function (data) {
                        $scope.$currentModal.close();
                        if (data.status) {
                            Modal.alert(
                                $filter('translate')('common.general_messages.contact_you_soon'),
                                {title: $filter('translate')('reports.email_send_successful_title')}
                            );
                        } else {
                            Modal.alert(
                                $filter('translate')('reports.email_send_failure'),
                                {title: $filter('translate')('reports.email_send_failure_title')}
                            );
                        }

                        if (data.status) {
                            $scope.currentStep = 'request-success';
                        }
                    });
            }
        };

        $scope.cancelAndRefundAction = function($event) {

            $event.preventDefault();

            API.post(Url.subscriptions.cancelSubscription())
                .success(function (data) {
                    $scope.$currentModal.close();
                    Events.triggerEvent('subscription.update');
                });

        };

        $scope.refund_data = {
            status  : false,
            error   : '',
            refundRequest    : ''
        };

        $scope.closePopup = function () {
          return close();
        };

        function close() {
            ModalContainer.closeGroup('subscriptionModals');
        }

    });