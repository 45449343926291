/**
 * Created by abichenko on 13.03.18.
 */
angular.module('sinvoice-desktop').factory('AttributeModal', function ($filter, Modal) {


    return {
        createAttribute   : function () {
            Modal.customDialog({
                title      : $filter('translate')('customers.edit.attributes.create_attribute'),
                size       : "medium",
                templateUrl: './desktop/components/customers/edit-attribute.html',
            });
        },
        editAttributeValue: function (attributeId, customerId) {
            Modal.customDialog({
                title         : $filter('translate')('customers.edit.attributes.edit_value_popup_title'),
                templateUrl   : './desktop/components/customers/edit-attribute-value.html',
                size          : "medium",
                scopeVariables: {
                    attributeId: attributeId,
                    customerId : customerId
                }
            });
        }
    };

});