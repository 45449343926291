angular.module('sinvoice-desktop').controller('editCommunicationController',
    function ($scope, $rootScope, Url, $filter, $q, dpickerSettings, Events, Modal, Communication, TokenStorage, Customer, EditedValueComparator, $timeout, DesktopModal) {

        var backup;

        $scope.spinner                   = {};
        $scope.spinner.editCommunication = true;
        $scope.contacts                  = null;
        $scope.editCommunicationForm     = {};
        $scope.allowedFiles              = ["pdf", "doc", "docx", "ppt", "pptx", "xls", "xlsx", "png", "jpg"];
        $scope.fileExtention             = $scope.allowedFiles.join(', ');

        $scope.$watch('selectedTime + selectedDate', function () {

            if (!$scope.selectedDate || !$scope.selectedTime)
                return false;

            var dateTimeString = $scope.selectedDate.format(dpickerSettings.dateFormat) + ' ' + $scope.selectedTime;

            $scope.communication.date = momentWrapped(
                dateTimeString,
                dpickerSettings.timeFormat
            );
        });

        $scope.close = function () {
            $scope.cancel();
        };

        $scope.$currentModal.onCancelCallback = function () {

            if (EditedValueComparator.isObjectChangedExceptOfProps(backup, $scope.communication)) {
                return DesktopModal.unsavedChanges();
            } else {
                return true;
            }
        };

        $scope.getCommunicationFile = function (id) {
            window.open(Url.customers.getCommunicationFile(id, TokenStorage.getCurrentSessionToken()), '_blank');
            return false;
        };

        $scope.deleteCommunication = function () {

            Modal
                .confirm(
                    $filter('translate')('customers.edit.communications.table.delete_confirm_popup.content'),
                    null,
                    null,
                    {title: $filter('translate')('customers.edit.communications.table.delete_confirm_popup.title')}
                )
                .then(function () {
                    Communication.delete($scope.communication.id).then(function () {
                        Events.triggerEvent('communications.update');
                        $scope.close();
                    })
                });


        };

        $scope.saveCommunication = function () {

            if ($scope.editCommunicationForm.$invalid) {
                return false;
            }

            if ($scope.communication.submission_type.name != 'Other') {
                $scope.communication.submission_label = $scope.communication.submission_type.name;
            }

            if ($scope.communication.id) {
                Communication.update($scope.communication.id, $scope.communication).then(function () {
                    Events.triggerEvent('communications.update');
                    $scope.$currentModal.close();
                });
            } else {
                Communication.create($scope.communication).then(function () {
                    Events.triggerEvent('communications.update');
                    $scope.$currentModal.close();
                })
            }
        };


        function loadData() {

            $q.all([
                loadContacts(),
                loadSubmissionsTypes()
            ]).then(function (data) {

                if (!$scope.communication) {
                    $scope.communication = {};
                }

                $scope.spinner.editCommunication = false;

                var defaultSubmission = $scope.submissions.length
                    ? $scope.submissions[0]
                    : null;
                var defaultContact    = $scope.contacts.length
                    ? $scope.contacts[0]
                    : null;

                if (!$scope.communication.date) {
                    var date = momentWrapped();
                } else {
                    var date = momentWrapped($scope.communication.date);
                }

                $scope.selectedDate = date;
                $scope.selectedTime = date.format('HH:mm');


                if (!$scope.communication.submission_type)
                    $scope.communication.submission_type = defaultSubmission;

                if (!$scope.communication.contact)
                    $scope.communication.contact = defaultContact;

                // waiting for initial $watch callbacks triggering
                $timeout(function () {
                    backup = angular.copy($scope.communication);
                });

            });

        }

        function loadSubmissionsTypes() {

            return Communication.getSubmissionTypes().then(function (data) {
                $scope.submissions = data;
            });
        }

        function loadContacts() {
            var id = $rootScope.editingCustomer.id;

            return Customer.getContacts(id).then(function (data) {
                $scope.contacts = data;
            });

        };

        (function () {
            loadData();
        })();

    });