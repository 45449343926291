/**
 * Created by abichenko on 18.01.18.
 */
angular.module('sinvoice-common')
    .factory('ScopeProvider', function ($rootScope, ConstantStorage) {

        return {
            getNewIsolatedScope: function (variables) {
                var newScope = $rootScope.$new(true);

                $.each(ConstantStorage.getRegisteredConstants(), function (key, value) {
                    newScope[key] = value;
                });

                if (angular.isObject(variables)) {
                    $.each(variables, function (key, value) {
                        newScope[key] = value;
                    });
                }

                return newScope;
            },
            getNewScope        : function (variables) {
                var newScope = $rootScope.$new();

                $.each(ConstantStorage.getRegisteredConstants(), function (key, value) {
                    newScope[key] = value;
                });

                if (angular.isObject(variables)) {
                    $.each(variables, function (key, value) {
                        newScope[key] = value;
                    });
                }

                return newScope;
            }
        }

    })
    .factory('ConstantStorage', function () {
        var constants = {};

        return {
            registerViewConstant  : function (key, value) {
                constants[key] = value;
            },
            getRegisteredConstants: function () {
                return constants;
            }
        }
    })
    .run(function (ScopeProvider, $rootScope, ConstantStorage) {
        var constants = ConstantStorage.getRegisteredConstants();

        $.each(constants, function (key, value) {
            $rootScope[key] = value;
        })
    });