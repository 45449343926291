/**
 * Created by abichenko on 21.11.17.
 */
angular.module('sinvoice-mobile')
    .directive('discountFormBlock', function () {
        return {
            scope      : {
                discount        : "=",
                templateVariable: '=',
                onCancel        : "&",
                onSave          : "&"
            },
            controller : 'discountFormBlockController',
            templateUrl: './mobile/components/new-document/partials/discount-form-block.html',
            link       : function (scope, element, attrs) {

            }
        }

    })