/**
 * Created by abichenko on 10.04.18.
 */
angular.module('sinvoice-common')
    .filter('translateReportType', function ($filter) {


        return function (value) {
            return $filter('translate')('reports.type.' + value);
        }

    });
