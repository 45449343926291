/**
 * Created by dbichenko on 05.09.2016.
 */
angular.module('sinvoice-desktop').controller('managedCustomersNavbarController', function (
    $scope, 
    ManagedCustomers,
    ManagedCustomersManagement, 
    $timeout,
    $state, Events,
    $rootScope) {

    // Select
    $scope.selectIsDisabled = true;
    $rootScope.managedCustomer  = {selected: {}};
    // Buttons
    $scope.goToMainPage = goToMainPage;


    // Remove current edited customer after go on aver pagea
    Events.subscribeToEvent('$stateChangeStart', $scope,     function(event, toState, toParams, fromState, fromParams, options){
        $rootScope.managedCustomer  = {selected: {}};
    });    


    $scope.selectCustomer = function (item) {
        ManagedCustomersManagement.enableManagementMode(item.id);
    };

    $scope.backToAccount = function () {
        ManagedCustomersManagement.disableManagementMode();
    };

    function goToMainPage () {
        if ($scope.currMenu === 'customers.representatives') {
            $state.reload();
        }else {
            $state.go('customers.representatives');
        }
    }


    function updateCurrentCustomer() {

        var currentManagedCustomer = ManagedCustomersManagement.getCurrentManagedCustomer();

        if (!currentManagedCustomer.id) {
            $rootScope.managedCustomer.selected = undefined;
        } else {
            $rootScope.managedCustomer.selected = currentManagedCustomer;
        }

        Events.triggerEvent('$managedCustomerChanged');
    }


    (function () {
        ManagedCustomers.getAll().then(function (data) {
            $scope.managedCustomers = data;
            $scope.selectIsDisabled = !data.length;

        });

        $rootScope.$watch(function () {
            return ManagedCustomersManagement.getCurrentManagedCustomer();
        }, function () {
            updateCurrentCustomer();
        })
    })();

});