/**
 * Created by abichenko on 20.11.17.
 */
angular.module('sinvoice-mobile').directive('titleBlock', function () {


    return {
        scope      : {
            titleModel              : '=',
            titleEnModel            : '=',
            isEnglishLanguageEnabled: '=',
            docType                 : '='
        },
        templateUrl: './mobile/components/new-document/partials/title-block.html',
        link       : function (scope, element, attrs) {

        }
    }
})