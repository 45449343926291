/**
 * Created by abichenko on 13.10.17.
 */
angular.module('sinvoice-desktop').run(function (DataTableDefaultsWrapper, UserSubscription) {

    DataTableDefaultsWrapper.setExtensionOptions('buttons', 'subscriptionEditActionButton', {

        enabled        : false,
        enabledCallback: function (e, dt, node, config) {

            var inheritEnableCallbackResult = config.extendOptions && angular.isFunction(config.extendOptions.enabledCallback)
                ? config.extendOptions.enabledCallback(e, dt)
                : true;

            return !UserSubscription.isCheckInProgress() && inheritEnableCallbackResult;
        },

        action         : function (e, dt, node, config) {
            UserSubscription.checkSubscriptionForEditAction().then(function () {
                config.extendOptions.action(e, dt, node, config);
            })
        }


    })

});