/**
 * Created by abichenko on 19.12.17.
 */
angular.module('sinvoice-desktop')
    .controller('incomeAndDebtsTableController',
        function ($scope,
                  $filter,
                  DTColumnDefBuilder,
                  DTOptionsBuilder,
                  ReportsExport,
                  DataTableBootstrapSettings,
                  DataTableLanguageSettings,
                  IncomeAndDebtsSummaryRowRenderer,
                  DataTableFunctions) {

            $scope.dt = {};


            $scope.getSortedItems = function () {
                return _.sortBy($scope.currentReport.content.docs, function (document) {
                    return document.docNumber;
                });
            };


            $scope.sendToEmail = function () {
                ReportsExport.email($scope.currentReport);
            };

            $scope.printTable = function (id) {
                ReportsExport.print($scope.currentReport);

            };

            $scope.isTotalRowNeedToBeShown = function (item, $index) {
                var nextDocument = $scope.getSortedItems()[$index + 1];

                if (!nextDocument) {
                    return true;
                }

                if (nextDocument.customer.id !== item.customer.id) {
                    return true;
                }
            };

            $scope.getTotalValue = function (item) {
                return $scope.currentReport.content.summary.customer_totals[item.customer.id]
            };


            function loadDataTable() {

                $scope.dt.options = initializeDataTable();
                $scope.dt.columns = initializeDataColumns();

                function initializeDataColumns() {

                    var dtColumns = [

                        DTColumnDefBuilder.newColumnDef(0),
                        DTColumnDefBuilder.newColumnDef(1),
                        DTColumnDefBuilder.newColumnDef(2),
                        DTColumnDefBuilder.newColumnDef(3),
                        DTColumnDefBuilder.newColumnDef(4),
                        DTColumnDefBuilder.newColumnDef(5),
                        DTColumnDefBuilder.newColumnDef(6),
                        DTColumnDefBuilder.newColumnDef(7)

                    ];

                    dtColumns.forEach(function (value) {
                        value.notSortable();
                        value.renderWith(function (data) {
                            return data;
                        })
                    });

                    return dtColumns;
                }

                function initializeDataTable() {


                    var dtOptions = DTOptionsBuilder
                        .newOptions()
                        .withBootstrap()
                        .withOption('sDom', "Brt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                        .withOption('oLanguage', DataTableLanguageSettings)
                        .withOption('buttonContainer', '#buttons-block')
                        .withButtons([
                            {
                                extend       : 'subscriptionEditActionButton',
                                text         : $filter('translate')('reports.email'),
                                className    : 'the_button_2 email',
                                extendOptions: {

                                    action         : function (e, dt) {
                                        ReportsExport.email($scope.currentReport)
                                    },
                                    enabledCallback: function (e, dt) {
                                        return DataTableFunctions.getData(dt).length;
                                    }
                                }
                            },
                            {
                                extend         : 'collection',
                                autoClose      : true,
                                text           : $filter('translate')('reports.export'),
                                className      : 'the_button_2 export_data',
                                buttons        : [
                                    {
                                        'text'         : $filter('translate')('reports.excel'),
                                        'className'    : 'the_button_2',
                                        action         : function (e, dt) {
                                            ReportsExport.downloadAsXLS($scope.currentReport);
                                        },
                                        enabled        : false,
                                        enabledCallback: function (e, dt) {
                                            return !!$scope.currentReport.content.docs.length;
                                        }

                                    },
                                    {
                                        'text'         : 'PDF',
                                        'className'    : 'the_button_2',
                                        action         : function (e, dt) {
                                            ReportsExport.showAsPDF($scope.currentReport);
                                        },
                                        enabled        : false,
                                        enabledCallback: function (e, dt) {
                                            return !!$scope.currentReport.content.docs.length;
                                        }
                                    }
                                ],
                                enabledCallback: function (e, dt) {
                                    return DataTableFunctions.getData(dt).length;
                                }
                            },
                            {
                                text           : $filter('translate')('reports.print'),
                                className      : 'the_button_2 print-grey',
                                action         : function (e) {
                                    ReportsExport.print($scope.currentReport);
                                },
                                enabledCallback: function (e, dt) {
                                    return DataTableFunctions.getData(dt).length;
                                }
                            }
                        ])
                        .withOption('fnDrawCallback', function (settings) {

                            var api         = this.api();
                            var rows        = api.rows().nodes();
                            var columnCount = api.columns().nodes().length;
                            var groups      = [];


                            api
                                .column(0)
                                .nodes()
                                .each(function (value, i) {
                                    var current = $(value);
                                    groups[i]   = current.find('.sorter-value').html().trim();
                                });

                            groups.forEach(function (currentGroup, i) {

                                var rowDom    = $(rows).eq(i);
                                var nextGroup = groups[i + 1];

                                rowDom.find('.tbl_summary_group_title').remove();
                                if (nextGroup !== currentGroup) {

                                    var customerTotals = $scope.currentReport.content.summary.customer_totals[currentGroup];

                                    var data = [];

                                    $.each(customerTotals.summaries, function (key, value) {
                                        data.push({
                                            label: $filter('translateIncomeRevenueSummaryRowLabel')(key),
                                            value: value
                                        });
                                    });

                                    $.each(customerTotals.docTypes, function (key, value) {
                                        data.push({
                                            label: $filter('translateReportDocTypeSummaryLabel')(key),
                                            value: value
                                        });
                                    });

                                    data = data.filter(function (row) {
                                        return !!row.value;
                                    });

                                    var row = IncomeAndDebtsSummaryRowRenderer.getRenderedDom(data, columnCount);
                                    rowDom.find('.main-td >  table > tbody').append(row);
                                }
                            });

                        })
                        .withOption('order', [0, "asc"])

                        .withDisplayLength(5);

                    return dtOptions;
                }
            }


            (function () {

                loadDataTable();
            })();


        });