/**
 * Created by abichenko on 16.03.18.
 */
angular.module('sinvoice-common').factory('ReportRouteResolver', function () {


    return {
        resolveFiltersToStateParams: function (filters, id, from, to) {

            var params = {};
            $.each(filters, function (key, array) {
                return params[key] = array.toString();
            });
            params.id   = id;
            params.from = from;
            params.to   = to;

            return params;
        },

        resolve: function (type) {

            var route;

            switch (type) {
                case 'vat_income':
                    route = 'reports.vatincome';
                    break;
                case 'bookkeeping_income':
                    route = 'reports.accounting';
                    break;
                case 'income_revenue':
                    route = 'reports.incomeanddebts';
                    break;
                case 'item_income':
                    route = 'reports.incomeitems';
                    break;
                case 'cheques':
                    route = 'reports.cheques';
                    break;
            }

            return route;
        }
    }

});