/**
 * Created by Front-end on 27.12.2016.
 */
angular.module('sinvoice-desktop').controller('chequesController',
    function ($scope,
              DTOptionsBuilder,
              DTColumnBuilder,
              DTColumnDefBuilder,
              DataTableFunctions,
              DataTableLanguageSettings,
              ReportsExport,
              $stateParams,
              Events,
              Report,
              $translate,
              $filter,
              $state,
              $timeout) {

        $scope.availableStatuses = [];

        $scope.spinner      = {enabled: true};
        $scope.currentState = $state.current.name;

        $scope.dt             = {};
        $scope.isDataSelected = false;
        $scope.$emit('onLoadReportPage', {
            state: $scope.currentState,
            page : {
                title      : $filter('translate')('reports.pages.cheques'),
                description: '',
                alias      : 'reports.pages.cheques'
            }
        });

        $scope.updateIsSelected = updateIsSelected;
        function updateIsSelected() {

            $timeout(function () {
                $scope.isDataSelected = DataTableFunctions.getSelectedData($scope.dt.instance.DataTable).length > 0 ? true : false
            })

        }

        function loadDataTable() {

            $scope.dt.options  = initializeDataTable();
            $scope.dt.columns  = initializeDataColumns();
            $scope.dt.instance = {};

            function initializeDataColumns() {

                var dtColumns = [


                    DTColumnDefBuilder.newColumnDef(0),
                    DTColumnDefBuilder.newColumnDef(1),
                    DTColumnDefBuilder.newColumnDef(2),
                    DTColumnDefBuilder.newColumnDef(3),
                    DTColumnDefBuilder.newColumnDef(4),
                    DTColumnDefBuilder.newColumnDef(5),
                    DTColumnDefBuilder.newColumnDef(6),
                    DTColumnDefBuilder.newColumnDef(7),
                    DTColumnDefBuilder.newColumnDef(8),
                    DTColumnDefBuilder.newColumnDef(9)

                ];

                dtColumns.forEach(function (value) {
                    value.notSortable();
                    value.renderWith(function (data) {
                        return data;
                    })

                });

                return dtColumns;
            }

            function initializeDataTable() {


                var dtOptions = DTOptionsBuilder
                    .newOptions()
                    .withBootstrap()
                    .withOption('sDom', "Brt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                    .withOption('oLanguage', DataTableLanguageSettings)
                    .withOption('fnSelect', function () {
                        $scope.updateIsSelected();
                    })
                    .withOption('buttonContainer', '#buttons-block')
                    .withButtons([
                        {
                            extend       : 'subscriptionEditActionButton',
                            text         : $translate.instant('reports.email'),
                            className    : 'the_button_2 email',
                            extendOptions: {
                                action         : function (e, dt) {
                                    ReportsExport.email($scope.currentReport)
                                },
                                enabledCallback: function (e, dt) {
                                    return DataTableFunctions.getData(dt).length;
                                }
                            }
                        },
                        {
                            extend         : 'collection',
                            text           : $translate.instant('reports.export'),
                            autoClose      : true,
                            className      : 'the_button_2 export_data',
                            buttons        : [
                                {
                                    'text'         : $translate.instant('reports.excel'),
                                    'className'    : 'the_button_2',
                                    enabled        : false,
                                    enabledCallback: function (e, dt) {
                                        return !!$scope.currentReport.content.length;
                                    },
                                    action         : function (e, dt) {
                                        ReportsExport.downloadAsXLS($scope.currentReport);
                                    }
                                },
                                {
                                    'text'         : 'PDF',
                                    'className'    : 'the_button_2',
                                    enabled        : false,
                                    enabledCallback: function (e, dt) {
                                        return !!$scope.currentReport.content.length;
                                    },
                                    action         : function (e, dt) {
                                        ReportsExport.showAsPDF($scope.currentReport);
                                    }
                                }
                            ],
                            enabledCallback: function (e, dt) {
                                return DataTableFunctions.getData(dt).length;
                            }
                        },
                        {
                            text           : $translate.instant('reports.print'),
                            className      : 'the_button_2 print-grey',
                            action         : function (e) {
                                ReportsExport.print($scope.currentReport);
                            },
                            enabledCallback: function (e, dt) {
                                return DataTableFunctions.getData(dt).length;
                            }
                        }
                    ])
                    .withOption('order', [2, "desc"])

                    .withDisplayLength(5);

                return dtOptions;
            }
        }

        function loadReport(id) {

            $scope.spinner.enabled = true;
            return Report.getSingle(id, 'cheques').then(function (data) {

                $scope.currentReport   = data;
                $scope.spinner.enabled = false;
            })
        }


        (function () {
            loadDataTable();

            if ($stateParams.id) {
                $scope.isTableShown = true;
                loadReport($stateParams.id)
            } else {
                $scope.isTableShown = false;
            }

            Events.subscribeToEvent('report.update', $scope, function (e, newReport) {
                $scope.isTableShown = true;
                loadReport(newReport.id);
            })


        })()
    });