/**
 * Created by abichenko on 17.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('mobileNewDocumentStepTwoController',
        function ($scope,
                  ProfileChecker,
                  AuthDataStorage,
                  $filter,
                  MobileModal,
                  DocumentModalCallbackContainer,
                  DocumentModalCallbackChecker,
                  Events,
                  $stateParams,
                  $q,
                  promiseHelper,
                  CustomerTypeChecker,
                  Customer) {

            $scope.documentForm = {};

            $scope.spinner = {enabled: true};

            $scope.template = {};

            $scope.isEnglishLanguageEnabled = function () {

                return ProfileChecker.isEnglishAvailable((AuthDataStorage.getProfileBusinesses()))
                    || $scope.editedDocument.language === 'en';
            };

            $scope.isEnglishLanguageSelected = function () {
                return $scope.editedDocument.language === 'en';
            };


            $scope.submit = function () {

                if ($filter('isSubmitNotAllowed')($scope.documentForm))
                    return;

                $scope.spinner.enabled = true;

                DocumentModalCallbackContainer
                    .getValidatePromise($scope.documentForm)
                    .then(DocumentModalCallbackContainer.getPreSavePromise)
                    .then(function () {
                        $scope.afterSave();
                    })
                    .finally(function () {
                        $scope.spinner.enabled = false;
                    });
            };

            $scope.showDebugScreen = function () {
                MobileModal.overlay('./mobile/components/new-document/partials/debug-window.html', {
                    scopeVariables: {
                        editedDocument: $scope.editedDocument
                    }
                });
            };

            (function () {

                var ngIncludePromise = new Promise(function (resolve, reject) {
                    var destroy = $scope.$on('$includeContentLoaded', function () {
                        resolve();
                        destroy();
                    });
                });


                var loadCustomerPromise = $stateParams.customerId
                    ? Customer.getSingleCustomer($stateParams.customerId).then(function (data) {
                        $scope.editedDocument.customer = data;
                    })
                    : promiseHelper.resolvedPromise();


                $q
                    .all([
                        ngIncludePromise,
                        loadCustomerPromise
                    ])
                    .finally(function () {
                        $scope.spinner.enabled = false;
                    });


                DocumentModalCallbackContainer.reset();

                DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                    if (!DocumentModalCallbackChecker.isWithholdingTaxPopupNeedToBeShown(
                            $scope.editedDocument,
                            $scope.template.$paymentsTotal
                        ))
                        return flow.allow();

                    MobileModal
                        .confirm(
                            $filter('translate')('documents.withholding_tax_warning'),
                            null,
                            null,
                            {title: $filter('translate')('documents.withholding_tax_warning_title')}
                        )
                        .then(flow.allow, flow.deny);


                });

                DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {


                    var editedDocument  = $scope.editedDocument;
                    var invalidAmount   = DocumentModalCallbackChecker.isCustomerMissingForAmountPopupNeedToBeShown(
                        editedDocument,
                        $scope.template.$paymentsTotalWithVatNIS,
                        $scope.template.$finalItemsTotalWithVatNIS
                    );
                    var invalidTaxValue = DocumentModalCallbackChecker.isCustomerMissingForTaxValuePopupNeedToBeShown(
                        editedDocument
                    );

                    var reason = null;
                    if (invalidAmount) {
                        reason = 'amount'
                    } else if (invalidTaxValue) {
                        reason = 'taxValue'
                    }

                    if (!reason) {
                        return flow.allow();
                    }


                    if (CustomerTypeChecker.isOccasional($scope.editedDocument.customer.customerType)) {
                        createCustomerPopup(reason)
                    } else {
                        missingBusinessNumberPopup(reason);
                    }

                    function createCustomerPopup(reason) {

                        var defaultCustomerName = $filter('translate')('documents.use_occuring');

                        MobileModal.bluebarModal(
                            './mobile/components/new-document/partials/popups/create-new-customer-popup.html', {
                                title         : $filter('translate')('documents.missing_info_popup.title'),
                                scopeVariables: {
                                    docType    : $scope.editedDocument.type,
                                    customer   : {
                                        email         : editedDocument.customerEmail,
                                        address       : editedDocument.customerAddress,
                                        businessnumber: editedDocument.customerOccasionalVatNumber,
                                        name          : editedDocument.customerOccasionalName !== defaultCustomerName
                                            ? editedDocument.customerOccasionalName
                                            : ''
                                    },
                                    triggeredBy: reason,
                                    onSave     : function (customer) {
                                        $scope.editedDocument.customer = customer;
                                        flow.allow()
                                    },
                                    onCancel   : function () {
                                        flow.deny();
                                    }
                                }
                            })
                    }

                    function missingBusinessNumberPopup(reason) {
                        MobileModal.bluebarModal(
                            './mobile/components/new-document/partials/popups/customer-info-missing-popup.html', {
                                title         : $filter('translate')('documents.missing_info_popup.title'),
                                scopeVariables: {
                                    docType    : $scope.editedDocument.type,
                                    customer   : angular.copy($scope.editedDocument.customer),
                                    triggeredBy: reason,
                                    onSave     : function (customer) {
                                        $scope.editedDocument.customer = customer;
                                        flow.allow()
                                    },
                                    onCancel   : function () {
                                        flow.deny();
                                    }
                                }
                            })
                    }
                })
            })();
        });