angular.module('sinvoice-desktop').controller('distributorsDashboardController',
    function ($scope,
              $filter,
              $compile,
              Url,
              Referral,
              DTOptionsBuilder,
              DTColumnBuilder,
              $translate,
              DataTableLanguageSettings,
              $q,
              DataTableBootstrapSettings,
              Modal,
              DataTableDatepickerLabels,
              AuthDataStorage,
              Auth,
              API,
              DirectiveCompiler,
              Events,
              UserSubscription,
              DataTableFunctions,
              NotificationModal) {

        $scope.activeTable        = {
            all      : true,
            paying   : false,
            nonPaying: false,
            invited  : false,
            declined : false
        };
        $scope.activeChart        = 'commissions';
        $scope.chart              = {commissions: {}, customers: {}};
        $scope.distributors       = [];
        var paymentFilterStatuses = [
            {
                value: "",
                label: $filter('translate')('control.payment.status.all')
            },
            {
                value: 'paying',
                label: $filter('translateDistributorStatus')('paying')
            },
            {
                value: 'nonPaying',
                label: $filter('translateDistributorStatus')('nonPaying')
            },
            {
                value: 'invited',
                label: $filter('translateDistributorStatus')('invited')
            },
            {
                value: 'refused',
                label: $filter('translateDistributorStatus')('refused')
            }
        ]

        function showInitialPopup() {
            var user = AuthDataStorage.retrieveStoredUser();

            if (user && !user.isDistributorInitialPopupShown) {
                Modal.customDialog({

                    templateUrl: './desktop/components/control/welcome-popup.html',
                    closeButton: false,
                    size       : 'large',
                    onEscape   : true,
                    className  : 'distibutorsPopWelcome',
                    scope      : $scope
                });

                API.post(Url.profile.disableDistributorsPopup()).success(function (data) {
                    if (data.status) {
                        Auth.changeDistributorPopupStatus(true);
                    }
                })
            }
        }

        $scope.closeDistributorPopUp = function () {
            Modal.hideAll();
        };

        $scope.sendMessage = function () {

            var distributors = DataTableFunctions.getSelectedData(getActiveTableInstance().DataTable);

            NotificationModal.sendEmailToDistributors(distributors);
        };

        $scope.isSendButtonDisabled = function () {

            var dataTable = getActiveTableInstance().DataTable;

            if (!dataTable)
                return true;

            if (UserSubscription.isCheckInProgress())
                return true;

            return !DataTableFunctions
                .getSelectedData(dataTable)
                .filter(function (recepient) {
                    return !!recepient.email;
                })
                .length
        };

        function getActiveTableInstance() {
            var active = null;

            $.each($scope.activeTable, function (key, value) {
                if (value)
                    active = key;
            });

            return $scope.dtInstances[active];

        }

        Events.subscribeToEvent('invites.update', $scope, function () {
            loadDistributorData().then(reloadTables)
        });

        function reloadTables() {

            $.each($scope.dtInstances, function (key, value) {
                if (!value)
                    return;

                value.reloadData(null, true);
            });
        }

        function initializeDataTable(filter, removeFilterStatus) {

            var oaColumns = [

                null,
                {
                    type  : 'select',
                    bRegex: true,
                    bSmart: false,
                    values: paymentFilterStatuses.map(function (val) {
                        return {
                            value: val.value == "" ? "" : "^" + val.value + "$",
                            label: val.label
                        }
                    })
                },
                {type: 'text', bRegex: true, bSmart: true},
                {type: 'date-range', bRegex: true, bSmart: true, sRangeFormat: DataTableDatepickerLabels.fromToLabels()},
                {type: 'text', bRegex: true, bSmart: true},
                null,
                null


            ];

            if (removeFilterStatus) {
                oaColumns.splice(1, 1)
            }

            var options = DTOptionsBuilder
                .fromFnPromise(function () {
                    return getFilteredData(filter);
                })
                .withColumnFilter({
                    aoColumns: oaColumns
                })

                .withBootstrap()
                .withOption('autoWidth', false)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withOption('sDom', "rt<'std_block_shadow pre_pagination'><'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('fnCreatedRow', function (nRow) {
                    return $compile(nRow)($scope);
                })
                .withButtons([]);

            return options;
        }

        function initializeDeclinedDataTable(filter) {
            var options = DTOptionsBuilder
                .fromFnPromise(function () {
                    return getFilteredData(filter);
                })
                .withColumnFilter({
                    aoColumns: [
                        null,
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'date-range', bRegex: true, bSmart: true, sRangeFormat: DataTableDatepickerLabels.fromToLabels()},
                        null
                    ]
                })
                .withBootstrap()
                .withOption('autoWidth', false)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withOption('sDom', "rt<'std_block_shadow pre_pagination'>p")
                .withOption('fnCreatedRow', function (nRow) {
                    return $compile(nRow)($scope);
                })
                .withButtons([]);

            return options;
        }

        function calculateStatus(fullData) {
            if (fullData.paymentStatus == '' && fullData.inviteStatus == 1) {
                return 'invited';
            } else if (fullData.paymentStatus == '' && fullData.inviteStatus == 3) {
                return 'refused';
            } else if (fullData.paymentStatus == 1) {
                return 'paying'
            } else if (fullData.paymentStatus == 2) {
                return 'nonPaying';
            }
        }

        function initializeDataTableColumns(removeStatus) {
            var columns = [
                DTColumnBuilder

                    .newColumn('select')
                    .withClass('select-checkbox')
                    .notSortable()
                    .renderWith(function (data) {
                        return '<dt-select-checkbox class="check-area"></dt-select-checkbox>';
                    }),
                DTColumnBuilder
                    .newColumn('paymentStatus')
                    .withClass('status-column')
                    .withOption('defaultContent', "")
                    .withTitle($translate.instant('control.status'))
                    .withOption('render', {
                        "_"      : "plain",
                        "filter" : function (data, type, fullData) {
                            return calculateStatus(fullData);
                        },
                        "display": function (data, type, fullData) {
                            return $filter('translateDistributorStatus')(calculateStatus(fullData));

                        }
                    }),
                DTColumnBuilder
                    .newColumn('name')
                    .withTitle($translate.instant('control.name'))
                    .withClass('name-column')
                    .renderWith(function (data, type, full) {
                        return full.firstName + ' ' + full.lastName
                    }),
                DTColumnBuilder
                    .newColumn('acceptanceDate')
                    .withTitle($translate.instant('control.dateOfJoin'))
                    .withClass('date-range-column')
                    .withOption('width', '150px')
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),
                DTColumnBuilder
                    .newColumn('email')
                    .withTitle($translate.instant('control.email'))
                    .withClass('email-column')
                    .renderWith(function (data, type, full) {
                        return "<a href='javascript:0' lang='en' class='mu_email_link ng-binding'>" + $filter('toString')(data) + "</a>";
                    }),
                DTColumnBuilder
                    .newColumn('income')
                    .withOption('width', '75px')
                    .withTitle($translate.instant('control.income'))
                    .withClass('noWrap')
                    .renderWith(function (data, type, full) {

                        return data != ''
                            ? DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : data
                            })
                            : '';
                    }),
                DTColumnBuilder
                    .newColumn('commission')
                    .withOption('width', '75px')
                    .withTitle($translate.instant('control.commission'))
                    .renderWith(function (data, type, full) {

                        return data != ''
                            ? DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : data
                            })
                            : '';
                    })

            ];
            if (removeStatus) {
                delete columns[1];
                return columns
            } else {
                return columns
            }
        }

        function initializeDataTableInvitedColumns() {
            return [
                DTColumnBuilder
                    .newColumn('select')
                    .withClass('select-checkbox')
                    .notSortable()
                    .renderWith(function (data) {
                        return '<dt-select-checkbox class="check-area"></dt-select-checkbox>';
                    }),

                DTColumnBuilder
                    .newColumn('name')
                    .withTitle($translate.instant('control.name'))
                    .withClass('name-column')
                    .renderWith(function (data, type, full) {
                        return full.firstName + ' ' + full.lastName
                    }),

                DTColumnBuilder
                    .newColumn('creationTime')
                    .withTitle($translate.instant('control.dateOfSendInvite'))
                    .withClass('date-range-column')
                    .withOption('width', '150px')
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),

                DTColumnBuilder
                    .newColumn('email')
                    .withTitle($translate.instant('control.email'))
                    .withClass('email-column')
                    .renderWith(function (data, type, full) {
                        return "<a href='javascript:0' lang='en' class='mu_email_link ng-binding'>" + $filter('toString')(data) + "</a>";
                    }),

                DTColumnBuilder
                    .newColumn('income')
                    .withOption('width', '75px')
                    .withTitle($translate.instant('control.income'))
                    .withClass('noWrap')
                    .renderWith(function (data, type, full) {

                        return data != ''
                            ? DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : data
                            })
                            : '';
                    }),

                DTColumnBuilder
                    .newColumn('commission')
                    .withTitle($translate.instant('control.commission'))
                    .withOption('width', '75px')
                    .renderWith(function (data, type, full) {
                        return data != ''
                            ? DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : data
                            })
                            : '';
                    }),
            ];
        }

        function initializeDeclinedDataTableColumns() {
            return [
                DTColumnBuilder
                    .newColumn('select')
                    .withClass('select-checkbox')
                    .notSortable()
                    .renderWith(function (data) {
                        return '<dt-select-checkbox class="check-area"></dt-select-checkbox>';
                    }),

                DTColumnBuilder
                    .newColumn('name')
                    .withTitle($translate.instant('control.name'))
                    .renderWith(function (data, type, full) {
                        return full.firstName + ' ' + full.lastName
                    }),
                DTColumnBuilder
                    .newColumn('declineDate')
                    .withTitle($translate.instant('control.dateOfDecline'))
                    .withOption('type', 'date-formatted')
                    .withOption('width', '150px')
                    .renderWith(function (data) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),

                DTColumnBuilder
                    .newColumn('email')
                    .withTitle($translate.instant('control.email'))
                    .renderWith(function (data, type, full) {
                        return "<a href='javascript:0' lang='en' class='mu_email_link ng-binding'>" + data + "</a>";
                    })
            ]
        }

        function getFilteredData(filter) {

            var defer = $q.defer();
            var data  = angular.copy($scope.distributors);

            data = filter
                ? $filter('filter')(data, filter, true)
                : data;

            defer.resolve(data);
            return defer.promise;


        }

        function loadDistributorData() {
            return Referral.getAll().then(function (data) {
                $scope.distributors = data;
            })
        }

        function loadTable() {

            loadDistributorData().then(function () {

                $scope.dtInstances        = {
                    all      : {},
                    paying   : {},
                    nonPaying: {},
                    invited  : {},
                    declined : {}
                };
                $scope.dtOptions          = {
                    all      : initializeDataTable(null, false),
                    paying   : initializeDataTable({paymentStatus: 1}, true),
                    nonPaying: initializeDataTable({paymentStatus: 2}, true),
                    invited  : initializeDataTable({paymentStatus: '', inviteStatus: 1}, true),
                    declined : initializeDeclinedDataTable({paymentStatus: '', inviteStatus: 3})
                };
                $scope.dtColumns          = {};
                $scope.dtColumns.all      = initializeDataTableColumns(false);
                $scope.dtColumns.payning  = initializeDataTableColumns(true);
                $scope.dtColumns.invited  = initializeDataTableInvitedColumns();
                $scope.dtColumns.declined = initializeDeclinedDataTableColumns();

            })

        }

        function loadCharts() {

            var months = $filter('translate')('global.monthNames').split(',').reverse();

            function sumFunc(prev, next) {
                return prev + next;
            }


            return Referral.getStats().then(function (data) {

                var now            = new Date();
                var currMonth      = Number(now.getMonth());
                var currMonthIndex = months.length - (currMonth) - 1;

                $scope.chart.commissions.data       = data.commissions.data;
                $scope.chart.commissions.categories = months;
                $scope.chart.commissions.total      = data.commissions.data.length ? data.commissions.data.reduce(sumFunc) : 0;
                $scope.chart.commissions.thisMonth  = data.commissions.data[currMonthIndex] || 0;
                $scope.chart.customers.data         = data.customers;
                $scope.chart.customers.categories   = months;
                $scope.chart.customers.total        = data.customers.length ? data.customers.reduce(sumFunc) : 0;
                $scope.chart.customers.thisMonth    = data.customers[currMonthIndex] || 0;
            });
        }

        (function () {
            showInitialPopup();
            loadCharts();
            loadTable();
        })()

    });
