angular.module('sinvoice-common').service("Url", function (API_URL) {

    function getNormalizedApiUrl() {

        var matchesArray = API_URL.match(/^(https|http):(\/\/|\\\\)(.*)$/);

        if (matchesArray) {
            return API_URL + '/';
        } else {
            return window.location.protocol + '//' + API_URL + '/';
        }

    }

    var pathRoot     = getNormalizedApiUrl();
    var userPathRoot = pathRoot + 'user/';
    var urlHelper    = {};

    urlHelper.auth = {
        login              : function () {
            return pathRoot + "login";
        },
        info               : function () {
            return pathRoot + "info";
        },
        logout             : function () {
            return pathRoot + "logout";
        },
        signin             : function () {
            return pathRoot + "/signin";
        },
        signout            : function () {
            return pathRoot + "/signout";
        },
        signup             : function () {
            return pathRoot + "/signup";
        },
        validateInviteToken: function (code) {
            return pathRoot + "validate-invite-token/" + code;
        },
        refuseInviteToken  : function (code) {
            return pathRoot + "refuse-invite-token/" + code;
        },
        validateApiKey     : function () {
            return pathRoot + "validate-token";
        },
        checkIfExistEmail  : function (attribute, value) {
            return pathRoot + "user/email/check-if-exist?attribute=" + attribute + "&value=" + value;
        }
    };

    urlHelper.items = {
        all   : function () {
            return pathRoot + "items";
        },
        create: function () {
            return pathRoot + "items";
        },
        update: function (id) {
            return pathRoot + "items/" + id;
        }
    };

    urlHelper.cities = {
        all: function () {
            return pathRoot + "cities";
        }
    };

    urlHelper.documents = {
        all              : function () {
            return pathRoot + "documents";
        },
        get              : function (id) {
            return pathRoot + "documents/" + id;
        },
        create           : function () {
            return pathRoot + "documents";
        },
        update           : function (id) {
            return pathRoot + "documents/" + id;
        },
        delete           : function (id) {
            return pathRoot + "documents/" + id;
        },
        changeStatus     : function (status) {
            return pathRoot + "documents/changestatus/" + status;
        },
        types            : function () {
            return pathRoot + "documents/type";
        },
        updateTypes      : function () {
            return pathRoot + "documents/type";
        },
        updateTypeComment: function (docType) {
            return pathRoot + "documents/type/" + docType + '/comment';
        },
        close            : function (id) {
            return pathRoot + "documents/" + id + "/close"
        },
        cheques          : function () {
            return pathRoot + "payments/cheques"
        },
        paymentTypes     : function () {
            return pathRoot + "payments/types"
        },
        creditTypes      : function () {
            return pathRoot + "documents/type/credit-type"
        },
        paymentBasedType : function () {
            return pathRoot + "documents/type/payment-based-type"
        },
    };

    urlHelper.export = {
        getExportToken           : function (id) {
            return pathRoot + "documents/" + id + "/token";
        },
        sendViaEmails            : function () {
            return pathRoot + "documents/send-via-email";
        },
        zippedContent            : function () {
            return pathRoot + "documents/export/pdf";
        },
        printableContent         : function (token) {
            return pathRoot + "documents/exported/" + token + "/print";
        },
        pdfContent               : function (token) {
            return pathRoot + "documents/exported/" + token + "/pdf";
        },
        mivne                    : function () {
            return pathRoot + "documents/export-mivne";
        },
        tamal                    : function () {
            return pathRoot + "documents/export-tamal";
        },
        wizcount                 : function () {
            return pathRoot + "documents/export-wizcount";
        },
        wizcountSupportedDoctypes: function () {
            return pathRoot + "documents/export-wizcount/supported-types";
        },
        showMivneSummary         : function () {
            return pathRoot + "documents/export-mivne/summary";
        },
        mivneSummaryPDFContent   : function () {
            return pathRoot + "documents/export-mivne/summary/pdf";
        },
        multipleDocuments        : function () {
            return pathRoot + "documents/export/printable";
        },
        originalAndCopy          : function (id) {
            return pathRoot + "documents/" + id + "/print/original-and-copy";
        },
        englishOriginal          : function (id) {
            return pathRoot + "documents/" + id + "/print/english-original";
        },
    };

    urlHelper.managedCustomers = {
        all               : function () {
            return pathRoot + "managed-customers";
        },
        get               : function (id) {
            return pathRoot + "managed-customers/" + id;
        },
        invitable         : function () {
            return pathRoot + "managed-customers/invitable";
        },
        invited           : function () {
            return pathRoot + "managed-customers/invited";
        },
        documents         : function (id) {
            return pathRoot + "managed-customers/" + id + "/documents";
        },
        changeStatus      : function (status) {
            return pathRoot + "managed-customers/change-status/" + status;
        },
        resendInvitations : function () {
            return pathRoot + "managed-customers/resend-invitations";
        },
        invite            : function () {
            return pathRoot + "managed-customers/invite";
        },
        comment           : function (id) {
            return pathRoot + "managed-customers/" + id + "/comment"
        },
        stats             : function (id) {
            return pathRoot + "managed-customers/" + id + "/stats"
        },
        acceptIncoming    : function (id) {
            return pathRoot + 'managed-customers/accept/' + id
        },
        declineIncoming   : function (id) {
            return pathRoot + 'managed-customers/decline/' + id
        },
        incoming          : function () {
            return pathRoot + "managed-customers/incoming-invites";
        },
        managerDetails    : function () {
            return pathRoot + "managed-customers/manager-personal-details";
        },
        removeManager     : function () {
            return pathRoot + "managed-customers/remove-manager";
        },
        checkIfExist      : function (attribute, value) {
            return pathRoot + "managed-customers/invite/check-if-exist?attribute=" + attribute + "&value=" + value;
        },
        sendSMS           : function () {
            return pathRoot + "managed-customers/send-sms";
        },
        sendSMSToInvited  : function () {
            return pathRoot + "managed-customers/invited/send-sms";
        },
        sendEmail         : function () {
            return pathRoot + "managed-customers/send-email";
        },
        sendEmailToInvited: function () {
            return pathRoot + "managed-customers/invited/send-email";
        },
        pdfContent        : function () {
            return pathRoot + "managed-customers/export/pdf"
        },
        xlsContent        : function () {
            return pathRoot + "managed-customers/export/excel"
        },
        exportFilterValues: function (id) {
            return pathRoot + "managed-customers/" + id + "/export/filter-values"
        },
        exportToPDF       : function (id) {
            return pathRoot + "managed-customers/" + id + "/export/pdf"
        },
        exportToExcel     : function (id) {
            return pathRoot + "managed-customers/" + id + "/export/excel"
        },
        documentsToExport : function (id) {
            return pathRoot + "managed-customers/" + id + "/export/filtered-documents"
        }
    };
    urlHelper.attributes       = {
        all      : function () {
            return pathRoot + "attribute";
        },
        getSingle: function (id) {
            return pathRoot + "attribute/" + id;
        },
        allValues: function (customerId) {
            return pathRoot + "customers/" + customerId + "/attribute";
        },
        getValue : function (customerId, attributeId) {
            return pathRoot + "customers/" + customerId + "/attribute/" + attributeId;
        },
        types: function () {

            return pathRoot + "attribute/types"
        }
    };

    urlHelper.customers = {
        all                  : function () {
            return pathRoot + "customers";
        },
        get                  : function (id) {
            return pathRoot + "customers/" + id;
        },
        getSubmissions       : function (id) {
            return pathRoot + "submission/types";
        },
        create               : function () {
            return pathRoot + "customers";
        },
        update               : function (id) {
            return pathRoot + "customers/" + id;
        },
        income               : function (id) {
            return pathRoot + "customers/" + id + "/income";
        },
        received             : function (id) {
            return pathRoot + "customers/" + id + "/received";
        },
        stats                : function (id) {
            return pathRoot + "customers/" + id + "/stats";
        },
        allContacts          : function (id) {
            return pathRoot + "customers/" + id + "/contacts";
        },
        createContact        : function () {
            return pathRoot + "customers/contacts";
        },
        updateContact        : function (id) {
            return pathRoot + "customers/contacts/" + id;
        },
        deleteContact        : function (id) {
            return pathRoot + "customers/contacts/" + id;
        },
        createBankAccount    : function () {
            return pathRoot + "customers/accounts";
        },
        updateBankAccount    : function (id) {
            return pathRoot + "customers/accounts/" + id;
        },
        deleteBankAccount    : function (id) {
            return pathRoot + "customers/accounts/" + id;
        },
        allRelatedCustomers  : function (customerId) {
            return pathRoot + "customers/" + customerId + "/related-customers";
        },
        createRelatedCustomer: function (customerId) {
            return pathRoot + "customers/" + customerId + "/related-customers";
        },
        updateRelatedCustomer: function (customerId, id) {
            return pathRoot + "customers/" + customerId + "/related-customers/" + id;
        },
        deleteRelatedCustomer: function (customerId, id) {
            return pathRoot + "customers/" + customerId + "/related-customers/" + id;
        },
        exportPDF            : function (token) {
            return pathRoot + "customers/export/pdf?token=" + token;
        },
        exportExcel          : function (token) {
            return pathRoot + "customers/export/excel?token=" + token;
        },
        createAddress        : function () {
            return pathRoot + "customers/addresses";
        },
        updateAddress        : function (id) {
            return pathRoot + "customers/addresses/" + id;
        },
        deleteAddress        : function (id) {
            return pathRoot + "customers/addresses/" + id;
        },
        relatedCustomersTypes: function () {
            return pathRoot + "relatedcustomerstypes";
        },
        communications       : function (id) {
            return pathRoot + "communications/" + id + "/status/-1";
        },
        getCommunication     : function (id) {
            return pathRoot + "communications/" + id;
        },
        createCommunication  : function () {
            return pathRoot + "communications";
        },
        updateCommunication  : function (id) {
            return pathRoot + "communications/" + id;
        },
        deleteCommunication  : function (id) {
            return pathRoot + "communications/" + id;
        },
        closeCommunication   : function (id) {
            return pathRoot + "communications/" + id + "/close";
        },
        paymentTerms         : function () {
            return pathRoot + "customers/payment-terms";
        },
        submissionsTypes     : function () {
            return pathRoot + "submissionstypes";
        },
        pdfContent           : function () {
            return pathRoot + "customers/export/pdf"
        },
        saveStateTable       : function (tableName) {
            return pathRoot + "columntable/statesave/" + tableName;
        },
        updateGeneral        : function (id) {
            return pathRoot + "customers/general/" + id;
        },
        getCommunicationFile : function (id, token) {
            return pathRoot + "communications/" + id + "/file/" + token;
        },
        changeStatus         : function (status) {
            return pathRoot + "customers/changestatus/" + status;
        },
        checkIfExistName     : function (attribute, value) {
            return userPathRoot + "customers/unique_name/check-if-exist?attribute=" + attribute + "&value=" + value;
        },
        sendSMS              : function () {
            return pathRoot + "customers/send-sms";
        },
        sendEmail            : function () {
            return pathRoot + "customers/send-email";
        },
        sendEmailDirectly    : function () {
            return pathRoot + "customers/send-email-directly";
        },
        documents            : function (id) {
            return pathRoot + "customers/" + id + "/documents";
        },
        xlsArchiveContent    : function () {
            return pathRoot + "customers/export/excel"
        },
        csvArchiveContent    : function () {
            return pathRoot + "customers/export/csv"
        },
        importXLS            : function () {
            return pathRoot + "customers/import/excel"
        },
        getUploadStatus      : function (token) {
            return pathRoot + "customers/import/excel/" + token
        },
        confirmImporting     : function (token) {
            return pathRoot + "customers/import/excel/" + token + "/confirm"
        }

    };

    urlHelper.stats = {
        print           : function (token) {
            return pathRoot + "user/referral/print/" + token;
        },
        income          : function () {
            return pathRoot + 'stats';
        },
        actions         : function () {
            return pathRoot + 'action-log';
        },
        users           : function () {
            return pathRoot + 'users';
        },
        onlyInvitedUsers: function () {
            return pathRoot + 'onlyInvitedUsers';
        }
    };


    urlHelper.invitations = {
        all                   : function () {
            return userPathRoot + 'referral';
        },
        nonManaged            : function () {
            return userPathRoot + 'referral/users/non-managed';
        },
        users                 : function () {
            return userPathRoot + 'referral/users';
        },
        invites               : function () {
            return userPathRoot + 'referral/invites';
        },
        paying                : function () {
            return userPathRoot + 'referral/users/paying';
        },
        nonPaying             : function () {
            return userPathRoot + 'referral/users/non-paying';
        },
        send                  : function () {
            return userPathRoot + 'referral'
        },
        stats                 : function () {
            return userPathRoot + 'referral/stats'
        },
        commissions           : function () {
            return userPathRoot + "referral/commissions"
        },
        exportCommissionsToPDF: function () {
            return userPathRoot + "referral/commissions/export/pdf"
        },
        exportCommissionsToXLS: function () {
            return userPathRoot + "referral/commissions/export/xls"
        },
        checkIfExist          : function (attribute, value) {
            return userPathRoot + "referral/invite/check-if-exist?attribute=" + attribute + "&value=" + value;
        },
        resend                : function (id) {
            return userPathRoot + "referral/invite/" + id + "/resend";
        },
        sendEmail             : function () {
            return userPathRoot + "referral/send-email";
        },
        sendEmailToInvited    : function () {
            return userPathRoot + "referral/invite/send-email";
        }
    };

    urlHelper.currencies = {
        all: function () {
            return pathRoot + "currencies";
        }
    };

    urlHelper.cities = {
        all: function () {
            return pathRoot + "cities";
        }
    };

    urlHelper.secretQuestions = {
        all: function () {
            return pathRoot + "secret-question";
        }
    };
    urlHelper.questions       = {
        categories: function () {
            return pathRoot + "question-category";
        },
        list      : function (categoryId) {
            return pathRoot + "question-category/" + categoryId + '/question';
        },
        details   : function (questionId) {
            return pathRoot + "question/" + questionId;
        },
        search    : function (query) {
            return pathRoot + "question/search?question=" + query
        },
        tooltips  : function () {
            return pathRoot + "question-tooltip";
        }
    };

    urlHelper.register = {
        firstStep : function () {
            return pathRoot + "register";
        },
        secondStep: function () {
            return pathRoot + "business/definitions";
        },
        thirdStep : function () {
            return pathRoot + "document/types";
        }
    };

    urlHelper.admin = {
        all    : function () {
            return pathRoot + "users";
        },
        update : function (id) {
            return pathRoot + "users/" + id;
        },
        actions: function (id) {
            return pathRoot + "users/actions/" + id;
        }
    };

    urlHelper.comments = {
        all   : function () {
            return pathRoot + "comments";
        },
        create: function () {
            return pathRoot + "comments";
        },
        update: function (id) {
            return pathRoot + "comments/" + id;
        }
    };


    urlHelper.templates = {
        get       : function () {
            return pathRoot + "template";
        },
        update    : function () {
            return pathRoot + "template";
        },
        getFiles  : function () {
            return pathRoot + "template/files";
        },
        updateLogo: function () {
            return pathRoot + "template/logo";
        }
    };

    urlHelper.business = {
        search: function () {
            return pathRoot + "users/business/search"
        },
        checkIfNumberExist: function (number) {
            return pathRoot + "users/business/check-if-exist?businessNumber="+number
        }

    };

    urlHelper.profile  = {
        changepass                 : function () {
            return pathRoot + "changepass";
        },
        profile                    : function () {
            return pathRoot + "users/profile";
        },
        update                     : function () {
            return pathRoot + "users/profile";
        },
        updateLogo                 : function () {
            return pathRoot + "users/updateLogo";
        },
        businessTypes              : function () {
            return pathRoot + "users/business/type"
        },
        businessDefinitions        : function () {
            return pathRoot + "users/business/definitions";
        },
        businessInfo               : function () {
            return pathRoot + "users/business/info";
        },
        disableDistributorsPopup   : function () {
            return pathRoot + "users/profile/distributors-popup"
        },
        referralAccounts           : function () {
            return pathRoot + 'user/referral/account';
        },
        setPaymentType             : function () {
            return userPathRoot + "referral/account/settings/set-payment-type"
        },
        settings                   : function () {
            return userPathRoot + "referral/account/settings"
        },
        mailSettings               : function () {
            return pathRoot + "users/profile/mail"
        },
        bookManagementSettings     : function () {
            return pathRoot + "users/profile/book-management"
        },
        printSettings              : function () {
            return pathRoot + "users/profile/print-settings"
        },
        wizcountSettings           : function () {
            return pathRoot + "users/profile/wizcount-settings"
        },
        subscriptionSettings       : function () {
            return pathRoot + "users/profile/subscription-settings"
        },
        setSendCopyEnabledByDefault: function () {
            return pathRoot + "users/profile/send-copy-default-value"
        },

    };
    urlHelper.contacts = {
        sendForm: function () {
            return pathRoot + 'inquiry'
        }
    };

    urlHelper.payments = {
        yaadpayCredentials: function () {
            return pathRoot + "payment/yaadpay/credentials"
        }
    };

    urlHelper.subscriptions = {
        current: function () {
            return pathRoot + "subscription/current"
        },
        change : function (id) {
            return pathRoot + "subscription/" + id + "/change"
        },
        all    : function () {
            return pathRoot + "subscription"
        },
        cancelSubscription : function () {
            return pathRoot + "subscription/current/cancel"
        },
        sendRequestAboutCancel : function () {
            return pathRoot + "subscription/current/send-request-about-cancel"
        }
    };

    urlHelper.widgets = {
        getSettings   : function () {
            return pathRoot + "widgets";
        },
        updateSettings: function () {
            return pathRoot + "widgets";
        }
    };

    urlHelper.resetPass = {
        validate: function (token) {
            return pathRoot + "resetPass/" + token;
        },
        confirm : function () {
            return pathRoot + "resetPass/reset";
        },
        generate: function () {
            return pathRoot + "resetPass/generate";
        }
    };

    urlHelper.banks = {
        all: function () {
            return pathRoot + "banks";
        }
    };

    urlHelper.reports = {
        all                             : function () {
            return pathRoot + "reports"
        },
        get                             : function (id) {
            return pathRoot + "reports/" + id
        },
        allByType                       : function (type) {
            return pathRoot + "reports/type/" + type
        },
        updateHelpPopupStatus           : function () {
            return pathRoot + "users/profile/reports-help-popup"
        },
        sendToCustomer                  : function (id) {
            return pathRoot + "reports/" + id + "/send-to-customer";
        },
        printableContent                : function (id) {
            return pathRoot + "reports/" + id + "/export/print";
        },
        create                          : function () {
            return pathRoot + "reports"
        },
        pdfContent                      : function (id) {
            return pathRoot + "reports/" + id + "/export/pdf";
        },
        filterValues                    : function (type) {
            return pathRoot + "reports/type/" + type + "/filter-values"
        },
        printableContentForCustomerBook : function () {
            return pathRoot + "reports/customer-book/export/print";
        },
        pdfContentForCustomerBook       : function () {
            return pathRoot + "reports/customer-book/export/pdf";
        },
        xlsArchiveContentForCustomerBook: function (id) {
            return pathRoot + "reports/customer-book/export/excel";
        },
        customerBook                    : function () {
            return pathRoot + "reports/customer-book"
        },
        sendCustomerBookToCustomers     : function () {
            return pathRoot + "reports/customer-book/send-to-customer";
        },
        customerBookFilterValues        : function () {
            return pathRoot + "reports/customer-book/filter-values"
        },
        orderBook                       : function () {
            return pathRoot + "reports/orders-book"
        },
        pdfContentForOrdersBook         : function (id) {
            return pathRoot + "reports/orders-book/export/pdf";
        },
        xlsArchiveContentForOrdersBook  : function (id) {
            return pathRoot + "reports/orders-book/export/excel";
        },
        printableContentForOrdersBook   : function (id) {
            return pathRoot + "reports/orders-book/export/print";
        },
        sendOrdersBookToCustomers       : function (id) {
            return pathRoot + "reports/orders-book/send-to-customer";
        },
        csvArchiveContent               : function (id) {
            return pathRoot + "reports/" + id + "/export/csv";
        },
        xlsArchiveContent               : function (id) {
            return pathRoot + "reports/" + id + "/export/excel";
        },
        printableContentForCommissions  : function () {
            return pathRoot + "reports/commissions/export/print"
        },
        pdfContentForCommissions        : function () {
            return pathRoot + "reports/commissions/export/pdf"
        },
        xlsArchiveContentForCommissions : function () {
            return pathRoot + "reports/commissions/export/excel"
        }

    };

    urlHelper.dev = {
        documentPdfContentForAllTemplates: function () {
            return pathRoot + "dev/documents/content-for-all-templates"
        }
    };

    return urlHelper;
});