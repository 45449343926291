(function () {
    'use strict';

    angular.module('sinvoice-desktop')
        .factory('DashboardRestService', function ($http, $q, API, Url) {
            var Service = {

                getWidgets: function () {
                    var deferred = $q.defer();

                    API.get(Url.widgets.getSettings())
                        .success(function (data) {

                            deferred.resolve(data);
                        });

                    return deferred.promise;
                }
            };

            return Service;
        });

})();