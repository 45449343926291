/**
 * Created by dbichenko on 07.11.2016.
 */
angular.module('sinvoice-desktop').factory('Report', function (API, Url, $q, $translate, TaxPeriodProvider) {

    return {
        createReport: function (type, options) {
            var deferred = $q.defer();

            options.type = type;

            API.post(Url.reports.create(), options)
                .success(function (data) {
                    deferred.resolve(data);
                });

            return deferred.promise;
        },

        getSingle                         : function (id, type) {
            var deferred = $q.defer();

            API.get(Url.reports.get(id, type))
                .success(function (data) {
                    deferred.resolve(data);
                });

            return deferred.promise;
        },
        getAll                            : function (reportType) {
            var deferred = $q.defer();

            API.get(Url.reports.all())
                .success(function (data) {
                    if (reportType) {
                        data = _.filter(data, {type: reportType});
                    }
                    deferred.resolve(data);
                });

            return deferred.promise;
        },
        getByType                         : function (type) {
            var deferred = $q.defer();

            API.get(Url.reports.allByType(type))
                .success(function (data) {
                    deferred.resolve(data);
                });

            return deferred.promise;
        },
        updateHelpPopupStatus             : function (status) {
            var deferred = $q.defer();

            API.post(Url.reports.updateHelpPopupStatus(), {status: status}).success(function (data) {
                deferred.resolve(data)
            });

            return deferred.promise;
        },
        sendToCustomers                   : function (id, customers, subject, message, sendCopy) {
            var deferred = $q.defer();
            API.post(Url.reports.sendToCustomer(id), {
                customers: customers,
                subject  : subject,
                message  : message,
                sendCopy : sendCopy
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getPrintableContent               : function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.printableContent(id), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getPDFContent                     : function (token) {
            var deferred = $q.defer();
            API.get(Url.reports.pdfContent(token), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getFilterValues                   : function (type) {
            var deferred = $q.defer();
            API.get(Url.reports.filterValues(type), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getPrintableContentForCustomerBook: function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.printableContentForCustomerBook(), {customer: id})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getPDFContentForCustomerBook      : function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.pdfContentForCustomerBook(), {customer: id})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getCustomerBookData               : function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.customerBook(), {customer: id})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        sendCustomerBookToCustomers         : function (customerId, customers, subject, message, sendCopy) {
            var deferred = $q.defer();
            API.post(Url.reports.sendCustomerBookToCustomers(), {
                customer: customerId,
                customers  : customers,
                subject : subject,
                message : message,
                sendCopy: sendCopy
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getCustomerBookFilterValues       : function () {
            var deferred = $q.defer();
            API.get(Url.reports.customerBookFilterValues())
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getOrderBookData                  : function () {
            var deferred = $q.defer();
            API.get(Url.reports.orderBook())
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getPDFContentForOrdersBook        : function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.pdfContentForOrdersBook(), {order: id})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getXLSArchiveContentForOrdersBook : function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.xlsArchiveContentForOrdersBook(), {order: id})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getPrintableContentForOrdersBook: function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.printableContentForOrdersBook(), {order: id})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        sendOrdersBookToCustomers         : function (id, customers, subject, message, sendCopy) {
            var deferred = $q.defer();
            API.post(Url.reports.sendOrdersBookToCustomers(), {
                order   : id,
                customers  : customers,
                subject : subject,
                message : message,
                sendCopy: sendCopy
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getCSVArchiveContent: function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.csvArchiveContent(id), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getXLSArchiveContent: function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.xlsArchiveContent(id), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getXLSArchiveContentForCustomerBook: function (id) {
            var deferred = $q.defer();
            API.get(Url.reports.xlsArchiveContentForCustomerBook(), {customer: id})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getXLSArchiveContentForCommissions: function (ids) {
            var deferred = $q.defer();
            API.get(Url.reports.xlsArchiveContentForCommissions(), {users: ids.join(',')})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getPDFContentForCommissions      : function (ids) {
            var deferred = $q.defer();
            API.get(Url.reports.pdfContentForCommissions(), {users: ids.join(',')})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getPrintableContentForCommissions: function (ids) {
            var deferred = $q.defer();
            API.get(Url.reports.printableContentForCommissions(), {users: ids.join(',')})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
    }

});