/**
 * Created by abichenko on 31.10.17.
 */
angular.module('sinvoice-mobile').controller('paginatorBlockController', function ($scope) {


    $scope.getLastPage = function () {
        return $scope.pages[$scope.pages.length - 1];
    };

    $scope.getFirstPage = function () {
        return $scope.pages[0];
    };

    $scope.$watch('currentPage + perPage', function () {
        $scope.onChange($scope.currentPage, $scope.perPage)
    });


    $scope.activatePage = function (page) {
        $scope.currentPage = page;
    };

    $scope.$watchCollection('elements', function (newVal) {

        var pageCount = newVal.length / $scope.perPage;

        $scope.pages = _.range(1, pageCount + 1);

    });

    (function () {
        if (!$scope.currentPage) {
            $scope.currentPage = 1;
        }
    })();
});