angular.module('sinvoice-desktop').controller('myDocsController', function ($scope, $translate, Document, UserSubscription, DocumentTypeConstants) {


    $scope.spinner        = {enabled: true};
    $scope.documentTypes  = {};
    $scope.backupDocTypes = {};
    var documentTypesArr  = [];

    $scope.isSubmitButtonDisabled = function () {
        return UserSubscription.isCheckInProgress();
    };


    $scope.isSaveAllowed = function () {
        return new Promise(function (resolve, reject) {

            if ($scope.profileForm.$invalid) {
                return reject(false);
            }

            return UserSubscription.checkSubscriptionForEditAction().then(function () {
                return resolve(true)
            });

        })
    };

    function arrToObject(array, callback) {
        var newObj = {};
        for (var key in array) {
            newObj[array[key].docType] = array[key];
            if (typeof callback == 'function' && callback) {
                newObj[array[key].docType] = callback(newObj[array[key].docType]);
            }
        }
        return newObj;
    }

    $scope.saveDocTypes = function () {

        $scope.spinner.enabled = true;

        return Document
            .updateDocTypes($scope.documentTypes)
            .then(function (data) {
                angular.forEach(data.data, function (value, key) {
                    value.label               = $translate.instant('documents.types.' + value.docType);
                    $scope.documentTypes[key] = value;
                });
                angular.copy($scope.documentTypes, $scope.backupDocTypes);
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            });
    };

    $scope.cancel = function () {
        angular.copy($scope.backupDocTypes, $scope.documentTypes);
    }
    function loadDocTypes() {
        var orderedDocTypes = [
           DocumentTypeConstants.TYPE_ORDER,
           DocumentTypeConstants.TYPE_PRICE_QUOTE,
           DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
           DocumentTypeConstants.TYPE_PERFORM,
           DocumentTypeConstants.TYPE_TAX_INVOICE,
           DocumentTypeConstants.TYPE_RECEIPT,
           DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT,
           DocumentTypeConstants.TYPE_DELIVERY_NOTE,
           DocumentTypeConstants.TYPE_RETURN_FORM,
           DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT_CREDIT,
           DocumentTypeConstants.TYPE_TAX_INVOICE_CREDIT,
           DocumentTypeConstants.TYPE_RECEIPT_CREDIT,
           DocumentTypeConstants.TYPE_BALANCE_ADJUSTMENT
        ];

        return Document
            .getDocTypes()
            .then(function (data) {
                angular.forEach(data, function (value, key) {
                    value.label               = $translate.instant('documents.types.' + value.docType);
                    value.order               = orderedDocTypes.indexOf(value.docType);
                    $scope.documentTypes[key] = value;
                });
                documentTypesArr     = _.sortBy($scope.documentTypes, [function (o) {
                    return o.order;
                }]);
                $scope.documentTypes = arrToObject(documentTypesArr, function (o) {
                    if (o.order == -1) {
                        o = null;
                    }
                    return o;
                });
                angular.copy($scope.documentTypes, $scope.backupDocTypes);
            })
            .then(function () {
                $scope.spinner.enabled = false;
            })
    }

    (function () {
        loadDocTypes();
    })()
});