angular.module('sinvoice-desktop')

    .run(function ($rootScope, $location, $state, $timeout, Auth, onInitContainer) {

        function getStateToRedirect(toStateName) {
            var unsecureStates = ['register', 'login', 'request', 'refuse', 'resetPass', 'export.pdf', 'export.print'];

            if (!Auth.isAuthenticated() && (unsecureStates.indexOf(toStateName) === -1)) {
                return 'login';
            }

            if (Auth.isAuthenticated() && !Auth.registrationIsComplete() && toStateName !== 'register' && toStateName !== 'login') {
                return 'register';
            }

            if (Auth.isAuthenticated() && toStateName === 'login') {
                return 'control.dashboard';
            }
        }

        onInitContainer.onInitSequenceIsCompleted(function () {

            var stateToRedirect = getStateToRedirect($state.current.name);
            if (stateToRedirect) {
                $state.go(stateToRedirect);
            }

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                var stateToRedirect = getStateToRedirect(toState.name);
                if (stateToRedirect) {
                    event.preventDefault();
                    $timeout(function () {
                        $state.go(stateToRedirect);
                    })
                }
            })
        });
    })

    .config(function ($stateProvider, $urlRouterProvider, $httpProvider) {
        $httpProvider.interceptors.push('responseObserver');

        $urlRouterProvider.otherwise(function ($injector, $location) {
            var $state = $injector.get('$state');
            $state.go('login');
        });

        var pages = [
            {
                state: 'login',
                url  : '/login',
                title: 'Super Invoice Login'
            },
            {
                state: 'register',
                url  : '/register?invite?refId',
                title: 'Super Invoice Register'
            },
            {
                state     : 'refuse',
                controller: 'refuseController',
                url       : '/refuse/:inviteToken',
                title     : 'Refuse invitation'
            },
            {
                state: 'request',
                url  : '/request',
                title: 'Super Invoice Forgotten Password'
            },
            {
                state: 'resetPass',
                url  : '/resetPassword/:token',
                title: 'Super Invoice Reset Password'
            },
            {
                state      : 'help',
                url        : '/help',
                abstract   : true,
                title      : 'Super Invoice Help',
                templateUrl: "./desktop/components/help/help.html",
                controller : "helpController"
            },

            {
                state      : 'help.contactus',
                url        : '/contact',
                title      : 'Contact us',
                templateUrl: "./desktop/components/help/contactus.html",
                controller : "contactusController"
            },
            {
                state      : 'help.details',
                url        : '/show/:id',
                title      : 'Super Invoice Help',
                templateUrl: "./desktop/components/help/details.html",
                controller : "helpDetailsController"
            },
            {
                state      : 'help.category',
                url        : '/:category?opened=',
                title      : 'Super Invoice Help',
                templateUrl: "./desktop/components/help/category.html",
                controller : "categoryController"
            },

            {
                state      : 'help.search',
                url        : '/search/:query',
                title      : 'Super Invoice Help',
                templateUrl: "./desktop/components/help/search.html",
                controller : "searchController"
            },

            {
                state      : 'appsearch',
                url        : '/business/search/:query',
                title      : 'Super Invoice Definitions',
                controller : "topnavSearchResultController",
                templateUrl: "./desktop/components/topnav/topnav-search-result.html"
            },

            {
                state: 'definitions',
                url  : '/definitions',
                title: 'Super Invoice Definitions'
            },
            {
                state: 'management',
                url  : '/management',
                title: 'Super Invoice Management'

            },
            {
                state: 'documents',
                url  : '/documents/?customer',
                title: 'Super Invoice Documents',

            },
            {
                state      : 'export',
                url        : '/export/:token',
                abstract   : true,
                templateUrl: "./desktop/components/export/index.html",
                controller : "exportIndexController",
                title      : 'Super Invoice Documents',

            },
            {
                state      : 'export.print',
                url        : '/print',
                templateUrl: "./desktop/components/export/print.html",
                controller : "exportPrintController",
                title      : 'Super Invoice Documents',

            },
            {
                state      : 'export.pdf',
                url        : '/pdf',
                templateUrl: "./desktop/components/export/pdf.html",
                controller : "exportPdfController",
                title      : 'Super Invoice Documents',
            },
            {
                state: 'items',
                url  : '/items',
                title: 'Super Invoice Items',
            },

            {
                state: 'newsletters',
                url  : '/newsletters',
                title: 'Super Invoice Newsletters',

            },
            {
                state   : 'reports',
                url     : '/reports',
                subpages: [
                    {
                        state      : 'reports.index',
                        url        : '/index?report',
                        title      : 'Super Invoice Reports',
                        subpage    : 'reports',
                        templateUrl: "./desktop/components/reports/types/index.html",
                        controller : "reportsIndexController",
                    },
                    {
                        state      : 'reports.accounting',
                        url        : '/accounting?id?customers?docTypes?from?to',
                        title      : 'Super Invoice Reports Income For Accounting',
                        subpage    : 'reports',
                        templateUrl: "./desktop/components/reports/types/incomeForAccounting/income-for-accounting.html",
                        controller : "incomeForAccountingController",
                    },
                    {
                        state      : 'reports.incomeanddebts',
                        url        : '/incomeanddebts?id?customers?docTypes?from?to',
                        title      : 'Super Invoice Income And Debts Reports',
                        subpage    : 'reports',
                        templateUrl: "./desktop/components/reports/types/incomeAndDebts/income-and-debts.html",
                        controller : "incomeAndDebtsController",
                    },
                    {
                        state      : 'reports.vatincome',
                        url        : '/vatincome?id?from?to',
                        title      : 'Super Invoice Vat Income Reports',
                        subpage    : 'reports',
                        templateUrl: "./desktop/components/reports/types/vatIncome/vat-income.html",
                        controller : "vatIncomeController",
                    },
                    {
                        state      : 'reports.incomeitems',
                        url        : '/incomeitems?id?items?from?to',
                        title      : 'Super Invoice Income By Items Reports',
                        subpage    : 'reports',
                        templateUrl: "./desktop/components/reports/types/incomeItems/income-items.html",
                        controller : "incomeItemsController",
                    },
                    {
                        state      : 'reports.cheques',
                        url        : '/cheques?id?from?to?statuses',
                        title      : 'Super Invoice Cheques Reports',
                        subpage    : 'reports',
                        templateUrl: "./desktop/components/reports/types/cheque/cheques.html",
                        controller : "chequesController",
                    },
                    {
                        state      : 'reports.customersbook',
                        url        : '/customersbook',
                        title      : 'Super Invoice Customers Book Reports',
                        subpage    : 'reports',
                        templateUrl: "./desktop/components/reports/types/customersBook/customers-book.html",
                        controller : "customersBookController",
                    },
                    {
                        state      : 'reports.commissions',
                        url        : '/commissions',
                        title      : 'Super Invoice Commisions Report',
                        subpage    : 'reports',
                        templateUrl: "./desktop/components/reports/types/commissions.html",
                        controller : "commissionsReportController",
                    },
                    {
                        state      : 'reports.ordersbook',
                        url        : '/ordersbook',
                        title      : 'Super Invoice Orders Book Report',
                        subpage    : 'reports',
                        templateUrl: "./desktop/components/reports/types/ordersBook/orders-book.html",
                        controller : "ordersBookReportController",
                    }
                ]
            },
            {
                state   : 'control',
                url     : '/control',
                subpages: [
                    //Hide this route/link according of SUP-2185
/*                    {
                        state  : 'control.backup',
                        url    : '/backup',
                        title  : 'Super Invoice Fast Backup',
                        subpage: 'control',
                    },*/
                    {
                        state  : 'control.export',
                        url    : '/export',
                        title  : 'Super Invoice Export',
                        subpage: 'control',
                    },
                    {
                        state  : 'control.dashboard',
                        url    : '/dashboard',
                        title  : 'Super Invoice Dashboard',
                        subpage: 'control',
                    }
                ]
            },
            {
                state   : 'distributors',
                url     : '/distributors',
                title   : 'Super Invoice Distributors',
                subpages: [
                    {
                        state      : 'distributors.dashboard',
                        url        : '/dashboard',
                        title      : 'Super Invoice Fast Backup',
                        subpage    : 'distributors',
                        controller : 'distributorsDashboardController',
                        templateUrl: "./desktop/components/distributors/dashboard/distributorsDashboard.html",
                    },
                    {
                        state      : 'distributors.commissions',
                        url        : '/commissions',
                        title      : 'Super Invoice Commisions',
                        subpage    : 'distributors',
                        controller : 'commissionsController',
                        templateUrl: "./desktop/components/distributors/commissions/commissions.html",
                    },
                    {
                        state      : 'distributors.distributorSettings',
                        url        : '/distributor_settings',
                        title      : 'Super Invoice Distributors Settings',
                        subpage    : 'distributors',
                        controller : 'distributorSettingsController',
                        templateUrl: "./desktop/components/distributors/distributorSettings/distributorSettings.html",
                    },

                ]
            },
            {
                state   : 'customers',
                url     : '/customers',
                subpages: [
                    {
                        state  : 'customers.my',
                        url    : '/my',
                        title  : 'Super Invoice My Customers',
                        subpage: 'customers',
                    },
                    {
                        state  : 'customers.representatives',
                        url    : '/representatives',
                        title  : 'Super Invoice My Representatives',
                        subpage: 'customers',
                    },
                    {
                        state  : 'customers.main',
                        url    : '/main',
                        title  : 'Super Invoice My Representatives Main page',
                        subpage: 'customers',
                    },
                    {
                        state  : 'customers.invited',
                        url    : '/invited',
                        title  : 'Super Invoice My Representatives Invited page',
                        subpage: 'customers',
                        submenu: 'representatives',
                    },
                ]
            },
            {
                state   : 'profile',
                url     : '/profile',
                subpages: [
                    {
                        state  : 'profile.edit',
                        url    : '/edit',
                        title  : 'Edit My Account',
                        subpage: 'profile',
                    },
                    {
                        state  : 'profile.business-definition',
                        url    : '/business-definition',
                        title  : 'Business definition',
                        subpage: 'profile',
                    },
                    {
                        state  : 'profile.subscription',
                        url    : '/subscription',
                        title  : 'My subscription',
                        subpage: 'profile',
                    },
                    {
                        state  : 'profile.business-info',
                        url    : '/business-info',
                        title  : 'Business info',
                        subpage: 'profile',
                    },
                    {
                        state  : 'profile.book-management',
                        url    : '/book-management',
                        title  : 'Book Management',
                        subpage: 'profile',
                    },
                    {
                        state  : 'profile.change-password',
                        url    : '/change-password',
                        title  : 'Change password',
                        subpage: 'profile'
                    },
                    {
                        state  : 'profile.mail',
                        url    : '/mail',
                        title  : 'Mail settings',
                        subpage: 'profile'
                    },
                    {
                        state  : 'profile.export',
                        url    : '/export',
                        title  : 'Export Settings',
                        subpage: 'profile'
                    },
                    {
                        state  : 'profile.my-docs',
                        url    : '/my-docs',
                        title  : 'My documents',
                        subpage: 'profile'
                    },
                    {
                        state  : 'profile.templates',
                        url    : '/templates',
                        title  : 'Documents template',
                        subpage: 'profile'
                    },
                    {
                        state  : 'profile.print-settings',
                        url    : '/print-settings',
                        title  : 'Print Settings',
                        subpage: 'profile'
                    },
                ],
            },
            {
                state: 'payment',
                url  : '/payment',
                title: 'Super Invoice Payment',
            },
        ];


        var makeState = function (page, parent) {


            var template = parent
                ? parent.template || parent.state
                : page.template || page.state;

            var genericTemplateUrl = parent
                ? './desktop/components/' + parent.state + '/' + template + '.html'
                : './desktop/components/' + page.state + '/' + template + '.html';

            var options = {
                abstract   : (page.subpages ? true : undefined),
                url        : page.url || '/' + page.state,
                templateUrl: page.templateUrl || genericTemplateUrl,
                params     : {
                    obj: null
                },
                controller : page.controller || template + 'Controller',
                data       : {
                    title  : page.title || page.state.charAt(0).toUpperCase() + page.state.slice(1) + ' | Super Invoice',
                    subpage: page.subpage || '',
                    submenu: page.submenu || ''
                }

            };

            if (page.resolve) {
                options.resolve = page.resolve
            }

            if (parent && page.template) {
                options.templateUrl = './desktop/' + parent.state + '/' + page.template + '/' + page.template + '.html';
            }

            $stateProvider.state(page.state, options);
        };

        pages.forEach(function (page, i) {
            makeState(page);
            if (page.subpages) {
                page.subpages.forEach(function (subpage, k) {
                    makeState(subpage, page);
                });
            }
        });
    })

    //.config(function ($routeProvider, $httpProvider) {
    //    $httpProvider.interceptors.push('responseObserver');
    ///* other configuration, like routing */
    //})

    .factory('responseObserver', function responseObserver($q, $window, $location, $rootScope, $localStorage) {
        return {
            'responseError': function (errorResponse) {
                switch (errorResponse.status) {
                    case 401:
                        //alert("Sorry, it's seems you are not logged in, you will be redirected to the log-in page!");
                        $localStorage.remove('userInfo');
                        $rootScope.currentUser = null;
                        $location.path('/login');
                        //$location.path('/logout');
                        break;
                    case 403:
                        alert("Sorry, it's seems you don't have access to this page");
                        //$window.location = '/login';
                        break;
                    case 500:

                        alert("Sorry! It's seems that there is an server error, please report this to our support!");
                        //$window.location = './500.html';
                        break;
                }
                return $q.reject(errorResponse);
            }
        };
    });
