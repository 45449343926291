/**
 * Created by abichenko on 14.09.17.
 */
angular.module('sinvoice-desktop').factory('IncomeForAccountingHelper', function (DocumentExport, Document, $q) {


    var wizcountSupportedDocTypes;

    var $loadingPromise = $q.all([
        Document.wizcountSupportedDoctypes().then(function (data) {
            wizcountSupportedDocTypes = data.content;
        })
    ]);

    return {
        $loadingPromise           : $loadingPromise,
        exportReportToMivne       : exportReportToMivne,
        exportReportToTamal       : exportReportToTamal,
        exportReportToWizcount    : exportReportToWizcount,
        isMivneExportingAllowed   : isMivneExportingAllowed,
        isWizcountExportingAllowed: isWizcountExportingAllowed,
        isTamalExportingAllowed   : isTamalExportingAllowed
    };

    function isTamalExportingAllowed(report) {
        return isMivneExportingAllowed(report);
    }

    function isMivneExportingAllowed(report) {

        var excludedTypes = DocumentExport.mivneExcludedTypes;

        if (!report.content.docs)
            return false;

        return !!report.content.docs
            .filter(function (document) {
                return !_.includes(excludedTypes, document.docType)
            })
            .length;
    }


    function isWizcountExportingAllowed(report) {

        if (!report.content.docs)
            return false;

        return !!report.content.docs
            .filter(function (document) {
                return _.includes(wizcountSupportedDocTypes, document.docType)
            })
            .length
    }

    function exportReportToTamal(report) {

        var excludedTypes = DocumentExport.mivneExcludedTypes;

        var ids = report.content.docs
            .filter(function (document) {
                return !_.includes(excludedTypes, document.docType)
            })
            .map(function (document) {
                return document.id;
            });

        DocumentExport.downloadAsTamal(ids)

    }

    function exportReportToWizcount(report) {

        var ids = report.content.docs
            .filter(function (document) {
                return _.includes(wizcountSupportedDocTypes, document.docType)
            })
            .map(function (document) {
                return document.id;
            });

        DocumentExport.downloadAsWizcount(ids);
    }


    function exportReportToMivne(report) {

        var excludedTypes = DocumentExport.mivneExcludedTypes;

        var ids = report.content.docs
            .filter(function (document) {
                return !_.includes(excludedTypes, document.docType)
            })
            .map(function (document) {
                return document.id;
            });

        DocumentExport.showMivneSummary(ids);
    }


});
