/**
 * Created by abichenko on 20.03.18.
 */
angular.module('sinvoice-desktop').factory('TopnavSearch', function () {

    var currentString;

    return {
        setCurrentQueryString: function (string) {
            currentString = string;
        },
        getCurrentQueryString: function () {
            return currentString;
        }
    }
})