/**
 * Created by dbichenko on 10.11.2016.
 */
angular.module('sinvoice-common').factory('DocumentFiltersValues', function ($filter, $translate, DocumentConstants) {

    return {
        getFilterValueFromLabel: getFilterValueFromLabel,
        getStatuses            : getStatuses,
        getCustomerFilterValues: getCustomerFilterValues,
        getTypeFilterValues    : getTypeFilterValues
    };

    function getFilterValueFromLabel(label, customValue) {

        return {
            value: angular.isDefined(customValue) ? customValue : label,
            label: label
        }
    }


    function getStatuses() {
        return [
            {
                value: '',
                label: $filter('translateDocStatus')('')
            },
            {
                value: DocumentConstants.STATUS_OPEN,
                label: $filter('translateDocStatus')(DocumentConstants.STATUS_OPEN)
            },
            {
                value: DocumentConstants.STATUS_CLOSED,
                label: $filter('translateDocStatus')(DocumentConstants.STATUS_CLOSED)
            }
        ]
    }

    function getCustomerFilterValues(existingDocuments, valueKey) {
        var filterValues = [];

        var customers = existingDocuments
            .filter(function (document) {
                return document.customer && document.customer.name;
            })
            .map(function (document) {
                return document.customer;
            });

        customers = _.uniqBy(customers, 'id');


        customers.forEach(function (customer) {

            var customValue;
            if (valueKey) {
                customValue = customer[valueKey];
            }

            filterValues.push(getFilterValueFromLabel(customer.name, customValue));
        });


        return filterValues;
    }

    function getTypeFilterValues(existingDocuments, valueKey) {

        var filterValues = [];


        filterValues.push({'value': '', label: $translate.instant('documents.table.statuses.all')});
        var docTypes = _.uniqBy(existingDocuments.map(function (document) {
            return document.type;
        }), 'id');


        angular.forEach(docTypes, function (type, key) {

            var customValue;

            if (valueKey) {
                customValue = type[valueKey];
            }


            filterValues.push(getFilterValueFromLabel($filter('translateDocType')(type.docType), customValue));
        });

        return filterValues;
    }

});