/**
 * Created by dbichenko on 17.08.2016.
 */
angular.module('sinvoice-desktop')

    .service('BusinessLogo', function (Template) {

        this.getLogo = function () {
            return Template.getHebrewLogo();
        };

        this.uploadLogo = function (content) {
            return Template.updateHebrewLogo({logoHe: content});
        };

        return this;
    })
    .service('IncomingInvites', function (API, Url, $q, ManagedCustomers, Modal, $translate, promiseHelper) {

        this.processIncomingInvites = process;

        return this;

        function createPopupChain(data) {

            var callbacks = [];

            data.forEach(function (value) {

                var name    = value.invitedBy.firstName + ' ' + value.invitedBy.lastName;
                var message = value.message;
                var text    = $translate.instant('global.incoming_invites.text', {name: name, message: message});

                var callback = function () {

                    var defer = $q.defer();

                    function approvedInvitation() {
                        return accept(value.id).then(function () {
                            defer.reject();
                        });
                    }

                    function rejectInvitation() {
                        return decline(value.id).then(function () {
                            defer.resolve();
                        });
                    }

                    Modal.customDialog({
                        templateUrl   : './desktop/components/common/invite-manager-popup.html',
                        closeButton   : false,
                        size          : 'medium',
                        headerUrl     : './desktop/components/common/invite-manager-popup-header.html',
                        onEscape      : false,
                        className     : 'distibutorsPopWelcome',
                        scopeVariables: {
                            okFunc    : approvedInvitation,
                            cancelFunc: rejectInvitation,
                            message   : text
                        }
                    });

                    return defer.promise;
                };

                callbacks.push(callback);
            });
            promiseHelper.createPromiseChain(callbacks);
        }

        function process() {
            getList().then(function (data) {
                return createPopupChain(data)
            });
        }

        function getList() {
            return ManagedCustomers.getIncomingInvites();
        }

        function decline(id) {
            return ManagedCustomers.declineIncomingInvite(id);
        }

        function accept(id) {
            return ManagedCustomers.acceptIncomingInvite(id);
        }


    })



    .service('monthsFromToday', ['$filter', function ($filter) {
        return {
            get: function () {
                var monthNames = $filter('translate')('global.monthNames').split(',');

                var presentMonth = new Date().getMonth() + 1;
                var months       = monthNames.slice(0, presentMonth).reverse();
                return months.concat(
                    monthNames
                        .slice(presentMonth)
                        .reverse()
                );
            }
        };
    }])



    .service('BankProvider', function (Bank, promiseHelper) {

        var banks = [];

        function filterBanks(bank) {
            return bank.type != 'custom';
        }

        this.getAll = function (includeCustom) {

            if (!banks.length)
                return Bank.getAll().then(function (data) {
                    banks = data;

                    var filteredBanks = includeCustom
                        ? angular.copy(data)
                        : data.filter(filterBanks);

                    return filteredBanks;
                });
            else {

                var filteredBanks = includeCustom
                    ? angular.copy(banks)
                    : banks.filter(filterBanks);

                return promiseHelper.resolvedPromise(filteredBanks)
            }

        };


        return this;

    })
    .factory('City', function ($q, Url, API) {
        return {
            getAll: function () {

                var defer = $q.defer();

                API.get(Url.cities.all(), {})
                    .success(function (data) {
                        defer.resolve(data);
                    })
                    .error(function (error) {
                        defer.reject(data);
                    });

                return defer.promise;

            }
        }
    })

    .constant('config', {
        dateFormat      : 'MM/DD/YYYY',
        dateFormatNormal: 'DD/MM/YYYY'
    });