/**
 * Created by abichenko on 27.09.17.
 */
angular.module('sinvoice-desktop').factory('HelpMenuService', function () {

    var currentCategory = null;

    return {
        setCurrentCategory: function (category) {
            currentCategory = category
        },
        getCurrentCategory: function () {
            return currentCategory
        }
    }


});