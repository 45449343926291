/**
 * Created by abichenko on 08.12.17.
 */
angular.module('sinvoice-mobile').directive('searchBlock', function () {

    return {
        controller : 'searchBlockController',
        templateUrl: './mobile/layouts/header/search-block.html',
        link       : function () {

        }
    }
});