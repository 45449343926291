angular.module('sinvoice-desktop').controller('changePasswordController', function ($scope, Profile, UserSubscription, Modal, $filter) {

    $scope.password = '';
    $scope.spinner  = {enabled: false};
    $scope.isChanged = false;

    $scope.isSubmitButtonDisabled = function () {
        return UserSubscription.isCheckInProgress();
    };


    $scope.isSaveAllowed = function () {
        return new Promise(function (resolve, reject) {
            if ($scope.profileForm.$invalid) {
                return reject(true);
            }

            return UserSubscription.checkSubscriptionForEditAction().then(function () {
                return resolve(true)
            });

        })
    };

    $scope.resetErrors = function () {
        $scope.profileForm.currentPassword.$setValidity('sameCurrentPass', true);
        $scope.profileForm.newPassword.$setValidity('repeats', true);
    };

    $scope.save = function () {

        $scope.spinner.enabled = true;


        return Profile
            .changePass($scope.password)
            .then(function (data) {
                if (data.success) {
                    $scope.isChanged = true;
                    Modal.alert($filter('translate')('profile.changePassword.success'), {
                        title: $filter('translate')('profile.changePassword.success_title')
                    });

                    return Promise.resolve();
                } else {
                    switch (data['error']){
                        case 'password.mismatch' :
                            $scope.profileForm.currentPassword.$setValidity('sameCurrentPass', false);
                            break;
                        case 'password.repeats' :
                            $scope.profileForm.newPassword.$setValidity('repeats', false);
                            break;
                    }
                    return Promise.reject();
                }
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            });
    };


    $scope.cancel = function () {
        $scope.profileForm.$setPristine();
        $scope.password       = '';
        $scope.newPassword    = '';
        $scope.passwordVerify = '';
    };

});
