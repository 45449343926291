/**
 * Created by dbichenko on 08.06.2017.
 */
angular.module('sinvoice-desktop')
    .directive('spaceBarClickable', function ($timeout) {


        return {
            link: function (scope, element, attrs) {

                $timeout(function () {

                    var forAttribute     = element.attr('for');
                    var elementTargetted = $('#' + forAttribute);

                    if (forAttribute && elementTargetted) {
                        scope.$watch(function () {
                            return elementTargetted.is(':enabled');
                        }, function (newValue) {
                            if (newValue) {
                                element.attr('tabindex', 0);
                            } else {
                                element.removeAttr('tabindex');
                            }
                        });

                    } else {
                        element.attr('tabindex', 0);
                    }

                    element.on('keydown', function (e) {

                        if (e.keyCode != 32)
                            return;

                        e.preventDefault();

                        var elementToClick;

                        if (forAttribute) {
                            elementToClick = elementTargetted;
                        } else {
                            elementToClick = element;
                        }

                        if (elementToClick) {
                            elementToClick.trigger('click');

                            // Perform double click for radio buttons.
                            // Some investigation required;
                            if (elementToClick.is(':radio'))
                                elementToClick.trigger('click');
                        }
                    });
                });
            }
        }
    })
    .directive('ngClick', function () {


        return {
            link: function (scope, element, attrs) {


                element.on('keydown', function (e) {

                    if (e.keyCode == 32) {

                        e.stopPropagation();

                        e.preventDefault();

                        element.trigger('click');
                    }

                });
            }
        }

    });