angular.module('sinvoice-mobile').controller('MobileMyDocumentsController',
    function ($scope, $rootScope, Document) {


        $scope.spinner = {enabled: true};

        $scope.documents = null;

        $scope.filterPopup = {isOpened: false};

        $scope.pagination = {};

        $scope.isFilterEnabled = false;


        $scope.isNumberOfResultsShown = function () {
            return $scope.isFilterEnabled;
        };

        $scope.onFilterChange = function (filteredDocuments, isFilterEnabled) {
            $scope.filteredDocuments = filteredDocuments;
            $scope.isFilterEnabled   = isFilterEnabled;
        };

        $scope.onPaginateChange = function (currentPage, perPage) {
            $scope.pagination = {
                currentPage: currentPage,
                perPage    : perPage
            };
        };


        $scope.toggleFilter = function () {
            $scope.filterPopup.isOpened = !$scope.filterPopup.isOpened;
        };

        (function () {
            Document
                .getData(0, 0)
                .then(function (data) {
                    $scope.documents         = data;
                    $scope.filteredDocuments = data;
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })

        })()

    });