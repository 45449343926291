/**
 * Created by abichenko on 30.10.17.
 */
angular.module('sinvoice-mobile').factory('MobileModal', function (Modal, $timeout, $q, $filter) {

    return {

        confirm: function (msg, acceptLabel, declineLabel, additionalOptions) {
            var defer = $q.defer();

            if (!acceptLabel)
                acceptLabel = $filter('translate')("global.bootbox_confirm.buttons.accept");

            if (!declineLabel)
                declineLabel = $filter('translate')("global.bootbox_confirm.buttons.decline");

            var buttons = {
                accept : {
                    label    : acceptLabel,
                    className: "btn-primary btn-sm",
                    callback : function () {
                        return defer.resolve();
                    }
                },
                decline: {
                    label    : declineLabel,
                    className: "btn-link btn-xs",
                    callback : function () {
                        return defer.reject();
                    }
                }
            };

            var options = {
                buttons       : buttons,
                size          : "medium",
                title         : $filter('translate')('common.confirm_title'),
                scopeVariables: {
                    message: msg
                }
            };

            if (!additionalOptions)
                additionalOptions = {};

            if (angular.isDefined(additionalOptions.title)) {
                options.title = additionalOptions.title;
            }

            this.bluebarModal(
                './mobile/components/mobile-common/mobile-modal-template.html',
                options
            );

            return defer.promise;
        },

        yellowModal: function (templateUrl, options) {

            var headerUrl = './mobile/components/mobile-common/yellow-modal-header-template.html';

            var defaultOptions = {
                className  : 'yellow-modal',
                headerUrl  : headerUrl,
                templateUrl: templateUrl,
                closeButton: false
            };


            options = _.merge(defaultOptions, options);


            return Modal
                .customDialog(options)
                .then(function (dialog) {

                    $timeout(function () {
                        dialog.element.addClass('active-popup')
                    });

                    return dialog;
                });
        },
        bluebarModal: function (templateUrl, options) {

            var defaultOptions = {
                className  : 'popup-document',
                templateUrl: templateUrl,
                closeButton: false
            };

            options = _.merge(defaultOptions, options);


            return Modal
                .customDialog(options)
                .then(function (dialog) {

                    $timeout(function () {

                        var button = angular.element('<a>');
                        var icon   = angular.element('<i>');

                        button.addClass('close bootbox-close-button');

                        icon.addClass('fa fa-times-circle');

                        button.append(icon);

                        dialog.element.find('.modal-header').append(button);
                    });

                    return dialog;
                });
        },
        overlay     : function (templateUrl, options) {


            var headerUrl = './mobile/components/mobile-common/overlay-modal-header-template.html';

            var defaultOptions = {
                className  : 'overlay-popup',
                headerUrl  : headerUrl,
                templateUrl: templateUrl,
                closeButton: false
            };


            options = _.merge(defaultOptions, options);


            return Modal.customDialog(options);
        },
        customDialog: Modal.customDialog,
        alert       : function (msg, additionalOptions) {
            var templateUrl = './mobile/components/mobile-common/mobile-modal-template.html';
            if (!additionalOptions) {
                additionalOptions = {
                    templateUrl: templateUrl,
                    className  : 'common-popup'
                };
            } else if (!angular.isDefined(additionalOptions.templateUrl)) {
                additionalOptions.templateUrl = templateUrl;
            }


            var defer = $q.defer();

            var buttons = {
                ok: {
                    label    : $filter('translate')('common.ok'),
                    className: "btn-primary btn-sm",
                    callback : function () {
                        return defer.resolve();
                    }
                }
            };


            var options = {
                buttons       : buttons,
                size          : "medium",
                title         : $filter('translate')('common.alert_title'),
                scopeVariables: {
                    message: msg
                }
            };

            if (!additionalOptions)
                additionalOptions = {};

            if (angular.isDefined(additionalOptions.title)) {
                options.title = additionalOptions.title;
            }

            this.bluebarModal(
                './mobile/components/mobile-common/mobile-modal-template.html',
                options
            );

            return defer.promise;



        }
    }


});