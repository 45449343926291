angular.module('sinvoice-desktop').controller('firstStepController',
    function ($scope,
              RegisterState,
              Profile,
              TokenStorage,
              Register,
              CheckIfExistEmail,
              Url,
              API,
              $stateParams,
              $timeout) {

        $scope.user            = {};
        $scope.registerForm     = {};
        $scope.questions       = [];
        $scope.responseMessage = '';
        $scope.spinner.enabled = true;

        function checkReferral(token) {
            return new Promise(
                function (resolve, reject) {
                    API.get(Url.auth.validateInviteToken(token))
                        .success(function (data) {
                            if (!!data)
                                resolve(data);
                            else
                                reject(data);
                        })
                })
        }

        (function () {

            Profile
                .getSecretQuestions()
                .then(function (data) {
                    $scope.questions = data;
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                });

            if ($stateParams.invite) {
                checkReferral($stateParams.invite).then(function (data) {
                    if(data.success){
                        $timeout(function () {
                            $scope.user.email = data.email;
                            $scope.user.first_name = data.first_name;
                            $scope.user.last_name = data.last_name;
                            $scope.user.phone = data.phone;
                            $scope.registerForm.$setValidity('email', true);
                        });

                    }
                });
            }

            initializeValidators();

        })();

        $scope.register = function () {
            if ($scope.registerForm.$invalid) {
                return false;
            } else {

                $scope.spinner.enabled      = true;
                $scope.user.first_question  = $scope.first_question;
                $scope.user.second_question = $scope.second_question;

                if ($scope.inviteToken) {
                    $scope.user.invite_token = $scope.inviteToken;
                }
                if ($scope.refId) {
                    $scope.user.referral_id = $scope.refId;
                }

                Register.performFirstStep($scope.user)
                    .then(function (data) {
                        if (data.success) {
                            TokenStorage.setCurrentSessionToken(data.api_key);
                            $scope.registerData.user = angular.copy(data.profile);
                            RegisterState.data.value = "secondStep";
                        } else {
                            // the server returns "ERROR:" in the message and we don't want it.
                            $scope.responseMessage = data.errors
                                .split('\n')
                                .map(function (item) {
                                    return item
                                        .replace(/ERROR\:/g, '')
                                        .trim();
                                })
                                .filter(function (item) {
                                    return !new RegExp(/\w+\:$/).test(item)
                                });
                            $scope.responseMessage = [$scope.responseMessage[0]];
                        }
                    })
                    .finally(function () {
                        $scope.spinner.enabled = false;
                    })
            }
        };

        function initializeValidators() {

            $scope.validators = {
                uniqueEmail: function (value) {
                    return CheckIfExistEmail.checkEmail('email', value).then(function (data) {
                        return !data.result
                    });
                }
            }
        }


    });