/**
 * Created by dbichenko on 18.01.2017.
 */
angular.module('sinvoice-desktop').controller('sendOrdersBookPopupController',
    function ($scope, Report, $filter, AuthDataStorage, Modal, NotificationSendCopyLabelHelper, Customer) {

        $scope.customers = [];

        $scope.availableCustomers = [];

        $scope.spinner = {enabled: true};

        $scope.save = function () {

            if ($scope.sendOrdersbookViaEmailForm.$invalid)
                return;


            var customerIds = $scope.customers.map(function (customer) {
                return customer.id
            });

            $scope.spinner.enabled = true;

            Report
                .sendOrdersBookToCustomers($scope.order.id, customerIds, $scope.subject, $scope.message, $scope.sendCopy)
                .then(function (data) {
                    $scope.$currentModal.close();

                    if (!data.result.email.failure.length) {
                        Modal.alert(
                            $filter('translate')('reports.email_send_successful'),
                            {title: $filter('translate')('reports.email_send_successful_title')}
                        );
                    } else {
                        Modal.alert(
                            $filter('translate')('reports.email_send_failure', {
                                emailList: data.result.email.failure.join(', ')
                            }),
                            {title: $filter('translate')('reports.email_send_failure_title')}
                        );
                    }
                })
        };


        $scope.onSendCopyChange = function () {
            NotificationSendCopyLabelHelper.onChangeCallback($scope.sendCopy);
        };

        $scope.cancel = function () {
            $scope.$currentModal.close();
        };

        (function () {

            $scope.sendCopy = NotificationSendCopyLabelHelper.getDefaultValue();

            Customer.getData()
                .then(function (data) {
                    $scope.availableCustomers = data.filter(function (customer) {
                        return !!customer.email;
                    })
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                });


            var business = AuthDataStorage.getHebrewBusiness();

            $scope.subject = $filter('translate')('reports.send_ordersbook_via_email_popup.default_subject', {
                businessName: business.name,
            });
            $scope.message = $filter('translate')('reports.send_ordersbook_via_email_popup.default_message')

        })();
    });