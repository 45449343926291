/**
 * Created by abichenko on 27.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('receiptCreditController',
        function ($scope, DocumentModalCallbackContainer, Events, DocumentModalCallbackChecker, MobileDocumentModalCallbackProvider, FormHelper) {

            $scope.errors = {};

            (function () {


                Events.subscribeToEvent('paymentForm.update', $scope, function (e, form) {
                    if (!form || !form.$name)
                        return;
                    FormHelper.addValidator(form.amount, 'required', function (value) {
                        return !!value && value > 0;
                    })
                });


                /*DocumentModalCallbackChecker.addPreSaveCallback(function (flow) {

                 if (!DocumentModalCallbackChecker.isCreditWarningPopupNeedToBeShown($scope.editedDocument)) {
                 return flow.allow();
                 }

                 Modal
                 .confirm(
                 $filter('documentPopupTranslate')('documents.occasional_customer_warning', $scope.editedDocument.type.docType),
                 null,
                 null,
                 {title: $filter('documentPopupTranslate')('documents.occasional_customer_warning_title', $scope.editedDocument.type.docType)}
                 )
                 .then(
                 flow.allow
                 )

                 });*/

                /*DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                 if (!DocumentModalCallbackChecker.isAmountDoesNotMatchPopupNeedToBeShown(
                 $scope.editedDocument,
                 $scope.template.$paymentsTotalWithVatNIS,
                 $scope.template.$finalItemsTotalWithVatNIS
                 ))
                 return flow.allow();

                 MobileModal
                 .confirm(
                 $filter('documentPopupTranslate')('documents.not_match_message', $scope.editedDocument.type.docType),
                 null,
                 null,
                 {title: $filter('documentPopupTranslate')('documents.not_match_message_title', $scope.editedDocument.type.docType)}
                 )
                 .then(
                 flow.allow,
                 flow.deny
                 );
                 });*/

                DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                    if (!DocumentModalCallbackChecker.isOccasionalCustomerInfoMissingPopupNeedToBeShown($scope.editedDocument)) {
                        return flow.allow();
                    }

                    MobileDocumentModalCallbackProvider
                        .openOccasionalCustomerInfoMissingPopup($scope.editedDocument)
                        .then(
                            function () {
                                if ($scope.documentForm.$invalid)
                                    flow.deny();
                                else
                                    flow.allow();
                            },
                            flow.deny
                        )

                });

                DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                    var editedDocument = $scope.editedDocument;
                    var paymentErrors  = $scope.errors;

                    paymentErrors.required = false;

                    if (!editedDocument.payments.length) {
                        flow.deny();
                        paymentErrors.required = true;
                        return false;
                    }

                    flow.allow();
                });


            })();


        });