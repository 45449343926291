/**
 * Created by abichenko on 02.11.17.
 */
angular.module('sinvoice-mobile').controller('mobileNewCustomerController',
    function ($scope, Customer, $filter, $state, AuthDataStorage, ProfileChecker, previousStateProvider) {

        $scope.customer = {};

        $scope.customerDetailsForm = {};

        $scope.spinner = {enabled: true};

        $scope.paymentTerms = null;

        $scope.isEnglishAvailable = function () {
            return ProfileChecker.isEnglishAvailable(AuthDataStorage.getProfileBusinesses());
        };

        $scope.save = function () {

            var prevState = previousStateProvider.getValue();

            if ($filter('isSubmitNotAllowed')($scope.customerDetailsForm))
                return;

            $scope.spinner.enabled = true;
            Customer
                .create(normalizeCustomer($scope.customer))
                .finally(function () {
                    $scope.spinner.enabled = false;


                    if (prevState && _.includes(['my-customers', 'my-documents-customers'], prevState.name)) {
                        $state.go(prevState.name);
                    } else {
                        $state.go('my-customers');
                    }
                })

        };

        function loadPaymentTerms() {
            return Customer
                .getPaymentTerms()
                .then(function (data) {
                    $scope.paymentTerms = data;
                })
        }


        function initializeValidators() {
            $scope.validators = {}
        }


        function normalizeCustomer(customer) {

            var copy = angular.copy(customer);

            if (copy.paymentterm) {
                copy.paymentterm = copy.paymentterm.id;
            }

            return copy;
        }

        (function () {

            initializeValidators();

            loadPaymentTerms()
                .finally(function () {
                    $scope.spinner.enabled = false;
                });
        })()


    });