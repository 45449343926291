/**
 * Created by dbichenko on 21.09.2016.
 */
angular.module('sinvoice-desktop').service('DocumentExport',
    function (Document, $window, Modal, $translate, FileHelper, $q, promiseHelper, Url, DocumentTypeConstants, AuthDataStorage) {

        this.mivneExcludedTypes = [
            DocumentTypeConstants.TYPE_BALANCE_ADJUSTMENT,
            DocumentTypeConstants.TYPE_PRICE_QUOTE,
            DocumentTypeConstants.TYPE_PAYMENT_REQUEST
        ];

        this.print                = print;
        this.email                = email;
        this.downloadAsMivne      = downloadAsMivne;
        this.downloadAsTamal      = downloadAsTamal;
        this.downloadAsWizcount   = downloadAsWizcount;
        this.downloadAsPdf        = downloadAsPDF;
        this.showAsPDF            = showAsPDF;
        this.printByToken         = printByToken;
        this.showAsPDFByToken     = showAsPDFByToken;
        this.printOriginalAndCopy = printOriginalAndCopy;
        this.printEnglishOriginal = printEnglishOriginal;
        this.showMivneSummary     = showMivneSummary;
        this.printMivneSummary    = printMivneSummary;
        this.printEachTemplate    = printEachTemplate;

        return this;

        function downloadAsTamal(ids, from, to) {

            if (!angular.isDefined(from)) {
                from = null;
            }

            if (!angular.isDefined(to)) {
                to = null;
            }

            var business = AuthDataStorage.getHebrewBusiness();
            var profile  = AuthDataStorage.getProfile();

            return Document.exportAsTamal(ids, from, to).then(function (data) {
                saveArchive(data, business.name + '_' + profile.authorizedDealerId + '.zip')
            });
        }

        function printMivneSummary(ids, from, to) {

            var defer = $q.defer();

            if (!angular.isDefined(from)) {
                from = null;
            }

            if (!angular.isDefined(to)) {
                to = null;
            }

            var promise = Document.mivneSummaryPDFContent(ids, from, to).then(function (data) {
                defer.resolve();
                return data.link
            });

            FileHelper.openWindowAndPrint(function () {
                return promise;
            });

            return defer.promise;

        }

        function showMivneSummary(ids, from, to) {

            if (!angular.isDefined(from)) {
                from = null;
            }

            if (!angular.isDefined(to)) {
                to = null;
            }

            return Document.showMivneSummary(ids, from, to).then(function (data) {

                if (!data.content) {
                    return false;
                }

                return Modal.customDialog({
                    size          : "large",
                    templateUrl   : './desktop/components/documents/export-documents-to-mivne-popup.html',
                    scopeVariables: {
                        ids    : ids,
                        content: data.content,
                        from   : from,
                        to     : to
                    },
                    title         : $translate.instant('documents.export_to_mivne_popup.title')
                })
            });
        }

        function printEnglishOriginal(id) {
            var defer = $q.defer();

            FileHelper.openWindowAndPrint(function () {
                return Document.printEnglishOriginal(id)
                    .then(function (data) {
                        defer.resolve();
                        return data.link;
                    })
            });

            return defer.promise;

        }

        function email(documents, email) {

            return Modal.customDialog({
                scopeVariables: {
                    documents: documents,
                    email    : email
                },
                title         : $translate.instant('documents.send_document_via_email_popup.title'),
                templateUrl   : './desktop/components/documents/popups/send-document-via-email-popup.html'
            });

        }

        function printEachTemplate(id) {

            var defer = $q.defer();

            FileHelper.openPDF(function () {
                return Document.printEachTemplate(id)
                    .then(function (data) {
                        defer.resolve();
                        return data.link;
                    })
            });

            return defer.promise;
        }

        function printOriginalAndCopy(id) {

            var defer = $q.defer();

            FileHelper.openWindowAndPrint(function () {
                return Document.printOriginalAndCopy(id)
                    .then(function (data) {
                        defer.resolve();
                        return data.link;
                    })
            });

            return defer.promise;
        }


        function print(ids) {

            var defer = $q.defer();

            FileHelper.openWindowAndPrint(function () {
                return Document.printableContentForMultiple(ids)
                    .then(function (data) {
                        defer.resolve();
                        return data.link
                    })
            });

            return defer.promise;
        }


        function showAsPDF(ids) {
            var defer = $q.defer();
            FileHelper.openPDF(function () {
                return Document.printableContentForMultiple(ids)
                    .then(function (data) {
                        defer.resolve();
                        return data.link
                    })
            })
            return defer.promise;
        }

        function downloadAsPDF(ids) {
            return Document.export(ids).then(saveArchive);
        }

        function downloadAsMivne(ids, from, to) {

            if (!angular.isDefined(from)) {
                from = null;
            }

            if (!angular.isDefined(to)) {
                to = null;
            }

            return Document.exportAsMivne(ids, from, to).then(saveArchive);
        }

        function downloadAsWizcount(ids, from, to) {

            if (!angular.isDefined(from)) {
                from = null;
            }

            if (!angular.isDefined(to)) {
                to = null;
            }


            return Document.exportAsWizcount(ids, from, to).then(saveArchive);
        }

        function openPDF(data) {
            var defer = $q.defer();
            FileHelper.openPDF(function () {
                defer.resolve(data.content);
                return defer.promise;
            })

        }

        function saveArchive(data, archiveName) {

            archiveName = archiveName || 'documents.zip';

            if (data.content) {
                FileHelper.saveArchive(data.content, archiveName);
                return true;
            } else {
                return false;
            }


        }


        function printByToken(token) {

            var defer = $q.defer();

            FileHelper.openWindowAndPrint(function () {
                defer.resolve();
                return promiseHelper.resolvedPromise(Url.export.pdfContent(token));
            });

            return defer.promise;

        }

        function showAsPDFByToken(token, useCurrentWindow) {
            var defer       = $q.defer();
            var windowToUse = null;

            if (useCurrentWindow)
                windowToUse = $window;

            FileHelper.openPDF(function () {

                defer.resolve();
                return promiseHelper.resolvedPromise(Url.export.pdfContent(token));
            }, windowToUse);
            return defer.promise;
        }

    });