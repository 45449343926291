/**
 * Created by abichenko on 08.12.17.
 */
angular.module('sinvoice-mobile').controller('sendDocumentPopupController',
    function ($scope, Document, MobileModal, $filter) {

        $scope.spinner = {enabled: false};

        $scope.sendDocumentViaEmailForm = {};

        $scope.sendDocument = function () {

            if ($scope.sendDocumentViaEmailForm.$invalid)
                return;

            $scope.spinner.enabled = true;

            Document
                .sendViaEmails([$scope.document.id], $scope.email, $scope.subject, $scope.message)
                .then(function (data) {

                    if (!data.result.email.failure.length) {
                        MobileModal
                            .alert(
                                $filter('translate')('documents.email_send_successful'),
                                {title: $filter('translate')('documents.email_send_successful_title')}
                            )
                            .finally(function () {
                                $scope.spinner.enabled = false

                            });
                    } else {
                        MobileModal
                            .alert(
                                $filter('translate')('documents.email_send_failure', {
                                    emailList: data.result.email.failure.join(', ')
                                }),
                                {title: $filter('translate')('documents.email_send_failure_title')}
                            )
                            .finally(function () {
                                $scope.spinner.enabled = false
                            })
                    }

                    $scope.$currentModal.close();
                })
        };

        $scope.cancel = function () {
            $scope.$currentModal.close();
        };

    }
);