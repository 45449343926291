/**
 * Created by abichenko on 28.09.17.
 */
angular.module('sinvoice-desktop').factory('NotificationModalHelper', function () {
    return {
        getNamesForRecipients: function (recipients, addresses, propertyName, nameProperty) {
            return recipients
                .filter(function (value) {
                    return _.includes(addresses, value[propertyName])
                })
                .map(function (recipient) {

                    if (nameProperty.length) {
                        var result = [];
                        nameProperty.forEach(function (property) {
                            result.push(recipient[property])
                        });
                        return result.filter(function (value) {
                            return !!value;
                        }).join(' ');
                    } else {
                        return recipient[nameProperty];
                    }

                })
        }
    }
});