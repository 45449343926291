angular.module('sinvoice-desktop').controller('editController', function ($scope, Profile, $q, Auth) {

    $scope.user       = null;
    $scope.questions  = [];
    $scope.backupUser = {
        user: {}
    };
    $scope.spinner    = {enabled: true};

    (function () {

        $q
            .all([
                Profile.getData().then(function (data) {
                    $scope.user = data;
                    angular.copy($scope.user, $scope.backupUser.user);
                }),

                Profile.getSecretQuestions().then(function (data) {
                    $scope.questions = data;
                })
            ])
            .then(function () {
                $scope.spinner.enabled = false;
            })
    })();


    $scope.save = function () {

        $scope.spinner.enabled = true;

        return Profile
            .updateProfile($scope.user)
            .then(function (data) {
                if (data == 1) {
                    Auth.changeProfile($scope.user);
                    $scope.backupUser.user = angular.copy($scope.user);
                    return Promise.resolve(data)
                } else {
                    return Promise.reject()
                }

            })
            .finally(function () {
                $scope.spinner.enabled = false;
            });
    };

    $scope.cancel = function () {
        angular.copy($scope.backupUser.user, $scope.user);
    };


});
