/**
 * Created by dbichenko on 15.11.2016.
 */
angular.module('sinvoice-desktop').service('ReportsExport', function (Modal, $translate, FileHelper, Report, $q) {


    this.email         = email;
    this.print         = print;
    this.showAsPDF     = showAsPDF;
    this.downloadAsCSV = downloadAsCSV;
    this.downloadAsXLS = downloadAsXLS;

    this.emailCustomerBook     = emailCustomerBook;
    this.printCustomerBook     = printCustomerBook;
    this.showAsPDFCustomerBook = showAsPDFCustomerBook;

    this.showOrdersBookAsPDF       = showOrdersBookAsPDF;
    this.printOrdersBook           = printOrdersBook;
    this.emailOrdersBook           = emailOrdersBook;
    this.downloadOrdersBookAsXLS   = downloadOrdersBookAsXLS;
    this.downloadCustomerBookAsXLS = downloadCustomerBookAsXLS;


    this.showCommissionsAsXLS = downloadCommissionsAsXLS;
    this.showCommissionsAsPDF = showCommissionsAsPDF;
    this.printCommissions     = printCommissions;

    return this;

    function emailCustomerBook(customer) {
        return Modal.customDialog({
            scopeVariables: {
                customer: customer
            },
            title         : $translate.instant('reports.send_report_via_email_popup.title'),
            templateUrl   : './desktop/components/reports/types/customersBook/send-customers-book-popup.html'
        });

    }

    function printCustomerBook(customer) {
        FileHelper.openWindowAndPrint(function () {
            return Report.getPrintableContentForCustomerBook(customer.id).then(function (data) {
                return data.link;
            })
        });
    }

    function showAsPDFCustomerBook(customer) {
        return FileHelper.openPDF(function () {
            return Report.getPDFContentForCustomerBook(customer.id).then(function (data) {
                return data.link;
            })
        });
    }

    function showAsPDF(report) {

        return FileHelper.openPDF(function () {
            return Report.getPDFContent(report.id).then(function (data) {
                return data.link;
            })
        });
    }

    function email(report, email) {

        return Modal.customDialog({
            scopeVariables: {
                reports: [report],
                email  : email
            },
            title         : $translate.instant('reports.send_report_via_email_popup.title'),
            templateUrl   : './desktop/components/reports/popups/send-report-via-email-popup.html'
        });

    }

    function print(report) {

        FileHelper.openWindowAndPrint(function () {
            return Report.getPrintableContent(report.id).then(function (data) {
                return data.link;
            })
        });
    }


    function showOrdersBookAsPDF(order) {

        return FileHelper.openPDF(function () {
            return Report.getPDFContentForOrdersBook(order.id).then(function (data) {
                return data.link;
            })
        });
    }

    function downloadOrdersBookAsXLS(order) {
        return Report.getXLSArchiveContentForOrdersBook(order.id).then(function (data) {
            FileHelper.saveArchive(data.content, 'report.zip')
        })
    }


    function downloadCustomerBookAsXLS(customer) {
        return Report.getXLSArchiveContentForCustomerBook(customer.id).then(function (data) {
            FileHelper.saveArchive(data.content, 'report.zip')
        })
    }


    function printOrdersBook(order) {

        FileHelper.openWindowAndPrint(function () {
            return Report.getPrintableContentForOrdersBook(order.id).then(function (data) {
                return data.link;
            })
        });
    }


    function emailOrdersBook(order) {
        return Modal.customDialog({
            scopeVariables: {
                order: order
            },
            title         : $translate.instant('reports.send_ordersbook_via_email_popup.title'),
            templateUrl   : './desktop/components/reports/types/ordersBook/send-orders-book-popup.html'
        });

    }

    function downloadAsCSV(report) {
        return Report.getCSVArchiveContent(report.id).then(function (data) {
            FileHelper.saveArchive(data.content, 'report.zip')
        })
    }

    function downloadAsXLS(report) {
        return Report.getXLSArchiveContent(report.id).then(function (data) {
            FileHelper.saveArchive(data.content, 'report.zip')
        })
    }

    function downloadCommissionsAsXLS(ids) {

        return Report.getXLSArchiveContentForCommissions(ids).then(function (data) {
            FileHelper.saveArchive(data.content, 'report.zip')
        })

    }

    function showCommissionsAsPDF(ids) {
        return FileHelper.openPDF(function () {
            return Report.getPDFContentForCommissions(ids).then(function (data) {
                return data.link;
            })
        });
    }

    function printCommissions(ids) {
        FileHelper.openWindowAndPrint(function () {
            return Report.getPrintableContentForCommissions(ids).then(function (data) {
                return data.link;
            })
        });

    }

});