/**
 * Created by abichenko on 14.02.18.
 */
angular.module('sinvoice-desktop').factory('DesktopModal', function (Modal, $filter, ModalContainer) {


    return {
        unsavedChanges: function (onSubmitCallback) {
            return new Promise(function (resolve, reject) {
                Modal
                    .customDialog({
                        templateUrl   : './desktop/components/common/unsaved-changes-popup.html',
                        size          : 'medium',
                        className     : 'saveChangesPopup',
                        headerUrl     : null,
                        scopeVariables: {
                            okFunc    : function () {
                                if (angular.isFunction(onSubmitCallback)) {
                                    onSubmitCallback();
                                }
                                resolve(true);
                            },
                            cancelFunc: function () {
                                reject(false);
                            },
                            message   : $filter('translate')('common.unsaved_changes_popup.message')
                        }
                    })
                    .then(function (dialog) {
                        ModalContainer.setElement('saveChanges', dialog, 'saveChangesPopup');
                    });
            })
        }
    }


})