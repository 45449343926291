angular.module('sinvoice-desktop').controller('businessDefinitionsController', function ($scope, Modal, $translate, Profile, Auth, ManagedCustomers, $q, UserSubscription) {


    $scope.spinner      = {
        enabled: true
    };
    $scope.user         = {};
    $scope.backup_user  = {};
    $scope.he_checkbox  = true;
    $scope.en_checkbox  = true;
    $scope.managerName  = '';
    $scope.managerExist = false;


    $scope.isSubmitButtonDisabled = function () {
        return UserSubscription.isCheckInProgress();
    };


    $scope.isSaveAllowed = function () {
        return new Promise(function (resolve, reject) {

            if ($scope.profileForm.$invalid) {
                return reject(false);
            }

            return UserSubscription.checkSubscriptionForEditAction().then(function () {
                return resolve(true)
            });

        })
    };


    $scope.refuseManagement = function () {
        $scope.spinner.enabled = true;

        Modal
            .confirm(
                $translate.instant('common.register_page.refuse_question'),
                null,
                null,
                {title: $translate.instant('common.register_page.refuse_question_title')}
            )
            .then(function () {
                ManagedCustomers
                    .refuseManagement()
                    .then(function (data) {
                        $scope.managerExist = false;
                    });
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })
        ;
    };

    $scope.save = function () {

        $scope.spinner.enabled = true;
        $scope.user.en_enabled = $scope.user.businesses['en'].en_checkbox;
        $scope.user.he_enabled = $scope.user.businesses['he'].he_checkbox ? $scope.user.businesses['he'].he_checkbox : true;

        return Profile
            .updateBusinessDefinitions($scope.user)
            .then(function (data) {
                if (data.success) {
                    angular.copy($scope.user, $scope.backup_user);
                    Auth.changeProfile($scope.user);
                    return Promise.resolve(data)
                } else {
                    return Promise.reject()
                }
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })
    };

    $scope.cancel = function () {
        angular.copy($scope.backup_user, $scope.user);
    };

    loadData();

    function loadData() {

        $q
            .all([
                Profile
                    .getBusinessTypes()
                    .then(function (data) {
                        $scope.businessTypes = data
                    }),

                ManagedCustomers
                    .getManagerDetails()
                    .then(function (data) {
                        if (data.status) {
                            $scope.managerExist = true;
                            $scope.managerName  = data.firstName + ' ' + data.lastName;
                        }
                    }),
                Profile
                    .getData()
                    .then(function (data) {
                        $scope.user = data;
                        angular.copy($scope.user, $scope.backup_user);
                    })
            ])
            .then(function () {
                $scope.spinner.enabled = false;
            })
    }


});