angular.module('sinvoice-desktop').controller('distributorSettingsController',
    function ($scope,
              $filter,
              $compile,
              $q,
              DistributorSettings,
              City,
              AuthDataStorage,
              Profile,
              BankProvider) {

        // Table

        var profile2 = AuthDataStorage.getProfile();

        $scope.selected        = {};
        $scope.banks           = [];
        $scope.spinner         = {enabled: true};
        $scope.details         = {};
        $scope.previousDetails = {};
        $scope.detailsForm     = [];
        $scope.userFullName    = profile2.firstName + " " + profile2.lastName;

        $scope.isSelectedBankDetails = function () {
            return $scope.selected.paymentType && $scope.selected.paymentType.type == 'bank_account';
        };

        $scope.isSelectedCheque = function () {
            return $scope.selected.paymentType && $scope.selected.paymentType.type == 'cheque';
        };

        $scope.savePaymentType = function () {
            DistributorSettings.setPaymentType($scope.selected.paymentType);
        };


        $scope.editDetails = function () {
            $scope.isEditing = true;
            backupChanges();
        };


        $scope.saveDetails = function () {


            if (!$scope.selected.paymentType)
                return false;

            var selectedType = $scope.selected.paymentType.type;

            if ($scope.detailsForm[selectedType].$invalid)
                return false;

            $scope.isEditing = false;


            var filteredDetails           = {};
            filteredDetails[selectedType] = $scope.details[selectedType];
            DistributorSettings.updateDetails(filteredDetails);

        };

        $scope.cancelEditDetails = function () {
            $scope.isEditing = false;
            revertChanges();

        };


        function backupChanges() {
            $scope.previousDetails = angular.copy($scope.details);
        }

        function revertChanges() {
            $scope.details         = angular.copy($scope.previousDetails);
            $scope.previousDetails = {};
        }

        (function () {

            $scope.paymentTypes = {
                cheque      : {
                    name: $filter('translate')('documents.payment_methods.check.title'),
                    id: 1,
                    type: 'cheque'
                },
                bank_account: {
                    name: $filter('translate')('documents.payment_methods.bank_transfer.title'),
                    id: 2,
                    type: 'bank_account'
                }
            };

            $q.all([
                loadBanks(),
                loadCities(),
                loadDetails(),
                loadSettings()
            ])
                .then(loadFirstAccount)
                .then(function () {

                    if ($scope.firstAccount && !$scope.details.bank_account) {
                        $scope.details.bank_account = {
                            bank   : $scope.firstAccount.bank,
                            account: $scope.firstAccount.account,
                            branch : $scope.firstAccount.branch
                        };
                    }
                    $scope.spinner.enabled = false;
                })

        })();


        function loadSettings() {
            return DistributorSettings.getSettings().then(function (data) {
                $scope.selected.paymentType = $scope.paymentTypes[data.paymentType] ? $scope.paymentTypes[data.paymentType] : null;
            });
        }

        function loadFirstAccount() {
            return Profile.getBusinessInfo().then(function (data) {
                $scope.firstAccount = data.accounts.shift();
            })
        }

        // Update bank details
        function loadBanks() {

            return BankProvider.getAll().then(function (data) {
                $scope.banks = data;
            });
        }

        // Update bank details
        function loadCities() {

            return City.getAll().then(function (data) {
                $scope.cities = data;
            });
        }

        function loadDetails() {

            return DistributorSettings.getDetails().then(function (data) {
                $scope.details = data;
            })
        }


    });

