/**
 * Created by abichenko on 08.11.17.
 */
angular.module('sinvoice-mobile').factory('CustomerDetailsModal', function (MobileModal, ModalContainer, $filter) {


    return {

        closeDetailsModals    : function () {
            ModalContainer.closeGroup('customerDetailsModals')
        },
        closeContactEditModals: function () {
            ModalContainer.closeGroup('customerContactsModals');
        },
        viewCustomerDetails   : viewCustomerDetails,
        editCustomerDetails   : editCustomerDetails,
        editPhone             : editPhone,
        editEmail             : editEmail
    };

    function editCustomerDetails(customer) {
        MobileModal
            .overlay(
                './mobile/components/view-customer/partials/customer-details-popup.html',
                {
                    headerUrl     : './mobile/components/view-customer/partials/customer-details-header.html',
                    scopeVariables: {
                        isEditable: true,
                        customer  : customer
                    }
                })
            .then(function (dialog) {
                ModalContainer.setElement('editCustomerDetails', dialog, 'customerDetailsModals');
                return dialog;
            })
    }

    function viewCustomerDetails(customer) {
        MobileModal
            .overlay(
                './mobile/components/view-customer/partials/customer-details-popup.html',
                {
                    headerUrl     : './mobile/components/view-customer/partials/customer-details-header.html',
                    scopeVariables: {
                        isEditable: false,
                        customer  : customer
                    }
                })
            .then(function (dialog) {
                ModalContainer.setElement('editCustomerDetails', dialog, 'customerDetailsModals');
                return dialog;
            })
    }

    function editEmail(email, onSaveCallback) {

        ModalContainer.closeGroup('customerContactsModals');

        MobileModal
            .yellowModal(
                './mobile/components/view-customer/partials/email-edit-popup.html',
                {
                    scopeVariables: {
                        title : $filter('translate')('view-customer.mobile.customer_details.edit_email_title'),
                        email : email,
                        onSave: onSaveCallback
                    }
                }
            )
            .then(function (dialog) {
                ModalContainer.setElement('editEmail', dialog, 'customerContactsModals');
                return dialog;
            })
    }

    function editPhone(phone, onSaveCallback) {

        ModalContainer.closeGroup('customerContactsModals');

        MobileModal
            .yellowModal(
                './mobile/components/view-customer/partials/phone-edit-popup.html',
                {
                    scopeVariables: {
                        title : $filter('translate')('view-customer.mobile.customer_details.edit_phone_title'),
                        phone : phone,
                        onSave: onSaveCallback
                    }
                }
            )
            .then(function (dialog) {
                ModalContainer.setElement('editPhone', dialog, 'customerContactsModals');
                return dialog;
            })
    }

});