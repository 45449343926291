/**
 * Created by dbichenko on 01.08.2016.
 */
angular.module('sinvoice-desktop').controller('itemFilterTableController',
    function ($scope, $compile, DataTableLanguageSettings, DataTableBootstrapSettings, DTOptionsBuilder, DTColumnDefBuilder, DataTableDatepickerLabels, $filter, DocumentInitializer) {


        DocumentInitializer.addInitCallback(function () {
            $scope.options = initializeDataTable();
            $scope.columns = initializeDataColumns();
        });


        function initializeDataTable(promise) {


            var dtOptions = DTOptionsBuilder
                .newOptions()
                .withBootstrap()
                .withOption('sDom', "rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withColumnFilter({
                    aoColumns: [
                        null,
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'number-range', bRegex: true, bSmart: true, 'sRangeFormat': DataTableDatepickerLabels.fromToLabels()},
                        null
                    ]
                })
                .withOption('autoWidth', false)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withDisplayLength(5)
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withButtons([])
                .withOption('fnRowCallback', rowCallback);

            return dtOptions;
        }

        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

            var name = $(nRow, iDisplayIndex).find('td.name');
            name.unbind('click');
            name.on('click', function (e) {
                $scope.$apply(function () {
                    //UGLY!!


                    var item      = aData;
                    $scope.opened = false;
                    $scope.addItem({
                        id         : item[0],
                        sku        : item[1],
                        name       : item[2],
                        description: item[3],
                        price      : item[4],
                        currency   : {name: item[5]}
                    });
                });

                e.stopPropagation();
            });

        }

        function initializeDataColumns() {

            var removeUnderline = function (value) {
                return '<span style="display:inline-block">' + value + '</span>';
            };
            var dtColumns       = [

                DTColumnDefBuilder.newColumnDef(0),
                DTColumnDefBuilder.newColumnDef(1).renderWith(removeUnderline),
                DTColumnDefBuilder.newColumnDef(2).renderWith(function (value) {
                    return '<span style="text-decoration:underline;display:inline-block; ">' + value + '</span>';
                }),
                DTColumnDefBuilder.newColumnDef(3).renderWith(removeUnderline),
                DTColumnDefBuilder.newColumnDef(4).withOption('render', {
                    "filter" : function (value) {
                        return value;
                    },
                    "display": function (value) {
                        return '<span style="display:inline-block">' + $filter('number')(value, 2) + '</span>';
                    }
                }),
                DTColumnDefBuilder.newColumnDef(5)

            ];

            dtColumns.forEach(function (value) {
                value.notSortable();

            });

            return dtColumns;
        }


    });
