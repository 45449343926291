/**
 * Created by dbichenko on 07.11.2016.
 */
angular.module('sinvoice-desktop')
    .filter('isNegativeValue', function ($filter) {

        return function (value, item) {

            var isCreditDoc = $filter('isCreditDocument')(item.docType);

            return (value > 0) && isCreditDoc;
        }

    })
    .filter('isNegativeBalanceAdjustment', function (Document) {

        return function (value, item) {

            return ((value < 0) && Document.isBalanceAdjustment(item.docType));
        }
    })


;