/**
 * Created by abichenko on 12.03.18.
 */
angular.module('sinvoice-desktop')
    .controller('addressBlockController', function ($scope) {

        $scope.isAddressShown = function (address) {
            return $scope.isElementShown(address);
        };

        $scope.editAddress = function (address) {
            $scope.onEditAddress(address);
        };

        $scope.deleteAddress = function (address) {
            $scope.onDeleteAddress(address)
        };

        $scope.getFilteredAddresses = function () {

            var addresses = $scope.addresses;

            var filtered = addresses.filter(function (value) {
                return $scope.isAddressShown(value);
            });

            return filtered;
        }

    });