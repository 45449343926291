/**
 * Created by abichenko on 20.11.17.
 */
angular.module('sinvoice-mobile')

    .controller('generalBlockController', function ($scope, Currency, Language, $q, Events) {

        var vm = this;

        vm.spinner    = {enabled: true};
        vm.currencies = [];
        vm.languages  = [];


        vm.onCurrencyChange = function () {
            Events.triggerEventAfterDigest('currency.change');
        };

        Events.subscribeToEvent('currency.change', $scope, function () {
            setCurrencyDefaultCourse();
        });

        function loadData() {

            var loadPromises = [];

            loadPromises.push(Currency.getData().then(function (data) {
                vm.currencies = data;
            }));

            loadPromises.push(Language.getData().then(function (data) {
                vm.languages = data;
            }));


            return $q.all(loadPromises);
        }

        function setCurrencyDefaultCourse() {
            vm.defaultNisCourse = Currency.getCurrencyCourse(vm.currencyModel.name);
            vm.nisCourse        = vm.defaultNisCourse;
        }


        (function () {

            loadData()
                .then(function () {
                    if (!angular.isDefined(vm.languageModel)) {
                        vm.languageModel = vm.languages[0];
                    }

                    if (!angular.isDefined(vm.currencyModel)) {
                        vm.currencyModel = vm.currencies[0];
                    }

                    vm.defaultNisCourse = Currency.getCurrencyCourse(vm.currencyModel.name);

                    if (!vm.nisCourse) {
                        setCurrencyDefaultCourse();
                    }
                })
                .finally(function () {
                    vm.spinner.enabled = false;
                });

        })();

    });