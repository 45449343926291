/**
 * Created by Front-end on 21.12.2016.
 */
angular.module('sinvoice-desktop').controller('vatIncomeController',
    function ($scope,
              $filter,
              Events,
              Report,
              DTColumnDefBuilder,
              DTOptionsBuilder,
              $translate,
              $state,
              ReportsExport,
              $stateParams) {

        $scope.spinner      = {
            enabled: true
        };
        $scope.currentState = $state.current.name;

        $scope.$emit('onLoadReportPage', {
            state: $scope.currentState,
            page : {
                title      : $filter('translate')('reports.pages.incomeVAT'),
                description: '',
                alias      : 'reports.pages.incomeVAT'
            }
        });


        function emailReport() {
            ReportsExport.email($scope.currentReport);
        }

        function exportReportToPDF() {
            ReportsExport.showAsPDF($scope.currentReport);
        }

        function exportReportToXLS() {
            ReportsExport.downloadAsXLS($scope.currentReport);
        }

        function printReport() {
            ReportsExport.print($scope.currentReport);
        }


        function loadDummyTable() {

            $scope.columns = [
                DTColumnDefBuilder.newColumnDef(0)
            ];

            $scope.options = DTOptionsBuilder
                .newOptions()
                .withOption('sDom', "B")
                .withButtons([
                    {
                        extend       : 'subscriptionEditActionButton',
                        text         : $translate.instant('reports.email'),
                        className    : 'the_button_2 email',
                        extendOptions: {
                            action: emailReport
                        }
                    },
                    {
                        extend   : 'collection',
                        autoClose: true,
                        text     : $translate.instant('reports.export'),
                        className: 'the_button_2 export_data',
                        buttons  : [
                            {
                                'text'     : $translate.instant('reports.excel'),
                                'className': 'the_button_2',
                                action     : exportReportToXLS
                            },
                            {
                                'text'     : 'PDF',
                                'className': 'the_button_2',
                                action     : exportReportToPDF
                            }
                        ]
                    },
                    {
                        text     : $translate.instant('reports.print'),
                        className: 'the_button_2 print-grey',
                        action   : printReport
                    }
                ])
                .withOption('buttonContainer', '#button-container')

        }

        function loadReport(id) {

            $scope.spinner.enabled = true;
            return Report.getSingle(id, 'vat_income').then(function (data) {
                $scope.currentReport   = data;
                $scope.spinner.enabled = false;
            })
        }


        (function () {
            loadDummyTable();

            if ($stateParams.id) {
                $scope.isTableShown = true;
                loadReport($stateParams.id)
            } else {
                $scope.isTableShown = false;
            }

            Events.subscribeToEvent('report.update', $scope, function (e, newReport) {
                $scope.isTableShown = true;
                loadReport(newReport.id);
            })

        })()
    });