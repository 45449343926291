/**
 * Created by abichenko on 22.03.18.
 */
angular.module('sinvoice-common').factory('DirectiveCompiler', function ($compile, ScopeProvider, $interpolate) {


    return {

        compile        : function (directiveName, scopeVariables) {

            var scope = ScopeProvider.getNewIsolatedScope(scopeVariables);
            var dom   = angular.element(document.createElement(directiveName));

            $.each(scopeVariables, function (key, value) {
                dom.attr(key, key);
            });


            var directive         = $compile(dom)(scope);
            var compiledDirective = $compile(directive)(scope);

            return compiledDirective;

        },
        compileIntoHtml: function (directiveName, scopeVariables) {

            var compiledDirective = this.compile(directiveName, scopeVariables);
            var scope             = ScopeProvider.getNewIsolatedScope(scopeVariables);

            return $interpolate(compiledDirective[0].outerHTML)(scope);

        }
    }

});