angular.module('sinvoice-mobile').controller('headerController', function ($scope, $rootScope, Auth, $translate, DISABLE_DEBUG_MODE) {
    $scope.toggleMenu = function () {
        $rootScope.isVisibleMenu = !$rootScope.isVisibleMenu;
    };

    $scope.isLanguageSwitcherShown = function () {
        return !DISABLE_DEBUG_MODE;
    };

    $scope.getCurrentLanguage = function () {
        return $translate.use();
    };

    $scope.toggleLanguage = function () {

        var lang = $scope.getCurrentLanguage();

        if (lang === 'en') {
            $translate.use('he');
        } else {
            $translate.use('en');
        }
    };


    $scope.closeMenu = function () {
        $rootScope.isVisibleMenu = false;
    };

    $scope.logout = function () {
        Auth.logout();

        $scope.closeMenu();
    }
});
