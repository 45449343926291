/**
 * Created by dbichenko on 30.12.2016.
 */
angular.module('sinvoice-desktop').controller('defaultCustomerDocumentsController',
    function ($scope,
              DocumentConstants,
              DocumentTypeConstants,
              CustomerTypeChecker) {

        $scope.$on('documents.loaded', function (e, getFilteredData, initializeDataTableColumns, initializeDataTable) {

            $scope.dtColumns       = {};
            $scope.dtOptions       = {};
            $scope.dtInstances.all = {};
            $scope.dtInstances.OI  = {};
            $scope.dtInstances.PR  = {};
            $scope.dtInstances.OR  = {};

            var tableAllPromise = function () {
                return getFilteredData();
            };
            var tableOIPromise  = function () {
                return getFilteredData(DocumentTypeConstants.TYPE_TAX_INVOICE, DocumentConstants.STATUS_OPEN);
            };
            var tablePRPromise  = function () {
                var types = [
                    DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
                    DocumentTypeConstants.TYPE_PERFORM
                ];
                return getFilteredData(types, DocumentConstants.STATUS_OPEN);
            };
            var tableORPromise  = function () {
                return getFilteredData(DocumentTypeConstants.TYPE_RECEIPT, DocumentConstants.STATUS_OPEN);
            };


            var isOccasionalCustomer = CustomerTypeChecker.isOccasional($scope.edited.customer.customerType);

            $scope.dtColumns['all']              = initializeDataTableColumns(true, isOccasionalCustomer);
            $scope.dtColumns['open_invoices']    = initializeDataTableColumns(false, isOccasionalCustomer);
            $scope.dtColumns['payment_requests'] = initializeDataTableColumns(false, isOccasionalCustomer);
            $scope.dtColumns['open_receipts']    = initializeDataTableColumns(false, isOccasionalCustomer);

            $scope.dtOptions['all']              = initializeDataTable(tableAllPromise, '#datatable-buttons-container-all', true, isOccasionalCustomer);
            $scope.dtOptions['open_invoices']    = initializeDataTable(tableOIPromise, '#datatable-buttons-container-oi', false, isOccasionalCustomer);
            $scope.dtOptions['payment_requests'] = initializeDataTable(tablePRPromise, '#datatable-buttons-container-pr', false, isOccasionalCustomer);
            $scope.dtOptions['open_receipts']    = initializeDataTable(tableORPromise, '#datatable-buttons-container-or', false, isOccasionalCustomer);
        })


    });