/**
 * Created by dbichenko on 02.03.2017.
 */
angular.module('sinvoice-desktop').controller('bookManagementController', function ($scope, Profile, $q, dateHelper, FileHelper, UserSubscription) {

    $scope.spinner = {enabled: true};

    $scope.settings = {};

    $scope.profileForm = {};

    $scope.backupInfo = {};

    $scope.profileData = {};

    $scope.files = {};

    $scope.allowedFormats = ["pdf", "doc", "docx", "ppt", "pptx", "xls", "xlsx", "png", "jpg"];


    $scope.isSubmitButtonDisabled = function () {
        return UserSubscription.isCheckInProgress();
    };


    $scope.isSaveAllowed = function () {
        return new Promise(function (resolve, reject) {

            if ($scope.profileForm.$invalid) {
                return reject(false);
            }

            return UserSubscription.checkSubscriptionForEditAction().then(function () {
                return resolve(true)
            });

        })
    };


    $scope.cancel = function () {
        $scope.settings = angular.copy($scope.backupInfo);
    };

    $scope.save = function () {

        $scope.spinner.enabled = true;
        return Profile
            .updateBookManagementSettings($scope.settings)
            .then(function (data) {
                $scope.backupInfo = angular.copy($scope.settings)
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            });

    };

    $scope.parseFile = function (index, filesToAdd) {

        if (!angular.isDefined($scope.settings.bookkeepingApprovals[index]))
            $scope.settings.bookkeepingApprovals[index] = [];

        filesToAdd.forEach(function (value) {
            $scope.settings.bookkeepingApprovals[index] = [{
                content: value.content,
                name   : value.file.name
            }]
        });

    };

    $scope.removeFile = function (index) {
        $scope.settings.bookkeepingApprovals[index] = null;
    };

    $scope.openFile = function (file, event) {
        event.preventDefault();
        FileHelper.saveBase64(file.content, file.name);
    };

    (function () {

        $scope.minDate = momentWrapped().startOf('day');

        $q
            .all([
                Profile
                    .getData()
                    .then(function (data) {
                        $scope.profileData = data
                    }),
                Profile
                    .getBookManagementSettings()
                    .then(function (data) {
                        $scope.settings   = data;
                        $scope.backupInfo = angular.copy($scope.settings);
                    })
            ])

            .finally(function () {
                $scope.spinner.enabled = false;
            })
    })();

});