/**
 * Created by abichenko on 14.12.17.
 */
angular.module('sinvoice-common')

    .factory('onInitContainer', function ($q, promiseHelper) {

        var promises                     = [];
        var repeatableAfterLoginPromises = [];
        var oneTimeAfterLoginPromises    = [];

        var onInitSequenceIsCompleted            = false;
        var repeatablePromiseSequenceIsCompleted = false;
        var oneTimePromiseSequenceIsCompleted    = false;

        var onInitSequenceCallbacks = [];

        function normalizeCallbacks(callbacks) {

            var normalized = [];
            callbacks.forEach(function (callback) {
                if (angular.isFunction(callback)) {
                    normalized.push(callback());
                } else {
                    normalized.push(callback);
                }
            });

            return normalized;

        };

        return {
            isInitSequenceCompleted: function () {
                return onInitSequenceIsCompleted;
            },
            addPromise             : function (promise) {
                promises.push(promise);
            },
            run       : function () {
                onInitSequenceIsCompleted = false;return $q.all(normalizeCallbacks(promises))
                    .finally(function () {
                        onInitSequenceIsCompleted = true;

                        onInitSequenceCallbacks.forEach(function (callback) {
                            callback();
                        })
                    });
            },

            afterLoginSequenceIsCompleted: function () {
                return repeatablePromiseSequenceIsCompleted && oneTimePromiseSequenceIsCompleted;
            },

            addAfterLoginPromise     : function (promise, deactivateAfterFirstUse) {
                if (deactivateAfterFirstUse || !angular.isDefined(deactivateAfterFirstUse)) {
                    oneTimeAfterLoginPromises.push(promise)
                } else {
                    repeatableAfterLoginPromises.push(promise);
                }

            },
            runRepeatablePromises    : function () {
                repeatablePromiseSequenceIsCompleted = false;

                return $q
                    .all(normalizeCallbacks(repeatableAfterLoginPromises))
                    .finally(function () {
                        repeatablePromiseSequenceIsCompleted = true;
                    });
            },
            runOneTimePromises       : function () {
                oneTimePromiseSequenceIsCompleted = false;

                return $q
                    .all(normalizeCallbacks(oneTimeAfterLoginPromises))
                    .finally(function () {
                        oneTimePromiseSequenceIsCompleted = true;
                    });
            },
            onInitSequenceIsCompleted: function (callback) {
                onInitSequenceCallbacks.push(callback);
            }

        }

    });