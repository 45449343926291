angular.module('sinvoice-desktop').controller('distributorsController',
    function ($scope,
              $filter,
              $compile,
              Url,
              Users,
              Referral,
              DTOptionsBuilder,
              DTColumnBuilder,
              $translate,
              $q,
              DataTableBootstrapSettings,
              Modal,
              Auth,
              API) {

     
    });
