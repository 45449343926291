/**
 * Created by dbichenko on 24.04.2017.
 */
angular.module('sinvoice-desktop').service('ManagedCustomerExport', function (ManagedCustomers, $q, FileHelper) {

    this.exportManagedCustomersToPDF   = exportManagedCustomersToPDF;
    this.printManagedCustomers         = printManagedCustomers;
    this.downloadManagedCustomersAsXLS = downloadManagedCustomersAsXLS;

    function exportManagedCustomersToPDF(customers, columns) {

        var defer = $q.defer();

        var customerIds = customers.map(function (value) {
            return value.id;
        });

        FileHelper.openPDF(function () {
            return ManagedCustomers.getPDFContent(customerIds.toString(), columns.toString())
                .then(function (data) {
                    defer.resolve();
                    return data.link;
                })
        });

        return defer.promise;
    }

    function printManagedCustomers(customers, columns) {

        var defer = $q.defer();

        var customerIds = customers.map(function (value) {
            return value.id;
        });

        FileHelper.openWindowAndPrint(function () {
            return ManagedCustomers.getPDFContent(customerIds.toString(), columns.toString())
                .then(function (data) {
                    defer.resolve();
                    return data.link;
                })
        });

        return defer.promise;
    }


    function downloadManagedCustomersAsXLS(customers, columns) {

        var customerIds = customers.map(function (value) {
            return value.id;
        });


        return ManagedCustomers.getXLSArchiveContent(customerIds.toString(), columns.toString()).then(function (data) {
            FileHelper.saveArchive(data.content, 'managed-customers.zip')
        })
    }

    return this;

});