/**
 * Created by abichenko on 20.11.17.
 */
angular.module('sinvoice-mobile').controller('extrasBlockController', function ($scope, DiscountCalculator, Events) {

    var vm = this;

    vm.toggleExtrasBlock = function () {
        vm.isExtrasBlockOpened = !vm.isExtrasBlockOpened;
    };

    vm.toggleDiscountBlock = function () {
        vm.isDiscountBlockOpened = !vm.isDiscountBlockOpened;
    };

    vm.applyDiscount = function (discount) {
        vm.discountsModel        = [discount];
        vm.isDiscountBlockOpened = false;
    };

    vm.closeDiscountBlock = function () {
        vm.isDiscountBlockOpened = false;
    };

    vm.onDiscountEnabledChange = function () {

        if (!vm.isDiscountEnabled) {
            vm.isDiscountBlockOpened = false;
            vm.discountsModel        = [];
        } else {
            vm.isDiscountBlockOpened = true;
        }

    };

    vm.getDiscountAmount = function () {
        var discounts        = vm.discountsModel;
        var templateVariable = vm.templateVariable;

        if (!discounts || !discounts[0])
            return 0;

        return DiscountCalculator.calculateValue(discounts[0], templateVariable.$itemsTotalBeforeDiscount)
    };

    vm.onIsVatParameterIncluded = function () {
        if (vm.isVatParameterIncluded === true) {
            vm.selectedVatParametersModel = 'include';
        } else {
            vm.selectedVatParametersModel = 'exclude';
        }
    };

    (function () {

        if (!angular.isDefined(vm.selectedVatParametersModel)) {
            vm.isVatParameterIncluded     = true;
            vm.selectedVatParametersModel = 'include';
        } else if (vm.selectedVatParametersModel) {
            vm.isVatParameterIncluded = vm.selectedVatParametersModel === 'include';
        }

        if (!angular.isDefined(vm.discountsModel)) {
            vm.discountsModel = [];
        }


        if (vm.discountsModel[0]) {
            vm.isDiscountEnabled = true;
        } else {
            vm.isDiscountEnabled = false;
        }

        if (!angular.isDefined(vm.isRoundingEnabled)) {
            vm.isRoundingEnabled = true;
        }

    })();

});