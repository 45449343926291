/**
 * Created by dbichenko on 20.03.2017.
 */
angular.module('sinvoice-common').factory('ProfileChecker', function () {


    return {

        isExemptDealer: function (profile) {
            return profile.businessType.type === 'exempt_dealer'
        },

        isEnglishAvailable: function (business) {
            return !!business.en.en_checkbox
        }


    }
});