/**
 * Created by abichenko on 28.11.17.
 */
angular.module('sinvoice-common').factory('DocumentModalCallbackChecker', function (CustomerTypeChecker, $filter) {

    return {

        isWithholdingTaxPopupNeedToBeShown: function (editedDocument, paymentsTotal) {

            if (!editedDocument.taxValue) {
                return false;
            }

            var taxValue = parseFloat(editedDocument.taxValue) || 0;

            if (taxValue <= (paymentsTotal)) {
                return false;
            }
            return true;
        },

        isOccasionalCustomerInfoMissingPopupNeedToBeShown: function (editedDocument) {
            if (editedDocument.taxValue <= 0) {
                return false;
            }

            if (!CustomerTypeChecker.isOccasional(editedDocument.customer.customerType)) {
                return false;
            }

            var occasionalCustomerName = editedDocument.customerOccasionalName;

            if (occasionalCustomerName === $filter('translate')('documents.use_occuring')) {
                occasionalCustomerName = '';
            }

            if (occasionalCustomerName && editedDocument.customerOccasionalVatNumber) {
                return false;
            }

            return true;
        },

        isCreditWarningPopupNeedToBeShown     : function (editedDocument) {
            return CustomerTypeChecker.isOccasional(editedDocument.customer.customerType)
        },
        isAmountDoesNotMatchPopupNeedToBeShown: function (editedDocument, paymentsTotalNIS, itemsTotalNIS) {

            if (paymentsTotalNIS.toFixed(2) === itemsTotalNIS.toFixed(2)) {
                return false;
            }
            return true;
        },

        isIssueDatePopupNeedToBeShown: function (editedDocument) {

            var editedDocumentDate = momentWrapped(editedDocument.issueDate);
            var lastDocument       = editedDocument.type.lastDocument;
            var lastDocumentDate   = momentWrapped(lastDocument ? lastDocument.issueDate : 0);

            if (editedDocument.id) {
                return false;
            }

            if (!lastDocument || (lastDocumentDate <= editedDocumentDate)) {
                return false;
            }

            return true;
        },

        isCustomerMissingForAmountPopupNeedToBeShown: function (editedDocument, paymentsTotalNIS, itemsTotalNIS) {
            var customer       = editedDocument.customer;
            var isPaymentBased = editedDocument.type.isPaymentBased;

            if (itemsTotalNIS < 5000 && !isPaymentBased) {
                return false;
            }

            if ((paymentsTotalNIS < 5000) && isPaymentBased) {
                return false;
            }

            if (!CustomerTypeChecker.isOccasional(customer.customerType) && customer.businessnumber) {
                return false;
            }

            return true;
        },
        isCustomerMissingForTaxValuePopupNeedToBeShown: function (editedDocument) {
            var customer       = editedDocument.customer;

            if (!editedDocument.taxValue) {
                return false;
            }

            if (!CustomerTypeChecker.isOccasional(customer.customerType) && customer.businessnumber) {
                return false;
            }

            return true;
        }
    }

});
