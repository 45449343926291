/**
 * Created by Front-end on 22.12.2016.
 */
angular.module('sinvoice-desktop').controller('newIncomeItemsReportBlockController', function ($scope, Item, Report, Events, $stateParams, ReportsFilterMapper, TaxPeriodProvider) {

    $scope.availableItems = [];
    $scope.spinner        = {
        enabled: true
    };
    $scope.newReportForm  = {};

    $scope.setOtherDate     = function () {
        $scope.selected.dateExample = $scope.dateExamples[4];
    };
    $scope.updateFromToDate = function (date) {
        $scope.selected.from = date.from ? date.from : new Date();
        $scope.selected.to   = date.to ? date.to : new Date();
    };


    $scope.produceNewReport = function () {

        $scope.spinner.enabled = true;
        Report.createReport('item_income', $scope.selected).then(function (data) {
            $scope.spinner.enabled = false;
            Events.triggerEvent('report.update', data);
        });

    };


    function loadItems() {
        return Report.getFilterValues('item_income')
            .then(function (data) {
                $scope.availableItems         = data.items;
                $scope.selected.filters.items = ReportsFilterMapper.getMappedValues($stateParams.items, 'id', $scope.availableItems);
            })
    }

    (function () {


        $scope.selected = {
            filters: {
                items: [],
            }
        };

        $scope.dateExamples = TaxPeriodProvider.getDatepickerValues();
        var dateFilters     = ReportsFilterMapper.getMappedDate(
            $stateParams.from,
            $stateParams.to,
            $scope.dateExamples
        );

        $scope.selected.to          = dateFilters.to;
        $scope.selected.from        = dateFilters.from;
        $scope.selected.dateExample = dateFilters.dateExample;
        
        loadItems().then(function () {
            $scope.spinner.enabled = false;
        });
    })();

});
