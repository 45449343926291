/**
 * Created by dbichenko on 03.10.2016.
 */
angular.module('sinvoice-desktop').controller('editCustomerHeaderController',
    function ($scope, CommunicationModal, Modal, $filter, DocumentModal, NotificationModal) {

        $scope.addCommunication = function () {
            if ($scope.edited.customer.contacts.length === 0) {
                Modal.alert(
                    $filter('translate')('customers.edit.communications.no_contacts_warning.message'),
                    {title: $filter('translate')('customers.edit.communications.no_contacts_warning.title')}
                );
            } else {
                CommunicationModal.newCommunication($scope.edited.customer);
            }
        };


        $scope.sendEmail = function () {
            NotificationModal.sendEmailToSingleCustomer($scope.edited.customer);
        };

        $scope.sendNewsletter = function () {
            alert('Newsletter activated');
        };

        $scope.sendSMS = function () {
            NotificationModal.sendSMSToSingleCustomer($scope.edited.customer);
        };

        $scope.produceDocument = function () {
            DocumentModal.createDocumentForCustomer($scope.edited.customer);
        }

    });
