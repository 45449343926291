/**
 * Created by abichenko on 27.12.17.
 */
angular.module('sinvoice-mobile')
    .directive('accountInputBlock', function () {
        return {
            scope           : {
                account   : '=',
                customer  : '=',
                parentForm: '='
            },
            controller      : 'accountInputBlockController',
            controllerAs    : 'vm',
            bindToController: true,
            templateUrl     : './mobile/components/new-document/partials/account-input-block.html',
            link            : function (scope, element, attrs) {

            }
        }
    });