/**
 * Created by abichenko on 06.09.17.
 */
angular.module('sinvoice-common').factory('DocumentType', function (Document, DocumentTypeConstants) {


    return {

        getAvailableDocTypes  : function () {


            var map = [
                DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT,
                DocumentTypeConstants.TYPE_TAX_INVOICE,
                DocumentTypeConstants.TYPE_RECEIPT,
                DocumentTypeConstants.TYPE_ORDER,
                DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
                DocumentTypeConstants.TYPE_PRICE_QUOTE,
                DocumentTypeConstants.TYPE_PERFORM,
                DocumentTypeConstants.TYPE_DELIVERY_NOTE,
                DocumentTypeConstants.TYPE_RETURN_FORM,
                DocumentTypeConstants.TYPE_BALANCE_ADJUSTMENT,
                DocumentTypeConstants.TYPE_RECEIPT_CREDIT,
                DocumentTypeConstants.TYPE_TAX_INVOICE_CREDIT,
                DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT_CREDIT
            ];

            return Document
                .getDocTypes().then(function (data) {

                    var values = [];

                    $.each(data, function (key, value) {
                        value.order = map.indexOf(key);
                        values.push(value);
                    });

                    var orderedValues = values.sort(function (a, b) {
                        return a.order - b.order;
                    });

                    return orderedValues;
                })
        },
        getDocTypesWithComment: function () {

            return this
                .getAvailableDocTypes()
                .then(function (values) {
                    return values.filter(function (value) {
                        return value.isInternalCommentAllowed;
                    })
                })
                .then(function (data) {
                    return data.filter(function (value) {
                        return !!value.comment;
                    })
                })


        }
    }

});