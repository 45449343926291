/**
 * Created by abichenko on 01.12.17.
 */
angular.module('sinvoice-mobile').directive('newDocumentStepThreeBlock', function () {


    return {
        scope      : {
            editedDocument   : '=',
            onBackButtonClick: '&',
            afterSave        : '&'
        },
        controller : 'newDocumentStepThreeController',
        templateUrl: './mobile/components/new-document/new-document-step-three.html',
        link       : function (scope, element, attrs) {

        }

    }
});