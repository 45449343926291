/**
 * Created by dbichenko on 27.07.2016.
 */
angular.module('sinvoice-desktop').controller('PaymentsController',
    function ($scope,
              $timeout,
              Bank,
              Currency,
              Events,
              $translate,
              DocumentModalCallbackContainer,
              DocumentSummaryDataProvider,
              DocumentPaymentAmountCalculator,
              Customer,
              DocumentInitializer,
              PaymentTypeConstants) {


        $scope.getPaymentCurrencySymbol = function () {
            return $scope.editedDocument.isNisPayments ? 'NIS' : $scope.editedDocument.currencies.name;
        };

        $scope.isLanguageEnglish = function () {
            return $scope.editedDocument.language == 'en'
        };


        $scope.addElement = function (type) {
            $scope.editedDocument.payments.push({type: type});

            Events.triggerEvent('payment.addElement', type);
            calculateData();
        };

        $scope.removeElement = function (item) {


            $scope.editedDocument.payments = $scope.editedDocument.payments.filter(function (currentElement) {
                return currentElement != item;
            });
            if(item.type === 'check'){
                removeCheckFromMainPaiment(item);
            }
        };

        $scope.isNISPaymentsCheckboxShown = function () {
            return $scope.editedDocument.currencies && $scope.editedDocument.currencies.name != 'NIS';
        };

        function removeCheckFromMainPaiment(item){
            var count_check = 0;
            angular.forEach($scope.editedDocument.payments, function (value, key) {
                if(value.type === 'check'){
                    count_check++;
                }
            });
            $scope.next_check_number = count_check > 0 ? --item.checkNumber : 0;
            $scope.next_check_data = $scope.next_check_data.add(-1, 'month').startOf('day');
        }

        function setDefaults() {
            if (!$scope.editedDocument.payments) {
                $scope.editedDocument.payments = [];
            }
            $scope.template.useTaxWithdrawal = !!Number($scope.editedDocument.taxValue);
            $scope.template.payments         = {errors: {}}

        }


        function activateTab(tab) {
            $scope.activeTab[tab] = true;
        }

        function calculateData() {

            $scope.summaries = DocumentPaymentAmountCalculator.getSummaries($scope.editedDocument.payments);

            var paymentsTotalWithVAT = DocumentPaymentAmountCalculator.calculatePaymentsTotal(
                $scope.editedDocument.payments
            );

            var totals = DocumentPaymentAmountCalculator.getPaymentTotals(
                paymentsTotalWithVAT,
                $scope.editedDocument.taxValue,
                $scope.template.useTaxWithdrawal,
                $scope.editedDocument.isNisPayments,
                $scope.editedDocument.currencies,
                $scope.editedDocument.nisCourse
            );


            $scope.template.$paymentsTotal           = totals.paymentsTotal;
            $scope.template.$paymentsTotalWithVat    = totals.paymentsTotalWithVat;
            $scope.template.$paymentsTotalWithVatNIS = totals.paymentsTotalWithVatNIS;

        }

        $scope.calculateUseTaxWithdrawal = function () {
            calculateData();
        };

        function loadCustomer(id) {
            Customer.getSingleCustomer(id).then(function (data) {
                $scope.selectedCustomerData = data;
                $scope.editedDocument.banks_customer_account = angular.copy(data.accounts);
            })
        }

        function calculatePaymentsTotalNis() {

            $scope.template.$paymentsTotalWithVatNIS = Currency.convertAmount(
                    $scope.template.$paymentsTotalWithVat,
                    $scope.editedDocument.isNisPayments ? 'NIS' : $scope.editedDocument.currencies.name,
                    'NIS',
                    $scope.editedDocument.nisCourse
                ) || 0;
        }


        (function () {

            DocumentInitializer.addInitCallback(function () {

                $scope.activeTab                                 = {};
                $scope.activeTab[PaymentTypeConstants.TYPE_CASH] = true;
                $scope.summaries                                 = undefined;
                $scope.banks                                     = null;
                $scope.cards                                     = [
                    'isracard',
                    'mastercard',
                    'visa',
                    'amex',
                    'diners'
                ];
                $scope.paymentForms                              = {};
                $scope.selectedCustomerData                      = null;
                $scope.next_check_number = 0;
                $scope.next_check_data = momentWrapped();

                $scope.$watch(function () {
                    return JSON.stringify([
                        $scope.template,
                        $scope.editedDocument.taxValue,
                        $scope.editedDocument.currencies,
                        $scope.editedDocument.nisCourse,
                        $scope.getPaymentCurrencySymbol()
                    ]);
                }, function () {
                    $scope.summaryData = DocumentSummaryDataProvider.getPaymentSummaries(
                        $scope.template,
                        $scope.editedDocument.taxValue,
                        $scope.editedDocument.currencies,
                        $scope.editedDocument.nisCourse,
                        $scope.getPaymentCurrencySymbol()
                    );
                });

                $scope.$watch(function () {
                    return JSON.stringify([
                        $scope.editedDocument.payments,
                        $scope.editedDocument.taxValue,
                        $scope.template.useTaxWithdrawal,
                        $scope.editedDocument.isNisPayments,
                        $scope.editedDocument.nisCourse
                    ])
                }, function () {

                    if($scope.editedDocument.payments.length !== 0){
                        $scope.editedDocument.payments.some(function (value) {

                            if ($scope.editedDocument.payments[$scope.editedDocument.payments.length - 1].type === 'check') {
                                $scope.next_check_number = $scope.editedDocument.payments[$scope.editedDocument.payments.length - 1].checkNumber;
                                var add_one_month = momentWrapped(new Date($scope.editedDocument.payments[$scope.editedDocument.payments.length - 1].issueDate));
                                $scope.next_check_data = add_one_month.add(1, 'month').startOf('day');
                                return true;
                            }
                        });
                    }

                    calculateData();
                });

                $scope.$watch('template.useTaxWithdrawal', function (newValue, oldValue) {
                    if (!newValue && newValue != oldValue)
                        $scope.editedDocument.taxValue = 0;

                });

                $scope.$watch('editedDocument.nisCourse', function (newVal) {
                    calculatePaymentsTotalNis();
                });

                $scope.$watch('isNISPaymentsCheckboxShown()', function (newValue, oldValue) {
                    if (newValue == oldValue || !!newValue)
                        return;

                    $scope.editedDocument.isNisPayments = false;
                });

                Events.subscribeToEvent('customer.change', $scope, function (e, newVal) {
                    loadCustomer(newVal.id);
                });

                Events.subscribeToEvent('currencies.change', $scope, function (event, newCurrency, oldCurrency) {
                    if (newCurrency == oldCurrency || !newCurrency)
                        return false;

                    calculateData();
                });

                $scope.$watch('paymentForms', function (value) {
                    Events.triggerEvent('paymentForms.update', value)
                }, true);

                setDefaults();

                if (angular.isDefined($scope.editedDocument.customer)) {
                    loadCustomer($scope.editedDocument.customer.id);
                }
            });

            DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                var invalidTabName = null;

                $.each($scope.paymentForms, function (tabName, forms) {
                    $.each(forms, function (k, form) {
                        if (form && form.$invalid && !invalidTabName) {
                            invalidTabName = tabName;
                        }
                    })
                });

                if (invalidTabName !== null) {
                    activateTab(invalidTabName);
                    flow.deny()
                }
                flow.allow();
            });

            Events.subscribeToEvent('paymentBank.change_through_select', $scope, function (event, curr_payment) {

                if (angular.isDefined($scope.editedDocument.banks_customer_account)) {
                    $scope.editedDocument.banks_customer_account.some(function(bank){
                        if (bank.bank.bank_number === curr_payment.bank.bank_number){
                            curr_payment.account = bank.account;
                            curr_payment.branch = bank.branch;
                            curr_payment.bank = bank.bank;
                            return true;
                        } else {
                            curr_payment.account = '';
                            curr_payment.branch = '';
                        }
                    });
                }

                $scope.editedDocument.payments.some(function (value) {
                    if (value.type === 'check') {
                        if(value.bank.bank_number === curr_payment.bank.bank_number){
                            curr_payment.account = value.account;
                            curr_payment.branch = value.branch;
                            return true;
                        }
                    }
                });

            });

            Events.subscribeToEvent('payment.addElement', $scope, function (event, type) {


                if (type === 'check') {

                    var first_check = '';
                    var keepGoing = true;

                    $scope.next_check_number++;

                    angular.forEach($scope.editedDocument.payments, function (value, key) {

                        if(keepGoing){
                            if(value.type === type){
                                first_check = key;
                                keepGoing = false;
                            }
                        }

                        if(value.type === type){

                            $scope.editedDocument.payments[$scope.editedDocument.payments.length - 1].checkNumber = $scope.next_check_number;
                            $scope.editedDocument.payments[$scope.editedDocument.payments.length - 1].issueDate = $scope.next_check_data;

                        $timeout(function () {
                            $scope.editedDocument.payments.some(function (payment){

                                if (angular.isDefined(payment.bank)) {
                                    $scope.editedDocument.payments[$scope.editedDocument.payments.length - 1].account = $scope.editedDocument.payments[first_check].account;
                                    $scope.editedDocument.payments[$scope.editedDocument.payments.length - 1].branch = $scope.editedDocument.payments[first_check].branch;
                                    $scope.editedDocument.payments[$scope.editedDocument.payments.length - 1].bank = $scope.editedDocument.payments[first_check].bank;
                                    return true;
                                }
                            });
                        });
                    }
                    });

                }
            })
        })();
    });