/**
 * Created by abichenko on 13.03.18.
 */
angular.module('sinvoice-desktop')
    .controller('editAttributeController', function ($scope, Attribute, Events, $filter, EditedValueComparator, DesktopModal, Modal) {

        var backup;

        $scope.spinner           = {enabled: true};
        $scope.attributeEditForm = {};
        $scope.types             = [];
        $scope.defaultOptions    = {};

        $scope.$currentModal.onCancelCallback = function () {
            if (EditedValueComparator.isObjectChangedExceptOfProps(backup, $scope.editedAttribute)) {
                return DesktopModal.unsavedChanges();
            } else {
                return true;
            }
        };

        $scope.addOptionToChoice = function () {
            $scope.editedAttribute.typeOption.choices.push('');
        };

        $scope.removeOptionFromChoice = function ($index) {
            $scope.editedAttribute.typeOption.choices.splice($index, 1);
        };

        $scope.setDefaultOptions = function () {
            $scope.editedAttribute.typeOption = $scope.defaultOptions[$scope.editedAttribute.type];
        };

        $scope.saveNewAttribute = function () {

            if ($filter('isSubmitNotAllowed')($scope.attributeEditForm))
                return false;

            $scope.spinner.enabled = true;

            Attribute
                .createAttribute($scope.editedAttribute)
                .then(function () {
                    return Modal.alert(
                        $filter('translate')('customers.edit.attributes.attribute_save_success_message'),
                        {title: $filter('translate')('customers.edit.attributes.attribute_save_success_message_title')}
                    );
                })
                .then(function () {
                    Events.triggerEvent('attributes.update');
                    $scope.$currentModal.close();
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        };

        (function () {

            if (!angular.isDefined($scope.editedAttribute)) {
                $scope.editedAttribute = {};
            }

            if (!angular.isDefined($scope.editedAttribute.typeOption)) {
                $scope.editedAttribute.typeOption = {};
            }


            Attribute.getTypes()
                .then(function (data) {
                    data.forEach(function (value) {
                        $scope.types.push(value.alias);
                        $scope.defaultOptions[value.alias] = value.defaultOptions;
                        $scope.editedAttribute.type        = $scope.types[0];
                        $scope.setDefaultOptions();
                    });

                })
                .finally(function () {
                    backup = angular.copy($scope.editedAttribute);


                    $scope.spinner.enabled = false;
                })


        })();

    });