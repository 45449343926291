/**
 * Created by abichenko on 06.10.17.
 */
angular.module('sinvoice-desktop')
    .directive('subscriptionsPaymentsBlock', function () {
        return {
            scope      : {
                paymentAmount: '=?',
                selectedPlan : "=",
                currentSubscription : "=",
                title        : '@',
                customPrice  : '=?',
                isAccountant : '=?'
            },
            templateUrl: "./desktop/components/profile/subscriptions-payments-block.html",
            link       : function (scope, element, attrs) {

            }
        }
    })
    .controller('subscriptionsPaymentsBlockController', function ($scope, Subscription, Events, $filter) {

        /*
        That commented temporary 'cause we have no different types of payments
        $scope.paymentTabs = [
            {label: 'profile.subscriptions.payment.credit_card', content: 'CC iframe'},
            /!*{label: 'profile.subscriptions.payment.paypal', content: 'PP iframe'}*!/
        ];*/

        $scope.is_refund_amount_exist = (($scope.currentSubscription.currentSubscription !== null) && $scope.currentSubscription.currentSubscription.isCancelled);

        $scope.notify = function (params) {

            return new Promise(function (resolve, reject) {
                if (params.CCode === "0") {

                    return Subscription
                        .change(
                            $scope.selectedPlan.id,
                            params.Id,
                            params.Amount,
                            params
                        )
                        .then(function () {
                            Events.triggerEvent('subscription.update');
                            resolve();
                        }, function () {
                            var message = $filter('translate')('profile.subscriptions.payment.internal_error');
                            reject(message);
                        });

                } else {
                    var message = $filter('translate')('profile.subscriptions.payment.gateway_error');
                    return reject(message);
                }
            })
        };

        /*
        * @param with_refund - param for condition counting price with refund
        * */
        $scope.getActualPrice = function (with_refund = false) {

            var price = 0;

            if ($scope.customPrice)
                price = $scope.customPrice;
            else if ($scope.selectedPlan)
                price =  $scope.selectedPlan.amountToChange;
            else
                price = 0;

            if (with_refund) {
                price = $scope.calculatePriceWithRefundAmount(price);
            }

            return price;
        };

        $scope.isEnoughBalance = function () {

            var result = false;

            if($scope.selectedPlan.amountToChange < $scope.getRefundAmount()){
                result = true;
            }

            return result;

        };

        $scope.calculatePriceWithRefundAmount = function(price) {

            if ($scope.isEnoughBalance()){
                price = $scope.getRefundAmount() - price;
            }
            else if(($scope.currentSubscription.currentSubscription !== null) && $scope.currentSubscription.currentSubscription.isCancelled){
                price = price - $scope.getRefundAmount();
            }

            return price;
        };

        $scope.getRefundAmount = function () {
            if($scope.currentSubscription.isRefundAmountExist.status)
                return $scope.currentSubscription.isRefundAmountExist.amount;
            else
                return 0;
        };


    });