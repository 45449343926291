/**
 * Created by dbichenko on 06.10.2016.
 */
angular.module('sinvoice-desktop').controller('orderController', function ($scope, DocumentTypeConstants) {

    $scope.template.$reliableTypesOfOpenDocuments = [
        DocumentTypeConstants.TYPE_PRICE_QUOTE
    ];


    $scope.template.$oneSideRelationDocTypes = [
        DocumentTypeConstants.TYPE_PRICE_QUOTE,
    ];

    (function () {

        if (!angular.isDefined($scope.editedDocument.validUntil))
            $scope.editedDocument.validUntil = momentWrapped();

    })()
});
