angular.module('sinvoice-desktop').controller('customersBookController',
    function ($scope,
              $state,
              $filter,
              Events,
              Customer,
              ReportsExport,
              dpickerSettings,
              Report,
              Modal,
              $translate) {

        $scope.currentState = $state.current.name;
        $scope.spinner      = {enabled: true};
        $scope.documents    = [];


        $scope.sendEmail = function () {
            ReportsExport.emailCustomerBook($scope.currentCustomer);

        };

        $scope.exportToPDF = function () {
            ReportsExport.showAsPDFCustomerBook($scope.currentCustomer);
        };

        $scope.downloadAsXLS = function () {
            ReportsExport.downloadCustomerBookAsXLS($scope.currentCustomer);
        };

        $scope.print = function () {
            ReportsExport.printCustomerBook($scope.currentCustomer);
        };

        $scope.$emit('onLoadReportPage', {
            state: $scope.currentState,
            page : {
                title      : $filter('translate')('reports.pages.customersBook'),
                description: '',
                alias      : 'reports.pages.customersBook'
            }
        });

        Events.subscribeToEvent('reports.changeCustomer', $scope, function (e, newCust) {
            $scope.currentCustomer = newCust;

            loadDocuments();
        });


        function loadDocuments() {

            $scope.spinner.enabled = true;

            Report
                .getCustomerBookData($scope.currentCustomer.id)
                .then(function (data) {
                    $scope.documents       = data;
                    $scope.spinner.enabled = false;
                    calculateSummary();
                });
        }

        function calculateSummary() {

            $scope.summary      = {
                expense         : 0,
                credit          : 0,
                remainingBalance: 0
            };

            $scope.documents.forEach(function (document) {
                $scope.summary.expense += Number(document.displayedTotalNIS);

                document.relatedToDocs.forEach(function (relatedToDoc) {
                    $scope.summary.credit += Number(relatedToDoc.displayedTotalNIS);
                })
            });

            $scope.summary.remainingBalance = $scope.summary.expense - $scope.summary.credit;

        }

        (function () {


            Modal.alert($translate.instant('reports.customersBook.warningPopupMessage'),{
                title:$translate.instant('reports.customersBook.warningPopupMessage_title')
            });
        })();
    });