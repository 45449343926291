angular.module('sinvoice-common')
    .factory('Document', function ($http, $q, $log, API, Url, DocumentTypeConstants) {
        return {

            create       : function (docData) {
                var deferred = $q.defer();
                API.post(Url.documents.create(), docData)
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            get          : function (id) {

                var deferred = $q.defer();
                API.get(Url.documents.get(id))
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;

            },
            sendViaEmails: function (ids, email, subject, message, language) {
                var deferred = $q.defer();
                API.post(Url.export.sendViaEmails(), {ids: ids, email: email, subject: subject, message: message, language: language})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            changeStatus : function (status, ids) {

                var defer = $q.defer();
                API.post(Url.documents.changeStatus(status), {ids: ids})
                    .success(function (data) {
                        defer.resolve(data);
                    });

                return defer.promise;
            },

            getTaxModifier: function () {
                return 0.18
            },

            getData             : function (maxResult = 0, nextWeek = 0) {
                var deferred = $q.defer();
                API.get(Url.documents.all(), {
                    maxResult: maxResult,
                    nextWeek : nextWeek
                })
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            getDocTypes         : function () {
                var deferred = $q.defer();
                API.get(Url.documents.types(), {})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            updateDocTypeComment: function (comment, commentEn, type) {
                var deferred = $q.defer();
                API.post(Url.documents.updateTypeComment(type.docType), {comment: comment, commentEn: commentEn})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;

            },

            getToken: function (id) {
                var deferred = $q.defer();
                API.post(Url.export.getExportToken(id), {})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

            printByToken          : function (token) {
                var deferred = $q.defer();
                API.get(Url.export.printableContent(token), {})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            pdfByToken            : function (token) {
                var deferred = $q.defer();
                API.get(Url.export.pdfContent(token), {})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            export                : function (ids) {
                var deferred = $q.defer();
                API.post(Url.export.zippedContent(), {ids: ids})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            showMivneSummary      : function (ids, from, to) {
                var deferred = $q.defer();
                API.post(Url.export.showMivneSummary(), {ids: ids, from: from, to: to})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            mivneSummaryPDFContent: function (ids, from, to) {
                var deferred = $q.defer();
                API.post(Url.export.mivneSummaryPDFContent(), {ids: ids, from: from, to: to})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

            exportAsMivne              : function (ids, from, to) {
                var deferred = $q.defer();
                API.post(Url.export.mivne(), {ids: ids, from: from, to: to})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            exportAsTamal              : function (ids, from, to) {
                var deferred = $q.defer();
                API.post(Url.export.tamal(), {ids: ids, from: from, to: to})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            }
            ,
            exportAsWizcount           : function (ids, from, to) {
                var deferred = $q.defer();
                API.post(Url.export.wizcount(), {ids: ids, from: from, to: to})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            wizcountSupportedDoctypes  : function () {
                var deferred = $q.defer();
                API.get(Url.export.wizcountSupportedDoctypes())
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            }
            ,
            printableContentForMultiple: function (ids) {
                var deferred = $q.defer();
                API.post(Url.export.multipleDocuments(), {ids: ids})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            }
            ,
            closeDocument              : function (id) {
                var deferred = $q.defer();
                API.post(Url.documents.close(id))
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

            printEnglishOriginal: function (id) {
                var deferred = $q.defer();
                API.post(Url.export.englishOriginal(id))
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

            printOriginalAndCopy: function (id) {
                var deferred = $q.defer();
                API.post(Url.export.originalAndCopy(id))
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

            isPaymentBased: function (docType) {
                var collection = [
                    DocumentTypeConstants.TYPE_RECEIPT,
                    DocumentTypeConstants.TYPE_RECEIPT_CREDIT,
                    DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT_CREDIT,
                    DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT
                ];

                return _.includes(collection, docType)
            },
            isCreditDoc   : function (docType) {
                var creditDocs = [
                    DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT_CREDIT,
                    DocumentTypeConstants.TYPE_TAX_INVOICE_CREDIT,
                    DocumentTypeConstants.TYPE_RECEIPT_CREDIT
                ];

                return _.includes(creditDocs, docType)
            },

            isBalanceAdjustment: function (docType) {
                return docType === DocumentTypeConstants.TYPE_BALANCE_ADJUSTMENT;
            },

            updateDocTypes      : function (data) {

                var deferred = $q.defer();

                API.patch(Url.documents.updateTypes(), data)
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;

            },
            getCheques          : function () {
                var deferred = $q.defer();

                API.get(Url.documents.cheques())
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            },
            getCreditTypes      : function () {
                var deferred = $q.defer();

                API.get(Url.documents.creditTypes())
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            },
            getPaymentBasedTypes: function () {
                var deferred = $q.defer();

                API.get(Url.documents.paymentBasedType())
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            },
            getPaymentTypes     : function () {
                var deferred = $q.defer();

                API.get(Url.documents.paymentTypes())
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            },
            getExtendedList     : function () {
                var deferred = $q.defer();
                API.get(Url.documents.all(), {
                    maxResult    : 0,
                    nextWeek     : 0,
                    allProperties: 1
                })
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },


            printEachTemplate: function (id) {
                var deferred = $q.defer();
                API.post(Url.dev.documentPdfContentForAllTemplates(), {id: id})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },

        };
    });