/**
 * Created by dbichenko on 09.08.2016.
 */
angular.module('sinvoice-common')

    .factory('dateApiNormalizer', function () {

        return {
            request: function (request) {

                return request;

                function normalizeDataRecursive(data) {

                    $.each(data, function (key, value) {

                        if (moment.isMoment(value)) {
                            data[key] = value.utc().format();
                        } else if (angular.isDate(value)) {
                            data[key] = moment(value).utc().format();
                        } else if (angular.isObject(value) || angular.isArray(value)) {
                            normalizeDataRecursive(value);
                        }

                    });
                }

                if (request.data) {
                    normalizeDataRecursive(request.data);
                }


                return request;
            }
        }
    })

    .filter('dateToUnixTime', function () {
        return function (value) {
            return moment(value).valueOf();

        }
    })

    .factory('dateHelper', function () {

        return {
            normalizeMomentObject     : function (value) {
                if (moment.isMoment(value))
                    return value;
                else {
                    return moment(value);
                }
            },
            removeUTCOffset           : function (date) {
                return moment(date).utcOffset(0).add(moment().utcOffset(), 'minutes')
            },
            isInNextWeekRange         : function (value) {
                var nextWeekStart = moment().add(1, 'weeks').startOf('isoWeek');
                var nextWeekEnd   = moment().add(1, 'weeks').endOf('isoWeek');

                var date = moment(value);

                return date <= nextWeekEnd && date >= nextWeekStart
            },
            isInNextSevenDayRange     : function (value) {
                var start = moment();
                var end   = moment().add(7, 'days');

                var date = moment(value);

                return date <= end && date >= start

            },
            normalizeToDateWithoutTime: function (date) {
                return moment(date).startOf('day');
            }
        }
    });
  