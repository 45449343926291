/**
 * Created by dbichenko on 04.11.2016.
 */
angular.module('sinvoice-desktop').factory('sendEmailPopupFunctions', function (ManagedCustomers, Customer, Referral, $q) {

    var functions = {
        'customers'               : {
            getData       : Customer.getData,
            sendEmail     : function (ids, message, customers, subject, sendCopy) {
                return Customer.sendEmail(ids, message, subject, sendCopy);
            },
            mapToRecipient: function (customer) {
                return {
                    id   : customer.id,
                    name : customer.name,
                    email: customer.email
                }
            }
        },
        'managed-customer-invites': {
            getData       : ManagedCustomers.getInvited,
            sendEmail     : function (ids, message, invites, subject, sendCopy) {
                return ManagedCustomers.sendEmailToInvited(ids, message, subject, sendCopy);
            },
            mapToRecipient: function (invite) {
                return {
                    id   : invite.id,
                    name : invite.firstName,
                    email: invite.email
                }
            }
        },
        'managed-customers'       : {
            getData       : ManagedCustomers.getAll,
            sendEmail     : function (ids, message, customers, subject, sendCopy) {
                return ManagedCustomers.sendEmail(ids, message, subject, sendCopy);
            },
            mapToRecipient: function (managedCustomer) {
                return {
                    id   : managedCustomer.id,
                    name : managedCustomer.user.name,
                    email: managedCustomer.user.email
                }
            }
        },
        'distributors'            : {
            getData       : Referral.getAll,
            sendEmail     : function (ids, message, distributors, subject, sendCopy) {

                var registered = distributors
                    .filter(function (distributor) {
                        return distributor.type == 'distributor';
                    }).map(function (distributor) {
                        return distributor.id;
                    });

                var invited = distributors
                    .filter(function (distributor) {
                        return distributor.type == 'invite';
                    }).map(function (distributor) {
                        return distributor.id;
                    });

                return $q.all([
                    Referral.sendEmail(registered, message, subject, sendCopy),
                    Referral.sendEmailToInvited(invited, message, subject, sendCopy)
                ]).then(function (data) {

                    var result = _.mergeWith(data[0], data[1], function (obj, src) {
                        if (_.isArray(obj)) {
                            return obj.concat(src);
                        }
                    });

                    return result;

                })
            },
            mapToRecipient: function (distributor) {

                if (distributor.paymentStatus) {
                    return {
                        id   : distributor.id,
                        name : distributor.firstName + ' ' + distributor.lastName,
                        email: distributor.email,
                        type : 'distributor'
                    }
                } else if (distributor.inviteStatus) {
                    return {
                        id   : distributor.id,
                        name : distributor.firstName + ' ' + distributor.lastName,
                        email: distributor.email,
                        type : 'invite'
                    }
                }


            }
        }
    };

    function getFunction(type, funcName) {

        var func = functions[type][funcName];

        if (angular.isDefined(func) && angular.isFunction(func)) {
            return func;
        } else {
            throw new Error(type + ' is not supported type for Email popup');
        }
    }

    return {
        getDataFunction     : function (type) {
            return getFunction(type, 'getData');
        },
        getSendEmailFunction: function (type) {
            return getFunction(type, 'sendEmail');
        },
        getMapperFunction   : function (type) {
            return getFunction(type, 'mapToRecipient');
        }
    }

});

