angular.module('sinvoice-desktop').controller('contactusController', function ($scope, $state, Url, API, Modal, Auth, HelpService, HelpMenuService, $timeout, $stateParams, AuthDataStorage) {
    $scope.currentState = $state;

    $scope.spinner      = {sendInquiry: false};
    $scope.isFormHidden = false;

    $scope.pageParams.hideHeader = true;

    (function () {

        HelpMenuService.setCurrentCategory(null);

        var profile   = AuthDataStorage.getProfile();
        $scope.sender = {
            email   : profile.email,
            phone   : profile.phone,
            fullName: profile.firstName + " " + profile.lastName
        };

        HelpService.loadCategories().then(function (data) {
            $timeout(function () {
                $scope.categories = data.filter(function (category) {
                    return category.id.toString() === $stateParams.category;
                })[0];
            });
        });
    })();

    $scope.sendContactForm = function () {
        if ($scope.contactusForm.$invalid) {
            return;
        } else {
            $scope.spinner.sendInquiry = true;
            API.post(Url.contacts.sendForm(), $scope.sender)
                .success(function (data) {
                    if (data.status) {
                        hideForm();
                        $scope.spinner.sendInquiry = false;
                    }
                });
        }
    }

    function hideForm() {
        $scope.isFormHidden = true;
    }

});
