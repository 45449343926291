/**
 * Created by dbichenko on 29.08.2016.
 */
angular.module('sinvoice-desktop').controller('paymentsElementController', function ($scope, Currency, Events, DocumentInitializer) {


    function calculateSummary() {
        var convertedValue;
        var amount = $scope.payment.amount;

        $scope.payment.$summary = amount;
    }

    (function () {

        DocumentInitializer.addInitCallback(function () {

            if (!$scope.payment) {
                throw new Error('$scope.payment is not specified. Please check your variable name.')
            }

            $scope.$watch('payment.amount', calculateSummary);
            $scope.$watch('editedDocument.isNisPayments', calculateSummary);

            Events.subscribeToEvent('currencies.change', $scope, function (event, newCurrency, oldCurrency) {
                if (newCurrency == oldCurrency || !newCurrency)
                    return false;

                calculateSummary();
            });
        });

    })();


});