/**
 * Created by abichenko on 01.11.17.
 */
angular.module('sinvoice-mobile').filter('paginate', function () {

    return function (value, currentPage, perPage) {

        if (!value)
            return value;

        var startFrom = (currentPage - 1) * parseInt(perPage);
        var endAt     = startFrom + parseInt(perPage);


        return value.slice(startFrom, endAt);
    }
});