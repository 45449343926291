/**
 * Created by dbichenko on 01.11.2016.
 */
angular.module('sinvoice-desktop').controller('paymentsElementPaypalController', function ($scope, Currency, Events, DocumentInitializer) {

    $scope.calculateTotal  = calculateTotal;
    $scope.calculateAmount = function () {
        var from  = $scope.editedDocument.isNisPayments ? 'NIS' : $scope.editedDocument.currencies.name;
        var to    = $scope.payment.currency.name;
        var total = $scope.payment.total;

        if (!from || !to || !total)
            return;

        $scope.payment.amount = Currency.convertAmount(total, from, to, getCustomCourse(from, to));
    };


    function calculateTotal() {
        var from   = $scope.payment.currency.name;
        var to     = $scope.editedDocument.isNisPayments ? 'NIS' : $scope.editedDocument.currencies.name;
        var amount = $scope.payment.amount;


        if (!from || !to || !amount)
            return;

        $scope.payment.total = Currency.convertAmount(amount, from, to, getCustomCourse(from, to))
    }

    function calculateSummary() {
        $scope.payment.$summary = $scope.payment.total;
    }

    function getNISCurrency() {
        return $scope.currencies[0];
    }

    function getCustomCourse(from, to) {

        if (from == 'NIS' && $scope.editedDocument.currencies.name == to)
            return $scope.editedDocument.nisCourse;

        if (to == 'NIS' && $scope.editedDocument.currencies.name == from)
            return $scope.editedDocument.nisCourse;

        return $scope.payment.currency.nisCourse;
    }

    function refreshValues() {
        calculateTotal();
        calculateSummary();
    }

    DocumentInitializer.addInitCallback(function () {
        if (!$scope.payment) {
            throw new Error('$scope.payment is not specified. Please check your variable name.')
        }

        if (!$scope.payment.currency) {
            $scope.payment.currency = getNISCurrency();
        }

        $scope.$watch('editedDocument.isNisPayments', function (newValue) {
            refreshValues();
        });

        $scope.$watch('payment.amount', refreshValues);
        $scope.$watch('payment.currency', refreshValues);
        $scope.$watch('editedDocument.nisCourse', refreshValues);

        Events.subscribeToEvent('currencies.change', $scope, function (event, newCurrency, oldCurrency) {
            if (newCurrency == oldCurrency || !newCurrency)
                return false;

            refreshValues();
        });
    });

});