/**
 * Created by abichenko on 06.10.17.
 */
angular.module('sinvoice-desktop').controller('subscriptionsMonthlyBlockController', function ($scope, Events, Subscription, $filter) {

    $scope.spinner = {enabled: false};

    $scope.getActiveUntil = function () {
        return $filter('dpickerDateFormatter')(momentWrapped($scope.currentSubscriptionData.currentPeriod.activeUntil).subtract(1, 'days'))
    }

    $scope.isPlanAvailable = function (plan) {
        return plan.maxDocuments > $scope.currentSubscriptionData.currentPeriod.selectedPlan.maxDocuments
    };

    $scope.getCustomPrice = function (plan) {
        var customPrice = plan
            ? plan.price - $scope.currentSubscriptionData.selectedPlan.price
            : 0;


        if (customPrice < 0)
            customPrice = 0;

        return Math.round(customPrice * 100) / 100;
    };

    $scope.selectMonthlyPlan = function (plan) {
        $scope.monthlyPlan = _.find($scope.plans, {'alias': plan.alias, 'type': plan.type});
    };

    $scope.change = function () {
        $scope.spinner.enabled = true;


        return Subscription
            .change($scope.monthlyPlan)
            .then(function () {
                Events.triggerEvent('subscription.update');
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            });

    }
});