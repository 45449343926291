/**
 * Created by pc on 02.08.2016.
 */
angular.module('sinvoice-desktop').controller('deliveryNoteController',

    function ($scope,
              Modal,
              $translate,
              DocumentModalCallbackContainer,
              RelatedDocumentTableCallbackContainer,
              DocumentTypeConstants) {

        $scope.template.$reliableTypesOfOpenDocuments = [
            DocumentTypeConstants.TYPE_ORDER,
            DocumentTypeConstants.TYPE_PRICE_QUOTE,
            DocumentTypeConstants.TYPE_RETURN_FORM
        ];

        $scope.template.$oneSideRelationDocTypes = [
            DocumentTypeConstants.TYPE_ORDER,
            DocumentTypeConstants.TYPE_PRICE_QUOTE
        ];


        (function () {


            if (!angular.isDefined($scope.editedDocument.deliveryDate))
                $scope.editedDocument.deliveryDate = momentWrapped();


            DocumentModalCallbackContainer.addPreSaveCallback(customerIdRequiredPopup);

            RelatedDocumentTableCallbackContainer.addPreSelectCallback(returnFormSelectPopup);


            function returnFormSelectPopup(flow, attributes) {

                var actions       = attributes.actions;
                var addedDocument = attributes.addedDocument;

                if (addedDocument.type.docType !== DocumentTypeConstants.TYPE_RETURN_FORM) {
                    flow.allow();
                    return;
                }

                Modal
                    .confirm(
                        $translate.instant('documents.on_select_related_doc_popup.message'),
                        $translate.instant('documents.delivery_note.on_select_related_doc_popup.relate_bidirectional'),
                        $translate.instant('documents.on_select_related_doc_popup.copy'),
                        {
                            closeButton: false,
                            title      : $translate.instant('documents.on_select_related_doc_popup.title')
                        }
                    )
                    .then(
                        function () {
                            flow.deny();
                            actions.relate();
                        },
                        function () {
                            flow.deny();
                            actions.copy();
                        });

            }

            function customerIdRequiredPopup(flow) {

                var customer = $scope.editedDocument.customer;

                if (customer && customer.businessnumber) {
                    flow.allow();
                    return true;
                }

                var popupFunc = function () {

                    Modal.customDialog({
                        scopeVariables: {
                            docType : $scope.editedDocument.type,
                            customer: angular.copy(customer),
                            flow    : flow,
                            onSave  : function (customer) {
                                $scope.editedDocument.customer = customer
                            }
                        },
                        title         : $translate.instant('documents.missing_info_popup.title'),
                        templateUrl   : './desktop/components/documents/popups/customer-info-missing-popup.html',
                        className     : 'centered-popup',
                        size          : "medium"
                    });

                };

                popupFunc();

            };


        })()

    });
