/**
 * Created by dbichenko on 28.10.2016.
 */
angular.module('sinvoice-desktop').controller('discountTableController', function ($scope, DocumentInitializer) {


    $scope.removeDiscount = function (discount) {
        $scope.editedDocument.discounts = $scope.editedDocument.discounts.filter(function (value) {
            return value != discount;
        })
    };

    DocumentInitializer.addInitCallback(function () {

        if (!$scope.editedDocument.discounts) {
            $scope.editedDocument.discounts = [];
        }
    });

});