/**
 * Created by dbichenko on 18.11.2016.
 */
angular.module('sinvoice-desktop')

    .directive('businessUniqueNumber', function (asyncValidatorFactory, Profile) {

        return {

            require: 'ngModel',
            link   : function (scope, element, attr, ngModel) {

                ngModel.$asyncValidators['uniqueNumber'] = asyncValidatorFactory
                    .createAsyncValidator(function (object) {
                        return Profile
                            .checkIfNumberExist(object.value)
                            .then(function (data) {
                                if (attr['initialValue'] && attr['initialValue'] === object.value) {
                                    return true;
                                } else {
                                    return !data.result
                                }
                            });
                    });
            }
        }

    })
    .directive("doctypeNumberInputGroup", function () {


        var docNumbersOffset = 100;

        return {
            scope     : {},
            link      : function (scope, element, attrs) {

                scope.inputs = [];
                scope.$watch(function () {
                    scope.inputs.forEach(function (firstModel) {

                        firstModel.$setValidity('validateFields', true);


                        scope.inputs.forEach(function (secondModel) {

                            if ((firstModel.$viewValue === '') || (secondModel.$viewValue === '')) {
                                return;
                            }

                            var firstModelValue  = Number(firstModel.$viewValue);
                            var secondModelValue = Number(secondModel.$viewValue);


                            if (!angular.isDefined(firstModelValue) || !angular.isDefined(secondModelValue)) {
                                return;
                            }


                            if (secondModel === firstModel) {
                                return;
                            }

                            if ((firstModelValue >= secondModelValue - Number(docNumbersOffset)) &&
                                (firstModelValue <= secondModelValue + Number(docNumbersOffset))) {
                                firstModel.$setValidity('validateFields', false);
                                firstModel.$setDirty();
                            }
                        });
                    });

                })
            },
            controller: function ($scope) {
                this.addInput = function (input) {
                    $scope.inputs.push(input);
                }
            }
        }
    })
    .directive("doctypeNumberInput", function ($parse) {
        return {
            require: ["ngModel", "^^doctypeNumberInputGroup"],
            link   : function (scope, element, attrs, controllers) {

                var ngModelCtrl = controllers[0];
                var groupCtrl   = controllers[1];

                groupCtrl.addInput(ngModelCtrl);
            }
        };
    })
    .directive('profilePageFooter', function () {
        return {
            scope      : {
                'onSave'                : '&',
                'onCancel'              : '&',
                'isSaveAllowed'         : '&',
                'isSubmitButtonDisabled': '=?',
            },
            templateUrl: './desktop/components/profile/partials/profile-page-footer.html',
            link       : function (scope) {
                // console.log(scope.isSaveAllowed);
            }
        }
    })
    .directive('profilePageMenu', function ($timeout) {

        function showDropdown(element) {
            element.find('.dropdown-list-group').addClass('active').show();
        }

        function hideDropdown(element) {
            element.find('.dropdown-list-group').removeClass('active').hide();
        }

        return {
            link: function (scope, element, attrs) {

                element.on('focus', '.list-group-item', function (e) {
                    showDropdown(element);
                });

                element.on('blur', '.dropdown-list-group .list-group-item', function (e) {

                    $timeout(function () {
                        if (!$(document.activeElement).hasClass('list-group-item'))
                            hideDropdown(element);
                    }, 1);

                });


                element.on('mouseenter', function (e) {
                    showDropdown(element);
                });

                element.on('mouseleave', function (e) {
                    hideDropdown(element);
                });

            }
        }
    })


;