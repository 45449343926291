/**
 * Created by abichenko on 17.11.17.
 */
angular.module('sinvoice-mobile').directive('customerBlock', function () {


    return {
        scope           : {
            selectedCustomer           : '=',
            customerNameEn             : '=',
            customerAddressEn          : "=",
            customerAddress            : "=",
            customerEmail              : "=",
            customerOccasionalVatNumber: "=",
            customerOccasionalNameEn   : "=",
            customerOccasionalName     : "=",
            isEnglishLanguageEnabled   : '=',
            isOccasionalCustomerEnabled: '='

        },
        controllerAs    : 'vm',
        bindToController: true,
        templateUrl     : './mobile/components/new-document/partials/customer-block.html',
        controller      : 'mobileCustomerBlockController',
        link            : function (scope, element, attrs) {

        }
    }
})