/**
 * Created by dbichenko on 23.09.2016.
 */
angular.module('sinvoice-desktop')
    .controller('docTypeCommentModalController',
        function ($scope, Document, Events, DocumentType, AuthDataStorage, ProfileChecker) {


            $scope.spinner       = {enabled: true};
            $scope.commentForm   = {};
            $scope.template      = {};
            $scope.documentTypes = {};
            $scope.save          = save;

            $scope.close = function () {
                $scope.$currentModal.close();
            };

            $scope.isEnglishAvailable = function () {
                return ProfileChecker.isEnglishAvailable(AuthDataStorage.getProfileBusinesses());
            };

            function save() {

                $scope.commentForm.$setSubmitted();
                if ($scope.commentForm.$invalid)
                    return false;

                Document
                    .updateDocTypeComment($scope.comment, $scope.commentEn, $scope.type)
                    .then(function () {
                        Events.triggerEvent('doctypes.update');
                        $scope.close();
                    })
            }


            function loadDocTypes() {

                return DocumentType.getAvailableDocTypes().then(function (data) {
                    $scope.documentTypes = data;
                })
            }

            (function () {

                loadDocTypes().then(function () {

                    $scope.spinner.enabled     = false;
                    $scope.template.isEditable = !$scope.type;


                    if (angular.isDefined($scope.type)) {
                        $scope.comment   = $scope.type.comment;
                        $scope.commentEn = $scope.type.commentEn;
                    } else {
                        $scope.type = $scope.documentTypes[0];
                    }

                });


            })()


        });