/**
 * Created by dbichenko on 23.11.2016.
 */
angular.module('sinvoice-desktop').controller('receiptCreditController',
    function ($scope,
              Modal,
              $translate,
              FormHelper,
              dateHelper,
              Events,
              TemplateChecker,
              DocumentModalCallbackContainer,
              RelatedDocumentTableCallbackContainer,
              DocumentModalCallbackProvider,
              DocumentModalCallbackChecker,
              CustomerTypeChecker,
              DocumentTypeConstants,
              Currency) {

        $scope.template.$reliableTypesOfOpenDocuments = [
            DocumentTypeConstants.TYPE_TAX_INVOICE_CREDIT,
            DocumentTypeConstants.TYPE_RETURN_FORM,
            'receipt_balance_adjustment'
        ];

        $scope.template.$reliableTypesOfAnyStatusDocuments = [
            DocumentTypeConstants.TYPE_RECEIPT
        ];

        $scope.template.$oneSideRelationDocTypes = [
            DocumentTypeConstants.TYPE_RETURN_FORM
        ];

        $scope.template.$isOccasionalDocsRelatingAllowed = true;


        Events.subscribeToEvent('relatedDocs.related', $scope, function (e, documents) {

            if (!$scope.template.isEditable)
                return;

            var docNumberString = documents.map(function (document) {
                return $translate.instant('documents.credit_documents_title_document_label', {
                    'docNumber': document.docNumber
                });
            }).join(', ');

            $scope.editedDocument.title = docNumberString
                ? $translate.instant('documents.credit_documents_title_placeholder', {docNumberString: docNumberString})
                : '';

        });

        (function () {

            DocumentModalCallbackContainer.addPreSaveCallback(
                DocumentModalCallbackProvider.creditDocumentOccasionalCustomerWarning($scope.editedDocument)
            );

            DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                if (!DocumentModalCallbackChecker.isAmountDoesNotMatchPopupNeedToBeShown(
                        $scope.editedDocument,
                        $scope.template.$paymentsTotalWithVatNIS,
                        $scope.template.$finalItemsTotalWithVatNIS
                    ))
                    return flow.allow();

                Modal
                    .confirm(
                        $translate.instant('documents.receipt_credit.not_match_message'),
                        null,
                        null,
                        {title: $translate.instant('documents.receipt_credit.not_match_message_title')}
                    )
                    .then(
                        flow.allow,
                        flow.deny
                    );
            });

            DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                if (!$scope.editedDocument.relatedDocs || !$scope.editedDocument.relatedDocs.length) {
                    flow.allow();
                    return true;
                }

                var relatedDocumentTotalAmount = _.sumBy($scope.editedDocument.relatedDocs, 'displayedTotal');

                if ($scope.template.$paymentsTotalWithVat > relatedDocumentTotalAmount) {

                    Modal
                        .confirm(
                            $translate.instant('documents.receipt_credit.amount_warning'),
                            null,
                            null,
                            {title: $translate.instant('documents.receipt_credit.amount_warning_title')}
                        )
                        .then(function () {
                            flow.allow();
                        });


                } else {
                    flow.allow();
                }

            });

            DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                var editedDocument = $scope.editedDocument;
                var paymentErrors  = $scope.template.payments.errors;

                paymentErrors.required = false;

                if (!editedDocument.payments.length) {
                    flow.deny();
                    paymentErrors.required = true;
                    return false;
                }

                flow.allow();
            });


            DocumentModalCallbackContainer.addPreSaveCallback(
                DocumentModalCallbackProvider.occasionalCustomerInfoMissing($scope.editedDocument, $scope.template, $scope.documentForm)
            );

            Events.subscribeToEvent('paymentForms.update', $scope, function (e, value) {

                $.each(value, (function (key, forms) {
                    $.each(forms, (function (key, form) {
                        if (!form)
                            return;
                        FormHelper.addValidator(form.amount, 'required', function (value) {
                            return !!value && value > 0;
                        })

                    }))
                }))
            });


        })();
    });