/**
 * Created by abichenko on 21.11.17.
 */
angular.module('sinvoice-mobile').controller('discountFormBlockController', function ($scope) {

    var backup = null;

    $scope.discountForm = {};

    $scope.cancel = function () {
        $scope.onCancel();
    };

    $scope.save              = function () {
        $scope.onSave({newDiscount: $scope.editedDiscount});
    };
    $scope.getDiscountAmount = function () {
        var discounts = $scope.editedDocument.discounts;
        if (!discounts || !discounts[0])
            return 0;

        return DiscountCalculator.calculateValue(discounts[0], $scope.template.$itemsTotalBeforeDiscount)

    };

    (function () {

        if (!angular.isDefined($scope.discount)) {
            $scope.editedDiscount = {type: 'fixed', value: 0};
        } else {
            $scope.editedDiscount = $scope.discount;
        }

        backup = angular.copy($scope.editedDiscount);

    })();
})