/**
 * Created by dbichenko on 02.02.2017.
 */
angular.module('sinvoice-desktop').controller('retroactiveRelatingButtonController', function ($scope, Events, TemplateChecker, DocumentInitializer) {

    var initialRelatedDocs = angular.copy($scope.editedDocument.relatedDocs);

    DocumentInitializer.addInitCallback(function () {

        if (TemplateChecker.isRetrotractiveDocsRelatingAllowed($scope)) {
            $scope.template.saveButtonDisabled = true;
        }

        Events.subscribeToEvent('relatedDocs.checkedHasRelatedDocuments', $scope, function (e, hasRelatedDocs) {
            if (!hasRelatedDocs && TemplateChecker.isRetrotractiveDocsRelatingAllowed($scope)) {
                $scope.template.saveButtonDisabled = true;
            }
        });

        Events.subscribeToEvent('relatedDocs.selectedFromTable', $scope, function (e, documents) {
            if (TemplateChecker.isRetrotractiveDocsRelatingAllowed($scope)) {
                var newDocs                        = _.differenceWith($scope.editedDocument.relatedDocs, initialRelatedDocs, function (first, second) {
                    return first.id == second.id
                });
                $scope.template.saveButtonDisabled = !newDocs.length;
            }
        });

    });
});