/**
 * Created by abichenko on 15.11.17.
 */
angular.module('sinvoice-desktop').directive('businessContact', function () {


    return {
        scope      : {
            contact           : '=',
            parentForm        : '=?',
            formIndex         : '=?',
            isEnglishAvailable: '=?',
            onRemove          : '&'


        },
        controller : 'businessContactController',
        templateUrl: './desktop/components/profile/partials/business-contact.html',
        link       : function () {

        }
    }
})