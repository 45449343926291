/**
 * Created by abichenko on 21.11.17.
 */
angular.module('sinvoice-mobile').directive('singleItem', function () {


    return {
        scope      : {
            paragraph            : "=",
            onRemove             : "&",
            selectedVatParameters: "="
        },
        controller : 'singleItemController',
        templateUrl: './mobile/components/new-document/partials/single-item.html',
        link       : function () {

        }
    }
})