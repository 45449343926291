angular.module('sinvoice-desktop').controller('thirdStepController',
    function ($scope, API, Url, growl, $state, Auth, Document, Events, DocumentTypeConstants, Modal, $filter) {

        $scope.inputs = [];

        (function () {
            var orderedDocTypes = [
                DocumentTypeConstants.TYPE_ORDER,
                DocumentTypeConstants.TYPE_PRICE_QUOTE,
                DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
                DocumentTypeConstants.TYPE_PERFORM,
                DocumentTypeConstants.TYPE_TAX_INVOICE,
                DocumentTypeConstants.TYPE_RECEIPT,
                DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT,
                DocumentTypeConstants.TYPE_DELIVERY_NOTE,
                DocumentTypeConstants.TYPE_RETURN_FORM,
                DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT_CREDIT,
                DocumentTypeConstants.TYPE_TAX_INVOICE_CREDIT,
                DocumentTypeConstants.TYPE_RECEIPT_CREDIT,
                DocumentTypeConstants.TYPE_BALANCE_ADJUSTMENT
            ];
            Document.getDocTypes().then(function (data) {

                $scope.spinner.enabled = false;
                angular.forEach(data, function (value, key) {
                    value = {
                        key  : value.docType,
                        value: value.rangeStart,
                        order: orderedDocTypes.indexOf(value.docType)
                    };
                    if (value.order != -1) {
                        $scope.inputs.push(value);
                    }
                    $scope.spinner.enabled = false;

                });
                $scope.inputs = _.sortBy($scope.inputs, [function (o) {
                    return o.order;
                }]);
            })
        })();

        $scope.save = function () {
            if ($scope.profileForm.$invalid) {
                return false;
            } else {
                $scope.spinner.enabled = true;

                new Promise(
                    function (resolve, reject) {
                        API.post(Url.register.thirdStep(), $scope.inputs)
                            .success(function (data) {
                                if (data.success) {
                                    resolve(data);
                                } else {
                                    reject();
                                    growl.error('global.REGISTER_ERROR');
                                }
                            })
                    })
                    .then(function (data) {

                        return Modal
                            .alert(
                                $filter('translate')('register.third_step.success_message'),
                                {title: $filter('translate')('register.third_step.success_message_title')}
                            )
                            .then(function () {
                                Auth.login(data);
                                $state.go('control.dashboard');
                            })
                    })

            }
        };
    });