/**
 * Created by dbichenko on 06.03.2017.
 */
/*
angular.module('sinvoice-desktop')
    .config(function ($httpProvider, $compileProvider, DOCTRINE_QUERY_PROFILER_ENABLED) {

        if (!DOCTRINE_QUERY_PROFILER_ENABLED)
            return;

        // TODO Understand why this affects obtaining scope for container at core/app.js:29
        //  Maybe just timing problem
        // $compileProvider.debugInfoEnabled(false);

        $httpProvider.interceptors.push(['$injector', function ($injector) {
            return $injector.get('DoctrineQueryNotifier');
        }]);

    })
    .factory('DoctrineQueryNotifier', function (growl, $localStorage) {

        return {
            response: function (response) {


                var count = response.headers('X-Database-Query-Count');
                if (count && count > 20) {

                    var routes = $localStorage.getItem('doctrineQuery.routes');

                    if (!_.includes(routes, response.config.url)) {

                        var text = 'Response to ' +
                            response.config.method + '  ' + response.config.url +
                            ' caused enormous database query count: <b>' + count + '</b>' +
                            '.<br>Fix it.';

                        growl.warning(text, {title: "DB Warning", ttl: 7000});


                        // routes.push(response.config.url);
                        $localStorage.setItem('doctrineQuery.routes', routes);
                    }

                }

                return response;

            }
        }

    });*/
