angular.module('sinvoice-desktop').controller('commissionsController',
    function ($scope,
              $filter,
              DataTableDatepickerLabels,
              $compile,
              Referral,
              DirectiveCompiler,
              monthsFromToday,
              DTOptionsBuilder,
              DTColumnBuilder,
              DataTableLanguageSettings,
              DataTableFunctions,
              DataTableBootstrapSettings,
              DistributorExport,
              $translate,
              $state) {

        $scope.invitedUsers = [];
        $scope.chart        = {commissions: {}};

        $scope.openPaymentTable = function () {
            $state.go('documents', {customer: 'Superinvoice'})
        };

        $scope.exportToPDF = function (id) {
            DistributorExport.exportCommissionsToPDF([id])
        };
        $scope.exportToXLS = function (id) {
            DistributorExport.downloadCommissionsAsXLS([id])
        };
        $scope.print       = function (id) {
            DistributorExport.printCommissions([id])
        };

        // load chart
        function loadCharts() {

            var months = $filter('translate')('global.monthNames').split(',').reverse();

            function sumFunc(prev, next) {
                return prev + next;
            }


            return Referral.getStats().then(function (data) {

                var now            = new Date();
                var currMonth      = Number(now.getMonth());
                var currMonthIndex = months.length - (currMonth) - 1;


                $scope.chart.commissions.data           = data.commissions.data;
                $scope.chart.commissions.paid           = data.commissions.paid;
                $scope.chart.commissions.due            = data.commissions.due;
                $scope.chart.commissions.count          = data.commissions.count;
                $scope.chart.commissions.categories     = months;
                $scope.chart.commissions.total          = data.commissions.data.length ? data.commissions.data.reduce(sumFunc) : 0;
                $scope.chart.commissions.totalPaid      = data.commissions.paid.length ? data.commissions.paid.reduce(sumFunc) : 0;
                $scope.chart.commissions.totalDue       = data.commissions.due.length ? data.commissions.due.reduce(sumFunc) : 0;
                $scope.chart.commissions.totalCount     = data.commissions.count.length ? data.commissions.count.reduce(sumFunc) : 0;
                $scope.chart.commissions.thisMonth      = data.commissions.data[currMonthIndex] || 0;
                $scope.chart.commissions.thisMonthCount = data.commissions.count[currMonthIndex] || 0;
            });
        }

        (function () {
            loadCharts();
            loadTable();
        })();


        function loadTable() {

            $scope.dtInstance = {};
            $scope.dtColumns  = initializeDataColumns();
            $scope.dtOptions  = initializeDataTable(function () {
                return Referral.getCommissions();
            });
        }


        function initializeDataColumns() {


            return [
                DTColumnBuilder
                    .newColumn('select')
                    .withTitle('<dt-select-all-checkbox/>')
                    .notSortable()
                    .withOption('width', '50px')
                    .withClass('select-checkbox')
                    .renderWith(function (data) {
                        return '<dt-select-checkbox/>';
                    }),

                DTColumnBuilder
                    .newColumn('creationTime')
                    .withTitle($translate.instant('distributors.commissions_page.date'))
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),

                DTColumnBuilder
                    .newColumn('name')
                    .withTitle($translate.instant('distributors.commissions_page.name'))
                    .withOption('render', {
                        "display": function (data, type, full) {
                            return '' +
                                '<table class="language-based-table name-wrapper">' +
                                '   <tr>' +
                                '       <td class="name-block">' + full.referral.firstName + ' ' + full.referral.lastName + '</td>' +
                                '       <td class="language-based-direction button-block">' +
                                '          <div dt-collection-button class="row-button btn export">' +
                                '               <button class="btn btn-info btn-sm mr10 btn-warning xls-export-button" ' +
                                '                       ng-click="exportToXLS(\'' + full.id + '\')">' +
                                '                       {{"customers.excel"|translate}}' +
                                '               </button>' +
                                '               <button class="btn btn-info btn-sm mr10 btn-warning pdf-export-button"' +
                                '                       ng-click="exportToPDF(\'' + full.id + '\')">' +
                                '                       PDF' +
                                '               </button>' +
                                '          </div>' +
                                '          <button ng-click="print(\'' + full.id + '\')" class="row-button btn print"></button>' +
                                '       </td>' +
                                '   </tr>' +
                                '</table>'
                        },
                        filter   : function (data, type, full) {
                            return full.referral.firstName + ' ' + full.referral.lastName
                        }
                    }),

                DTColumnBuilder
                    .newColumn('amount')
                    .withTitle($translate.instant('distributors.commissions_page.sum'))
                    .withOption('render', {
                        "_"      : 'plain',
                        "filter" : function (data, type, fullData) {
                            return data;
                        },
                        "display": function (data, type, fullData) {
                            return DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : data
                            });
                        }
                    })
            ];

        }


        function initializeDataTable(promise) {
            return DTOptionsBuilder

                .fromFnPromise(promise)
                .withBootstrap()
                .withOption('sDom', "<'row'<'col-xs-12 text-left mb20 dotted_buttons'B>>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('autoWidth', false)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withOption('createdRow', rowCallback)
                .withColumnFilter({
                    aoColumns: [
                        null,
                        {type: 'date-range', bRegex: true, bSmart: true, sRangeFormat: DataTableDatepickerLabels.fromToLabels()},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'number-range', bRegex: true, bSmart: true, 'sRangeFormat': DataTableDatepickerLabels.fromToLabels()}
                    ]
                })
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withButtons([
                    {
                        extend   : 'collection',
                        text     : '<i class="cloud-download"></i>' + $translate.instant('customers.export'),
                        buttons  : [
                            {
                                extend   : 'selected',
                                text     : $translate.instant('customers.excel'),
                                className: 'btn-warning',
                                action   : function (e, dt, node, config) {
                                    var ids = DataTableFunctions
                                        .getSelectedData(dt)
                                        .map(function (value) {
                                            return value.id;
                                        });

                                    DistributorExport.downloadCommissionsAsXLS(ids)
                                }


                            },
                            {
                                extend   : 'selected',
                                text     : 'PDF',
                                className: 'btn-warning',
                                action   : function (e, dt, node, config) {
                                    var ids = DataTableFunctions
                                        .getSelectedData(dt)
                                        .map(function (value) {
                                            return value.id;
                                        });

                                    DistributorExport.exportCommissionsToPDF(ids)
                                }

                            },
                        ],
                        autoClose: true,
                        className: 'btn-warning'
                    },
                    {

                        extend   : 'selected',
                        text     : '<i class="print"></i>' + $translate.instant('customers.printing'),
                        className: 'btn-warning',
                        action   : function (e, dt, node, config) {
                            var ids = DataTableFunctions
                                .getSelectedData(dt)
                                .map(function (value) {
                                    return value.id;
                                });

                            DistributorExport.printCommissions(ids)
                        }

                    },
                ]);

        }

        function rowCallback(nRow, aData, iDisplayIndex) {
            $compile(nRow)($scope);
        }


    });

