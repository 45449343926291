/**
 * Created by dbichenko on 29.09.2016.
 */
angular.module('sinvoice-desktop').controller('customerInfoMissingPopupController', function ($scope, Customer, Events, $filter) {


    $scope.customerMissingInfoForm = {};

    $scope.getMessage = function () {

        var translateKey;
        if ($scope.triggeredBy === 'taxValue') {
            translateKey = 'documents.missing_info_popup.body_tax_value';
        } else {
            translateKey = 'documents.missing_info_popup.body';
        }

        return $filter('documentPopupTranslate')(translateKey, $scope.docType.docType);
    };

    $scope.save = function () {

        if ($scope.customerMissingInfoForm.$invalid)
            return false;

        Customer
            .update($scope.customer.id, $scope.customer)
            .then(function () {
                $scope.flow.allow();
                $scope.$currentModal.close();
                $scope.onSave($scope.customer);
                Events.triggerEvent('customer.update');
            });
    };

    $scope.cancel = function () {
        $scope.flow.deny();
        $scope.$currentModal.close();
    };

    (function () {

        if (!$scope.customer) {
            throw new Error('Customer is not provided')
        }

        if (!$scope.flow) {
            throw new Error('flow is not defined')
        }


    })();


});