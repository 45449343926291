angular.module('sinvoice-desktop').controller('sendInvitationPopupController',
    function ($scope,
              $state,
              $filter,
              Events,
              NotificationModal,
              NotificationModalHelper,
              Referral) {


        $scope.spinner        = {enabled: false};
        // Form
        $scope.sendInvitation = sendInvitation;
        // Table
        $scope.customers      = [];
        $scope.removeCustomer = removeCustomer;
        $scope.addCustomer    = addCustomer;
        // Textarea
        $scope.message        = ($filter('translate')('common.add_managed_customer_poppup.message_block_text'));


        // Send form data
        function sendInvitation() {


            if ($filter('isSubmitNotAllowed')($scope.sendInvitationForm))
                return false;

            $scope.spinner.enabled = true;

            Referral
                .invite($scope.customers, $scope.message, $scope.resend)
                .then(function (data) {

                    Events.triggerEvent('invites.update');

                    var failureEmails = data.result.email.failure;
                    var allEmails     = failureEmails.concat(data.result.email.success);
                    var failurePhones = data.result.phone.failure;
                    var allPhones     = failurePhones.concat(data.result.phone.success);

                    var getNames                  = NotificationModalHelper.getNamesForRecipients;
                    var failedEmailRecipientNames = getNames($scope.customers, failureEmails, 'email', ['firstName', 'lastName']);
                    var emailRecipientNames       = getNames($scope.customers, allEmails, 'email', ['firstName', 'lastName']);
                    var failedPhoneRecipientNames = getNames($scope.customers, failurePhones, 'phone', ['firstName', 'lastName']);
                    var phoneRecipientNames       = getNames($scope.customers, allPhones, 'phone', ['firstName', 'lastName']);


                    NotificationModal.displaySendResultPopup(
                        failedEmailRecipientNames,
                        failedPhoneRecipientNames,
                        emailRecipientNames,
                        phoneRecipientNames
                    );

                    $scope.$currentModal.close();
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })


        };


        // Remove current customer from table
        function removeCustomer(index) {
            $scope.customers.splice(index, 1);
            // reloadTableContent();
        }

        // Add empty customet in table
        function addCustomer() {
            var customer  = Referral.createEmptyCustomer();
            customer.tool = $scope.tools[0];
            $scope.customers.push(customer);
        }

        function initializeValidators() {

            $scope.validators = {
                uniqueEmail: function (value) {
                    return Referral.checkIfExist('email', value).then(function (data) {
                        return !data.result
                    });
                },
                uniquePhone: function (value) {
                    return Referral.checkIfExist('phone', value).then(function (data) {
                        return !data.result
                    });
                }
            }
        }

        (function () {

            $scope.tools = [
                'sms', 'email'
            ];

            $scope.resendOptions = [
                'no_reminder',
                'one_week',
                'two_week',
                'month'
            ];

            $scope.resend = $scope.resendOptions[0];


            addCustomer();

            initializeValidators();
        })();
    });