angular.module('sinvoice-desktop').controller('controlController', 
    function($scope, $rootScope, $state, $stateParams, $ngBootbox, $translate) {

    $scope.currentState = $state.current.name;
    

    $scope.$on('$stateChangeStart', function(event, toState, toParams, fromState, fromParams){ 
        $scope.currentState = toState.name;
    });

   
});