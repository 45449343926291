/**
 * Created by pc on 01.08.2016.
 */

angular.module('sinvoice-desktop').controller('performController', function ($scope, DocumentTypeConstants) {

    $scope.template.$reliableTypesOfOpenDocuments = [
        DocumentTypeConstants.TYPE_ORDER,
        DocumentTypeConstants.TYPE_DELIVERY_NOTE,
        DocumentTypeConstants.TYPE_PRICE_QUOTE
    ];
    $scope.template.$oneSideRelationDocTypes      = [
        DocumentTypeConstants.TYPE_ORDER,
        DocumentTypeConstants.TYPE_DELIVERY_NOTE,
        DocumentTypeConstants.TYPE_PRICE_QUOTE
    ];


    (function () {

        if (!angular.isDefined($scope.editedDocument.validUntil))
            $scope.editedDocument.validUntil = momentWrapped();

    })()
});
