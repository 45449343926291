/**
 * Created by abichenko on 27.09.17.
 */
angular.module('sinvoice-desktop').controller('categoryController',
    function ($scope,
              HelpService,
              $stateParams,
              $timeout,
              $state,
              HelpMenuService) {

        $scope.pageParams.hideHeader = false;
        $scope.currentCategory       = null;
        $scope.openedQuestions       = [];
        $scope.questionVideos        = [];


        $scope.openQuestion = function (question, $event) {

            $event.preventDefault();

            if ($scope.isQuestionOpened(question.id)) {
                closeQuestion(question.id);
            } else {
                $scope.openedQuestions.push(question.id);
                HelpService.loadVideos(question.medias, $scope.questionVideos, question.id);
            }
        };

        function closeQuestion(id) {
            var index = $scope.openedQuestions.indexOf(id);
            $scope.openedQuestions.splice(index, 1);
        }

        $scope.isQuestionOpened = function (id) {
            return _.includes($scope.openedQuestions, id);
        };

        function updateVideos() {
            if (!$scope.currentCategory)
                return;

            var questionsWithVideos = $scope.currentCategory.questions
                .filter(function (question) {
                    return _.includes($scope.openedQuestions, question.id)
                })
                .filter(function (question) {
                    return question.medias.length > 0
                });

            var categoryVideos = $scope.currentCategory.medias;
            var questionVideos = [];

            if (questionsWithVideos.length) {
                questionsWithVideos.forEach(function (question) {
                    questionVideos = questionVideos.concat(question.medias);
                })
            }


            $scope.questionVideos = _.uniqBy(categoryVideos.concat(questionVideos), 'id');
        }

        (function () {

            if (angular.isDefined($stateParams.opened)) {
                $scope.openedQuestions = $stateParams.opened.split(',').map(function (value) {
                    return parseInt(value)
                });
            }


            $scope.$watch('openedQuestions.length', function () {

                updateVideos();

                $state.go($state.current.name, {opened: $scope.openedQuestions.join(',')}, {notify: false})
            });


            HelpService.loadCategories().then(function (data) {
                $timeout(function () {

                    $scope.currentCategory = data.filter(function (category) {
                        return category.id.toString() === $stateParams.category;
                    })[0];

                    updateVideos();

                    HelpMenuService.setCurrentCategory($scope.currentCategory);

                    $scope.pageParams.subTitle = $scope.currentCategory.title;
                });
            });

        })();
    });