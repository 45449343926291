/**
 * Created by abichenko on 01.11.17.
 */
angular.module('sinvoice-mobile')
    .directive('filterRowBlock', function ($timeout) {
        return {
            require    : '^^filterBlock',
            templateUrl: './mobile/components/my-documents/filter-row-block.html',
            transclude : true,
            scope      : {
                'title'                : '=',
                'selectedElementsCount': '=?',
                'onClose'              : '&',
                'onOpen'               : '&'
            },
            link       : function (scope, element, attrs, controller) {

                var activeClass = 'filter-row-active';
                var item        = angular.element(element).find('.filter-row');
                var opener      = item.find('.filter-panel');
                var slide       = item.find('.filter-slide');

                opener.on('click', function () {


                    item.toggleClass(activeClass);

                    if (item.hasClass(activeClass)) {
                        slide.stop(true, false).slideDown();
                        $timeout(function () {
                            scope.onOpen();
                        });
                    } else {
                        slide.stop(true, false).slideUp();
                        $timeout(function () {
                            scope.onClose();
                        });
                    }
                });

            }
        }
    })
    .directive('filterBlock', function ($timeout) {

        return {
            templateUrl: './mobile/components/my-documents/filter-block.html',
            scope      : {
                'isOpened' : '=',
                'documents': '=',
                'onFinish' : '='
            },
            controller : 'filterBlockController',
            link       : function (scope, element, attrs) {

                element.on('click', '.popup-overlay', function () {
                    $timeout(function () {
                        if (scope.isOpened)
                            scope.close();
                    });
                })

            }
        }
    });