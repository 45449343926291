/**
 * Created by abichenko on 25.09.17.
 */
angular.module('sinvoice-desktop')

    .directive('subscriptionUpgradeMoneyAmount', function () {

        return {
            transclude: true,
            replace   : true,
            template  : '' +
            '<span>' +
            '   <span class="money-amount">' +
            '       <small>{{currency|translateCurrencyLabel}}</small>' +
            '       <b>{{amount|number:2}}</b>' +
            '   </span>' +
            '   <span>/</span>' +
            '   <span ng-transclude=""></span>' +
            '   ' +
            '</span>',
            scope     : {amount: '=', currency: '='},
            link      : function (scope, element, attr) {

            }
        }
    })
    .directive('accountingSettingsBlock', function () {

        return {
            scope      : {
                'isAccountantCheckbox': '='
            },
            templateUrl: './desktop/components/profile/subscriptions-account-settings-block.html',
            link       : function () {

            }
        }

    })
    .directive('ifRefundBalanceIsEnough', function ($window, $timeout, API, Url, $filter) {

        return {
            scope: {
                amount: '=',
                selectedPlan: '=',
                tash: '=?',
            },
            templateUrl: './desktop/components/profile/subscriptions-get-by-account-balance-block.html',
            controller: ['$scope', 'Events', function ($scope, Events) {

                $scope.spinner = { enabled: false };
                $scope.isButtonShown = true;

                $scope.paySubscriptionFromBalanceButton = function () {

                    API.post(Url.subscriptions.change($scope.selectedPlan.id), {
                            params     : {
                                transactionId: null,
                                amount       : $scope.amount,
                                raw          : null,
                            },
                        }).success(function () {
                            $scope.message       = $filter('translate')('profile.subscriptions.payment.success');
                            $scope.isButtonShown = false;

                            $timeout(function () {
                                Events.triggerEvent('subscription.update');
                            });

                        }).error(function () {
                            $scope.message = $filter('translate')('profile.subscriptions.payment.internal_error');
                        });


                };

                $scope.refreshPage = function () {
                    $scope.message = null;
                    // $window.location.reload();
                }
            }],
        }
    })
        .directive('yaadpayIframe', function (AuthDataStorage, $timeout, $filter, SubscriptionsPaymentCredentialsProvider, $window) {


        function parseParams(queryString) {

            var parsed = {};

            queryString = queryString.split('?')[1];
            queryString.split('&').forEach(function (value) {
                var parts        = value.split('=');
                parsed[parts[0]] = parts[1] || null;
            });

            return parsed;
        }

        function createIframeLink(amount, description, terminalNumber, tash) {

            var profile = AuthDataStorage.getProfile();

            var params = {
                'action'     : 'pay',
                'Masof'      : terminalNumber,
                'Info'       : description,
                'UTF8'       : "True",
                'UTF8out'    : "True",
                'Amount'     : Math.round(amount * 100) / 100,
                'PageLang'   : 'HEB',
                'ClientName' : profile.firstName,
                'ClientLName': profile.lastName,
                'Sign'       : "True",
                'Tash'       : tash || '1',
                'Order'      : $window.location.origin

                // 4580000000000000
                // תעודת זהות אמיתית
                // 558139820

            };

            return 'https://icom.yaad.net/p/?' + $.param(params);
        }

        return {
            scope   : {
                amount     : '=',
                description: '=',
                tash       : '=?',
                onResult   : '&'
            },
            template: '' +
            '<div >' +
            '   <div  style="border: 1px solid #CCC; overflow: hidden" ng-if="!message && isCredentialsObtained">' +
            '      <spinner ng-show="spinner.enabled"></spinner>' +
            '      <iframe' +
            '           width="100%"' +
            '           height="450"' +
            '           id="yaad-iframe-{{iframeId}}" ' +
            '           ng-src="{{ iframeLink(amount,description)|trustedResource }}" ' +
            '           ng-show="!spinner.enabled">' +
            '      </iframe>' +
            '   </div>' +
            '   <div ng-if="message">' +
            '       <div>{{message}}</div>' +
            '       <div ng-if="isButtonShown">' +
            '           <button ng-click="refreshPage()" type="button" class="btn btn-info">' +
            '               <i class="i icon-refresh"></i>{{\'profile.subscriptions.payment.refresh_button_label\'|translate}}' +
            '           </button>' +
            '       </div>' +
            '   </div>' +
            '</div>',
            link    : function (scope, element, attrs) {


                scope.iframeId              = scope.$id;
                scope.spinner               = {enabled: false};
                scope.isButtonShown         = true;
                scope.isCredentialsObtained = false;

                SubscriptionsPaymentCredentialsProvider
                    .getYaadpayCredentials()
                    .then(function (data) {
                        $timeout(function () {
                            scope.yaadpayTerminalNumber = data.terminalNumber;
                            scope.isCredentialsObtained = true;
                        });

                    });

                scope.refreshPage = function () {
                    scope.message = null;
                    applyIframeListeners();
                };

                scope.iframeLink = function () {
                    if (!scope.yaadpayTerminalNumber)
                        return null;

                    return createIframeLink(scope.amount, scope.description, scope.yaadpayTerminalNumber, scope.tash)
                };

                scope.$watch('iframeLink()', function (newVal, oldVal) {
                    if (newVal == oldVal || !newVal)
                        return;

                    scope.refreshPage();
                });

                (function () {
                    applyIframeListeners();
                })();

                function applyIframeListeners() {

                    var iframeId       = '#yaad-iframe-' + scope.iframeId;
                    var timesRefreshed = 0;

                    $timeout(function () {

                        $(iframeId)
                            .off('load')
                            .on('load', function () {

                                if (timesRefreshed === 1) {
                                    this.contentWindow.postMessage({
                                        iframeId : iframeId,
                                        direction: 'to'
                                    }, '*');
                                }
                                timesRefreshed++;
                            });

                        $(window)
                            .off('message')
                            .one("message", onFallbackMessage);

                    });


                    function onFallbackMessage(event) {

                        var data = event.originalEvent.data;

                        if (!data)
                            return;

                        if (data.iframeId == iframeId && data.direction == 'from') {

                            var value = parseParams(data.params);
                            scope.$apply(function () {
                                scope.spinner.enabled = true;
                                scope.onResult({ params: value })
                                    .then(
                                        function () {
                                            scope.message       = $filter('translate')('profile.subscriptions.payment.success');
                                            scope.isButtonShown = false;
                                        },
                                        function (message) {
                                            scope.message = message;
                                        })
                                    .finally(function () {
                                        $timeout(function () {
                                            scope.spinner.enabled = false;
                                        })
                                    })
                            })
                        }
                    };


                };


            }
        }

    });
