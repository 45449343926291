/**
 * Created by abichenko on 14.03.18.
 */
angular.module('sinvoice-desktop')
    .filter('translateAttributeTypeLabel', function ($translate) {

        return function (value) {
            if (!value)
                return '';

            return $translate.instant('customers.edit.attributes.types.' + value);
        }

    });