/**
 * Created by abichenko on 19.12.17.
 */
angular.module('sinvoice-desktop').filter('incomeAndDebtsDataProvider', function () {


    return function (docs) {
        return docs.filter(function (doc) {
            return false;
        })
    }
});
