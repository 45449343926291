angular.module('sinvoice-common').run(function () {


    function setmomentWrappedJSDefaults() {
        moment.tz.setDefault('utc');

        window.momentWrapped = function () {

            var passedArgs;
            if (!arguments.length) {
                passedArgs = [
                    (Date.now() + (moment().local().utcOffset() * 60000))
                ];
            } else {
                passedArgs = arguments;
            }
            moment.locale('he');
            return moment.utc.apply(null, passedArgs).parseZone();

        };
    }

    setmomentWrappedJSDefaults();

});