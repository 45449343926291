(function () {
    'use strict';

    angular.module('sinvoice-desktop')
        .factory('DashboardService', function ($filter) {
            var self = this;

            var Service = {


                // Filtering widgets by columns
                filterWidgetsByColumns: function (widgets) {
                    var i   = 0,
                        obj = {};

                    widgets = Service.prepareWidgets(widgets);

                    obj.leftColumn                    = [];
                    obj.rightColumn                   = [];
                    obj.mainColumn                    = [];
                    obj.mainColumnCats                = {};
                    obj.mainColumnCats.communications = [];
                    obj.mainColumnCats.documents      = [];
                    obj.mainColumnCats.income         = [];
                    obj.mainColumnCats.account        = [];

                    widgets.sort(function (a, b) {
                        return a.order - b.order;
                    });

                    for (i; i < widgets.length; i++) {

                        if (widgets[i].position === 'left') {

                            obj.leftColumn.push(widgets[i]);
                        } else if (widgets[i].position === 'right') {

                            obj.rightColumn.push(widgets[i]);
                        } else {

                            obj.mainColumn.push(widgets[i]);
                        }
                    }

                    if (obj.mainColumn.length > 0) {

                        sortByCategories(obj);
                    }

                    function sortByCategories() {
                        var u       = 0,
                            element = null;

                        for (u = 0; u < obj.mainColumn.length; u++) {

                            if (obj.mainColumn[u].widget.category == 'documents') {

                                element = angular.copy(obj.mainColumn[u]);
                                obj.mainColumnCats.documents.push(obj.mainColumn[u]);
                            } else if (obj.mainColumn[u].widget.category == 'communications') {

                                element = angular.copy(obj.mainColumn[u]);
                                obj.mainColumnCats.communications.push(obj.mainColumn[u]);
                            } else if (obj.mainColumn[u].widget.category == 'income') {

                                element = angular.copy(obj.mainColumn[u]);
                                obj.mainColumnCats.income.push(obj.mainColumn[u]);
                            } else if (obj.mainColumn[u].widget.category == 'account') {

                                element = angular.copy(obj.mainColumn[u]);
                                obj.mainColumnCats.account.push(obj.mainColumn[u]);
                            }
                        }
                    }

                    return obj;
                },

                // Adding description for widgets by alias
                prepareWidgets: function (widgets) {
                    var i          = 0,
                        u          = 0,
                        widgetData = Service.getWidgetsDefinitions(),
                        resultArr  = [],
                        obj        = {};


                    if (widgets && widgets.length) {
                        for (i = 0; i < widgets.length; i++) {
                            for (u = 0; u < widgetData.length; u++) {
                                if (widgets[i].alias == widgetData[u].alias) {

                                    obj              = angular.copy(widgets[i]);
                                    obj.widget       = widgetData[u].data;
                                    obj.widget.title = $filter('translate')(obj.widget.title);

                                    resultArr.push(obj);
                                }
                            }
                        }
                    }


                    return resultArr;

                },


                getWidgetsDefinitions: function () {

                    return [

                        {
                            alias: 'currencies',
                            data : {
                                template   : './desktop/components/control/widgets/currencies.html',
                                title      : 'control.widgets.currencies.title',
                                isClosable : true,
                                dragability: true,
                                category   : 'account'
                            }

                        },
                        {
                            alias: 'system-messages',
                            data : {
                                template   : './desktop/components/control/widgets/system-messages.html',
                                title      : 'control.widgets.system_messages.title',
                                isClosable : true,
                                dragability: true,
                                category   : 'communications'
                            }

                        },
                        {
                            alias: 'subscription-status',
                            data : {
                                template   : './desktop/components/control/widgets/subscription-status.html',
                                title      : 'control.widgets.subscription_status.title',
                                isClosable : false,
                                dragability: false,
                                category   : 'account'
                            }

                        },
                        {
                            alias: 'income',
                            data : {
                                template    : './desktop/components/control/widgets/income.html',
                                title       : 'control.widgets.income.title',
                                isClosable  : true,
                                dragability : false,
                                closedColumn: 'left_column',
                                category    : 'income'
                            }

                        },
                        {
                            alias: 'recent-documents',
                            data : {
                                template   : './desktop/components/control/widgets/recent-documents.html',
                                title      : 'control.widgets.recent_documents.title',
                                isClosable : true,
                                dragability: false,
                                category   : 'documents'
                            }

                        },
                        {
                            alias: 'recent-actions',
                            data : {
                                template   : './desktop/components/control/widgets/recent-actions.html',
                                title      : 'control.widgets.recent_actions.title',
                                isClosable : true,
                                dragability: true,
                                category   : 'communications'
                            }

                        },
                        {
                            alias: 'checks',
                            data : {
                                template   : './desktop/components/control/widgets/checks.html',
                                title      : 'control.widgets.checks.title',
                                isClosable : true,
                                dragability: false,
                                category   : 'documents'
                            }

                        },
                        {
                            alias: 'next-week-invoices',
                            data : {
                                template   : './desktop/components/control/widgets/next-week-invoices.html',
                                title      : 'control.widgets.next_week_invoices.title',
                                isClosable : true,
                                dragability: false,
                                category   : 'documents'
                            }

                        },
                        {
                            alias: 'overdue-invoices',
                            data : {
                                template   : './desktop/components/control/widgets/overdue-invoices.html',
                                title      : 'control.widgets.overdue_invoices.title',
                                isClosable : true,
                                dragability: false,
                                category   : 'documents'
                            }

                        },
                        {
                            alias: 'receipts-without-invoices',
                            data : {
                                template   : './desktop/components/control/widgets/receipts-without-invoices.html',
                                title      : 'control.widgets.receipts_without_invoices.title',
                                isClosable : true,
                                dragability: false,
                                category   : 'income'
                            }
                        }
                    ];
                },

                getDefaultWidgetsPlacement: function () {
                    return {
                        layout : 'column',
                        widgets: [
                            {
                                "alias"   : "currencies",
                                "position": "left",
                                "order"   : 1,
                                "options" : {}
                            },
                            {
                                "alias"   : "system-messages",
                                "position": "left",
                                "order"   : 2,
                                "options" : {}
                            },
                            {
                                "alias"   : "recent-actions",
                                "position": "left",
                                "order"   : 3,
                                "options" : {}
                            },
                            {
                                "alias"   : "subscription-status",
                                "position": "right",
                                "order"   : 1,
                                "options" : {}
                            },
                            {
                                "alias"   : "income",
                                "position": "right",
                                "order"   : 2,
                                "options" : {}
                            },
                            {
                                "alias"   : "recent-documents",
                                "position": "right",
                                "order"   : 3,
                                "options" : {}
                            },

                            {
                                "alias"   : "checks",
                                "position": "",
                                "options" : {}
                            },
                            {
                                "alias"   : "next-week-invoices",
                                "position": "",
                                "options" : {}
                            },
                            {
                                "alias"   : "overdue-invoices",
                                "position": "",
                                "options" : {}
                            },
                            {
                                "alias"   : "receipts-without-invoices",
                                "position": "",
                                "options" : {}
                            }
                        ]
                    };
                }
            };

            return Service;
        });

})();