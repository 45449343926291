angular.module('sinvoice-desktop').controller('incomeAndDebtsController',
    function ($scope,
              $filter,
              $state,
              $stateParams,
              Events,

              Report) {

        $scope.isTableShown = false;
        $scope.currentState = $state.current.name;
        $scope.spinner      = {enabled: true};

        $scope.itemsTotal    = 0;
        $scope.paymentsTotal = 0;

        $scope.$emit('onLoadReportPage', {
            state: $scope.currentState,
            page : {
                title      : $filter('translate')('reports.pages.incomeAndDebts'),
                description: '',
                alias      : 'reports.pages.incomeAndDebts'
            }
        });

        $scope.getTitle = function () {
            var key = 'reports.incomeAndDebts.title';


            if ($scope.currentReport) {
                var groups = $scope.currentReport.filters.docTypes

                    .filter(function (id) {
                        return _.includes(['income', 'received', 'debts'], id)
                    });

                if (groups.length === 1) {
                    var firstElement = groups[0];

                    if (firstElement === 'received') {
                        key = 'reports.incomeAndDebts.title_received'
                    } else if (firstElement === 'income') {
                        key = 'reports.incomeAndDebts.title_income'
                    } else if (firstElement === 'debts') {
                        key = 'reports.incomeAndDebts.title_debts';
                    }
                }
            }

            return $filter('translate')(key);
        };

        function loadReport(id) {

            $scope.spinner.enabled = true;
            return Report.getSingle(id, 'income_revenue').then(function (data) {

                $scope.currentReport   = data;
                $scope.spinner.enabled = false;

            })
        }

        (function () {

            if ($stateParams.id) {
                $scope.isTableShown = true;
                loadReport($stateParams.id)
            } else {
                $scope.isTableShown = false;
            }

            Events.subscribeToEvent('report.update', $scope, function (e, newReport) {
                $scope.isTableShown = true;
                loadReport(newReport.id);
            })
        })()
    });