/**
 * Created by pc on 16.11.2016.
 */
angular.module('sinvoice-desktop').controller('priceQuoteController', function ($scope) {


    (function () {

        if (!angular.isDefined($scope.editedDocument.validUntil))
            $scope.editedDocument.validUntil = momentWrapped();

    })()
});
