/**
 * Created by Front-end on 06.01.2017.
 */
angular.module('sinvoice-desktop').controller('unsavedChangesPopupControler', function ($scope) {

    $scope.cancel = function () {
        $scope.cancelFunc();
        $scope.$currentModal.close();
    };

    $scope.ok = function () {
        $scope.okFunc();
        $scope.$currentModal.close();
    }

});