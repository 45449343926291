angular.module('sinvoice-desktop').controller('incomeItemsController',
    function ($scope,
              $translate,
              Events,
              $filter,
              Report,
              DTOptionsBuilder,
              DataTableFunctions,
              ReportsExport,
              DTColumnDefBuilder,
              DataTableLanguageSettings,
              $stateParams,
              $state) {

        $scope.currentState = $state.current.name;
        $scope.spinner      = {enabled: true};
        $scope.dt           = {};


        $scope.$emit('onLoadReportPage', {
            state: $scope.currentState,
            page : {
                title      : $filter('translate')('reports.pages.incomePerItem'),
                description: '',
                alias      : 'reports.pages.incomePerItem'
            }
        });

        function loadReport(id) {

            $scope.spinner.enabled = true;
            return Report.getSingle(id, 'item_income').then(function (data) {

                $scope.currentReport   = data;
                $scope.spinner.enabled = false;
            })
        }

        function loadDataTable() {

            $scope.dt.options = initializeDataTable();
            $scope.dt.columns = initializeDataColumns();

            function initializeDataColumns() {

                var dtColumns = [

                    DTColumnDefBuilder.newColumnDef(0),
                    DTColumnDefBuilder.newColumnDef(1),
                    DTColumnDefBuilder.newColumnDef(2),
                    DTColumnDefBuilder.newColumnDef(3),

                ];

                dtColumns.forEach(function (value) {
                    value.notSortable();
                    value.renderWith(function (data) {
                        return data;
                    });
                });

                return dtColumns;
            }

            function initializeDataTable() {


                var dtOptions = DTOptionsBuilder
                    .newOptions()
                    .withBootstrap()
                    .withOption('sDom', "Brt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                    .withOption('oLanguage', DataTableLanguageSettings)
                    .withOption('buttonContainer', '#buttons-block')
                    .withButtons([
                        {
                            extend       : 'subscriptionEditActionButton',
                            text         : $translate.instant('reports.email'),
                            className    : 'the_button_2 email',
                            extendOptions: {

                                action         : function (e, dt) {
                                    ReportsExport.email($scope.currentReport)
                                },
                                enabledCallback: function (e, dt) {
                                    return DataTableFunctions.getData(dt).length;
                                }
                            }
                        },
                        {
                            extend         : 'collection',
                            autoClose      : true,
                            text           : $translate.instant('reports.export'),
                            className      : 'the_button_2 export_data',
                            buttons        : [
                                {
                                    'text'         : $translate.instant('reports.excel'),
                                    'className'    : 'the_button_2',
                                    action         : function (e, dt) {
                                        ReportsExport.downloadAsXLS($scope.currentReport);
                                    },
                                    enabled        : false,
                                    enabledCallback: function (e, dt) {
                                        return !!$scope.currentReport.content.length;
                                    },
                                },
                                {
                                    'text'         : 'PDF',
                                    'className'    : 'the_button_2',
                                    action         : function (e, dt) {
                                        ReportsExport.showAsPDF($scope.currentReport);
                                    },
                                    enabled        : false,
                                    enabledCallback: function (e, dt) {
                                        return !!$scope.currentReport.content.length;
                                    },
                                }
                            ],
                            enabledCallback: function (e, dt) {
                                return DataTableFunctions.getData(dt).length;
                            }
                        },
                        {
                            text           : $translate.instant('reports.print'),
                            className      : 'the_button_2 print-grey',
                            action         : function (e) {
                                ReportsExport.print($scope.currentReport);
                            },
                            enabledCallback: function (e, dt) {
                                return DataTableFunctions.getData(dt).length;
                            }
                        }
                    ])
                    .withOption('order', [3, "asc"])
                    .withDisplayLength(5);

                return dtOptions;
            }
        }

        (function () {

            loadDataTable();

            if ($stateParams.id) {
                $scope.isTableShown = true;
                loadReport($stateParams.id)
            } else {
                $scope.isTableShown = false;
            }

            Events.subscribeToEvent('report.update', $scope, function (e, newReport) {
                $scope.isTableShown = true;
                loadReport(newReport.id);
            })
        })()
    });