/**
 * Created by abichenko on 23.11.17.
 */
angular.module('sinvoice-mobile')
    .directive('paymentsBlock', function () {


        return {
            scope      : {
                documentForm            : '=',
                isEnglishLanguageEnabled: '=',
                currency                : '=',
                isNisPayments           : '=',
                nisCourse               : '=',
                payments                : '=',
                taxValue                : '=',
                templateVariable        : '=',
                customer                : '='
            },
            controller : 'paymentsBlockController',
            templateUrl: './mobile/components/new-document/partials/payments-block.html',
            link       : function (scope, element, attrs) {

            }
        }
    });
