angular.module('sinvoice-desktop').controller('representativesMainInsideController',
    function ($scope,
              $translate,
              DTOptionsBuilder,
              DTColumnBuilder,
              DocumentExport,
              $compile,
              DataTableBootstrapSettings,
              ManagedCustomersManagement,
              $filter,
              ManagedCustomers,
              Document,
              DocumentModal,
              $state,
              Events,
              DataTableLanguageSettings,
              DirectiveCompiler,
              DataTableDatepickerLabels,
              TopnavContainer,
              $localStorage,
              DataTableFunctions,
              DocumentConstants,
              DocumentFiltersValues,
              CustomerTypeChecker,
              DocumentTypeConstants,
              ManagedCustomersModal,
              $q,
              $location,
              $anchorScroll) {


        $scope.documents   = [];
        $scope.activeChart = {};
        $scope.activeTab   = {all: true};

        $scope.rangeFilter = {
            to     : momentWrapped().endOf('day'),
            from   : momentWrapped().subtract(1, 'weeks'),
            enabled: false
        };

        $scope.edited.customer = ManagedCustomersManagement.getCurrentManagedCustomer();
        $scope.charts          = {};
        $scope.charts.brief    = {};
        $scope.charts.income   = {};
        $scope.charts.received = {};

        $scope.spinner         = {};
        $scope.spinner.enabled = true;

        // Select
        $scope.managedCustomers = [];
        $scope.selectIsDisabled = true;
        $scope.selectCustomer   = selectCustomer;

        $scope.export = function () {
            ManagedCustomersModal.exportManagedCustomer($scope.edited.customer.id);
        };

        (function () {
            // Get all managed customers
            ManagedCustomers.getAll().then(function (data) {
                data                    = addMainPageOption(data);
                $scope.managedCustomers = data;
                $scope.selectIsDisabled = !data.length;
            });
        })();


        Events.subscribeToEvent('$managedCustomerChanged', $scope, function () {
            $scope.edited.customer = ManagedCustomersManagement.getCurrentManagedCustomer();

            if ($scope.edited.customer.id) {
                loadCharts();
                loadTable();
            }

        });


        // Aded link on main page for select
        function addMainPageOption(arr) {
            var page = {};

            page.id        = 0;
            page.comment   = 'main page';
            page.user      = {};
            page.user.name = $filter('translate')('customers.main');

            arr.unshift(page);

            return arr;
        }

        // Set current edited customer
        function selectCustomer(item) {
            var currentState = $localStorage.getItem('currNav').currState;

            if (item.comment === 'main page') {

                if (currentState === 'customers.representatives') {
                    $state.reload();
                } else {
                    $state.go('customers.representatives');
                }
            } else {
                ManagedCustomersManagement.enableManagementMode(item.id);
            }
        };


        // Remove current edited customer after go on aver pagea
        Events.subscribeToEvent('$stateChangeStart', $scope, function () {
            $scope.edited.customer.id = null
        });

        // Load blue top menu
        (function () {
            loadNavbarMenu();

        })();
        // Load blue top menu
        function loadNavbarMenu() {

            TopnavContainer.addElement('managedCustomers', './desktop/components/customers/representatives/managed-customers-navbar.html');
            $scope.$on('$destroy', function () {
                TopnavContainer.removeElement('managedCustomers');
            });
        }


        $scope.toggleDateFilter = function () {
            $scope.rangeFilter.enabled = !$scope.rangeFilter.enabled;
            $scope.applyDateFilter();
            $location.hash('filter_action');
            $anchorScroll();
        };

        $scope.applyDateFilter = function () {
            updateTables();
        };

        function updateTables() {
            $.each($scope.dtInstances, function (k, instance) {
                instance.reloadData(null, true);
            })
        }

        function loadData() {
            $q.all([
                loadCharts(),
                loadTable(),
            ]).then(function () {
                $scope.spinner.enabled = false;
            })

        }

        function loadCharts() {

            var months = $filter('translate')('global.monthNames').split(',').reverse();

            $scope.activeChart                = 'income';
            $scope.charts.income.categories   = months;
            $scope.charts.received.categories = months;


            return ManagedCustomers
                .stats($scope.edited.customer.id)
                .then(function (data) {
                        $scope.charts.brief.data    = data.brief;
                        $scope.charts.income.data   = data.income;
                        $scope.charts.received.data = data.received;
                    }
                );
        }

        // 
        function loadDocuments() {
            return ManagedCustomers
                .getDocuments($scope.edited.customer.id)
                .then(function (data) {
                    $scope.documents = data;
                });
        }

        function getFilteredData(docType, status) {

            var defer = $q.defer();

            var criteria = {};

            var documents = angular.copy($scope.documents);

            if (angular.isArray(docType)) {
                documents = documents.filter(function (document) {
                    return _.includes(docType, document.type.docType);
                })
            } else {
                criteria.type = {docType: docType};
            }

            if (typeof  status != 'undefined')
                criteria.status = status;

            var data = Object.keys(criteria).length
                ? $filter('filter')(documents, criteria, true)
                : documents;


            if ($scope.rangeFilter.from && $scope.rangeFilter.to && $scope.rangeFilter.enabled) {
                data = data.filter(function (document) {
                    var issueDate = momentWrapped(document.issueDate);

                    console.log(issueDate, $scope.rangeFilter.to, $scope.rangeFilter.from);
                    return (issueDate <= $scope.rangeFilter.to) && (issueDate >= $scope.rangeFilter.from);
                })
            }

            defer.resolve(data);

            return defer.promise;
        }

        function loadTable() {

            $scope.dtColumns   = {};
            $scope.dtOptions   = {};
            $scope.dtInstances = {};

            var documentsPromise = loadDocuments();

            documentsPromise.then(function () {

                var tableAllPromise = function () {
                    return getFilteredData();
                };
                var tableOIPromise  = function () {
                    return getFilteredData(DocumentTypeConstants.TYPE_TAX_INVOICE, DocumentConstants.STATUS_OPEN);
                };
                var tablePRPromise  = function () {
                    var types = [
                        DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
                        DocumentTypeConstants.TYPE_PERFORM
                    ];
                    return getFilteredData(types, DocumentConstants.STATUS_OPEN);
                };
                var tableORPromise  = function () {
                    return getFilteredData(DocumentTypeConstants.TYPE_RECEIPT, DocumentConstants.STATUS_OPEN);
                };

                $scope.dtColumns.all          = initializeDataTableColumns(false);
                $scope.dtColumns.removeStatus = initializeDataTableColumns(true);

                $scope.dtOptions['all']              = initializeDataTable(tableAllPromise, '#button_container_all', false);
                $scope.dtOptions['open_invoices']    = initializeDataTable(tableOIPromise, '#button_container_OI', true);
                $scope.dtOptions['payment_requests'] = initializeDataTable(tablePRPromise, '#button_container_PR', true);
                $scope.dtOptions['open_receipts']    = initializeDataTable(tableORPromise, '#button_container_OR', true);

            });

            $scope.dtInstantiateDataTableAll = function (instance) {
                $scope.dtInstances.all = instance;
            };
            $scope.dtInstantiateDataTableOI  = function (instance) {
                $scope.dtInstances.OI = instance;
            };
            $scope.dtInstantiateDataTablePR  = function (instance) {
                $scope.dtInstances.PR = instance;
            };
            $scope.dtInstantiateDataTableOR  = function (instance) {
                $scope.dtInstances.OR = instance;
            };

            return documentsPromise;
        }


        function initializeDataTableColumns(removeStatus) {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('select')
                    .withTitle('<dt-select-all-checkbox/>')
                    .withClass('select-checkbox')
                    .withOption('width', '50px')
                    .notSortable()
                    .renderWith(function (data) {
                        return '<dt-select-checkbox class="check-area"></dt-select-checkbox>';
                    }),


                DTColumnBuilder
                    .newColumn('status')
                    .withTitle($translate.instant('customers.managed_customer.status'))
                    .renderWith(function (data) {
                        return '<span class="hide-content">' + data + '</span><i class="status-state"></i>'
                    }),


                DTColumnBuilder
                    .newColumn('docNumber')
                    .withTitle($translate.instant('customers.managed_customer.doc_number'))
                    .renderWith(function (data) {
                        return '<span class="num-link">' + data + '</span>'
                    })
                ,


                DTColumnBuilder
                    .newColumn('type.docType')
                    .withTitle($translate.instant('customers.managed_customer.doc_type'))
                    .renderWith(function (data) {
                        return $filter('translateDocType')(data)
                    }),


                DTColumnBuilder
                    .newColumn('issueDate')
                    .withTitle($translate.instant('customers.managed_customer.date'))
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),


                DTColumnBuilder
                    .newColumn('customer.name')
                    .withTitle($translate.instant('customers.managed_customer.customer'))
                    .renderWith(function (data, type, fullData) {
                        if (CustomerTypeChecker.isOccasional(fullData.customer.customerType)) {
                            return $translate.instant('documents.use_occuring')
                        } else {
                            return fullData.customer.name;
                        }
                    }),

                DTColumnBuilder
                    .newColumn('title')
                    .withTitle($translate.instant('customers.managed_customer.title_main')),
                DTColumnBuilder
                    .newColumn('status')
                    .withTitle($translate.instant('customers.managed_customer.open_closed'))
                    .renderWith(function (data) {
                        return data === 0
                            ? $translate.instant('customers.managed_customer.statuses.open')
                            : $translate.instant('customers.managed_customer.statuses.closed')
                    }),
                DTColumnBuilder
                    .newColumn('amount')
                    .withTitle($translate.instant('customers.managed_customer.amount'))
                    .withOption('width', '170px')
                    .withOption('createdCell', function (td, cellData, rowData, row, col) {
                            var cell = $(td);
                            if (!Document.isCreditDoc(rowData.type.docType) && !Document.isBalanceAdjustment(rowData.type.docType))
                                cell.addClass('green-text');
                            else {
                                cell.addClass('red-text')
                            }
                        }
                    )
                    .withOption('render', {

                        "sort"   : function (data, type, fullData) {
                            return fullData.displayedTotalNIS;
                        },
                        "filter" : function (data, type, fullData) {
                            return fullData.displayedTotalNIS;
                        },
                        "display": function (data, type, fullData) {
                            return DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : fullData.displayedTotalNIS
                            });
                        }
                    }),

                DTColumnBuilder
                    .newColumn('balance')
                    .withTitle($translate.instant('documents.table.balance'))
                    .withOption('width', '170px')
                    .withOption('createdCell', function (td, cellData, rowData, row, col) {
                            var cell = $(td);
                            if (!Document.isCreditDoc(rowData.type.docType) && !Document.isBalanceAdjustment(rowData.type.docType))
                                cell.addClass('green-text');
                            else {
                                cell.addClass('red-text')
                            }
                        }
                    )
                    .withOption('render', {

                        "sort"   : function (data, type, fullData) {
                            return fullData.remainingBalanceNIS;
                        },
                        "filter" : function (data, type, fullData) {
                            return fullData.remainingBalanceNIS;
                        },
                        "display": function (data, type, fullData) {

                            var value;
                            if (angular.isNumber(parseFloat(fullData.remainingBalanceNIS) == 0) || fullData.remainingBalanceNIS == null)
                                value = 0;
                            else
                                value = fullData.remainingBalanceNIS;


                            return DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : value
                            });
                        }
                    })


            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            if (removeStatus) {
                delete dtColumns[1];
                return dtColumns;
            } else {
                return dtColumns;

            }
        }

        function initializeDataTable(promise, buttonContainer, removeStatus) {

            var filterValues = {
                types    : [],
                customers: []
            };

            function promiseCallback() {
                return promise().then(function (documents) {
                    loadFilters(documents, filterValues);
                    return documents
                })
            }

            function loadFilters(existingDocuments, filterValues) {

                // We cannot just replace filterValues with empty array because reference will be lost
                // So we should cleanup original one

                $.each(filterValues, function (key, value) {
                    value.splice(0, value.length);
                });

                DocumentFiltersValues
                    .getTypeFilterValues(existingDocuments)
                    .forEach(function (val) {
                        filterValues.types.push(val);
                    });

                DocumentFiltersValues
                    .getCustomerFilterValues(existingDocuments)
                    .forEach(function (val) {
                        filterValues.customers.push(val);
                    });
            }


            var oaColumn = [

                null,
                {
                    type  : 'select',
                    bRegex: false,
                    bSmart: false,
                    values: DocumentFiltersValues.getStatuses()
                },
                {type: 'text', bRegex: true, bSmart: true},
                {type: 'select', bRegex: false, bSmart: false, values: filterValues.types},
                null,
                {type: 'select', bRegex: false, bSmart: false, values: filterValues.customers},
                {type: 'text', bRegex: true, bSmart: true},
                {type: 'text', bRegex: true, bSmart: true},
                {type: 'number-range', bRegex: true, bSmart: true, 'sRangeFormat': DataTableDatepickerLabels.fromToLabels()},
                {type: 'number-range', bRegex: true, bSmart: true, 'sRangeFormat': DataTableDatepickerLabels.fromToLabels()},


            ];

            if (removeStatus) {
                oaColumn.splice(1, 1)
            }

            return DTOptionsBuilder
                .fromFnPromise(promiseCallback)
                .withBootstrap()
                .withOption('sDom', "brt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withColumnFilter({
                    aoColumns: oaColumn
                })
                .withOption('oLanguage', DataTableLanguageSettings)
                .withOption('autoWidth', false)
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withOption('buttonContainer', buttonContainer)
                .withOption('createdRow', rowCallback)
                .withOption('order', [5, 'desc'])
                .withButtons([

                    {
                        extend       : 'subscriptionEditActionButton',
                        className    : 'the_button_2 email',
                        text         : $translate.instant('customers.managed_customer.email'),
                        extendOptions: {
                            enabledCallback: function (e, dt) {
                                return !!DataTableFunctions.getSelectedData(dt).length;
                            },
                            action         : function (e, dt, node, config) {
                                var documents = DataTableFunctions.getSelectedData(dt);
                                DocumentExport.email(documents, $scope.edited.customer.user.email);
                            }
                        }
                    },

                    {
                        extend   : 'selected',
                        text     : $translate.instant('customers.managed_customer.download'),
                        className: 'the_button_2 download',
                        action   : function (e, dt) {
                            var ids = DataTableFunctions.getSelectedData(dt).map(function (document) {
                                return document.id;
                            });

                            DocumentExport.downloadAsPdf(ids);
                        }
                    },

                    {
                        extend   : 'selected',
                        text     : $translate.instant('customers.managed_customer.print'),
                        className: 'the_button_2 print',
                        action   : function (e, dt, node, config) {
                            var ids = DataTableFunctions.getSelectedData(dt).map(function (document) {
                                return document.id;
                            });

                            DocumentExport.print(ids);
                        }
                    },
                    {
                        extend: 'selected',
                        action: function (e, dt, node, config) {

                            var ids = DataTableFunctions.getSelectedData(dt).map(function (document) {
                                return document.id;
                            });

                            DocumentExport.showAsPDF(ids);

                        },
                        text  : '<i class="fa fa-eye"></i>' + $translate.instant('documents.table.view_document')
                    }
                ]);
        }


        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

            $compile(nRow)($scope);

            if (aData.status != DocumentConstants.STATUS_OPEN) {
                $(nRow).addClass('disabled');
            }

            $(nRow).on('click', '.num-link', function (e) {
                DocumentModal.viewDocument(aData.id);
                e.stopPropagation();

            });

        }

        (function () {
            loadData();

        })();
    });