/**
 * Created by Front-end on 17.01.2017.
 */
angular.module('sinvoice-desktop').controller('ordersBookTableTemplateController',
    function ($scope, DTColumnDefBuilder, DTOptionsBuilder, DataTableLanguageSettings) {

        $scope.dt = {};

        function loadDataTable() {

            $scope.dt.options  = initializeDataTable();
            $scope.dt.columns  = initializeDataColumns();
            $scope.dt.instance = {};

            function initializeDataColumns() {

                var dtColumns = [


                    DTColumnDefBuilder.newColumnDef(0).renderWith(function (data) {
                        return data;
                    }),
                    DTColumnDefBuilder.newColumnDef(1),
                    DTColumnDefBuilder.newColumnDef(2),
                    DTColumnDefBuilder.newColumnDef(3)

                ];

                dtColumns.forEach(function (value) {
                    value.notSortable();
                });

                return dtColumns;
            }

            function initializeDataTable() {


                var dtOptions = DTOptionsBuilder
                    .newOptions()
                    .withBootstrap()
                    .withOption('sDom', "rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                    .withOption('oLanguage', DataTableLanguageSettings)
                    .withOption('order', [2, "desc"])
                    .withDisplayLength(10);

                return dtOptions;
            }
        }


        (function () {
            loadDataTable();
        })()
    });