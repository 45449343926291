angular.module('sinvoice-desktop').controller('currenciesController',
    function ($scope, $window, $rootScope, $filter, API, Url, DTOptionsBuilder, DTColumnBuilder, $translate, Currency) {

        $scope.spinner    = true;
        $scope.currencies = null;

        (function () {
            Currency.getData().then(function (data) {
                $scope.currencies = data;
                $scope.spinner    = false;
            });
        })()
    });