/**
 * Created by abichenko on 11.12.17.
 */
angular.module('sinvoice-desktop').factory('NotificationSendCopyLabelHelper', function (Auth, $filter, Profile, Modal) {


    return {

        getDefaultValue : function () {

            if (Auth.getProfileValue('isSendCopyEnabledByDefault')) {
                return true;
            } else {
                return false;
            }

        },
        onChangeCallback: function (sendCopyValue) {

            if (!sendCopyValue && Auth.getProfileValue('isSendCopyEnabledByDefault')) {
                Modal
                    .confirm(
                        $filter('translate')('common.notifications.send_email_popup.no_copy_confirm_popup_text'),
                        null,
                        null,
                        {title: $filter('translate')('common.notifications.send_email_popup.no_copy_confirm_popup_title')}
                    )
                    .then(function () {
                        Auth.changeSendCopyEnabledByDefault(false);
                        return Profile.updateSendCopyEnabledByDefault(false)
                    })
            }

        }
    }

});