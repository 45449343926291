angular.module('sinvoice-desktop').controller('subscriptionsController', function ($scope, Profile, Subscription, $q, Events) {

    $scope.spinner                 = {enabled: true};
    $scope.currentSubscriptionData = {};
    $scope.subscription            = {};
    $scope.plans                   = [];


    $scope.notify = function (params) {

    };

    $scope.isStarterPackageActive = function () {
        return $scope.currentSubscriptionData.selectedPlan === 'starter';
    };

    $scope.isMonthlySubscriptionAvailable = function () {
        return !!$scope.currentSubscriptionData.currentPeriod;
    };


    function loadData() {
        return $q.all([
            Subscription.getCurrent().then(function (data) {
                $scope.subscription            = data.content;
                $scope.currentSubscriptionData = angular.copy(data.content);
            }),

            Subscription.getAll().then(function (data) {
                $scope.plans = data.content;
            })

        ]);

    }

    (function () {

        Events.subscribeToEvent('subscription.update', $scope, function (event) {

            $scope.spinner.enabled = true;
            loadData().finally(function () {
                $scope.spinner.enabled = false;
            });
        });

        loadData().finally(function () {
            $scope.spinner.enabled = false;
        });
    })();
});