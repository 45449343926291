/**
 * Created by abichenko on 31.10.17.
 */
angular.module('sinvoice-mobile').directive('paginatorBlock', function () {

    return {
        templateUrl: './mobile/components/mobile-common/paginator-block.html',
        scope      : {
            perPage : "@",
            elements: "=",
            onChange: '='
        },
        controller : 'paginatorBlockController',
        link       : function (scope, element, attrs, ctrl, transclude) {

        }

    }


});