/**
 * Created by dbichenko on 15.05.2017.
 */
angular.module('sinvoice-desktop').controller('paymentsElementBankController',
    function ($scope, BankProvider, $filter, BankChecker, BusinessBankAccountDataProvider, DocumentInitializer, Events) {

        $scope.getBankName = function (bank) {

            if (!bank)
                return '';

            return BankChecker.isCustomBank(bank)
                ? $filter('translate')('common.bank.custom')
                : bank.bank_name
        };

        $scope.isLanguageEnglish = function () {
            return $scope.editedDocument.language == 'en'
        };

        $scope.isCustomBankSelected = function () {
            return BankChecker.isCustomBank($scope.payment.bank);
        };

        $scope.changeThroughSelect = function (curr_payment) {
            Events.triggerEvent('paymentBank.change_through_select', curr_payment);
        };

        function updateAvailableBanks() {

            BankProvider.getAll(true).then(function (data) {

                $scope.banks = data;

                if (!$scope.payment.bank)
                    return;

                if (!_.includes(_.map($scope.banks, 'id'), $scope.payment.bank.id)) {
                    $scope.payment.bank = null;
                }

            });

        }

        DocumentInitializer.addInitCallback(function () {
            $scope.banks = [];


            $scope.$watch('payment.bank', function (newValue, oldValue) {
                if (newValue == oldValue)
                    return;

                if (!BankChecker.isCustomBank(newValue))
                    $scope.payment.customBankName = null;
            });

            if (!angular.isDefined($scope.payment.bank) && $scope.selectedCustomerData) {
                $scope.payment.bank = BusinessBankAccountDataProvider.getBank($scope.selectedCustomerData);
            }

            if (!angular.isDefined($scope.payment.customBankName) && $scope.selectedCustomerData) {
                $scope.payment.customBankName = BusinessBankAccountDataProvider.getCustomBankName($scope.selectedCustomerData);
            }

            updateAvailableBanks();
        });


    });