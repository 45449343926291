angular.module('sinvoice-desktop').controller('incomeForAccountingController',
    function ($scope,
              DTOptionsBuilder,
              DTColumnDefBuilder,
              $translate,
              DataTableFunctions,
              DataTableLanguageSettings,
              DataTableBootstrapSettings,
              dpickerSettings,
              Document,
              IncomeForAccountingHelper,
              DocumentExport,
              $filter,
              $state,
              DocumentTypeConstants,
              $q,
              $stateParams,
              Events,
              ReportsExport,
              Report) {

        $scope.isTableShown = false;
        $scope.currentState = $state.current.name;
        $scope.spinner      = {enabled: true};

        $scope.dt            = {};
        $scope.itemsTotal    = 0;
        $scope.paymentsTotal = 0;


        $scope.getTitle = function () {
            var key = 'reports.incomeForAccounting.title';


            if ($scope.currentReport) {

                var allGroupsSelected = false;
                var groups            = getReportUsedGroups();
                if (_.includes(groups, 'total_income_received')) {
                    allGroupsSelected = true;
                }

                var incomeSelected   = _.includes(groups, 'total_income');
                var receivedSelected = _.includes(groups, 'total_received');

                if (incomeSelected && receivedSelected) {
                    allGroupsSelected = true;
                }

                if (allGroupsSelected) {
                    key = 'reports.incomeForAccounting.title_income_and_received'
                } else if (incomeSelected) {
                    key = 'reports.incomeForAccounting.title_income'
                } else if (receivedSelected) {
                    key = 'reports.incomeForAccounting.title_received'
                }
            }

            return $filter('translate')(key);
        };


        $scope.isPaymentShown = function (item) {

            var types = [
                DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT_CREDIT,
                DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT,
            ];

            if (_.includes(item.docType, types)) {
                return true;
            }

            if ($scope.isReceivedSummaryShown()) {
                return true;
            }


            return false;
        };

        $scope.filterIsCurrenciesHasNis = function(items) {
            if(items) {
                var allIsNIS = Object.keys(items).some(function (key) {
                    return key === 'NIS';
                });

                if(Object.keys(items).length > 1 && allIsNIS) {
                    return items;
                }
            }
            return [];
        };

        $scope.currencyLength = function(object){

            if(!object)
                return;

            return Object.keys(object).length > 1 ? true : false;

        };


        $scope.isReceivedSummaryShown = function () {
            var groups = getReportUsedGroups();
            return !(groups.length === 1 && groups[0] === 'total_income');
        };

        $scope.isIncomeSummaryShown = function () {
            var groups = getReportUsedGroups();
            return !(groups.length === 1 && groups[0] === 'total_received');
        };


        $scope.sendToEmail = function () {
            ReportsExport.email($scope.currentReport);
        };

        $scope.printTable = function (id) {
            ReportsExport.print($scope.currentReport);

        };

        $scope.$emit('onLoadReportPage', {
            state: $scope.currentState,
            page : {
                title      : $filter('translate')('reports.pages.incomeForAccounting'),
                description: '',
                alias      : 'reports.pages.incomeForAccounting'
            }
        });

        function getReportUsedGroups() {
            if (!$scope.currentReport)
                return [];

            return $scope.currentReport.filters.docTypes
                .filter(function (id) {
                    return _.includes(['total_income_received', 'total_received', 'total_income'], id)
                });
        }

        function loadReport(id) {

            $scope.spinner.enabled = true;

            return $q
                .all([
                    IncomeForAccountingHelper.$loadingPromise,
                    Report.getSingle(id, 'bookkeeping_income').then(function (data) {
                        $scope.currentReport = data;
                    }),
                    Document.getPaymentTypes().then(function (data) {
                        $scope.paymentTypes = data.content;
                    })
                ])
                .finally(function () {
                    $scope.spinner.enabled = false;
                });
        }


        function loadDataTable() {

            $scope.dt.options = initializeDataTable();
            $scope.dt.columns = initializeDataColumns();

            function initializeDataColumns() {

                var dtColumns = [

                    DTColumnDefBuilder.newColumnDef(0),
                    DTColumnDefBuilder.newColumnDef(1),
                    DTColumnDefBuilder.newColumnDef(2),
                    DTColumnDefBuilder.newColumnDef(3),
                    DTColumnDefBuilder.newColumnDef(4),
                    DTColumnDefBuilder.newColumnDef(5),
                    DTColumnDefBuilder.newColumnDef(6),
                    DTColumnDefBuilder.newColumnDef(7)

                ];

                dtColumns.forEach(function (value) {
                    value.notSortable();
                    value.renderWith(function (data) {
                        return data;
                    })

                });

                return dtColumns;
            }

            function initializeDataTable() {


                var dtOptions = DTOptionsBuilder
                    .newOptions()
                    .withBootstrap()
                    .withOption('sDom', "Brt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                    .withOption('oLanguage', DataTableLanguageSettings)
                    .withOption('buttonContainer', '#buttons-block')
                    .withButtons([
                        {
                            text         : $translate.instant('reports.email'),
                            className    : 'the_button_2 email',
                            extend       : 'subscriptionEditActionButton',
                            extendOptions: {

                                action         : function (e, dt) {
                                    ReportsExport.email($scope.currentReport)
                                },
                                enabledCallback: function (e, dt) {
                                    return DataTableFunctions.getData(dt).length;
                                }
                            }
                        },
                        {
                            autoClose      : true,
                            extend         : 'collection',
                            text           : $translate.instant('reports.export'),
                            className      : 'the_button_2 export_data',
                            buttons        : [

                                {
                                    enabled        : true,
                                    className      : 'the_button_2',
                                    enabledCallback: function (e, dt) {
                                        return IncomeForAccountingHelper.isMivneExportingAllowed($scope.currentReport);
                                    },
                                    text           : $filter('translate')('reports.incomeForAccounting.export_to_mivne_button_label'),
                                    action         : function (e, dt) {
                                        return IncomeForAccountingHelper.exportReportToMivne($scope.currentReport);
                                    }
                                },
                                {
                                    enabled        : true,
                                    className      : 'the_button_2',
                                    enabledCallback: function (e, dt) {
                                        return IncomeForAccountingHelper.isTamalExportingAllowed($scope.currentReport);
                                    },
                                    text           : 'Tamal',
                                    action         : function (e, dt) {
                                        return IncomeForAccountingHelper.exportReportToTamal($scope.currentReport);
                                    }
                                },
                                {
                                    className      : 'the_button_2',
                                    enabled        : true,
                                    enabledCallback: function (e, dt) {
                                        return IncomeForAccountingHelper.isWizcountExportingAllowed($scope.currentReport);
                                    },
                                    text           : $filter('translate')('reports.incomeForAccounting.export_to_wizcount_button_label'),
                                    action         : function (e, dt) {
                                        return IncomeForAccountingHelper.exportReportToWizcount($scope.currentReport)
                                    }
                                },

                                {
                                    'text'         : $filter('translate')('customers.excel'),
                                    'className'    : 'the_button_2',
                                    enabled        : false,
                                    enabledCallback: function (e, dt) {
                                        return !!$scope.currentReport.content.docs.length;
                                    },
                                    action         : function (e, dt) {
                                        ReportsExport.downloadAsXLS($scope.currentReport);
                                    }
                                },
                                {
                                    'text'         : 'PDF',
                                    'className'    : 'the_button_2',
                                    enabled        : false,
                                    enabledCallback: function (e, dt) {
                                        return !!$scope.currentReport.content.docs.length;
                                    },

                                    action: function (e, dt) {
                                        ReportsExport.showAsPDF($scope.currentReport);
                                    }
                                }

                            ],
                            enabledCallback: function (e, dt) {
                                return DataTableFunctions.getData(dt).length;
                            }
                        },
                        {
                            text           : $translate.instant('reports.print'),
                            className      : 'the_button_2 print-grey',
                            action         : function (e) {
                                ReportsExport.print($scope.currentReport);
                            },
                            enabledCallback: function (e, dt) {
                                return DataTableFunctions.getData(dt).length;
                            }
                        }
                    ])
                    .withOption('order', [0, "asc"])
                    .withDisplayLength(5);

                return dtOptions;
            }
        }

        (function () {


            loadDataTable();

            if ($stateParams.id) {
                $scope.isTableShown = true;
                loadReport($stateParams.id)
            } else {
                $scope.isTableShown = false;
            }

            Events.subscribeToEvent('report.update', $scope, function (e, newReport) {
                $scope.isTableShown = true;
                loadReport(newReport.id);
            })
        })()
    });