/**
 * Created by abichenko on 27.12.17.
 */

angular.module('sinvoice-mobile')
    .controller('accountInputBlockController', function ($scope, BusinessBankAccountDataProvider) {

        var vm = this;


        (function () {

            if (!angular.isDefined(vm.account) && vm.customer) {
                vm.account = BusinessBankAccountDataProvider.getAccount(vm.customer);
            }

        })();


    });