/**
 * Created by abichenko on 13.02.18.
 */
angular.module('sinvoice-desktop').factory('DocumentInitializer', function () {

    var afterInitCallbacks = [];

    return {
        addInitCallback     : function (callback) {

            var result = callback();

            if (result && result.then) {
                result.then(function () {
                    afterInitCallbacks.forEach(function (afterInitCb) {
                        afterInitCb();
                    })
                })
            } else {
                afterInitCallbacks.forEach(function (afterInitCb) {
                    afterInitCb();
                })
            }


        },
        addAfterInitCallback: function (callback) {
            afterInitCallbacks.push(callback);
        }
    }

});