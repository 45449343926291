/**
 * Created by abichenko on 27.09.17.
 */
angular.module('sinvoice-desktop').factory('DocumentModalCallbackProvider',
    function ($filter, CustomerTypeChecker, Modal, DocumentModalCallbackChecker) {


        return {
            creditDocumentOccasionalCustomerWarning: function (editedDocument) {

                return function (flow) {

                    if (!DocumentModalCallbackChecker.isCreditWarningPopupNeedToBeShown(editedDocument)) {
                        return flow.allow();
                    }

                    Modal
                        .confirm(
                            $filter('documentPopupTranslate')('documents.occasional_customer_warning', editedDocument.type.docType),
                            null,
                            null,
                            {title: $filter('documentPopupTranslate')('documents.occasional_customer_warning_title', editedDocument.type.docType)}
                        )
                        .then(
                            flow.allow
                        )

                }
            },

            occasionalCustomerInfoMissing: function (editedDocument, template, documentForm) {

                return function (flow) {

                    if (!DocumentModalCallbackChecker.isOccasionalCustomerInfoMissingPopupNeedToBeShown(editedDocument)) {
                        return flow.allow();
                    }

                    if (!template.useTaxWithdrawal) {
                        return flow.allow();
                    }

                    var occasionalCustomerName = editedDocument.customerOccasionalName;
                    if (occasionalCustomerName === $filter('translate')('documents.use_occuring')) {
                        occasionalCustomerName = '';
                    }


                    Modal.customDialog({
                        scopeVariables: {
                            docType                    : editedDocument.type,
                            occasionalCustomerName     : occasionalCustomerName,
                            occasionalCustomerVatNumber: editedDocument.customerOccasionalVatNumber,
                            onSave                     : function (occasionalCustomerName, occasionalCustomerVatNumber) {
                                editedDocument.customerOccasionalVatNumber = occasionalCustomerVatNumber;
                                editedDocument.customerOccasionalName      = occasionalCustomerName;

                                if (documentForm.$invalid)
                                    flow.deny();
                                else
                                    flow.allow();

                            },
                            onCancel                   : function () {
                                flow.deny();
                            }
                        },
                        title         : $filter('documentPopupTranslate')('documents.occasional_customer_missing_info_popup.title', editedDocument.type.docType),
                        templateUrl   : './desktop/components/documents/popups/occasional-customer-missing-info-popup.html',
                        className     : 'centered-popup',
                        size          : "medium"
                    });


                }
            }
        }

    });