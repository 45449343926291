/**
 * Created by dbichenko on 19.10.2016.
 */
angular.module('sinvoice-desktop').controller('exportPrintController', function ($scope, DocumentExport, $stateParams, $state) {

    (function () {
        
        // TEMP
        $state.go('login');
    })();

});