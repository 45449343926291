angular.module('sinvoice-mobile')
    .controller('singleDocumentController',
        function ($scope, $filter, DocumentConstants, CustomerTypeChecker, MobileModal, $state, $translate) {


            var toggledDocument = null;

            $scope.sendDocument = function (document, sendDocumentDefaultEmail) {
                MobileModal.bluebarModal('./mobile/components/my-documents/send-document-popup.html', {
                    title         : $filter('translate')('documents.send_document_via_email_popup.title'),
                    scopeVariables: {
                        document: document,
                        email   : sendDocumentDefaultEmail
                    }
                })
            };

            $scope.getCustomerName = function (document) {
                if (CustomerTypeChecker.isOccasional(document.customer.customerType)) {
                    return $filter('translate')('documents.use_occuring')
                } else {
                    return document.customer.name;
                }
            };

        $scope.viewDocument = function (document) {
            $state.go('view-document', {documentId: document.id});
        };

        $scope.getCustomerName = function (document) {
            if (CustomerTypeChecker.isOccasional(document.customer.customerType)) {
                return $translate.instant('documents.use_occuring')
            } else {
                return document.customer.name;
            }
        };

            $scope.documentIsActive = function (document) {
                return document.status === DocumentConstants.STATUS_OPEN;
            };

            $scope.isMenuToggled = function (document) {
                return toggledDocument === document;
            };

            $scope.toggleDocumentMenu = function (document) {
                if ($scope.isMenuToggled(document)) {
                    toggledDocument = null;
                } else {
                    toggledDocument = document;
                }
            };

            $scope.openDocumentMenu = function (document) {
                toggledDocument = document;
            };

            $scope.closeDocumentMenu = function (document) {
                toggledDocument = null;
            };

            $scope.viewDocument = function (document) {
                $state.go('view-document', {
                    documentId: document.id
                });
            };


        });