/**
 * Created by dbichenko on 30.08.2016.
 */
angular.module('sinvoice-desktop').controller('invitedCustomersController', function (ManagedCustomers, $scope) {
    var perPage = 2;

    $scope.spinner = true;
    $scope.invites = null;

    $scope.showAmount = perPage;

    $scope.showMore = function () {
        $scope.showAmount += perPage;
    };

    (function () {

        ManagedCustomers.getInvited().then(function (data) {

            data.sort(function (a, b) {
                return new Date(a.createdAt) - new Date(b.createdAt);
            });

            $scope.invites = data;
            $scope.spinner = false;
        });
    })()

});