/**
 * Created by dbichenko on 16.09.2016.
 */
angular.module('sinvoice-desktop').controller('editCustomerController',
    function ($scope, Modal, CommunicationModal, Events, Customer, $rootScope, EditedValueComparator, DesktopModal, DetailsBackup) {

        $scope.close   = Modal.hideAll;
        $scope.spinner = {
            editCustomer: true
        };

        $scope.$currentModal.onCancelCallback = function () {

            if (DetailsBackup.isChanged()) {
                return DesktopModal.unsavedChanges();
            } else {
                return true;
            }
        };

        Events.subscribeToEvent('customer.update', $scope, function () {
            loadCustomer();
        });

        function loadCustomer() {
            return Customer
                .getSingleCustomer($scope.customerId)
                .then(function (customer) {
                    $scope.edited.customer     = customer;
                    $rootScope.editingCustomer = customer;

                    return customer;
                })
        }

        (function () {
            loadCustomer()
                .finally(function () {
                    $scope.spinner.editCustomer = false;
                });

        })();
    });