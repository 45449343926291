/**
 * Created by abichenko on 14.03.18.
 */
angular.module('sinvoice-desktop').directive('businessAddress', function (Auth, ProfileChecker) {


        return {
            scope      : {
                address           : '=',
                parentForm        : '=?',
                formIndex         : '=?',
                isEnglishAvailable: '=?',
                onRemove          : '&',
                isRemoveAllowed   : '&',
                cities            : '='
            },
            templateUrl: './desktop/components/profile/partials/business-address.html',
            link       : function (scope) {

            }
        }
    }
);