/**
 * Created by Front-end on 29.12.2016.
 */
angular.module('sinvoice-desktop').controller('detailsAddressesController',
    function ($scope, Modal, $filter, Events, ProfileChecker, City, Customer, DetailsBackup, DesktopModal, $timeout,CustomerAddressType, AuthDataStorage) {

        $scope.addressForm   = {};
        $scope.editedAddress = null;

        $scope.isMainAddress = function (address) {
            return address.type === CustomerAddressType.TYPE_MAIN;
        };

        $scope.isAdditionalAddress = function (address) {
            return address.type === CustomerAddressType.TYPE_DEFAULT;
        };

        $scope.isEnglishAvailable = function () {
            return ProfileChecker.isEnglishAvailable(AuthDataStorage.getProfileBusinesses());
        };

        $scope.getAddressString = function (address) {
            var street = address.street || address.streetEn;
            var city;

            if (address.city && address.city.name) {
                city = address.city.name;
            } else {
                city = address.cityEn
            }

            return [street, city]
                .filter(function (val) {
                    return !!val;
                })
                .join(', ');
        };
        $scope.getMailString    = function (address) {
            return [address.mailbox, address.postcode]
                .filter(function (val) {
                    return !!val;
                })
                .join(', ');
        };

        $scope.editAddress = function (address) {
            $scope.editedAddress = angular.copy(address);
            DetailsBackup.registerTrackedSection('address', angular.copy(address), $scope.editedAddress);
        };

        $scope.deleteAddress = function (address) {

            Modal
                .confirm(
                    $filter('translate')('customers.edit.details.address.delete_confirm'),
                    null,
                    null,
                    {title: $filter('translate')('customers.edit.details.address.delete_confirm_title')}
                )
                .then(function () {
                    return Customer.deleteAddress(address.id)
                })
                .then(function () {
                    $scope.edited.customer.addresses = $scope.edited.customer.addresses.filter(function (currentAddress) {
                        return currentAddress != address;
                    });
                    reload();
                })

        };

        $scope.saveAddress = function () {
            $scope.editedAddress.customer_id = $scope.edited.customer.id;

            if ($scope.editedAddress.city) {
                $scope.editedAddress.city_id = $scope.editedAddress.city.id;
            }

            if ($scope.editedAddress.id) {
                Customer
                    .updateAddress($scope.editedAddress.id, $scope.editedAddress)
                    .then(function (data) {
                        for (var i = 0; i < $scope.edited.customer.addresses.length; i++) {
                            if ($scope.edited.customer.addresses[i].id == $scope.editedAddress.id) {
                                $scope.edited.customer.addresses[i] = data;
                            }
                        }
                        reload();
                        doCancelAddress();
                    })
            } else {
                delete $scope.editedAddress.city;
                Customer.createAddress($scope.editedAddress).then(function (data) {
                    $scope.edited.customer.addresses.push(data);
                    doCancelAddress();
                    reload()
                })
            }

        };


        $scope.cancelAddress = function () {

            if (DetailsBackup.isChanged('address')) {
                DesktopModal.unsavedChanges().then(function () {
                    $timeout(doCancelAddress);
                })
            } else {
                return doCancelAddress();
            }

        };

        $scope.addAddress = function () {
            $scope.editedAddress = {};
            DetailsBackup.registerTrackedSection('address', angular.copy($scope.editedAddress), $scope.editedAddress);
        };

        function doCancelAddress() {
            $scope.editedAddress = null;
            $scope.addressForm.$setPristine();
            DetailsBackup.unregisterTrackedSection('address');
        }

        function reload() {
            Events.triggerEvent('customer.update');
        }

        function loadCities() {
            City.getAll().then(function (data) {
                $scope.cities = data;
            })
        }

        (function () {
            loadCities();
        })();

    });