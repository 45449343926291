/**
 * Created by abichenko on 28.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('taxInvoiceReceiptController',

        function ($scope, DocumentModalCallbackContainer, DocumentModalCallbackChecker, MobileModal, $filter) {

            $scope.errors = {payments: {}, paragraphs: {}};

            (function () {


                DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                    if (!DocumentModalCallbackChecker.isAmountDoesNotMatchPopupNeedToBeShown(
                            $scope.editedDocument,
                            $scope.template.$paymentsTotalWithVatNIS,
                            $scope.template.$finalItemsTotalWithVatNIS
                        ))
                        return flow.allow();

                    MobileModal
                        .alert(
                            $filter('documentPopupTranslate')('documents.not_match_message', $scope.editedDocument.type.docType),
                            {title: $filter('documentPopupTranslate')('documents.not_match_message_title', $scope.editedDocument.type.docType)}
                        )
                        .then(
                            flow.deny
                        );
                });

                DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                    var editedDocument  = $scope.editedDocument;
                    var paragraphErrors = $scope.errors.paragraphs;

                    paragraphErrors.required = false;

                    if (!editedDocument.paragraphs.length) {
                        flow.deny();
                        paragraphErrors.required = true;
                        return false;
                    }
                    flow.allow();
                });

                DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                    var editedDocument = $scope.editedDocument;
                    var paymentErrors  = $scope.errors.payments;

                    paymentErrors.required = false;

                    if (!editedDocument.payments.length) {
                        flow.deny();
                        paymentErrors.required = true;
                        return false;
                    }

                    flow.allow();
                });

            })();
        });