/**
 * Created by dbichenko on 06.03.2017.
 */
angular.module('sinvoice-common')

    .run(function (onInitContainer, Auth, Currency) {


        var authPromise = function () {
            return Auth
                .checkRememberedToken()
                .then(Auth.checkSessionToken);
        };

        onInitContainer.addPromise(authPromise);
        onInitContainer.addPromise(Currency.populateCurrencies);
    })
    .controller('bodyController', function ($scope, $rootScope, Auth, onInitContainer) {

        $scope.isDesktop = function () {
            return $rootScope.version === 'desktop';
        };

        $scope.isMobile = function () {
            return $rootScope.version === 'mobile';
        };

        $scope.isPreloadComplete = function () {
            return (!Auth.isAuthenticated() || onInitContainer.afterLoginSequenceIsCompleted())
                && onInitContainer.isInitSequenceCompleted();
        };

        (function () {

            if (Auth.isAuthenticated()) {
                onInitContainer.runRepeatablePromises();
                onInitContainer.runOneTimePromises();
            } else {
                var destroy = $rootScope.$on('auth:login', function () {
                    onInitContainer.runOneTimePromises();
                    destroy();
                });
            }

            $rootScope.$on('auth:login', function () {
                onInitContainer.runRepeatablePromises();
            });

            onInitContainer.run();

        })();

    });