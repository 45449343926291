/**
 * Created by dbichenko on 17.11.2016.
 */
angular.module('sinvoice-desktop').controller('currencyCalculatorBlockController', function ($scope, Currency, Events, DocumentInitializer) {

    DocumentInitializer.addInitCallback(function () {

        function setCurrencyDefaultCourse() {
            $scope.defaultNisCourse         = Currency.getCurrencyCourse($scope.editedDocument.currencies.name);
            $scope.editedDocument.nisCourse = $scope.defaultNisCourse;
        }

        Events.subscribeToEvent('currencies.change', $scope, function (event, newCurrency, oldCurrency) {
            if (newCurrency == oldCurrency || !newCurrency)
                return false;

            setCurrencyDefaultCourse();
        });


        Events.subscribeToEvent('issueDate.change', $scope, function (e, newIssueDate) {
            var currencyCourseForDate = Currency.getCurrencyCourseForDate(
                $scope.editedDocument.currencies.name,
                newIssueDate
            );

            if (currencyCourseForDate) {
                $scope.editedDocument.nisCourse = currencyCourseForDate;
            }
        });

        $scope.defaultNisCourse = Currency.getCurrencyCourse($scope.editedDocument.currencies.name);

        if (!$scope.editedDocument.nisCourse) {
            setCurrencyDefaultCourse();
        }

    });
});