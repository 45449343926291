/**
 * Created by abichenko on 21.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('singleItemController', function ($scope, DocumentTaxCalculator, ParagraphTotalCalculator, $filter) {


        $scope.vatOptions = [
            {label: $filter('translate')('common.include'), value: true},
            {label: $filter('translate')('common.exclude'), value: false}
        ];


        function updateVATSelect() {
            $scope.disabledVATSelect = ($scope.selectedVatParameters !== 'mixed');
        }

        $scope.$watch('selectedVatParameters', function (newValue) {

            updateVATSelect();

            if (newValue === 'exclude') {
                $scope.paragraph.displayWithVat = false;
            } else if (newValue === 'include') {
                $scope.paragraph.displayWithVat = true;
            }
            $scope.recalculateOnChangeUnitPrice();

        });

        $scope.recalculateOnChangeDisplayWithVat = function () {
            $scope.recalculateOnChangeUnitPrice();
        };

        $scope.removeParagraph = function () {
            $scope.onRemove();
        };

        $scope.recalculateOnChangeTotalVat = function () {
            ParagraphTotalCalculator.calculateUnitPriceFromTotalWithVat($scope.paragraph);
        };

        $scope.recalculateOnChangeTotal = function () {
            ParagraphTotalCalculator.calculateUnitPriceFromTotal($scope.paragraph);
        };

        $scope.recalculateOnChangeUnitPrice = function () {
            if ($scope.paragraph.unitPrice) {
                ParagraphTotalCalculator.calculateTotals($scope.paragraph);
            }
        };

        (function () {

            updateVATSelect();

            $scope.recalculateOnChangeUnitPrice();

            if (!angular.isDefined($scope.paragraph.displayWithVat)) {
                $scope.paragraph.displayWithVat = true;
            }
        })();
    });