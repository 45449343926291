/**
 * Created by dbichenko on 30.12.2016.
 */
angular.module('sinvoice-desktop').controller('exemptDealerCustomerDocumentsController',

    function ($scope, DocumentConstants, CustomerTypeChecker, DocumentTypeConstants) {

        $scope.$on('documents.loaded', function (e, getFilteredData, initializeDataTableColumns, initializeDataTable) {

            $scope.dtColumns       = {};
            $scope.dtOptions       = {};
            $scope.dtInstances.all = {};
            $scope.dtInstances.CR  = {};
            $scope.dtInstances.CPR = {};
            $scope.dtInstances.OPR = {};


            var tableAllPromise = function () {
                return getFilteredData();
            };
            var tableCRPromise  = function () {
                return getFilteredData(DocumentTypeConstants.TYPE_RECEIPT, DocumentConstants.STATUS_CLOSED);
            };
            var tableCPRPromise = function () {
                var types = [
                    DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
                    DocumentTypeConstants.TYPE_PERFORM
                ];

                return getFilteredData(types, DocumentConstants.STATUS_CLOSED);
            };
            var tableOPRPromise = function () {
                var types = [
                    DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
                    DocumentTypeConstants.TYPE_PERFORM
                ];

                return getFilteredData(types, DocumentConstants.STATUS_OPEN);
            };

            var isOccasionalCustomer = CustomerTypeChecker.isOccasional($scope.edited.customer.customerType);

            $scope.dtColumns['all']                     = initializeDataTableColumns(true, isOccasionalCustomer);
            $scope.dtColumns['closed_receipts']         = initializeDataTableColumns(false, isOccasionalCustomer);
            $scope.dtColumns['open_payment_requests']   = initializeDataTableColumns(false, isOccasionalCustomer);
            $scope.dtColumns['closed_payment_requests'] = initializeDataTableColumns(false, isOccasionalCustomer);

            $scope.dtOptions['all']                     = initializeDataTable(tableAllPromise, '#datatable-buttons-container-all', true, isOccasionalCustomer);
            $scope.dtOptions['closed_receipts']         = initializeDataTable(tableCRPromise, '#datatable-buttons-container-cr', false, isOccasionalCustomer);
            $scope.dtOptions['open_payment_requests']   = initializeDataTable(tableOPRPromise, '#datatable-buttons-container-opr', false, isOccasionalCustomer);
            $scope.dtOptions['closed_payment_requests'] = initializeDataTable(tableCPRPromise, '#datatable-buttons-container-cpr', false, isOccasionalCustomer);
        })

    });