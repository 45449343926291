/**
 * Created by abichenko on 17.11.17.
 */
angular.module('sinvoice-mobile').directive('newDocumentStepTwoBlock', function () {


    return {
        scope      : {
            editedDocument   : '=',
            onBackButtonClick: '&',
            afterSave        : '&'
        },
        controller : 'mobileNewDocumentStepTwoController',
        templateUrl: './mobile/components/new-document/new-document-step-two.html',
        link       : function (scope, element, attrs) {

        }
    }
})