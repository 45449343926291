/**
 * Created by dbichenko on 03.10.2016.
 */
angular.module('sinvoice-desktop').controller('sendDocumentViaEmailPopupController',
    function ($scope, Document, AuthDataStorage, Modal, $filter, Language, CustomLanguageTranslatorFactory, $timeout) {

        $scope.spinner = {enabled: true};

        $scope.changeText = function () {
            setTexts($scope.language);
        };

       $scope.setDefaultDocLanguage = function () {

           let language = {};

           $scope.documents.some(function(itm){
               if(itm.language === 'he'){
                   language = itm.language;
                   return true;
               } else {
                   language = itm.language
               }
           });

           return language;

        };

        $scope.save = function () {

            if ($scope.sendDocumentViaEmailForm.$invalid)
                return;

            var ids = $scope.documents.map(function (document) {
                return document.id;
            });

            $scope.spinner.enabled = true;

            Document
                .sendViaEmails(ids, $scope.email, $scope.subject, $scope.message, $scope.language)
                .then(function (data) {

                    if (!data.result.email.failure.length) {
                        Modal
                            .alert(
                                $filter('translate')('documents.email_send_successful'),
                                {title: $filter('translate')('documents.email_send_successful_title')}
                            );
                    } else {
                        Modal
                            .alert(
                                $filter('translate')('documents.email_send_failure', {
                                    emailList: data.result.email.failure.join(', ')
                                }),
                                {title: $filter('translate')('documents.email_send_failure_title')}
                            );
                    }

                    $scope.$currentModal.close();
                })
                .finally(function () {
                    $scope.spinner.enabled = flase;
                })
        };

        $scope.cancel = function () {
            $scope.$currentModal.close();
        };

        function loadLanguages() {
            return Language.getData().then(function (data) {
                $scope.languages = data;
                $scope.language  = $scope.setDefaultDocLanguage();
            })

        }

        function setTexts(lang) {

            var isEnglishName = function (firstName, lastName) {
                var isFirstNameEnglish = firstName.match(/^[A-Za-z0-9]*$/);
                var isLastNameEnglish  = lastName.match(/^[A-Za-z0-9]*$/);

                return isFirstNameEnglish && isLastNameEnglish;
            };

            CustomLanguageTranslatorFactory.useLanguageForCallback(lang, function () {

                var businesses = AuthDataStorage.getProfileBusinesses();
                var profile    = AuthDataStorage.getProfile();
                var business   = businesses[lang];

                var translateVariables = {
                    businessName: business ? business.name : '',
                    firstName   : profile.firstName,
                    lastName    : profile.lastName,
                    documents   : $scope.documents.map(function (document) {
                        return $filter('translateDocType')(document.type.docType) + ' ' + document.docNumber;
                    }).join(', ')
                };

                var subjectKey = 'documents.send_document_via_email_popup.default_subject';
                var messageKey = 'documents.send_document_via_email_popup.default_message';

                if (lang === 'en' && !isEnglishName(user.profile.firstName, user.profile.lastName)) {
                    subjectKey = 'documents.send_document_via_email_popup.default_subject_no_name';
                }

                $scope.subject = $filter('translate')(subjectKey, translateVariables);
                $scope.message = $filter('translate')(messageKey, translateVariables);

            })
        }


        (function () {

            loadLanguages().finally(function () {
                $scope.spinner.enabled = false;
            });

            if (!$scope.documents) {
                $scope.documents = [];
            }

            if (!$scope.email) {
                $scope.email = '';
            }

            setTexts($scope.setDefaultDocLanguage());

        })();
    });
