angular.module('sinvoice-desktop').controller('templatesController',
    function ($scope,
              DTOptionsBuilder,
              DTColumnBuilder,
              $sce,
              $translate,
              DataTableBootstrapSettings,
              DocumentType,
              DataTableLanguageSettings,
              Modal,
              $filter,
              $q,
              AuthDataStorage,
              Events,
              UserSubscription,
              DocTypeCommentModal,
              ProfileChecker,
              Template) {

        $scope.allowedFiles = ["png", "jpg"];
        $scope.allowedSize  = 0.5;
        $scope.spinner      = {template: true};


        $scope.isSubmitButtonDisabled = function () {
            return UserSubscription.isCheckInProgress();
        };


        $scope.isSaveAllowed = function () {
            return new Promise(function (resolve, reject) {

                if ($scope.profileForm.$invalid) {
                    return reject(false);
                }

                return UserSubscription.checkSubscriptionForEditAction().then(function () {
                    return resolve(true)
                });

            })
        };

        $scope.removeLogoEn = function () {
            confirmRemoveModal().then(function () {
                $scope.selected.logoEn = null;
            })
        };
        $scope.removeLogoHe = function () {
            confirmRemoveModal().then(function () {
                $scope.selected.logoHe = null;
            });
        };

        $scope.removeSignatureHe = function () {
            confirmRemoveModal().then(function () {
                $scope.selected.signatureHe = null;
            })
        };

        $scope.removeSignatureEn = function () {
            confirmRemoveModal().then(function () {
                $scope.selected.signatureEn = null;
            })
        };

        $scope.isEnglishTemplatesBlockShown = function () {
            return ProfileChecker.isEnglishAvailable(AuthDataStorage.getProfileBusinesses())
        };

        $scope.selectTemplate = function (template) {
            $scope.selected.template = angular.copy(template);
        };


        $scope.save = function () {
            return Template
                .update($scope.selected)
                .then(function () {
                    $scope.selectedDuplicate = angular.copy($scope.selected);
                });
        };

        $scope.cancel = function () {
            angular.copy($scope.selectedDuplicate, $scope.selected);
        };

        function loadTemplates() {
            return Template.getAllAvailable().then(function (data) {

                $scope.availableTemplates = data.map(function (value, key) {
                    value.pdfUrl     = $sce.trustAsResourceUrl(value.pdfUrl);
                    value.previewUrl = $sce.trustAsResourceUrl(value.previewUrl);

                    return value;
                })
            });
        }

        function loadTemplateSettings() {


            return Template.getSettings().then(function (data) {

                if (data.signatureHe) {
                    $scope.selected.signatureHe = data.signatureHe;
                }
                if (data.logoHe) {
                    $scope.selected.logoHe = data.logoHe;
                }

                if (data.signatureEn) {
                    $scope.selected.signatureEn = data.signatureEn;
                }
                if (data.logoEn) {
                    $scope.selected.logoEn = data.logoEn;
                }
                if (data.template) {
                    $scope.selected.template            = data.template;
                    $scope.selected.template.previewUrl = $sce.trustAsResourceUrl(data.template.previewUrl);
                    $scope.selected.template.pdfUrl     = $sce.trustAsResourceUrl(data.template.pdfUrl);
                }
            })
        }

        (function () {
            $scope.selected          = {};
            $scope.selectedDuplicate = {};
            $q.all([
                loadTemplates(),
                loadTemplateSettings()
            ]).then(function () {
                $scope.spinner.template = false;
                angular.copy($scope.selected, $scope.selectedDuplicate)
            })


        })();


        $scope.openNewCommentForm = function () {
            DocTypeCommentModal.newComment();
        };

        (function () {


            loadDataTable();

            Events.subscribeToEvent('doctypes.update', $scope, function () {

                $scope.dtCommentInstance.rerender();
            });
        })();

        function loadDataTable() {

            $scope.dtCommentInstance = {};

            $scope.dtCommentOptions = DTOptionsBuilder
                .fromFnPromise(function () {
                    return DocumentType.getDocTypesWithComment();
                })
                .withBootstrap()
                .withOption('sDom', "<'row'>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withOption('oLanguage', DataTableLanguageSettings)
                .withButtons([])
                .withOption('order', [0, 'asc'])
                .withOption('fnCreatedRow', rowCallback);

            $scope.dtCommentColumns = [
                DTColumnBuilder
                    .newColumn('order')
                    .notSortable()
                    .notVisible(),

                DTColumnBuilder
                    .newColumn('docType')
                    .withTitle($filter('translate')('profile.templates.table.doctype'))
                    .notSortable()
                    .renderWith(function (data, type, full) {
                        return $filter('translateDocType')(data);
                    }),
                DTColumnBuilder
                    .newColumn('comment')
                    .withTitle($filter('translate')('profile.templates.table.comment'))
                    .notSortable()
                    .renderWith(function (data, type, full) {
                        var html = '';

                        if (full.comment) {
                            html += '<p><img src="assets/img/he.png" alt=""> ' + full.comment + '</p>';
                        }

                        if (full.commentEn) {
                            html += '<p><img src="assets/img/en.png" alt=""> ' + full.commentEn + '</p>';
                        }
                        return html;
                    })
            ];

            $scope.dtCommentColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });


        }


        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
            $(nRow).on('click', 'td', function () {
                UserSubscription.checkSubscriptionForEditAction().then(function () {
                    DocTypeCommentModal.editComment(aData);
                })
            });
            return nRow;
        }

        function confirmRemoveModal() {
            return Modal.confirm(
                $translate.instant('profile.templates.remove_confirm'),
                null,
                null,
                {title: $translate.instant('profile.templates.remove_confirm_title')}
            );
        }

    });