/**
 * Created by abichenko on 30.10.17.
 */
angular.module('sinvoice-desktop')
    .run(function (UserSubscriptionCallbackContainer, $filter, Modal, DataStorage) {

        UserSubscriptionCallbackContainer.addEditActionCallback(function (flow, result) {

            if (result) {
                flow.allow();
            } else {
                Modal
                    .alert(
                        $filter('translate')('common.subscriptions.edit_action_restricted_modal.message'),
                        {
                            title      : $filter('translate')('common.subscriptions.edit_action_restricted_modal.title'),
                            closeButton: false
                        }
                    )
                    .then(function () {
                        flow.deny();
                    })
            }
        });

        UserSubscriptionCallbackContainer.addDocumentCreationCallback(function (flow, data) {

            var currentPeriod       = data.content.currentPeriod;
            var currentSubscription = data.content.currentSubscription;

            if (currentPeriod && currentSubscription) {
                flow.allow();
                return;
            }

            Modal
                .alert(
                    $filter('translate')('common.subscriptions.subscription_expired_modal.message'),
                    {
                        title      : $filter('translate')('common.subscriptions.subscription_expired_modal.title'),
                        templateUrl: './desktop/components/documents/popups/subscription-limit-popup.html',
                        closeButton: false

                    }
                )
                .then(function () {
                    flow.deny();
                });
        });

        UserSubscriptionCallbackContainer.addDocumentCreationCallback(function (flow, data) {

            var currentPeriod = data.content.currentPeriod;
            var documentsLeft = currentPeriod.selectedPlan.maxDocuments - currentPeriod.documentsUsed;

            if (documentsLeft !== 0) {
                flow.allow();
                return;
            }

            Modal
                .alert(
                    $filter('translate')('common.subscriptions.subscription_limit_modal.message'),
                    {
                        title      : $filter('translate')('common.subscriptions.subscription_limit_modal.title'),
                        templateUrl: './desktop/components/documents/popups/subscription-limit-popup.html',
                        closeButton: false
                    }
                )
                .then(function () {
                    flow.deny();
                })
        });

        UserSubscriptionCallbackContainer.addDocumentCreationCallback(function (flow, data) {

            var currentSubscription = data.content.currentSubscription;
            var activeUntil         = momentWrapped(currentSubscription.activeUntil);
            var oneWeekLater        = momentWrapped().add(1, 'weeks').startOf('day');

            var isPopupShown = DataStorage.getValue('isExpireSubscriptionPopupShown');
            if (isPopupShown || (activeUntil > oneWeekLater)) {
                flow.allow();
                return;

            }

            Modal
                .alert(
                    $filter('translate')('common.subscriptions.subscription_remind_modal.message', {
                        expirationDate: $filter('dpickerDateFormatter')(currentSubscription.activeUntil)
                    }),
                    {
                        title      : $filter('translate')('common.subscriptions.subscription_remind_modal.title'),
                        templateUrl: './desktop/components/documents/popups/subscription-remind-popup.html',
                        closeButton: false

                    }
                )
                .then(function () {
                    DataStorage.setValue('isExpireSubscriptionPopupShown', true);
                    flow.allow();
                });

        });

        UserSubscriptionCallbackContainer.addDocumentCreationCallback(function (flow, data) {

            var currentPeriod = data.content.currentPeriod;
            var documentsLeft = currentPeriod.selectedPlan.maxDocuments - currentPeriod.documentsUsed;

            if (!(documentsLeft <= 5)) {
                flow.allow();
                return;
            }

            Modal
                .alert(
                    $filter('translate')('common.subscriptions.subscription_remaining_docs_modal.message', {
                        remainingDocs: documentsLeft
                    }),
                    {
                        title      : $filter('translate')('common.subscriptions.subscription_remaining_docs_modal.title'),
                        templateUrl: './desktop/components/documents/popups/subscription-remind-popup.html',
                        closeButton: false

                    }
                )
                .then(function () {
                    flow.allow();
                });

        })
    });