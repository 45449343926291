/**
 * Created by dbichenko on 26.12.2016.
 */
angular.module('sinvoice-desktop').controller('detailsRelatedCustomersController',
    function ($scope,
              Modal,
              $filter,
              Events,
              $timeout,
              DetailsBackup,
              DesktopModal,
              Customer) {


        var availableRelatedCustomerTypes = [];

        $scope.relatedCustomersTypes = [];
        $scope.relatedCustomerForm   = {};
        $scope.editedRelatedCustomer = null;
        $scope.customers             = [];


        $scope.updateAvailableRelationTypes = function () {

            if (!$scope.editedRelatedCustomer.customer2)
                return;


            var relationRecords = $scope.records.filter(function (relationRecord) {
                return relationRecord.customer2.id === $scope.editedRelatedCustomer.customer2.id
                    && relationRecord.id !== $scope.editedRelatedCustomer.id;
            });

            var usedTypes = relationRecords.map(function (record) {
                return record.type.id;
            });

            $scope.relatedCustomersTypes = availableRelatedCustomerTypes.filter(function (type) {
                return !_.includes(usedTypes, type.id);
            });

        };

        $scope.editRelatedCustomer = function (relationRecord) {
            $scope.editedRelatedCustomer = angular.copy(relationRecord);
            $scope.updateAvailableRelationTypes();
            DetailsBackup.registerTrackedSection('relatedCustomer', angular.copy($scope.editedRelatedCustomer), $scope.editedRelatedCustomer);
        };

        $scope.deleteRelatedCustomer = function (relationRecord) {

            Modal
                .confirm(
                    $filter('translate')('customers.edit.details.related_customers.delete_confirm'),
                    null,
                    null,
                    {title: $filter('translate')('customers.edit.details.related_customers.delete_confirm_title')}
                )
                .then(function () {
                    return Customer.deleteRelatedCustomer(relationRecord.customer1.id, relationRecord.id)
                })
                .then(function (data) {
                    Events.triggerEvent('customer.update');
                })


        };

        $scope.saveRelatedCustomer = function () {
            var denormalizedData = getDenormalizedRelationRecord($scope.editedRelatedCustomer);

            if ($scope.editedRelatedCustomer.id) {
                Customer
                    .updateRelatedCustomer($scope.editedRelatedCustomer.customer1.id, $scope.editedRelatedCustomer.id, denormalizedData)
                    .then(function () {
                        doCancelRelatedCustomer();
                        Events.triggerEvent('customer.update');
                    });

            } else {
                Customer
                    .createRelatedCustomer($scope.editedRelatedCustomer.customer1.id, denormalizedData)
                    .then(function (data) {
                        doCancelRelatedCustomer();
                        Events.triggerEvent('customer.update');
                    });
            }
        };

        $scope.cancelRelatedCustomer = function () {
            if (DetailsBackup.isChanged('relatedCustomer')) {
                DesktopModal
                    .unsavedChanges()
                    .then(function () {
                        $timeout(doCancelRelatedCustomer);
                    });
            } else {
                return doCancelRelatedCustomer();
            }
        };

        $scope.addRelatedCustomer = function () {
            $scope.editedRelatedCustomer = {
                customer1: $scope.edited.customer
            };
            DetailsBackup.registerTrackedSection('relatedCustomer', angular.copy($scope.editedRelatedCustomer), $scope.editedRelatedCustomer);

        };

        $scope.$watchGroup(
            ['edited.customer.relatedcustomers', 'edited.customer.relatedTocustomers'],
            function () {
                $scope.records = getNormalizedRelationRecords();
            },
            true
        );

        (function () {
            loadRelatedCustomersTypes();
            loadCustomers();
        })();


        function doCancelRelatedCustomer() {
            $scope.editedRelatedCustomer = null;
            DetailsBackup.unregisterTrackedSection('relatedCustomer');
        }

        function getNormalizedRelationRecords() {

            var customer = $scope.edited.customer;

            return []
                .concat(customer.relatedcustomers)
                .concat(customer.relatedTocustomers)
                .map(function (relationRecord) {
                    return getNormalizedRelationRecord(relationRecord)
                });
        }

        function getNormalizedRelationRecord(relationRecord) {

            var customer1;
            var customer2;

            if ($scope.edited.customer.id === relationRecord.customerToRelate.id) {
                customer1 = relationRecord.customerToRelate;
                customer2 = relationRecord.customer;
            } else {
                customer1 = relationRecord.customer;
                customer2 = relationRecord.customerToRelate;
            }

            return {
                id       : relationRecord.id,
                type     : relationRecord.type,
                customer1: customer1,
                customer2: customer2
            };
        }


        function getDenormalizedRelationRecord(relationRecord) {

            var customer;
            var customerToRelate;

            if ($scope.edited.customer.id === relationRecord.customer1.id) {
                customer         = relationRecord.customer1;
                customerToRelate = relationRecord.customer2;
            } else {
                customerToRelate = relationRecord.customer1;
                customer         = relationRecord.customer2;
            }

            return {
                id              : relationRecord.id,
                type            : relationRecord.type,
                customerToRelate: customerToRelate,
                customer        : customer
            }

        }

        function loadCustomers() {

            return Customer.getData().then(function (data) {
                data.forEach(function (customer, index) {
                    var customerType = customer.customerType.type;
                    if (customerType != 'superinvoice' && customerType != 'occasional') {
                        $scope.customers.push(customer);
                    }
                })
            });

        }

        function loadRelatedCustomersTypes() {
            return Customer.getRelatedCustomerTypes().then(function (data) {
                availableRelatedCustomerTypes = data;
            });
        }

    });