/**
 * Created by dbichenko on 08.11.2016.
 */
angular.module('sinvoice-desktop').controller('invitationButtonController', function ($scope, Modal, $translate) {

    $scope.sendInvitationPoppup = sendInvitationPoppup;

    function sendInvitationPoppup(e) {
        Modal.customDialog({
            title      : $translate.instant('control.add_invited_poppup.poppup_title'),
            templateUrl: './desktop/components/common/invitations/send-invitation-popup.html',
            size       : "large"
        });
    }

});