/**
 * Created by abichenko on 29.11.17.
 */
angular.module('sinvoice-mobile').controller('occasionalCustomerInfoMissingPopupController', function ($scope) {

    $scope.occasionalCustomerMissingInfoForm = {};

    $scope.save = function () {

        if ($scope.occasionalCustomerMissingInfoForm.$invalid)
            return false;

        $scope.onSave($scope.occasionalCustomerName, $scope.occasionalCustomerVatNumber);
        $scope.$currentModal.close();
    };

    $scope.cancel = function () {
        $scope.onCancel();
        $scope.$currentModal.close();
    };

});