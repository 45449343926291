/**
 * Created by abichenko on 07.11.17.
 */

(function () {

    var prevState = {value: null};

    angular.module('sinvoice-common')
        .run(function ($rootScope) {
            $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {

                if (fromState.name)
                    prevState.value = fromState;
            });
        });

    angular.module('sinvoice-common')
        .factory('previousStateProvider', function () {
            return {
                getValue: function () {
                    return prevState.value
                }
            };
        });

})();