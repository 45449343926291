angular.module('sinvoice-desktop').controller('commissionsReportController',
    function ($scope,
              $translate,
              $filter,
              $state,
              Referral,
              $compile,
              DTOptionsBuilder,
              DTColumnBuilder,
              ReportsExport,
              DirectiveCompiler,
              DataTableLanguageSettings,
              DataTableFunctions,
              DataTableDatepickerLabels) {

        $scope.currentState = $state.current.name;
        $scope.chart        = {commissions: {}, customers: {}};
        $scope.activeChart  = 'commissions';

        $scope.$emit('onLoadReportPage', {
            state: $scope.currentState,
            page : {
                title      : $filter('translate')('reports.pages.partnersCommission'),
                description: '',
                alias      : 'reports.pages.partnersCommission'
            }
        });
        function loadCharts() {

            var months = $filter('translate')('global.monthNames').split(',').reverse();

            function sumFunc(prev, next) {
                return prev + next;
            }


            return Referral.getStats().then(function (data) {

                var now            = new Date();
                var currMonth      = Number(now.getMonth());
                var currMonthIndex = months.length - (currMonth) - 1;

                $scope.chart.commissions.data       = data.commissions.data;
                $scope.chart.commissions.categories = months;
                $scope.chart.commissions.total      = data.commissions.data.length ? data.commissions.data.reduce(sumFunc) : 0;
                $scope.chart.commissions.thisMonth  = data.commissions.data[currMonthIndex] || 0;
                $scope.chart.customers.data         = data.customers;
                $scope.chart.customers.categories   = months;
                $scope.chart.customers.total        = data.customers.length ? data.customers.reduce(sumFunc) : 0;
                $scope.chart.customers.thisMonth    = data.customers[currMonthIndex] || 0;
            });
        };

        function loadTable() {

            $scope.dtInstance = {};
            $scope.dtColumns  = initializeDataColumns();
            $scope.dtOptions  = initializeDataTable(function () {
                return Referral.getCommissions();
            });
        }


        function initializeDataColumns() {

            var columns = [

                DTColumnBuilder
                    .newColumn('select')
                    .withTitle('<dt-select-all-checkbox/>')
                    .notSortable()
                    .withOption('width', '50px')
                    .withClass('select-checkbox')
                    .renderWith(function (data) {
                        return '<dt-select-checkbox/>';
                    }),

                DTColumnBuilder
                    .newColumn('creationTime')
                    .withTitle($translate.instant('distributors.commissions_page.date'))
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),

                DTColumnBuilder
                    .newColumn('name')
                    .withTitle($translate.instant('distributors.commissions_page.name'))
                    .withClass('referralName')
                    .renderWith(function (data, type, full) {
                        var innerColumn = '' +
                            '<span class="inner-wrapper">' +
                            '   <span class="name-wrapper">' +
                            '      ' + full.referral.firstName + ' ' + full.referral.lastName +
                            '   </span>' +
                            '   <span class="table_controls_type_2 row-buttons">' +
                            '      <button class="btn btn-info btn-sm mr10 buttons-collection the_button_2 export pdf-export-button">' +
                            '      </button>' +
                            '      <button class="btn btn-info btn-sm mr10 buttons-collection the_button_2 print-grey print-button">' +
                            '      </button>' +
                            '   </span>' +
                            '</span>';

                        return innerColumn;
                    }),

                DTColumnBuilder
                    .newColumn('amount')
                    .withTitle($translate.instant('distributors.commissions_page.sum'))
                    .withOption('render', {

                        "filter" : function (data, type, fullData) {
                            return data;
                        },
                        "display": function (data, type, fullData) {
                            return DirectiveCompiler.compileIntoHtml('money-amount', {
                                currency: 'NIS',
                                amount  : data
                            });
                        }
                    }),
            ];

            columns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return columns;
        }


        function initializeDataTable(promise) {
            return DTOptionsBuilder

                .fromFnPromise(promise)
                .withBootstrap()
                .withOption('sDom', "<'row'<'col-xs-12 text-left mb20 dotted_buttons'B>>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('autoWidth', false)

                .withOption('oLanguage', DataTableLanguageSettings)
                .withOption('createdRow', rowCallback)
                .withColumnFilter({
                    aoColumns: [
                        null,
                        {type: 'date-range', bRegex: true, bSmart: true, sRangeFormat: DataTableDatepickerLabels.fromToLabels()},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'number-range', bRegex: true, bSmart: true, sRangeFormat: DataTableDatepickerLabels.fromToLabels()}
                    ]
                })
                .withOption('buttonContainer', '#comissions_table_button_container')
                .withButtons([
                    {
                        extend   : 'collection',
                        autoClose: true,
                        text     : $translate.instant('reports.export'),
                        className: 'the_button_2 export',
                        buttons  : [
                            {
                                extend     : 'selected',
                                'text'     : 'Excel',
                                'className': 'the_button_2',
                                action     : function (e, dt) {

                                    var ids = DataTableFunctions.getSelectedData(dt).map(function (row) {
                                        return row.id
                                    });

                                    ReportsExport.showCommissionsAsXLS(ids);
                                }

                            },
                            {
                                extend     : 'selected',
                                'text'     : 'PDF',
                                'className': 'the_button_2',
                                action     : function (e, dt) {
                                    var ids = DataTableFunctions.getSelectedData(dt).map(function (row) {
                                        return row.id
                                    });

                                    ReportsExport.showCommissionsAsPDF(ids);
                                }
                            }
                        ]
                    },
                    {
                        extend   : 'selected',
                        text     : $translate.instant('reports.print'),
                        className: 'the_button_2 print-grey',
                        action   : function (e, dt) {

                            var ids = DataTableFunctions.getSelectedData(dt).map(function (row) {
                                return row.id
                            });


                            ReportsExport.printCommissions(ids);
                        }
                    }
                ])
                .withOption('order', [2, "desc"]);


        }

        function rowCallback(nRow, aData, iDisplayIndex) {
            $compile(nRow)($scope);

            angular.element(nRow).on('click', '.pdf-export-button', function () {
                ReportsExport.showCommissionsAsPDF([aData.id])
            });

            angular.element(nRow).on('click', '.print-button', function () {
                ReportsExport.printCommissions([aData.id])
            })
        }

        (function () {
            loadTable();
            loadCharts()
        })()
    });