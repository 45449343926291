/**
 * Created by dbichenko on 22.07.2016.
 */
angular.module('sinvoice-desktop').controller('typeDocumentController',

    function ($scope, $q, $translate, DocumentModal, Modal, promiseHelper, Document, $state, $filter,AuthDataStorage, DocumentTypeConstants) {

        $scope.businessType = AuthDataStorage.getProfile().businessType.type;


        $scope.docTypeIsNotAvailable = function (docType) {

            return !$scope.documentTypes[docType]
        };

        function getPreSelectTypePromise(selectedType) {

            var popups      = [];
            var creditTypes = [
                DocumentTypeConstants.TYPE_TAX_INVOICE_CREDIT,
                DocumentTypeConstants.TYPE_RECEIPT_CREDIT,
                DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT_CREDIT
            ];

            // TODO move this into separate service or into according type controllers
            if (creditTypes.indexOf(selectedType.docType) != -1) {
                popups.push(function (flow) {
                    return Modal
                        .confirm(
                            $filter('documentPopupTranslate')('documents.credit_disclaimer', selectedType.docType),
                            null,
                            null,
                            {title: $translate.instant('documents.credit_disclaimer_title')}
                        )
                        .then(flow.allow);
                });
            }

            if (selectedType.docType === DocumentTypeConstants.TYPE_BALANCE_ADJUSTMENT) {
                popups.push(function (flow) {
                    return Modal
                        .alert(
                            $translate.instant('documents.balance_adjustment.warning_text'),
                            {title: $translate.instant('documents.balance_adjustment.warning_text_title')}
                        )
                        .then(flow.allow);
                });
            }

            function confirmDocNumberPopup(flow) {

                if (!selectedType.isEditableDocTypeNumber) {
                    flow.allow();
                    return
                }


                Modal.customDialog({
                    title         : $filter('translateDocType')(selectedType.docType) + ' ' + '#' + selectedType.nextDocNumber,
                    className     : 'centered-popup',
                    closeButton   : true,
                    onEscape      : function () {
                        flow.deny();
                    },
                    templateUrl   : './desktop/components/common/common-popup.html',
                    scopeVariables: {
                        message: $translate.instant('documents.change_doc_number')
                    },
                    size          : "medium",
                    buttons       : {
                        "Confirm": {
                            className: "btn-info",
                            callback : function () {
                                flow.allow();
                            },
                            label    : $translate.instant('common.confirm')
                        },
                        "Edit"   : {
                            className: "btn",
                            callback : function () {
                                $state.go('profile.my-docs');
                                flow.deny();
                                Modal.hideAll();
                            },
                            label    : $translate.instant('common.edit')
                        }
                    }

                });

            }

            popups.push(confirmDocNumberPopup);

            return promiseHelper.createPromiseChainWithDefer(popups);
        }

        $scope.selectType = function (type) {

            var selectedType = $scope.documentTypes[type];

            getPreSelectTypePromise(selectedType).then(function () {
                $scope.editedDocument.type = selectedType;
                DocumentModal._displayNewDocumentModal();
            });
        }
    }
);