/**
 * Created by Front-end on 22.12.2016.
 */
angular.module('sinvoice-desktop').controller('newChequeReportBlockController', function ($scope, Report, Events, $filter, ReportsFilterMapper, $stateParams, TaxPeriodProvider) {

    $scope.availableStatuses = [];
    $scope.spinner           = {
        enabled: true
    };
    $scope.newReportForm     = {};

    $scope.setOtherDate     = function () {
        $scope.selected.dateExample = $scope.dateExamples[4];
    };
    $scope.updateFromToDate = function (date) {
        $scope.selected.from = date.from ? date.from : new Date();
        $scope.selected.to   = date.to ? date.to : new Date();
    };


    $scope.produceNewReport = function () {

        $scope.spinner.enabled = true;
        Report.createReport('cheques', $scope.selected).then(function (data) {
            $scope.spinner.enabled = false;
            Events.triggerEvent('report.update', data);
        });

    };


    function loadStatuses() {
        return Report.getFilterValues('cheques')
            .then(function (data) {
                $scope.availableStatuses = data.statuses.map(function (status) {
                    return {
                        id   : status,
                        label: $filter('translateChequeStatus')(status)
                    }
                });

                $scope.selected.filters.statuses = ReportsFilterMapper.getMappedValues($stateParams.statuses, 'id', $scope.availableStatuses);


            })
    }

    (function () {

        $scope.selected = {
            filters: {
                statuses: []
            }
        };

        $scope.dateExamples = TaxPeriodProvider.getDatepickerValues();
        var dateFilters     = ReportsFilterMapper.getMappedDate(
            $stateParams.from,
            $stateParams.to,
            $scope.dateExamples
        );

        $scope.selected.to          = dateFilters.to;
        $scope.selected.from        = dateFilters.from;
        $scope.selected.dateExample = dateFilters.dateExample;
        
 

        loadStatuses().then(function () {
            $scope.spinner.enabled = false;
        });
    })();

});
