/**
 * Created by dbichenko on 12.08.2016.
 */
angular.module('sinvoice-desktop').controller('recentActionsController', function ($scope, Url, API, $filter, Events) {

    var perPage = 2;

    $scope.spinner = true;
    $scope.recentActions = null;

    $scope.showAmount = perPage;

    $scope.showMore = function () {
        $scope.showAmount += perPage;
    };


    function getTranslateKey(value) {
        return 'control.widgets.recent_actions.messages.' + value.type;
    }

    function formatRecentActions(data) {

        $scope.recentActions = data.map(function (action, key) {

            var translateKey = getTranslateKey(action);
            var translatedDocType = action.payload && action.payload.docType
                ? $filter('translateDocType')(action.payload.docType)
                : null;

            var message = $filter('translate')(translateKey, {
                title: action.target.title,
                docType: translatedDocType,
                customerName: action.payload.customerName || null
            });

            return {
                id: action.id,
                date: $filter('dpickerDateFormatter')(action.date),
                message: message
            }
        })
    }

    function updateRecentActions() {
        API.get(Url.stats.actions()).success(function (data) {

            formatRecentActions(data);
            $scope.spinner = false;

        });
    }

    (function () {

        updateRecentActions();

        Events.subscribeToEvent(
            ['customer.update','documents.update'],
            $scope,
            updateRecentActions
        );




    })()
});
