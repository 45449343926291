/**
 * Created by abichenko on 12.03.18.
 */
angular.module('sinvoice-desktop').directive('addressBlock', function () {


    return {
        scope      : {
            title                    : '=',
            addresses                : '=',
            editedAddress            : '=',
            isDetailsEditingAvailable: '=',
            onDeleteAddress          : '=',
            onEditAddress            : '=',
            isElementShown           : '='
        },
        controller : 'addressBlockController',
        templateUrl: './desktop/components/customers/partials/address-block.html',
        link       : function (scope, element, attrs) {

        }
    }
})