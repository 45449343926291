/**
 * Created by Front-end on 17.01.2017.
 */
angular.module('sinvoice-desktop').controller('ordersBookSliderTemplateController',
    function ($scope, ReportsExport) {

        $scope.selected = {slide: {}};




        $scope.exportToPDF = function () {
            ReportsExport.showOrdersBookAsPDF($scope.getCurrentOrder());
        };

        $scope.exportToExcel = function () {
            ReportsExport.downloadOrdersBookAsXLS($scope.getCurrentOrder());
        };


        $scope.print = function () {
            ReportsExport.printOrdersBook($scope.getCurrentOrder());
        };

        $scope.email = function () {
            ReportsExport.emailOrdersBook($scope.getCurrentOrder());
        };

        $scope.getCurrentOrderIndex = function () {
            return $scope.getCurrentOrder().index + 1;
        };

        $scope.getNextOrderIndex = function () {
            return $scope.getNextOrder().index + 1;
        };

        $scope.getPreviousOrderIndex = function () {
            return $scope.getPreviousOrder().index + 1;
        };

        $scope.getCurrentOrder = function () {
            return $scope.selected.slide;
        };

        $scope.getNextOrder = function () {
            var position = _.findIndex($scope.mappedOrders,$scope.selected.slide);
            return $scope.mappedOrders[position - 1]
        };

        $scope.getPreviousOrder = function () {
            var position = _.findIndex($scope.mappedOrders,$scope.selected.slide);
            return $scope.mappedOrders[position + 1]
        };

        (function () {

            $scope.mappedOrders = angular.copy($scope.orders);

            $scope.mappedOrders = _.orderBy($scope.mappedOrders, ['issueDate'], ['asc']);

            $scope.mappedOrders.forEach(function (order, i) {
                order.index = i;
            });

            $scope.mappedOrders = $scope.mappedOrders.reverse();

        })()
    });