/**
 * Created by abichenko on 30.10.17.
 */
angular.module('sinvoice-mobile').run(function (UserSubscriptionCallbackContainer, MobileModal, $filter, DataStorage) {

    UserSubscriptionCallbackContainer.addEditActionCallback(function (flow, result) {

        if (result) {
            flow.allow();
            return;
        }

        showSubscriptionModal(
            $filter('translate')('common.subscriptions.edit_action_restricted_modal.message'),
            function () {
                flow.deny();
            });
    });


    UserSubscriptionCallbackContainer.addDocumentCreationCallback(function (flow, data) {

        var currentPeriod       = data.content.currentPeriod;
        var currentSubscription = data.content.currentSubscription;

        if (currentPeriod && currentSubscription) {
            flow.allow();
            return;
        }

        showSubscriptionModal(
            $filter('translate')('common.subscriptions.subscription_expired_modal.message'),
            function () {
                flow.deny()
            });


    });

    UserSubscriptionCallbackContainer.addDocumentCreationCallback(function (flow, data) {

        var currentPeriod = data.content.currentPeriod;
        var documentsLeft = currentPeriod.selectedPlan.maxDocuments - currentPeriod.documentsUsed;

        if ((documentsLeft) !== 0) {
            flow.allow();
            return;
        }


        showSubscriptionModal(
            $filter('translate')('common.subscriptions.subscription_limit_modal.message'),
            function () {
                flow.deny()
            });

    });

    UserSubscriptionCallbackContainer.addDocumentCreationCallback(function (flow, data) {

        var currentPeriod   = data.content.currentPeriod;
        var documentsLeft   = currentPeriod.selectedPlan.maxDocuments - currentPeriod.documentsUsed;
        var lastTimeShownOn = DataStorage.getValue('lastTimeShownAtDocumentNumber');

        if (!(documentsLeft <= 5) || (lastTimeShownOn === documentsLeft)) {
            flow.allow();
            return;
        }

        DataStorage.setValue('lastTimeShownAtDocumentNumber', documentsLeft);

        showSubscriptionModal(
            $filter('translate')('mobile-common.current_documents_modal_text', {documentsLeft: documentsLeft}),
            function () {
                flow.allow()
            });


    });

    UserSubscriptionCallbackContainer.addDocumentCreationCallback(function (flow, data) {

        var currentSubscription = data.content.currentSubscription;
        var activeUntil         = momentWrapped(currentSubscription.activeUntil);
        var oneWeekLater        = momentWrapped().add(1, 'weeks').startOf('day');

        var isPopupShown = DataStorage.getValue('isExpireSubscriptionPopupShown');

        if (isPopupShown || (activeUntil > oneWeekLater)) {
            flow.allow();
            return;
        }

        DataStorage.setValue('isExpireSubscriptionPopupShown', true);

        showSubscriptionModal(
            $filter('translate')('common.subscriptions.subscription_remind_modal.message', {
                expirationDate: $filter('dpickerDateFormatter')(momentWrapped(currentSubscription.activeUntil).subtract(1, 'days'))
            }),
            function () {
                flow.allow();
            }
        );

    });

    function showSubscriptionModal(message, onEscape) {

        var storedDialog;

        MobileModal
            .customDialog({
                templateUrl   : './mobile/components/mobile-common/user-subscription-modal.html',
                className     : 'common-popup',
                scopeVariables: {
                    message: message
                },
                onEscape      : function () {
                    onEscape();
                    storedDialog.close();
                }
            })
            .then(function (dialog) {
                storedDialog = dialog;
            });
    }

});