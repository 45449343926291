/**
 * Created by dbichenko on 21.07.2016.
 */
angular.module('sinvoice-desktop').controller('invoiceRetainerController', function ($scope, DocumentTypeConstants) {

    $scope.months                    = [
        'July', 'August'
    ];
    $scope.notificationReceiverTypes = [
        {id: 1, label: 'Client'},
        {id: 2, label: 'User'},
        {id: 3, label: 'Both'}
    ];

    $scope.template.$reliableTypesOfOpenDocuments = [DocumentTypeConstants.TYPE_RECEIPT];

    (function () {


    })();


});