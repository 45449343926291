/**
 * Created by abichenko on 22.03.18.
 */
angular.module('sinvoice-mobile')
    .directive('selectCreditDoctypeButton', function () {
        return {
            scope   : {
                availableDocTypes: '=',
                docType          : '@',
                onClick          : '=',
            },
            replace : true,
            template: '' +
            '<button type="button" ng-click="vm.onSelect(vm.docType)" ng-hide="vm.docTypeIsNotAvailable(vm.docType)">' +
            '       {{vm.docType | translateDocType}}' +
            '</button>',

            bindToController: true,
            controllerAs    : 'vm',
            controller      : function () {
                var vm = this;

                vm.onSelect = function (type) {
                    vm.onClick(type);
                };

                vm.docTypeIsNotAvailable = function (docType) {
                    return !_.includes(vm.availableDocTypes, docType);
                }
            }
        }


    })
    .directive('selectDoctypeButton', function () {
        return {
            scope   : {
                availableDocTypes: '=',
                docType          : '@',
                onClick          : '=',
            },
            replace : true,
            template: '' +
            '<button type="button" ng-click="vm.onSelect(vm.docType)" class="btn-docType {{vm.docType}}" ng-hide="vm.docTypeIsNotAvailable(vm.docType)">' +
            '   <span class="button-text">' +
            '       {{vm.docType | translateDocType}}' +
            '   </span>' +
            '</button>',

            bindToController: true,
            controllerAs    : 'vm',
            controller      : function () {
                var vm = this;

                vm.onSelect = function (type) {
                    vm.onClick(type);
                };

                vm.docTypeIsNotAvailable = function (docType) {
                    return !_.includes(vm.availableDocTypes, docType);
                }
            }
        }
    });
