/**
 * Created by abichenko on 24.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('singlePaymentWrapperController', function ($scope) {

        $scope.$watch('payment.amount', calculateSummary);

        function calculateSummary() {
            $scope.payment.$summary = $scope.payment.amount;
        }

    });