/**
 * Created by abichenko on 24.01.18.
 */
angular.module('sinvoice-mobile').directive('closeButtonBlock', function (DocumentConstants) {


    return {
        scope     : {
            document: '=',
            onClose : '&'
        },
        template  : '' +
        '<button class="btn btn-warning" type="button" ng-click="close()" ng-if="isClosable()">' +
        '   {{ \'documents.close_document_button\' | translate }}' +
        '</button>' +
        '',
        controller: function ($scope) {
            $scope.close      = function () {
                $scope.onClose();
            };
            $scope.isClosable = function () {
                return $scope.document.id
                    && $scope.document.status === DocumentConstants.STATUS_OPEN
                    && $scope.document.type.isClosable
            }
        },
        link      : function (scope, element, attrs) {

        }
    }
})