/**
 * Created by dbichenko on 13.01.2017.
 */
angular.module('sinvoice-desktop').controller('chequesStatusChangeBlockController', function ($scope, $filter, Report, DataTableFunctions, $timeout) {

    $scope.availableStatuses = [];

    $scope.spinner = {enabled: true};
    $scope.changeStatus = function () {
        var ids = DataTableFunctions.getSelectedData($scope.dt.instance.DataTable).map(function (val) {
            return val[0];
        });

        $scope.spinner.enabled = true;

        $timeout(function () {
            $scope.spinner.enabled = false;
        }, 1000);


    };


    function loadStatuses() {
        return Report.getFilterValues('cheques')
            .then(function (data) {
                $scope.availableStatuses = data.statuses.map(function (status) {
                    return {
                        id   : status,
                        label: $filter('translateChequeStatus')(status)
                    }
                });
            })
    }


    loadStatuses().then(function () {
        $scope.spinner.enabled = false;
    });


});