/**
 * Created by abichenko on 28.03.18.
 */
angular.module('sinvoice-mobile').controller('selectNewPaymentTypePopupController', function ($scope, PaymentBlockModal) {

    $scope.close = function () {
        $scope.$currentModal.close();
    };


    $scope.selectType = function (type) {

        PaymentBlockModal.addPayment(type, $scope.onSave, $scope.documentVariables);

        $scope.$currentModal.close();
    };


});