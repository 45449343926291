/**
 * Created by dbichenko on 12.08.2016.
 */
angular.module('sinvoice-desktop').controller('subscriptionStatusController', function ($scope, Subscription, Events, $filter) {


    $scope.subscription = {};
    $scope.spinner      = true;

    $scope.upgradeSubscription = function (e) {

        e.preventDefault();

        alert('subscription is upgraded!');

    };
    $scope.getActiveUntil      = function () {
        return $filter('dpickerDateFormatter')(momentWrapped($scope.subscription.currentSubscription.activeUntil).subtract(1, 'days'));
    };

    function loadDocuments() {

        Subscription.getCurrent()
            .then(function (data) {
                $scope.subscription = data.content;

                if (data.content.currentPeriod) {
                    $scope.maxDocuments     = data.content.currentPeriod.selectedPlan.maxDocuments;
                    $scope.currentDocuments = data.content.currentPeriod.documentsUsed;
                    $scope.percent          = ($scope.currentDocuments / $scope.maxDocuments) * 100;
                }

            })
            .finally(function () {
                $scope.spinner = false;

            })
    }

    (function () {

        Events.subscribeToEvent('documents.update', $scope, loadDocuments);

        loadDocuments();
    })()


});