/**
 * Created by dbichenko on 30.08.2016.
 */
angular.module('sinvoice-desktop').controller('managedCustomersTopBlockController', function ($scope, ManagedCustomersModal) {

    $scope.openNewManagedCustomerForm = openNewManagedCustomerForm;

    function openNewManagedCustomerForm() {
        ManagedCustomersModal.sendInvitations();
    }

});