angular.module('sinvoice-desktop')
    .directive('reportAmountField', function () {


        return {
            template: '' +
            '<div>' +
            '   <span cut-value ng-if="value | isNegativeBalanceAdjustment:item" class="negative-amount">' +
            '       {{ value | number:2 }}' +
            '   </span>' +
            '   <span cut-value ng-if="value | isNegativeValue:item" class="negative-amount">' +
            '       -{{value | number:2}}' +
            '   </span>' +
            '   <span cut-value ng-if="!(value | isNegativeValue:item) && !(value | isNegativeBalanceAdjustment:item)">' +
            '       {{value | number:2}}' +
            '   </span>' +
            '</div>',
            scope   : {'item': '=', 'value': '='},
            link    : function (scope, element, attrs) {

            }
        }
    })
    .directive('itemIndexInput', function () {
        return {
            require: 'ngModel',
            link   : function (scope, element, attrs, ngModel) {

                ngModel.$parsers.push(function (val) {
                    if (val === 0)
                        return null;

                    return val - 1;
                });

                ngModel.$formatters.push(function (val) {
                    if (val === null)
                        return 0;
                    return val + 1;
                })

            }
        }
    })
    .directive('paginationReport', ['$translate', function ($translate) {
        return {
            require : 'ngModel',
            scope   : {
                list: '='
            },
            template: '' +
            '           <div class="pagination-holder">' +
            '               <span ng-bind-html="(\'reports.pagination.label\' | translate:{pageCount:(list.length)} | trusted)"></span>' +
            '               <span class="paginationInput">' +
            '                   <button type="button" tabindex="0" class="minusButton" ng-click="previous()" ng-disabled="!isPreviousButtonEnabled()" ng-class="{disabled:!isPreviousButtonEnabled()}"></button>' +
            '                   <input type="text" readonly item-index-input ng-model="currentItemIndex">' +
            '                   <button type="button" tabindex="0"  class="plusButton"  ng-click="next()" ng-disabled="!isNextButtonEnabled()" ng-class="{disabled:!isNextButtonEnabled()}"></button>' +
            '               </span>' +
            '           </div>',
            link    : function (scope, element, attrs, ngModel) {


                scope.currentItemIndex = null;
                scope.currentItem      = null;

                scope.$watchCollection('list', function (newVal, oldVal) {
                    initData();
                });

                scope.$watch('currentItem', function (newVal, oldVal) {

                    if (angular.isUndefined(newVal) || newVal === null)
                        return;


                    ngModel.$setViewValue(newVal)
                });

                function getPreviousItemIndex() {

                    var newIndex;
                    if (scope.list.length < scope.currentItemIndex - 1)
                        newIndex = scope.currentItemIndex - 1;
                    else {
                        newIndex = 0;
                    }
                    return newIndex;
                }

                function getNextItemIndex() {
                    var newIndex;

                    if (scope.list.length > scope.currentItemIndex + 1)
                        newIndex = scope.currentItemIndex + 1;
                    else {
                        newIndex = 0;
                    }
                    return newIndex;
                }

                scope.previous = function () {
                    scope.currentItem      = scope.list[getPreviousItemIndex()];
                    scope.currentItemIndex = getPreviousItemIndex();
                };

                scope.next = function () {
                    scope.currentItem      = scope.list[getNextItemIndex()];
                    scope.currentItemIndex = getNextItemIndex();
                };

                scope.isPreviousButtonEnabled = function () {
                    return scope.list.length > 1;
                };

                scope.isNextButtonEnabled = function () {
                    return scope.list.length > 1;
                };

                function initData() {
                    if (scope.list && scope.list.length) {
                        scope.currentItemIndex = 0;

                        if (!_.values(scope.ngModel).some(function (x) {
                                return x !== undefined
                            })) {
                            ngModel.$setViewValue(scope.list[0])
                        } else {
                            scope.list.forEach(function (item, index) {
                                if (_.isEqual(scope.ngModel, item)) {
                                    scope.currentItem = index + 1;
                                }
                            })
                        }

                    }
                }
            }
        };
    }]);
