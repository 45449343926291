/**
 * Created by abichenko on 11.08.17.
 */
angular.module('sinvoice-desktop').controller('exportDocumentsToMivnePopupController', function ($scope, $filter, DocumentExport) {


    $scope.spinner = {enabled: false};

    $scope.getRecordCodeDescription = function (value) {

        if (!value)
            return '';

        return $filter('translate')('documents.export_to_mivne_popup.code_descriptions.' + value)
    };
    $scope.getDocTypeName           = function (value) {

        if (!Number(value))
            return '';

        return $filter('translate')('documents.export_to_mivne_popup.doc_types.' + value)
    };

    $scope.print = function () {

        $scope.spinner.enabled = true;

        DocumentExport
            .printMivneSummary($scope.ids, $scope.from, $scope.to)
            .finally(function () {
                $scope.spinner.enabled = false;

            });

    }

    $scope.confirm = function () {
        $scope.spinner.enabled = true;

        DocumentExport
            .downloadAsMivne($scope.ids, $scope.from, $scope.to)
            .then(function () {
                $scope.$currentModal.close();
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })
    };

    $scope.cancel = function () {
        $scope.$currentModal.close();
    };
});