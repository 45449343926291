/**
 * Created by dbichenko on 06.03.2017.
 */
angular.module('sinvoice-mobile').controller('MobileHelpController', function ($scope, HelpService, $timeout) {

    $scope.spinner         = {enabled: true};
    $scope.categories      = [];
    $scope.openedQuestions = [];

    (function () {
        HelpService.loadCategories()
            .then(function (data) {
                $scope.categories = data;
            })
            .finally(function () {
                $timeout(function () {
                    $scope.spinner.enabled = false;
                });
            });
    })();

    $scope.toggleQuestion = function (id) {
        if ($scope.isQuestionOpened(id)) {
            closeQuestion(id);
        } else {
            $scope.openedQuestions.push(id);
        }
    };

    $scope.isQuestionOpened = function (id) {
        return $scope.openedQuestions.indexOf(id) !== -1;
    };

    function closeQuestion(id) {
        var index = $scope.openedQuestions.indexOf(id);
        $scope.openedQuestions.splice(index, 1);
    }


});