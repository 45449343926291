/**
 * Created by dbichenko on 25.08.2016.
 */
angular.module('sinvoice-desktop').controller('remainingBalanceController', function ($scope, DocumentInitializer) {

    function calculateRelatedDocsRemainingBalance() {

        var remainingBalance = 0;

        if ($scope.editedDocument.relatedDocs && $scope.editedDocument.relatedDocs.length) {
            $scope.editedDocument.relatedDocs.forEach(function (document) {

                if (!document.remainingBalance)
                    return;

                remainingBalance += parseFloat(document.remainingBalance);
            });

            if (remainingBalance < 0)
                remainingBalance = 0;

        }

        $scope.template.$relatedDocsRemainingBalance = remainingBalance;
    }

    function calculateRemainingBalance() {

        if ($scope.template.isEditable) {

            var relatedDocsRemaining = $scope.template.$relatedDocsRemainingBalance;
            var payments             = $scope.template.$paymentsTotalWithVat || 0;
            var items;

            if (relatedDocsRemaining) {
                items = relatedDocsRemaining;
            } else {
                items = $scope.template.$finalItemsTotalWithVat || 0;
            }


            $scope.template.$remainingBalanceActual = payments - items;
            if ($scope.template.$remainingBalanceActual < 0) {
                $scope.editedDocument.remainingBalance = 0;
            } else {
                $scope.editedDocument.remainingBalance = $scope.template.$remainingBalanceActual;
            }

        }
    }

    DocumentInitializer.addInitCallback(function () {

        $scope.template.$relatedDocsRemainingBalance = 0.0;

        $scope.$watchCollection('editedDocument.relatedDocs', calculateRelatedDocsRemainingBalance);

        $scope.$watch('template.$paymentsTotalWithVat + template.$itemsTotalWithVatDiscounted + template.$relatedDocsRemainingBalance', calculateRemainingBalance);

    })

});