/**
 * Created by dbichenko on 12.08.2016.
 */
angular.module('sinvoice-desktop').controller('incomeController', function ($scope, API, Url, $filter) {

    $scope.spinner     = true;
    $scope.activeChart = 'income';
    $scope.charts      = {};

    (function () {
        API.get(Url.stats.income()).success(function (data) {

            var months = $filter('translate')('global.monthNames').split(',').reverse();


            function sumFunc(prev, next) {
                return prev + next;
            }

            $scope.charts.received = {
                data      : data.receipt_receiptTI,
                categories: months,
                total     : data.receipt_receiptTI.reduce(sumFunc)
            };
            $scope.charts.income   = {
                data      : data.income,
                categories: months,
                total     : data.income.reduce(sumFunc)
            };

            $scope.spinner = false;

        });
    })()


});