/**
 * Created by abichenko on 30.10.17.
 */
angular.module('sinvoice-common').factory('UserSubscriptionCallbackContainer', function (promiseHelper) {

    var documentCreationCallbacks = [];
    var editActionCallbacks       = [];


    return {
        addDocumentCreationCallback       : function (callback) {
            documentCreationCallbacks.push(callback);
        },
        getDocumentCreationCallbackPromise: function (data) {
            return promiseHelper.createPromiseChainWithDefer(documentCreationCallbacks, data);
        },
        addEditActionCallback             : function (callback) {
            editActionCallbacks.push(callback);
        },
        getEditActionCallbackPromise      : function (data) {
            return promiseHelper.createPromiseChainWithDefer(editActionCallbacks, data);
        }
    }

})