/**
 * Created by abichenko on 02.04.18.
 */
angular.module('sinvoice-desktop')

    .filter('translateReportDocTypeSummaryLabel', function ($filter) {

        return function (value) {
            return $filter('translate')('reports.incomeAndDebts.doctype_totals.' + value);
        }

    })
    .filter('translateIncomeRevenueSummaryRowLabel', function ($filter) {

        return function (value) {

            if (!value)
                return;

            return $filter('translate')('reports.incomeAndDebts.group_title_' + value);
        }

    });