/**
 * Created by abichenko on 05.09.17.
 */
angular.module('sinvoice-desktop').run(function () {

    applyClipboardModalFix();


    function applyClipboardModalFix() {
        $.fn.modal.Constructor.prototype.enforceFocus = function () {

        };

    }

});