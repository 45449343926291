/**
 * Created by dbichenko on 27.09.2016.
 */
angular.module('sinvoice-desktop').factory('Template', function (API, Url, $q) {

    return {

        getSettings    : function () {
            var defer = $q.defer();

            API.get(Url.templates.get())
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getAllAvailable: function () {
            var defer = $q.defer();

            API.get(Url.templates.getFiles())
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        update         : function (request) {

            var defer = $q.defer();

            API.post(Url.templates.update(), request)
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        updateHebrewLogo     : function (request) {

            var defer = $q.defer();

            API.post(Url.templates.updateLogo(), request)
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        },
        getHebrewLogo     : function (request) {

            var defer = $q.defer();

            API.get(Url.templates.updateLogo(), request)
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (error) {
                    defer.reject(error);
                });

            return defer.promise;
        }
    };
});