/**
 * Created by dbichenko on 20.12.2016.
 */
angular.module('sinvoice-desktop').factory('RelatedDocumentTableCallbackContainer', function (promiseHelper) {

    var onPostFetchCallbacks = [null];
    var postSelectCallbacks  = [];
    var preSelectCallbacks   = [];

    return {

        addPreSelectCallback: function (callback) {

            if (!angular.isFunction(callback))
                throw new Error('You should provide correct callback');

            preSelectCallbacks.push(callback);
        },

        addPostSelectCallback : function (callback) {

            if (!angular.isFunction(callback))
                throw new Error('You should provide correct callback');

            postSelectCallbacks.push(callback);
        },
        addOnPostFetchCallback: function (callback) {

            if (!angular.isFunction(callback))
                throw new Error('You should provide correct callback');


            onPostFetchCallbacks.push(callback);

        },
        getPostSelectPromise  : function (selectedDocs, addedDocument) {

            var callbacks = angular.copy(postSelectCallbacks);

            return promiseHelper.resolvedPromise().then(function () {
                return promiseHelper.createPromiseChainWithDefer(callbacks, {
                    selectedDocs : selectedDocs,
                    addedDocument: addedDocument
                })
            });
        },

        getPreSelectPromise: function (selectedDocs, addedDocument, actions) {

            var callbacks = angular.copy(preSelectCallbacks);

            return promiseHelper.resolvedPromise().then(function () {
                return promiseHelper.createPromiseChainWithDefer(callbacks, {
                    selectedDocs : selectedDocs,
                    addedDocument: addedDocument,
                    actions      : actions
                })
            });
        },

        getOnPostFetchPromise: function (documents) {

            var callbacks = angular.copy(onPostFetchCallbacks);
            callbacks.unshift(function () {
                return documents;
            });

            return promiseHelper.createPromiseChain(callbacks);

        },
        reset                : function () {
            onPostFetchCallbacks = [];
            postSelectCallbacks  = [];
            preSelectCallbacks   = [];
        }
    }


});