/**
 * Created by abichenko on 02.04.18.
 */
angular.module('sinvoice-desktop')

    .filter('translateIncomeForAccountingCurrencySummaryLabel', function ($filter) {

        return function (value) {

            if (!value)
                return;

            return $filter('translate')('reports.incomeForAccounting.currencySummary.label', {
                'currency': $filter('translateCurrencyLabel')(value)
            });
        }

    })

    .filter('translateIncomeForAccountingCurrencySummaryLabelInNis', function ($filter) {

        return function (value) {

            if (!value)
                return;

            return $filter('translate')('reports.incomeForAccounting.currencySummary.label_income_in_nis', {
                'currency': $filter('translateCurrencyLabel')(value)
            });
        }

    })

    .filter('translateIncomeForAccountingCurrencySummaryReceivedLabelInNis', function ($filter) {

        return function (value) {

            if (!value)
                return;

            return $filter('translate')('reports.incomeForAccounting.currencySummary.label_paid_in_nis', {
                'currency': $filter('translateCurrencyLabel')(value)
            });
        }

    })

    .filter('translateIncomeForAccountingItemSummaryLabel', function ($filter) {


        return function (value) {

            if (!value)
                return;

            return $filter('translate')('reports.incomeForAccounting.itemSummary.' + value);
        }

    })
    .filter('translateIncomeForAccountingPaymentSummaryLabel', function ($filter) {

        return function (value) {

            if (!value)
                return;

            return $filter('translate')('reports.incomeForAccounting.paymentSummary.' + value);
        }

    });