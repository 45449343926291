/**
 * Created by abichenko on 14.12.17.
 */
angular.module('sinvoice-desktop')
    .controller('paymentsElementAccountController', function ($scope, BusinessBankAccountDataProvider, DocumentInitializer) {


        DocumentInitializer.addInitCallback(function () {
            if (!angular.isDefined($scope.payment.account) && $scope.selectedCustomerData) {
                $scope.payment.account = BusinessBankAccountDataProvider.getAccount($scope.selectedCustomerData);
            }
        })


    });