/**
 * Created by abichenko on 01.12.17.
 */
angular.module('sinvoice-mobile')
    .controller('newDocumentStepThreeController', function ($scope, Document, $filter, MobileModal) {

        $scope.spinner = {enabled: false};

        $scope.selected = {};

        $scope.documentForm = {};

        $scope.removeEmail = function (index) {
            $scope.editedDocument.clientEmails.splice(index, 1);
        };

        $scope.addEmail = function () {

            $scope.editedDocument.clientEmails.push($scope.selected.additionalEmail);

            $scope.selected.additionalEmail = null;
        };

        $scope.onSendRequiredChange = function () {
            if (!$scope.selected.isSendRequired) {
                $scope.editedDocument.clientEmails = [];
                $scope.editedDocument.emailContent = '';
            } else if ($scope.selected.isSendRequired && $scope.editedDocument.customer.email) {
                $scope.editedDocument.clientEmails = [$scope.editedDocument.customer.email];
            }
        };

        $scope.saveDocument = function () {

            if ($filter('isSubmitNotAllowed')($scope.documentForm))
                return;

            $scope.spinner.enabled = true;


            return Document
                .create($scope.editedDocument)
                .then(function () {

                    if (!$scope.selected.isSendRequired) {
                        return Promise.resolve();
                    }

                    return MobileModal
                        .alert(
                            $filter('translate')('documents.email_send_successful', {
                                emailList: $scope.editedDocument.clientEmails.join(', ')
                            }),
                            {title: $filter('translate')('documents.email_send_successful_title')}
                        )
                })
                .then(function () {
                    $scope.afterSave();
                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })

        };

        (function () {


            if (!angular.isDefined($scope.editedDocument.clientEmails)) {
                $scope.editedDocument.clientEmails = [];
            }

            if (!$scope.editedDocument.clientEmails || !$scope.editedDocument.clientEmails.length) {
                $scope.selected.isSendRequired = false;
            } else {
                $scope.selected.isSendRequired = true;
            }

        })();

    });