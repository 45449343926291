/**
 * Created by dbichenko on 27.10.2016.
 */
angular.module('sinvoice-common').factory('Events', function ($rootScope, $timeout) {

    return {
        triggerEvent: function (event, args) {
            $rootScope.$broadcast.apply($rootScope, arguments)
        },

        triggerEventAfterDigest: function (event, args) {
            var scopeArguments = arguments;
            $timeout(function () {
                $rootScope.$broadcast.apply($rootScope, scopeArguments)
            })
        },

        subscribeToEvent: function (events, scope, callback) {

            if (!scope)
                scope = $rootScope;

            if (!angular.isArray(events))
                events = [events];

            events.forEach(function (event) {
                var handler = $rootScope.$on(event, callback);
                scope.$on('$destroy', function () {
                    handler();
                })
            });


        }
    }

});