/**
 * Created by dbichenko on 05.07.2016.
 */
angular.module('sinvoice-desktop')
    .run(function ($rootScope,
                   $state,
                   $window,
                   $location,
                   $localStorage,
                   DataTableDefaultsWrapper,
                   $compile,
                   DTRendererService,
                   DataTableFunctions,
                   DataTableSortOptionsWrapper,
                   dpickerSettings,
                   $filter,
                   dateHelper,
                   $timeout,
                   dpickerRenderer) {


        function applyColumnReorderingFix(api, settings) {


            if (!settings.oInit.colReorder)
                return;

            var table    = api.table();
            var instance = settings.oInstance;
            var original = settings.oInit.columnFilterOptions;

            var rerenderFiltersDebounced = _.debounce(function (e, settings, reorderData) {

                var reordered  = DataTableFunctions.reorderFilterColumns(
                    original.aoColumns,
                    settings._colReorder.fnGetCurrentOrder()
                );
                var copy       = angular.copy(original);
                copy.aoColumns = reordered;
                instance.columnFilter(copy);

            }, 750);


            table.on('column-reorder', rerenderFiltersDebounced);

            // To trigger column-reorder event
            api.colReorder.order(api.colReorder.order());

        }

        function applyButtonContainerHandler(api, settings) {
            if (settings.oInit.buttonContainer) {
                document.querySelector(settings.oInit.buttonContainer).innerHTML = "";
                var oldContainer                                                 = angular.element(api.buttons().container());
                oldContainer.contents().appendTo(settings.oInit.buttonContainer);
                oldContainer.hide();
            }
        }

        function applyColumnDefaultValueHandler(api, settings) {
            if (settings.oInit.columnFilterOptions) {
                if (settings.oInit.columnFilterOptions.aoColumns) {
                    settings.oInit.columnFilterOptions.aoColumns.forEach(function (value, index) {
                        if (value && value.defaultValue) {
                            api.column(index).search(value.defaultValue).draw()
                        }
                    })
                }
            }
        }

        function applyOnSelectHandler(api, settings) {

            function getCallback(button) {

                var hasOwnEnabledCallback = button.enabledCallback && angular.isFunction(button.enabledCallback);

                if (hasOwnEnabledCallback)
                    return button.enabledCallback;
                else if (button.extend && $.fn.dataTable.ext.buttons[button.extend].enabledCallback)
                    return $.fn.dataTable.ext.buttons[button.extend].enabledCallback;
                else
                    return null;
            }

            function haveEnabledCallback(button) {
                return !!getCallback(button);
            }

            function setEnabledCallbacks(buttons) {

                buttons.forEach(function (button, index) {

                    var buttonVisibilityWatcher = scope.$watch(function () {
                        buttonVisibilityCallback(null, api.table(), null, button)
                    });

                    var buttonVisibilityCallback = function (e, dt, node, config) {
                        try {
                            var isEnabled = getCallback(button)(e, dt, node, config);
                            dt.button(index).enable(!!isEnabled);
                        } catch (e) {

                        }
                    };

                    if (button.extend === 'collection') {

                        if (haveEnabledCallback(button)) {
                            scope.$on('$destroy', buttonVisibilityWatcher);
                        }

                        button.buttons.forEach(function (collectionChildButtonConfig, cbIndex) {

                            if (!haveEnabledCallback(collectionChildButtonConfig)) {
                                return;
                            }

                            var collectionButtonVisibilityWatcher = scope.$watch(function () {
                                collectionButtonVisibilityCallback(null, api.table(), null, button)
                            });

                            var collectionButtonVisibilityCallback = function (e, dt, node, config) {
                                var isEnabled = getCallback(collectionChildButtonConfig)(e, dt, node, config);

                                try {
                                    var collectionButton = dt.button(index);
                                    if (!collectionButton.node) {
                                        return;
                                    }

                                    // Geez. What is going on there?
                                    var collectionChildButton = collectionButton[0].inst.s.buttons[index].buttons[cbIndex];
                                    if (!collectionChildButton) {
                                        return;
                                    }

                                    if (!collectionChildButton.node) {
                                        return;
                                    }

                                    dt.button(collectionChildButton.node).enable(!!isEnabled);
                                } catch (e) {

                                }

                            };

                            scope.$on('$destroy', collectionButtonVisibilityWatcher);
                        })

                    } else if (haveEnabledCallback(button)) {


    /*                    var buttonVisibilityWatcher = scope.$watch(function () {
                            buttonVisibilityCallback(null, api.table(), null, button)
                        });

                        var buttonVisibilityCallback = function (e, dt, node, config) {
                            try {
                                var isEnabled = getCallback(button)(e, dt, node, config);
                                dt.button(index).enable(!!isEnabled);
                            } catch (e) {

                            }
                        };*/

                        scope.$on('$destroy', buttonVisibilityWatcher);
                    }


                })
            }

            var table = api.table();
            var scope = angular.element(table.container()).scope();

            if (settings.oInit.fnSelect) {
                if (angular.isFunction(settings.oInit.fnSelect)) {
                    table
                        .on('select', settings.oInit.fnSelect)
                        .on('deselect', settings.oInit.fnSelect)

                }
            }


            if (settings.oInit.buttons) {
                setEnabledCallbacks(settings.oInit.buttons);
            }
        }

        function applyFromToLabels(api, settings) {

            DTRendererService.registerPlugin({
                postRender: function (options, result) {
                    var oTable = result.dataTable;

                    var containers = oTable.find('.filter_number_range, .filter_date_range');

                    containers.each(function (i, container) {
                        var inputs = angular.element(container).find('input');

                        var fromInput = angular.element(inputs[0]);
                        var toInput   = angular.element(inputs[1]);

                        fromInput.attr('placeholder', $filter('translate')('documents.table.from'));
                        toInput.attr('placeholder', $filter('translate')('documents.table.to'));
                    });


                }
            })
        }

        function setDataTableDefaults() {


            DataTableDefaultsWrapper.setOption('fnHeaderCallback', function (header) {
                DataTableFunctions.recompileHeader(header);
            });


            DataTableDefaultsWrapper.setOption('initComplete', function (settings) {

                var api   = this.api();
                var table = api.table();

                applyColumnDefaultValueHandler(api, settings);
                applyColumnReorderingFix(api, settings);
                applyOnSelectHandler(api, settings);
                applyButtonContainerHandler(api, settings);
                applyFromToLabels(api, settings);

                table
                    .on('column-sizing.dt', function (e, settings, column, state) {
                        if (settings.nTBody.children.length == 1 && settings.nTBody.children[0].children.length == 1) {
                            var bodyrowCols = settings.nTBody.children[0].children;
                            for (var i = 0; i < bodyrowCols.length; i++) {
                                bodyrowCols[i].setAttribute("colspan", settings.nTHead.children[0].children.length);
                            }
                        }

                    });

            });

            DataTableDefaultsWrapper.setButtonDefaults({
                buttons : ['copy', 'excel', 'csv', 'pdf', 'print'],
                name    : 'main',
                tabIndex: 0,
                dom     : {
                    container  : {
                        tag      : 'div',
                        className: 'dt-buttons'
                    },
                    collection : {
                        tag      : 'div',
                        className: 'dt-button-collection'
                    },
                    button     : {
                        tag      : 'button',
                        className: 'btn btn-info btn-sm mr10',
                        type     : 'button',
                        active   : 'active',
                        disabled : 'disabled'
                    },
                    buttonLiner: {
                        tag      : 'span',
                        className: ''
                    }
                }
            });
        }

        function setDateSortingFunctions() {
            DataTableSortOptionsWrapper
                .setPre('date-formatted', function (a) {
                    return momentWrapped(a, dpickerSettings.dateFormat);
                });

        }

        function setDatepicker() {
            DTRendererService.registerPlugin({
                postRender: function (options, result) {

                    var oTable = result.dataTable;
                    var inputs = oTable.find('.filter_date_range input');
                    var i;


                    inputs.datepicker('destroy');
                    inputs.each(function (k, element) {

                        i = $(element).attr('rel');

                        // 'dp.change' is not working at this point.
                        var dtp = dpickerRenderer.render(element);

                        dtp.on('dp.show', function (e) {
                            $(this).one('dp.hide', function () {
                                oTable.fnDraw();
                            });
                        });
                    });

                    function filterFunc(oSettings, aData, iDataIndex) {

                        if (oTable.attr("id") != oSettings.sTableId)
                            return true;

                        var sToId   = oTable.attr("id") + '_range_to_' + i;
                        var sFromId = oTable.attr("id") + '_range_from_' + i;

                        var endDatepickerData   = $('#' + sToId).data('DateTimePicker');
                        var startDatepickerData = $('#' + sFromId).data('DateTimePicker');

                        // Not initialized yet.
                        if (!endDatepickerData || !startDatepickerData)
                            return true;

                        var dEndDate   = endDatepickerData.date();
                        var dStartDate = startDatepickerData.date();


                        if (dStartDate == null && dEndDate == null) {
                            return true;
                        }

                        // We need to compare only dates;
                        if (dEndDate)
                            dEndDate = dEndDate.startOf('day');
                        if (dStartDate)
                            dStartDate = dStartDate.startOf('day');

                        var dCellDate = null;
                        try {
                            if (aData[i] == null || aData[i] == "")
                                return false;
                            dCellDate = momentWrapped(aData[i], startDatepickerData.options().format).startOf('day');
                        } catch (ex) {
                            return false;
                        }
                        if (dCellDate == null)
                            return false;


                        if (dStartDate == null && dCellDate <= dEndDate) {
                            return true;
                        }
                        else if (dStartDate <= dCellDate && dEndDate == null) {
                            return true;
                        }
                        else if (dStartDate <= dCellDate && dCellDate <= dEndDate) {
                            return true;
                        }
                        return false;

                    }

                    oTable.dataTableExt.afnFiltering.push(filterFunc);
                }
            });
        }


        setDatepicker();

        setDateSortingFunctions();

        setDataTableDefaults();

        $rootScope.$on('$stateChangeSuccess', function (e, toState, toParams, fromState, fromParams) {

            $rootScope.pageTitle = toState.data.title;

            var currNav = $localStorage.getItem('currNav');
            currNav     = (currNav.currState ? currNav : {currState: 'control.panel', subpage: 'control'});

            $localStorage.setItem('currNav', {
                currState : toState.name,
                subpage   : (toState.data.subpage ? toState.data.subpage : ''),
                subsubmenu: (toState.data.submenu ? toState.data.submenu : '')
            });

        });
    })

    .directive('datatableWithTfootAutoresize', function ($parse) {
        return {
            replace   : true,
            transclude: true,
            template  : '<table datatable ng-transclude=""></table>',
            link      : function (scope, element, attrs) {

                var columnConfig = $parse(attrs['dtColumns'])(scope);
                var tfoot        = element.find('tfoot > tr');

                if (tfoot.length) {
                    var currentColumnCount = tfoot.children().length;
                    var missingColumnCount = columnConfig.length - currentColumnCount;

                    _.range(0, missingColumnCount).forEach(function () {
                        tfoot.append(angular.element('<th>'));
                    })
                }

/*
                setTimeout(function(){
                    $('tfoot th.select-checkbox').append($('<span class="search-table"></span>'));
                },800);
*/




            }
        }
    })

    .directive('dtCollectionButton', function () {
        return {
            replace   : true,
            template  : '' +
            '<span>' +
            '   <button type="button" class="dt-collection-button">{{ buttonTitle }}</button>' +
            '   <span style="display: none;" class="dt-collection-button-container" ng-transclude></span>' +
            '</span>',
            transclude: true,
            controller: function ($scope) {

                this.setButtonTitle = function (buttonTitle) {
                    $scope.buttonTitle = buttonTitle
                }
            },

            link: function (scope, element, attrs) {

                var button = element.find('.dt-collection-button');

                button.addClass(element.attr('class'));
                element.removeClass();


                element.addClass('dt-collection-button-wrapper');
                button.on('click', function () {

                    var config          = {};
                    var host            = button;
                    var hostOffset      = host.offset();
                    var hiddenContainer = element.find('.dt-collection-button-container')


                    if ($('div.dt-button-background').length) {
                        $('body').trigger('click.dtb-collection');
                    }

                    config = {
                        top : hostOffset.top + host.outerHeight(),
                        left: hostOffset.left
                    };


                    var buttonsContainer = $('<div>')
                        .addClass('dt-button-collection')
                        .css(config)
                        .css('display', 'none')
                        .appendTo('body')
                        .fadeIn(400);

                    hiddenContainer
                        .contents()
                        .appendTo(buttonsContainer);


                    showBackground(true);

                    setTimeout(function () {
                        // This is bonkers, but if we don't have a click listener on the
                        // background element, iOS Safari will ignore the body click
                        // listener below. An empty function here is all that is
                        // required to make it work...
                        $('div.dt-button-background').on('click.dtb-collection', function () {
                        });

                        $('body').on('click.dtb-collection', function (e) {
                            // andSelf is deprecated in jQ1.8, but we want 1.7 compat
                            var back = $.fn.addBack ? 'addBack' : 'andSelf';

                            if (!$(e.target).parents()[back]().filter(config._collection).length) {

                                $('div.dt-button-background').off('click.dtb-collection');

                                buttonsContainer.fadeOut(400, function () {

                                    buttonsContainer
                                        .contents()
                                        .appendTo(hiddenContainer);

                                    buttonsContainer.remove();

                                });

                                showBackground(false);

                                $('body').off('click.dtb-collection');
                            }
                        });
                    }, 10)
                })
            }
        }

        function showBackground(show) {

            if (show) {
                $('<div/>')
                    .addClass('dt-button-background')
                    .css('display', 'none')
                    .appendTo('body')
                    .fadeIn(400);
                ;
            }
            else {
                $('body > div.dt-button-background')
                    .fadeOut(400, function () {
                        $(this)
                            .removeClass('dt-button-background')
                            .remove();
                    });
            }
        }
    })
    .directive('dtCollectionButtonHeader', function ($interpolate) {
        return {
            link   : function (scope, element, attrs, buttonController) {
                var compiledHtml = $interpolate(element.html())(scope);

                buttonController.setButtonTitle(compiledHtml);

                element.remove();
            },
            require: '^^dtCollectionButton'

        }

    })
    .factory('DataTableSortOptionsWrapper', function () {

        return {
            setPre : function (name, callback) {
                var keyName                           = name + '-pre';
                jQuery.fn.dataTableExt.oSort[keyName] = callback;

            },
            setAsc : function (name, callback) {
                var keyName                           = name + '-asc';
                jQuery.fn.dataTableExt.oSort[keyName] = callback;
            },
            setDesc: function (name, callback) {
                var keyName                           = name + '-desc';
                jQuery.fn.dataTableExt.oSort[keyName] = callback;
            }

        }
    })
    .factory('DataTableDefaultsWrapper', function () {
        return {
            setOption          : function (attribute, value) {

                var option = {};
                option.colReorder = true;

                option.autoWidth = true;
                option.colResize = {
                    minColumnWidth: 75,
                    autoWidth: true,
                    resizeTable: true
                };

                option.column = {
                    render: $.fn.dataTable.render.text()
                };

                option.scrollX = false;

                option[attribute] = value;

                $.extend(true, $.fn.dataTable.defaults, option);
                return this;
            },
            setButtonDefaults  : function (defaults) {
                $.extend(true, $.fn.dataTable.Buttons.defaults, defaults);

                return this;
            },
            setExtensionOptions: function (extensionKey, innerKey, value) {
                $.fn.dataTable.ext[extensionKey][innerKey] = value;
            }
        }
    })
    .service('DataTablePromiseWrapper', ['$filter', '$log', function ($filter, $log) {

        this.wrap = wrap;

        function wrap(func) {

            if (!angular.isFunction(func)) {
                $log.warn('Please provide correct callback that returns promise');
            }

            return {

                filteredBy: function (filter, strict) {
                    return function () {

                        var promise = null;

                        if (!promise) {
                            promise = func();
                        }

                        if (!filter) {
                            return promise;
                        }

                        return promise.then(function (value) {
                            return $filter('filter')(value, filter, strict);
                        })
                    }
                }
            };
        }

        return this;

    }])
    .service('DataTableFilterValuesConverter', ['$translate', function ($translate) {

        this.convert = convert;


        function convert(values, labelKey, valueKey) {
            var j               = 0;
            var iLen            = values.length;
            var convertedValues = [];
            for (j = 0; j < iLen; j++) {

                var label = $translate.instant(values[j][labelKey]);
                convertedValues.push(label);

            }

            return convertedValues;
        }

        return this;

    }])

    .factory('DataTableStorage', function (API, Url, $q) {


        return {

            retrieve: function (tablename) {
                var defer = $q.defer();
                API.get(Url.customers.saveStateTable(tablename))
                    .success(function (rsp) {
                        defer.resolve(rsp);
                    });

                return defer.promise;


            },
            store   : function (tablename, data) {

                var newData = angular.copy(data);

                $.each(newData.columns, function (i, column) {
                    column.search.search = '';
                });

                var defer = $q.defer();

                API.post(Url.customers.saveStateTable(tablename), {data: newData})
                    .success(function (response) {
                        defer.resolve(response);
                    });

                return defer.promise;
            }
        }

    })

    .factory('DataTableFunctions', function ($compile, DataTableStorage) {

        var recompileHeader = function (header) {
            header    = angular.element(header);
            var scope = header.scope();

            if (scope)
                $compile(header.contents())(scope);
        };

        function reorderFilterColumns(filterColumns, newOrder) {

            if (!newOrder || !filterColumns) {
                return;
            }

            if (!newOrder.length || !filterColumns.length) {
                return;
            }

            var reorderedColumns = [];

            filterColumns.forEach(function (val, i) {

                var newPos = newOrder.indexOf(i);

                reorderedColumns[newPos] = val;
            });


            return reorderedColumns;


        }

        function getData(DataTable) {

            var data = [];

            DataTable.rows().data().each(function (val) {
                data.push(val);
            });

            return data;

        }

        function getVisibleColumnNames(DataTable) {
            var data = [];

            DataTable.columns().visible().each(function (val, index) {
                if (val) {
                    var newVar = DataTable.settings()[0]['aoColumns'][index]['mData'];
                    data.push(newVar);
                }
            });


            return data;
        }

        function getSelectedData(DataTable) {
            var data = [];

            DataTable.rows({selected: true}).data().each(function (val) {
                data.push(val);
            });

            return data;
        }

        return {
            reorderFilterColumns : reorderFilterColumns,
            getSelectedData      : getSelectedData,
            getData              : getData,
            getVisibleColumnNames: getVisibleColumnNames,
            recompileHeader      : recompileHeader
        }
    })
    .directive('dtSelectAllCheckbox', function ($timeout) {

        return {
            scope   : {},
            replace : true,
            template: function () {
                return '<span><input id="selectAll_{{ $id }}" type="checkbox" class="select-checkbox"><label for="selectAll_{{ $id }}"></label></span>'
            },
            link    : function (scope, elem, attrs) {

                var parentTable = elem.parents('table');

                if (!angular.isDefined(parentTable.DataTable)) {
                    throw Error('dtSelectAllCheckbox directive should be defined inside of DataTable');
                }


                elem.on('change', 'input', function (e) {

                    $timeout(function () {
                        var rows = parentTable.DataTable().rows({filter: "applied"});
                        if (e.target.checked === false) {
                            rows.deselect();
                        } else {
                            rows.select();
                        }
                    });
                });


            }
        }
    })
    .directive('dtSelectCheckbox', function () {

        return {
            template: '<div class="check-area">',
            scope   : {
                'disabled': '=',
                'onchange': '&'
            },
            replace : true,
            link    : function (scope, element, attrs, ngModelCtrl) {

                let self   = $(element);
                let target = null;

                if (self.parents('td')) {
                    target = self.parents('td');
                } else {
                    target = self;
                }


                target.on('click', function () {
                    if (scope.disabled)
                        return;

                    let that = $(this);
                    let domRow = that.parents('tr');

                    let table = that.parents('table').DataTable();

                    let dtRow = table.row(domRow);


                    if (domRow.hasClass("selected")) {
                        dtRow.deselect();
                    } else {
                        dtRow.select();
                    }

                    scope.$apply(function () {
                        scope.onchange();
                    })

                });

            }
        }

    })
    .directive('dtRemoveColumnButton', function () {

        return {
            replace: true,

            template: function () {
                return '<span class="animate-show x-btn"></span>';
            },

            link: function (scope, element, attrs) {
                var self = $(element);

                self.on('click', function (e) {

                    var table  = $(this).parents('table').DataTable();
                    var column = table.column($(this).parents('th'));

                    column.visible(false);
                    e.preventDefault();
                    e.stopPropagation();
                })
            }
        }
    })
    .factory('DataTableBootstrapSettings', function () {
        return {
            TableTools: {
                classes: {
                    container: 'btn-group',
                    buttons  : {
                        normal: 'btn btn-danger'
                    }
                }
            },
            ColVis    : {
                classes: {
                    masterButton: 'btn btn-primary'
                }
            },
            pagination: {
                classes: {
                    ul: 'pagination p0'
                }
            }
        };
    })
    .factory('DataTableLanguageSettings', function ($translate) {
        return {
            "select"       : {
                "rows": {
                    _: $translate.instant("common.table.selected_many"),
                    0: "",
                    1: $translate.instant("common.table.selected_one")
                }
            },
            "sInfoEmpty"   : $translate.instant('common.table.no_entries'),
            "sEmptyTable"  : $translate.instant('common.table.no_data'),
            "oPaginate"    : {
                "sNext"    : $translate.instant('common.table.next'),
                "sPrevious": $translate.instant('common.table.previous')
            },
            "sInfo"        : $translate.instant('common.table.summary'),
            "sInfoFiltered": $translate.instant('common.table.info_filtered'),
            "sZeroRecords" : $translate.instant('common.table.zero_records'),
        }
    })
    .factory('DataTableDatepickerLabels', function ($translate) {

        return {
            fromToLabels: function (settings) {
                var options = {};
                if (settings) {
                    options = settings;
                }

                options.toPlaceholder   = 'top';
                options.fromPlaceholder = 'nottop';

                return "{from} - {to}"
            }
        }
    });