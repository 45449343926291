angular
    .module('sinvoice-desktop')
    .service('TemplateChecker', [function () {
            var self = this;

            self.isRetrotractiveDocsRelatingAllowed = function ($scope) {
                if(!$scope){
                    return;
                }
                return $scope.template.isRetroactiveRelatingAllowed && !$scope.template.isEditable && $scope.editedDocument.id && $scope.template.isActiveDocument
            }

        }]);