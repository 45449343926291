angular
    .module('sinvoice-desktop')
    .service('DocumentModal',
        function ($translate,
                  Modal,
                  Document,
                  Url,
                  API,
                  UserSubscription,
                  $q,
                  Customer,
                  Item,
                  Currency,
                  $filter,
                  promiseHelper,
                  ModalContainer,
                  Events,
                  ScopeProvider,
                  DocumentModalCallbackContainer,
                  DocumentConstants,
                  RelatedDocumentTableCallbackContainer) {


            this._displayNewDocumentModal = _displayNewDocumentModal;

            this.viewDocument              = viewDocument;
            this.editDocument              = editDocument;
            this.createDocument            = createDocument;
            this.duplicateDocument         = duplicateDocument;
            this.createDocumentForCustomer = createDocumentForCustomer;
            this.closeAll                  = closeAll;

            this.subscribeToUpdate = subscribeToUpdate;
            this.notifyAboutUpdate = notifyAboutUpdate;

            this.isEditable                   = isEditable;
            this.isRetroactiveRelatingAllowed = isRetroactiveRelatingAllowed;
            this.isActive                     = isActive;


            var scope;

            return this;


            function closeAll() {
                ModalContainer.closeGroup('documentModals')
            }

            function viewDocument(id) {
                scope = initializeScope(true);
                displayDocumentModal(id)
            }

            function editDocument(id) {
                scope = initializeScope(false);
                displayDocumentModal(id);
            }

            function initializeScope(readOnly) {
                var scope = ScopeProvider.getNewIsolatedScope();

                scope.documentForm  = {};
                scope.template      = {
                    isModalLockedForEdit: !!readOnly
                };
                scope.documentTypes = null;
                scope.customers     = null;
                scope.items         = null;
                scope.currencies    = null;

                scope.editedDocument = {clientEmails: []};

                scope.spinner = {
                    newDocument : true,
                    editDocument: true
                };
                scope.save    = save;
                scope.close   = close;

                DocumentModalCallbackContainer.reset();
                RelatedDocumentTableCallbackContainer.reset();

                return scope;
            }


            function isRetroactiveRelatingAllowed(document) {
                return document.type.isRetroactiveRelatingAllowed;
            }

            function isActive(document) {
                return document.status == DocumentConstants.STATUS_OPEN;
            }

            function isEditable(document) {

                var isEditable              = document.type.isEditingAllowed;
                var documentIsActive        = isActive(document);
                var documentIsNotYetCreated = !document.id;

                return (isEditable && documentIsActive) || documentIsNotYetCreated;
            }

            function subscribeToUpdate(scope, callback) {
                Events.subscribeToEvent('documents.update', scope, callback);
            }

            function notifyAboutUpdate() {
                Events.triggerEvent('documents.update');
            }


            function displaySelectTypeModal(customer) {

                scope = initializeScope(false);

                if (customer) {
                    scope.editedDocument.customer = customer;
                }

                scope.spinner.newDocument = true;
                loadDocTypes().then(function () {
                    scope.spinner.newDocument = false;
                });

                Modal
                    .customDialog({
                        scope      : scope,
                        title      : $translate.instant('documents.document_type'),
                        templateUrl: './desktop/components/documents/typeDocument.html',
                        size       : "large",
                        onEscape   : close
                    })
                    .then(function (dialog) {
                        ModalContainer.setElement('selectType', dialog, 'documentModals');
                    });
            }

            function createDocumentForCustomer(customer, type) {

                UserSubscription.checkSubscriptionForDocumentCreation().then(function () {

                    if (!customer)
                        throw new Error('Please provide correct customer');

                    if (!type) {
                        displaySelectTypeModal(customer);
                    } else {

                        scope                         = initializeScope();
                        scope.editedDocument.type     = type;
                        scope.editedDocument.customer = customer;

                        _displayNewDocumentModal();
                    }

                });

            }

            function duplicateDocument(id) {

                UserSubscription.checkSubscriptionForDocumentCreation().then(function () {
                    scope = initializeScope();

                    Document.get(id).then(function (data) {
                        var document = data[0];

                        document.id          = null;
                        document.relatedDocs = [];
                        document.paragraphs.forEach(function (paragraph) {
                            paragraph.isImportedFromRelatedDoc = false;
                        });

                        _displayNewDocumentModal(data);

                    })
                });
            }

            function _displayNewDocumentModal(aData) {

                var userDeferred = $q.defer();

                if (aData) {
                    userDeferred.resolve(aData);
                    scope.editedDocument = aData[0];
                } else {
                    userDeferred.resolve();
                }

                loadEditModalData(userDeferred.promise);


                Modal
                    .customDialog({
                        headerUrl   : './desktop/components/documents/partials/header-block.html',
                        templateUrl : './desktop/components/documents/document.html',
                        className   : 'document-modal',
                        size        : "large",
                        scope       : scope,
                        destroyScope: true,
                        onEscape    : close,
                        closeButton : false
                    })
                    .then(function (dialog) {
                        ModalContainer.setElement('newDocument', dialog, 'documentModals');
                    });


            }


            function displayDocumentModal(id) {

                var userDeferred = $q.defer();

                API.get(Url.documents.get(id))
                    .success(function (data) {

                        scope.editedDocument = data[0];
                        userDeferred.resolve();

                    });

                Modal
                    .customDialog({
                        headerUrl   : './desktop/components/documents/partials/header-block.html',
                        templateUrl : './desktop/components/documents/document.html',
                        size        : "large",
                        scope       : scope,
                        destroyScope: true,
                        className   : 'document-modal',
                        onEscape    : close,
                        closeButton : false

                    })
                    .then(function (dialog) {
                        ModalContainer.setElement('editDocument', dialog, 'documentModals');
                    });

                loadEditModalData(userDeferred.promise);


            }


            function createDocument() {
                UserSubscription.checkSubscriptionForDocumentCreation().then(function () {
                    displaySelectTypeModal();
                });
            }

            function close() {
                ModalContainer.closeGroup('documentModals');
            }


            function save() {

                scope.documentForm.$submitted = true;

                DocumentModalCallbackContainer.getValidatePromise(scope.documentForm)
                    .then(DocumentModalCallbackContainer.getPreSavePromise)
                    .then(doSave)
                    .then(function (data) {

                        if (scope.editedDocument.id)
                            return;

                        displayCreationSuccessfulPopup(data.result);
                    });

                return false;
            }


            function doSave() {

                var defer                  = $q.defer();
                scope.spinner.editDocument = true;

                function success(data) {

                    close();
                    if (!data.result.success && data.result.message) {
                        alert($translate.instant('documents.' + data.result.message));
                    } else {
                        notifyAboutUpdate();
                    }
                    defer.resolve(data);
                    scope.spinner.editDocument = false;
                }


                if (scope.editedDocument.id) {
                    API.patch(Url.documents.update(scope.editedDocument.id), scope.editedDocument)
                        .success(success)
                } else {
                    Document
                        .create(scope.editedDocument)
                        .then(success)
                }

                return defer.promise;
            }

            function displayCreationSuccessfulPopup(document) {
                Modal
                    .customDialog({
                        scopeVariables: {
                            editedDocument: document
                        },
                        title         : $translate.instant('documents.print_document.title', {
                            docNumber: document.docNumber,
                            docType  : $filter('translateDocType')(document.type.docType)
                        }),
                        templateUrl   : './desktop/components/documents/popups/print-document-popup.html',
                        className     : 'document-modal',
                        size          : "medium"
                    })
                    .then(function (dialog) {
                        ModalContainer.setElement('createdSuccessfully', dialog, 'documentModals');
                    })
                ;

            };

            function loadDocTypes() {

                return Document.getDocTypes()
                    .then(function (data) {
                        scope.documentTypes = {};
                        angular.forEach(data, function (value, key) {
                            scope.documentTypes[key] = value;
                        });
                    })
            }


            function loadItems() {
                return Item.getData()
                    .then(function (data) {
                        scope.items = data;
                    })
            }

            function filterItems() {

            }

            function loadCustomers() {
                return Customer.getData()
                    .then(function (data) {
                        scope.customers = data;
                    })
            }

            function loadCurrencies() {
                return Currency.getData()
                    .then(function (data) {
                        scope.currencies = data;
                    })
            }


            function setDefaults() {


                if (!scope.editedDocument.currencies) {
                    scope.editedDocument.currencies = scope.currencies[0];
                }

                var displayWithVat = scope.editedDocument.displayWithVat;
                if (displayWithVat === null || typeof(displayWithVat) == 'undefined')
                    scope.editedDocument.displayWithVat = true;

            }

            function loadEditModalData(userPromise) {

                scope.spinner.editDocument = true;
                var promises               = [
                    loadUser(userPromise),
                    loadDocTypes(),
                    loadCurrencies(),
                    loadItems(),
                    loadCustomers()
                ];

                return $q.all(promises).then(function () {

                    setDefaults();
                    scope.spinner.editDocument = false;
                });
            }

            function loadUser(userPromise) {

                if (!userPromise) {
                    var userDeferred = $q.defer();
                    userDeferred.resolve();
                    userPromise = userDeferred.promise.then(function () {
                        scope.editedDocument = {};
                    });
                }

                return userPromise;
            }

        }
    )
;