angular.module('sinvoice-desktop').controller('footerController', function ($scope, GITHUB_URL, BUILD_HASH, GITHUB_TITLE,DISABLE_DEBUG_MODE) {
    //

    $scope.isDebugModeEnabled = function () {
        return !DISABLE_DEBUG_MODE;
    };

    if (GITHUB_TITLE || BUILD_HASH || GITHUB_URL) {
        $scope.devInfo = {
            hash : BUILD_HASH,
            title: GITHUB_TITLE,
            url  : GITHUB_URL
        }
    }


});