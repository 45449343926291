angular.module('sinvoice-desktop').controller('ordersBookReportController',
    function ($scope,
              $translate,
              $filter,
              $state,
              Events,
              Report) {

        $scope.currentState = $state.current.name;

        $scope.spinner = {enabled: true};

        $scope.currentTemplate = '';


        $scope.$emit('onLoadReportPage', {
            state: $scope.currentState,
            page : {
                title      : $filter('translate')('reports.pages.ordersBook'),
                description: '',
                alias      : 'reports.pages.ordersBook'
            }
        });

        $scope.orders = [];

        Events.subscribeToEvent('reports.ordersBookTemplateChanged', $scope, function (e, template) {
            $scope.currentTemplate = template;
        });

        function loadOrders() {
            return Report.getOrderBookData().then(function (data) {
                $scope.orders          = data;
                $scope.spinner.enabled = false;
            })
        }

        (function () {
            loadOrders();
        })();
    });