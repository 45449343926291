/**
 * Created by abichenko on 29.01.18.
 */
angular.module('sinvoice-desktop').controller('reportHelpNotificationPopupController', function ($scope, Auth, Report) {

    $scope.closePopup = function () {
        $scope.onClose();
    };

    $scope.openHelp = function () {
        $scope.template.showHelp = true;
    };

    $scope.toggleDontShowPopupButton = function () {
        var showPopup = !$scope.template.dontShowAgain;

        Report.updateHelpPopupStatus(showPopup).then(function () {
            Auth.changeReportHelpPopupStatus(showPopup);
        });
    };

});