/**
 * Created by dbichenko on 21.07.2016.
 */
angular.module('sinvoice-desktop').controller('taxInvoiceReceiptController',
    function ($scope,
              Modal,
              $translate,
              FormHelper,
              Currency,
              DocumentTypeConstants,
              Events,
              TemplateChecker,
              DocumentModalCallbackChecker,
              DocumentModalCallbackContainer,
              DocumentModalCallbackProvider) {

        // vat block
        $scope.disabledVAT            = {};
        $scope.paragraphForm          = [];
        $scope.error                  = false;
        $scope.template.itemsRequired = true;


        $scope.template.$reliableTypesOfOpenDocuments = [
            DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
            DocumentTypeConstants.TYPE_ORDER,
            DocumentTypeConstants.TYPE_DELIVERY_NOTE,
            DocumentTypeConstants.TYPE_PRICE_QUOTE,
            DocumentTypeConstants.TYPE_PERFORM,
        ];

        $scope.template.$reliableTypesOfAnyStatusDocuments = [
            DocumentTypeConstants.TYPE_TAX_INVOICE_RECEIPT_CREDIT
        ];


        $scope.template.$oneSideRelationDocTypes = [
            DocumentTypeConstants.TYPE_ORDER,
            DocumentTypeConstants.TYPE_DELIVERY_NOTE,
            DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
            DocumentTypeConstants.TYPE_PRICE_QUOTE,
            DocumentTypeConstants.TYPE_PERFORM
        ];


        function initializeCustomValidators() {


            DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                var editedDocument  = $scope.editedDocument;
                var paymentErrors   = $scope.template.payments.errors;
                var paragraphErrors = $scope.template.paragraphs.errors;

                paymentErrors.required   = false;
                paragraphErrors.required = false;

                if (!editedDocument.paragraphs.length) {
                    flow.deny();
                    paragraphErrors.required = true;
                    return false;
                }

                if (!editedDocument.payments.length) {
                    flow.deny();
                    paymentErrors.required = true;
                    return false;
                }

                flow.allow();
            });

            Events.subscribeToEvent('paymentForms.update', $scope, function (e, value) {

                $.each(value, (function (key, forms) {
                    $.each(forms, (function (key, form) {
                        if (!form)
                            return;
                        FormHelper.addValidator(form.amount, 'required', function (value) {
                            return !!value && value > 0;
                        })

                    }))
                }))
            })

        }

        (function () {
            initializeCustomValidators();


            DocumentModalCallbackContainer.addPreSaveCallback(
                DocumentModalCallbackProvider.occasionalCustomerInfoMissing($scope.editedDocument, $scope.template, $scope.documentForm)
            );

            DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                if (!DocumentModalCallbackChecker.isAmountDoesNotMatchPopupNeedToBeShown(
                        $scope.editedDocument,
                        $scope.template.$paymentsTotalWithVatNIS,
                        $scope.template.$finalItemsTotalWithVatNIS
                    ))
                    return flow.allow();

                Modal.alert(
                    $translate.instant('documents.tax_invoice_receipt.not_match_message'),
                    {title: $translate.instant('documents.tax_invoice_receipt.not_match_message_title')}
                );

            })


        })()

    });