angular.module('sinvoice-desktop').controller('documentsController',
    function ($scope,
              DTOptionsBuilder,
              DTColumnBuilder,
              DocumentFiltersValues,
              API,
              Url,
              $translate,
              Document,
              DataTableLanguageSettings,
              DataTableBootstrapSettings,
              DataTableFunctions,
              Modal,
              $compile,
              DocumentModal,
              DocumentExport,
              DISABLE_DEBUG_MODE,
              DirectiveCompiler,
              $stateParams,
              DocumentConstants,
              CustomerTypeChecker,
              $filter,
              DataTableDatepickerLabels) {


        $scope.spinner = {
            documents: true
        };

        $scope.openNewDocumentForm = function () {
            DocumentModal.createDocument($scope.dtInstance);
        };

        function initializeDataTable() {

            var filterValues = {
                types    : [],
                customers: []
            };

            function promiseCallback() {
                return Document.getData().then(function (documents) {
                    loadFilters(documents, filterValues);
                    return documents
                })
            }

            function loadFilters(existingDocuments, filterValues) {

                // We cannot just replace filterValues with empty array because reference will be lost
                // So we should cleanup original one

                $.each(filterValues, function (key, value) {
                    value.splice(0, value.length);
                });

                DocumentFiltersValues
                    .getTypeFilterValues(existingDocuments)
                    .forEach(function (val) {
                        filterValues.types.push(val);
                    });


                var isDefaultNamePresent = false;
                var defaultName          = getDefaultCustomerValue();

                DocumentFiltersValues
                    .getCustomerFilterValues(existingDocuments)
                    .forEach(function (val) {
                        filterValues.customers.push(val);

                        if (defaultName && val.value === defaultName) {
                            isDefaultNamePresent = true;
                        }
                    });

                if (!isDefaultNamePresent && defaultName) {
                    filterValues.customers.push(getDefaultCustomerValue());
                }

            }


            function getDefaultCustomerValue() {
                return $stateParams.customer ? $stateParams.customer : null
            }


            var filterSettings = [

                {type: 'number-range', bRegex: true, bSmart: true, 'sRangeFormat': DataTableDatepickerLabels.fromToLabels()},/*balance*/
                {type: 'number-range', bRegex: true, bSmart: true, 'sRangeFormat': DataTableDatepickerLabels.fromToLabels()},/*amount*/
                {type: 'text', bRegex: true, bSmart: true},/*description*/
                {
                    type        : 'select',
                    bRegex      : false,
                    bSmart      : false,
                    values      : filterValues.customers,
                    defaultValue: getDefaultCustomerValue()
                },/*customer*/
                {type: 'date-range', bRegex: true, bSmart: true, sRangeFormat: DataTableDatepickerLabels.fromToLabels()},/*date*/
                {
                    type  : 'select',
                    bRegex: false,
                    bSmart: false,
                    values: filterValues.types
                },/*type*/
                {type: 'text', bRegex: true, bSmart: true},/*number*/
                {
                    type  : 'select',
                    bRegex: false,
                    bSmart: false,
                    values: DocumentFiltersValues.getStatuses()
                },/*status*/
                null,/*check-box*/
                ];

            var buttons = [
                {
                    extend       : 'subscriptionEditActionButton',
                    text         : '<i class="envelope"></i>' + $translate.instant('customers.email'),
                    extendOptions: {
                        enabledCallback: function (e, dt) {
                            return !!DataTableFunctions.getSelectedData(dt).length;
                        },
                        action         : function (e, dt, node, config) {

                            var documents = DataTableFunctions.getSelectedData(dt);

                            DocumentExport.email(documents);
                        }
                    }
                },

                {
                    extend   : 'collection',
                    autoClose: true,
                    text     : '<i class="cloud-download"></i>' + $translate.instant('customers.export'),
                    buttons  : [


                        // Thats because excel button does not have any options to disable it until some rows got selected
                        {
                            extend   : 'selected',
                            className: 'excel-button-delegate',
                            autoClose: true,
                            text     : $translate.instant('customers.excel'),
                            action   : function (e, dt) {
                                dt.buttons('.excel-button').trigger()
                            }
                        },
                        {
                            extend       : 'excel',
                            className    : 'excel-button hidden',
                            exportOptions: {
                                modifier: {
                                    selected: true
                                },
                                columns : ':visible:not(.ignored-on-export)'
                            },
                            customizeData: function (object) {
                                var statusIndex = 0;
                                var body        = object.body;
                                object.header.reverse();
                                body.forEach(function (row, index) {
                                    body[index][statusIndex] = $filter('translateDocStatus')(row[statusIndex]);
                                    row.reverse();
                                });
                            }
                        },


                        {
                            extend   : 'selected',
                            autoClose: true,
                            text     : $translate.instant('documents.table.pdf'),
                            action   : function (e, dt) {

                                var ids = DataTableFunctions.getSelectedData(dt).map(function (document) {
                                    return document.id;
                                });

                                DocumentExport.downloadAsPdf(ids);
                            }
                        },
                    ]
                },
                {

                    text         : '<i class="clone"></i>' + $translate.instant('customers.duplicate'),
                    extend       : 'subscriptionEditActionButton',
                    extendOptions: {
                        enabledCallback: function (e, dt) {
                            return DataTableFunctions
                                    .getSelectedData(dt)
                                    .filter(function (document) {
                                        return !CustomerTypeChecker.isSuperinvoice(document.customer.customerType);
                                    })
                                    .length === 1;
                        }, action      : function (e, dt, node, config) {

                            DataTableFunctions.getSelectedData(dt).forEach(function (document) {
                                DocumentModal.duplicateDocument(document.id);
                            });
                        }
                    }
                },
                {
                    extend: 'selected',
                    action: function (e, dt, node, config) {

                        var ids = DataTableFunctions.getSelectedData(dt).map(function (document) {
                            return document.id;
                        });

                        DocumentExport.print(ids);

                    },
                    text  : '<i class="print"></i>' + $translate.instant('customers.printing')
                }
            ];

            if (!DISABLE_DEBUG_MODE)
                buttons.push({
                    extend: 'selectedSingle',
                    text  : 'Dev Print',
                    action: function (e, dt, node, config) {

                        DataTableFunctions.getSelectedData(dt).forEach(function (document) {
                            DocumentExport.printEachTemplate(document.id);
                        });
                    }
                });

            buttons.push({
                extend: 'selected',
                action: function (e, dt, node, config) {

                    var ids = DataTableFunctions.getSelectedData(dt).map(function (document) {
                        return document.id;
                    });

                    DocumentExport.showAsPDF(ids);

                },
                text  : '<i class="fa fa-eye"></i>' + $translate.instant('documents.table.view_document')
            });

            var dtOptions = DTOptionsBuilder
                .fromFnPromise(promiseCallback)
                .withBootstrap()
                // .withOption('defaultContent', "")
                .withOption('sDom', "<'row'<'col-xs-12 text-left mb20 language-based-direction'B>>r<'documents-table-wrapper't><'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('stateSave', true)
                .withOption('autoWidth', false)
                //.withOption('order', [4, 'desc'])
                .withOption('colReorder', {
                    fixedColumnsRight: 2,
                    fixedColumnsLeft: 1
                })
                .withColumnFilter({
                    aoColumns: filterSettings
                })
                .withOption('oLanguage', DataTableLanguageSettings)
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withButtons(buttons)
                .withOption('fnCreatedRow', rowCallback);

            return dtOptions;
        }

        function initializeDataColumns() {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('balance')
                    // .withTitle($translate.instant('documents.table.balance'))
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('documents.table.balance') + "'>" + $filter('translate')('documents.table.balance') + "</span>")
                    .withOption('createdCell', function (td, cellData, rowData, row, col) {
                            var cell = $(td);
                            if (!Document.isCreditDoc(rowData.type.docType) && !Document.isBalanceAdjustment(rowData.type.docType))
                                cell.addClass('green-text');
                            else {
                                cell.addClass('red-text')
                            }
                        }
                    )
                    .withOption('render', {

                        "sort"   : function (data, type, fullData) {
                            return fullData.remainingBalanceNIS;
                        },
                        "filter" : function (data, type, fullData) {
                            return fullData.remainingBalanceNIS;
                        },
                        "display": function (data, type, fullData) {

                            var value;
                            if (angular.isNumber(parseFloat(fullData.remainingBalanceNIS) == 0) || fullData.remainingBalanceNIS == null)
                                value = 0;
                            else
                                value = fullData.remainingBalanceNIS;

                            return DirectiveCompiler.compileIntoHtml('money-amount', {currency:'NIS','amount':value});


                        }
                    }),

                DTColumnBuilder
                    .newColumn(null)
                    // .withTitle($translate.instant('documents.table.amount'))
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('documents.table.amount') + "'>" + $filter('translate')('documents.table.amount') + "</span>")
                    .withOption('createdCell', function (td, cellData, rowData, row, col) {
                            var cell = $(td);
                            if (!Document.isCreditDoc(rowData.type.docType) && !Document.isBalanceAdjustment(rowData.type.docType))
                                cell.addClass('green-text');
                            else {
                                cell.addClass('red-text')
                            }
                        }
                    )
                    .withOption('render', {

                        "sort"   : function (data, type, fullData) {
                            return fullData.remainingBalanceNIS;
                        },
                        "filter" : function (data, type, fullData) {
                            return fullData.displayedTotalNIS;
                        },
                        "display": function (data, type, fullData) {
                            return DirectiveCompiler.compileIntoHtml('money-amount', {currency:'NIS','amount':fullData.displayedTotalNIS});
                        }
                    }),

                DTColumnBuilder
                    .newColumn('title')
                    // .withTitle($translate.instant('documents.table.description'))
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('documents.table.description') + "'>" + $filter('translate')('documents.table.description') + "</span>"),

                DTColumnBuilder
                    .newColumn('customer.names')
                    // .withTitle($translate.instant('documents.table.customer'))
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('documents.table.customer') + "'>" + $filter('translate')('documents.table.customer') + "</span>")
                    .renderWith(function (data, type, fullData) {
                        if (CustomerTypeChecker.isOccasional(fullData.customer.customerType)) {
                            return $translate.instant('documents.use_occuring')
                        } else {
                            return fullData.customer.name;
                        }
                    }),

                DTColumnBuilder
                    .newColumn('issueDate')
                    // .withTitle($translate.instant('documents.table.date'))
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('documents.table.date') + "'>" + $filter('translate')('documents.table.date') + "</span>")
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data, fullData) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),

                DTColumnBuilder
                    .newColumn('type')
                    // .withTitle($translate.instant('documents.table.type'))
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('documents.table.type') + "'>" + $filter('translate')('documents.table.type') + "</span>")
                    .renderWith(function (data) {
                        return $translate.instant('documents.types.' + data.docType)
                    }),

                DTColumnBuilder
                    .newColumn('docNumber')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('documents.table.number') + "'>" + $filter('translate')('documents.table.number') + "</span>")
                    .withClass('numb-column')
                    .withOption('width', '12%')
                    .withOption('render', {

                        "_"      : "plain",
                        "filter" : function (data) {
                            return data;
                        },
                        "sort"   : function (data) {
                            return data;
                        },
                        "display": function (data, type, row) {
                            var value = '' +
                                '<span class="num-link">' + data + '</span>' +
                                '<span class="button-wrapper">';

                            if (CustomerTypeChecker.isSuperinvoice(row.customer.customerType)) {
                                value += '<button class="print_row"></button>';
                            } else {
                                value += '<button class="duplicate" subscription-checked-button></button>';
                                value += '<button class="print_row"></button>';
                            }
                            value += '</span>';

                            return value

                        }
                    }),

                DTColumnBuilder
                    .newColumn('status')
                    .withClass('status-box')
                    .withOption('width', '6%')
                    .withTitle("<span class='my_elipsis' title='" + $filter('translate')('customers.table.status') + "'>" + $filter('translate')('customers.table.status') + "</span>")
                    .renderWith(function (data) {
                        return '<span class="hide-content">' + data + '</span><i class="status-state"></i>'
                    }),

                DTColumnBuilder
                    .newColumn('select')
                    .withTitle('' +
                        '<span style="display: none;">' + $filter('translate')('customers.table.select') + '</span>' +
                        '<dt-select-all-checkbox></dt-select-all-checkbox>'
                    )
                    .notSortable()
                    .withOption('width', '52px')
                    .withClass('select-checkbox ignored-on-export')
                    .renderWith(function (data) {
                        return '<dt-select-checkbox class="check-area"></dt-select-checkbox>';
                    }),

            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;
        }

        function rowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {

            $compile(nRow)($scope);

            if (aData.status != DocumentConstants.STATUS_OPEN) {
                $(nRow).addClass('disabled');
            }

            $(nRow).on('click', '.print_row', function (e) {
                DocumentExport.print(aData.id);
                e.stopPropagation();
            });

            $(nRow).on('click', '.duplicate', function (e) {
                DocumentModal.duplicateDocument(aData.id);
                e.stopPropagation();
            });


            // TODO rework all other ones to similiar style to avoid not working links
            $(nRow).on('click', '.num-link', function (e) {
                DocumentModal.editDocument(aData.id);
                e.stopPropagation();

            });


            return nRow;
        }


        function loadTable() {
            $scope.dtColumns         = initializeDataColumns();

            $scope.dtOptions         = initializeDataTable();
            $scope.dtInstance        = {};
            $scope.spinner.documents = false;

            DocumentModal.subscribeToUpdate($scope, function () {
                $scope.dtInstance.reloadData(null, true);
            });
        }

        (function () {
            loadTable();

        })();
    });
