/**
 * Created by abichenko on 27.11.17.
 */
angular.module('sinvoice-mobile').controller('returnFormController',
    function ($scope, DocumentModalCallbackContainer, MobileModal, $filter) {

        (function () {
            if (!angular.isDefined($scope.editedDocument.returnDate))
                $scope.editedDocument.returnDate = momentWrapped().startOf('day')._d;

            DocumentModalCallbackContainer.addPreSaveCallback(function (flow) {

                var customer = $scope.editedDocument.customer;
                if (customer && customer.businessnumber) {
                    return flow.allow();
                }

                MobileModal.bluebarModal(
                    './mobile/components/new-document/partials/popups/customer-info-missing-popup.html', {
                        title         : $filter('translate')('documents.missing_info_popup.title'),
                        scopeVariables: {
                            docType : $scope.editedDocument.type,
                            customer: angular.copy(customer),
                            onSave  : function (customer) {
                                $scope.editedDocument.customer = customer;
                                flow.allow();
                            },
                            onCancel: function () {
                                flow.deny();
                            }
                        }
                    });
            })

        })();
    });