/**
 * Created by abichenko on 27.09.17.
 */
angular.module('sinvoice-desktop').controller('helpMenuController', function ($scope, $state, HelpMenuService) {

    $scope.getCurrentStateName = function () {
        return $state.current.name;
    };

    $scope.getCurrentCategoryId = function () {
        var currentCategory = HelpMenuService.getCurrentCategory();
        return currentCategory ? currentCategory.id : null;
    }
});