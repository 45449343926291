angular.module('sinvoice-common')
    .factory('Item', function ($http, $q, API, Url) {
        return {
            getData   : function () {
                var deferred = $q.defer();
                API.get(Url.items.all(), {})
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });
                return deferred.promise;
            },
            deleteItem: function (id) {

                var deferred = $q.defer();

                API.delete(Url.items.update(id))
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;

            },
            get: function (id) {

                var deferred = $q.defer();

                API.get(Url.items.update(id))
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;

            },
            updateItem: function (id, data) {

                var deferred = $q.defer();

                API.patch(Url.items.update(id), data)
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;

            },
            createItem: function (data) {

                var deferred = $q.defer();

                API.post(Url.items.create(), data)
                    .success(function (data) {
                        deferred.resolve(data);
                    })
                    .error(function (error) {
                        deferred.reject(error);
                    });

                return deferred.promise;
            }
        };
    });