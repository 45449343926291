/**
 * Created by dbichenko on 09.08.2016.
 */
angular.module('sinvoice-desktop').controller('paragraphTableElementController',
    function ($scope, $rootScope, Document, Currency, ParagraphCheck, $translate, DocumentTaxCalculator, Events, ParagraphTotalCalculator, DocumentInitializer) {

        $scope.recalculateOnChangeQuantity       = recalculateOnChangeQuantity;
        $scope.recalculateOnChangeDisplayWithVat = recalculateOnChangeDisplayWithVat;
        $scope.recalculateOnChangeTotal          = recalculateOnChangeTotal;
        $scope.recalculateOnChangeTotalVat       = recalculateOnChangeTotalVAT;
        $scope.recalculateOnChangeUnitPrice      = recalculateOnChangeUnitPrice;
        $scope.recalculateOnChangeUnitPriceVAT   = recalculateOnChangeUnitPriceVAT;

        $scope.isLanguageEnglish = function () {
            return $scope.editedDocument.language == 'en'
        };


        function initialize(element) {

            var currencyOfParent;
            if (ParagraphCheck.isImportedFromRelatedDocument(element) && element.document) {
                currencyOfParent = element.document.currencies;

            } else if (ParagraphCheck.isNew(element) && ParagraphCheck.isCreatedFromItem(element)) {
                element.item.name = element.details;

                if (element.item.sku)
                    element.sku = element.item.sku;

                if (element.item.price && !element.unitPrice) {
                    element.unitPrice = parseFloat(element.item.price) || 0;
                }
                currencyOfParent = element.item.currency;
            }

            if (currencyOfParent) {
                convertParagraphCurrency(
                    currencyOfParent,
                    $scope.editedDocument.currencies
                );
            }

            recalculateOnChangeUnitPrice();
        }


        function convertParagraphCurrency(oldCurrency, newCurrency) {
            $scope.paragraph.unitPrice = Currency.convert(
                $scope.paragraph.unitPrice,
                oldCurrency,
                newCurrency,
                $scope.editedDocument.nisCourse
            );
        }


        function recalculateOnChangeDisplayWithVat() {
            recalculateOnChangeUnitPrice();
        }

        function recalculateOnChangeQuantity() {
            recalculateOnChangeUnitPrice();
        }

        function recalculateOnChangeUnitPriceVAT() {

            var element = $scope.paragraph;

            var withVat    = 0.0;
            var withoutVat = 0.0;

            var unitPriceVAT = parseFloat(element.unitPriceVAT || 0);
            var quantity     = parseFloat(element.quantity || 0);
            if (element.displayWithVat) {
                withVat    = unitPriceVAT;
                withoutVat = DocumentTaxCalculator.calculateAmountWithoutTax(unitPriceVAT)
            } else {
                withVat    = unitPriceVAT;
                withoutVat = unitPriceVAT;
            }
            element.unitPrice = parseFloat(withoutVat);
            element.total     = parseFloat(withoutVat * quantity);
            element.totalVat  = parseFloat(withVat * quantity);

            $scope.paragraph = element;
        }

        function recalculateOnChangeUnitPrice() {
            ParagraphTotalCalculator.calculateTotals($scope.paragraph);
        }

        function recalculateOnChangeTotal() {
            ParagraphTotalCalculator.calculateUnitPriceFromTotal($scope.paragraph)
        }

        function recalculateOnChangeTotalVAT() {
            ParagraphTotalCalculator.calculateUnitPriceFromTotalWithVat($scope.paragraph);
        }

        DocumentInitializer.addInitCallback(function () {

            Events.subscribeToEvent('currencies.change', $scope, function (event, newCurrency, oldCurrency) {
                if (newCurrency == oldCurrency || !newCurrency)
                    return false;

                convertParagraphCurrency(oldCurrency, newCurrency);
                recalculateOnChangeUnitPrice();
            });

            Events.subscribeToEvent('paragraph.VATSelectDisabled', $scope, function () {
                recalculateOnChangeDisplayWithVat();
            });

            Events.subscribeToEvent('paragraph.VATSelectDisabled', $scope, function (event, data) {
                $scope.disabledVATSelect = data;
            });

            $scope.vatOptions = [
                {label: $translate.instant('common.include'), value: true},
                {label: $translate.instant('common.exclude'), value: false}
            ];

            if (!$scope.paragraph) {
                throw new Error('$scope.paragraph is not specified. Please check your variable name.')
            }

            if ($scope.editedDocument.selectedVATParameters != 'mixed' && !angular.isDefined($scope.disabledVATSelect))
                $scope.disabledVATSelect = true;

            if (ParagraphCheck.isVATNotAllowed()) {
                $scope.disabledVATSelect        = true;
                $scope.paragraph.displayWithVat = false;
            }

            initialize($scope.paragraph);
        });


    });