angular.module('sinvoice-desktop').controller('secondStepController', function ($scope, API, Url, $q, Profile, City, BankProvider, AuthDataStorage) {
    $scope.user            = {
        addresses: [],
        contacts : [],
        accounts : []
    };
    $scope.cities          = [];
    $scope.banks           = [];
    $scope.he_checkbox     = true;
    $scope.en_checkbox     = false;
    $scope.responseMessage = '';


    $scope.isEnglishAvailable = function () {
        return $scope.en_checkbox;
    };

    $scope.save = function () {
        if ($scope.profileForm.$invalid) {
            return false;
        } else {

            $scope.spinner.enabled = true;
            $scope.user.en_enabled = $scope.en_checkbox;
            $scope.user.he_enabled = $scope.he_checkbox;

            API.post(Url.register.secondStep(), $scope.user)
                .success(function (data) {
                    if (data.success) {
                        $scope.registerData.user  = angular.copy(data.profile);
                        $scope.currentState.value = "thirdStep";
                    } else {
                        $scope.responseMessage = data.errors;
                    }
                })
                .error(function (error) {

                });
        }
    };

    $scope.addAddress = function () {
        $scope.user.addresses.push({});
    };

    $scope.removeAddress = function (index) {
        $scope.user.addresses.splice(index, 1);
    };



    $scope.addContact = function () {
        $scope.user.contacts.push({});
    };

    $scope.removeContact = function (index) {
        $scope.user.contacts.splice(index, 1);
    };

    $scope.addAccount = function () {
        $scope.user.accounts.push({});
    };

    $scope.removeAccount = function (index) {
        $scope.user.accounts.splice(index, 1);
    };

    (function () {

        $scope.addAddress();

        $scope.spinner.enabled = true;
        loadData().then(function () {
            $scope.user.email      = $scope.registerData.user.email
                ? $scope.registerData.user.email
                : AuthDataStorage.getProfile().email;
            $scope.spinner.enabled = false;
        });

    })();


    function loadData() {

        return $q.all([
            City.getAll().then(function (data) {
                $scope.cities = data;
            }),
            BankProvider.getAll(true).then(function (data) {
                $scope.banks = data;
            }),
            Profile.getBusinessTypes().then(function (data) {
                $scope.businessTypes = data;
            })
        ])


    }


});