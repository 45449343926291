/**
 * Created by dbichenko on 26.12.2016.
 */
angular.module('sinvoice-common').factory('CustomerTypeChecker', function () {


    return {
        isOccasional: function (customerType) {
            return customerType.type === 'occasional';
        },
        isSuperinvoice: function (customerType) {
            return customerType.type === 'superinvoice';

        }
    };

});