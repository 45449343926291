/**
 * Created by abichenko on 23.11.17.
 */
angular
    .module('sinvoice-mobile')
    .controller('paymentsBlockController',
        function ($scope, Bank, BankChecker, Currency, DocumentPaymentAmountCalculator, Events, PaymentBlockModal) {

            $scope.openedSections = {
                cash: true
            };

            $scope.editPayment = function (payment) {

                var editedPayment = payment;

                PaymentBlockModal.editPayment(
                    angular.copy(editedPayment),
                    function (payment) {
                        var index             = $scope.payments.indexOf(editedPayment);
                        $scope.payments[index] = payment;
                    },
                    {
                        isEnglishLanguageEnabled: $scope.isEnglishLanguageEnabled,
                        currency                : $scope.currency,
                        isNisPayments           : $scope.isNisPayments,
                        nisCourse               : $scope.nisCourse,
                        taxValue                : $scope.taxValue,
                        templateVariable        : $scope.templateVariable,
                        customer                : $scope.customer
                    }
                )
            };

            $scope.addPayment = function () {
                PaymentBlockModal.selectNewPaymentType(
                    function (payment) {
                        $scope.payments.push(payment);
                    },
                    $scope.isEnglishLanguageEnabled,
                    $scope.currency,
                    $scope.isNisPayments,
                    $scope.nisCourse,
                    $scope.taxValue,
                    $scope.templateVariable,
                    $scope.customer
                )
            };

            $scope.removePayment = function (payment) {
                $scope.payments = $scope.payments.filter(function (currentElement) {
                    return currentElement !== payment;
                });
            };


            $scope.$watch(function () {
                return JSON.stringify([
                    $scope.payments,
                    $scope.taxValue,
                    $scope.useTaxWithdrawal,
                    $scope.isNisPayments,
                    $scope.currency,
                    $scope.nisCourse
                ]);
            }, function () {
                calculateData();
            });

            $scope.isNISPaymentsCheckboxShown = function () {
                return $scope.currency && $scope.currency.name !== 'NIS';
            };

            $scope.$watch('isNISPaymentsCheckboxShown()', function (newValue, oldValue) {
                if (newValue === oldValue || !!newValue)
                    return;

                $scope.isNisPayments = false;
            });


            function calculateData() {

                var paymentsTotalWithVAT = DocumentPaymentAmountCalculator.calculatePaymentsTotal(
                    $scope.payments
                );

                var totals = DocumentPaymentAmountCalculator.getPaymentTotals(
                    paymentsTotalWithVAT,
                    $scope.taxValue,
                    $scope.useTaxWithdrawal,
                    $scope.isNisPayments,
                    $scope.currency,
                    $scope.nisCourse
                );

                $scope.templateVariable.$paymentsTotal           = totals.paymentsTotal;
                $scope.templateVariable.$paymentsTotalWithVat    = totals.paymentsTotalWithVat;
                $scope.templateVariable.$paymentsTotalWithVatNIS = totals.paymentsTotalWithVatNIS;

                var summary      = DocumentPaymentAmountCalculator.getSummaries($scope.payments);
                $scope.summaries = summary;
            }


            (function () {

                $scope.useTaxWithdrawal = ($scope.taxValue > 0);

                if (!$scope.useTaxWithdrawal) {
                    $scope.taxValue = 0;
                }

                if (!angular.isDefined($scope.payments)) {
                    $scope.payments = [];
                }

            })();
        });
