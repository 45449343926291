/**
 * Created by abichenko on 22.01.18.
 */

angular.module('sinvoice-mobile')
    .controller('createNewCustomerPopupController', function ($scope, Events, Customer, $filter) {

        $scope.createCustomerModalForm = {};

        $scope.getMessage = function () {


            var translateKey;
            if ($scope.triggeredBy === 'taxValue') {
                translateKey = 'documents.create_customer_popup.body_tax_value';
            } else {
                translateKey = 'documents.create_customer_popup.body';
            }

            return $filter('documentPopupTranslate')(translateKey, $scope.docType.docType);
        };

        $scope.save = function () {
            if ($scope.createCustomerModalForm.$invalid) {
                return false;
            }

            Customer.create($scope.customer).then(function (data) {
                $scope.customer = data;
                $scope.$currentModal.close();
                $scope.onSave($scope.customer);
            })

        };

        $scope.cancel = function () {
            $scope.onCancel();
            $scope.$currentModal.close();
        };

        (function () {

            if (!$scope.customer)
                $scope.customer = {};


        })();

        function initializeValidators() {

            $scope.validators = {}
        }

        initializeValidators();

    });