/**
 * Created by dbichenko on 26.07.2016.
 */
angular.module('sinvoice-desktop').controller('relatedDocumentTableController',
    function ($scope,
              DTColumnBuilder,
              DTOptionsBuilder,
              DataTableLanguageSettings,
              $q,
              $translate,
              $filter,
              $compile,
              DataTableDatepickerLabels,
              Currency,
              Customer,
              DataTableBootstrapSettings,
              ParagraphCheck,
              Modal,
              DataTableFunctions,
              DocumentFiltersValues,
              DocumentInitializer,
              RelatedDocumentTableCallbackContainer,
              Events,
              $timeout,
              Document) {

        var originalDocuments = [];
        var originalCustomer  = {};
        var documents         = [];


        function checkOtherConditions() {
            //check for define adjustment type variable
            var areAllConditionsTrue = true;
            if ($scope.template.relatedDocumentsShowConditions && $scope.template.relatedDocumentsShowConditions.length > 0) {
                $scope.template.relatedDocumentsShowConditions.forEach(function (item) {
                    if (!item()) {
                        areAllConditionsTrue = false;
                        return;
                    }
                })
            }
            ;
            return areAllConditionsTrue;
        }

        function updateDocumentsCheckbox() {
            $scope.template.showRelatedDocumentsTable = false;
        }

        $scope.openRelatedDocumentsTable = function () {
            $scope.template.showRelatedDocumentsTable = true;
        };

        $scope.isRetroactiveRelatingCheckboxDisabled = function () {
            return !!originalDocuments.length;
        };

        $scope.isSelectDisabled = function (id) {


            if (!$scope.template.isEditable && !$scope.template.isRetroactiveRelatingAllowed)
                return true;

            if (!$scope.template.isActiveDocument && $scope.editedDocument.id)
                return true;

            var isContains = _.includes(originalDocuments.map(function (document) {
                return document.id;
            }), id);

            if (isContains && $scope.template.isRetroactiveRelatingAllowed)
                return true;
        };

        $scope.toggleRelatedDocuments = function () {
            Events.triggerEvent('relatedDocs.checkedHasRelatedDocuments', $scope.template.showRelatedDocumentsTable);
        };

        $scope.calculateSelectedDocuments = function () {

            var api            = $scope.dt.api;
            var selectedDocs   = DataTableFunctions.getSelectedData(api);
            var prevDocs       = angular.copy($scope.editedDocument.relatedDocs);
            var addedDocuments = _.differenceBy(selectedDocs, prevDocs, 'id');
            var addedDocument  = addedDocuments ? addedDocuments.pop() : null;

            if (addedDocument) {

                var actions = {
                    relate       : function () {
                        $timeout(function () {
                            performRelate()
                        })
                    },
                    copy         : function () {
                        $timeout(function () {
                            performCopy()
                        })
                    },
                    unrelate     : function () {
                        $timeout(function () {
                            performUnrelate()
                        })
                    },
                    preventRelate: function () {
                        $timeout(function () {
                            preventRelate();
                        })
                    }
                };


                RelatedDocumentTableCallbackContainer
                    .getPreSelectPromise(selectedDocs, addedDocument, actions)
                    .then(function () {
                        actions.relate();
                    })
                ;

            } else {
                performUnrelate();
            }

            function preventRelate() {
                updateRelatedDocsCheckboxes(api);
                Events.triggerEvent('relatedDocs.preventedRelate');
            }

            function performRelate(isReplaceOriginals) {

                return RelatedDocumentTableCallbackContainer
                    .getPostSelectPromise(selectedDocs, addedDocument)
                    .then(
                        function () {

                            if (isReplaceOriginals)
                                $scope.editedDocument.relatedDocs = selectedDocs;
                            else
                                $scope.editedDocument.relatedDocs = selectedDocs.concat(originalDocuments);

                            Events.triggerEvent('relatedDocs.related', selectedDocs, prevDocs, addedDocument);
                            Events.triggerEvent('relatedDocs.selectedFromTable', selectedDocs, prevDocs);

                        },
                        function () {
                            preventRelate();
                        });
            }

            function performCopy() {
                preventRelate();
                Events.triggerEvent('relatedDocs.copied', selectedDocs, prevDocs, addedDocument);
                Events.triggerEvent('relatedDocs.selectedFromTable', selectedDocs, prevDocs);
            }

            function performUnrelate() {
                $scope.editedDocument.relatedDocs = selectedDocs;
                Events.triggerEvent('relatedDocs.unrelated', selectedDocs, prevDocs);
                Events.triggerEvent('relatedDocs.selectedFromTable', selectedDocs, prevDocs);
            }

        };

        function removeAllRelatedDocuments() {
            $scope.editedDocument.relatedDocs = [];
        }

        function updateShowRelatedDocumentsBlock() {
            var defaultDocs = [];

            if (!isCustomerChanged()) {
                defaultDocs = filterDocumentsByRelate(originalDocuments);
                defaultDocs = filterDocumentsForSelectedCurrency(defaultDocs)
            } else {
                defaultDocs = [];
            }
            if ($scope.template.isEditable || $scope.template.isRetroactiveRelatingAllowed) {
                getRelatedDocuments().then(function (data) {

                    data                              = filterDocumentsByRelate(data);
                    data                              = filterDocumentsForSelectedCurrency(data);
                    var hasDocumentsToRelate          = !!data.length;
                    $scope.template.showRelationBlock = (defaultDocs.length || hasDocumentsToRelate) && checkOtherConditions();
                });
            } else {
                $scope.template.showRelationBlock = !!defaultDocs.length;
            }
        }

        function getRelatedDocuments() {

            var defer                     = $q.defer();
            var documentsOfAnyStatusArray = $scope.template.$reliableTypesOfAnyStatusDocuments;
            var onlyOpenedArray           = $scope.template.$reliableTypesOfOpenDocuments;
            if (isCustomerSelected() && (onlyOpenedArray || documentsOfAnyStatusArray)) {

                var customerId = $scope.editedDocument.customer
                    ? $scope.editedDocument.customer.id
                    : null;

                var documentsOfAnyStatusString = documentsOfAnyStatusArray
                    ? documentsOfAnyStatusArray.toString()
                    : 'undefined';


                var onlyOpenedString = onlyOpenedArray
                    ? onlyOpenedArray.toString()
                    : 'undefined';


                if (!$scope.template.isModalLockedForEdit)
                    Customer
                        .getRelatedDocuments(customerId, {
                            opened: onlyOpenedString,
                            all   : documentsOfAnyStatusString
                        })
                        .then(function (data) {
                            defer.resolve(data);
                        });
                else
                    defer.resolve([]);

            } else {
                defer.resolve([]);
            }


            return defer
                .promise
                .then(function (documents) {
                    return RelatedDocumentTableCallbackContainer.getOnPostFetchPromise(documents);
                });

        }


        $scope.reloadRelatedDocumentTable = function () {

            if ($.isEmptyObject($scope.dt.instance) || !$scope.template.showRelatedDocumentsTable)
                return;

            if (!isCustomerSelected())
                return;

            /* For some reasons DTInstance.DataTable is not working after table rerender.
             Dunno what is it. Need more deep researching of this problem. I suspect the library version.
             Now i forced to set valid DataTable API object via fnDrawCallback.
             TODO Do some research to understand why it happens and how to resolve that problem.
             */
            $scope.dt.instance.rerender();

            // $scope.dt.instance.reloadData(null, true);

        };

        function onDataReload(data) {

            var defaultDocs = !isCustomerChanged()
                ? originalDocuments
                : [];

            var merged = data.concat(defaultDocs);

            var result = [];
            $.each(merged, function (index, doc) {
                var isAlreadyAdded = $.grep(result, function (e) {
                    return doc.id && e.id && doc.id === e.id
                });

                if (isAlreadyAdded.length === 0) {
                    result.push(doc);
                }
            });

            return convertDocumentAmounts(result);

        }

        function convertDocumentAmounts(documents) {

            documents.forEach(function (document) {


                if (document.discounts)
                    document.discounts.forEach(function (discount) {
                        discount.value = Currency.convert(
                            discount.value,
                            document.currencies,
                            $scope.editedDocument.currencies,
                            $scope.editedDocument.nisCourse
                        );
                    });


                var amounts = [
                    'total',
                    'totalWithVat',
                    'displayedTotal',
                    'remainingBalance'
                ];

                amounts.forEach(function (attribute) {

                    if (!document[attribute])
                        return;

                    document[attribute] = Currency.convert(
                        document[attribute],
                        document.currencies,
                        $scope.editedDocument.currencies,
                        $scope.editedDocument.nisCourse
                    )


                });
            });

            return documents;
        }

        function isCustomerChanged() {
            return originalCustomer && originalCustomer.id != $scope.editedDocument.customer.id;
        }

        function isCustomerSelected() {

            var isOccasionalDocsCanBeRelated = !$scope.template.$isOccasionalCustomerBlockDisabled && $scope.template.$isOccasionalDocsRelatingAllowed;

            return (!$scope.template.useExistingCustomer && isOccasionalDocsCanBeRelated)
                || ($scope.editedDocument.customer && $scope.editedDocument.customer.id);
        }

        function isDocumentInRelated(value) {

            if (!$scope.editedDocument.relatedDocs)
                return false;

            var result = $.grep($scope.editedDocument.relatedDocs, function (e) {
                return e.id == value.id;
            });

            return result && result.length > 0;
        }

        function filterDocumentsForSelectedCurrency(documents) {
            return $filter('filter')(documents, {
                currencies: {
                    name: $scope.editedDocument.currencies.name
                }
            });
        }

        function filterDocumentsByRelate(documents) {
            return documents.length ? _.differenceBy(documents, originalDocuments, 'id') : [];
        }


        function updateRelatedDocsCheckboxes(api) {
            api.rows().every(function (index) {
                var row          = api.row(index);
                var documentData = row.data();

                if (isDocumentInRelated(documentData) && !angular.isUndefined(row.select)) {
                    row.select();
                } else {
                    row.deselect();
                }
            });
        }

        function initializeDataTable() {

            var filterValues = {
                types: []
            };

            function loadFilters(existingDocuments, filterValues) {

                // We cannot just replace filterValues with empty array because reference will be lost
                // So we should cleanup original one

                $.each(filterValues, function (key, value) {
                    value.splice(0, value.length);
                });

                DocumentFiltersValues
                    .getTypeFilterValues(existingDocuments)
                    .forEach(function (val) {
                        filterValues.types.push(val);
                    });

            }

            var promise = function () {
                return getRelatedDocuments()
                    .then(function (documents) {
                        documents = onDataReload(documents);
                        documents = filterDocumentsByRelate(documents);
                        return filterDocumentsForSelectedCurrency(documents);
                    })
                    .then(function (documents) {
                        loadFilters(documents, filterValues);
                        return documents;
                    });
            };


            var customLanguageSettings = angular.copy(DataTableLanguageSettings);

            customLanguageSettings.select.rows['_'] = $translate.instant('documents.related_documents_table.many');
            customLanguageSettings.select.rows[1]   = $translate.instant('documents.related_documents_table.one');

            return DTOptionsBuilder
                .fromFnPromise(promise)
                .withBootstrap()
                .withOption('autoWidth', false)
                .withOption('sDom', "<'row'>rt<'row'<'col-xs-6 text-left'i><'col-xs-6 text-right'p>>")
                .withOption('oLanguage', customLanguageSettings)
                .withBootstrapOptions(DataTableBootstrapSettings)
                .withColumnFilter({
                    aoColumns: [

                        null,
                        {
                            type  : 'select',
                            bRegex: false,
                            bSmart: false,
                            values: filterValues.types
                        },
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'date-range', bRegex: true, bSmart: true, sRangeFormat: DataTableDatepickerLabels.fromToLabels()},
                        {type: 'text', bRegex: true, bSmart: true},
                        {type: 'number-range', bRegex: true, bSmart: true, 'sRangeFormat': DataTableDatepickerLabels.fromToLabels()},
                        {type: 'number-range', bRegex: true, bSmart: true, 'sRangeFormat': DataTableDatepickerLabels.fromToLabels()}
                    ]
                })
                .withOption('fnRowCallback', function (nRow, aData) {
                    $compile(nRow)($scope);
                    return nRow;
                })
                .withOption('fnDrawCallback', function (settings) {

                    $scope.dt.api = new $.fn.dataTable.Api(settings);

                    var api = $scope.dt.api;

                    updateRelatedDocsCheckboxes(api);

                })
                .withButtons([]);
        }

        function initializeDataTableColumns() {

            var dtColumns = [

                DTColumnBuilder
                    .newColumn('select')
                    .notSortable()
                    .withOption('width', '35px')
                    .withClass('select-checkbox')
                    .renderWith(function (data, type, fullData) {
                        return '<dt-select-checkbox disabled="::isSelectDisabled(' + fullData.id + ')" onchange="calculateSelectedDocuments()"/>';
                    }),

                DTColumnBuilder
                    .newColumn('type')
                    .withOption('width', '10%')
                    .withTitle($translate.instant('documents.table.type'))
                    .renderWith(function (data) {
                        return $filter('translateDocType')(data.docType)
                    }),

                DTColumnBuilder
                    .newColumn('docNumber')
                    .withOption('width', '10%')
                    .withTitle($translate.instant('documents.table.number')),

                DTColumnBuilder
                    .newColumn('issueDate')
                    .withOption('width', '21%')
                    .withTitle($translate.instant('documents.table.date'))
                    .withOption('type', 'date-formatted')
                    .renderWith(function (data) {
                        return data ? $filter('dpickerDateFormatter')(data) : '';
                    }),


                DTColumnBuilder
                    .newColumn('title')
                    .withOption('width', '15%')
                    .withTitle($translate.instant('documents.title')),

                DTColumnBuilder
                    .newColumn('amount')
                    .withOption('width', '17%')
                    .withTitle($translate.instant('documents.table.amount'))
                    .withOption('render', {
                        "filter" : function (data, type, fullData) {
                            return fullData.displayedTotal;
                        },
                        "sort"   : function (data, type, fullData) {
                            return fullData.displayedTotal;
                        },
                        "display": function (data, type, fullData) {
                            return $filter('number')(fullData.displayedTotal, 2)
                        }
                    })
                    .withOption('createdCell', function (td, cellData, rowData, row, col) {
                            var cell = $(td);
                            if (!Document.isCreditDoc(rowData.type.docType) && !Document.isBalanceAdjustment(rowData.type.docType))
                                cell.addClass('green-text');
                            else {
                                cell.addClass('red-text')
                            }
                        }
                    ),

                DTColumnBuilder
                    .newColumn('balance')
                    .withOption('width', '17%')
                    .withTitle($translate.instant('documents.table.balance'))
                    .withOption('render', {
                        "filter" : function (data, type, fullData) {
                            return fullData.remainingBalance;
                        },
                        "sort"   : function (data, type, fullData) {
                            return fullData.remainingBalance;
                        },
                        "display": function (data, type, fullData) {
                            return $filter('number')(fullData.remainingBalance, 2)
                        }
                    })
                    .withOption('createdCell', function (td, cellData, rowData, row, col) {
                            var cell = $(td);
                            if (!Document.isCreditDoc(rowData.type.docType) && !Document.isBalanceAdjustment(rowData.type.docType))
                                cell.addClass('green-text');
                            else {
                                cell.addClass('red-text')
                            }
                        }
                    )
            ];

            dtColumns.forEach(function(element) {
                element.withOption('defaultContent', "");
            });

            return dtColumns;

        }

        DocumentInitializer.addInitCallback(function () {

            originalDocuments                         = angular.copy($scope.editedDocument.relatedDocs) || [];
            originalCustomer                          = angular.copy($scope.editedDocument.customer);
            $scope.template.showRelationBlock         = false;
            $scope.dt                                 = {};
            $scope.dt.instance                        = {};
            $scope.dt.options                         = initializeDataTable();
            $scope.dt.columns                         = initializeDataTableColumns();
            $scope.initialRelatedDocs                 = angular.copy($scope.editedDocument.relatedDocs);
            $scope.template.showRelatedDocumentsTable = false;
            $scope.initialRelatedToDocs               = angular.copy($scope.editedDocument.relatedToDocs);

            updateDocumentsCheckbox();
            updateShowRelatedDocumentsBlock();

            Events.subscribeToEvent('currencies.change', $scope, function (event, newCurrency, oldCurrency) {
                if (newCurrency == oldCurrency || !newCurrency)
                    return false;

                updateShowRelatedDocumentsBlock();
                $scope.reloadRelatedDocumentTable();
            });

            Events.subscribeToEvent('relatedDocs.checkedHasRelatedDocuments', $scope, function () {
                if (!$scope.template.showRelatedDocumentsTable) {
                    removeAllRelatedDocuments();
                }
                $scope.reloadRelatedDocumentTable();
            });

            $scope.$watch('template.$reliableTypesOfOpenDocuments', function (newVal, oldVal) {
                if (newVal == oldVal || !newVal)
                    return;

                updateShowRelatedDocumentsBlock();
                $scope.reloadRelatedDocumentTable();
            });

            Events.subscribeToEvent('customer.change', $scope, function (e, newVal) {
                $scope.editedDocument.relatedDocs = [];
                updateShowRelatedDocumentsBlock();

                $scope.reloadRelatedDocumentTable();
            });

        })

    });