/**
 * Created by abichenko on 21.09.17.
 */
angular.module('sinvoice-common').factory('UserSubscription', function (Subscription, UserSubscriptionCallbackContainer) {

    var isCheckInProgress = false;

    return {
        isCheckInProgress                   : function () {
            return isCheckInProgress;
        },
        checkSubscriptionForEditAction      : function () {
            isCheckInProgress = true;

            return Subscription
                .getCurrent()
                .then(function (data) {
                    return UserSubscriptionCallbackContainer.getEditActionCallbackPromise(
                        _.includes(data.content.availableOptions, 'edit')
                    )
                })
                .finally(function () {
                    isCheckInProgress = false;
                })
        },
        checkSubscriptionForDocumentCreation: function () {

            isCheckInProgress = true;

            return Subscription
                .getCurrent()
                .then(function (data) {
                    return UserSubscriptionCallbackContainer.getDocumentCreationCallbackPromise(data)
                })
                .finally(function () {
                    isCheckInProgress = false;
                })

        }
    }

});