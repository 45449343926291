/**
 * Created by abichenko on 24.11.17.
 */

angular.module('sinvoice-common')
    .factory('DocumentPaymentAmountCalculator', function (Currency, PaymentTypeConstants) {


        return {

            getSummaries: function (payments) {
                var summaries = {};

                var types = [
                    PaymentTypeConstants.TYPE_MONEY_WORTH,
                    PaymentTypeConstants.TYPE_DIRECT_DEBIT,
                    PaymentTypeConstants.TYPE_CREDIT_CARD,
                    PaymentTypeConstants.TYPE_BANK_TRANSFER,
                    PaymentTypeConstants.TYPE_CHECK,
                    PaymentTypeConstants.TYPE_PAYPAL,
                    PaymentTypeConstants.TYPE_CASH,
                ];

                types.forEach(function (value) {
                    summaries[value] = {amount: 0.0, count: 0, amountNonConverted: 0.0};
                });

                payments.forEach(function (value) {

                    var amount = parseFloat(value.$summary) || 0;
                    summaries[value.type].amount += amount;
                    summaries[value.type].count++;

                });

                return summaries;
            },


            calculatePaymentsTotal: function (payments) {

                var total = 0;
                payments.forEach(function (value) {
                    total += parseFloat(value.$summary) || 0;
                });

                return total
            },

            getPaymentTotals: function (total, taxValue, useTaxWithdrawal, isNisPayments, currency, nisCourse) {

                var totalWithVat = total;
                taxValue         = parseFloat(taxValue) || 0;

                if (useTaxWithdrawal)
                    totalWithVat += taxValue;

                if (totalWithVat < 0)
                    totalWithVat = 0;


                return {
                    paymentsTotal          : total,
                    paymentsTotalWithVat   : totalWithVat,
                    paymentsTotalWithVatNIS: getPaymentTotalWithVat(totalWithVat, isNisPayments, currency, nisCourse)
                };

            }
        };


        function getPaymentTotalWithVat(paymentsTotalWithVat, isNisPayments, currency, nisCourse) {

            return Currency.convertAmount(
                    paymentsTotalWithVat,
                    isNisPayments ? 'NIS' : currency.name,
                    'NIS',
                    nisCourse
                ) || 0;
        }


    });