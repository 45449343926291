angular.module('sinvoice-common').factory('Customer', function ($http, $q, API, Url, $translate) {
    return {
        getData: function () {
            var deferred = $q.defer();
            API.get(Url.customers.all(), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getSingleCustomer: function (id) {
            var deferred = $q.defer();

            API.get(Url.customers.get(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getPaymentTerms  : function () {
            var deferred = $q.defer();
            API.get(Url.customers.paymentTerms())
                .success(function (data) {
                    deferred.resolve(data);

                })
                .error(function (error) {

                });
            return deferred.promise;
        },
        getCommunications: function (id) {
            var deferred = $q.defer();
            API.get(Url.customers.communications(id), {})
                .success(function (data) {
                    dbg(data);
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },


        getRelatedDocuments: function (id, options) {
            var deferred = $q.defer();
            API.get(Url.customers.documents(id), options || {})
                .success(function (data) {
                    angular.forEach(data, function (value, key) {
                        //value.doc_type_label = value.doc_type;
                        value.doc_type_label = $translate.instant('documents.types.' + value.type.docType);
                        value.doc_type       = value.type.docType;
                        data[key]            = value;
                    });
                    dbg(data);
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        update             : function (id, customer) {

            var deferred = $q.defer();

            API.patch(Url.customers.update(id), customer)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;

        },
        create             : function (customer) {

            var deferred = $q.defer();

            API.post(Url.customers.create(), customer)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;

        },

        checkIfExist        : function (attribute, value) {
            var defer = $q.defer();

            API
                .get(Url.customers.checkIfExistName(attribute, value))
                .success(function (data) {
                    defer.resolve(data)
                })
                .error(function (data) {
                    defer.reject(data)
                });

            return defer.promise;
        },
        sendSMS             : function (ids, message) {
            var deferred = $q.defer();

            API.post(Url.customers.sendSMS(), {ids: ids, message: message})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        sendEmail           : function (ids, message, subject, sendCopy) {
            var deferred = $q.defer();

            API.post(Url.customers.sendEmail(), {ids: ids, message: message, subject: subject, sendCopy: sendCopy})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        sendEmailDirectly   : function (emails, message, subject, sendCopy) {
            var deferred = $q.defer();

            API.post(Url.customers.sendEmailDirectly(), {
                emails  : emails,
                message : message,
                subject : subject,
                sendCopy: sendCopy
            })
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });

            return deferred.promise;
        },
        reloadCustomer      : function (id) {
            var deferred = $q.defer();
            API.get(Url.customers.get(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        changeStatus        : function (status, ids) {
            var deferred = $q.defer();

            API.post(Url.customers.changeStatus(status), {id: ids})
                .success(function (data) {
                    deferred.resolve(data);
                });

            return deferred.promise;


        },
        getStats            : function (id) {
            var deferred = $q.defer();
            API.get(Url.customers.stats(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getIncome           : function (id) {
            var deferred = $q.defer();
            API.get(Url.customers.income(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getReceived         : function (id) {
            var deferred = $q.defer();
            API.get(Url.customers.received(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getPDFContent       : function (customers, columns) {
            var deferred = $q.defer();
            API.get(Url.customers.pdfContent(), {customers: customers, columns: columns})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getXLSArchiveContent: function (customers, columns) {
            var deferred = $q.defer();
            API.get(Url.customers.xlsArchiveContent(), {customers: customers, columns: columns})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getCSVArchiveContent: function (customers) {
            var deferred = $q.defer();
            API.get(Url.customers.csvArchiveContent(), {customers: customers})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getUploadStatus        : function (token) {
            var deferred = $q.defer();
            API.get(Url.customers.getUploadStatus(token))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        importFromXLS          : function (base64) {
            var deferred = $q.defer();
            API.post(Url.customers.importXLS(), {content: base64})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        getRelatedCustomerTypes: function () {
            var deferred = $q.defer();
            API.get(Url.customers.relatedCustomersTypes(), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        deleteRelatedCustomer  : function (customerId, relatedCustomerRecordId) {
            var deferred = $q.defer();
            API.delete(Url.customers.deleteRelatedCustomer(customerId, relatedCustomerRecordId))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        updateRelatedCustomer: function (customerId, relatedCustomerRecordId, data) {
            var deferred = $q.defer();
            API.put(Url.customers.updateRelatedCustomer(customerId, relatedCustomerRecordId), data)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        createRelatedCustomer: function (customerId, data) {
            var deferred = $q.defer();
            API.post(Url.customers.createRelatedCustomer(customerId), data)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getRelatedCustomers: function (customerId) {
            var deferred = $q.defer();
            API.get(Url.customers.allRelatedCustomers(customerId), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        getContacts: function (customerId) {
            var deferred = $q.defer();
            API.get(Url.customers.allContacts(customerId), {})
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        deleteContact: function (id) {
            var deferred = $q.defer();
            API.delete(Url.customers.deleteContact(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },

        updateContact   : function (id, data) {
            var deferred = $q.defer();
            API.put(Url.customers.updateContact(id), data)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        createContact   : function (data) {

            var deferred = $q.defer();
            API.post(Url.customers.createContact(), data)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        confirmImporting: function (token) {
            var deferred = $q.defer();
            API.post(Url.customers.confirmImporting(token))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        deleteAddress   : function (id) {

            var deferred = $q.defer();
            API.delete(Url.customers.deleteAddress(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        updateAddress   : function (id, data) {

            var deferred = $q.defer();
            API.put(Url.customers.updateAddress(id), data)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        createAddress   : function (data) {

            var deferred = $q.defer();
            API.post(Url.customers.createAddress(), data)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        updateGeneral   : function (id, data) {

            var deferred = $q.defer();
            API.patch(Url.customers.updateGeneral(id), data)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        deleteBankAccount   : function (id) {

            var deferred = $q.defer();
            API.delete(Url.customers.deleteBankAccount(id))
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        updateBankAccount   : function (id, data) {

            var deferred = $q.defer();
            API.put(Url.customers.updateBankAccount(id), data)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
        createBankAccount   : function (data) {

            var deferred = $q.defer();
            API.post(Url.customers.createBankAccount(), data)
                .success(function (data) {
                    deferred.resolve(data);
                })
                .error(function (error) {
                    deferred.reject(error);
                });
            return deferred.promise;
        },
    };
});
