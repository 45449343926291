/**
 * Created by dbichenko on 20.12.2016.
 */
angular.module('sinvoice-common').factory('DocumentModalCallbackContainer', function (promiseHelper) {

    var preSaveCallbacks     = [];
    var preValidateCallbacks = [];

    return {
        addPreSaveCallback: function (callback) {
            preSaveCallbacks.push(callback);
        },

        addPreValidateCallback: function (callback) {
            preValidateCallbacks.push(callback)
        },
        getPreSavePromise: function () {
            return promiseHelper.createPromiseChainWithDefer(preSaveCallbacks);
        },
        
        getValidatePromise: function (form) {

            var callbacks = angular.copy(preValidateCallbacks);

            callbacks.push(function (flow) {
                if (form.$invalid) {
                    flow.deny();
                } else {
                    flow.allow();
                }
            });

            return promiseHelper.createPromiseChainWithDefer(callbacks);
        },
        reset : function () {
            preSaveCallbacks = [];
            preValidateCallbacks = [];
        }
    }

});