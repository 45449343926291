/**
 * Created by dbichenko on 16.09.2016.
 */
angular.module('sinvoice-desktop').controller('newCustomerController',
    function ($scope, Customer, Modal, $filter, Events, ProfileChecker, AuthDataStorage, FormHelper, EditedValueComparator, DesktopModal) {


        var backup;

        $scope.showLastContact     = showLastContact;
        $scope.showPreviousContact = showPreviousContact;
        $scope.showNextContact     = showNextContact;
        $scope.activateTab         = activateTab;
        $scope.saveCustomer        = saveCustomer;

        $scope.customer = {
            name    : '',
            phone   : '',
            email   : '',
            fax     : '',
            contacts: [
                {
                    fname : '',
                    phones: [],
                    emails: [],
                    faxes : []
                }
            ]
        };

        $scope.newContactForms     = [];
        $scope.newCustomerForm     = {};
        $scope.currentContactsPage = 0;
        $scope.activeTab           = {'details': true, 'contacts': false};
        $scope.spinner             = {newCustomer: false};


        $scope.close = function () {
            $scope.cancel();
        };

        $scope.$currentModal.onCancelCallback = function () {

            if (EditedValueComparator.isObjectChangedExceptOfProps(backup, $scope.customer) || $scope.newCustomerForm.$pending) {
                return DesktopModal.unsavedChanges();
            } else {
                return true;
            }
        };

        $scope.containsNotEmptyFields = function (form, ignoreName) {
            return !!FormHelper.getInputControllers(form)
                .filter(function (value) {
                    return form.$name !== ignoreName && value.$viewValue;
                })
                .length;
        };

        $scope.isEnglishAvailable = function () {
            return ProfileChecker.isEnglishAvailable(AuthDataStorage.getProfileBusinesses());
        };


        function activateTab(index) {
            $scope.activeTab[index] = true;
        };


        function showLastContact() {
            $scope.currentContactsPage = $scope.customer.contacts.length - 1;
        }

        function showPreviousContact() {
            if ($scope.currentContactsPage > 0) {
                $scope.currentContactsPage -= 1;
            }
        }

        function showNextContact() {
            if ($scope.currentContactsPage < $scope.customer.contacts.length - 1) {
                $scope.currentContactsPage += 1;
            }
        }


        // Check validation create customer form
        function saveCustomer() {
            var invalidContactNumber = null;

            $scope.newCustomerForm.$setSubmitted();

            if ($filter('isSubmitNotAllowed')($scope.newCustomerForm)) {
                $scope.activateTab('details');
                return false;
            }

            $scope.newContactForms.forEach(function (form, k) {
                if (form.$invalid) {
                    invalidContactNumber = k;
                }
            });


            if (invalidContactNumber !== null) {
                $scope.activateTab('contacts');
                $scope.currentContactsPage = invalidContactNumber;
                return false;
            }


            doCreate();

        };

        // Send new customer to server
        function doCreate() {

            if ($scope.customer.paymentTerm) {
                $scope.customer.paymentterm = $scope.customer.paymentTerm.id;
                delete $scope.customer.paymentTerm;
            } else {
                $scope.customer.paymentterm = null;
            }

            $scope.spinner.newCustomer = true;
            Customer
                .create($scope.customer)
                .then(function (data) {
                    $scope.$currentModal.close();
                    Events.triggerEvent('customer.update');

                    Modal.alert(
                        $filter('translate')('customers.save_success_popup.message'),
                        {title: $filter('translate')('customers.save_success_popup.title')}
                    );
                })
                .finally(function () {
                    $scope.spinner.newCustomer = false;
                });
        }


        // Load customer types from server for select
        function loadPaymentTerms() {
            return Customer.getPaymentTerms().then(function (data) {
                $scope.paymentTerms = data;
            })
        };

        (function () {
            $scope.activateTab('details');
            loadPaymentTerms()
                .finally(function () {
                    $scope.spinner.newCustomer = false;
                });
            backup = angular.copy($scope.customer);

        })();


        function initializeValidators() {
            $scope.validators = {}
        }

        initializeValidators();


    });
