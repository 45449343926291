/**
 * Created by dbichenko on 06.03.2017.
 */
/**
 * Created by dbichenko on 06.03.2017.
 */
angular.module('sinvoice-mobile')

    .run(function ($rootScope, $location, $state, $timeout, Auth, onInitContainer) {

        function getStateToRedirect(toStateName) {
            var unsecureStates = ['login', 'forgot-password', 'reset-password'];

            if (!Auth.isAuthenticated() && (unsecureStates.indexOf(toStateName) === -1)) {
                return 'login';
            }

            if (Auth.isAuthenticated() && toStateName === 'login') {
                return 'my-documents-customers';
            }
        }

        onInitContainer.onInitSequenceIsCompleted(function () {

            var stateToRedirect = getStateToRedirect($state.current.name);
            if (stateToRedirect) {
                $state.go(stateToRedirect);
            }

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                var stateToRedirect = getStateToRedirect(toState.name);
                if (stateToRedirect) {
                    event.preventDefault();
                    $timeout(function () {
                        $state.go(stateToRedirect);
                    })
                }
            })
        });
    })

    .config(function ($stateProvider, $urlRouterProvider) {

        $urlRouterProvider.otherwise("/login");

        var pages = [
            {
                state      : 'header',
                url        : '/header',
                templateUrl: './mobile/layouts/header/header.html'
            },
            {
                state      : 'help',
                url        : '/help',
                templateUrl: './mobile/components/help/help.html'
            },
            {
                group      : 'documents',
                state      : 'my-documents',
                url        : '/my-documents',
                templateUrl: './mobile/components/my-documents/my-documents.html'
            },
            {
                state      : 'my-documents-customers',
                url        : '/my-documents-customers',
                templateUrl: './mobile/components/my-documents-customers/my-documents-customers.html'
            },
            {
                group      : 'customers',
                state      : 'my-customers',
                url        : '/my-customers',
                templateUrl: './mobile/components/my-customers/my-customers.html'
            },
            {
                state      : 'login',
                url        : '/login',
                templateUrl: './mobile/components/login/login.html'
            },
            {
                group      : 'documents',
                state      : 'new-document',
                url        : '/new-document?customerId',
                templateUrl: './mobile/components/new-document/new-document.html'
            },
            {

                group      : 'customers',
                state      : 'new-customer',
                url        : '/new-customer',
                templateUrl: './mobile/components/new-customer/new-customer.html'
            },
            {
                group      : 'customers',
                state      : 'view-customer',
                url        : '/view-customer/:customerId',
                templateUrl: './mobile/components/view-customer/view-customer.html'
            },
            {
                group      : 'customers',
                state      : 'view-customer.edit',
                url        : '/edit',
                templateUrl: './mobile/components/view-customer/edit-customer.html'
            },
            {
                group      : 'customers',
                state      : 'view-customer.details',
                url        : '/details',
                templateUrl: './mobile/components/view-customer/view-customer-details.html'
            },
            {
                group      : 'customers',
                state      : 'edit-customer-detail',
                url        : '/edit-customer-detail',
                templateUrl: './mobile/components/edit-customer-detail/edit-customer-detail.html'
            },
            {
                group      : 'documents',
                state      : 'view-document',
                url        : '/view-document/:documentId',
                templateUrl: './mobile/components/view-document/view-document.html'
            },
            {
                state      : 'search-overlay',
                url        : '/search-overlay',
                templateUrl: './mobile/components/search-overlay/search-overlay.html'
            },
            {
                state      : 'forgot-password',
                url        : '/forgot-password',
                templateUrl: './mobile/components/login/forgot-password.html'
            },
            {
                state      : 'reset-password',
                url        : '/resetPassword/:token',
                templateUrl: './mobile/components/login/reset-password.html'
            },
            {
                state      : 'contact-us',
                url        : '/contact-us',
                controller : 'contactUsController',
                templateUrl: './mobile/components/contact-us/contact-us.html'
            }
        ];

        pages.forEach(function (page) {
            $stateProvider.state(page.state, page)
        })

    });