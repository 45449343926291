angular.module('sinvoice-desktop').factory('Users', function ($http, $q, $log, API, Url) {
    return {
        getData: function (url) {
            var deferred = $q.defer();
            API.get(url, {})
                .success(function(data){
                    deferred.resolve(data);
                })
                .error(function(error){
                    deferred.reject(error);
                });
            return deferred.promise;
        }
    }; 
});