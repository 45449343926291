/**
 * Created by abichenko on 29.01.18.
 */
angular.module('sinvoice-desktop')
    .directive('helpPopup', function (HelpService, $timeout, ModalContainer) {
        return {
            scope      : {
                templateUrl: '=',
                headerUrl  : '=',
                onClose    : '&',
                onLinkClick: '&',
                alias      : '='
            },
            templateUrl: './desktop/components/common/smiling-man/help-popup-directive.html',
            link       : function (scope, element, attrs) {

                scope.close = function () {
                    scope.onClose()
                };

                scope.linkClick = function () {
                    scope.onLinkClick();


                    ModalContainer.closeAllModals();
                };

                (function () {

                    HelpService.showQuestionTooltip(scope.alias).then(function (data) {
                        $timeout(function () {
                            scope.content = data;
                        })

                    })
                })();
            }
        }
    });