/**
 * Created by dbichenko on 15.11.2016.
 */
angular.module('sinvoice-common').factory('DiscountCalculator', function () {


    return {

        calculateValue: function (discount, total) {

            if (discount.type === 'fixed') {
                return Number(discount.value);
            } else if (discount.type === 'percent') {
                return Number(total) * (Number(discount.value) / 100);
            }

        }
    }

});