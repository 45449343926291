/**
 * Created by dbichenko on 05.05.2017.
 */
angular.module('sinvoice-desktop').filter('translateDistributorStatus', function ($translate) {


    return function (value) {
        if (!value)
            return '';

        return $translate.instant('control.payment.status.' + value);
    }

});