/**
 * Created by dbichenko on 06.10.2016.
 */
angular.module('sinvoice-common').factory('ParagraphCheck', function (AuthDataStorage, BusinessChecker) {

    return {
        isImportedFromRelatedDocument: isImportedFromRelatedDocument,
        isCreatedFromItem            : isCreatedFromItem,
        isNew                        : isNew,
        containDiscounts             : containDiscounts,
        isDiscount                   : isDiscount,
        isVATNotAllowed              : isVATNotAllowed
    };

    function isImportedFromRelatedDocument(element) {
        return element.isImportedFromRelatedDoc || element.document;
    }

    function isCreatedFromItem(element) {
        return !!element.item;
    }

    function isNew(paragraph) {
        return !paragraph.id;
    }


    function containDiscounts(paragraphs) {
        return !!paragraphs.filter(isDiscount).length;

    }

    function isDiscount(paragraph) {
        return paragraph.unitPrice < 0;
    }

    function isVATNotAllowed() {


        return BusinessChecker.isExemptDealer(AuthDataStorage.getProfile().businessType)
    }

});