angular.module('sinvoice-desktop')
    .directive('docTypeButtonDirective', function () {
        return {
            scope      : {
                onClick: '&'
            },
            transclude: true,
            templateUrl: './desktop/components/documents/partials/doc-type-button.html',
            replace    : true,
            link       : function (scope, element, attr) {

            }
        }
    });

