angular
    .module('sinvoice-desktop', [
        'dragularModule',
        'slickCarousel',
        'ui.bootstrap',
        'ngDragDrop',
        'datatables',
        'datatables.bootstrap',
        'datatables.columnfilter',
        'datatables.buttons',
        'datatables.select',
        'ngBootbox',
        'angular-growl',
        'naif.base64',
        'ngclipboard',
        'gridster',
        'ui.select',
        'ui.bootstrap-slider',
        'angular.filter',
        'bootstrapLightbox',
        'videosharing-embed',
        'sinvoice-common'
    ])
    .config(function ($httpProvider) {
        $httpProvider.interceptors.push(['$injector', function ($injector) {
            return $injector.get('ApiInterceptor');
        }]);
        $httpProvider.interceptors.push(['$injector', function ($injector) {
            return $injector.get('dateApiNormalizer');
        }]);
    })
    .config(function ($animateProvider) {
        $animateProvider.classNameFilter(/angular-animate/);
    })
    .filter('debug', function () {
        return function (input) {
            if (input === '') return 'empty string';
            return input ? input : ('' + input);
        };
    })
    .config(['growlProvider', function (growlProvider) {
        growlProvider.globalReversedOrder(true);
        growlProvider.onlyUniqueMessages(false);
        growlProvider.globalPosition('top-right');
        growlProvider.globalDisableIcons(true);
        growlProvider.globalDisableCloseButton(true);
        growlProvider.globalTimeToLive(3000);
        growlProvider.globalDisableCountDown(true)

    }])

