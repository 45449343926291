/**
 * Created by abichenko on 31.07.17.
 */
angular.module('sinvoice-desktop').controller('importCustomersFromCSVPopupController',
    function ($scope, CustomerImport, $timeout, Events, CustomerModal, FileHelper) {

        var timer;

        $scope.uploadCSVForm = {};

        $scope.selected = {};

        $scope.spinner = {enabled: false};

        $scope.allowedFormats = ['csv'];

        $scope.$on('$destroy', function () {
            $timeout.cancel(timer);
        });


        $scope.upload = function (addedFiles) {

            $scope.uploadCSVForm.file.$setValidity('csv', true);

            var first = addedFiles[0];

            $scope.spinner.enabled = true;

            CustomerImport
                .importFromXLSContent(first.content)
                .then(
                    function (data) {
                        enableStatusPolling(data.token)
                    },
                    function () {
                        $scope.uploadCSVForm.file.$setValidity('csv', false);
                        $scope.spinner.enabled = false;
                    })

        };


        function enableStatusPolling(token) {

            timer = $timeout(function () {
                CustomerImport
                    .checkUploadStatus(token)
                    .then(function (data) {
                        if (data.isCompleted) {
                            CustomerModal.importCustomersPreviewScreen(token);
                            $scope.$currentModal.close();
                        } else {
                            enableStatusPolling(token);
                        }
                    }, function () {
                        enableStatusPolling(token);
                    })
            }, 1000)
        }


    });