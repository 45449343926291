/**
 * Created by abichenko on 27.09.17.
 */
angular.module('sinvoice-desktop')
    .controller('helpDetailsController', function ($scope, $filter, HelpService, $stateParams, HelpMenuService, $timeout) {
        $scope.pageParams.subTitle   = $filter('translate')('help.specificQuestions');
        $scope.pageParams.hideHeader = false;
        $scope.spinner               = {enabled: true};
        $scope.currentQuestion       = null;

        (function () {
            HelpService
                .showQuestionDetails($stateParams.id)
                .then(function (data) {
                    $timeout(function () {
                        $scope.currentQuestion = data;
                        HelpMenuService.setCurrentCategory($scope.currentQuestion.category);
                    });
                })
                .finally(function () {
                    $timeout(function () {
                        $scope.spinner.enabled = false;
                    });
                });
        })();
    });