/**
 * Created by abichenko on 24.11.17.
 */
angular.module('sinvoice-common').directive('paymentsSummaryBlock', function () {

    return {
        scope      : {
            templateVariable: '=',
            taxValue        : '=',
            currency        : '=',
            nisCourse       : '=',
            isNisPayments   : '='
        },
        templateUrl: './mobile/components/new-document/partials/payments-summary-block.html',
        controller : 'paymentSummaryBlockController',
        link       : function (scope, element, attrs) {

        }
    }
});