/**
 * Created by dbichenko on 15.03.2017.
 */
angular.module('sinvoice-desktop').service('ItemModal', function ($translate, Modal) {


    this.createNewItem = createNewItem;
    this.editItem      = editItem;


    function createNewItem() {
        openEditItemModal();
    }

    function editItem(item) {
        openEditItemModal(item);
    }

    function openEditItemModal(id) {

        Modal.customDialog({
            title         : id
                ? $translate.instant('items.edit_item_title')
                : $translate.instant('items.new_item_title'),
            templateUrl   : './desktop/components/items/edit-item.html',
            scopeVariables: {
                itemId: id
            }
        })
    }

    return this;
});