/**
 * Created by dbichenko on 03.05.2017.
 */
angular.module('sinvoice-desktop').controller('printSettingsController', function ($scope, Profile, UserSubscription) {

    $scope.spinner  = {enabled: true};
    $scope.settings = {};


    $scope.isSubmitButtonDisabled = function () {
        return UserSubscription.isCheckInProgress();
    };


    $scope.isSaveAllowed = function () {
        return new Promise(function (resolve, reject) {

            if ($scope.profileForm.$invalid) {
                return reject(false);
            }

            return UserSubscription.checkSubscriptionForEditAction().then(function () {
                return resolve(true)
            });

        })
    };


    $scope.save = function () {

        $scope.spinner.enabled = true;
        return Profile
            .updatePrintSettings($scope.settings)
            .then(function (data) {
                $scope.backupInfo = angular.copy($scope.settings)
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            });
    };


    $scope.cancel = function () {
        $scope.settings = angular.copy($scope.backupInfo);
    };

    (function () {
        Profile
            .getPrintSettings()
            .then(function (data) {
                $scope.settings   = data;
                $scope.backupInfo = angular.copy(data);
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })
    })();
});