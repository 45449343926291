/**
 * Created by dbichenko on 22.12.2016.
 */
angular
    .module('sinvoice-desktop')
    .controller('newIncomeAndDebtsBlockController', function ($scope, Document, Report, Customer, Events, $q, $stateParams, $filter, ReportsFilterMapper, TaxPeriodProvider) {


        $scope.spinner            = {
            enabled: true
        };
        $scope.newReportForm      = {};
        $scope.availableCustomers = [];
        $scope.availableDocTypes  = [];

        $scope.setOtherDate     = function () {
            $scope.selected.dateExample = $scope.dateExamples[4];
        };
        $scope.updateFromToDate = function (date) {
            $scope.selected.from = date.from ? date.from : new Date();
            $scope.selected.to   = date.to ? date.to : new Date();
        };

        $scope.produceNewReport = function () {

            $scope.spinner.enabled = true;

            var requestData = angular.copy($scope.selected);

            Report.createReport('income_revenue', requestData).then(function (data) {
                $scope.spinner.enabled = false;
                Events.triggerEvent('report.update', data);
            });

        };


        (function () {

            $scope.selected = {
                filters: {
                    customers: [],
                    docTypes : []
                }
            };

            $scope.dateExamples = TaxPeriodProvider.getDatepickerValues();
            var dateFilters     = ReportsFilterMapper.getMappedDate(
                $stateParams.from,
                $stateParams.to,
                $scope.dateExamples
            );

            $scope.selected.to          = dateFilters.to;
            $scope.selected.from        = dateFilters.from;
            $scope.selected.dateExample = dateFilters.dateExample;


            Report.getFilterValues('income_revenue')
                .then(function (data) {

                    $scope.availableCustomers = data.customers;
                    $scope.availableDocTypes  = data.docTypes.map(function (val) {
                        return {id: val, label: $filter('translateDocType')(val)}
                    });

                    var groups = data.groups.map(function (group) {
                        return {id: group, label: $filter('translate')('reports.incomeAndDebts.' + group)}
                    });

                    $scope.availableDocTypes = groups.concat($scope.availableDocTypes);

                })
                .then(function () {


                    $scope.selected.filters.docTypes  = ReportsFilterMapper.getMappedValues($stateParams.docTypes, 'id', $scope.availableDocTypes);
                    $scope.selected.filters.customers = ReportsFilterMapper.getMappedValues($stateParams.customers, 'id', $scope.availableCustomers);

                })

                .then(function () {
                    $scope.spinner.enabled = false;
                })

        })();

    });