/**
 * Created by abichenko on 29.03.18.
 */
angular.module('sinvoice-mobile').controller('customBankInputBlockController',

    function ($scope, BusinessBankAccountDataProvider) {

        var vm = this;

        (function () {
            if (!angular.isDefined(vm.customBankName) && vm.customer) {
                vm.customBankName = BusinessBankAccountDataProvider.getCustomBankName(vm.customer);
            }
        })();

    });