/**
 * Created by dbichenko on 03.11.2016.
 */
angular.module('sinvoice-desktop').controller('sendEmailPopupController',
    function ($scope,
              $filter,
              sendEmailPopupFunctions,
              Profile,
              Modal,
              NotificationSendCopyLabelHelper,
              Auth,
              NotificationModal) {

        var allRecipients = [];



        $scope.spinner = {enabled: true};


        $scope.onSendCopyChange = function () {
            NotificationSendCopyLabelHelper.onChangeCallback($scope.sendCopy);

        };

        $scope.send = function () {

            if ($filter('isSubmitNotAllowed')($scope.sendEmailForm))
                return false;


            $scope.spinner.enabled = true;
            var ids                = _.map($scope.recipients, 'id');
            var sendEmailFunction  = sendEmailPopupFunctions.getSendEmailFunction($scope.$type);

            sendEmailFunction(ids, $scope.message, $scope.recipients, $scope.subject, $scope.sendCopy)
                .then(function (data) {

                    var emptyEmails = $scope.recipients.filter(function (recipient) {
                        return !recipient.email;
                    });

                    var serverError = $scope.recipients.filter(function (recipient) {
                        if (!data.result.email.failure.length) {
                            return false;
                        }
                        return _.includes(data.result.email.failure, recipient.email);
                    });


                    var failedList = _.flatten([
                        emptyEmails,
                        serverError
                    ]);

                    var failedNames       = _.map(failedList, 'name');
                    var allRecipientNames = _.map($scope.recipients, 'name');

                    NotificationModal
                        .displaySendEmailResultPopup(failedNames, allRecipientNames)
                        .then($scope.cancel);

                })
                .finally(function () {
                    $scope.spinner.enabled = false;
                })
        };

        $scope.cancel = function () {
            $scope.$currentModal.close();
        };


        (function () {
            $scope.sendCopy = NotificationSendCopyLabelHelper.getDefaultValue();

            $scope.message = '';

            if ($scope.recipients) {
                var mapperFunction = sendEmailPopupFunctions.getMapperFunction($scope.$type);
                $scope.recipients  = $scope.recipients.map(mapperFunction)
            } else {
                $scope.recipients = [];
            }

            loadAllRecipients().then(function () {

                $scope.spinner.enabled     = false;
                $scope.availableRecipients = allRecipients;

            });

        })();


        function loadAllRecipients() {

            var dataFunction   = sendEmailPopupFunctions.getDataFunction($scope.$type);
            var mapperFunction = sendEmailPopupFunctions.getMapperFunction($scope.$type);

            return dataFunction().then(function (data) {
                allRecipients = data.map(mapperFunction);
            })
        }


    });