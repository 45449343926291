/**
 * Created by dbichenko on 05.09.2016.
 */
angular.module('sinvoice-desktop').service('ManagedCustomersManagement', function (ManagedCustomers, $rootScope) {

    var customer = getEmptyUser();

    this.enableManagementMode      = enableManagementMode;
    this.getCurrentManagedCustomer = getCurrentManagedCustomer;
    this.disableManagementMode     = disableManagementMode;
    this.setCurrentManagedCustomer = setCurrentManagedCustomer;

    return this;

    function enableManagementMode(customerId) {


        if (angular.isObject(customerId)) {
            customer = customerId;
        } else {
            customer = {id: customerId};
        }


        if (!customer.id) {
            disableManagementMode();
            return null;
        }
        
        // We still need to obtain full data about customer.        
        return ManagedCustomers.get(customer.id).then(function (data) {
            customer = data;
        });

    }

    function getEmptyUser() {
        return {id: null};
    }

    function disableManagementMode() {
        customer = getEmptyUser();
    }

    function setCurrentManagedCustomer(id) {
        customer.id = id;
    }

    function getCurrentManagedCustomer() {
        return customer;
    }

});