angular.module('sinvoice-desktop').controller('loginController', function ($scope, $state, Auth, $filter, Login) {

    $scope.spinner  = {enabled: false};
    $scope.username = '';
    $scope.password = '';

    $scope.register = function (event) {
        event.preventDefault();
        Auth.onRegister();
        $state.go('register', null, {reload: true})
    };

    $scope.login = function () {

        $scope.loginForm.password.$setValidity('invalid', true);
        $scope.loginForm.$setValidity('subscription', true);

        if ($filter('isSubmitNotAllowed')($scope.loginForm)) {
            return
        }

        $scope.spinner.enabled = true;

        Login
            .performLogin($scope.username, $scope.password)
            .then(function (data) {
                if (data.success && data.api_key) {
                    Auth.login(data, $scope.rememberMe);
                } else {
                    if (data.isLocked) {
                        $scope.loginForm.$setValidity('subscription', false);
                    } else {
                        $scope.loginForm.password.$setValidity('invalid', false);
                    }
                }
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })
    };


});