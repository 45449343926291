/**
 * Created by abichenko on 15.11.17.
 */
angular.module('sinvoice-desktop')
    .controller('businessContactController', function ($scope) {

        $scope.addEmail = function () {
            $scope.contact.emails.push('');
        };
        $scope.addPhone = function () {
            $scope.contact.phones.push('');
        };
        $scope.addFax   = function () {
            $scope.contact.faxes.push('');
        };

        $scope.remove = function (array, $index) {
            array.splice($index, 1);
        };

        (function () {
            if (!angular.isDefined($scope.contact.emails)) {
                $scope.contact.emails = [''];
            }
            if (!angular.isDefined($scope.contact.faxes)) {
                $scope.contact.faxes = [''];
            }
            if (!angular.isDefined($scope.contact.phones)) {
                $scope.contact.phones = [''];
            }


        })();

    });