/**
 * Created by dbichenko on 23.09.2016.
 */

angular.module('sinvoice-common')

    .run(function (onInitContainer, Question, HelpServiceContainer) {

        onInitContainer.addAfterLoginPromise(function () {

            return Question.getCategories().then(function (data) {
                HelpServiceContainer.setQuestionCategories(data);
            })

        });

        onInitContainer.addAfterLoginPromise(function () {

            return Question.getQuestionTooltips().then(function (data) {
                HelpServiceContainer.setQuestionTooltips(data);
            })

        });

    })
    .factory('HelpServiceContainer', function () {
        var questionTooltips   = null;
        var questionCategories = null;

        return {
            getQuestionCategories: function () {
                return questionCategories;
            },
            setQuestionCategories: function (value) {
                questionCategories = value;
            },
            setQuestionTooltips  : function (value) {
                questionTooltips = value;
            },
            getQuestionTooltips  : function () {
                return questionTooltips;
            }
        }
    })
    .service('HelpService', function (HelpServiceContainer, Question) {
        var self = this;

        self.showQuestionTooltip = function (alias) {
            return self.loadTooltips().then(function (data) {
                return _.find(data, {alias: alias});
            })
        };

        self.loadTooltips = function () {
            return new Promise(function (resolve) {
                resolve(HelpServiceContainer.getQuestionTooltips());
            })
        };

        self.loadCategories = function () {
            return new Promise(function (resolve, reject) {
                resolve(HelpServiceContainer.getQuestionCategories());
            });
        };

        self.loadVideos = function (medias, array, catId) {

            if (!medias)
                return;

            medias.forEach(function (item, i) {
                item.catId = catId ? catId : null;
                item.type  = "video";
                item.url   = item.embedUrl;
                array.push(item);
            })
        };

        self.searchQuestions = function (query) {
            return Question.getQuestionByQuery(query);
        };

        self.showQuestionDetails = function (id) {
            return Question.getQuestionDetails(id);
        };

        return this;
    });
