/**
 * Created by abichenko on 21.11.17.
 */
angular.module('sinvoice-common')
    .factory('DocumentItemAmountCalculator', function (DocumentTaxCalculator) {

        return {
            getItemBeforeDiscountTotals: function (paragraphs) {

                var itemsExcludedVatTotal        = 0.0;
                var itemsIncludedVatTotal        = 0.0;
                var itemsIncludedVatTotalWithVat = 0.0;

                paragraphs.forEach(function (value) {

                    if (!value.displayWithVat) {
                        itemsExcludedVatTotal += Number(value.total);
                    } else {
                        itemsIncludedVatTotal += Number(value.total);
                        itemsIncludedVatTotalWithVat += Number(value.totalVat);
                    }
                });

                return {
                    itemsExcludedVatTotal       : itemsExcludedVatTotal,
                    itemsIncludedVatTotal       : itemsIncludedVatTotal,
                    itemsIncludedVatTotalWithVat: itemsIncludedVatTotalWithVat
                }

            },
            getItemAfterDiscountTotals : function (discount, itemsIncludedVatTotal, itemsExcludedVatTotal, itemsIncludedVatTotalWithVat) {


                var discountValue;
                if (discount && discount.type === 'fixed') {
                    discountValue = discount.value;
                } else if (discount && discount.type === 'percent') {
                    discountValue = (itemsIncludedVatTotal + itemsExcludedVatTotal) * (discount.value / 100)
                }

                var itemsIncludedVatTotalDiscounted        = 0.0;
                var itemsExcludedVatTotalDiscounted        = 0.0;
                var itemsIncludedVatTotalWithVatDiscounted = 0.0;

                if (discountValue) {
                    if (itemsIncludedVatTotal > discountValue) {
                        itemsIncludedVatTotalDiscounted = itemsIncludedVatTotal - discountValue;
                        itemsExcludedVatTotalDiscounted = itemsExcludedVatTotal;
                    } else {
                        itemsExcludedVatTotalDiscounted = itemsExcludedVatTotal + (itemsIncludedVatTotal - discountValue);
                        itemsIncludedVatTotalDiscounted = 0;
                    }
                    itemsIncludedVatTotalWithVatDiscounted = DocumentTaxCalculator.calculateAmountWithTax(itemsIncludedVatTotalDiscounted);

                } else {
                    itemsIncludedVatTotalDiscounted        = itemsIncludedVatTotal;
                    itemsExcludedVatTotalDiscounted        = itemsExcludedVatTotal;
                    itemsIncludedVatTotalWithVatDiscounted = itemsIncludedVatTotalWithVat
                }


                return {
                    itemsIncludedVatTotalDiscounted       : itemsIncludedVatTotalDiscounted,
                    itemsExcludedVatTotalDiscounted       : itemsExcludedVatTotalDiscounted,
                    itemsIncludedVatTotalWithVatDiscounted: itemsIncludedVatTotalWithVatDiscounted
                }

            },
            getItemAfterRounding       : function (isRoundingEnabled, itemsExcludedVatTotalDiscounted, itemsIncludedVatTotalDiscounted, itemsIncludedVatTotalWithVatDiscounted) {

                var itemsExcludedVatTotalRounded        = 0.0;
                var itemsIncludedVatTotalWithVatRounded = 0.0;
                var itemsIncludedVatTotalRounded        = 0.0;

                if (isRoundingEnabled) {
                    itemsExcludedVatTotalRounded        = Math.round(itemsExcludedVatTotalDiscounted);
                    itemsIncludedVatTotalWithVatRounded = Math.round(itemsIncludedVatTotalWithVatDiscounted);
                    itemsIncludedVatTotalRounded        = DocumentTaxCalculator.calculateAmountWithoutTax(itemsIncludedVatTotalWithVatRounded);
                } else {
                    itemsExcludedVatTotalRounded        = itemsExcludedVatTotalDiscounted;
                    itemsIncludedVatTotalWithVatRounded = itemsIncludedVatTotalWithVatDiscounted;
                    itemsIncludedVatTotalRounded        = itemsIncludedVatTotalDiscounted;
                }

                return {
                    itemsExcludedVatTotalRounded       : itemsExcludedVatTotalRounded,
                    itemsIncludedVatTotalWithVatRounded: itemsIncludedVatTotalWithVatRounded,
                    itemsIncludedVatTotalRounded       : itemsIncludedVatTotalRounded
                }
            }
        }

    });