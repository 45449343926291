/**
 * Created by dbichenko on 21.07.2016.
 */
angular.module('sinvoice-desktop').controller('taxInvoiceController',
    function ($scope,
              Modal,
              $translate,
              Currency,
              FormHelper,
              TemplateChecker,
              Events,
              DocumentTypeConstants,
              DocumentModalCallbackContainer,
              RelatedDocumentTableCallbackContainer) {


        $scope.paragraphForm                          = [];
        $scope.template.itemsRequired                 = true;
        $scope.template.$reliableTypesOfOpenDocuments = [
            DocumentTypeConstants.TYPE_ORDER,
            DocumentTypeConstants.TYPE_DELIVERY_NOTE,
            DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
            DocumentTypeConstants.TYPE_PRICE_QUOTE,
            DocumentTypeConstants.TYPE_RECEIPT,
            DocumentTypeConstants.TYPE_PERFORM,
            DocumentTypeConstants.TYPE_TAX_INVOICE_CREDIT,
            'invoice_balance_adjustment'
        ];


        $scope.template.$oneSideRelationDocTypes = [
            DocumentTypeConstants.TYPE_ORDER,
            DocumentTypeConstants.TYPE_DELIVERY_NOTE,
            DocumentTypeConstants.TYPE_PAYMENT_REQUEST,
            DocumentTypeConstants.TYPE_PRICE_QUOTE,
            DocumentTypeConstants.TYPE_PERFORM
        ];


        $scope.$watch('editedDocument.issueDate', updateDates);


        function updateDates(newVal, oldValue) {

            if (newVal === oldValue)
                return;

            var paymentDate = moment.isDate($scope.editedDocument.paymentDate)
                ? $scope.editedDocument.paymentDate
                : new momentWrapped($scope.editedDocument.paymentDate);

            var issueDate = moment.isDate($scope.editedDocument.issueDate)
                ? $scope.editedDocument.issueDate
                : new momentWrapped($scope.editedDocument.issueDate);

            if (issueDate > paymentDate) {
                $scope.editedDocument.paymentDate = issueDate;
            }
        }


        (function () {

            if (!angular.isDefined($scope.editedDocument.paymentDate))
                $scope.editedDocument.paymentDate = momentWrapped();

            initializeCustomValidators();

            function initializeCustomValidators() {
                DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                    var editedDocument  = $scope.editedDocument;
                    var paragraphErrors = $scope.template.paragraphs.errors;

                    paragraphErrors.required = false;

                    if (!editedDocument.paragraphs.length) {
                        flow.deny();
                        paragraphErrors.required = true;
                        return false;
                    }
                    flow.allow();
                });

            }


        })()
    });