/**
 * Created by abichenko on 10.08.17.
 */
angular.module('sinvoice-desktop').constant('ManagedCustomerConstants', {
    'STATUS_INVITED' : 1,
    'STATUS_DECLINED': 3,
    STATUS_ACTIVE    : 1,
    STATUS_INACTIVE  : 2,
    STATUS_DELETED   : 4

});