/**
 * Created by dbichenko on 13.01.2017.
 */
angular.module('sinvoice-desktop').controller('newVatIncomeReportBlockController',
    function ($scope, Item, Report, Events, ReportsFilterMapper, $stateParams, TaxPeriodProvider) {

        $scope.spinner       = {
            enabled: true
        };
        $scope.newReportForm = {};

        $scope.setOtherDate     = function () {
            $scope.selected.dateExample = $scope.dateExamples[4];
        };
        $scope.updateFromToDate = function (date) {
            $scope.selected.from = date.from ? date.from : new Date();
            $scope.selected.to   = date.to ? date.to : new Date();
        };


        $scope.produceNewReport = function () {

            $scope.spinner.enabled = true;
            Report.createReport('vat_income', $scope.selected).then(function (data) {
                $scope.spinner.enabled = false;
                Events.triggerEvent('report.update', data);
            });

        };

        (function () {

            $scope.selected = {};

            $scope.dateExamples = TaxPeriodProvider.getDatepickerValues();
            var dateFilters     = ReportsFilterMapper.getMappedDate(
                $stateParams.from,
                $stateParams.to,
                $scope.dateExamples
            );

            $scope.selected.to          = dateFilters.to;
            $scope.selected.from        = dateFilters.from;
            $scope.selected.dateExample = dateFilters.dateExample;

            $scope.spinner.enabled = false;
        })();

    });