/**
 * Created by abichenko on 07.12.17.
 */
angular.module('sinvoice-mobile').controller('searchBlockController', function ($scope, MobileModal, Events) {


    $scope.query = '';

    $scope.performSearch = function () {


        MobileModal.overlay(
            './mobile/layouts/header/search-popup.html',
            {
                className     : 'search-popup overlay-popup',
                scopeVariables: {
                    query   : $scope.query,
                    onChange: function (newValue) {
                        $scope.query = newValue;
                    }
                }
            }
        )

    };

    (function () {


        Events.subscribeToEvent('$stateChangeStart', $scope, function () {
            $scope.query = '';
        });
    })();

});