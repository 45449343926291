/**
 * Created by abichenko on 11.10.17.
 */
angular.module('sinvoice-desktop').controller('subscriptionLimitPopupController', function ($scope, $state) {


    $scope.go = function () {
        $scope.$currentModal.close();
        $state.go('profile.subscription')
    }

});