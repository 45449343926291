/**
 * Created by abichenko on 27.03.18.
 */
angular.module('sinvoice-mobile').controller('editPaymentPopupController',
    function ($scope, $filter, BankChecker, Bank, Currency, Customer, $q, Events) {

        $scope.spinner = {enabled: true};

        $scope.paymentForm = {};
        $scope.currencies  = [];
        $scope.dates       = [];
        $scope.banks       = [];
        $scope.cards       = [
            'isracard',
            'mastercard',
            'visa',
            'amex',
            'diners'
        ];


        $scope.$watch('paymentForm', function (value) {
            Events.triggerEvent('paymentForm.update', $scope.paymentForm)
        }, true);

        $scope.close = function () {
            $scope.$currentModal.close();
        };

        $scope.save = function () {

            if ($filter('isSubmitNotAllowed')($scope.paymentForm)) {
                return;
            }

            $scope.onSave($scope.payment);

            $scope.$currentModal.close();
        };


        $scope.isCustomBank = function (bank) {
            return BankChecker.isCustomBank(bank);
        };

        $scope.onUseTaxWithdrawalChange = function () {
            if (!$scope.useTaxWithdrawal) {
                $scope.taxValue = 0;
            }
        };
        $scope.getPaymentCurrencySymbol = function () {
            return $scope.isNisPayments ? 'NIS' : $scope.currency.name;
        };


        function loadCustomer(id) {
            return Customer.getSingleCustomer(id).then(function (data) {
                $scope.selectedCustomerData = data;
            })
        }

        function loadData() {

            var promises = [];

            if (angular.isDefined($scope.customer)) {
                promises.push(loadCustomer($scope.customer.id));
            }

            promises.push(
                Currency
                    .getData()
                    .then(function (data) {
                        $scope.currencies = data
                    })
            );
            promises.push(
                Bank
                    .getAll()
                    .then(function (data) {
                        $scope.banks = data;
                    })
            );

            return $q.all(promises);


        }

        (function () {
            var daySubtractValues = _.range(0, 15);

            daySubtractValues.forEach(function (value) {
                $scope.dates.push(momentWrapped().subtract(value, 'days'));
            });

            loadData().finally(function () {
                $scope.spinner.enabled = false;
            })


        })();


        (function () {
            if (!angular.isDefined($scope.payment)) {
                $scope.payment = {type: $scope.type, amount: 0};
            }
        })();

    });