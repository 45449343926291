/**
 * Created by abichenko on 21.12.17.
 */
angular.module('sinvoice-mobile').directive('sectionBlock', function () {

    return {
        transclude: true,
        link      : function (scope, element, attrs) {

        }
    }

});