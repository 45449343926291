/**
 * Created by dbichenko on 20.07.2016.
 */
angular.module('sinvoice-desktop').controller('paragraphTableController',
    function ($scope,
              Events,
              ParagraphCheck,
              DocumentTaxCalculator,
              $filter,
              Document,
              Modal,
              $translate,
              DocumentItemAmountCalculator,
              DocumentInitializer) {


        function updateImportedParagraphs(document) {

            var vatParametersMatches = true;
            if (document.selectedVATParameters !== $scope.editedDocument.selectedVATParameters && !Document.isBalanceAdjustment(document.doc_type))
                vatParametersMatches = false;


            if (!vatParametersMatches && $scope.editedDocument.selectedVATParameters != 'mixed') {
                Modal.alert(
                    $translate.instant('documents.relating_different_vat_settings_warning'),
                    {title: $translate.instant('documents.relating_different_vat_settings_warning_title')}
                );
            }


            updateParagraphVATDisplay();
        }

        function importParagraphsFromRelatedDocs(relatedDocs, isCopy) {


            if (!relatedDocs)
                return;


            var importedParagraphs = [];
            var existingParagraphs = $scope.editedDocument.paragraphs;

            relatedDocs.forEach(function (document) {

                if (document.paragraphs) {
                    document.paragraphs.forEach(function (value) {
                        var paragraph                      = angular.copy(value);
                        paragraph.isEditable               = true;
                        paragraph.isImportedFromRelatedDoc = !isCopy;

                        importedParagraphs.push(paragraph)
                    });
                }
            });

            for (var i = 0; i < existingParagraphs.length; i++) {
                if (typeof existingParagraphs[i].unitPrice == 'undefined' && !existingParagraphs[i].details) {
                    existingParagraphs.splice(i, 1);
                    i--;
                }
            }
            ;

            $scope.template.paragraphs.isEditable = true;
            $scope.editedDocument.paragraphs      = importedParagraphs.concat(existingParagraphs);
        }

        function updateFilteredItems(newCurrency) {
            $scope.filteredItems = $filter('filter')($scope.items, {
                currency: {
                    name: newCurrency.name
                }
            })
        }

        function setDefaults() {

            updateFilteredItems($scope.editedDocument.currencies);

            if (!$scope.editedDocument.paragraphs) {
                $scope.editedDocument.paragraphs = [];
                $scope.addElement();
            }

            if (!$scope.template.paragraphs) {
                $scope.template.paragraphs        = {};
                $scope.template.paragraphs.errors = {};
            }


            var editable = $scope.template.paragraphs.isEditable;
            if (typeof(editable) == 'undefined' || editable === null)
                $scope.template.paragraphs.isEditable = true;

        }


        $scope.isEditable = function (paragraph) {
            var paragraphEditable;

            paragraphEditable = (!paragraph || paragraph.isEditable !== false);

            return $scope.template.isEditable && $scope.template.paragraphs.isEditable && paragraphEditable;
        };


        $scope.addItem = function (item) {


            removeEmptyItems();

            var paragraph = {
                quantity      : 1,
                item          : item,
                details       : item.name,
                isEditable    : true,
                displayWithVat: $scope.editedDocument.selectedVATParameters == 'exclude' ? false : true
            };

            Events.triggerEvent('paragraph.addedNewElement');

            $scope.editedDocument.paragraphs.push(paragraph);

        };

        $scope.addElement = function (e) {
            var paragraph = {
                quantity      : 1,
                details       : '',
                isEditable    : true,
                displayWithVat: $scope.editedDocument.selectedVATParameters == 'exclude' ? false : true
            };

            Events.triggerEvent('paragraph.addedNewElement');

            $scope.editedDocument.paragraphs.push(paragraph);
        };


        $scope.removeElement = function (item) {

            $scope.editedDocument.paragraphs = $scope.editedDocument.paragraphs.filter(function (currentElement) {
                return currentElement != item;
            });
        };


        function removeEmptyItems() {
            $scope.editedDocument.paragraphs = $scope.editedDocument.paragraphs.filter(function (paragraph) {
                return angular.isDefined(paragraph.unitPrice) || paragraph.details
            })
        }

        function removeAllImportedParagraphs() {


            if (!$scope.editedDocument.paragraphs)
                return;

            var filtered = $scope.editedDocument.paragraphs.filter(function (paragraph) {
                return !ParagraphCheck.isImportedFromRelatedDocument(paragraph);
            });

            $scope.editedDocument.paragraphs = !$scope.template.isEditable ? $scope.editedDocument.paragraphs : filtered;

        }


        function updateParagraphVATDisplay() {
            if ($scope.editedDocument.selectedVATParameters === 'exclude') {

                $scope.editedDocument.paragraphs.forEach(function (paragraph) {
                    paragraph.displayWithVat = false;
                });
                Events.triggerEvent('paragraph.VATSelectDisabled', true);
                $scope.editedDocument.displayWithVat = false;

            } else if ($scope.editedDocument.selectedVATParameters === 'include') {

                $scope.editedDocument.paragraphs.forEach(function (paragraph) {
                    paragraph.displayWithVat = true;
                });
                Events.triggerEvent('paragraph.VATSelectDisabled', true);
                $scope.editedDocument.displayWithVat = true;

            } else {
                Events.triggerEvent('paragraph.VATSelectDisabled', false);
            }
        }

        function calculateItemsTotal() {

            var itemsExcludedVatTotalRounded        = 0.0;
            var itemsIncludedVatTotalWithVatRounded = 0.0;
            var itemsIncludedVatTotalRounded        = 0.0;

            var paragraphs = angular.copy($scope.editedDocument.paragraphs);

            var beforeDiscountTotals         = DocumentItemAmountCalculator.getItemBeforeDiscountTotals(paragraphs);
            var itemsExcludedVatTotal        = beforeDiscountTotals.itemsExcludedVatTotal;
            var itemsIncludedVatTotal        = beforeDiscountTotals.itemsIncludedVatTotal;
            var itemsIncludedVatTotalWithVat = beforeDiscountTotals.itemsIncludedVatTotalWithVat;

            $scope.template.$itemsExcludedVatBeforeDiscount = itemsExcludedVatTotal;
            $scope.template.$itemsIncludedVatBeforeDiscount = itemsIncludedVatTotal;
            $scope.template.$itemsTotalBeforeDiscount       = itemsExcludedVatTotal + itemsIncludedVatTotal;

            var discount;
            if ($scope.editedDocument.discounts && $scope.editedDocument.discounts[0]) {
                // Currently we need only one discount.
                discount = $scope.editedDocument.discounts[0];
            }

            var afterDiscountTotals = DocumentItemAmountCalculator.getItemAfterDiscountTotals(
                discount,
                itemsIncludedVatTotal,
                itemsExcludedVatTotal,
                itemsIncludedVatTotalWithVat
            );

            var itemsExcludedVatTotalDiscounted        = afterDiscountTotals.itemsExcludedVatTotalDiscounted;
            var itemsIncludedVatTotalDiscounted        = afterDiscountTotals.itemsIncludedVatTotalDiscounted;
            var itemsIncludedVatTotalWithVatDiscounted = afterDiscountTotals.itemsIncludedVatTotalWithVatDiscounted;

            $scope.template.$itemsExcludedVatAfterDiscount        = itemsExcludedVatTotalDiscounted;
            $scope.template.$itemsIncludedVatAfterDiscount        = itemsIncludedVatTotalDiscounted;
            $scope.template.$itemsIncludedVatWithVatAfterDiscount = itemsIncludedVatTotalWithVatDiscounted;


            if ($scope.template.showRoundedTotal) {
                itemsExcludedVatTotalRounded        = Math.round(itemsExcludedVatTotalDiscounted);
                itemsIncludedVatTotalWithVatRounded = Math.round(itemsIncludedVatTotalWithVatDiscounted);
                itemsIncludedVatTotalRounded        = DocumentTaxCalculator.calculateAmountWithoutTax(itemsIncludedVatTotalWithVatRounded);
            } else {
                itemsExcludedVatTotalRounded        = itemsExcludedVatTotalDiscounted;
                itemsIncludedVatTotalWithVatRounded = itemsIncludedVatTotalWithVatDiscounted;
                itemsIncludedVatTotalRounded        = itemsIncludedVatTotalDiscounted;
            }

            $scope.template.$itemsExcludedVatTotal        = itemsExcludedVatTotalRounded;
            $scope.template.$itemsIncludedVatTotal        = itemsIncludedVatTotalRounded;
            $scope.template.$itemsIncludedVatTotalWithVat = itemsIncludedVatTotalWithVatRounded;

            $scope.template.$itemsIncludedVatRoundingValue = itemsIncludedVatTotalRounded - itemsIncludedVatTotalDiscounted;
            $scope.template.$itemsExcludedVatRoundingValue = itemsExcludedVatTotalRounded - itemsExcludedVatTotalDiscounted;

        }

        DocumentInitializer.addInitCallback(function () {

            $scope.isVATNotAllowed = ParagraphCheck.isVATNotAllowed();

            Events.subscribeToEvent(['customer.change', 'currencies.change'], $scope, function () {
                removeAllImportedParagraphs();
            });
            $scope.$watch('template.$reliableTypesOfOpenDocuments', function (newValue, oldValue) {
                if (newValue == oldValue || !newValue)
                    return;

                removeAllImportedParagraphs();
            });
            Events.subscribeToEvent('relatedDocs.checkedHasRelatedDocuments', $scope, function () {
                if (!$scope.template.showRelatedDocumentsTable) {
                    removeAllImportedParagraphs();
                }
            });


            Events.subscribeToEvent('vatOptions.change', $scope, function () {
                updateParagraphVATDisplay();
            });

            Events.subscribeToEvent('relatedDocs.checkedHasRelatedDocuments', $scope, function (e, hasRelatedDocs) {
                if (!hasRelatedDocs)
                    $scope.template.isBalanceWarningNeedToBeShown = true;
            });

            Events.subscribeToEvent('relatedDocs.related', $scope, function (e, newDocs, oldDocs, newDocument) {

                if (!$scope.template.isEditable)
                    return;

                removeAllImportedParagraphs();
                importParagraphsFromRelatedDocs(newDocs);

                if (newDocument)
                    updateImportedParagraphs(newDocument);


            });


            Events.subscribeToEvent('relatedDocs.copied', $scope, function (e, newDocs, oldDocs, newDocument) {

                if (!$scope.template.isEditable)
                    return;

                importParagraphsFromRelatedDocs([newDocument], true);

            });

            Events.subscribeToEvent('relatedDocs.unrelated', $scope, function (e, newDocs, oldDocs) {

                if (!$scope.template.isEditable)
                    return;

                removeAllImportedParagraphs();
                importParagraphsFromRelatedDocs(newDocs);

            });


            $scope.$watch(function () {

                var result = 0.0;

                $scope.editedDocument.paragraphs.forEach(function (paragraph) {
                    result += paragraph.totalVat + paragraph.total;
                });

                result += $scope.template.showRoundedTotal;

                return result;

            }, function () {
                calculateItemsTotal();
            });


            Events.subscribeToEvent('currencies.change', $scope, function (event, newCurrency, oldCurrency) {

                updateFilteredItems(newCurrency);
            });

            $scope.$watch('editedDocument.discounts', function () {
                calculateItemsTotal();
            }, true);

            setDefaults();

        });


    })
;
