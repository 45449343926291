angular.module('sinvoice-desktop').directive('itemSearch', ['$templateRequest', '$compile', function ($templateRequest, $compile) {
    return {
        controller: 'itemFilterTableController',
        scope     : {
            items  : '=',
            onClick: '&',
            opened : '=?'
        },
        link      : function (scope, element, attrs, ngModelCtrl) {

            function toggleTable() {

                scope.$apply(function () {
                    scope.opened = !scope.opened;
                });

            }


            $templateRequest('./desktop/components/documents/partials/item-filter-table.html').then(function (template) {

                if (!scope.items || !scope.items.length) {

                    /*element.hide();
                     return;*/
                }

                if (!angular.isDefined(scope.opened)) {
                    scope.opened = false
                }

                var html = $compile(template)(scope);

                var parentTr = $(element).parents('tr');
                var newTr    = $('<tr>');
                var td       = $('<td>');

                newTr.insertAfter(parentTr);
                newTr.append(td);

                td.addClass('item-search-table');
                td.attr('colspan', 9);
                td.html(html);

                scope.addItem = function (item) {
                    scope.onClick({item: item});
                };

                $(element).on('click', function (e) {

                    e.stopPropagation();

                    e.preventDefault();

                    toggleTable();

                });

                $(element).on('keydown', function (e) {
                    
                    if (e.keyCode == 32){

                        e.stopPropagation();

                        e.preventDefault();

                        toggleTable();
                    }

                });

                scope.$watch('opened', function (newValue) {
                    if (!newValue)
                        newTr.hide();
                    else
                        newTr.toggle();
                });


            });
        }
    };
}])
    .directive('tooltipTextarea', [function () {
        return {
            templateUrl: './desktop/components/documents/partials/tooltipTextarea.html',
            restrict   : 'A',
            replace    : false,
            link       : function (scope, element, attrs) {
                var confirm             = angular.element(element[0]).find('.tooltipTextareaConfim'),
                    reset               = angular.element(element[0]).find('.tooltipTextareaReset'),
                    input               = angular.element(element[0]).find('.tooltipTextareaInput');
                scope.showState         = {};
                scope.showState.visible = false;
                input[0].onchange       = function (e) {

                    if (input[0].scrollHeight >= input[0].offsetHeight) {
                        input[0].style.height += 15;
                    }
                };
                input.bind("click", function (e) {
                    scope.$apply(function () {
                        scope.showState.visible = true;
                    });
                });

                confirm.bind("click", function (e) {
                    scope.$apply(function () {
                        scope.showState.visible = false;
                    });
                });

                reset.bind("click", function (e) {
                    scope.$apply(function () {
                        scope.paragraph.details = '';
                    });
                });

            }
        };
    }])
