/**
 * Created by abichenko on 31.10.17.
 */
angular.module('sinvoice-mobile').controller('myCustomersController', function ($scope, $state, Customer) {

    $scope.filteredCustomers = [];
    $scope.spinner           = {enabled: true};
    $scope.customers         = null;

    $scope.onChange = function (currentPage, perPage) {
        $scope.filteredCustomers = angular.copy($scope.customers).splice((currentPage - 1) * perPage, perPage);
    };

    $scope.newCustomer = function () {
        $state.go('new-customer');
    };

    $scope.editCustomer = function (customer) {
        $state.go('view-customer', {customerId: customer.id});

    };


    $scope.newDocument = function (customer) {
        $state.go('new-document', {'customerId': customer.id});
    };


    (function () {
        Customer
            .getData()
            .then(function (data) {
                $scope.customers = data;
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })
    })()

});