/**
 * Created by abichenko on 01.12.17.
 */
angular.module('sinvoice-mobile')
    .controller('mobileForgotPasswordController', function ($scope, ResetPass, $filter) {

        $scope.internalStep = 1;

        $scope.selected = {};

        $scope.spinner = {enabled: false};

        $scope.sendRequestForm = {};


        $scope.sendRequest = function () {

            if ($filter('isSubmitNotAllowed')($scope.sendRequestForm)) {
                return;
            }

            $scope.spinner.enabled = true;

            ResetPass.sendResetRequest($scope.selected.email)
                .then(function (data) {
                    $scope.internalStep = 2;
                })
                .finally(function () {
                    $scope.spinner.enabled = false
                })
        }
    });