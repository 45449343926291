angular.module('sinvoice-desktop').controller('mailController', function ($scope, Profile, UserSubscription) {

    $scope.spinner = {enabled: true};

    $scope.settings = {
        reportEmails: []
    };

    $scope.addEmailForm = {};

    $scope.profileForm = {};

    $scope.addEmailFormData = {};

    $scope.backupInfo = {};

    $scope.externalTypes = [
        'mivne', 'wizcount'
    ];

    $scope.isSubmitButtonDisabled = function () {
        return UserSubscription.isCheckInProgress();
    };


    $scope.isSaveAllowed = function () {
        return new Promise(function (resolve, reject) {

            if ($scope.profileForm.$invalid) {
                return reject(false);
            }

            return UserSubscription.checkSubscriptionForEditAction().then(function () {
                return resolve(true)
            });

        })
    };

    $scope.addEmail = function () {
        $scope.settings.reportEmails.push($scope.addEmailFormData.email);
        $scope.addEmailFormData.email = '';
    };

    $scope.removeEmail = function ($index) {
        $scope.settings.reportEmails.splice($index, 1);
    };

    $scope.cancel = function () {
        $scope.settings = angular.copy($scope.backupInfo);
    };

    $scope.save = function () {

        $scope.spinner.enabled = true;
        return Profile
            .updateMailSettings($scope.settings)
            .then(function (data) {
                $scope.backupInfo = angular.copy($scope.settings)
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            });
    };

    $scope.isReportFrequencySelected = function () {
        return $scope.settings.reportFrequency !== 'never';
    };

    (function () {

        $scope.$watch(function () {

            var isValid;
            if (!$scope.isReportFrequencySelected())
                isValid = true;
            else {
                isValid = !!$scope.settings.reportEmails.length;
            }
            $scope.profileForm.$setValidity('emails', isValid)

        });

        Profile
            .getMailSettings()
            .then(function (data) {
                $scope.settings   = data;
                $scope.backupInfo = angular.copy($scope.settings);
            })
            .finally(function () {
                $scope.spinner.enabled = false;
            })
    })();

});