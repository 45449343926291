/**
 * Created by abichenko on 27.11.17.
 */
angular.module('sinvoice-mobile')
    .controller('taxInvoiceCreditController',
        function ($scope, DocumentModalCallbackContainer, DocumentModalCallbackChecker, $filter) {
            $scope.errors = {};


            (function () {

                if (!angular.isDefined($scope.editedDocument.paymentDate))
                    $scope.editedDocument.paymentDate = momentWrapped().startOf('day')._d;



                /*DocumentModalCallbackChecker.addPreSaveCallback(function (flow) {

                    if (!DocumentModalCallbackChecker.isCreditWarningPopupNeedToBeShown($scope.editedDocument)) {
                        return flow.allow();
                    }

                    Modal
                        .confirm(
                            $filter('documentPopupTranslate')('documents.occasional_customer_warning', $scope.editedDocument.type.docType),
                            null,
                            null,
                            {title: $filter('documentPopupTranslate')('documents.occasional_customer_warning_title', $scope.editedDocument.type.docType)}
                        )
                        .then(
                            flow.allow
                        )

                });*/

                DocumentModalCallbackContainer.addPreValidateCallback(function (flow) {

                    var editedDocument  = $scope.editedDocument;
                    var paragraphErrors = $scope.errors;

                    paragraphErrors.required = false;

                    if (!editedDocument.paragraphs.length) {
                        flow.deny();
                        paragraphErrors.required = true;
                        return false;
                    }
                    flow.allow();
                });


            })();
        });