/**
 * Created by dbichenko on 06.03.2017.
 */
angular
    .module('sinvoice-mobile', [
        'ngBootbox',
        'ui.bootstrap',
        'bootstrapLightbox',
        'videosharing-embed',
        'sinvoice-common',
        'ngTouch',
        'ui.select'
    ])
    .run(function () {
    });