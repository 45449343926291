/**
 * Created by dbichenko on 19.10.2016.
 */
angular.module('sinvoice-desktop').controller('exportIndexController', function ($scope, $stateParams, $state) {


    (function () {

        if (!$stateParams.token) {
            $state.go('login');
        }

    })();
});